import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import {
  documentTypes,
  AlertService,
  ModalService,
  ValidationService,
} from "lib";
import { ComponentLoadDirective } from "../../../core/directives/componentLoad.directive";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as fromApp from "../../../store/app.reducer";
import {
  selectQueryParams,
  selectRouteParams,
} from "../../../store/app.reducer";
import { Observable, zip } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { WebFormService } from "../../../shared/services/web-form/web-form.service";
import { IMM5562StartComponent } from "../../forms/IMM5562/imm5562-start/imm5562-start.component";
import { IMM5562SectionAComponent } from "../../forms/IMM5562/imm5562-section-a/imm5562-section-a.component";
import { IMM5562SectionBComponent } from "../../forms/IMM5562/imm5562-section-b/imm5562-section-b.component";
import { IMM5562SectionCComponent } from "../../forms/IMM5562/imm5562-section-c/imm5562-section-c.component";
import { IMM5562SectionBaseComponent } from "../../forms/IMM5562/imm5562-section-base/imm5562-section-base.component";
import { from } from "rxjs/internal/observable/from";
import { Case } from "../../../shared/case-module/case.model";
import { Document } from "../../../core/models/document.model";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { of, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { CanComponentDeactivate } from "../../../routing/guards/nav.guard";
import _ from "lodash";
import FormNavbarItems from "./imm5562-form-nabvar-items.json";
import { MediaMatcher } from "@angular/cdk/layout";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5562",
  templateUrl: "./IMM5562page.component.html",
  styleUrls: [
    "./IMM5562page.component.scss",
    "../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM5562PageComponent
  implements OnInit, OnDestroy, AfterViewChecked, CanComponentDeactivate
{
  @ViewChild(ComponentLoadDirective, { static: true })
  praComponentHost: ComponentLoadDirective;

  public nextButtonKey: string;
  public isDataLoading = true;
  public goBackModalId = "goBackModalId";
  public curr = 0;
  public FormNavbarItems = FormNavbarItems;
  public formVersion: number;

  private currentLoadedComponent: ComponentRef<any>;
  private caseId: string | null;
  private currentPage: string = "page1";
  private dataForCurrentForm: any = {}; // this to be better defined once we know what API provides
  private all5562StepPages = ["page1", "page2", "page3", "page4"]; // this is used to navigate back when you re on page 2, or page 3 etc
  private all5562SectionsPages = [
    "",
    "principalApplicant",
    "partnerApplicant",
    "dependantApplicant",
  ]; // this is the sections that will be used for each form

  private documentTypeId: number = documentTypes.imm5562.id;
  private documentId?: number;
  private subscriptions: Subscription[] = [];
  private currentLang: string;

  // navigation modal
  private navigationModalId = "navigationModal";
  private navigationModalSelection = false;
  private isNavigatingInPages = true;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromApp.State>,
    private webFormSvc: WebFormService,
    private translate: TranslateService,
    private alertService: AlertService,
    private authService: AuthService,
    private routeLocalizer: RouteLocalizerService,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private validationService: ValidationService,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  // Use cases:
  // 1. Start pages (// :caseId/imm5562 or :caseId/imm5562?pageId=page1)
  // 2. Form pages (// :caseId/imm5562?pageId=page2)

  ngOnInit(): void {
    this.authService.checkSession();
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.checkDocumentInStore();
    this.startSubscription();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  /*********** PUBLIC ***********/

  // using "this.currentLoadedComponent.instance" you can check if current form has errors etc...
  // if current component can't be saved you display appropriate message and you  return
  // without trying to save
  // here you get data from current loaded component and build appropriate web service call to save it

  public saveAndNext(): void {
    // If you are on the first start page, the save should be to navigate to next page only
    const nextPage =
      this.all5562StepPages[
        this.all5562StepPages.indexOf(this.currentPage) + 1
      ];
    this.isNavigatingInPages = true;

    // Skipping the saving if it is read-only or the first page
    if (
      !this.user.can("documents:write") ||
      this.currentPage === this.all5562StepPages[0]
    ) {
      if (this.currentPage === this.all5562StepPages[3]) {
        const newNestedPath = this.routeLocalizer.getNestedPath(
          this.currentLang,
          ["INTAKE", "INTAKE_CASE_DETAILS"]
        );
        this.router.navigate([
          `/${this.currentLang}/${newNestedPath}${this.caseId}`,
        ]);
      } else {
        this.navigateTo(nextPage);
      }
      return;
    }

    // Validate the form in the pages values
    if (this.currentLoadedComponent.instance.isFormValid) {
      const formDataToSave = this.currentLoadedComponent.instance.value;
      console.log("formData5662:", formDataToSave);
      const navigateToNext = () => {
        // If we are at the last page, navigate to the case details page, display the saved banner
        if (this.currentPage === this.all5562StepPages[3]) {
          const newNestedPath = this.routeLocalizer.getNestedPath(
            this.currentLang,
            ["INTAKE", "INTAKE_CASE_DETAILS"]
          );
          this.router
            .navigate([`/${this.currentLang}/${newNestedPath}${this.caseId}`])
            .then(() =>
              this.alertService.success(
                this.translate.instant("INTAKE.ALERTS.DOCUMENTS_SAVED")
              )
            );
        } else {
          this.navigateTo(nextPage);
        }
      };
      if (this.currentLoadedComponent.instance.isFormTouched) {
        // with correct values for each save
        const formInfo = {
          caseId: this.caseId || "",
          documentTypeId: this.documentTypeId,
          documentId: this.documentId,
          sectionId: this.getCurrentSection(),
        };
        from(
          this.webFormSvc.updateFormSection(formInfo, {
            form: { ...formDataToSave, requestVersion: this.formVersion },
          })
        )
          .pipe(first())
          .subscribe(
            (resp) => {
              navigateToNext();
            },
            (error) => {
              if (error.response.status === 409) {
                this.alertService.danger(
                  this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
                );
              } else {
                this.alertService.danger(this.alertTechnicalError);
                console.error(
                  " web service call error to save data for: " +
                    this.currentPage,
                  error.response.status
                );
              }
            }
          );
      } else {
        navigateToNext();
      }
    } else {
      this.currentLoadedComponent.instance.showFormInvalidErrors();
    }
  }

  public navigateTo(pageId: string): void {
    switch (pageId) {
      case this.all5562StepPages[1]: {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { pageId: this.all5562StepPages[1] },
          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
        break;
      }
      case this.all5562StepPages[2]: {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { pageId: this.all5562StepPages[2] },
          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
        break;
      }
      case this.all5562StepPages[3]: {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { pageId: this.all5562StepPages[3] },
          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
        break;
      }
      case this.all5562StepPages[0]:
      default: {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { pageId: this.all5562StepPages[0] },
          queryParamsHandling: "merge", // remove to replace all query params by provided
        });
        break;
      }
    }
  }

  public navigateBack(forceNavigation?: boolean): void {
    const unsavedData =
      this.currentPage !== this.all5562StepPages[0] &&
      this.currentLoadedComponent.instance.isFormDataUnSaved();
    this.isNavigatingInPages = true;
    if (unsavedData && !forceNavigation) {
      this.openNavigateModal();
      return;
    }
    if (this.currentPage === this.all5562StepPages[0]) {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
    } else {
      const nextPage =
        this.all5562StepPages[
          this.all5562StepPages.indexOf(this.currentPage) - 1
        ];
      this.navigateTo(nextPage);
    }
  }

  formSideNavbarNavigation(pageId: number) {
    this.currentPage = this.all5562StepPages[pageId + 2];
    this.navigateBack();
  }

  // Fires before the entire route is expected to change (not params in the route)
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    this.subscriptions.push(
      this.modalService.navigateAwaySelection$.subscribe((selection) => {
        this.navigationModalSelection = selection;
      })
    );

    // check if form has been touched or saved
    const unsavedData =
      this.currentLoadedComponent?.instance?.isFormDataUnSaved();

    if (!this.isNavigatingInPages && unsavedData) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$.pipe(take(1));
    } else {
      return true;
    }
  }

  // Fires when the params in the url change such as pages between the form itself
  public openNavigateModal(): void {
    this.modalService.open(this.goBackModalId);
  }

  public closeModal(): void {
    this.isNavigatingInPages = false;
    this.modalService.close(this.goBackModalId);
  }

  public forceGoBack() {
    this.closeModal();
    this.navigateBack(true);
  }

  public get disableNextButton(): boolean {
    return !this.currentLoadedComponent?.instance?.isFormValid;
  }

  /*********** PRIVATE ***********/

  private getCurrentSection(): string {
    const pageIdIndex = this.all5562StepPages.indexOf(this.currentPage);
    return this.all5562SectionsPages[pageIdIndex];
  }

  private startSubscription(): void {
    this.subscriptions.push(
      zip(
        this.store.select(selectRouteParams),
        this.store.select(selectQueryParams)
      )
        .pipe(
          switchMap((data) => {
            // next lines are giving you info that you need to identify caseId and page to
            // load and get data for
            const [routeParmas, queryParmas] = data;
            const emptyPrams = _.isEmpty(routeParmas) && _.isEmpty(queryParmas);

            // checking the user is leaving the imm5562 form
            const isNavigatingAway =
              !this.isNavigatingInPages || this.navigationModalSelection; // true if they are leaving
            if (isNavigatingAway || emptyPrams) {
              this.setDataLoading(false);
              return of({ isNavigatingAway: true });
            }

            this.caseId = routeParmas?.caseId;
            this.currentPage = queryParmas?.pageId || this.all5562StepPages[0];
            this.currentLoadedComponent = this.loadComponentForPage(
              this.currentPage
            );

            this.setDataLoading(true);
            // this is loading the data for specific page (form/component)
            // the contract for webFormService to be defined
            // create a document or get document as per use case to be completed

            // If we have a documentId, we can get the document Data, else create a new document
            if (this.documentId) {
              const formData = {
                caseId: this.caseId || "",
                documentTypeId: this.documentTypeId,
                sectionId: this.getCurrentSection(),
                documentId: this.documentId,
              };

              switch (this.currentPage) {
                case this.all5562StepPages[1]: {
                  formData.sectionId = this.all5562SectionsPages[1];
                  return this.webFormSvc.getFormSection(formData);
                }
                case this.all5562StepPages[2]: {
                  formData.sectionId = this.all5562SectionsPages[2];
                  return this.webFormSvc.getFormSection(formData);
                }
                case this.all5562StepPages[3]: {
                  formData.sectionId = this.all5562SectionsPages[3];
                  return this.webFormSvc.getFormSection(formData);
                }
                case this.all5562StepPages[0]:
                default: {
                  return of(null);
                }
              }
            } else {
              const formInfo = {
                caseId: this.caseId ? this.caseId : "",
                documentTypeId: this.documentTypeId,
              };
              return this.webFormSvc.createDocument(formInfo);
            }
          })
        )
        .subscribe(
          (data) => {
            if (!data?.isNavigatingAway) {
              // here you obtain the data from web service and you are ready to pass it to component
              // that you will load
              // If there is no documentId
              // If the document was created, we need to set the document Id for next section API calls
              if (!this.documentId && data.id) {
                this.documentId = data?.id;
              }
              this.dataForCurrentForm = data;
              console.log(data);

              this.formVersion = data?.IMM5562FormVersion;

              console.log("version", this.formVersion);
              this.loadDataIntoCurrentComponent();
              this.isNavigatingInPages = false;
            }
          },
          (error) => {
            this.dataForCurrentForm = {};
            this.loadDataIntoCurrentComponent();
            this.alertService.danger(this.alertTechnicalError);
          }
        )
    );
  }

  private loadComponentForPage(pageId: string): ComponentRef<any> {
    const viewContainerRef = this.praComponentHost.viewContainerRef;
    viewContainerRef.clear();

    let componentFactory;
    switch (pageId) {
      case this.all5562StepPages[1]: {
        this.nextButtonKey = !this.user.can("documents:write")
          ? "INTAKE.FORM_PAGE_NEXT"
          : "INTAKE.FORM_PAGE_SAVE";
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5562SectionAComponent
          );
        this.curr = 1;
        break;
      }
      case this.all5562StepPages[2]: {
        this.nextButtonKey = !this.user.can("documents:write")
          ? "INTAKE.FORM_PAGE_NEXT"
          : "INTAKE.FORM_PAGE_SAVE";
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5562SectionBComponent
          );
        this.curr = 2;
        break;
      }
      case this.all5562StepPages[3]: {
        this.nextButtonKey = !this.user.can("documents:write")
          ? "INTAKE.FORM_PAGE_RETURN"
          : "INTAKE.FORM_PAGE_COMPLETE";
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5562SectionCComponent
          );
        this.curr = 3;
        break;
      }
      case this.all5562StepPages[0]:
      default: {
        this.nextButtonKey = "INTAKE.FORM_PAGE_NEXT";
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5562StartComponent
          );
        this.curr = 0;
        break;
      }
    }
    return viewContainerRef.createComponent<IMM5562SectionBaseComponent>(
      componentFactory
    );
  }

  private loadDataIntoCurrentComponent(): void {
    this.setDataLoading(false);
    this.currentLoadedComponent.instance.patchFormData(this.dataForCurrentForm);
  }

  // Check if the form exists in the store
  private checkDocumentInStore(): void {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.id !== "" && caseData.documents) {
          const imm5562Document = caseData.documents.find(
            (doc: Document) => doc.documentTypeId === this.documentTypeId
          );
          if (imm5562Document) {
            this.documentId = imm5562Document?.id;
          }
        }
      });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private setDataLoading(isLoading: boolean): void {
    this.isDataLoading = isLoading;
    this.currentLoadedComponent.instance.setDataLoading(isLoading);
  }
}
