<h1 class="title emphasis">
  {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
</h1>
<h2>
  {{ "INTAKE.IMM0008.PAGES.START_PAGE.HEADING" | translate }}
</h2>
<p class="body1">
  {{ "INTAKE.IMM0008.PAGES.START_PAGE.DESCRIPTION" | translate }}
</p>
<ul class="details">
  <li>{{ "INTAKE.IMM0008.PAGES.START_PAGE.ITEM_1" | translate }}</li>
  <li>{{ "INTAKE.IMM0008.PAGES.START_PAGE.ITEM_2" | translate }}</li>
  <li>{{ "INTAKE.IMM0008.PAGES.START_PAGE.ITEM_3" | translate }}</li>
</ul>
<p>{{ "INTAKE.IMM0008.PAGES.START_PAGE.DETAILS_1" | translate }}</p>
<p>{{ "INTAKE.IMM0008.PAGES.START_PAGE.DETAILS_2" | translate }}</p>
<h2>
  {{ "INTAKE.IMM0008.PAGES.START_PAGE.SUBHEADING" | translate }}
</h2>
<p>{{ "INTAKE.IMM0008.PAGES.START_PAGE.TEXT" | translate }}</p>
<p
  [innerHTML]="'INTAKE.IMM0008.PAGES.START_PAGE.INFO' | translate"
  class="info"
></p>

<lib-layout-row gap="one-and-half" mt="2">
  <a [routerLink]="['./../../']" category="secondary" role="link">
    {{ "INTAKE.FORM_PAGE_BACK" | translate }}
  </a>
  <a
    [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE2', '../')"
    category="primary"
    role="link"
  >
    {{ "INTAKE.FORM_PAGE_NEXT" | translate }}
  </a>
</lib-layout-row>
