<form [formGroup]="languageDetailsForm" autocomplete="off">
  <fieldset>
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.LEGEND"
          | translate
      }}
    </legend>
    <lib-form-select
      identifier="nativeLanguage"
      data-cy="language-details-native-language"
      required="true"
      [formControl]="languageDetailsForm.controls.nativeLanguage"
      [label]="
        'INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.NATIVE_LANGUAGE.NATIVE_LANGUAGE_LABEL'
          | translate
      "
      [options]="languagesList"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          languageDetailsForm.get('nativeLanguage')?.touched &&
          languageDetailsForm.get('nativeLanguage')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LEGEND" | translate }}
    </legend>
    <lib-form-select
      identifier="language"
      data-cy="language-details-language"
      required="true"
      [formControl]="languageDetailsForm.controls.language"
      [label]="
        'INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL.LANGUAGE_LABEL'
          | translate
      "
      [options]="officialLanguageList"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          languageDetailsForm.get('language')?.touched &&
          languageDetailsForm.get('language')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
  </fieldset>
  <fieldset *ngIf="isBilingualSelected">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LEGEND"
          | translate
      }}
    </legend>
    <lib-form-select
      identifier="preferredLanguage"
      data-cy="language-details-preferred-language"
      [required]="isBilingualSelected"
      [formControl]="languageDetailsForm.controls.preferredLanguage"
      [label]="
        'INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.BILINGUAL_PREFERENCE.LANGUAGE_LABEL'
          | translate
      "
      [options]="preferenceLanguageList"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          languageDetailsForm.get('preferredLanguage')?.touched &&
          languageDetailsForm.get('preferredLanguage')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
  </fieldset>

  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.LANGUAGE_DETAILS.LANGUAGE_TEST.LEGEND' | translate
      "
      firstId="testing-yes"
      secondId="testing-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="testing"
      [formControl]="languageDetailsForm.controls.takenTest"
      [required]="isLanguageTestRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          languageDetailsForm.get('takenTest')?.touched &&
          languageDetailsForm.get('takenTest')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>
</form>
