export enum LobIds {
  // Economic Programs
  AgriFoodPilot = 1,
  AtlanticHighSkilled = 2,
  AtlanticInternationalGraduate = 3,
  AtlanticIntermediateSkilled = 4,
  RuralAndNorthernImmigration = 9,
  ProvincialNomineeProgram = 10,
  SelfEmployedPersonsClass = 11,
  StartUpBusinessClass = 12,
  QuebecSkilledWorkers = 13,
  QuebecSelectedInvestor = 14,
  QuebecEntrepreneur = 15,
  QuebecSelfEmployedPersons = 16,

  // Family Programs
  Spouse = 17,
  CommonLaw = 18,
  ConjugalPartner = 19,
  ParentsGrandparents = 20,
  DependentChild = 21,
  OtherRelative = 22,
  ChildToBeAdoptedInCanada = 23,

  // Refugee Programs
  ProtectedPersonInCanada = 24,
  GovernmentAssistedRefugeesProgram = 53,

  // Other
  HumanitarianAndCompassionate = 25,
  Ukraine = 49,
  UkraineQC = 50,
  FamilyViolence = 51,
  Sudan = 54,
}

export enum ProgramIds {
  Economic = 1,
  Family,
  Refugee,
  Other,
}
