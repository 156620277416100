<mat-sidenav-container class="page-container">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [hasBackButton]="true"
        formNavbarBackButtonTitle="INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.SUBHEADING"
        formNavbarBackButtonLink="FORM_PAGE9"
        formNavbarBackButtonPath="./../../"
        [items]="FormNavbarItems"
        [curr]="selectedPage | number"
        [isLoading]="isLoading"
        (NavButtonClickEvent)="onNavButtonClick($event)"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="emphasis">
      {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
    </h1>
    <h2 class="emphasis">
      {{ "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.SUBHEADING" | translate }}
    </h2>
    <div
      [innerHTML]="'INTAKE.IMM0008.PAGES.ADD_DEPENDANT.DESCRIPTION' | translate"
    ></div>

    <lib-spinner [showSpinner]="isLoading" [fullPage]="false"> </lib-spinner>

    <div tabindex="0" #dependantFormId></div>

    <pra-imm0008-add-dependant-form
      [selectedPage]="selectedPage"
      [ngStyle]="{ display: isLoading ? 'none' : 'block' }"
      #dependantsForm
      [formData]="formData"
    >
    </pra-imm0008-add-dependant-form>

    <lib-layout-row gap="one-and-half" mt="3">
      <!-- Back button -->
      <ng-container *ngIf="selectedPage === pages[0]">
        <a
          [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE9', './../../')"
          category="secondary"
        >
          {{ "INTAKE.FORM_PAGE_BACK" | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="selectedPage !== pages[0]">
        <button (click)="onBackButtonClick()" category="secondary">
          {{ "INTAKE.FORM_PAGE_BACK" | translate }}
        </button>
      </ng-container>

      <!-- Next button -->
      <ng-container *ngIf="selectedPage !== pages[4]">
        <button
          [disabled]="!isCurrentFormValid || isFormSaving"
          (click)="onNextButtonClick()"
        >
          {{ "INTAKE.FORM_PAGE_NEXT" | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="selectedPage === pages[4]">
        <button
          [disabled]="!isCurrentFormValid || isFormSaving"
          (click)="onNextButtonClick()"
        >
          {{ "INTAKE.FORM_PAGE_SAVE" | translate }}
        </button>
      </ng-container>
    </lib-layout-row>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isLoading"
></pra-form-hamburger>
