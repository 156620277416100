<h3>{{ "INTAKE.OTHER.DOCUMENTS.SUBHEADING" | translate }}</h3>
<p>{{ "INTAKE.OTHER.DOCUMENTS.DESC" | translate }}</p>
<form #otherDocumentsContainer>
  <div class="other-documents__input-container">
    <lib-icon-chooser icon="search"></lib-icon-chooser>
    <input
      class="other-documents__input"
      type="text"
      [formControl]="formControl"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      #otherDocumentsInput
      [placeholder]="'INTAKE.OTHER.DOCUMENTS.INPUT_PLACEHOLDER' | translate"
      (click)="handleClick(trigger)"
    />
    <div class="chevron"></div>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="resetInput()">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.text[lang]"
      (onSelectionChange)="addRow(option, $event)"
    >
      {{ option.text[lang] }}
    </mat-option>
  </mat-autocomplete>
</form>
<div class="other-documents-table">
  <table class="table">
    <thead class="other-documents__table-head">
      <tr>
        <th>
          {{
            "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.DOCUMENT_NAME"
              | translate
          }}
        </th>
        <th>
          {{
            "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.ATTACHMENT"
              | translate
          }}
        </th>
        <th *ngIf="user.can('documents:write')">
          {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
        </th>
      </tr>
    </thead>
    <tbody class="other-documents__table-body">
      <ng-container *ngIf="otherDocumentsRows.length > 0; else noDocuments">
        <tr
          *ngFor="let doc of otherDocumentsRows; let indexOfRow = index"
          class="other-documents__table-row"
        >
          <!-- Document Type -->
          <td class="other-documents__name">
            {{ doc.text[lang] }}
            <pra-tool-tip
              *ngIf="toolTipContent.hasOwnProperty(doc.value)"
              [tooltip_id]="doc.value"
              [tooltip_title]="
                toolTipContent[doc.value] + '.TOOLTIP_TITLE' | translate
              "
              [tooltip_body]="
                toolTipContent[doc.value] + '.TOOLTIP_BODY' | translate
              "
            ></pra-tool-tip>
          </td>
          <!-- Attachment -->
          <td class="other-documents__attachment">
            <div
              class="other-documents__divider"
              *ngIf="getFilesByDocumentType(doc.value).length > 0"
            >
              <div>
                <hr />
              </div>
              <h5>Uploaded Files</h5>
            </div>
            <div *ngFor="let document of getFilesByDocumentType(doc.value)">
              <pra-attachment-status
                [state]="
                  document.statusType === 'incomplete' ? 'warning' : 'success'
                "
                [fileName]="document.documentName"
                [fileStatus]="document.statusType"
                [canDelete]="user.can('documents:write')"
                (deleteFile)="
                  openDynamicDeleteModal(
                    'INTAKE.DELETE_MODAL.SUBHEADING',
                    'INTAKE.DELETE_MODAL.DESCRIPTION',
                    removeFile,
                    document.rawDocument,
                    document.documentType
                  )
                "
                (downloadFile)="downloadFile(document.rawDocument)"
              >
              </pra-attachment-status>
            </div>
            <!-- temp attachment when uploading-->
            <div
              *ngFor="
                let temp of tempAttachments[indexOfRow];
                let attachmentIndex = index
              "
            >
              <pra-attachment-status
                [state]="temp.state"
                [fileName]="temp.fileName"
                [canDelete]="user.can('documents:write')"
                (deleteFile)="removeTempFileError(indexOfRow, attachmentIndex)"
                [error]="temp.error"
              >
              </pra-attachment-status>
            </div>
          </td>
          <td
            *ngIf="user.can('documents:write')"
            class="other-documents__action"
          >
            <div class="other-documents__button-container">
              <lib-file-upload
                fileTypes="{{ acceptedFileTypes }}"
                (change)="
                  setTempFileInfoAndUpload($event, indexOfRow, doc.value)
                "
                buttonStyle="fixed-width"
                [disabled]="disableUpload()"
                *ngIf="user.can('documents:write')"
                id="supporting-documents"
              >
                {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
              </lib-file-upload>
              <button
                category="plain"
                color="critical"
                (click)="
                  openDynamicDeleteModal(
                    'INTAKE.DELETE_MODAL.DELETE_ROW_SUBHEADING',
                    'INTAKE.DELETE_MODAL.DELETE_ROW_DESCRIPTION',
                    removeRow,
                    indexOfRow,
                    doc.value
                  )
                "
              >
                <lib-icon-chooser icon="delete"></lib-icon-chooser>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #noDocuments>
        <tr *ngIf="!loadingDocs">
          <td class="other-documents__no-documents" colspan="4">
            {{
              "INTAKE.TABLE_HEADINGS.EMPTY_CELL.NO_DOCUMENTS_UPLOADED"
                | translate
            }}
          </td>
        </tr>
        <tr *ngIf="loadingDocs">
          <td colspan="100%">
            <lib-spinner class="table__spinner" [fullPage]="false">
            </lib-spinner>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
<!-- DELETE CONFIRMATION MODAL -->
<lib-modal
  [id]="deleteModalId"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ deleteHeading | translate }}
    </p>
    <p>
      {{ deleteDescription | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button
      category="primary"
      color="critical"
      (click)="deleteFn(deleteFnParams[0], deleteFnParams[1])"
    >
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
