import { RejectionReason } from "@pr-applicant/app/shared/case-module/case.model";
import { Lob } from "@pr-applicant/app/core/models/lob.model";

export interface ILob {
  programEn: string;
  categoryEn: string;
  programFr: string;
  categoryFr: string;
}

export interface IStatus {
  label: string;
  type: string;
}

export class AppItem {
  appName: string;
  clientName: string;
  clientEmail: string;
  id: number;
  lob: Lob;
  showDisabledViewButton: boolean;
  showViewButton: boolean;
  enableDeleteButton: boolean;
  showDeleteButton: boolean;
  status: IStatus;
  mandatoryDocsSignedByClient: boolean;
  statusId: number;
  submittedAt: string;
  link: string;
  lobId?: number;
  phaseId: number;
  updatedAt?: string;
  createdAt?: string;
  surname?: string;
  uci?: string;
  givenName?: string;
  applicationNumber?: string;
  dob?: string;
  isUrgent?: boolean;
  urgencyRequestDate?: string;
  statusDate: string;
  rejectionReasons?: RejectionReason[];
  showExpiredText?: boolean;
  showNoReturnedAccessSponsorText: boolean;
  showNoReturnedAccessRepresentativeText: boolean;
  showDownloadLink: boolean;
}

export class AppItemForView {
  appName: string;
  clientName: string;
  clientEmail: string;
  id: number;
  lob: Lob;
  showDisabledViewButton: boolean;
  showViewButton: boolean;
  enableDeleteButton: boolean;
  showDeleteButton: boolean;
  status: IStatus;
  mandatoryDocsSignedByClient: boolean;
  statusId: number;
  submittedAt: string;
  action: string;
  categoryName?: string;
  link: string;
  lobId?: number;
  phaseId: number;
  updatedAt?: string;
  createdAt?: string;
  surname?: string;
  uci?: string;
  givenName?: string;
  applicationNumber?: string;
  dob?: string;
  isUrgent?: boolean;
  urgencyRequestDate?: string;
  statusDate: string;
  rejectionReasons?: RejectionReason[];
  showExpiredText?: boolean;
  showNoReturnedAccessSponsorText: boolean;
  showNoReturnedAccessRepresentativeText: boolean;
  showDownloadLink: boolean;

  constructor(appItem: AppItem) {
    this.appName = appItem.appName;
    this.clientName = appItem.clientName;
    this.clientEmail = appItem.clientEmail;
    this.id = appItem.id;
    this.lob = appItem.lob;
    this.action = "";
    this.showDownloadLink = appItem.showDownloadLink;
    this.showDisabledViewButton = appItem.showDisabledViewButton;
    this.showViewButton = appItem.showViewButton;
    this.enableDeleteButton = appItem.enableDeleteButton;
    this.showDeleteButton = appItem.showDeleteButton;
    this.status = appItem.status;
    this.mandatoryDocsSignedByClient = appItem.mandatoryDocsSignedByClient;
    this.statusId = appItem.statusId;
    this.submittedAt = appItem.submittedAt ? appItem.submittedAt : "";
    this.categoryName = "";
    this.link = appItem.link;
    this.lobId = appItem.lobId;
    this.phaseId = appItem.phaseId;
    this.updatedAt = appItem.updatedAt;
    this.createdAt = appItem.createdAt;
    this.surname = appItem.surname;
    this.uci = appItem.uci;
    this.givenName = appItem.givenName;
    this.applicationNumber = appItem.applicationNumber;
    this.dob = appItem.dob;
    this.isUrgent = appItem.isUrgent;
    this.urgencyRequestDate = appItem.urgencyRequestDate;
    this.statusDate = appItem.statusDate;
    this.rejectionReasons = appItem.rejectionReasons;
    this.showExpiredText = appItem.showExpiredText;
    this.showNoReturnedAccessSponsorText =
      appItem.showNoReturnedAccessSponsorText;
    this.showNoReturnedAccessRepresentativeText =
      appItem.showNoReturnedAccessRepresentativeText;
  }
}
