<form autocomplete="off">
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="'INTAKE.IMM5406.FORMS.SECTION_A.RADIO_LABEL' | translate"
      firstId="principalAppYes"
      secondId="principalAppNo"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM5406.FORMS.SECTION_A.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM5406.FORMS.SECTION_A.RADIO_NO' | translate"
      nameVal="radio-principal-applicant"
      [formControl]="primaryApplicantForm.controls.principalApplicant"
    >
    </lib-form-radio>
  </fieldset>

  <fieldset class="fix-spacing">
    <h2 class="emphasis">
      {{ "INTAKE.IMM5406.FORMS.SECTION_A.MAIN_HEADING" | translate }}
    </h2>
  </fieldset>

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5406.FORMS.SECTION_A.APPLICANT_SUBHEADING" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="applicantFullName"
      [formControl]="applicantForm.controls.fullName"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.FULL_NAME_LABEL' | translate"
    >
      <span
        *ngIf="
          applicantForm.get('fullName')?.touched &&
          applicantForm.get('fullName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : FullNameMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="applicantDOB"
      [formControl]="applicantForm.controls.dob"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.DOB_LABEL' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="applicantBirthplace"
      [formControl]="applicantForm.controls.countryOfBirth"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.BIRTHPLACE_LABEL' | translate"
    >
      <span
        *ngIf="
          applicantForm.get('countryOfBirth')?.touched &&
          applicantForm.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : BirthplaceMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="applicantMaritalStatus"
      [formControl]="applicantForm.controls.maritalStatus"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.MARITAL_STATUS_LABEL' | translate
      "
      [options]="principalMaritalStatusOptions"
    ></lib-form-select>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="applicantEmail"
      [formControl]="applicantForm.controls.emailAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.EMAIL_LABEL' | translate"
    >
      <span
        *ngIf="
          applicantForm.get('emailAddress')?.touched &&
          applicantForm.get('emailAddress')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : EmailMaxChars }}
      </span>
      <span
        *ngIf="
          applicantForm.get('emailAddress')?.touched &&
          (applicantForm.get('emailAddress')?.hasError('email') ||
            applicantForm.get('emailAddress')?.hasError('pipe'))
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_EMAIL" | translate }}
      </span>
    </lib-form-input>
    <lib-form-textarea
      [editable]="user.can('documents:write')"
      identifier="applicantAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_LABEL' | translate"
      [formControl]="applicantForm.controls.address"
    >
      <span
        *ngIf="
          applicantForm.get('address')?.touched &&
          applicantForm.get('address')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : AddressMaxChars }}
      </span>
    </lib-form-textarea>
  </fieldset>

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5406.FORMS.SECTION_A.PARTNER_SUBHEADING" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="partnerFullName"
      [formControl]="partnerForm.controls.fullName"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.FULL_NAME_LABEL' | translate"
    >
      <span
        *ngIf="
          partnerForm.get('fullName')?.touched &&
          partnerForm.get('fullName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : FullNameMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="partnerDOB"
      [formControl]="partnerForm.controls.dob"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.DOB_LABEL' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="partnerBirthplace"
      [formControl]="partnerForm.controls.countryOfBirth"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.BIRTHPLACE_LABEL' | translate"
    >
      <span
        *ngIf="
          partnerForm.get('countryOfBirth')?.touched &&
          partnerForm.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : BirthplaceMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="partnerMaritalStatus"
      [formControl]="partnerForm.controls.maritalStatus"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.MARITAL_STATUS_LABEL' | translate
      "
      [options]="partnerMaritalStatusOptions"
    >
    </lib-form-select>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="partnerEmail"
      [formControl]="partnerForm.controls.emailAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.EMAIL_LABEL' | translate"
    >
      <span
        *ngIf="
          partnerForm.get('emailAddress')?.touched &&
          (partnerForm.get('emailAddress')?.hasError('email') ||
            partnerForm.get('emailAddress')?.hasError('pipe'))
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_EMAIL" | translate }}
      </span>
      <span
        *ngIf="
          partnerForm.get('emailAddress')?.touched &&
          partnerForm.get('emailAddress')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : EmailMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-textarea
      [editable]="user.can('documents:write')"
      identifier="partnerAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_LABEL' | translate"
      [formControl]="partnerForm.controls.address"
    >
      <span
        *ngIf="
          partnerForm.get('address')?.touched &&
          partnerForm.get('address')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : AddressMaxChars }}
      </span>
    </lib-form-textarea>
  </fieldset>

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5406.FORMS.SECTION_A.MOTHER_SUBHEADING" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="motherFullName"
      [formControl]="motherForm.controls.fullName"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.FULL_NAME_LABEL' | translate"
    >
      <span
        *ngIf="
          motherForm.get('fullName')?.touched &&
          motherForm.get('fullName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : FullNameMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="motherDOB"
      [formControl]="motherForm.controls.dob"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.DOB_LABEL' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="motherBirthplace"
      [formControl]="motherForm.controls.countryOfBirth"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.BIRTHPLACE_LABEL' | translate"
    >
      <span
        *ngIf="
          motherForm.get('countryOfBirth')?.touched &&
          motherForm.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : BirthplaceMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="motherMaritalStatus"
      [formControl]="motherForm.controls.maritalStatus"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.MARITAL_STATUS_LABEL' | translate
      "
      [options]="otherMaritalStatusOptions"
    ></lib-form-select>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="motherEmail"
      [formControl]="motherForm.controls.emailAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.EMAIL_LABEL' | translate"
    >
      <span
        *ngIf="
          motherForm.get('emailAddress')?.touched &&
          motherForm.get('emailAddress')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : EmailMaxChars }}
      </span>
      <span
        *ngIf="
          motherForm.get('emailAddress')?.touched &&
          (motherForm.get('emailAddress')?.hasError('email') ||
            motherForm.get('emailAddress')?.hasError('pipe'))
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_EMAIL" | translate }}
      </span>
    </lib-form-input>
    <lib-form-textarea
      [editable]="user.can('documents:write')"
      identifier="motherAddress"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_PARENT_LABEL' | translate
      "
      [formControl]="motherForm.controls.address"
    >
      <span
        *ngIf="
          motherForm.get('address')?.touched &&
          motherForm.get('address')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : AddressMaxChars }}
      </span>
    </lib-form-textarea>
  </fieldset>

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5406.FORMS.SECTION_A.FATHER_SUBHEADING" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="fatherFullName"
      [formControl]="fatherForm.controls.fullName"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.FULL_NAME_LABEL' | translate"
    >
      <span
        *ngIf="
          fatherForm.get('fullName')?.touched &&
          fatherForm.get('fullName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : FullNameMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="fatherDOB"
      [formControl]="fatherForm.controls.dob"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.DOB_LABEL' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="fatherBirthplace"
      [formControl]="fatherForm.controls.countryOfBirth"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.BIRTHPLACE_LABEL' | translate"
    >
      <span
        *ngIf="
          fatherForm.get('countryOfBirth')?.touched &&
          fatherForm.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : BirthplaceMaxChars }}
      </span>
    </lib-form-input>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="fatherMaritalStatus"
      [formControl]="fatherForm.controls.maritalStatus"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.MARITAL_STATUS_LABEL' | translate
      "
      [options]="otherMaritalStatusOptions"
    ></lib-form-select>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="fatherEmail"
      [formControl]="fatherForm.controls.emailAddress"
      [label]="'INTAKE.IMM5406.FORMS.SECTION_A.EMAIL_LABEL' | translate"
    >
      <span
        *ngIf="
          fatherForm.get('emailAddress')?.touched &&
          fatherForm.get('emailAddress')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : EmailMaxChars }}
      </span>
      <span
        *ngIf="
          fatherForm.get('emailAddress')?.touched &&
          (fatherForm.get('emailAddress')?.hasError('email') ||
            fatherForm.get('emailAddress')?.hasError('pipe'))
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_EMAIL" | translate }}
      </span>
    </lib-form-input>
    <lib-form-textarea
      [editable]="user.can('documents:write')"
      identifier="fatherAddress"
      [label]="
        'INTAKE.IMM5406.FORMS.SECTION_A.ADDRESS_PARENT_LABEL' | translate
      "
      [formControl]="fatherForm.controls.address"
    >
      <span
        *ngIf="
          fatherForm.get('address')?.touched &&
          fatherForm.get('address')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : AddressMaxChars }}
      </span>
    </lib-form-textarea>
  </fieldset>
</form>
