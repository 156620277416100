import * as fromApp from "../../../../store/app.reducer";

import { ActivatedRoute, Router } from "@angular/router";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertService, ModalService, documentTypes } from "lib";
import { Observable, Subscription } from "rxjs";

import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { CanComponentDeactivate } from "../../../../routing/guards/nav.guard";
import { Case } from "../../../../shared/case-module/case.model";
import { Document } from "../../../../core/models/document.model";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { IMM0008AddressFormComponent } from "../../../forms/IMM0008/address-form/address-form.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import FormNavbarItems from "../imm0008-form-navbar-items.json";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm0008-page4",
  templateUrl: "./page4.component.html",
  styleUrls: [
    "./page4.component.scss",
    "../../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM0008Page4Component
  implements OnInit, CanComponentDeactivate, AfterContentChecked, OnDestroy
{
  public lang: string;
  public navigationModalId = "navigationModal";
  public isFormSaved: boolean = false;
  public isLoading: boolean = true;
  public formData: any;
  public imm0008_version: number;
  public FormNavbarItems = FormNavbarItems;
  mobileQuery: MediaQueryList;

  private formName: string = "contactDetails";
  private caseId: string;
  private caseIMM0008Id?: number;
  private imm0008DocumentTypeId = documentTypes.imm0008.id;

  private subscriptionsList: Subscription[] = [];
  private _mobileQueryListener: () => void;
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private modalService: ModalService,
    private documentService: DocumentService,
    private store: Store<fromApp.State>,
    private alertService: AlertService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  @ViewChild("addressForm", { static: false })
  addressForm: IMM0008AddressFormComponent;

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();

    this.getStoredCaseInfo();
  }

  ngOnDestroy(): void {
    this.subscriptionsList.forEach((subsc) => subsc.unsubscribe());
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
    // call or add here your code
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // check if form has been touched or saved
    if (this.isFormDirty && !this.isFormSaved) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  public get isFormDirty(): boolean {
    return this.addressForm?.isFormDirty;
  }

  public get nextButtonKey(): string {
    if (!this.user.can("documents:write")) {
      return "INTAKE.FORM_PAGE_NEXT";
    }
    return "INTAKE.FORM_PAGE_SAVE";
  }

  public async saveForm() {
    const nextPage = this.routeLocalizer.get(this.lang, "FORM_PAGE5", "../");

    if (!this.isFormDirty) {
      this.router.navigate([nextPage], { relativeTo: this.route });
    } else if (
      this.isFormDirty &&
      this.caseId &&
      this.caseIMM0008Id &&
      this.formValue
    ) {
      try {
        const response =
          await this.documentService.updateFormByDocumentIdAndPage(
            this.caseId,
            this.caseIMM0008Id,
            this.formName,
            this.formValue,
            this.imm0008_version
          );
        if (response && response.hasApiError) {
          const errorStatus = response.error.response.status;
          if (errorStatus === 409) {
            this.alertService.danger(
              this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
            );
          } else {
            this.alertService.danger(this.alertTechnicalError);
          }
        } else {
          this.isFormSaved = true;
          this.router.navigate([nextPage], { relativeTo: this.route });
        }
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      // dont save (form hasnt been changed) and navigate
      this.router.navigate([nextPage], { relativeTo: this.route });
    }
  }

  private getStoredCaseInfo(): void {
    this.subscriptionsList.push(
      this.store
        .select("selectedCase")
        .pipe(filter((storedCase) => storedCase.id !== ""))
        .subscribe((storedCase: Case) => {
          this.caseId = storedCase.id;
          const imm8Document = storedCase.documents?.find(
            (doc: Document) => doc.documentTypeId === this.imm0008DocumentTypeId
          );
          this.caseIMM0008Id = imm8Document?.id;
          this.getFormData();
        })
    );
  }

  private async getFormData(): Promise<any> {
    if (!this.caseId || !this.caseIMM0008Id) {
      return;
    }

    try {
      this.formData = await this.documentService.getFormByDocumentIdAndPage(
        this.caseId,
        this.caseIMM0008Id,
        this.formName
      );
      this.imm0008_version = this.formData.version;
      this.isLoading = false;
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get formValue() {
    return this.addressForm?.preparedFormValue;
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }
}
