<h1 class="emphasis">{{ "PHOTOS.PAGE_HEADING" | translate : nameParam }}</h1>
<p>{{ "PHOTOS.INTRO_TEXT" | translate : nameParam }}</p>

<div *ngIf="rejectionReasons.length > 0" class="rejection-notice">
  <b>{{ "PHOTOS.REJECTION_NOTICE" | translate : nameParam }}</b>
  <ul>
    <li *ngFor="let reason of rejectionReasons">
      {{ reason }}
    </li>
  </ul>
</div>

<pra-photo-requirements [nameParam]="this.nameParam"></pra-photo-requirements>
<pra-photo-upload [nameParam]="this.nameParam"></pra-photo-upload>
