import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "matchingValue" })
export class MatchingValuePipe implements PipeTransform {
  transform(value: any, array: any[], valueKey: string, lang: string): any {
    const found = array.find(function (item) {
      return (
        item[valueKey] === value ||
        item[valueKey] === Number(value) ||
        item[valueKey] === String(value)
      );
    });
    return found ? found.text[lang] : "";
  }
}
