import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import {
  FormGroup,
  Validators,
  FormControl,
  UntypedFormBuilder,
} from "@angular/forms";
import { ValidationService, FormSelectOption } from "lib";
import { Subscription } from "rxjs";
import { LovService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-national-identity-form",
  templateUrl: "./national-identity-form.component.html",
  styleUrls: ["./national-identity-form.component.scss"],
})
export class IMM0008NationalIdentityFormComponent
  implements OnInit, OnChanges, AfterContentChecked, OnDestroy
{
  @Input() formData: any;

  public formValues: any;
  public lang: string;
  public maxNICNumberChars = { max: "20" };
  public case: any;
  public countryList: FormSelectOption[];

  private subscriptions: Subscription[] = [];

  public nationalIdentityForm: FormGroup = this.fb.group({
    nationalIdentityDocument: [null, Validators.required],
    nationalIdentityNumber: new FormControl(null, {
      validators: [],
    }),
    countryOfIssue: [null],
    issueDate: new FormControl(null, {
      validators: [],
    }),
    expiryDate: new FormControl(null, {
      validators: [],
    }),
  });

  private nationalIdentityNumberValidation = [
    Validators.required,
    Validators.maxLength(20),
    this.validationService.validatorAlphaNumeric,
  ];
  private nationalIdentityCountryOfIssueValidation = [Validators.required];
  private nationalIdentityIssueDateValidation = [
    Validators.required,
    this.validationService.validatorDateFormat,
    this.validationService.validatorDate,
    this.validationService.validatorDatePast,
  ];
  private nationalIdentityExpiryDateValidation = [
    Validators.required,
    this.validationService.validatorDateFormat,
    this.validationService.validatorDate,
    this.validationService.validatorDateFuture,
  ];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.watchNICValue();
    this.watchIssueDate();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => sub?.unsubscribe());
  }

  ngOnChanges(): void {
    this.countryList = this.lovService.lovs.countryOfIssue;
    this.formValues = this.formData?.form;
    if (this.formValues) {
      this.setFormDataIfSaved();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  public get isNationalIdentityRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.nationalIdentityForm.controls.nationalIdentityDocument
    );
  }

  public getFormatedFormValues(): any {
    const formValues = this.nationalIdentityForm?.value;
    if (formValues) {
      return {
        ...this.nationalIdentityForm.value,
        nationalIdentityDocument:
          formValues?.nationalIdentityDocument !== null
            ? formValues?.nationalIdentityDocument === true
              ? true
              : false
            : null,
      };
    }
    return {};
  }

  public setTouched(): void {
    this.nationalIdentityForm.markAsTouched();
  }

  public get showNationalIdentityForm() {
    const radioField = this.nationalIdentityForm.get(
      "nationalIdentityDocument"
    );
    return radioField?.value === true;
  }

  public get maxNICNumberCharsValidator(): object {
    return {
      characters: this.maxNICNumberChars,
    };
  }

  public get issueDateInvalidError() {
    const field = this.nationalIdentityForm.get("issueDate");
    if (
      field?.touched &&
      field?.hasError("dateFormat") &&
      !field?.hasError("required")
    ) {
      return true;
    }
    return false;
  }

  public get issueDatePastError() {
    const field = this.nationalIdentityForm.get("issueDate");
    if (
      field?.touched &&
      field?.hasError("pastDate") &&
      !(field?.hasError("dateFormat") && !field?.hasError("required"))
    ) {
      return true;
    }
    return false;
  }

  public get expiryDateInvalidError() {
    const field = this.nationalIdentityForm.get("expiryDate");
    if (
      field?.touched &&
      field?.hasError("dateFormat") &&
      !field?.hasError("required")
    ) {
      return true;
    }
    return false;
  }

  public get verifyExpiryAndIssueDate() {
    const expiryDateField = this.nationalIdentityForm.get("expiryDate");
    const issueDateField = this.nationalIdentityForm.get("issueDate");
    const issueDateValue = this.nationalIdentityForm.get("issueDate")?.value;
    const expiryDateValue = this.nationalIdentityForm.get("expiryDate")?.value;

    const issueDate = new Date(issueDateValue);
    const expiryDate = new Date(expiryDateValue);

    //  issue & expiry dates are not required, but validations depend on the values of the other
    //  also only validate if the fields have values
    if (
      expiryDateField?.touched &&
      issueDateValue !== null &&
      issueDateValue !== "" &&
      expiryDateValue !== null &&
      expiryDateValue !== "" &&
      issueDate >= expiryDate &&
      !expiryDateField?.hasError("required") &&
      !expiryDateField?.hasError("dateFormat")
    ) {
      this.nationalIdentityForm
        ?.get("expiryDate")
        ?.setErrors({ incorrect: true });

      return true;
    } else {
      this.nationalIdentityForm
        ?.get("expiryDate")
        ?.setErrors({ incorrect: false });
      this.nationalIdentityForm?.get("expiryDate")?.updateValueAndValidity();
      return false;
    }
  }

  private setFormDataIfSaved(): any {
    if (this.formValues?.nationalIdentityDocument === null) {
      this.nationalIdentityForm.controls.nationalIdentityDocument.setValue(
        null
      );
    } else {
      this.nationalIdentityForm.controls.nationalIdentityDocument.setValue(
        this.formValues?.nationalIdentityDocument
      );
      if (this.formValues?.nationalIdentityDocument === true) {
        if (this.nationalIdentityForm.controls.nationalIdentityNumber) {
          this.nationalIdentityForm.controls.nationalIdentityNumber.setValue(
            this.formValues?.nationalIdentityNumber
          );
        }
        if (this.nationalIdentityForm.controls.countryOfIssue) {
          this.nationalIdentityForm.controls.countryOfIssue.setValue(
            this.formValues?.countryOfIssue
          );
        }
        if (this.nationalIdentityForm.controls.issueDate) {
          this.nationalIdentityForm.controls.issueDate.setValue(
            this.formValues?.issueDate
          );
        }
        if (this.nationalIdentityForm.controls.expiryDate) {
          this.nationalIdentityForm.controls.expiryDate.setValue(
            this.formValues?.expiryDate
          );
        }
        this.setValidatorsForNICChecked();
      } else if (this.formValues?.nationalIdentityDocument === false) {
        this.clearFieldData();
      }
    }
  }

  private setValidatorsForNICChecked(): void {
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.setValidators(
      this.nationalIdentityNumberValidation
    );
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.countryOfIssue?.setValidators(
      this.nationalIdentityCountryOfIssueValidation
    );
    this.nationalIdentityForm?.controls?.countryOfIssue?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.issueDate?.setValidators(
      this.nationalIdentityIssueDateValidation
    );
    this.nationalIdentityForm?.controls?.issueDate?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.expiryDate?.setValidators(
      this.nationalIdentityExpiryDateValidation
    );
    this.nationalIdentityForm?.controls?.expiryDate?.updateValueAndValidity();
  }

  private clearFieldData(): void {
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.setValue(null);
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.reset();
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.clearValidators();
    this.nationalIdentityForm?.controls?.nationalIdentityNumber?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.countryOfIssue?.setValue(null);
    this.nationalIdentityForm?.controls?.countryOfIssue?.reset();
    this.nationalIdentityForm?.controls?.countryOfIssue?.clearValidators();
    this.nationalIdentityForm?.controls?.countryOfIssue?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.issueDate?.setValue(null);
    this.nationalIdentityForm?.controls?.issueDate?.reset();
    this.nationalIdentityForm?.controls?.issueDate?.clearValidators();
    this.nationalIdentityForm?.controls?.issueDate?.updateValueAndValidity();

    this.nationalIdentityForm?.controls?.expiryDate?.setValue(null);
    this.nationalIdentityForm?.controls?.expiryDate?.reset();
    this.nationalIdentityForm?.controls?.expiryDate?.clearValidators();
    this.nationalIdentityForm?.controls?.expiryDate?.updateValueAndValidity();
  }

  private watchNICValue(): void {
    this.subscriptions.push(
      this.nationalIdentityForm.controls.nationalIdentityDocument.valueChanges.subscribe(
        (value) => {
          //  if true, set validators for the fields
          if (value === true) {
            this.setValidatorsForNICChecked();
          }
          //  if false, clear validators
          else {
            this.clearFieldData();
          }
        }
      )
    );
  }

  //  update expiry date validity on issue date change
  //  this will ensure errors are shown for expiry field if it's before issue date
  private watchIssueDate(): void {
    this.subscriptions.push(
      this.nationalIdentityForm.controls.issueDate.valueChanges.subscribe(
        () => {
          this.nationalIdentityForm?.controls?.expiryDate?.updateValueAndValidity();
        }
      )
    );
  }
}
