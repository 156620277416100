<lib-copy-block
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.PAGES.START.INTRO_HEADING_1' | translate"
  [body]="'INTAKE.IMM5669.PAGES.START.INTRO_COPY_1' | translate"
></lib-copy-block>
<lib-copy-block
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.PAGES.START.INTRO_HEADING_2' | translate"
  [body]="'INTAKE.IMM5669.PAGES.START.INTRO_COPY_2' | translate"
></lib-copy-block>
<lib-information-box [config]="informationBoxConfig">
  <p class="information-box-text">
    {{ "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX" | translate }}
    <a
      [routerLink]="
        routeLocalizer.getNestedPath(
          lang,
          ['INTAKE', 'PROFILE'],
          '../../../../'
        ) + caseId
      "
      >{{
        "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX_LINK" | translate
      }}</a
    >
  </p>
</lib-information-box>

<pra-family-table [familyMembers]="forms"></pra-family-table>
