import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { casePhases, ValidationService } from "lib";
import * as AuthActions from "../../store/auth.actions";
import * as fromApp from "../../../../store/app.reducer";
import { merge, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { environment } from "@pr-applicant/environments/environment";

@Component({
  selector: "pra-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  @Input() isLoading: boolean;

  public email: string | null | undefined;
  public displayName: string | null | undefined;
  public loggedIn = false;
  public case: any;
  public isProduction = environment.env === "prod";

  constructor(
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private validationService: ValidationService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      merge(
        this.store.select("auth"),
        this.store.select("selectedCase")
      ).subscribe((res) => {
        if ("uci" in res && "surname" in res) {
          // CaseState from store.select('selectedCase')
          if (res.id) {
            this.case = res;
          } else {
            this.case = undefined;
          }
        } else {
          // from store.select('auth')
          this.email = (res as any)?.user?.username;
          this.loggedIn = !!res?.user;
        }
        this.updateSignedInName();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private updateSignedInName(): void {
    const caseExists = !!this.case?.id;
    if (caseExists && this.loggedIn) {
      if (this.case.phaseId === casePhases.confirmation.id) {
        // for pr confirmation display their full name and uci
        const fullName =
          this.case?.givenName !== null
            ? `${this.case.givenName} ${this.case.surname}`
            : this.case.surname;
        this.displayName = this.getTranslatedDisplayName(
          fullName,
          this.case.uci
        );
      } else if (this.case.phaseId === 1) {
        this.updateDisplayNameByEmail(this.email || "");
      }
    } else {
      this.updateDisplayNameByEmail(this.email || "");
    }
  }

  public logout(): void {
    this.store.dispatch(AuthActions.StartLogout());
  }

  private getTranslatedDisplayName(signedInName: string, uci: string): string {
    let translatedUCI = "";
    this.translate
      .get("LOGOUT.SIGNED_IN", { signedInName, uci })
      .subscribe((res) => {
        translatedUCI = res;
      });
    return translatedUCI;
  }

  private updateDisplayNameByEmail(email: string): void {
    this.translate
      .get("LOGOUT.SIGNED_IN_FIRST_TIME", { email })
      .pipe(first())
      .subscribe((res) => {
        this.displayName = res;
      });
  }

  public logRole() {
    console.log(this.userService.getPermissions());
  }
}
