<!-- Page info -->
<div class="header">
  <h1>
    {{ (isClientUser ? "SIGNUP.TITLE" : "SIGNUP.REP_TITLE") | translate }}
  </h1>
  <h2>
    {{ "SIGNUP.FORM_HEADER" | translate }}
  </h2>
  <p class="header__description">
    {{
      (isClientUser ? "SIGNUP.FORM_DESCRIPTION" : "SIGNUP.REP_FORM_DESCRIPTION")
        | translate
    }}
  </p>
</div>
<section class="form__container">
  <!-- Email only for now -->
  <form [formGroup]="signUpForm">
    <fieldset>
      <legend>
        {{ "SIGNUP.FORM.FIELDS.ACCOUNT_INFO" | translate }}
      </legend>
      <lib-form-input
        identifier="username"
        type="email"
        [formControl]="signUpForm.controls.email"
        [label]="'SIGNUP.FORM.FIELDS.EMAIL' | translate"
        required="true"
      >
        <span
          *ngIf="
            signUpForm.controls.email.touched &&
            signUpForm.controls.email.errors?.required
          "
        >
          {{ "LOGIN.ERRORS.REQUIRED_EMAIL" | translate }}
        </span>
        <span
          *ngIf="
            signUpForm.controls.email.touched &&
            signUpForm.controls.email.errors?.email
          "
        >
          {{ "LOGIN.ERRORS.INVALID_EMAIL" | translate }}
        </span>
        <span
          *ngIf="signUpForm.controls.email.touched && this.emailAlreadyExists"
        >
          {{ "SIGNUP.ERRORS.EMAIL_EXISTS" | translate }}
        </span>
      </lib-form-input>
    </fieldset>
  </form>

  <!-- Passwords with requirements (resuable component) -->
  <div class="form__passwords">
    <pra-auth-reset
      class="form__passwords"
      #passwordsForm
      [isFirstLogin]="isSignUp"
      [isSignUp]="isSignUp"
    ></pra-auth-reset>
  </div>

  <!-- Checkbox for terms & conditions -->
  <div class="form__terms checkbox-control">
    <input
      value="true"
      id="terms"
      name="terms"
      [formControl]="signUpForm.controls.terms"
      type="checkbox"
    />
    <label
      [innerHTML]="'SIGNUP.FORM.FIELDS.TERMS' | translate"
      for="terms"
    ></label>
  </div>
  <!-- Create account -->
  <lib-layout-row mt="2">
    <button
      [disabled]="
        !signUpForm.valid || !this.passwordsForm.resetPasswordForm.valid
      "
      class="form__button btn btn-primary"
      (click)="onSubmitNewAccount()"
    >
      {{ "SIGNUP.FORM.SUBMIT" | translate }}
    </button>
  </lib-layout-row>

  <div *ngIf="!isClientUser" class="rep-footer">
    {{ "SIGNUP.REP_FOOTER" | translate }}
  </div>
</section>
