import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import awsconfig from "../.../../../../../../../../config/aws-exports";
import { from, Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "@pr-applicant/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private baseUrl = `${awsconfig.aws_cloud_logic_custom[0].endpoint}/${environment.API_VERSION}`;

  constructor(private awsService: AWSService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const needAuthHeader = request.url.includes(this.baseUrl);
    if (needAuthHeader) {
      return from(this.awsService.getToken()).pipe(
        catchError((err) => of("")),
        switchMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: token,
              },
            });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
