<lib-modal [id]="modalId" [title]="title">
  <ng-container modalBody>
    <p>
      {{ description }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="confirmInvite(true)">
      {{ sendText }}
    </button>
    <button category="secondary" (click)="confirmInvite(false)">
      {{ cancelText }}
    </button>
  </ng-container>
</lib-modal>
