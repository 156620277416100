import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "pra-reset-password-page",
  templateUrl: "./reset-password-page.component.html",
  styleUrls: ["./reset-password-page.component.scss"],
})
export class ResetPasswordPageComponent implements OnInit {
  public emailFromUrl: string;
  public currentLang: string;
  public isFirstLogin = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("RESET.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    /* If email/usernameID doesnt exist in the link, route them back to the password recovery page or login page */
    this.route.queryParams.subscribe((params) => {
      this.emailFromUrl = params.email;
    });

    if (!this.authService.tempForgotPasswordUserEmail) {
      this.routeLocalizer.goTo(this.currentLang, "LOGIN");
    }
  }
}
