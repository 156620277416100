<div *ngIf="applicationMode !== 'outage'">
  <pra-ng-idle-logout></pra-ng-idle-logout>

  <lib-navigation-warning
    [title]="'INTAKE.NAVIGATION_MODAL.TITLE' | translate"
    [subheading]="'INTAKE.NAVIGATION_MODAL.SUBHEADING' | translate"
    [description]="'INTAKE.NAVIGATION_MODAL.DESCRIPTION' | translate"
    [leaveBtn]="'INTAKE.NAVIGATION_MODAL.LEAVE_ACTION' | translate"
    [stayBtn]="'INTAKE.NAVIGATION_MODAL.STAY_ACTION' | translate"
    [enabled]="isAuthenticated && !isReloading"
  ></lib-navigation-warning>

  <header>
    <button class="skip-link" (click)="skipToMainContent()">
      {{ "HEADER.SKIP_LINK" | translate }}
    </button>
    <pra-header></pra-header>
    <pra-navbar *ngIf="displayNavbar"></pra-navbar>
    <pra-logout *ngIf="isAuthenticated"></pra-logout>
  </header>

  <lib-alert (libClickOutside)="forceCloseAlert()"></lib-alert>

  <main #mainContent tabindex="-1" class="app__container">
    <lib-spinner [showSpinner]="loading"> </lib-spinner>
    <!-- handles all localized (en/fr) routes -->
    <lib-banner-box
      [title]="bannerBoxHeading | translate"
      [type]="bannerBoxType"
      *ngIf="!loading && applicationMode === 'banner'"
    >
      <div [innerHTML]="bannerBoxBody"></div>
    </lib-banner-box>
    <div [ngStyle]="{ display: loading ? 'none' : 'block' }">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer>
    <pra-footer></pra-footer>
  </footer>
</div>
<div *ngIf="applicationMode === 'outage'">
  <lib-maintenance></lib-maintenance>
</div>
<lib-modal [id]="reloadModalId" [title]="'RELOAD.MODAL_TITLE' | translate">
  <ng-container modalBody>
    <p>
      {{ "RELOAD.MODAL_BODY" | translate }}
    </p>
    <div role="timer" class="modal-timer">{{ countdownRelodLabel }}</div>
    <p>{{ "RELOAD.MODAL_BODY_ACTION" | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button class="btn btn-primary-alt" (click)="reloadPage()">
      {{ "RELOAD.MODAL_BUTTON" | translate }}
    </button>
  </ng-container>
</lib-modal>
