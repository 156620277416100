import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CaseStatusBoxType } from "lib";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import * as fromApp from "../../store/app.reducer";

@Component({
  selector: "pra-confirmation-landing-page",
  templateUrl: "./confirmation-landing-page.component.html",
  styleUrls: ["./confirmation-landing-page.component.scss"],
})
export class ConfirmationLandingPageComponent implements OnInit {
  public status: string;
  public statusBoxType: CaseStatusBoxType;
  public statusLabel: string;
  public actionLabel: string;
  case: any;
  currentLang: string;

  constructor(
    private route: ActivatedRoute,
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("HOME.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    this.route.data.subscribe((data) => {
      this.case = data.case;
    });

    this.store.select("selectedCase").subscribe((caseData) => {
      this.case = caseData;
      this.getApplicationStatus();
    });
  }

  public get headingParams(): object {
    const displayName = this.case.givenName
      ? `${this.case.givenName} ${this.case.surname}`
      : `${this.case.surname}`;
    return {
      name: displayName,
      uci: this.case.uci,
    };
  }

  private getApplicationStatus(): void {
    const statusID = this.case.caseStatusId;
    if (statusID === 1) {
      this.status = "INITIATED";
      this.statusBoxType = "edit";
    } else if (statusID === 2) {
      this.status = "REVIEW";
      this.statusBoxType = "wait";
    } else if (statusID === 3) {
      this.status = "REVISION";
      this.statusBoxType = "problem";
    } else if (statusID === 4) {
      this.status = "ACCEPTED";
      this.statusBoxType = "success";
    } else if (statusID === 5) {
      this.status = "REJECTED";
      this.statusBoxType = "problem";
    }
    this.actionLabel = `HOME.STATUS_BAR.${this.status}.ACTION`;
    this.statusLabel = `HOME.STATUS_BAR.${this.status}.STATUS`;
  }
}
