<div class="sponsor-profile">
  <lib-layout-row>
    <header>
      <h1>
        {{ "PSR.SPONSOR_PROFILE_PAGE.PAGE_HEADING" | translate }}
      </h1>
      <p>
        {{ "PSR.SPONSOR_PROFILE_PAGE.FORM_DESCRIPTION" | translate }}
      </p>
    </header>
  </lib-layout-row>
  <lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
  <form
    focusInvalidInput
    [formGroup]="sponsorProfileForm"
    autocomplete="off"
    (submit)="sponsorProfileFormSubmit($event)"
    *ngIf="!isProcessing"
  >
    <fieldset>
      <lib-form-input
        identifier="familyName"
        [formControl]="sponsorProfileForm.controls.familyName"
        [label]="'PSR.SPONSOR_PROFILE_PAGE.FORM.FAMILY_NAME' | translate"
        required="true"
      >
        <span
          *ngIf="
            sponsorProfileForm.controls?.familyName.touched &&
            sponsorProfileForm.controls?.familyName.hasError('required')
          "
          >{{ "PSR.MESSAGES.REQUIRED" | translate }}
        </span>
      </lib-form-input>
      <lib-form-input
        identifier="givenName"
        [formControl]="sponsorProfileForm.controls.givenName"
        [label]="'PSR.SPONSOR_PROFILE_PAGE.FORM.GIVEN_NAME' | translate"
      >
      </lib-form-input>
      <pra-form-buttons [form]="sponsorProfileForm">
        <div class="sponsor-profile__buttons">
          <button type="submit" category="primary" role="link">
            {{ "PSR.SPONSOR_PROFILE_PAGE.FORM.SUBMIT" | translate }}
          </button>
        </div>
      </pra-form-buttons>
    </fieldset>
  </form>
</div>
