import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { DocumentService } from "@pr-applicant/app/shared/services/document/document.service";
import { Console } from "console";

@Injectable({
  providedIn: "root",
})
export class CaseDocumentsResolver implements Resolve<any> {
  caseDocuments: any;

  constructor(private documentService: DocumentService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const caseId = route.params.caseId;
    if (caseId) {
      try {
        this.caseDocuments = await this.documentService.getDocumentByCaseId(
          caseId
        );
      } catch (error) {
        throw error;
      }
    }

    return this.caseDocuments;
  }
}
