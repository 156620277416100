<h2 class="emphasis">{{ "PHOTOS.UPLOAD_SECTION_HEADING" | translate }}</h2>
<p [innerHTML]="'PHOTOS.UPLOAD_SECTION_TEXT' | translate : nameParam"></p>

<h3 class="photo-type-heading">{{ "PHOTOS.PHOTO_TYPE_HEADER" | translate }}</h3>
<p class="photo-indication-legend">
  {{ "PHOTOS.PHOTO_TYPE_LEGEND" | translate }}
</p>
<lib-form-radio
  [editable]="user.can('documents:write')"
  class="photo-type-options"
  [options]="tempOptionsList"
  nameVal="photo-indication"
  [formControl]="photoTypeControl"
></lib-form-radio>
<br />
<lib-file-upload
  fileTypes="{{ acceptedFileTypes }}"
  (change)="selectFile($event)"
  [disabled]="disableReplaceBtn || photoisBeingValidated"
  >{{ buttonTextKey | translate }}</lib-file-upload
>
<ngx-photo-editor
  [imageUrl]="templatePhotoLink"
  [aspectRatio]="7 / 9"
  [preSetCropperCoordinatesBias]="templateCoordinates"
  (modalCloseCancel)="modalCloseCancel($event)"
  (cropperCoordinatesBias)="setCoordinatesBias($event)"
></ngx-photo-editor>
<p *ngIf="typeError" class="upload-error">{{ typeError }}</p>
<p *ngIf="sizeError" class="upload-error">{{ sizeError }}</p>

<div class="photo-upload-container" *ngIf="showPhotoContent">
  <div class="photo-upload-header">
    {{ "PHOTOS.FILE_TABLE_HEADING" | translate }}
  </div>
  <div class="photo-upload-content">
    <div *ngIf="photoisBeingValidated">
      <span
        class="photo-validation-spinner-text"
        *ngIf="photoisBeingValidated"
        aria-live="assertive"
        >{{ "PHOTOS.PHOTO_VALIDATION_SPINNER_TEXT" | translate }}</span
      >
      <div class="spinner" *ngIf="photoisBeingValidated">
        <lib-spinner
          [showSpinner]="photoisBeingValidated"
          [fullPage]="false"
        ></lib-spinner>
      </div>
    </div>

    <div *ngIf="!selectedPhoto.url && showPreviewIMG" class="no-photo">
      {{ "PHOTOS.NO_FILE" | translate }}
    </div>
    <div *ngIf="!showPreviewIMG">
      <span class="photo_edit_description" *ngIf="!photoisBeingValidated">{{
        "PHOTOS.PHOTO_EDIT_DESCRIPTION" | translate
      }}</span>
      <lib-image-preview
        *ngIf="!photoisBeingValidated"
        [coordinates]="selectedPhoto.coordinates"
        [imageSrc]="selectedPhoto.url"
        [imageAlt]="'PHOTOS.PREVIEW_ALT' | translate"
        canvasId="croppedPreview"
        sourceImgId="croppedImg"
      ></lib-image-preview>
      <button
        *ngIf="!photoisBeingValidated"
        class="btn btn-secondary-editImgButton"
        [disabled]="disableReplaceBtn"
        (click)="editPhoto(false)"
        id="editImgButton1"
      >
        {{ "PHOTOS.EDIT" | translate }}
      </button>
    </div>

    <div
      *ngIf="showPreviewIMG"
      [ngClass]="{ 'rejected-photo-preview': rejectionReasons.length > 0 }"
    >
      <span
        class="photo-validation-spinner-text"
        *ngIf="photoisBeingValidated"
        >{{ "PHOTOS.PHOTO_VALIDATION_SPINNER_TEXT" | translate }}</span
      >
      <div class="spinner" *ngIf="photoisBeingValidated">
        <lib-spinner
          [showSpinner]="photoisBeingValidated"
          [fullPage]="false"
        ></lib-spinner>
      </div>
      <span *ngIf="!photoisBeingValidated">{{
        "PHOTOS.PHOTO_EDIT_DESCRIPTION" | translate
      }}</span>
      <div *ngIf="!photoisBeingValidated" class="rejection-reasons">
        <lib-image-preview
          [coordinates]="selectedPhoto.coordinates"
          [imageSrc]="selectedPhoto.url"
          [imageAlt]="'PHOTOS.PREVIEW_ALT' | translate"
          canvasId="previewPhoto"
          sourceImgId="previewSource"
        ></lib-image-preview>
        <div *ngIf="rejectionReasons.length > 0">
          <p id="rejection-list-title">
            {{ "PHOTOS.REJECTED_PHOTO_LIST_NAME" | translate }}
          </p>
          <ul aria-labelledby="rejection-list-title">
            <li *ngFor="let reason of rejectionReasons">
              {{ reason }}
            </li>
          </ul>
          <p>{{ "PHOTOS.REJECTED_PHOTO_INSTRUCTION" | translate }}</p>
        </div>
      </div>
      <button
        *ngIf="!photoisBeingValidated"
        class="btn btn-secondary-editImgButton"
        [disabled]="disableReplaceBtn"
        (click)="editPhoto(true)"
        id="editImgButton2"
      >
        {{ "PHOTOS.EDIT" | translate }}
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="!allValidationPassed && !photoisBeingValidated"
  class="validation-feedback-warning"
  id="warning_label"
  aria-live="assertive"
  [tabindex]="focusSet"
>
  <img
    class="alert-icon"
    src="../../../../assets/images/icons/triangle-warning.svg"
    aria-hidden="true"
    alt=""
  />
  <span [innerHTML]="'PHOTOS.PHOTO_VALIDATION_FEEDBACK_HEADER' | translate">
  </span>
  <ul class="feedback-list">
    <li
      *ngIf="!validationFeedback?.exposureApproved"
      [innerHTML]="'PHOTOS.PHOTO_VALIDATION_FEEDBACK_BRIGHTNESS' | translate"
    ></li>
    <li
      *ngIf="!validationFeedback?.backgroundApproved"
      [innerHTML]="'PHOTOS.PHOTO_VALIDATION_FEEDBACK_BACKGROUND' | translate"
    ></li>
    <li
      *ngIf="!validationFeedback?.pictureSizeApproved"
      [innerHTML]="'PHOTOS.PHOTO_VALIDATION_FEEDBACK_PIXEL_RANGE' | translate"
    ></li>
  </ul>
  <span class="feedback-footer">{{
    "PHOTOS.PHOTO_VALIDATION_FEEDBACK_FOOTER" | translate
  }}</span>
</div>

<div class="self-declare">
  <h3 class="self-declare__heading">
    {{ "PHOTOS.SELF_DECLARATION" | translate }}
  </h3>
  <div class="checkbox-control">
    <input
      [checked]="selfDeclaredPhoto"
      [disabled]="photoisBeingValidated || showPreviewIMG"
      (change)="selfDeclared()"
      id="declare"
      type="checkbox"
    />
    <label
      [innerHTML]="'PHOTOS.DECLARATION_LABEL' | translate : nameParam"
      for="declare"
    ></label>
  </div>
</div>

<div class="self-declare" *ngIf="selfDeclaredPhoto && !allValidationPassed">
  <h3 class="self-declare__heading">
    {{ "PHOTOS.PHOTO_VALIDATION_ACKNOWLEDGEMENT_HEADER" | translate }}
  </h3>
  <div class="checkbox-control">
    <input
      [checked]="validationAcknowledgment"
      [disabled]="photoisBeingValidated || showPreviewIMG"
      (change)="photoValidationAcknowledgement()"
      id="acknowledgement"
      type="checkbox"
    />
    <label
      [innerHTML]="
        'PHOTOS.PHOTO_VALIDATION_ACKNOWLEDGEMENT_LABEL' | translate : nameParam
      "
      for="acknowledgement"
    ></label>
  </div>
</div>

<div class="form-actions">
  <a [routerLink]="['../']" category="secondary">
    {{ "PHOTOS.BACK_LINK" | translate }}
  </a>
  <button
    type="button"
    (click)="onSubmit()"
    [disabled]="enableSubmitButton"
    [tabindex]="!showPreviewIMG ? '0' : '-1'"
  >
    {{ "PHOTOS.SUBMIT_BUTTON" | translate }}
  </button>
</div>
