import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";

@Injectable({ providedIn: "root" })
export class PRRenewalGuard implements CanActivate {
  private currentLang: string;
  private intakeCasesTotal = 0;

  constructor() {}

  canActivate(): any {
    return true;
  }
}
