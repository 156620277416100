import { FormSelectOption } from "lib";

export enum PRC_PRTD_SITUATIONS_CODES {
  PRIntake = "INTAKE",
  PRRenewal = "RENEWAL",
}

export enum INSIDE_OUTSIDE_CANADA_SITUATION_CODES {
  InCanada = "IN_CANADA",
  OutsideOfCanada = "OUTSIDE_OF_CANADA",
}

export const PRA_PRC_PRTD_SITUATION_OPTIONS: FormSelectOption[] = [
  {
    value: PRC_PRTD_SITUATIONS_CODES.PRIntake,
    text: {
      en: "Permanent Residence, including Refugee Resettlement",
      fr: "Résidence permanente, y compris la réinstallation des réfugiés",
    },
  },
  {
    value: PRC_PRTD_SITUATIONS_CODES.PRRenewal,
    text: {
      en: "PR Card Renewal and PR Travel Document",
      fr: "Enouvellement de carte RP/titre de voyage RP",
    },
  },
];
export const INSIDE_OUTSIDE_CANADA_OPTIONS: FormSelectOption[] = [
  {
    value: INSIDE_OUTSIDE_CANADA_SITUATION_CODES.InCanada,
    text: {
      en: "I am in Canada",
      fr: "Je suis au Canada",
    },
  },
  {
    value: INSIDE_OUTSIDE_CANADA_SITUATION_CODES.OutsideOfCanada,
    text: {
      en: "I am outside Canada",
      fr: "Je suis à l’extérieur du Canada",
    },
  },
];
