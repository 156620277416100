import { createAction, props } from "@ngrx/store";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";

export enum RenewalApplicationsPRTDActionTypes {
  StartLoadPRTDAction = "[Start Load PRTD Application ] Load PRTD Application",
  SetNewPRTDAction = "[Set New PRTD ] Set New PRTD Application",
  LoadPRTDAction = "[Load PRTD ] Load PRTD Application",
  LoadPRTDErrorAction = "[Load PRTD Application Error ] Load PRTD Application Error",
  ResetPRTDStateAction = "[SET PRTD STATE TO INITIAL VALUES] Reset PR state to initial values",
  UpdatePRTDClientAndApplicationInfo = "[Update PTD case With Client And Apication Info] Update PRTD client and application info",
  UpdatePRTDWithUrgencyOtherExplanation = "[Update PRTD case with urgencyOtherExplanation] Update PRTD urgency other reason",
  UpdatePRTDUrgencyStatus = "[Update PRTD urgency status] Update PRTD urgency status; true or false",
}

export const StartLoadPRTDAction = createAction(
  RenewalApplicationsPRTDActionTypes.StartLoadPRTDAction,
  props<{ caseId: number | string }>()
);

export const SetNewPRTDAction = createAction(
  RenewalApplicationsPRTDActionTypes.SetNewPRTDAction
);

export const LoadPRTDAction = createAction(
  RenewalApplicationsPRTDActionTypes.LoadPRTDAction,
  props<{ prtd: Case }>()
);

export const LoadPRTDErrorAction = createAction(
  RenewalApplicationsPRTDActionTypes.LoadPRTDErrorAction,
  props<{ id: number; error: string }>()
);

export const ResetPRTDStateAction = createAction(
  RenewalApplicationsPRTDActionTypes.ResetPRTDStateAction
);

export const UpdatePRTDWitClientAndAppInfoAction = createAction(
  RenewalApplicationsPRTDActionTypes.UpdatePRTDClientAndApplicationInfo,
  props<{
    emailAddress: string;
    givenName: string;
    surname: string;
    applicationName: string;
  }>()
);

export const UpdatePRTDUrgencyOtherExplanation = createAction(
  RenewalApplicationsPRTDActionTypes.UpdatePRTDWithUrgencyOtherExplanation,
  props<{ urgencyOtherExplanation: string }>()
);

export const UpdatePRTDIsUrgentStatus = createAction(
  RenewalApplicationsPRTDActionTypes.UpdatePRTDUrgencyStatus,
  props<{ isUrgent: boolean; urgencyRequestDate: string }>()
);
