import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RoleIds } from "@pr-applicant/app/shared/services/psr/psr.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { environment } from "@pr-applicant/environments/environment";

interface ITranslations {
  familyName: string;
  givenName: string;
  emailAddress: string;
  emailConfirm: string;
  isPrimaryContact?: boolean;
}

@Component({
  selector: "pra-member-fields",
  templateUrl: "./member-fields.component.html",
  styleUrls: ["./member-fields.component.scss"],
})
export class MemberFieldsComponent implements OnInit {
  public RoleIds = RoleIds;
  constructor(public user: UserService) {}

  @Input() form: FormGroup;
  @Input() legend: String = "";
  @Input() translations: ITranslations;

  ngOnInit() {}

  preventPaste(e: any) {
    if (environment.env === "local") return;
    e.preventDefault();
    this.form.controls.emailConfirm.setErrors({
      pasted: true,
    });
  }
}
