import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DownloadService } from "../../../shared/services/download.service";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pra-copr-download",
  templateUrl: "./copr-download.component.html",
  styleUrls: ["./copr-download.component.scss"],
})
export class CoprDownloadComponent implements OnInit {
  @Input() public documents: any[];
  public canDownloadCopr = false;
  public coprDocument: any;
  public case: any;

  constructor(
    private downloadService: DownloadService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.coprDocument = this.documents?.filter(
      (doc: any) => doc.documentTypeId === 3
    );
    if (this.coprDocument?.length > 0) {
      this.canDownloadCopr = true;
    }
    this.route.data.subscribe((data) => {
      this.case = data.case;
    });
  }

  async downloadCopr() {
    if (this.canDownloadCopr) {
      const document = this.coprDocument[0]
      const downloadName = document.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)
      try{
        await this.downloadService.downloadDocument(document.caseId, document.id, downloadName);
      }catch(error){
        this.alertService.danger(this.alertTechnicalError)
      }
    }
  }
  
  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public get nameParam(): object {
    const firstName = this.case.givenName ? `${this.case.givenName}` : "";
    const lastName = this.case.surname;

    return { name: `${firstName} ${lastName}` };
  }
}
