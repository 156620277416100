import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthService } from "@pr-applicant/app/core/auth-module/services/auth.service";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import {
  AlertService,
  documentTypes,
  enableViewButton,
  disableViewButton,
  renewalDocTypes,
  enableDeleteButton,
  showDeleteButton,
  ModalService,
  showExpiredText,
  caseStatuses,
} from "lib";
import { delay, filter, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import { Subscription } from "rxjs";
import { AppItemForView } from "./viewAppModels";
import { TranslateService } from "@ngx-translate/core";
import routePaths from "@pr-applicant/app/routing/route-paths";
import { CasesCountService } from "@pr-applicant/app/shared/services/case-count.service";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import moment from "moment";
import { FormControl } from "@angular/forms";
import { DownloadService } from "../../../shared/services/download.service";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
import {
  IGroupMember,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";

@Component({
  selector: "pra-rep-dashboard",
  templateUrl: "./rep-dashboard.component.html",
  styleUrls: ["./rep-dashboard.component.scss"],
})
export class RepDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  public tableCases: any[] = []; // Simpler list format for the table cases view
  public lang: "en" | "fr";

  public appItemsList: AppItemForView[];
  public dataSource: MatTableDataSource<AppItemForView>;
  public displayedColumns: string[];
  public paginationNumber: number;
  public recordsPerPage = 50;
  public hasRecordsToShow = false;
  public sortEvent: any = { active: "statusDate", direction: "desc" };
  private subs: Subscription[] = [];
  private searchBy: string = "";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private repColumns = [
    "clientDetailsRep",
    "categoryName",
    "clientSignedDocs",
    "statusDate",
    "submittedAt",
    "status",
    "action",
  ];
  private clientColumns = [
    "clientDetailsClient",
    "categoryName",
    "statusDate",
    "submittedAt",
    "status",
    "action",
  ];
  public isRep: boolean = false;
  public isClient: boolean = true;
  public _moment = moment;
  public lobFilter = new FormControl();
  public lobFilterOptions: {
    value: number;
    text: { en: string; fr: string };
  }[] = [];
  public statusFilter = new FormControl();
  public statusFilterOptions: {
    value: number;
    text: { en: string; fr: string };
  }[];
  public sortActive: string = "statusDate";
  public sortDirection: "asc" | "desc" | "" = "desc";
  public selectedCase: number;
  public tableSpinner = false;
  private downloadApplicationSubs = new Subscription();
  private rows: AppItemForView[] = [];

  constructor(
    private authService: AuthService,
    public routeLocalizer: RouteLocalizerService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private store: Store<fromApp.State>,
    private alertService: AlertService,
    private translate: TranslateService,
    private casesCountService: CasesCountService,
    private userService: UserService,
    private caseService: CaseService,
    private modalService: ModalService,
    private downloadService: DownloadService,
    public lobService: LobService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.authService.checkSession();
    this.isRep = this.userService.isRepAccount();
    this.isClient = !this.isRep;
    this.lang = this.routeLocalizer.getCurrentRouteLang() as "en" | "fr";
    this._moment.locale(this.lang);
    this.routeLocalizer.setTranslatedTitle("INTAKE.LANDING_PAGE.PAGE_TITLE");
    this.displayedColumns = this.isRep ? this.repColumns : this.clientColumns;
    this.lobFilter.valueChanges
      .pipe(filter((lobSelection) => !isNaN(lobSelection)))
      .subscribe((lobSelection) => {
        this.applySearchCriterias();
      });

    this.statusFilter.valueChanges.subscribe((statusSelection) => {
      this.applySearchCriterias();
    });
  }

  updateStatusFilterOptions() {
    this.statusFilterOptions = [];
    this.statusFilterOptions = this.appItemsList.map((item) => {
      return {
        value: this.translate.instant(item.status.label),
        text: {
          en: this.translate.instant(item.status.label),
          fr: this.translate.instant(item.status.label),
        },
      };
    });
    this.statusFilterOptions = this.getUniqueAndSortedOptions(
      this.statusFilterOptions
    );
  }

  updateLobFilterOptions() {
    this.lobFilterOptions = [];
    this.appItemsList.forEach((item) => {
      item.categoryName = this.lobService.getCategoryDisplayText(item.lob);
      let lobId = item.lobId;
      if (item.lobId === null || typeof item.lobId === "undefined") lobId = -1; // confimration has a NULL lobID
      if ([41, 42].includes(item.lobId!)) lobId = 17;
      if ([43, 44].includes(item.lobId!)) lobId = 18;
      const option = {
        value: lobId!,
        text: { en: "", fr: "" },
      };
      option.text[this.lang] = item.categoryName;
      this.lobFilterOptions.push(option);
    });
    this.lobFilterOptions = this.getUniqueAndSortedOptions(
      this.lobFilterOptions
    );
  }

  public getTranslatedVersion(lob: any, type: string): string {
    if (type === "program") {
      if (this.lang === "fr") {
        return lob.programFr;
      } else {
        return lob.programEn;
      }
    } else {
      if (this.lang === "fr") {
        return lob.categoryFr;
      } else {
        return lob.categoryEn;
      }
    }
  }

  getUniqueAndSortedOptions(filterOptions: any[]): any[] {
    return filterOptions
      .filter((lob) => lob.text.en || lob.text.fr)
      .reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.value === o.value)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .sort((a: any, b: any) => {
        return a.text[this.lang].localeCompare(b.text[this.lang]);
      });
  }

  applyRowsToTable(rows: AppItemForView[]) {
    this.rows = rows;
    this.hasRecordsToShow = rows.length > 0;
    this.dataSource = new MatTableDataSource<AppItemForView>();
    this.paginationNumber = Math.ceil(rows.length / this.recordsPerPage);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.rows;
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.paginator.pageSize = this.recordsPerPage;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this.route.data.pipe(delay(1), first()).subscribe((data) => {
      this.tableCases = this.getDataToView(data.cases as any[]);
      console.log(this.tableCases);
      if (data.cases.length === 0 && this.isClient) {
        const paths = this.routeLocalizer.getRoutes()[this.lang];
        this.router.navigateByUrl(
          [, this.lang, paths["APPLICATION"], paths["START"]].join("/")
        );
      }

      this.appItemsList = this.tableCases.map(
        (item) => new AppItemForView(item)
      );

      this.appItemsList.forEach((item) => {
        item.categoryName = this.lobService.getCategoryDisplayText(item.lob);
      });

      this.updateLobFilterOptions();
      this.updateStatusFilterOptions();
      this.sortLastUpdated();
    });
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  goToPageNumber(event: any) {
    this.paginator.pageIndex = event;
    this.paginator.page.next({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length,
    });
    this.cdr.detectChanges();
  }

  public addNewApplication() {
    if (this.casesCountService.reachLimit()) {
      this.alertService.danger(this.addNewApplicationError);
    } else {
      // navigate to new application page
      const stringLang = this.lang === "fr" ? "fr" : "en";
      this.router.navigateByUrl(
        `${this.lang}/${routePaths[stringLang].INTAKE}/${routePaths[stringLang].INTAKE_NEW}`
      );
    }
  }

  public getValidTime(date: string): string {
    return date.split(".")[0];
  }

  private get addNewApplicationError() {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  private areMandatoryDocsSigned(row: any) {
    if (!row.rawAPiResp.documents) return null;
    if (row.phaseId === 3) {
      const mandatoryDocs = row?.rawAPiResp?.documents.filter(
        (doc: any) =>
          doc.documentTypeId === documentTypes.renewalDeclaration.id ||
          doc.documentTypeId === renewalDocTypes.IMM5476.id
      );
      return (
        mandatoryDocs &&
        mandatoryDocs?.length === 2 &&
        mandatoryDocs.every((doc: any) => {
          return (
            (doc.documentTypeId === documentTypes.renewalDeclaration.id &&
              doc.isComplete) ||
            doc.documentTypeId === renewalDocTypes.IMM5476.id
          );
        })
      );
    }
    if (row.phaseId === 1) {
      const declarationSignature = row?.rawAPiResp?.documents.some(
        (doc: any) =>
          doc.documentTypeId === documentTypes.declarationSignature.id &&
          doc.isComplete
      );
      const imm5669 = row?.rawAPiResp?.documents.some(
        (doc: any) =>
          doc.documentTypeId === documentTypes.imm5669.id && doc.isComplete
      );
      const declarationSignatureWith5669 = row?.rawAPiResp?.documents.some(
        (doc: any) =>
          doc.documentTypeId ===
            documentTypes.declarationSignatureWith5669.id && doc.isComplete
      );

      if (
        declarationSignature &&
        imm5669 &&
        row.rawAPiResp.caseStatusId !== 6 &&
        row.rawAPiResp.caseStatusId !== 8
      ) {
        return true;
      } else if (declarationSignatureWith5669) {
        return true;
      } else {
        return false;
      }
    }
  }

  private getDataToView(data: any[]): any[] {
    return data.map((row: any) => {
      const casePermissions = this.user.getPermissionsByRoleId(row.roleId);
      const disableDownloadLink =
        casePermissions && !casePermissions.includes("case:download");
      const disableAccessForReturnedCase =
        casePermissions &&
        !casePermissions.includes("case-returned:access") &&
        row.statusId === caseStatuses.intake.incomplete.id;
      const createdByPrimarySponsor = row.members.some(
        (member: IGroupMember) => member.roleId == RoleIds.PRIMARY_SPONSOR
      );
      const createdBySponsorRep = row.members.some(
        (member: IGroupMember) => member.roleId == RoleIds.SPONSOR_REP
      );
      return {
        ...row,
        showDownloadLink: !disableDownloadLink,
        showViewButton:
          enableViewButton.includes(row.statusId) &&
          !disableAccessForReturnedCase,
        showDisabledViewButton: disableViewButton.includes(row.statusId),
        showExpiredText: showExpiredText.includes(row.statusId),
        showNoReturnedAccessSponsorText:
          disableAccessForReturnedCase && createdByPrimarySponsor,
        showNoReturnedAccessRepresentativeText:
          disableAccessForReturnedCase && createdBySponsorRep,
        enableDeleteButton:
          enableDeleteButton.includes(row.statusId) &&
          (!casePermissions || casePermissions.includes("case:delete")),
        showDeleteButton:
          showDeleteButton.includes(row.statusId) &&
          !disableAccessForReturnedCase,
        mandatoryDocsSignedByClient: this.areMandatoryDocsSigned(row),
      };
    });
  }

  searchedTerm(event: any) {
    this.searchBy = event ? event.trim().toUpperCase() : "";
    this.applySearchCriterias();
  }

  sortLastUpdated(sortByStatus = false): void {
    let filteredRows = [...this.appItemsList];
    const statusOrder = [
      [3, 8, 17], // returned
      [1, 6, 15], // initiated
      [2, 7, 11, 12, 13, 14, 16, 18, 19], // submitted
      [21], // expired
      [4, 5, 10, 20], // complete
      [9], // withdrawn
    ];
    filteredRows.sort((a: any, b: any) => {
      let aStatusOrder = 0;
      let bStatusOrder = 0;
      statusOrder.forEach((statusList, index) => {
        if (statusList.includes(a.statusId)) aStatusOrder = index;
        if (statusList.includes(b.statusId)) bStatusOrder = index;
      });
      if (aStatusOrder - bStatusOrder !== 0) return aStatusOrder - bStatusOrder;
      return Date.parse(b.statusDate) - Date.parse(a.statusDate);
    });
    this.applyRowsToTable(filteredRows);
  }

  applySearchCriterias(): void {
    let filteredRows = [...this.appItemsList];
    if (this.statusFilter.value) {
      filteredRows = this.appItemsList.filter(
        (item) =>
          this.translate.instant(item.status.label) === this.statusFilter.value
      );
    }
    console.log(filteredRows);
    if (this.searchBy) {
      filteredRows = filteredRows.filter(
        (item) =>
          item.clientEmail.toUpperCase().includes(this.searchBy) ||
          item.clientName?.toUpperCase().includes(this.searchBy)
      );
    }
    if (this.lobFilter.value) {
      if (this.lobFilter.value === -1) {
        filteredRows = filteredRows.filter((item) => item.phaseId === 2); // confimration has a NULL lobID
      } else if (this.lobFilter.value === 17) {
        filteredRows = filteredRows.filter((item) =>
          [41, 42].includes(item.lobId!)
        );
      } else if (this.lobFilter.value === 18) {
        filteredRows = filteredRows.filter((item) =>
          [43, 44].includes(item.lobId!)
        );
      } else {
        filteredRows = filteredRows.filter(
          (item) => item.lobId === this.lobFilter.value
        );
      }
    }
    if (!this.statusFilter.value && !this.searchBy && !this.lobFilter.value)
      this.sortLastUpdated();
    else this.applyRowsToTable(filteredRows);
  }

  sortData(event: any) {
    this.sortEvent = Object.assign({}, event);
  }

  public openModal(id: string, caseId: number): void {
    this.selectedCase = caseId;
    this.modalService.open(id);
  }

  public closeModal(id: string): void {
    this.modalService.close(id);
  }

  public deleteCase() {
    this.tableSpinner = true;
    this.modalService.close("confirmDeleteCase");
    this.caseService.deleteCaseByCaseId(this.selectedCase.toString()).then(
      (res) => {
        let deleteIndex: number;

        for (let index in this.appItemsList) {
          if (this.appItemsList[index].id === this.selectedCase) {
            deleteIndex = Number(index);
            this.appItemsList.splice(deleteIndex, 1);
            this.applyRowsToTable(this.appItemsList);
          }
        }
        this.tableSpinner = false;
        this.alertService.success(this.alertSuccess);
      },
      () => {
        this.tableSpinner = false;
        this.alertService.danger(this.alertTechnicalError);
      }
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }

  private get alertSuccess(): string {
    return this.translate.instant("INTAKE.DELETE_CASE_MODAL.DELETE_SUCCESS");
  }

  public formatDate(
    date: string,
    withTime = false,
    utcToLocal = false
  ): string {
    // const localFormat = this.lang === "en" ? "MMM d, YYYY" : "d MMM YYYY";
    // const localTimeFormat = this.lang === "en" ? ", m:h am z" : ", h ";
    let format = "LL";
    if (withTime) format = "LLL";
    if (utcToLocal) return this._moment.utc(date).local().format(format);
    else return this._moment(date).format(format);
  }

  public viewApplication(row: AppItemForView) {
    if (!row.showViewButton) return;
    this.router.navigate([row.link], { relativeTo: this.route });
  }

  downloadAllDocuments(caseid: string, appName: string) {
    this.tableSpinner = true;
    this.downloadService.downloadClientApplication(caseid, appName);
    this.subs.push(
      (this.downloadApplicationSubs =
        this.downloadService.clientApplicationObs$.subscribe({
          next: () => {
            this.tableSpinner = false;
          },
          error: () => {
            this.alertService.danger(this.alertTechnicalError);
            this.tableSpinner = false;
          },
        }))
    );
  }
}
