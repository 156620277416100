<div class="tool-tip">
  <button
    type="button"
    category="plain"
    (click)="openModal()"
    [attr.aria-label]="
      'INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.ARIA_LABEL'
        | translate
    "
  >
    <i class="fa-regular fa-circle-info"></i>
  </button>
</div>
<lib-modal [id]="'tool-tip-' + tooltip_id" [title]="tooltip_title">
  <ng-container modalBody>
    <!-- insert pipe that returns modal html -->
    <div [innerHtml]="tooltip_body"></div>
  </ng-container>
  <ng-container modalButtons>
    <button type="button" category="secondary" (click)="closeModal()">
      {{ "INTAKE.LANDING_PAGE.SUBMIT_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
