import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { AlertService, ModalService, ValidationService } from "lib";
import { Subscription, first } from "rxjs";
import {
  IPrincipalApplicant,
  PsrService,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { PsrValidationService } from "@pr-applicant/app/shared/services/psr/psr-validation.service";
import { CognitoHelperService } from "@pr-applicant/app/shared/services/utils/cognitoHelper.service";
import {
  ICaseProfileModel,
  ProfileService,
} from "@pr-applicant/app/shared/services/profile/profile.service";
import { autoPopulate } from "@pr-applicant/app/shared/helpers/auto-populate";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pra-invite-principal-applicant",
  templateUrl: "./invite-principal-applicant.component.html",
  styleUrls: ["./invite-principal-applicant.component.scss"],
})
export class InvitePrincipalApplicantComponent implements OnInit {
  constructor(
    public routeLocalizer: RouteLocalizerService,
    public activeRoute: ActivatedRoute,
    private validationService: ValidationService,
    private modalService: ModalService,
    private psrService: PsrService,
    private psrValidationService: PsrValidationService,
    private profileService: ProfileService,
    private cognitoService: CognitoHelperService,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  public lang: string;
  public caseId: string = "";
  private subscriptions: Subscription[] = [];
  public principalApplicant: IPrincipalApplicant;
  public isProcessing: boolean = true;
  public modalId = "confirmSendInvite";
  public returnPath: string;
  private familyName: string;
  private givenName: string;
  public invitePrincipalApplicant = new FormGroup(
    {
      familyName: new FormControl("", {
        validators: [Validators.required],
      }),
      givenName: new FormControl("", {
        validators: [],
      }),
      emailAddress: new FormControl("", {
        validators: [Validators.required, Validators.email],
        asyncValidators: [
          this.psrValidationService.validatorCheckEmailForPaidRep(
            this.cognitoService
          ),
        ],

        updateOn: "blur",
      }),
      emailConfirm: new FormControl("", {
        validators: [Validators.email],
      }),
    },
    this.validationService.validatorEmailMatching
  );

  public translations = {
    familyName: "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.FAMILY_NAME",
    givenName: "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.GIVEN_NAME",
    emailAddress: "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.EMAIL",
    emailConfirm: "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.EMAIL_CONFIRM",
  };

  private disabledForAutopopulate: AbstractControl[] = [
    this.invitePrincipalApplicant.controls.familyName,
    this.invitePrincipalApplicant.controls.givenName,
  ];

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.returnPath = this.routeLocalizer.getNestedPath(
      this.lang,
      ["MANAGE_GROUP_MEMBERS"],
      "../"
    );
    this.subscriptions.push(
      this.profileService.profileObs$.subscribe((data: ICaseProfileModel) => {
        this.invitePrincipalApplicant.controls.familyName.setValue(
          data.profile.familyName
        );
        this.invitePrincipalApplicant.controls.givenName.setValue(
          data.profile.givenName
        );
        this.familyName = data.profile.familyName;
        this.givenName = data.profile.givenName;
        autoPopulate(this.disabledForAutopopulate);
        this.isProcessing = false;
      }),
      this.activeRoute.params.subscribe((params) => {
        this.caseId = params.caseId;
        this.profileService.getProfile(this.caseId);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  inviteConfirm(e: any) {
    if (this.invitePrincipalApplicant.invalid) {
      this.invitePrincipalApplicant.markAllAsTouched();
    } else {
      this.modalService.open(this.modalId);
    }
  }

  inviteSend(check: boolean) {
    const applicant = this.invitePrincipalApplicant.value;
    applicant.familyName = this.familyName;
    applicant.givenName = this.givenName;
    delete applicant.emailConfirm;
    if (check) {
      this.psrService
        .addMember(
          applicant as IPrincipalApplicant,
          Number(this.caseId),
          RoleIds.REFUGEE
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.router.navigate([`${this.returnPath}`], {
              relativeTo: this.activeRoute,
            });
          },
          error: (error) => {
            console.log("Invite Error", error.response.status);
            if (error.response.status === 409) {
              this.alertService.danger(
                this.translate.instant(
                  "PSR.INVITE_GROUP_MEMBER_PAGE.MEMBER_EXISTS"
                )
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          },
        });
    }
  }
  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }
}
