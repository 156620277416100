import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { CurrentIMMDocumentState } from "../../../../shared/currentIMMDocument/store/currentIMMDocument.reducer";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-c",
  templateUrl: "./imm5669-section-c.component.html",
  styleUrls: ["./imm5669-section-c.component.scss"],
})
export class IMM5669SectionCComponent implements OnInit, OnDestroy {
  public formMaxLength = {
    elementarySchoolYears: 2,
    secondarySchoolYears: 2,
    universityAndCollegeYears: 2,
    otherSchoolYears: 2,
    detail: {
      from: 7,
      to: 7,
      nameOfInstitution: 24,
      cityAndCountry: 21,
      typeOfDiploma: 17,
      fieldOfStudy: 17,
    },
  };

  public sectionCdata: any;
  public sectionCForm: FormGroup;
  public educationHistory: UntypedFormArray = new UntypedFormArray([]);
  public isReadOnly = !this.user.can("documents:write");
  public subscription$: Subscription;

  private buttonClicked: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    public alertService: AlertService,
    public translate: TranslateService,
    private store: Store<fromApp.State>,
    private validator: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.sectionCForm = this.createForms();
    this.subscription$ = this.store
      .select("currentIMMDocument")
      .pipe(distinctUntilChanged())
      .subscribe((formdata: CurrentIMMDocumentState) => {
        this.sectionCdata = formdata?.currentForm?.form?.sectionC;
        if (this.sectionCdata.educationHistory?.length > 0) {
          this.fillFormWithSavedValues();
        } else {
          this.fillFormWithSavedValues();
          this.educationHistory.push(this.createNewEducationDetail());
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public get isFormValid(): boolean {
    return this.sectionCForm?.valid;
  }

  public get alerMaxEducationLimit(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  // public functions
  public addEducation(): void {
    // check limit
    if (this.educationHistory.length < DOC_LIMITS.IMM5669.EDUCATION_MAX) {
      this.buttonClicked = true;
      this.educationHistory.push(this.createNewEducationDetail());
    } else {
      // alert
      this.alertService.danger(this.alerMaxEducationLimit);
    }
  }

  public removeEducation(i: number): void {
    this.buttonClicked = true;
    this.educationHistory.removeAt(i);
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.sectionCForm.touched || this.buttonClicked;
  }

  public getFormData(): any {
    if (
      this.sectionCForm.invalid ||
      (this.educationHistory.controls as FormControl[]).find((fc) => fc.invalid)
    ) {
      return { sectionName: "sectionC", isInvalid: true };
    }
    const formValue = this.sectionCForm.getRawValue();
    return {
      sectionName: "sectionC",
      sectionInfo: { ...this.sectionCdata, ...formValue },
    };
  }

  // private functions
  private createForms(): FormGroup {
    return this.fb.group({
      elementarySchoolYears: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.elementarySchoolYears),
          this.validator.validatorOnlyNumbers,
        ],
      }),
      secondarySchoolYears: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.secondarySchoolYears),
          this.validator.validatorOnlyNumbers,
        ],
      }),
      universityAndCollegeYears: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.universityAndCollegeYears),
          this.validator.validatorOnlyNumbers,
        ],
      }),
      otherSchoolYears: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.otherSchoolYears),
          this.validator.validatorOnlyNumbers,
        ],
      }),
      educationHistory: this.educationHistory,
    });
  }

  private fillFormWithSavedValues(): void {
    this.educationHistory?.clear();
    this.sectionCForm?.reset();
    const savedEducationForm = this.sectionCdata;
    const savededucationHistory = this.sectionCdata?.educationHistory;
    this.sectionCForm?.patchValue({
      elementarySchoolYears: savedEducationForm.elementarySchoolYears,
      secondarySchoolYears: savedEducationForm.secondarySchoolYears,
      universityAndCollegeYears: savedEducationForm.universityAndCollegeYears,
      otherSchoolYears: savedEducationForm.otherSchoolYears,
    });
    savededucationHistory?.forEach((savedEducationDetail: any) => {
      const educationDetail: FormGroup = this.createNewEducationDetail();
      educationDetail?.setValue({
        from: savedEducationDetail.from,
        to: savedEducationDetail.to,
        nameOfInstitution: savedEducationDetail.nameOfInstitution,
        cityAndCountry: savedEducationDetail.cityAndCountry,
        typeOfDiploma: savedEducationDetail.typeOfDiploma,
        fieldOfStudy: savedEducationDetail.fieldOfStudy,
      });
      this.educationHistory.push(educationDetail);
    });
  }

  private createNewEducationDetail(): FormGroup {
    const form = this.fb.group({
      from: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.detail.from)],
        },
      ],
      to: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.detail.to)],
        },
      ],
      nameOfInstitution: [
        null,
        {
          validators: [
            Validators.maxLength(this.formMaxLength.detail.nameOfInstitution),
          ],
        },
      ],
      cityAndCountry: [
        null,
        {
          validators: [
            Validators.maxLength(this.formMaxLength.detail.cityAndCountry),
          ],
        },
      ],
      typeOfDiploma: [
        null,
        {
          validators: [
            Validators.maxLength(this.formMaxLength.detail.typeOfDiploma),
          ],
        },
      ],
      fieldOfStudy: [
        null,
        {
          validators: [
            Validators.maxLength(this.formMaxLength.detail.fieldOfStudy),
          ],
        },
      ],
    });
    return form;
  }
}
