<section class="anchor-doc-table">
  <h2>
    <span class="anchor-doc-table__required">*</span
    >{{ "INTAKE.LANDING_PAGE.SIDE_NAV.ANCHOR_DOCUMENTS" | translate }}
    <span class="anchor-doc-table__required">{{
      "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
    }}</span>
  </h2>
  <pra-documents-upload-table
    [isHiddenLob]="isHiddenLob"
    (supportDocumentsUpdated)="handleUpdate()"
    [requiredDocumentOptions]="memberDocumentOptions"
    [caseMemberId]="caseMemberId"
    deleteModalIdAnchor="anchor"
  ></pra-documents-upload-table>
</section>
