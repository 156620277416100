<div
  *ngIf="config"
  class="information-box"
  [ngStyle]="{
    backgroundColor: config.backgroundColor,
    border: config.borderColor
  }"
>
  <div class="icon-container">
    <i
      *ngIf="config.icon"
      [ngClass]="config.icon"
      [ngStyle]="{ color: config.iconColor }"
      [title]="config.iconAltText"
      aria-hidden="true"
    >
      <span class="sr-only"> {{ config.iconAltText }} </span>
    </i>
  </div>
  <ng-content></ng-content>
</div>
