<mat-sidenav-container class="page-container">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [items]="FormNavbarItems"
        [curr]="4"
        [isLoading]="isLoading"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="imm0008-page5__heading emphasis">
      {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
    </h1>
    <h2 class="emphasis imm0008-page5__subheading">
      {{ "INTAKE.IMM0008.PAGES.PASSPORT_DETAILS.SUBHEADING" | translate }}
    </h2>
    <lib-spinner [showSpinner]="isLoading" [fullPage]="false"> </lib-spinner>

    <pra-imm0008-passport-details-form
      [ngStyle]="{ display: isLoading ? 'none' : 'block' }"
      #passportDetailsForm
      [formData]="formData"
    ></pra-imm0008-passport-details-form>

    <lib-layout-row gap="one-and-half" mt="3">
      <a
        [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE4', '../')"
        category="secondary"
      >
        {{ "INTAKE.FORM_PAGE_BACK" | translate }}
      </a>
      <button
        [disabled]="passportDetailsForm?.passportDetailsForm?.invalid"
        (click)="saveForm()"
        category="primary"
      >
        {{ nextButtonKey | translate }}
      </button>
    </lib-layout-row>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isLoading"
></pra-form-hamburger>
