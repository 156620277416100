import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { samplePhotos } from "../sample-photo.data";

@Component({
  selector: "pra-photo-requirements",
  templateUrl: "./photo-requirements.component.html",
  styleUrls: ["./photo-requirements.component.scss"],
})
export class PhotoRequirementsComponent implements OnInit {
  @Input() public nameParam: any;
  public currentLang: string;
  public requirementSets: any[];
  public photos = samplePhotos;

  constructor(
    private translate: TranslateService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit(): void {
    this.store.select(fromApp.selectRouteData).subscribe((data) => {
      this.currentLang = data?.lang ? data.lang : "en";
    });
    this.getTranslatedRequirements();
  }

  private getTranslatedRequirements(): void {
    this.translate
      .get("PHOTOS.REQUIREMENTS", this.nameParam)
      .subscribe((res) => {
        this.requirementSets = res;
      });
  }
}
