import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { filter } from "rxjs/operators";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { TranslateService } from "@ngx-translate/core";
import { Lob } from "../../../../core/models/lob.model";
import { IInformationBoxConfig } from "lib";

@Component({
  selector: "pra-program-info-links",
  templateUrl: "./program-info-links.component.html",
  styleUrls: ["./program-info-links.component.scss"],
})
export class ProgramInfoLinksComponent implements OnInit, OnChanges {
  private lob: Lob;
  @Input() isLobUpdated: boolean;

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getLobOfStoredCase();
  }

  ngOnChanges(): void {
    if (this.isLobUpdated) {
      this.getLobOfStoredCase();
    }
  }

  /* PUBLIC ------------------------------------------------------------------------- */

  public get infoBoxText(): string {
    switch (this.lob.id) {
      case 40:
        return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_EMPP_RNIP", {
          documentChecklistUrl: this.documentChecklistUrl,
          eligibilityUrl: this.eligibilityUrl,
          guidelinesUrl: this.guidelinesUrl,
        });
      case 39:
        return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_EMPP_PNP", {
          documentChecklistUrl: this.documentChecklistUrl,
          eligibilityUrl: this.eligibilityUrl,
          guidelinesUrl: this.guidelinesUrl,
        });
      case 38:
        return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_EMPP_AIP", {
          documentChecklistUrl: this.documentChecklistUrl,
          eligibilityUrl: this.eligibilityUrl,
          guidelinesUrl: this.guidelinesUrl,
        });
      case 45:
      case 46:
      case 48:
        return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_PSR", {
          documentChecklistUrl: this.documentChecklistUrl,
          eligibilityUrl: this.eligibilityUrl,
          guidelinesUrl: this.guidelinesUrl,
        });
      default:
        return this.translate.instant("INTAKE.PROGRAM_INFO_BOX", {
          documentChecklistUrl: this.documentChecklistUrl,
          eligibilityUrl: this.eligibilityUrl,
          guidelinesUrl: this.guidelinesUrl,
        });
    }
  }

  /* PRIVATE ------------------------------------------------------------------------- */

  private get infoBoxTextHeading(): string {
    if (this.lob.id === 40) {
      return this.translate.instant(
        "INTAKE.PROGRAM_INFO_BOX_EMPP_RNIP_HEADING"
      );
    } else if (this.lob.id === 39) {
      return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_EMPP_PNP_HEADING");
    } else if (this.lob.id === 38) {
      return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_EMPP_AIP_HEADING");
    } else {
      return this.translate.instant("INTAKE.PROGRAM_INFO_BOX_HEADING");
    }
  }

  private get documentChecklistUrl(): string {
    return this.lang === "fr"
      ? this.lob?.documentChecklistFr
      : this.lob?.documentChecklistEn;
  }

  private get eligibilityUrl(): string {
    return this.lang === "fr"
      ? this.lob?.eligibilityCriteriaFr
      : this.lob?.eligibilityCriteriaEn;
  }

  private get guidelinesUrl(): string {
    return this.lang === "fr"
      ? this.lob?.applicationGuideFr
      : this.lob?.applicationGuideEn;
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  public get config() {
    return {
      icon: "infoRegular",
      header: this.translate.instant("INTAKE.PROGRAM_INFO_BOX_HEADING"),
      type: "info",
    };
  }

  private getLobOfStoredCase(): void {
    this.store
      .select("selectedCase")
      .pipe(filter((caseData: any) => caseData.id !== ""))
      .subscribe((caseData: any) => {
        this.lob = caseData.lob;
      });
  }
}
