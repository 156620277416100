<h1 class="page__title emphasis">
  {{ "INTAKE.DECLARATION.ESIG.HEADING" | translate }}
</h1>
<h2 class="emphasis subheading">
  {{ "INTAKE.DECLARATION.ESIG.SUBHEADING" | translate }}
</h2>

<p>{{ "INTAKE.DECLARATION.ESIG.PAGE_DESCRIPTION" | translate }}</p>

<form
  [formGroup]="declarationForm"
  autocomplete="off"
  (ngSubmit)="submitESig()"
>
  <fieldset>
    <lib-form-radio
      [editable]="editable"
      [legend]="'INTAKE.DECLARATION.ESIG.FORM.CAN_CONTACT.LEGEND' | translate"
      [required]="true"
      firstId="canContact-yes"
      secondId="canContact-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="canContact-consent"
      [formControl]="declarationForm.controls.canContact"
    >
      <span
        class="error-radio"
        *ngIf="
          declarationForm.get('canContact')?.touched &&
          declarationForm.get('canContact')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.DECLARATION.ESIG.FORM.RELEASE_INFO_TITLE" | translate }}
    </legend>
    <lib-form-radio
      [editable]="editable"
      [legend]="'INTAKE.DECLARATION.ESIG.FORM.CAN_SHARE.LEGEND' | translate"
      [required]="true"
      firstId="canShare-yes"
      secondId="canShare-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="canShare-consent"
      [formControl]="declarationForm.controls.shareData"
    >
      <span
        class="error-radio"
        *ngIf="
          declarationForm.get('shareData')?.touched &&
          declarationForm.get('shareData')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
    <div
      [innerHTML]="'INTAKE.DECLARATION.ESIG.FORM.LEGAL_TEXT' | translate"
    ></div>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.DECLARATION.ESIG.IMM5669.HEADING" | translate }}
    </legend>
    <div [innerHTML]="'INTAKE.DECLARATION.ESIG.IMM5669.BODY' | translate"></div>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.DECLARATION.ESIG.FORM.SIGN_TITLE" | translate }}
    </legend>
    <ul>
      <li
        [innerHTML]="item"
        *ngFor="
          let item of 'INTAKE.DECLARATION.ESIG.FORM.SIGN_TERMS.LIST' | translate
        "
      ></li>
    </ul>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.DECLARATION.ESIG.FORM.SIGNATURE.LEGEND" | translate }}
    </legend>
    <lib-form-input
      [editable]="editable"
      identifier="declarationName"
      required="true"
      [label]="'INTAKE.DECLARATION.ESIG.FORM.SIGNATURE.LABEL' | translate"
      [formControl]="declarationForm.controls.declarationName"
      type="text"
      class="highlight"
      [ngClass]="{ 'read-only': !editable }"
    >
      <span
        *ngIf="
          declarationForm.get('declarationName')?.touched &&
          (declarationForm.get('declarationName')?.hasError('required') ||
            declarationForm.get('declarationName')?.hasError('pattern'))
        "
      >
        {{ "INTAKE.DECLARATION.ESIG.FORM.DETAILS.NAME.VALIDATION" | translate }}
      </span>
    </lib-form-input>
    <div
      [innerHTML]="
        'INTAKE.DECLARATION.ESIG.FORM.PERSONAL_INFO_LEGAL' | translate
      "
    ></div>
  </fieldset>

  <lib-layout-row gap="one-and-half">
    <a [routerLink]="['../']" category="secondary">
      {{ "INTAKE.FORM_PAGE_BACK" | translate }}
    </a>
    <button
      *ngIf="editable"
      type="submit"
      [disabled]="
        declarationForm.invalid || caseHasBeenSubmitted || isUploading
      "
    >
      {{ "INTAKE.DECLARATION.ESIG.FORM.BUTTONS.NEXT" | translate }}
    </button>
  </lib-layout-row>
  <p
    class="error-message"
    *ngIf="editable && (declarationForm.invalid || caseHasBeenSubmitted)"
  >
    <span>*</span>
    {{ "INTAKE.DECLARATION.ESIG.FORM.WARNING.MISSING_FIELD" | translate }}
  </p>
</form>
