import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertService,
  FormSelectOption,
  ModalService,
  ValidationService,
} from "lib";
import { LovService } from "projects/lib/src/public-api";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Observable, Subscription, first } from "rxjs";
import { cloneDeep, isNull, isNumber } from "lodash";
import {
  ProfileService,
  IProfileModel,
  IDependantModel,
  INITIAL_PROFILE,
  INITIAL_DEPENDANT_PROFILE,
  ICaseProfileModel,
} from "@pr-applicant/app/shared/services/profile/profile.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { PsrService } from "@pr-applicant/app/shared/services/psr/psr.service";
import { GarService } from "@pr-applicant/app/shared/services/gar/gar.service";

@Component({
  selector: "pra-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profileForm: FormGroup;
  public dependantForm: FormGroup;
  public residentialAddressGroup: FormGroup = this.fb.group({});
  public mailingAddressGroup: FormGroup = this.fb.group({});
  public dependantList: UntypedFormArray = this.fb.array([]);
  public isProcessing = true;
  public showResidential: boolean = false;
  public lang: string;
  public selectedDependantId: string;
  public selectedDependantIndex: any;
  public dependantFormVisible: boolean = false;
  public dependantEditFormVisible: boolean = false;
  public allInputsTouched: boolean = false;
  public editingDependant: any = null;
  public countriesList: FormSelectOption[];
  public statesList: FormSelectOption[];
  public provinceList: FormSelectOption[];
  public RelationshipToPAList: FormSelectOption[];
  public TrueFalseList: FormSelectOption[];
  public correspondenceOptions: FormSelectOption[];
  public currentCaseLobId: number | null;
  public profileVersion: number;

  public max100 = { max: "100" };
  public max30 = { max: "30" };
  public max15 = { max: "15" };
  public max10 = { max: "10" };

  private subscriptionsList: Subscription[] = [];
  private canadaCountryValue: string = "511";
  private usaCountryValue: string = "461";
  private caseId: string = "";
  private caseStatusId: number | null;
  private routeSub: Subscription;
  private serviceSub: Subscription;
  private profile: IProfileModel = INITIAL_PROFILE.profile;
  private dependant: IDependantModel = INITIAL_DEPENDANT_PROFILE;
  private saveClicked: boolean = false;
  private isFirstLoad: boolean;

  @ViewChild("depForm") DepForm: ElementRef;

  constructor(
    private store: Store<fromApp.State>,
    private fb: FormBuilder,
    public routeLocalizer: RouteLocalizerService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private validators: ValidationService,
    private alertService: AlertService,
    private translate: TranslateService,
    private lovService: LovService,
    public user: UserService,
    private modalService: ModalService,
    private profileService: ProfileService,
    private userService: UserService,
    private psrService: PsrService,
    private garService: GarService
  ) {
    this.routeSub = this.activeRoute.params.subscribe((params) => {
      this.caseId = params.caseId;
    });
    this.serviceSub = this.profileService.profileObs$.subscribe(
      (data: ICaseProfileModel) => {
        if (data.caseId === this.caseId) {
          this.profile = this.updateProfileValues(data.profile);
          this.profileVersion = data.profile.profileVersion;
          console.log("profileVersion:", this.profileVersion);
          this.updatedFormValues();
          this.isProcessing = false;
        }
        this.setIsFirstLoad();
      }
    );
    this.subscriptionsList.push(
      this.store
        .select("selectedCase")
        .pipe(first())
        .subscribe((caseData) => {
          this.caseStatusId = caseData.caseStatusId;
        })
    );
    this.subscriptionsList.push(
      this.userService.currentCaseObs$.subscribe((data) => {
        this.currentCaseLobId = data.lobId;
      })
    );
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.serviceSub.unsubscribe();
    this.subscriptionsList.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.profileService.getProfile(this.caseId);
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.mailingAddressGroup = this.createAddressGroup(
      this.mailingAddressGroup
    );
    this.residentialAddressGroup = this.createAddressGroup(
      this.residentialAddressGroup
    );
    this.dependantForm = this.createDependentFormGroup();
    this.profileForm = this.createProfileFormGroup();
    this.isProcessing = true;
    this.countriesList = this.lovService.lovs.countryOfBirth;
    this.correspondenceOptions = this.lovService.lovs.preferenceLanguage;
    this.RelationshipToPAList = this.lovService.lovs.relationshipToPA;
    this.TrueFalseList = this.lovService.lovs.trueFalse;
    this.watchMailingAddressCountryValue([
      this.mailingAddressGroup,
      this.residentialAddressGroup,
    ]);
    this.watchResidentialSameAsMailingAddress();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (
      (this.profileForm.touched && !this.saveClicked) ||
      (this.dependantForm.touched && !this.saveClicked)
    ) {
      this.modalService.open("navigationModal");
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  public get isPsr(): boolean {
    return this.psrService.isPSR(Number(this.currentCaseLobId));
  }

  public get isGars(): boolean {
    return this.garService.isGAR(Number(this.currentCaseLobId));
  }

  public get isClientAccount(): boolean {
    return this.user.isClientAccount();
  }

  public get isReturned(): boolean {
    if (this.caseStatusId === 8) {
      return true;
    } else {
      return false;
    }
  }

  private updateProfileValues(profile: any): any {
    const tempProfile = cloneDeep(profile);
    const dependants = this.createDependants(profile.dependants, "object");
    this.dependantList = this.fb.array(
      this.createDependants(profile.dependants, "formGroup")
    );
    tempProfile.dependants = dependants;
    return tempProfile;
  }

  private createDependants(dependants: any, type: string): any {
    return dependants.map((dep: any) => {
      const dependant = {
        id: dep._id,
        familyName: dep.personalDetails.familyName,
        givenName: dep.personalDetails.givenName,
        dob: dep.personalDetails.dob,
        relationshipToPA: dep.personalDetails.relationshipToPA,
        accompanyingPA: dep.personalDetails.accompanyingPA,
      };
      return type === "object" ? dependant : this.fb.group(dependant);
    });
  }

  private updatedFormValues() {
    this.profileForm.patchValue(this.profile);
    this.profileForm.updateValueAndValidity();
  }

  public handleTrimByControl(ev: Event, formControl: AbstractControl) {
    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  public async saveProfileForm() {
    if (
      this.profileForm.invalid ||
      this.dependantFormVisible ||
      this.dependantEditFormVisible
    ) {
      this.markAllInputsAsTouched();
      this.allInputsTouched = true;
    } else {
      this.allInputsTouched = false;
      let profileFormValue = this.profileForm.value;
      profileFormValue.dependants = this.dependantList.value;
      profileFormValue.isComplete = this.profile.isComplete;
      profileFormValue.profileVersion = this.profileVersion;
      this.isProcessing = true;
      this.profileService
        .updateProfile(profileFormValue, this.caseId)
        .subscribe((response) => {
          if (response.form) {
            this.saveClicked = true;
            if (
              (this.psrService.isPSR(Number(this.currentCaseLobId)) &&
                this.isFirstLoad) ||
              (this.isClientAccount &&
                this.garService.isGAR(Number(this.currentCaseLobId)) &&
                this.isFirstLoad)
            ) {
              const newNestedPath = this.routeLocalizer.getNestedPath(
                this.lang,
                ["INTAKE", "CASE"]
              );
              const manageGroupMembers = this.routeLocalizer.getNestedPath(
                this.lang,
                ["MANAGE_GROUP_MEMBERS"]
              );
              this.router.navigate([
                `/${this.lang}/${newNestedPath}${this.caseId}/${manageGroupMembers}`,
              ]);
            } else {
              const newNestedPath = this.routeLocalizer.getNestedPath(
                this.lang,
                ["INTAKE", "INTAKE_CASE_DETAILS"]
              );
              this.router.navigate([
                `/${this.lang}/${newNestedPath}${this.caseId}`,
              ]);
            }
          }
        });
    }
  }

  public validatorSpouseOrCommonLaw(
    control: FormControl
  ): ValidationErrors | null {
    if (
      control.value === "3: 01" ||
      control.value === "01" ||
      control.value === "6: 10" ||
      control.value === "10"
    ) {
      const haveSpouseOrCommonLaw = this.dependantList?.value.some(
        (eachDependant: any, index: number) => {
          return this.editingDependant !== index
            ? eachDependant.relationshipToPA === "01" ||
                eachDependant.relationshipToPA === "10"
            : false;
        }
      );
      return haveSpouseOrCommonLaw ? { hasSpouseOrCommonLaw: true } : null;
    }
    return null;
  }

  public async saveDependentForm() {
    const dep = cloneDeep(this.dependantForm);

    if (isNull(this.editingDependant)) {
      this.dependantList.push(dep);
      this.showDependantForm(false);
    } else {
      this.dependantList.removeAt(this.editingDependant);
      this.dependantList.insert(this.editingDependant, dep);
      this.editingDependant = null;
      this.dependantEditFormVisible = !this.dependantEditFormVisible;
    }
  }

  public editDependent(index: any) {
    if (this.dependantFormVisible) {
      this.dependantFormVisible = false;
      this.dependantForm.reset();
    }
    this.editingDependant = index;
    const dep = cloneDeep(this.dependantList.at(index));
    this.dependantForm.patchValue(dep.value);
    this.showDependantForm(false, true);
  }

  public openModal(id: string, index: any): void {
    this.selectedDependantIndex = index;
    if (!this.profile.dependants[index]) {
      this.selectedDependantId = "";
    } else {
      this.selectedDependantId = this.profile.dependants[index].id;
    }
    console.log(this.selectedDependantId);
    this.modalService.open(id);
  }

  public closeModal(id: string): void {
    this.modalService.close(id);
  }

  public async deleteDependent() {
    this.isProcessing = true;
    if (this.selectedDependantId !== "") {
      this.profileService
        .deleteDependantProfile(this.caseId, this.selectedDependantId)
        .subscribe(() => {
          this.modalService.close("confirmDeleteDependant");
          this.isProcessing = false;
        });
      if (!this.profile.dependants[this.selectedDependantIndex]) {
        this.dependantList.removeAt(this.selectedDependantIndex);
        this.modalService.close("confirmDeleteDependant");
        this.isProcessing = false;
      }
    } else {
      this.dependantList.removeAt(this.selectedDependantIndex);
      this.alertService.success(
        this.translate.instant(
          "INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.DELETE_SUCCESS"
        )
      );
      this.isProcessing = false;
      this.modalService.close("confirmDeleteDependant");
    }
  }

  public showDependantForm(
    resetForm: boolean,
    edit: boolean = false,
    resetEditing: boolean = false
  ) {
    if (edit) {
      this.dependantEditFormVisible = !this.dependantEditFormVisible;
      resetForm && this.dependantForm.reset();
      this.editingDependant = resetEditing ? null : this.editingDependant;
    } else {
      if (this.dependantEditFormVisible) {
        this.dependantEditFormVisible = false;
        this.editingDependant = null;
        this.dependantForm.reset();
      }
      this.dependantFormVisible = !this.dependantFormVisible;
      resetForm && this.dependantForm.reset();
    }
  }

  public isCanadaSelectedMailing(group: any): boolean {
    return group.controls?.country?.value === this.canadaCountryValue;
  }

  public isUsaSelectedMailing(group: any): boolean {
    return group.controls?.country?.value === this.usaCountryValue;
  }

  private postalCodeCAValidation = [
    Validators.maxLength(10),
    this.validators.validatorCanadianPostalCode,
    Validators.required,
  ];

  private postalCodeUSValidation = [
    Validators.maxLength(10),
    this.validators.validatorUnitedStatesPostalCode,
    Validators.required,
  ];

  private postalCodeNonNAValidation = [
    Validators.maxLength(10),
    this.validators.validatorAlphaNumPlusFew,
    this.forceNonCanadianPostalCode(),
  ];

  // this will show error if country is not Canada but is Canadian postal code entered
  public forceNonCanadianPostalCode(): ValidatorFn {
    const self = this;
    return (control: FormControl): ValidationErrors | null => {
      const errorValidCanadiaPostal =
        self.validators.validatorCanadianPostalCode(control);
      return !self.isCanadaSelectedMailing && !errorValidCanadiaPostal
        ? { mustBeNonCanadianPostalCode: true }
        : null;
    };
  }

  public provinceOrStateListMailing(group: any): FormSelectOption[] | null {
    if (this.isCanadaSelectedMailing(group)) {
      return this.lovService.lovs.provinceAbbrev;
    } else if (this.isUsaSelectedMailing(group)) {
      return this.lovService.lovs.stateAbbrev;
    } else {
      return null;
    }
  }

  private createAddressGroup(group: any): FormGroup {
    return this.fb.group({
      postOfficeBox: new FormControl(group.postOfficeBox, {
        validators: [
          Validators.maxLength(15),
          this.validators.validatorAlphaNumericPlusAllSymbols,
        ],
      }),
      apartmentUnit: new FormControl(group.apartmentUnit, {
        validators: [
          Validators.maxLength(10),
          this.validators.validatorAlphaNumericPlusAllSymbols,
        ],
      }),
      streetNumber: new FormControl(group.streetNumber, {
        validators: [
          Validators.maxLength(10),
          this.validators.validatorAlphaNumericPlusAllSymbols,
        ],
      }),
      streetName: new FormControl(group.streetName, {
        validators: [
          Validators.maxLength(100),
          this.validators.validatorAlphaNumericPlusAllSymbols,
          Validators.required,
        ],
      }),
      city: new FormControl(group.city, {
        validators: [
          Validators.maxLength(30),
          this.validators.validatorAlphaNumericPlusAllSymbols,
          Validators.required,
        ],
      }),
      country: new FormControl(group.country, {
        validators: [Validators.required],
      }),
      province: new FormControl(group.province, {
        validators: [],
      }),
      postalCode: new FormControl(group.postalCode, {
        validators: [
          Validators.maxLength(10),
          this.validators.validatorAlphaNumPlusFew,
        ],
      }),
      district: new FormControl(group.district, {
        validators: [
          Validators.maxLength(30),
          this.validators.validatorAlphaNumericPlusAllSymbols,
        ],
      }),
    });
  }

  private createProfileFormGroup(): FormGroup {
    return this.fb.group({
      correspondence: new FormControl(this.profile.correspondence, {
        validators: [Validators.required],
      }),
      familyName: new FormControl(this.profile.familyName, {
        validators: [
          Validators.required,
          Validators.maxLength(100),
          this.validators.validatorProperName,
        ],
      }),
      givenName: new FormControl(this.profile.givenName, {
        validators: [
          Validators.maxLength(100),
          this.validators.validatorProperName,
        ],
      }),
      dob: new FormControl(this.profile.dob, {
        validators: [],
      }),
      residentialAddress: this.residentialAddressGroup,
      mailingAddress: this.mailingAddressGroup,
      dependants: this.dependantList,
      residentialSameAsMailingAddress: new FormControl(
        this.profile.residentialSameAsMailingAddress
      ),
    });
  }

  private createDependentFormGroup(): FormGroup {
    return this.fb.group({
      relationshipToPA: new FormControl(this.dependant.relationshipToPA, {
        validators: [
          Validators.required,
          this.validatorSpouseOrCommonLaw.bind(this),
        ],
      }),
      accompanyingPA: new FormControl(this.dependant.accompanyingPA, {
        validators: [Validators.required],
      }),
      familyName: new FormControl(this.dependant.familyName, {
        validators: [
          Validators.required,
          Validators.maxLength(100),
          this.validators.validatorProperName,
        ],
      }),
      givenName: new FormControl(this.dependant.givenName, {
        validators: [
          Validators.maxLength(100),
          this.validators.validatorProperName,
        ],
      }),
      dob: new FormControl(this.dependant.dob, {
        validators: [],
      }),
    });
  }

  // toggle the validation of residentialAddressGroup
  public toggleResidentialAddressGroupValidation(visible: boolean) {
    if (visible) {
      this.residentialAddressGroup.enable();
      this.residentialAddressGroup.patchValue(this.profile.residentialAddress);
    } else {
      this.residentialAddressGroup.disable();
    }
    this.residentialAddressGroup.updateValueAndValidity();
  }

  // Watch residentialSameAsMailingAddress and update form group dynamically to handle residential address
  public watchResidentialSameAsMailingAddress(): void {
    this.subscriptionsList.push(
      this.profileForm.controls.residentialSameAsMailingAddress.valueChanges.subscribe(
        (value) => {
          this.showResidential = value === 1 || value === null ? false : true;
          this.toggleResidentialAddressGroupValidation(this.showResidential);
        }
      )
    );
  }

  // Watch conditional validation (postal code & district) for mailing address
  public watchMailingAddressCountryValue(
    addressGroups: Array<FormGroup>
  ): void {
    addressGroups.forEach((group) => {
      this.subscriptionsList.push(
        group.controls.country.valueChanges.subscribe((value) => {
          group.controls.province.reset();
          group.controls.postalCode.reset();
          group.controls.district.reset();

          if (value === this.canadaCountryValue) {
            /* Setting postal code and district validators based on country */
            group.controls.province.enable();
            group.controls.province.setValidators([Validators.required]);
            group.controls.province.updateValueAndValidity();
            group.controls.district.clearValidators();
            group.controls.district.disable();
            group.controls.postalCode.setValidators(
              this.postalCodeCAValidation
            );
          } else if (value === this.usaCountryValue) {
            group.controls.province.enable();
            group.controls.province.setValidators([Validators.required]);
            group.controls.province.updateValueAndValidity();
            group.controls.district.clearValidators();
            group.controls.district.disable();
            group.controls.postalCode.setValidators(
              this.postalCodeUSValidation
            );
          } else {
            group.controls.province.clearValidators();
            group.controls.province.disable();
            group.controls.district.enable();
            group.controls.postalCode.setValidators(
              this.postalCodeNonNAValidation
            );
          }

          group?.controls.postalCode.updateValueAndValidity();
        })
      );
    });
  }

  private markAllInputsAsTouched() {
    this.profileForm.markAllAsTouched();
    if (this.dependantFormVisible) {
      this.dependantForm.markAllAsTouched();
    }
  }

  private setIsFirstLoad(): void {
    console.log(this.caseId, typeof this.caseId);
    console.log(
      this.profileService.cachedProfile?.caseId,
      typeof this.profileService.cachedProfile?.caseId
    );
    this.isFirstLoad =
      this.profileService.cachedProfile?.profile?.familyName === null &&
      this.profileService.cachedProfile?.profile?.isComplete === null &&
      this.profileService.cachedProfile?.caseId === this.caseId;
  }
}
