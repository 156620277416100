import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { environment } from "../../../environments/environment";
import { CaseService } from "../../shared/case-module/case.service";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { DomSanitizer } from "@angular/platform-browser";
import { DocumentService } from "@pr-applicant/app/shared/services/document/document.service";
import { from, of } from "rxjs";
import { delay, retry, switchMap } from "rxjs/operators";
import { AwsSdkService } from "@pr-applicant/app/shared/services/aws-sdk/aws-sdk.service";
import { Document } from "@pr-applicant/app/core/models/document.model";

const apiVersion = environment.API_VERSION;

@Injectable({
  providedIn: "root",
})
export class PhotoService {
  public case: any;

  constructor(
    private store: Store<fromApp.State>,
    private caseService: CaseService,
    private awsService: AWSService,
    private sanitizer: DomSanitizer,
    private documentService: DocumentService,
    private awsSdkService: AwsSdkService
  ) {
    this.store.select("selectedCase").subscribe((caseData) => {
      this.case = caseData;
    });
  }

  public async uploadPhoto(
    selectedPhoto: any,
    selfDeclaration: boolean,
    coordinates: any,
    validationFeedback?: any,
    photoType?: any
  ): Promise<any> {
    const defaultFileExtension = "jpeg";
    const fileType = selectedPhoto.type;
    const fileName = selectedPhoto.name;
    let conventionFileName = `${this.case.id}/${this.case.uci}-photo.${defaultFileExtension}`;
    const photoTypeInt = parseInt(photoType);
    // if photo already exists
    let photo = null;
    const photosOnly = this.case.documents?.filter(
      (doc: any) => doc.documentTypeId === 1
    );
    if (photosOnly.length > 0) {
      photo = photosOnly[0]; // assuming only 1 photo will be there
    }

    if (photo) {
      conventionFileName = photo.documentName.slice(
        photo.documentName.indexOf("/") + 1
      );
    }

    try {
      const identityId = await this.awsService.getIdentityId();

      const apiName = environment.API_NAME;
      const pathPut = `/${apiVersion}/cases/${this.case.id}/documents/${
        photo ? photo.id : ""
      }`;
      const pathPost = `/${apiVersion}/cases/${this.case.id}/documents/`;
      const init: any = {
        headers: {
          Authorization: await this.awsService.getToken(),
        },
        body: {
          documentName: `${identityId}/${conventionFileName}`,
          documentTypeId: 1,
          selfDeclaration,
        },
      };
      if (!!validationFeedback) {
        init.body = {
          ...init.body,
          documentMetadata: {
            exposureApproved: validationFeedback.exposureApproved,
            backgroundApproved: validationFeedback.backgroundApproved,
            pictureSizeApproved: validationFeedback.pictureSizeApproved,
            photoType: photoTypeInt,
          },
        };
      }
      const result =
        photo !== null
          ? await this.awsService.api.put(apiName, pathPut, init)
          : await this.awsService.api.post(apiName, pathPost, init);

      await this.caseService.getCaseAndDocumentsByCaseId(
        this.case.emailAddress,
        this.case.id
      ); // update state before navigating

      return result;
    } catch (error) {
      throw error;
    }
  }

  public async storePhototoS3(
    selectedPhoto: any,
    coordinates: any
  ): Promise<any> {
    const defaultFileExtension = "jpeg";
    const fileType = selectedPhoto.type;
    const fileName = selectedPhoto.name;
    let conventionFileName = `${this.case.id}/${this.case.uci}-photo.${defaultFileExtension}`;

    let photo = null;
    const photosOnly = this.case.documents?.filter(
      (doc: any) => doc.documentTypeId === 1
    );

    // need to store BW photo from response to the document store
    if (photosOnly.length > 0) {
      photo = photosOnly[0]; // assuming only 1 photo will be there
    }

    if (photo) {
      conventionFileName = photo.documentName.slice(
        photo.documentName.indexOf("/") + 1
      );
    }
    return this.documentService
      .createFileChecksum(selectedPhoto)
      .pipe(
        switchMap((hash) =>
          this.awsSdkService.uploadFileToS3(
            conventionFileName,
            selectedPhoto,
            fileType,
            hash,
            coordinates
          )
        ),
        retry(2),
        delay(200)
      )
      .toPromise();
  }

  public async getPhotoValidationFeedback(fileName: string): Promise<any> {
    try {
      const identityId = await this.awsService.getIdentityId();
      const apiName = environment.API_NAME;
      const pathPost = `/photo-validation`;
      const init = {
        headers: {
          Authorization: await this.awsService.getToken(),
        },
        body: {
          s3Path: `${identityId}/${fileName}`,
        },
      };
      return this.awsService.api.post(apiName, pathPost, init);
    } catch (error) {
      throw error;
    }
  }

  // This method will get the information about a photo from S3
  public async getPhotoDetailsFromStorage(photoName: string): Promise<any> {
    try {
      const identityId = await this.awsService.getIdentityId();
      const photoLocation = `${identityId}/${photoName}`;
      const s3response: any = await this.awsService.storage.get(photoName, {
        identityId,
        download: true,
        cacheControl: "no-cache",
      });
      const blobFile = s3response.Body;
      const previewFile = new File([blobFile], photoName, {
        type: s3response.ContentType,
      });
      const photoLink = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(previewFile)
      );
      const metadata = s3response.Metadata;
      const coordinates = {
        heightCompare: parseFloat(metadata.heightcompare),
        widthCompare: parseFloat(metadata.widthcompare),
        leftDiff: parseFloat(metadata.leftdiff),
        topDiff: parseFloat(metadata.topdiff),
      };
      return { photoLink, photoName, photoLocation, coordinates, previewFile };
    } catch (err) {
      throw err;
    }
  }
}
