<section class="supporting-documents-upload">
  <h2 *ngIf="!isCurrentLobAFamilyClassLob">
    <span class="supporting-documents-upload__required">*</span
    >{{ "INTAKE.SUPPORTING_DOCUMENTS.HEADING" | translate }}
    {{ category }}
    <span class="supporting-documents-upload__required">{{
      "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
    }}</span>
  </h2>
  <h2 *ngIf="isCurrentLobAFamilyClassLob">
    <span class="supporting-documents-upload__required">*</span
    >{{ "INTAKE.SUPPORTING_DOCUMENTS.HEADING_FAMILY_CLASS" | translate }}
    <span class="supporting-documents-upload__required">{{
      "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
    }}</span>
  </h2>
  <div
    [innerHTML]="description"
    class="supporting-documents-upload__description"
  ></div>

  <!-- Supporting Documents Requirements  -->
  <h3>
    {{ "INTAKE.SUPPORTING_DOCUMENT_REQUIREMENTS_LIST.TITLE" | translate }}
  </h3>
  <p>
    {{ "INTAKE.SUPPORTING_DOCUMENT_REQUIREMENTS_LIST.DESCRIPTION" | translate }}
  </p>
  <ul
    [innerHTML]="
      'INTAKE.SUPPORTING_DOCUMENT_REQUIREMENTS_LIST.LIST' | translate
    "
  ></ul>

  <pra-documents-upload-table
    [isHiddenLob]="isHiddenLob"
    (supportDocumentsUpdated)="handleUpdate()"
    [requiredDocumentOptions]="requiredDocumentOptions"
  ></pra-documents-upload-table>
  <pra-other-documents
    id="additionalSupportingDocumentsAnchor"
    [isHiddenLob]="isHiddenLob"
    [otherDocumentOptions]="otherDocumentOptions"
  ></pra-other-documents>
  <div *ngIf="showMemberDocTable">
    <pra-referrel-partner-doc-table
      id="refDocAnchor"
      (supportDocumentsUpdated)="handleUpdate()"
      [caseMemberId]="caseMemberId"
      [requiredDocumentOptions]="memberDocumentOptions"
    >
    </pra-referrel-partner-doc-table>
  </div>

  <!-- Anchor Documents Table -->
  <div *ngIf="showAnchorDocTable">
    <pra-anchor-doc-table
      id="anchorDocAnchor"
      (supportDocumentsUpdated)="handleUpdate()"
      [caseMemberId]="caseMemberId"
      [memberDocumentOptions]="memberDocumentOptions"
    >
    </pra-anchor-doc-table>
  </div>

  <!-- Adding Extra Spacing -->
  <ng-container
    *ngIf="
      !this.requiredPOP &&
      (this.categoryId === 1000 || this.categoryId === 1001)
    "
  >
    <lib-layout-row mt="2">
      <lib-information-area [config]="emppConfig">
        <div
          [innerHTML]="'INTAKE.PROOF_OF_PAYMENT.EMPP_NOTIFICATION' | translate"
        ></div>
      </lib-information-area>
    </lib-layout-row>
  </ng-container>
  <div class="supporting-documents-upload__table" *ngIf="showPaymentTable">
    <pra-proof-of-payment-table
      id="paymentAnchor"
      [isHiddenLob]="isHiddenLob"
      (proofOfPaymentUpdated)="handleUpdate()"
    >
    </pra-proof-of-payment-table>
  </div>
</section>
