import { Injectable } from "@angular/core";
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
} from "@angular/forms";
import { Observable, map } from "rxjs";
import { CognitoHelperService } from "../utils/cognitoHelper.service";

@Injectable({
  providedIn: "root",
})
export class PsrValidationService {
  /* checks cognito for user is existing in the rep group*/
  validatorCheckEmailForPaidRep(
    congnitoHelper: CognitoHelperService
  ): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors | null> => {
      return congnitoHelper
        .isCorrectUserGroup(control.value.toString())
        .pipe(
          map((response) =>
            response.includes("Not an Applicant") ? { isPaidRep: true } : null
          )
        );
    };
  }
}
