<section class="invite-principal-applicant">
  <lib-layout-row mb="3">
    <header>
      <h1>
        {{ "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.PAGE_HEADING" | translate }}
      </h1>
      <p
        [innerHTML]="
          'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.PAGE_DETAILS' | translate
        "
      ></p>
    </header>
  </lib-layout-row>
  <section>
    <lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
    <form
      focusInvalidInput
      [formGroup]="invitePrincipalApplicant"
      autocomplete="off"
      (submit)="inviteConfirm($event)"
      *ngIf="!isProcessing"
    >
      <fieldset>
        <legend>
          {{ "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.INTRO_HEADING" | translate }}
        </legend>
        <div
          [innerHTML]="
            'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.INTRO_DETAILS' | translate
          "
        ></div>
      </fieldset>
      <pra-member-fields
        [form]="invitePrincipalApplicant"
        [translations]="translations"
      >
      </pra-member-fields>
      <pra-form-buttons [form]="invitePrincipalApplicant">
        <button type="submit" category="primary">
          {{ "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.SUBMIT" | translate }}
        </button>
        <button
          [routerLink]="
            routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'], '../')
          "
          type="button"
          category="secondary"
          role="link"
        >
          {{ "PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.FORM.CANCEL" | translate }}
        </button>
      </pra-form-buttons>
    </form>
  </section>
</section>

<pra-invite-confirm
  [modalId]="modalId"
  [title]="'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.MODAL.TITLE' | translate"
  [description]="
    'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.MODAL.DESCRIPTION' | translate
  "
  [sendText]="'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.MODAL.SEND' | translate"
  [cancelText]="'PSR.INVITE_PRINCIPAL_APPLICANT_PAGE.MODAL.CANCEL' | translate"
  (confirmation)="inviteSend($event)"
></pra-invite-confirm>
