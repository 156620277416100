import {
  DSSizes,
  NavigationItemType,
  INavigationItemAccordion,
  INavigationItemLink,
  INavigationConfig,
  IndicatorStatus,
} from "ircc-ds-angular-component-library";

export function createSideNav(): INavigationConfig {
  let anchorDoc: INavigationItemLink = {
    id: "anchorDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.ANCHOR_DOCUMENTS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "anchorDocAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };

  let referralPartnerDoc: INavigationItemLink = {
    id: "referralPartnerDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.REFERRAL_PARTNER_DOCUMENTS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "refDocAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };
  let declaration: INavigationItemLink = {
    id: "declarationDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.DECLARATION",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "declarationAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };

  let payment: INavigationItemLink = {
    id: "paymentDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.FEE_PAYMENT",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "paymentAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };

  let additionalSupportingDocuments: INavigationItemLink = {
    id: "additionalSupportingDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.ADDITIONAL_SUPPORTING_DOCUMENTS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "additionalSupportingDocumentsAnchor",
    header: true,
    children: [],
  };

  let supportingDocuments: INavigationItemLink = {
    id: "supportingDocuments",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.SUPPORTING_DOCUMENTS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "supportingDocumentsAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };

  let additionalForms: INavigationItemLink = {
    id: "additionalForms",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.ADDITIONAL_APPLICATION_FORMS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "additionalFormsAnchor",
    header: true,
    children: [],
  };

  let requiredPdfForms: INavigationItemLink = {
    id: "requiredPdfForms",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.PDF_FORMS",
    type: NavigationItemType.link,
    href: window.location.pathname,
    anchor: "requiredPdfFormsAnchor",
    header: true,
    indicator: {
      status: IndicatorStatus.neutral,
      icon: "fa-light fa-circle",
    },
    children: [],
  };

  let requiredFormsAccordion: INavigationItemAccordion = {
    id: "requiredFormsAccordion",
    label: "INTAKE.LANDING_PAGE.SIDE_NAV.APPLICATION_FORMS",
    type: NavigationItemType.accordion,
    open: true,
    leftPadding: true,
    bold: true,
    children: [],
  };

  return {
    id: "intakeSideNav",
    size: DSSizes.small,
    height: "100vh",
    marginTop: 0,
    scrolling: true,
    navigationConfig: [
      requiredFormsAccordion,
      requiredPdfForms,
      additionalForms,
      supportingDocuments,
      additionalSupportingDocuments,
      referralPartnerDoc,
      anchorDoc,
      payment,
      declaration,
    ],
  };
}
