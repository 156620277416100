<form autocomplete="off">
  <div>
    <lib-copy-block
      [body]="'INTAKE.IMM5669.FORMS.SECTION_A.FORM_INSTRUCTIONS' | translate"
    ></lib-copy-block>

    <!-- Indicate whether you are filling out this form for: -->

    <lib-form-radio
      [editable]="user.can('documents:write')"
      firstId="principalApplicant"
      secondId="principalOther"
      [firstValue]="true"
      [secondValue]="false"
      nameVal="principalApplicant"
      [firstLabel]="'INTAKE.IMM5669.FORMS.FIELDS.PA' | translate"
      [secondLabel]="'INTAKE.IMM5669.FORMS.FIELDS.NOT_PA' | translate"
      [formControl]="sectionAForm.controls.principalApplicant"
    >
    </lib-form-radio>

    <!-- Heading  -->

    <lib-copy-block
      [heading]="'INTAKE.IMM5669.FORMS.SECTION_A.FORM_HEADING' | translate"
    >
    </lib-copy-block>

    <!-- Your Full Name  -->
  </div>
  <fieldset class="fix-spacing">
    <legend>
      {{
        "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FAMILY_GIVEN_NAME" | translate
      }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="familyName"
      [formControl]="sectionAForm.controls.familyName"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.FAMILY_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAForm.get('familyName')?.touched &&
          sectionAForm.get('familyName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>

    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="givenName"
      [formControl]="sectionAForm.controls.givenName"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.GIVEN_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAForm.get('givenName')?.touched &&
          sectionAForm.get('givenName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Your Full Name in your native language -->

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FULL_NAME" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="nativeFullName"
      [formControl]="sectionAForm.controls.nativeFullName"
      [helpText]="
        'INTAKE.IMM5669.FORMS.SECTION_A.LABEL.FULL_NAME_HELP' | translate
      "
      [label]="'INTAKE.IMM5669.FORMS.SECTION_A.LABEL.FULL_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAForm.get('nativeFullName')?.touched &&
          sectionAForm.get('nativeFullName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.nativeFullName }
        }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Birth information -->

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.BIRTH" | translate }}
    </legend>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="dob"
      [formControl]="sectionAForm.controls.dob"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.DOB' | translate"
    >
    </lib-form-datepicker>
  </fieldset>

  <!-- Personal details about your father -->

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.FATHER" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="familyNameFather"
      [formControl]="sectionAFormFather.controls.familyName"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.FAMILY_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAFormFather.get('familyName')?.touched &&
          sectionAFormFather.get('familyName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>

    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="givenNameFather"
      [formControl]="sectionAFormFather.controls.givenName"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.GIVEN_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAFormFather.get('givenName')?.touched &&
          sectionAFormFather.get('givenName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>
  </fieldset>

  <fieldset>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="sectionAFormFatherDOB"
      [formControl]="sectionAFormFather.controls.dob"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.DOB' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="sectionAFormFatherDeceasedDate"
      [formControl]="sectionAFormFather.controls.deceasedDate"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.DOD' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
  </fieldset>

  <fieldset>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="sectionAFormFatherCityOfBirth"
      [formControl]="sectionAFormFather.controls.cityOfBirth"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.CITY_OF_BIRTH' | translate"
    >
      <span
        *ngIf="
          sectionAFormFather.get('cityOfBirth')?.touched &&
          sectionAFormFather.get('cityOfBirth')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.cityOfBirth }
        }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="sectionAFormFatherCountryOfBirth"
      [formControl]="sectionAFormFather.controls.countryOfBirth"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.COUNTRY_OF_BIRTH' | translate"
    >
      <span
        *ngIf="
          sectionAFormFather.get('countryOfBirth')?.touched &&
          sectionAFormFather.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.countryOfBirth }
        }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Personal details about your mother -->

  <fieldset>
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_A.LEGEND.MOTHER" | translate }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="familyNameMother"
      [formControl]="sectionAFormMother.controls.familyName"
      [label]="
        'INTAKE.IMM5669.FORMS.SECTION_A.LABEL.MOTHER_FAMILY_NAME' | translate
      "
    >
      <span
        *ngIf="
          sectionAFormMother.get('familyName')?.touched &&
          sectionAFormMother.get('familyName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>

    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="givenNameMother"
      [formControl]="sectionAFormMother.controls.givenName"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.GIVEN_NAME' | translate"
    >
      <span
        *ngIf="
          sectionAFormMother.get('givenName')?.touched &&
          sectionAFormMother.get('givenName')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.givenName }
        }}
      </span>
    </lib-form-input>
  </fieldset>

  <fieldset>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="sectionAFormMotherDOB"
      [formControl]="sectionAFormMother.controls.dob"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.DOB' | translate"
      [pastDate]="true"
    >
      <span
        *ngIf="
          sectionAFormMother.get('dob')?.touched &&
          sectionAFormMother.get('dob')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.dob }
        }}
      </span>
    </lib-form-datepicker>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="sectionAFormMotherDeceasedDate"
      [formControl]="sectionAFormMother.controls.deceasedDate"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.DOD' | translate"
      [pastDate]="true"
    >
    </lib-form-datepicker>
  </fieldset>

  <fieldset>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="sectionAFormMotherCityOfBirth"
      [formControl]="sectionAFormMother.controls.cityOfBirth"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.CITY_OF_BIRTH' | translate"
    >
      <span
        *ngIf="
          sectionAFormMother.get('cityOfBirth')?.touched &&
          sectionAFormMother.get('cityOfBirth')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.cityOfBirth }
        }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="sectionAFormMotherCountryOfBirth"
      [formControl]="sectionAFormMother.controls.countryOfBirth"
      [label]="'INTAKE.IMM5669.FORMS.FIELDS.COUNTRY_OF_BIRTH' | translate"
    >
      <span
        *ngIf="
          sectionAFormMother.get('countryOfBirth')?.touched &&
          sectionAFormMother.get('countryOfBirth')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: maxFieldLengths.countryOfBirth }
        }}
      </span>
    </lib-form-input>
  </fieldset>
</form>
