<h1 class="emphasis">{{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}</h1>
<h2 class="emphasis">{{ "INTAKE.DECLARATION.ESIG.PAGE_TITLE" | translate }}</h2>

<pra-imm0008-consent-declaration-form></pra-imm0008-consent-declaration-form>

<lib-layout-row gap="one-and-half" mt="3">
  <a
    [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE9', '../')"
    category="secondary"
  >
    {{ "INTAKE.FORM_PAGE_BACK" | translate }}
  </a>
  <a
    [routerLink]="
      routeLocalizer.getNestedPath(
        lang,
        ['INTAKE', 'INTAKE_CASE_DETAILS'],
        '../../../'
      ) + caseId
    "
    category="primary"
  >
    {{ "INTAKE.FORM_PAGE_COMPLETE" | translate }}
  </a>
</lib-layout-row>
