<button
  class="error-link"
  category="plain"
  *ngIf="form.invalid && form.touched"
  type="submit"
>
  <img
    class="arrow_up_icon"
    src="../../assets/images/icons/arrow-up.svg"
    aria-hidden="true"
    alt=""
  />
  {{ "INTAKE.PROFILE.ERROR_MESSAGE" | translate }}
</button>
