<ng-container *ngIf="!isDataLoading">
  <lib-copy-block [body]="'INTAKE.IMM5562.PAGES.PAGE_DESCRIPTION' | translate">
  </lib-copy-block>
  <lib-copy-block
    [heading]="'INTAKE.IMM5562.FORMS.SECTION_A.FORM_HEADING' | translate"
  >
  </lib-copy-block>
  <form autocomplete="off">
    <fieldset>
      <legend>
        {{ "INTAKE.IMM5562.FORMS.FIELDS.TRIP.FULL_NAME_LEGEND" | translate }}
      </legend>
      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="familyName"
        [formControl]="sectionAForm.controls.familyName"
        [label]="
          'INTAKE.IMM5562.FORMS.FIELDS.TRIP.FAMILY_NAME_LABEL' | translate
        "
        [widthMd]="true"
      >
        <span
          *ngIf="
            sectionAForm.get('familyName')?.touched &&
            sectionAForm.get('familyName')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.givenName }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="givenName"
        [formControl]="sectionAForm.controls.givenName"
        [label]="
          'INTAKE.IMM5562.FORMS.FIELDS.TRIP.GIVEN_NAME_LABEL' | translate
        "
        [widthMd]="true"
      >
        <span
          *ngIf="
            sectionAForm.get('givenName')?.touched &&
            sectionAForm.get('givenName')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.givenName }
          }}
        </span>
      </lib-form-input>
    </fieldset>
    <div class="fix-spacing">
      <lib-copy-block
        [body]="'INTAKE.IMM5562.FORMS.SECTION_A.FORM_INSTRUCTIONS' | translate"
      >
      </lib-copy-block>

      <!-- did not travel checkbox -->
    </div>
    <fieldset>
      <lib-form-checkbox
        [editable]="user.can('documents:write')"
        identifier="haveNotTravelled"
        [label]="
          'INTAKE.IMM5562.FORMS.FIELDS.DID_NOT_TRAVEL_CHECKBOX_LABEL'
            | translate
        "
        nameVal="haveNotTravelled"
        [description]="
          'INTAKE.IMM5562.FORMS.FIELDS.DID_NOT_TRAVEL_CHECKBOX_DESC' | translate
        "
        [formControl]="sectionAForm.controls.haveNotTravelled"
        [isRequired]="false"
      >
      </lib-form-checkbox>
    </fieldset>

    <!-- repeating fieldset -->
    <ng-container
      *ngFor="
        let trip of trips?.controls;
        index as i;
        first as isFirst;
        last as isLast
      "
      formArray="tripsArray"
    >
      <fieldset [formGroup]="trip">
        <legend>
          {{ "INTAKE.IMM5562.FORMS.FIELDS.TRIP.LEGEND" | translate }}
          {{ i + 1 }}
        </legend>
        <!-- date from -->
        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          [identifier]="'trip-from-' + i"
          [formControl]="trip.controls.from"
          [label]="
            'INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_FROM_LABEL' | translate
          "
          [shortFormat]="true"
        >
        </lib-form-datepicker>
        <!-- date to -->
        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          [identifier]="'trip-to-' + i"
          [formControl]="trip.controls.to"
          [label]="'INTAKE.IMM5562.FORMS.FIELDS.TRIP.DATE_TO_LABEL' | translate"
          [shortFormat]="true"
        >
        </lib-form-datepicker>

        <!-- destination -->
        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'trip-destination-' + i"
          [required]=""
          [formControl]="trip.controls.destination"
          [label]="
            'INTAKE.IMM5562.FORMS.FIELDS.TRIP.DESTINATION_LABEL' | translate
          "
        >
          <span
            *ngIf="
              trip.get('destination')?.touched &&
              trip.get('destination')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: maxFieldLengths.destination }
            }}
          </span>
        </lib-form-input>

        <!-- trip length -->
        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'trip-length-' + i"
          [required]=""
          [formControl]="trip.controls.length"
          [label]="
            'INTAKE.IMM5562.FORMS.FIELDS.TRIP.TRIP_LENGTH_LABEL' | translate
          "
        >
          <span
            *ngIf="
              trip.get('length')?.touched &&
              trip.get('length')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: maxFieldLengths.tripLength }
            }}
          </span>
        </lib-form-input>

        <!-- Purpose -->
        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'trip-purpose-' + i"
          [required]=""
          [formControl]="trip.controls.purposeOfTravel"
          [label]="'INTAKE.IMM5562.FORMS.FIELDS.TRIP.PURPOSE_LABEL' | translate"
        >
          <span
            *ngIf="
              trip.get('purposeOfTravel')?.touched &&
              trip.get('purposeOfTravel')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: maxFieldLengths.purpose }
            }}
          </span>
        </lib-form-input>

        <!-- Buttons -->
        <lib-layout-row gap="one-and-half">
          <button
            *ngIf="!isFirst"
            type="button"
            category="secondary"
            color="critical"
            (click)="removeTrip(i)"
            [disabled]="isReadOnly"
          >
            {{ "INTAKE.IMM5562.FORMS.FIELDS.TRIP.BUTTONS.REMOVE" | translate }}
            <span class="visually-hidden"
              >{{ "INTAKE.IMM5562.FORMS.FIELDS.TRIP.LEGEND" | translate }}
              {{ i + 1 }}</span
            >
          </button>
          <button
            *ngIf="isLast"
            type="button"
            [disabled]="isReadOnly"
            category="primary"
            (click)="addTrip(i)"
          >
            {{ "INTAKE.IMM5562.FORMS.FIELDS.TRIP.BUTTONS.ADD" | translate }}
          </button>
        </lib-layout-row>
      </fieldset>
    </ng-container>
  </form>
</ng-container>
