<lib-copy-block
  [body]="'INTAKE.IMM5406.FORMS.FORM_INSTRUCTIONS' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5406.FORMS.SECTION_C.HEADING' | translate"
  [body]="'INTAKE.IMM5406.FORMS.SECTION_C.INTRO_TEXT' | translate"
></lib-copy-block>
<form autocomplete="off">
  <!-- Siblings info -->
  <ng-container
    *ngFor="
      let sibling of siblingsFormArray?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="siblingsFormArray"
  >
    <fieldset
      [formGroup]="sibling"
      class="form-array__fieldset"
      data-cy="section-c-5406"
    >
      <legend>
        {{ "INTAKE.IMM5406.FORMS.SECTION_C.SIBLING_LEGEND" | translate }}
        {{ i + 1 }}
      </legend>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'relationship' + i"
        [formControl]="sibling.controls.relationship"
        [label]="'INTAKE.IMM5406.FORMS.RELATIONSHIP_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            sibling.get('relationship')?.touched &&
            sibling.get('relationship')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.relationship }
          }}
        </span>
      </lib-form-input>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'fullName' + i"
        [formControl]="sibling.controls.fullName"
        [label]="'INTAKE.IMM5406.FORMS.FULL_NAME_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            sibling.get('fullName')?.touched &&
            sibling.get('fullName')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.fullName }
          }}
        </span>
      </lib-form-input>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'dob' + i"
        [formControl]="sibling.controls.dob"
        [label]="'INTAKE.IMM5406.FORMS.DOB_LABEL' | translate"
        [pastDate]="true"
      >
      </lib-form-datepicker>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'countryOfBirth' + i"
        [formControl]="sibling.controls.countryOfBirth"
        [label]="'INTAKE.IMM5406.FORMS.BIRTHPLACE_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            sibling.get('countryOfBirth')?.touched &&
            sibling.get('countryOfBirth')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.countryOfBirth }
          }}
        </span>
      </lib-form-input>

      <lib-form-select
        [editable]="user.can('documents:write')"
        [identifier]="'maritalStatus' + i"
        [formControl]="sibling.controls.maritalStatus"
        [label]="'INTAKE.IMM5406.FORMS.MARITAL_STATUS_LABEL' | translate"
        widthMd="true"
        [options]="maritalStatusOptions"
      ></lib-form-select>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'emailAddress' + i"
        [formControl]="sibling.controls.emailAddress"
        [label]="'INTAKE.IMM5406.FORMS.EMAIL_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            sibling.get('emailAddress')?.touched &&
            sibling.get('emailAddress')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.emailAddress }
          }}
        </span>
      </lib-form-input>

      <lib-form-textarea
        [editable]="user.can('documents:write')"
        [identifier]="'address' + i"
        [formControl]="sibling.controls.address"
        [label]="
          'INTAKE.IMM5406.FORMS.ADDRESS_LABEL'
            | translate : { max: formMaxLength.address }
        "
      >
        <span
          *ngIf="
            sibling.get('address')?.touched &&
            sibling.get('address')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.address }
          }}
        </span>
      </lib-form-textarea>

      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          type="button"
          [disabled]="isReadOnly"
          category="secondary"
          color="critical"
          (click)="removeSibling(i)"
        >
          {{ "INTAKE.IMM5406.FORMS.SECTION_C.DELETE" | translate }}
        </button>
        <button
          *ngIf="isLast"
          type="button"
          [disabled]="isReadOnly"
          category="primary"
          (click)="addSibling()"
        >
          {{ "INTAKE.IMM5406.FORMS.SECTION_C.ADD" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
