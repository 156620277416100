import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { PsrValidationService } from "@pr-applicant/app/shared/services/psr/psr-validation.service";
import {
  IGroupMember,
  IPrincipalApplicantsRep,
  PsrService,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { CognitoHelperService } from "@pr-applicant/app/shared/services/utils/cognitoHelper.service";
import { AlertService, ModalService, ValidationService } from "lib";
import { Subscription, first } from "rxjs";
interface IConfig {
  header: string;
  description: string;
}

@Component({
  selector: "pra-invite-group-members",
  templateUrl: "./invite-group-members.component.html",
  styleUrls: ["./invite-group-members.component.scss"],
})
export class InviteGroupMembersComponent implements OnInit {
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private activeRoute: ActivatedRoute,
    private validationService: ValidationService,
    private modalService: ModalService,
    private psrService: PsrService,
    private psrValidationService: PsrValidationService,
    private cognitoService: CognitoHelperService,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}
  @Input() isPrimaryContact?: boolean = false;
  @Output() public childForm = new EventEmitter<FormGroup>();
  public lang: string;
  public caseId: string = "";
  private subscriptions: Subscription[] = [];
  public isProcessing: boolean = false; // toggle true/false based on api response
  public modalId = "confirmSendInvite";
  public returnPath: string;
  public config: IConfig;

  private memberConfig: IConfig = {
    header: "PSR.INVITE_GROUP_MEMBER_PAGE.PAGE_HEADING",
    description: "PSR.INVITE_GROUP_MEMBER_PAGE.PAGE_DETAILS",
  };
  private primaryContactConfig: IConfig = {
    header: "PSR.SPONSOR_PROFILE_PAGE.PAGE_HEADING",
    description: "PSR.SPONSOR_PROFILE_PAGE.FORM_DESCRIPTION",
  };

  public inviteGroupMemberForm = new FormGroup(
    {
      familyName: new FormControl("", {
        validators: [Validators.required],
      }),
      givenName: new FormControl("", {
        validators: [],
      }),
      emailAddress: new FormControl("", {
        validators: [Validators.required, Validators.email],
        asyncValidators: [
          this.psrValidationService.validatorCheckEmailForPaidRep(
            this.cognitoService
          ),
        ],

        updateOn: "blur",
      }),
      emailConfirm: new FormControl("", {
        validators: [
          Validators.email,
          // this.validationService.validatorCheckEmailForPaidRep,
        ],
      }),
      isPrimaryContact: new FormControl("", {
        validators: [],
      }),
    },
    this.validationService.validatorEmailMatching
  );

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.returnPath = this.routeLocalizer.getNestedPath(
      this.lang,
      ["MANAGE_GROUP_MEMBERS"],
      "../"
    );
    this.subscriptions.push(
      this.activeRoute.params.subscribe((params) => {
        this.caseId = params.caseId;
      })
    );
    this.isPrimaryContact
      ? (this.config = this.primaryContactConfig)
      : (this.config = this.memberConfig);
    this.childForm.emit(this.inviteGroupMemberForm);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.childForm.emit(undefined);
  }

  inviteGroupMember(e: any) {
    if (this.inviteGroupMemberForm.invalid) {
      this.inviteGroupMemberForm.markAllAsTouched();
    } else {
      this.modalService.open(this.modalId);
    }
  }

  inviteSend(check: boolean) {
    const sponsorMember = this.inviteGroupMemberForm.value;
    delete sponsorMember.emailConfirm;
    if (check) {
      this.psrService
        .addMember(
          sponsorMember as IPrincipalApplicantsRep,
          Number(this.caseId),
          RoleIds.SPONSOR_MEMBER
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.router.navigate([`${this.returnPath}`], {
              relativeTo: this.activeRoute,
            });
          },
          error: (error) => {
            console.log("Invite Error", error.response.status);
            if (error.response.status === 409) {
              this.alertService.danger(
                this.translate.instant(
                  "PSR.INVITE_GROUP_MEMBER_PAGE.MEMBER_EXISTS"
                )
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          },
        });
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }
}
