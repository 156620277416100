import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { Resolve } from "@angular/router";
import { CaseService } from "./case.service";
import * as CaseActions from "./store/case.actions";

@Injectable({
  providedIn: "root",
})
export class SelectedCaseResetResolver implements Resolve<boolean> {
  case: any;

  constructor(private store: Store<fromApp.State>) {}

  async resolve(): Promise<any> {
    this.store.dispatch(new CaseActions.ResetCaseInfo());
    return true;
  }
}
