<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_C.FORM_HEADING' | translate"
></lib-copy-block>

<form autocomplete="off">
  <fieldset [formGroup]="sectionCForm" class="form-array__fieldset">
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_C.SUMMARY_LEGEND" | translate }}
    </legend>

    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="elementarySchoolYears"
      [formControl]="sectionCForm.controls.elementarySchoolYears"
      [label]="'INTAKE.IMM5669.FORMS.SECTION_C.ELEMENTARY' | translate"
      widthMd="true"
    >
      <span
        *ngIf="
          sectionCForm.get('elementarySchoolYears')?.touched &&
          sectionCForm.get('elementarySchoolYears')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: formMaxLength.elementarySchoolYears }
        }}
      </span>
      <span
        *ngIf="
          sectionCForm.get('elementarySchoolYears')?.touched &&
          sectionCForm.get('elementarySchoolYears')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="secondarySchoolYears"
      [formControl]="sectionCForm.controls.secondarySchoolYears"
      [label]="'INTAKE.IMM5669.FORMS.SECTION_C.SECONDARY' | translate"
      widthMd="true"
    >
      <span
        *ngIf="
          sectionCForm.get('secondarySchoolYears')?.touched &&
          sectionCForm.get('secondarySchoolYears')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: formMaxLength.secondarySchoolYears }
        }}
      </span>
      <span
        *ngIf="
          sectionCForm.get('secondarySchoolYears')?.touched &&
          sectionCForm.get('secondarySchoolYears')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
    </lib-form-input>

    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="universityAndCollegeYears"
      [formControl]="sectionCForm.controls.universityAndCollegeYears"
      [label]="'INTAKE.IMM5669.FORMS.SECTION_C.UNIVERSITY' | translate"
      widthMd="true"
    >
      <span
        *ngIf="
          sectionCForm.get('universityAndCollegeYears')?.touched &&
          sectionCForm.get('universityAndCollegeYears')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: formMaxLength.universityAndCollegeYears }
        }}
      </span>
      <span
        *ngIf="
          sectionCForm.get('universityAndCollegeYears')?.touched &&
          sectionCForm.get('universityAndCollegeYears')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="otherSchoolYears"
      [formControl]="sectionCForm.controls.otherSchoolYears"
      [label]="'INTAKE.IMM5669.FORMS.SECTION_C.TRADE_SCHOOL' | translate"
      widthMd="true"
    >
      <span
        *ngIf="
          sectionCForm.get('otherSchoolYears')?.touched &&
          sectionCForm.get('otherSchoolYears')?.hasError('maxlength')
        "
      >
        {{
          "INTAKE.FORM_ERRORS.MAX_LENGTH"
            | translate : { max: formMaxLength.otherSchoolYears }
        }}
      </span>
      <span
        *ngIf="
          sectionCForm.get('otherSchoolYears')?.touched &&
          sectionCForm.get('otherSchoolYears')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
    </lib-form-input>
  </fieldset>
  <fieldset>
    <legend>
      {{ "INTAKE.IMM5669.FORMS.SECTION_C.DETAILS_LEGEND" | translate }}
    </legend>

    <ng-container
      *ngFor="
        let educationDetail of educationHistory?.controls;
        index as i;
        last as isLast;
        first as isFirst
      "
      formArray="educationHistory"
    >
      <fieldset [formGroup]="educationDetail" class="form-array__fieldset">
        <legend>
          {{ "INTAKE.IMM5669.FORMS.SECTION_C.EDUCATION" | translate }}
          {{ i + 1 }}
        </legend>

        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          [identifier]="'from' + i"
          [formControl]="educationDetail.controls.from"
          [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
          [shortFormat]="true"
        >
        </lib-form-datepicker>

        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          [identifier]="'to' + i"
          [formControl]="educationDetail.controls.to"
          [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
          [shortFormat]="true"
        >
        </lib-form-datepicker>

        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'nameOfInstitution' + i"
          [formControl]="educationDetail.controls.nameOfInstitution"
          [label]="
            'INTAKE.IMM5669.FORMS.SECTION_C.NAME_OF_INSTITUTION' | translate
          "
          widthMd="true"
        >
          <span
            *ngIf="
              educationDetail.get('nameOfInstitution')?.touched &&
              educationDetail.get('nameOfInstitution')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: formMaxLength.detail.nameOfInstitution }
            }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'cityAndCountry' + i"
          [formControl]="educationDetail.controls.cityAndCountry"
          [label]="'INTAKE.IMM5669.FORMS.FIELDS.CITY' | translate"
          widthMd="true"
        >
          <span
            *ngIf="
              educationDetail.get('cityAndCountry')?.touched &&
              educationDetail.get('cityAndCountry')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: formMaxLength.detail.cityAndCountry }
            }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'typeOfDiploma' + i"
          [formControl]="educationDetail.controls.typeOfDiploma"
          [label]="
            'INTAKE.IMM5669.FORMS.SECTION_C.TYPE_OF_CERTIFICATE' | translate
          "
          widthMd="true"
        >
          <span
            *ngIf="
              educationDetail.get('typeOfDiploma')?.touched &&
              educationDetail.get('typeOfDiploma')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: formMaxLength.detail.typeOfDiploma }
            }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          [identifier]="'fieldOfStudy' + i"
          [formControl]="educationDetail.controls.fieldOfStudy"
          [label]="'INTAKE.IMM5669.FORMS.SECTION_C.FIELD_OF_STUDY' | translate"
          widthMd="true"
        >
          <span
            *ngIf="
              educationDetail.get('fieldOfStudy')?.touched &&
              educationDetail.get('fieldOfStudy')?.hasError('maxlength')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.MAX_LENGTH"
                | translate : { max: formMaxLength.detail.fieldOfStudy }
            }}
          </span>
        </lib-form-input>

        <lib-layout-row gap="one-and-half">
          <button
            *ngIf="!isFirst"
            [disabled]="isReadOnly"
            type="button"
            category="secondary"
            color="critical"
            (click)="removeEducation(i)"
          >
            {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.REMOVE" | translate }}
          </button>
          <button
            *ngIf="isLast"
            [disabled]="isReadOnly"
            type="button"
            category="primary"
            (click)="addEducation()"
          >
            {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.ADD" | translate }}
          </button>
        </lib-layout-row>
      </fieldset>
    </ng-container>
  </fieldset>
</form>
