import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "pra-error-link",
  templateUrl: "./error-link.component.html",
  styleUrls: ["./error-link.component.scss"],
})
export class ErrorLinkComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
