<header class="page-header">
  <h1 *ngIf="isClientAccount && !isPsr && !isGars" class="page__title">
    {{ "INTAKE.PROFILE.PAGE_TITLE" | translate }}
  </h1>
  <h1
    *ngIf="(isClientAccount && isPsr) || (isClientAccount && isGars)"
    class="page__title"
  >
    {{ "INTAKE.PROFILE.PSR_PAGE_TITLE" | translate }}
  </h1>

  <h1 *ngIf="!isClientAccount" class="page__title">
    {{ "INTAKE.PROFILE.REP_PAGE_TITLE" | translate }}
  </h1>
  <p>{{ "INTAKE.PROFILE.LEGEND" | translate }}</p>
</header>
<lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
<ng-container *ngIf="!isProcessing">
  <form
    focusInvalidInput
    class="profile-form"
    [formGroup]="profileForm"
    autocomplete="off"
    (submit)="saveProfileForm()"
  >
    <fieldset>
      <!-- Language preference-->
      <!-- Correspondence -->
      <lib-form-select
        [editable]="user.can('documents:write')"
        identifier="profileForm-correspondence"
        class="country-dropdown"
        data-cy="profile-form-correspondence"
        widthMd="true"
        [formControl]="profileForm.controls.correspondence"
        [label]="'INTAKE.PROFILE.CORRESPONDANCE_LANGUAGE' | translate"
        [options]="correspondenceOptions"
        [required]="true"
      >
        <span
          *ngIf="
            profileForm.get('correspondence')?.touched &&
            profileForm.get('correspondence')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <!-- Family Name -->
      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="profileForm-familyName"
        widthMd="true"
        required="true"
        (sendBlurEvent)="
          handleTrimByControl($event, profileForm.controls.familyName)
        "
        [formControl]="profileForm.controls.familyName"
        [label]="
          'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL'
            | translate
        "
      >
        <span
          *ngIf="
            profileForm.get('familyName')?.touched &&
            profileForm.get('familyName')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            profileForm.get('familyName')?.touched &&
            profileForm.get('familyName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
        </span>
        <span
          *ngIf="
            profileForm.get('familyName')?.touched &&
            profileForm.get('familyName')?.hasError('properName')
          "
        >
          {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
        </span>
      </lib-form-input>

      <!-- Given Name -->
      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="personalDetailsForm-givenName"
        widthMd="true"
        (sendBlurEvent)="
          handleTrimByControl($event, profileForm.controls.givenName)
        "
        [formControl]="profileForm.controls.givenName"
        [label]="
          'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL'
            | translate
        "
      >
        <span
          *ngIf="
            profileForm.get('givenName')?.touched &&
            profileForm.get('givenName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
        </span>
        <span
          *ngIf="
            profileForm.get('givenName')?.touched &&
            profileForm.get('givenName')?.hasError('properName')
          "
        >
          {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
        </span>
      </lib-form-input>

      <!-- Birth info -->
      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        identifier="personalDetailsForm-dob"
        [required]="true"
        [formControl]="profileForm.controls.dob"
        [pastDate]="true"
        [label]="
          'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.DOB_LABEL'
            | translate
        "
      >
      </lib-form-datepicker>
    </fieldset>

    <fieldset>
      <!-- CURRENT ADDRESS -->
      <legend>
        {{ "INTAKE.PROFILE.MAILING_ADDRESS_TITLE" | translate }}
      </legend>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          [editable]="user.can('documents:write')"
          identifier="postOfficeBox"
          [formControl]="mailingAddressGroup.get('postOfficeBox')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POBOX_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('postOfficeBox')?.touched &&
              mailingAddressGroup.get('postOfficeBox')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max15 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postOfficeBox')?.touched &&
              mailingAddressGroup.get('postOfficeBox')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="apartmentUnit"
          [formControl]="mailingAddressGroup.get('apartmentUnit')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('apartmentUnit')?.touched &&
              mailingAddressGroup.get('apartmentUnit')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('apartmentUnit')?.touched &&
              mailingAddressGroup.get('apartmentUnit')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="streetNumber"
          [formControl]="mailingAddressGroup.get('streetNumber')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('streetNumber')?.touched &&
              mailingAddressGroup.get('streetNumber')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('streetNumber')?.touched &&
              mailingAddressGroup.get('streetNumber')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="streetName"
          [formControl]="mailingAddressGroup.get('streetName')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL' | translate
          "
          (sendBlurEvent)="
            handleTrimByControl($event, mailingAddressGroup.controls.streetName)
          "
          [required]="true"
          [widthMd]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('streetName')?.touched &&
              mailingAddressGroup.get('streetName')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('streetName')?.touched &&
              mailingAddressGroup.get('streetName')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('streetName')?.touched &&
              mailingAddressGroup.get('streetName')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-row>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="city"
          [formControl]="mailingAddressGroup.controls.city"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL' | translate
          "
          (sendBlurEvent)="
            handleTrimByControl($event, mailingAddressGroup.controls.city)
          "
          [required]="true"
          [widthMd]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('city')?.touched &&
              mailingAddressGroup.get('city')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('city')?.touched &&
              mailingAddressGroup.get('city')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('city')?.touched &&
              mailingAddressGroup.get('city')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="country"
          class="country-dropdown"
          data-cy="address-form-mailing-country"
          [formControl]="mailingAddressGroup.controls.country"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL' | translate
          "
          [options]="countriesList"
          [required]="true"
          [widthSm]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('country')?.touched &&
              mailingAddressGroup.get('country')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>

        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="province"
          data-cy="address-form-mailing-province-state"
          [formControl]="mailingAddressGroup.controls.province"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL'
              | translate
          "
          [options]="provinceOrStateListMailing(mailingAddressGroup)"
          [widthSm]="true"
        >
          <span
            *ngIf="
              (mailingAddressGroup.get('country')?.value ||
                mailingAddressGroup.get('country')?.dirty ||
                mailingAddressGroup.get('province')?.dirty) &&
              mailingAddressGroup.get('province')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>
      </lib-layout-row>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="postalCode"
          [formControl]="mailingAddressGroup.controls.postalCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL' | translate
          "
          [required]="
            isCanadaSelectedMailing(mailingAddressGroup) ||
            isUsaSelectedMailing(mailingAddressGroup)
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              (mailingAddressGroup.get('country')?.value ||
                mailingAddressGroup.get('country')?.dirty ||
                mailingAddressGroup.get('postalCode')?.touched) &&
              mailingAddressGroup.get('postalCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postalCode')?.touched &&
              mailingAddressGroup.get('postalCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postalCode')?.touched &&
              mailingAddressGroup.get('postalCode')?.hasError('CAPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_CA" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postalCode')?.touched &&
              mailingAddressGroup.get('postalCode')?.hasError('USPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_US" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postalCode')?.touched &&
              mailingAddressGroup.get('postalCode')?.hasError('alphaNumPlusFew')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_NON_NA" | translate }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('postalCode')?.touched &&
              mailingAddressGroup
                .get('postalCode')
                ?.hasError('mustBeNonCanadianPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_MUSTBE_NON_CA" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="district"
          [formControl]="mailingAddressGroup.controls.district"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL' | translate
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              mailingAddressGroup.get('district')?.touched &&
              mailingAddressGroup.get('district')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              mailingAddressGroup.get('district')?.touched &&
              mailingAddressGroup.get('district')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-row>
    </fieldset>
    <fieldset>
      <!-- RESIDENTIAL SAME AS MAILING -->
      <lib-form-radio
        [editable]="user.can('documents:write')"
        nameVal="residentialSameAsMailingAddress"
        [legend]="'INTAKE.PROFILE.RESIDENTIAL_SAME_AS_MAILING' | translate"
        firstId="yes"
        secondId="no"
        [firstValue]="1"
        [secondValue]="0"
        [firstLabel]="'INTAKE.PROFILE.RADIO_YES' | translate"
        [secondLabel]="'INTAKE.PROFILE.RADIO_NO' | translate"
        [formControl]="profileForm.controls.residentialSameAsMailingAddress"
        [required]="true"
      >
        <span
          class="error-radio"
          *ngIf="
            profileForm.controls.residentialSameAsMailingAddress?.touched &&
            profileForm.controls.residentialSameAsMailingAddress?.hasError(
              'required'
            )
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-radio>
      <!-- END OF RESIDENTIAL SAME AS MAILING -->
    </fieldset>
    <fieldset *ngIf="showResidential">
      <!-- RESIDENTIAL ADDRESS -->
      <legend>
        {{ "INTAKE.PROFILE.RESIDENTIAL_ADDRESS_TITLE" | translate }}
      </legend>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="apartmentUnit"
          [formControl]="residentialAddressGroup.get('apartmentUnit')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('apartmentUnit')?.touched &&
              residentialAddressGroup
                .get('apartmentUnit')
                ?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('apartmentUnit')?.touched &&
              residentialAddressGroup
                .get('apartmentUnit')
                ?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="streetNumber"
          [formControl]="residentialAddressGroup.get('streetNumber')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('streetNumber')?.touched &&
              residentialAddressGroup.get('streetNumber')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('streetNumber')?.touched &&
              residentialAddressGroup
                .get('streetNumber')
                ?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="streetName"
          [formControl]="residentialAddressGroup.get('streetName')"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL' | translate
          "
          (sendBlurEvent)="
            handleTrimByControl(
              $event,
              residentialAddressGroup.controls.streetName
            )
          "
          [required]="true"
          [widthMd]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('streetName')?.touched &&
              residentialAddressGroup.get('streetName')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('streetName')?.touched &&
              residentialAddressGroup.get('streetName')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('streetName')?.touched &&
              residentialAddressGroup
                .get('streetName')
                ?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-row>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="city"
          [formControl]="residentialAddressGroup.controls.city"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL' | translate
          "
          (sendBlurEvent)="
            handleTrimByControl($event, residentialAddressGroup.controls.city)
          "
          [required]="true"
          [widthMd]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('city')?.touched &&
              residentialAddressGroup.get('city')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('city')?.touched &&
              residentialAddressGroup.get('city')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('city')?.touched &&
              residentialAddressGroup.get('city')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>

        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="country"
          class="country-dropdown"
          data-cy="address-form-mailing-country"
          [formControl]="residentialAddressGroup.controls.country"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL' | translate
          "
          [options]="countriesList"
          [required]="true"
          [widthSm]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('country')?.touched &&
              residentialAddressGroup.get('country')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>

        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="province"
          data-cy="address-form-mailing-province-state"
          [formControl]="residentialAddressGroup.controls.province"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL'
              | translate
          "
          [options]="provinceOrStateListMailing(residentialAddressGroup)"
          [widthSm]="true"
        >
          <span
            *ngIf="
              (residentialAddressGroup.get('country')?.value ||
                residentialAddressGroup.get('country')?.dirty ||
                residentialAddressGroup.get('province')?.dirty) &&
              residentialAddressGroup.get('province')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>
      </lib-layout-row>

      <lib-layout-row gap="one-and-half">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="postalCode"
          [formControl]="residentialAddressGroup.controls.postalCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL' | translate
          "
          [required]="
            isCanadaSelectedMailing(residentialAddressGroup) ||
            isUsaSelectedMailing(residentialAddressGroup)
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              (residentialAddressGroup.get('country')?.value ||
                residentialAddressGroup.get('country')?.dirty ||
                residentialAddressGroup.get('postalCode')?.touched) &&
              residentialAddressGroup.get('postalCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('postalCode')?.touched &&
              residentialAddressGroup.get('postalCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('postalCode')?.touched &&
              residentialAddressGroup
                .get('postalCode')
                ?.hasError('CAPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_CA" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('postalCode')?.touched &&
              residentialAddressGroup
                .get('postalCode')
                ?.hasError('USPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_US" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('postalCode')?.touched &&
              residentialAddressGroup
                .get('postalCode')
                ?.hasError('alphaNumPlusFew')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_NON_NA" | translate }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('postalCode')?.touched &&
              residentialAddressGroup
                .get('postalCode')
                ?.hasError('mustBeNonCanadianPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_MUSTBE_NON_CA" | translate }}
          </span>
        </lib-form-input>

        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="district"
          [formControl]="residentialAddressGroup.controls.district"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL' | translate
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              residentialAddressGroup.get('district')?.touched &&
              residentialAddressGroup.get('district')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              residentialAddressGroup.get('district')?.touched &&
              residentialAddressGroup.get('district')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-row>
      <!-- END RESIDENTIAL ADDRESS-->
    </fieldset>

    <div>
      <!-- DEPENDENT -->
      <h2 class="page__title" id="dependantTitle" #depForm>
        {{ "INTAKE.PROFILE.DEPENDENT_SECTION.TITLE" | translate }}
      </h2>
      <p
        *ngIf="isClientAccount"
        [innerHtml]="'INTAKE.PROFILE.DEPENDENT_SECTION.LEGEND' | translate"
      ></p>
      <p
        *ngIf="!isClientAccount"
        [innerHtml]="'INTAKE.PROFILE.DEPENDENT_SECTION.REP_LEGEND' | translate"
      ></p>
      <button
        *ngIf="!dependantFormVisible"
        category="secondary"
        type="button"
        (click)="showDependantForm(true)"
        [disabled]="isReturned"
      >
        {{ "INTAKE.PROFILE.DEPENDENT_SECTION.ADD_DEPENDENT" | translate }}
      </button>
    </div>
    <ng-container *ngIf="dependantFormVisible">
      <pra-dependant-card>
        <div
          class="dependant-form"
          [formGroup]="dependantForm"
          autocomplete="off"
        >
          <fieldset>
            <legend class="dependant-form__heading">
              {{ "INTAKE.PROFILE.DEPENDENT_SECTION.CARD_TITLE" | translate }}
            </legend>
            <!-- DEPENDENT ACCOMPANYING PA -->
            <lib-form-radio
              [editable]="user.can('documents:write')"
              nameVal="dependants"
              [legend]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.ACCOMPANYING_PA' | translate
              "
              firstId="dependantYes"
              secondId="dependantNo"
              [firstValue]="true"
              [secondValue]="false"
              [firstLabel]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.RADIO_YES' | translate
              "
              [secondLabel]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.RADIO_NO' | translate
              "
              [formControl]="dependantForm.controls.accompanyingPA"
              [required]="true"
            >
              <span
                class="error-radio"
                *ngIf="
                  dependantForm.controls.accompanyingPA?.touched &&
                  dependantForm.controls.accompanyingPA?.hasError('required')
                "
              >
                {{ "FORM_ERRORS.REQUIRED" | translate }}
              </span>
            </lib-form-radio>
            <!-- DEPENDENT RELATIONSHIP TO PA -->
            <lib-form-select
              [editable]="user.can('documents:write')"
              identifier="dependantForm-relationship"
              data-cy="relationship-to-PA-list"
              [formControl]="dependantForm.controls.relationshipToPA"
              [label]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.RELATIONSHIP_TO_PA'
                  | translate
              "
              [options]="RelationshipToPAList"
              [required]="true"
            >
              <span
                *ngIf="
                  dependantForm.get('relationshipToPA')?.touched &&
                  dependantForm.get('relationshipToPA')?.hasError('required')
                "
              >
                {{ "FORM_ERRORS.REQUIRED" | translate }}
              </span>

              <span
                *ngIf="
                  dependantForm.get('relationshipToPA')?.touched &&
                  dependantForm
                    .get('relationshipToPA')
                    ?.hasError('hasSpouseOrCommonLaw')
                "
              >
                {{
                  "INTAKE.PROFILE.DEPENDENT_SECTION.ERRORS.HAS_SPOUSE"
                    | translate
                }}
              </span>
            </lib-form-select>
            <!-- DEPENDENT FAMILY NAME -->
            <lib-form-input
              [editable]="user.can('documents:write')"
              identifier="dependantForm-familyName"
              required="true"
              [formControl]="dependantForm.controls.familyName"
              [label]="
                'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL'
                  | translate
              "
            >
              <span
                *ngIf="
                  dependantForm.get('familyName')?.touched &&
                  dependantForm.get('familyName')?.hasError('required')
                "
              >
                {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
              </span>
              <span
                *ngIf="
                  dependantForm.get('familyName')?.touched &&
                  dependantForm.get('familyName')?.hasError('maxlength')
                "
              >
                {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
              </span>
              <span
                *ngIf="
                  dependantForm.get('familyName')?.touched &&
                  dependantForm.get('familyName')?.hasError('properName')
                "
              >
                {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
              </span>
            </lib-form-input>
            <!-- DEPENDENT GIVEN NAME -->
            <lib-form-input
              [editable]="user.can('documents:write')"
              identifier="dependantForm-givenName"
              [formControl]="dependantForm.controls.givenName"
              [label]="
                'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL'
                  | translate
              "
            >
              <span
                *ngIf="
                  dependantForm.get('givenName')?.touched &&
                  dependantForm.get('givenName')?.hasError('maxlength')
                "
              >
                {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
              </span>
              <span
                *ngIf="
                  (dependantForm.get('givenName')?.value ||
                    dependantForm.get('givenName')?.touched) &&
                  dependantForm.get('givenName')?.value &&
                  dependantForm.get('givenName')?.hasError('properName')
                "
              >
                {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
              </span>
            </lib-form-input>
            <!-- DEPENDENT DATE OF BIRTH -->
            <lib-form-datepicker
              [readOnly]="!user.can('documents:write')"
              identifier="personalDetailsForm-dob"
              [required]="true"
              [formControl]="dependantForm.controls.dob"
              [pastDate]="true"
              [label]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.DATE_OF_BIRTH'
                  | translate
                  | translate
              "
            >
            </lib-form-datepicker>
            <lib-form-custom-error
              *ngIf="dependantFormVisible && allInputsTouched"
              [message]="
                'INTAKE.PROFILE.DEPENDENT_SECTION.ERRORS.FORM_OPEN' | translate
              "
            >
            </lib-form-custom-error>
            <lib-layout-row gap="one-and-half">
              <button
                category="primary"
                type="button"
                (click)="saveDependentForm()"
                [disabled]="dependantForm.invalid"
              >
                {{
                  "INTAKE.PROFILE.DEPENDENT_SECTION.SAVE_DETAILS" | translate
                }}
              </button>
              <button
                category="secondary"
                type="button"
                (click)="showDependantForm(true)"
              >
                {{ "INTAKE.PROFILE.DEPENDENT_SECTION.CANCEL" | translate }}
              </button>
            </lib-layout-row>
          </fieldset>
        </div>
      </pra-dependant-card>
    </ng-container>
    <div class="dependant">
      <header>
        <h4>
          {{ "INTAKE.PROFILE.DEPENDENT_SECTION.SAVE_DEPENDENTS" | translate }}
        </h4>
        <p *ngIf="!dependantList?.length">
          {{
            "INTAKE.PROFILE.DEPENDENT_SECTION.SAVE_DEPENDENTS_LEGEND"
              | translate
          }}
        </p>
      </header>
      <section class="dependants-container" *ngIf="dependantList?.length">
        <pra-dependant-card
          *ngFor="let dependant of dependantList.value; let i = index"
        >
          <section *ngIf="editingDependant !== i">
            <h5>
              {{ "INTAKE.PROFILE.DEPENDENT_SECTION.ACCOMPANYING" | translate }}
            </h5>
            <p>
              {{
                dependant.accompanyingPA
                  | matchingValue : TrueFalseList : "value" : lang
              }}
            </p>
            <h5>
              {{ "INTAKE.PROFILE.DEPENDENT_SECTION.RELATIONSHIP" | translate }}
            </h5>
            <p>
              {{
                dependant.relationshipToPA
                  | matchingValue : RelationshipToPAList : "value" : lang
              }}
            </p>
            <h5>
              {{ "INTAKE.PROFILE.DEPENDENT_SECTION.NAME" | translate }}
            </h5>
            <p>{{ dependant.givenName }} {{ dependant.familyName }}</p>
            <h5>
              {{ "INTAKE.PROFILE.DEPENDENT_SECTION.DOB" | translate }}
            </h5>
            <p>
              {{ dependant.dob }}
            </p>
            <lib-layout-row gap="one-and-half" mt="2">
              <button
                [disabled]="dependantEditFormVisible || dependantFormVisible"
                category="primary"
                type="button"
                (click)="editDependent(i)"
              >
                {{ "INTAKE.PROFILE.DEPENDENT_SECTION.EDIT" | translate }}
              </button>
              <button
                [disabled]="
                  dependantEditFormVisible || dependantFormVisible || isReturned
                "
                category="secondary"
                color="critical"
                type="button"
                (click)="openModal('confirmDeleteDependant', i)"
              >
                {{ "INTAKE.PROFILE.DEPENDENT_SECTION.DELETE" | translate }}
              </button>
            </lib-layout-row>
          </section>
          <section *ngIf="editingDependant === i">
            <div
              class="dependant-form"
              [formGroup]="dependantForm"
              autocomplete="off"
            >
              <h4 class="dependant-form__heading">
                {{
                  "INTAKE.PROFILE.DEPENDENT_SECTION.CARD_TITLE_EDIT" | translate
                }}
              </h4>
              <fieldset>
                <!-- DEPENDENT ACCOMPANYING PA -->
                <lib-form-radio
                  [editable]="user.can('documents:write')"
                  nameVal="dependants"
                  [legend]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.ACCOMPANYING_PA'
                      | translate
                  "
                  firstId="dependantYes"
                  secondId="dependantNo"
                  [firstValue]="true"
                  [secondValue]="false"
                  [firstLabel]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.RADIO_YES' | translate
                  "
                  [secondLabel]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.RADIO_NO' | translate
                  "
                  [formControl]="dependantForm.controls.accompanyingPA"
                  [required]="true"
                >
                  <span
                    class="error-radio"
                    *ngIf="
                      dependantForm.controls.accompanyingPA?.touched &&
                      dependantForm.controls.accompanyingPA?.hasError(
                        'required'
                      )
                    "
                  >
                    {{ "FORM_ERRORS.REQUIRED" | translate }}
                  </span>
                </lib-form-radio>
                <!-- DEPENDENT RELATIONSHIP TO PA -->
                <lib-form-select
                  [editable]="user.can('documents:write')"
                  identifier="dependantForm-relationship"
                  data-cy="relationship-to-PA-list"
                  [formControl]="dependantForm.controls.relationshipToPA"
                  [label]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.RELATIONSHIP_TO_PA'
                      | translate
                  "
                  [options]="RelationshipToPAList"
                  [required]="true"
                >
                  <span
                    *ngIf="
                      dependantForm.get('relationshipToPA')?.touched &&
                      dependantForm
                        .get('relationshipToPA')
                        ?.hasError('required')
                    "
                  >
                    {{ "FORM_ERRORS.REQUIRED" | translate }}
                  </span>

                  <span
                    *ngIf="
                      dependantForm.get('relationshipToPA')?.touched &&
                      dependantForm
                        .get('relationshipToPA')
                        ?.hasError('hasSpouseOrCommonLaw')
                    "
                  >
                    {{
                      "INTAKE.PROFILE.DEPENDENT_SECTION.ERRORS.HAS_SPOUSE"
                        | translate
                    }}
                  </span>
                </lib-form-select>
                <!-- DEPENDENT FAMILY NAME -->
                <lib-form-input
                  [editable]="user.can('documents:write')"
                  identifier="dependantForm-familyName"
                  required="true"
                  [formControl]="dependantForm.controls.familyName"
                  [label]="
                    'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL'
                      | translate
                  "
                >
                  <span
                    *ngIf="
                      dependantForm.get('familyName')?.touched &&
                      dependantForm.get('familyName')?.hasError('required')
                    "
                  >
                    {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
                  </span>
                  <span
                    *ngIf="
                      dependantForm.get('familyName')?.touched &&
                      dependantForm.get('familyName')?.hasError('maxlength')
                    "
                  >
                    {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
                  </span>
                  <span
                    *ngIf="
                      dependantForm.get('familyName')?.touched &&
                      dependantForm.get('familyName')?.hasError('properName')
                    "
                  >
                    {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
                  </span>
                </lib-form-input>
                <!-- DEPENDENT GIVEN NAME -->
                <lib-form-input
                  [editable]="user.can('documents:write')"
                  identifier="dependantForm-givenName"
                  [formControl]="dependantForm.controls.givenName"
                  [label]="
                    'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL'
                      | translate
                  "
                >
                  <span
                    *ngIf="
                      dependantForm.get('givenName')?.touched &&
                      dependantForm.get('givenName')?.hasError('maxlength')
                    "
                  >
                    {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
                  </span>
                  <span
                    *ngIf="
                      (dependantForm.get('givenName')?.value ||
                        dependantForm.get('givenName')?.touched) &&
                      dependantForm.get('givenName')?.value &&
                      dependantForm.get('givenName')?.hasError('properName')
                    "
                  >
                    {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
                  </span>
                </lib-form-input>
                <!-- DEPENDENT DATE OF BIRTH -->
                <lib-form-datepicker
                  [readOnly]="!user.can('documents:write')"
                  identifier="personalDetailsForm-dob"
                  [required]="true"
                  [formControl]="dependantForm.controls.dob"
                  [pastDate]="true"
                  [label]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.DATE_OF_BIRTH'
                      | translate
                      | translate
                  "
                >
                </lib-form-datepicker>
                <!-- display error for form being open -->
                <lib-form-custom-error
                  *ngIf="dependantEditFormVisible && allInputsTouched"
                  [message]="
                    'INTAKE.PROFILE.DEPENDENT_SECTION.ERRORS.FORM_OPEN'
                      | translate
                  "
                >
                </lib-form-custom-error>
                <lib-layout-row gap="one-and-half">
                  <button
                    category="primary"
                    type="button"
                    (click)="saveDependentForm()"
                    [disabled]="dependantForm.invalid"
                  >
                    {{
                      "INTAKE.PROFILE.DEPENDENT_SECTION.SAVE_DETAILS"
                        | translate
                    }}
                  </button>
                  <button
                    category="secondary"
                    type="button"
                    (click)="showDependantForm(false, true, true)"
                  >
                    {{ "INTAKE.PROFILE.DEPENDENT_SECTION.CANCEL" | translate }}
                  </button>
                </lib-layout-row>
              </fieldset>
            </div>
          </section>
        </pra-dependant-card>
      </section>
    </div>
    <div class="profile-actions">
      <button
        class="btn-link"
        category="plain"
        *ngIf="
          (profileForm.invalid ||
            dependantFormVisible ||
            dependantEditFormVisible) &&
          profileForm.touched
        "
        type="submit"
      >
        <img
          class="arrow_up_icon"
          src="../../../../assets/images/icons/arrow-up.svg"
          aria-hidden="true"
          alt=""
        />
        {{ "INTAKE.PROFILE.ERROR_MESSAGE" | translate }}
      </button>
      <button type="submit">
        {{ "INTAKE.FORM_PAGE_SAVE" | translate }}
      </button>
    </div>
  </form>
</ng-container>
<!-- MODALS -->
<lib-modal
  id="confirmDeleteDependant"
  [title]="'INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p
      [innerHTML]="
        'INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.DESCRIPTION' | translate
      "
    ></p>
  </ng-container>
  <ng-container modalButtons>
    <button
      category="primary"
      color="critical"
      (click)="deleteDependent()"
      [disabled]="isProcessing"
    >
      <lib-spinner
        [showSpinner]="isProcessing"
        [fullPage]="false"
        [isButton]="true"
      ></lib-spinner>
      <div *ngIf="!isProcessing">
        {{ "INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.DELETE" | translate }}
      </div>
    </button>
    <button category="secondary" (click)="closeModal('confirmDeleteDependant')">
      {{ "INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
