<!-- "SECTION_TITLE": "Confirmation of permanent residence",
"SECTION_DESC": "You need to confirm your address first in order to be able to download the COPR",
"DOWNLOAD_COPR": "Download COPR",
"DOWNLOAD_TEXT" : "Your Confirmation of Permanent Residency will be availavle for download after your address will be confirmed.",
"BUTTON": "Download" -->
<div class="copr">
  <div *ngIf="canDownloadCopr">
    <div class="copr__intro">
      <h2 class="emphasis copr__intro-title">
        {{ "HOME.COPR.SECTION_TITLE" | translate }}
      </h2>
      <p
        class="copr__intro-desc"
        [innerHTML]="'HOME.COPR.SECTION_DESC' | translate : nameParam"
      ></p>
    </div>
    <div class="copr__download">
      <div>
        <span
          class="copr__download-title"
          [innerHTML]="'HOME.COPR.DOWNLOAD_COPR' | translate : nameParam"
        ></span>
        <span
          class="copr__download-text"
          [innerHTML]="'HOME.COPR.DOWNLOAD_TEXT' | translate : nameParam"
        ></span>
      </div>
      <button
        (click)="downloadCopr()"
        type="button"
        [disabled]="!canDownloadCopr"
        category="secondary"
      >
        {{ "HOME.COPR.BUTTON" | translate }}
      </button>
    </div>
  </div>
</div>
