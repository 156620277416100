import { SelectedPermanentResidentTravelDocumentState } from "../prcPrtdState";

import { Action, createReducer, on } from "@ngrx/store";
import * as SelectedPRTDActions from "../actions/selectedPRTDActions";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";

export const INITIAL_SELECTED_PR_TRAVEL_DOCUMENT: SelectedPermanentResidentTravelDocumentState =
  {
    prtdData: null,
    isLoaded: false,
    isLoading: false,
    hasError: false,
  };

const reduceSelectedPRTD = createReducer(
  INITIAL_SELECTED_PR_TRAVEL_DOCUMENT,
  on(SelectedPRTDActions.StartLoadPRTDAction, (state, action) => {
    return { ...state, isLoading: true };
  }),
  on(SelectedPRTDActions.SetNewPRTDAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      hasError: false,
      prtdData: { id: "ISNEW" } as Case,
    };
  }),
  on(SelectedPRTDActions.LoadPRTDAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      hasError: false,
      prtdData: action.prtd,
    };
  }),
  on(SelectedPRTDActions.LoadPRTDErrorAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      hasError: true,
      error: action.error,
    };
  }),
  on(SelectedPRTDActions.ResetPRTDStateAction, (state, action) => {
    return INITIAL_SELECTED_PR_TRAVEL_DOCUMENT;
  }),
  on(
    SelectedPRTDActions.UpdatePRTDWitClientAndAppInfoAction,
    (state, action) => {
      const prtdData = { ...state.prtdData, ...action } as Case;
      return { ...state, prtdData };
    }
  ),
  on(SelectedPRTDActions.UpdatePRTDUrgencyOtherExplanation, (state, action) => {
    const prtdData = { ...state.prtdData } as Case;
    prtdData.caseMetadata = { ...prtdData.caseMetadata, ...action };
    return { ...state, prtdData };
  }),
  on(SelectedPRTDActions.UpdatePRTDIsUrgentStatus, (state, action) => {
    const prtdData = { ...state.prtdData, ...action } as Case;
    return { ...state, prtdData };
  })
);

export function selectedPRTDReducer(
  state: SelectedPermanentResidentTravelDocumentState | undefined,
  action: Action
) {
  return reduceSelectedPRTD(state, action);
}
