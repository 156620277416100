import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { FormSelectOption } from "lib";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { LovService, AlertService, lovModel } from "lib";
import { autoPopulate } from "@pr-applicant/app/shared/helpers/auto-populate";
import {
  ICaseProfileModel,
  ProfileService,
  IDependantModel,
} from "@pr-applicant/app/shared/services/profile/profile.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5406-section-a-form",
  templateUrl: "./imm5406-section-a.component.html",
  styleUrls: ["./imm5406-section-a.component.scss"],
})
export class IMM5406SectionAComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public formValues: any;
  public lang: string;

  public principalMaritalStatusOptions: FormSelectOption[] =
    this.lovService.lovs.principalMaritalStatus;
  public partnerMaritalStatusOptions: FormSelectOption[] =
    this.lovService.lovs.partnerMaritalStatus;
  public otherMaritalStatusOptions: FormSelectOption[] =
    this.lovService.lovs.otherMaritalStatus;

  // used to build form control validators
  private fullNameMaxChar = 63;
  private dobMaxChar = 10;
  private birthplaceMaxChar = 30;
  private emailMaxChar = 40;
  private addressMaxChar = 80;

  private sectionAData: any;
  private storeSubsc$: Subscription;
  private profileSubsc$: Subscription;
  private currentForm: any;

  public profile?: any;
  public caseId?: number;
  public hasSpouse: boolean;
  public isPrincipalApplicant?: boolean;
  public spouse: IDependantModel;

  // FormGroups used to build reactive form
  // Important: Keep these below validation variables
  public primaryApplicantForm: FormGroup = this.createPrimaryApplicantForm();
  public applicantForm: FormGroup = this.createCommonFormGroup();
  public partnerForm: FormGroup = this.createCommonFormGroup();
  public motherForm: FormGroup = this.createCommonFormGroup();
  public fatherForm: FormGroup = this.createCommonFormGroup();

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService,
    public lovService: LovService,
    public profileService: ProfileService,
    public translate: TranslateService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.autoPopulateApplicant();
    this.profileSubsc$ = this.profileService.profileObs$.subscribe(
      (profileData: ICaseProfileModel) => {
        this.profile = profileData;
        this.getSpouse();

        if (this.spouse && this.isPAOrSpouse) {
          this.autoPopulateSpouse();
          this.removeSingleFromPARelationships();
          this.hasSpouse = true;
        }
      }
    );
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.sectionAData = currentImmDoc.currentForm.form.sectionA;
        this.isPrincipalApplicant =
          this.currentForm.form.sectionA.principalApplicant;
        this.fillFormWithSavedValues();
        this.caseId = currentImmDoc.caseId;
        this.isLoading = false;
        this.profileService.getProfile(this.caseId);
      });
  }

  ngOnDestroy() {
    this.profileSubsc$.unsubscribe();
    this.storeSubsc$.unsubscribe();
  }

  /*PUBLIC----------------------------------------------- */
  public get FullNameMaxChars(): { [key: string]: number } {
    return { max: this.fullNameMaxChar };
  }

  public get DOBMaxChars(): { [key: string]: number } {
    return { max: this.dobMaxChar };
  }

  public get BirthplaceMaxChars(): { [key: string]: number } {
    return { max: this.birthplaceMaxChar };
  }

  public get EmailMaxChars(): { [key: string]: number } {
    return { max: this.emailMaxChar };
  }

  public get AddressMaxChars(): { [key: string]: number } {
    return { max: this.addressMaxChar };
  }

  public get isFormValid(): boolean {
    return !(
      this.primaryApplicantForm.invalid ||
      this.partnerForm.invalid ||
      this.fatherForm.invalid ||
      this.motherForm.invalid ||
      this.applicantForm.invalid
    );
  }

  private autoPopulateApplicant(): void {
    autoPopulate([
      this.primaryApplicantForm.controls.principalApplicant,
      this.applicantForm.controls.fullName,
      this.applicantForm.controls.dob,
    ]);
  }

  private autoPopulateSpouse() {
    autoPopulate([
      this.partnerForm.controls.fullName,
      this.partnerForm.controls.dob,
    ]);
  }

  public get isPAOrSpouse(): boolean {
    // check if PA
    if (this.isPrincipalApplicant) {
      return true;
    }
    //check if current form belongs to case spouse
    const applicant = this.currentForm.form.sectionA.applicant;
    if (
      applicant.fullName ===
        this.spouse?.givenName + " " + this.spouse?.familyName &&
      applicant.dob === this.spouse?.dob
    ) {
      return true;
    }
    return false;
  }

  // map through profile dependants and return dependant that is spouse or common-law
  public getSpouse(): void {
    if (!this.isLoading && this.profile) {
      const dependantRelationships = this.profile.profile.dependants.map(
        (dep: any) => {
          return {
            relationshipToPA: dep.personalDetails.relationshipToPA,
            familyName: dep.personalDetails.familyName,
            givenName: dep.personalDetails.givenName,
            accompanyingPA: dep.personalDetails.accompanyingPA,
            dob: dep.personalDetails.dob,
          };
        }
      );
      this.spouse = dependantRelationships.filter(
        (dep: IDependantModel) =>
          dep.relationshipToPA === "10" || dep.relationshipToPA === "01"
      )[0];
    }
  }

  private removeSingleFromPARelationships(): void {
    this.principalMaritalStatusOptions =
      this.principalMaritalStatusOptions.filter((status: lovModel) => {
        return status.value !== "02";
      });
  }

  public getFormData(): any {
    if (!this.isFormValid) {
      return { sectionName: "sectionA", isInvalid: true };
    }
    const result = _.cloneDeep(this.currentForm);
    const fromAPISectionA = result.form.sectionA;
    const entryData = {
      principalApplicant:
        this.primaryApplicantForm.controls.principalApplicant.value,
      partner: {
        ...this.partnerForm.getRawValue(),
        _id: fromAPISectionA.partner._id,
      },
      applicant: {
        ...this.applicantForm.getRawValue(),
        _id: fromAPISectionA.applicant._id,
      },
      father: {
        ...this.fatherForm.getRawValue(),
        _id: fromAPISectionA.father._id,
      },
      mother: {
        ...this.motherForm.getRawValue(),
        _id: fromAPISectionA.mother._id,
      },
    };

    result.form.sectionA = { ...entryData, _id: result.form.sectionA._id };
    return { sectionName: "sectionA", sectionInfo: result.form.sectionA };
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    const result =
      this.primaryApplicantForm.touched ||
      this.partnerForm.touched ||
      this.applicantForm.touched ||
      this.fatherForm.touched ||
      this.motherForm.touched;
    return result;
  }

  /* PRIVATE ------------------------------------------------*/
  // Primary Applicant Form
  private createPrimaryApplicantForm(): FormGroup {
    return this.fb.group({
      principalApplicant: [{ value: null }],
    });
  }

  // A form group that is used for mother, father, applicant, partner
  private createCommonFormGroup(): FormGroup {
    return this.fb.group({
      fullName: [null, [Validators.maxLength(this.fullNameMaxChar)]],
      dob: [null, [Validators.maxLength(this.dobMaxChar)]],
      countryOfBirth: [null, [Validators.maxLength(this.birthplaceMaxChar)]],
      maritalStatus: [null],
      emailAddress: [
        null,
        {
          validators: [Validators.maxLength(this.emailMaxChar)],
        },
      ],
      address: [null, [Validators.maxLength(this.addressMaxChar)]],
    });
  }

  private fillFormWithSavedValues(): void {
    this.setValuesPrimaryApplicantForm();
    this.setValuesApplicantForm();
    this.setValuesPartnerForm();
    this.setValuesMotherForm();
    this.setValuesFatherForm();
  }

  private setValuesPrimaryApplicantForm(): void {
    this.primaryApplicantForm?.patchValue({
      principalApplicant: this.sectionAData?.principalApplicant,
    });
  }

  private setValuesApplicantForm(): void {
    this.applicantForm?.patchValue({
      fullName: this.sectionAData?.applicant?.fullName,
      dob: this.sectionAData?.applicant?.dob,
      countryOfBirth: this.sectionAData?.applicant?.countryOfBirth,
      maritalStatus: this.sectionAData?.applicant?.maritalStatus,
      emailAddress: this.sectionAData?.applicant?.emailAddress,
      address: this.sectionAData?.applicant?.address,
    });
  }

  private setValuesPartnerForm(): void {
    this.partnerForm?.patchValue({
      fullName: this.sectionAData?.partner?.fullName,
      dob: this.sectionAData?.partner?.dob,
      countryOfBirth: this.sectionAData?.partner?.countryOfBirth,
      maritalStatus: this.sectionAData?.partner?.maritalStatus,
      emailAddress: this.sectionAData?.partner?.emailAddress,
      address: this.sectionAData?.partner?.address,
    });
  }

  private setValuesMotherForm(): void {
    this.motherForm?.patchValue({
      fullName: this.sectionAData?.mother?.fullName,
      dob: this.sectionAData?.mother?.dob,
      countryOfBirth: this.sectionAData?.mother?.countryOfBirth,
      maritalStatus: this.sectionAData?.mother?.maritalStatus,
      emailAddress: this.sectionAData?.mother?.emailAddress,
      address: this.sectionAData?.mother?.address,
    });
  }

  private setValuesFatherForm(): void {
    this.fatherForm?.patchValue({
      fullName: this.sectionAData?.father?.fullName,
      dob: this.sectionAData?.father?.dob,
      countryOfBirth: this.sectionAData?.father?.countryOfBirth,
      maritalStatus: this.sectionAData?.father?.maritalStatus,
      emailAddress: this.sectionAData?.father?.emailAddress,
      address: this.sectionAData?.father?.address,
    });
  }
  private get alertTechnicalError(): string {
    return this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR");
  }
}
