import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  FormSelectOption,
  ValidationService,
  DOC_LIMITS,
  LobIds,
  IInformationBoxConfig,
} from "lib";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../shared/case-module/case.model";
import { LovService } from "lib";
import { autoPopulate } from "@pr-applicant/app/shared/helpers/auto-populate";
import { lovModel } from "projects/lib/src/lib/services/lov/lov.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-personal-details-form",
  templateUrl: "./personal-details-form.component.html",
  styleUrls: ["./personal-details-form.component.scss"],
})
export class IMM0008PersonalDetailsFormComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentChecked
{
  @Input() formData: any;
  @Input() isDependant?: boolean = false;

  public lang: string;
  public dependantDetailsForm: FormGroup;
  public personalDetailsForm: FormGroup;
  public currentCountry: FormGroup;
  public previousCountries: UntypedFormArray = new UntypedFormArray([]);
  public previousRelationshipForm: FormGroup;
  private dependantTypeValidation = [Validators.required];

  private subscriptions: Subscription[] = [];

  /* select lists */
  public countryOptions: FormSelectOption[];
  public countryOfBirthOptions: FormSelectOption[];
  public countryOfCitizenshipOptions: FormSelectOption[];
  public eyeColorOptions: FormSelectOption[];
  public genderOptions: FormSelectOption[];
  public immigrationStatusOptions: FormSelectOption[];
  public maritalStatusOptions: FormSelectOption[];
  public maritalStatusDepOptions: FormSelectOption[];
  public maritalStatusHistoryOptions: FormSelectOption[];
  public relationshipToPA: FormSelectOption[] | [];
  public dependantType: FormSelectOption[] | [];

  /* translation params for validations */
  public max10 = { max: "10" };
  public max30 = { max: "30" };
  public max50 = { max: "50" };
  public max100 = { max: "100" };

  public otherImmigrationStatusId: string = "06"; //  being used for current & prev country
  public programId: number;
  public caseProgramId: number;
  public refugeeProgramId: number = 3;
  public isDependantAccompanying = true;
  public dependantSpouseRelationshipId: string = "01";
  public dependantCommonLawRelationshipId: string = "10";
  public currentCountryImmigrationStatusesWithRequiredDates: Array<string> = [
    "03",
    "04",
    "05",
  ];

  private minHeight = 30;
  private maxHeight = 271;
  // this constant is to track if a previous country has been removed/added! form.dirty doesn't track when a fieldset is removed
  private isPreviousCountryChanged: boolean = false;

  private regexName: RegExp =
    /^[a-zA-Za-zéàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s][a-zA-Z-'a-zéàèùâêîôûëïöüæçœîôÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s]*$/;
  private regexOpenText: RegExp =
    /^[""a-zA-Z0-9\s\r\~\!\@\#\$\%\^\&\*\(\)\_\+\{\}\:\<\>\?\`\-\=\[\]\\\;\'\,\.\/éàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ]+$/;
  private regexCityOfBirth: RegExp =
    /^[a-zA-ZéàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\-\s\,\.\/\&""]*$/;
  public informationBoxConfig: IInformationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validators: ValidationService,
    private cdr: ChangeDetectorRef,
    private store: Store<fromApp.State>,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnChanges(): void {
    if (this.formData?.form) {
      this.fillFormWithSavedValues();
      this.fillFormWithSelectLists();
    }
    if (
      this.isDependant &&
      this.dependantDetailsForm?.get("relationshipToPA")
    ) {
      this.maritalStatusOptions = this.maritalStatusDepOptions;
    }
  }

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.createAllForms();
    this.watchIsNotAccompanyingPA();
    this.watchRelationshipToPA();
    this.watchNickname();
    this.watchCurrentCountry();
    this.watchPreviousRelationship();
    this.watchCommonLawOrMarriage();
    this.watchCurrentCountryStatus();

    if (!this.isDependant) {
      this.watchMaritalStatus();
    }

    const disabledForAutopopulate = [
      this.personalDetailsForm.controls.familyName,
      this.personalDetailsForm.controls.givenName,
      this.personalDetailsForm.controls.dob,
    ];
    if (this.isDependant) {
      disabledForAutopopulate.push(
        this.dependantDetailsForm.controls.accompanyingPA,
        this.dependantDetailsForm.controls.relationshipToPA
      );
    }
    autoPopulate(disabledForAutopopulate);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /* PUBLIC --------------------------------------------------------------------------- */

  public get hasMarriageOrCommonLaw(): boolean {
    return (
      this.personalDetailsForm?.controls.maritalStatus.value === "01" ||
      this.personalDetailsForm?.controls.maritalStatus.value === "03"
    );
  }

  public get hasNickname(): boolean {
    return this.personalDetailsForm?.controls.usedOtherName.value === true;
  }

  public get countryDatesRequired(): boolean {
    return this.currentCountryImmigrationStatusesWithRequiredDates.includes(
      this.currentCountry?.controls.immigrationStatus.value
    );
  }

  public get hasOtherImmigrationStatus(): boolean {
    return (
      this.currentCountry?.controls.immigrationStatus.value ===
      this.otherImmigrationStatusId
    ); // 06 === "Other"
  }

  public get hasOtherRelationshipToPA(): boolean {
    return this.dependantDetailsForm?.controls.relationshipToPA.value === "85"; // 85 === "Other"
  }

  public get hasPreviousCountries(): boolean {
    return (
      this.personalDetailsForm?.controls.hasPreviousCountries.value === true
    );
  }

  // get from select field
  public get hasPreviousMarriageOrCommonLawStatus(): boolean {
    return (
      this.personalDetailsForm?.controls.maritalStatus.value === "04" ||
      this.personalDetailsForm?.controls.maritalStatus.value === "05" ||
      this.personalDetailsForm?.controls.maritalStatus.value === "06" ||
      this.personalDetailsForm?.controls.maritalStatus.value === "09"
    );
  }

  public get hasPreviousMarriageOrCommonLawRadio(): boolean {
    return this.personalDetailsForm?.controls.previouslyMarriedOrCommonLaw
      .value;
  }

  //  Enable dependant type if not a refugee program, and not Accompanying and not spouse nor Common law relationship
  public get isDependantTypeRequired(): boolean {
    return (
      this.caseProgramId !== this.refugeeProgramId &&
      !this.isNotAccompanyingPA &&
      !this.isDependantRelationshipCommonLawToPA &&
      !this.isDependantRelationshipSpouseToPA
    );
  }

  // Check if the dependant is a Spouse of the PA
  public get isDependantRelationshipSpouseToPA(): boolean {
    return (
      this.dependantDetailsForm?.controls?.relationshipToPA.value ===
      this.dependantSpouseRelationshipId
    );
  }

  // Check if the dependant is a Common Law of the PA
  public get isDependantRelationshipCommonLawToPA(): boolean {
    return (
      this.dependantDetailsForm?.controls?.relationshipToPA.value ===
      this.dependantCommonLawRelationshipId
    );
  }

  public get isCurrentCountryCanada(): boolean {
    return this.currentCountry?.controls.country.value === "511"; // 511 === Canada
  }

  public get isNotAccompanyingPA(): boolean {
    return (
      this.dependantDetailsForm?.controls?.accompanyingPA.value === "false"
    );
  }

  public get isFormDirty(): boolean {
    const dependantFormDirty = this.isDependant
      ? this.dependantDetailsForm.dirty
      : false;
    return (
      this.personalDetailsForm.dirty ||
      this.currentCountry.dirty ||
      this.previousCountries.dirty ||
      this.isPreviousCountryChanged ||
      this.previousRelationshipForm.dirty ||
      dependantFormDirty
    );
  }

  public get isFormTouched(): boolean {
    const dependantFormTouched = this.isDependant
      ? this.dependantDetailsForm?.touched
      : false;
    return (
      this.personalDetailsForm.touched ||
      this.currentCountry.touched ||
      this.previousCountries.touched ||
      this.isPreviousCountryChanged ||
      this.previousRelationshipForm.touched ||
      dependantFormTouched
    );
  }

  public get isFormValid(): boolean {
    const dependantFormValid = this.isDependant
      ? this.dependantDetailsForm.valid
      : true;
    return (
      this.personalDetailsForm.valid &&
      this.currentCountry.valid &&
      this.previousCountries.valid &&
      (this.previousRelationshipForm.valid ||
        this.previousRelationshipForm.disabled) &&
      dependantFormValid
    );
  }

  public get minMaxHeight(): object {
    return { min: this.minHeight, max: this.maxHeight };
  }

  public get preparedFormValue(): any {
    const dependantValues = this.isDependant
      ? this.prepareDependantDetailsValues()
      : null;
    return {
      ...this.personalDetailsForm?.getRawValue(),
      ...this.previousRelationshipForm?.value,
      currentCountry: this.currentCountry?.value,
      ...dependantValues,
    };
  }

  public get canAddAnotherCountry(): boolean {
    return !(
      this.previousCountries.length >= DOC_LIMITS.IMM0008.PREV_COUNTRIES
    );
  }

  public get isDependantAccompanyingPARadioRequired(): any {
    return this.validators.isRadioFieldRequired(
      this.dependantDetailsForm.controls.accompanyingPA
    );
  }

  public get isUsedOtherNameRadioRequired(): any {
    return this.validators.isRadioFieldRequired(
      this.personalDetailsForm.controls.usedOtherName
    );
  }

  public get isPreviousCountriesRadioRequired(): any {
    return this.validators.isRadioFieldRequired(
      this.personalDetailsForm.controls.hasPreviousCountries
    );
  }

  public get isPreviousRelationshipRadioRequired(): any {
    return this.validators.isRadioFieldRequired(
      this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw
    );
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  public addPreviousCountry(): void {
    this.isPreviousCountryChanged = true;
    if (this.previousCountries.length < DOC_LIMITS.IMM0008.PREV_COUNTRIES) {
      this.previousCountries.push(this.createPreviousCountryFormGroup());
    }
  }

  public removePreviousCountry(index: number): void {
    this.isPreviousCountryChanged = true;
    this.previousCountries.removeAt(index);
  }

  public toggleFieldsPreviousCountries(): void {
    if (this.hasPreviousCountries) {
      this.addPreviousCountry();
    } else {
      this.previousCountries.clear();
    }
  }

  public handleTrimByControl(ev: Event, formControl: AbstractControl) {
    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  /* PRIVATE -------------------------------------------------------------------------- */

  private createAllForms(): void {
    if (this.isDependant) {
      this.getProgramId();
      this.dependantDetailsForm = this.createDependantFormGroup();
    }
    this.previousRelationshipForm = this.createPreviousRelationshipFormGroup();
    this.personalDetailsForm = this.createPersonalDetailsFormGroup();
    this.currentCountry = this.createCurrentCountryFormGroup();
  }

  private createCurrentCountryFormGroup(): FormGroup {
    return this.fb.group({
      country: [null, [Validators.required]],
      immigrationStatus: [null, [Validators.required]],
      startDateOfImmigrationStatus: new FormControl(null, {
        validators: [],
      }),
      endDateOfImmigrationStatus: new FormControl(null, {
        validators: [],
      }),
      otherImmigrationStatus: new FormControl(null, {
        validators: [
          Validators.pattern(this.regexOpenText),
          Validators.maxLength(30),
        ],
      }),
    });
  }

  private createPreviousCountryFormGroup(): FormGroup {
    const form = this.fb.group({
      country: [null, [Validators.required]],
      immigrationStatus: [null, [Validators.required]],
      startDateOfImmigrationStatus: new FormControl(null, {
        validators: [],
      }),
      endDateOfImmigrationStatus: new FormControl(null, {
        validators: [],
      }),
      otherImmigrationStatus: new FormControl(null, {
        validators: [
          Validators.pattern(this.regexOpenText),
          Validators.maxLength(30),
        ],
      }),
    });

    this.watchPreviousCountryImmigrationStatus(
      form.controls.immigrationStatus,
      form.controls.otherImmigrationStatus
    );

    return form;
  }

  private createDependantFormGroup(): FormGroup {
    return this.fb.group({
      relationshipToPA: [null, [Validators.required]],
      otherRelationshipToPA: new FormControl(null, {
        validators: [
          /* req'd if this.hasOtherRelationshipToPA (see template) */
          Validators.maxLength(30),
          this.validators.validatorAlphaNumPlusFew,
        ],
      }),
      dependantType: [null],
      accompanyingPA: [null, [Validators.required]],
      reasonNotAccompanying: new FormControl(null, {
        validators: [
          /* req'd if this.isAccompanyingPA (see template) */
          Validators.maxLength(50),
          this.validators.validatorAlphaNumPlusFew,
        ],
      }),
    });
  }

  private createPersonalDetailsFormGroup(): FormGroup {
    return this.fb.group(
      {
        familyName: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        givenName: new FormControl(null, {
          validators: [
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        usedOtherName: [null, [Validators.required]],
        otherFamilyName: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        otherGivenName: new FormControl(null, {
          validators: [
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        uci: new FormControl(null, {
          validators: [
            Validators.maxLength(10),
            this.validators.validatorOnlyNumbers,
          ],
        }),
        sex: [null, [Validators.required]],
        eyeColour: [null, this.getEyeColorValidators()],
        heightInCM: new FormControl(null, {
          validators: this.getHeightInCmValidators(),
        }),
        dob: new FormControl(null, {
          validators: [],
        }),
        cityOfBirth: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.pattern(this.regexCityOfBirth),
            Validators.maxLength(30),
          ],
        }),
        countryOfBirth: [null, [Validators.required]],
        citizenship1: [null, [Validators.required]],
        citizenship2: [null],
        dateOfLastEntry: new FormControl(null, {
          validators: [],
        }),
        placeOfLastEntry: [
          null,
          {
            validators: [
              Validators.required,
              Validators.pattern(this.regexOpenText),
              Validators.maxLength(30),
            ],
          },
        ],
        hasPreviousCountries: [null, [Validators.required]],
        previousCountries: this.previousCountries,
        maritalStatus: [null, [Validators.required]],
        demoCheckbox: [null],
        demoCheckbox2: [null],
        demoTextarea: [null],
        dateOfMarriageOrCommonLaw: new FormControl(null, {
          validators: [
            /* req'd if this.hasMarriageOrCommonLaw (see template) */
          ],
        }),
        familyNameOfSpouse: new FormControl(null, {
          /* req'd if this.hasMarriageOrCommonLaw (see template) */
          validators: [
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        givenNameOfSpouse: new FormControl(null, {
          validators: [
            Validators.maxLength(100),
            Validators.pattern(this.regexName),
          ],
        }),
        previouslyMarriedOrCommonLaw: [null, [Validators.required]],
      },
      { validators: [this.validators.validatorCheckNickName] }
    );
  }

  private createPreviousRelationshipFormGroup() {
    const form = this.fb.group({
      previousSpouseFamilyName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(this.regexName),
        ],
      }),
      previousSpouseGivenName: new FormControl(null, {
        validators: [
          Validators.maxLength(100),
          Validators.pattern(this.regexName),
        ],
      }),
      typeOfRelationship: [null, [Validators.required]],
      startDateOfRelationship: new FormControl(null, {
        validators: [Validators.required],
      }),
      endDateOfRelationship: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    if (!this.isDependant) {
      form.addControl("previousSpouseDob", new FormControl(null, []));
    }

    return form;
  }

  private getPreviousSpouseValidators() {
    const validators = [
      this.validators.validatorDateFormat,
      this.validators.validatorDate,
      this.validators.validatorDatePast,
    ];
    if (!this.isDependant) {
      validators.push(Validators.required);
    }
    return validators;
  }

  private fillFormWithSavedValues(): void {
    this.setValuesPersonalDetails();
    this.setValuesCurrentCountry();
    this.setValuesPreviousCountries();
    this.setValuesPreviousRelationship();
    if (this.isDependant) {
      this.setValuesDependantDetails();
    }
  }

  private fillFormWithSelectLists(): void {
    this.countryOptions = this.lovService.lovs.countryOfLastPermanentResident;
    this.countryOfBirthOptions = this.lovService.lovs.countryOfBirth;
    this.countryOfCitizenshipOptions =
      this.lovService.lovs.countryOfCitizenship;
    this.eyeColorOptions = this.lovService.lovs.eyeColor;
    this.genderOptions = this.lovService.lovs.gender;
    this.immigrationStatusOptions = this.lovService.lovs.immigrationStatus;
    this.maritalStatusOptions = this.lovService.lovs.maritalStatus;
    this.maritalStatusDepOptions = this.lovService.lovs.maritalStatus;
    this.maritalStatusHistoryOptions =
      this.lovService.lovs.maritalStatusHistory;
    if (this.isDependant) {
      this.relationshipToPA = this.lovService.lovs.relationshipToPA;
      this.dependantType = this.lovService.lovs.dependantType;
    }
  }

  /* Replace non-required empty values with `null` for dependants, so we don't store empty strings */
  private prepareDependantDetailsValues(): any {
    const dependantValues = this.dependantDetailsForm?.getRawValue();
    return {
      ...dependantValues,
      otherRelationshipToPA: this.hasOtherRelationshipToPA
        ? dependantValues?.otherRelationshipToPA
        : null,
      reasonNotAccompanying: this.isNotAccompanyingPA
        ? dependantValues?.reasonNotAccompanying
        : null,
    };
  }

  private setValuesDependantDetails(): void {
    let relationshiptoPA = this.formData.form?.relationshipToPA;

    this.dependantDetailsForm?.patchValue({
      relationshipToPA: relationshiptoPA,
      otherRelationshipToPA: this.formData.form?.otherRelationshipToPA,
      dependantType: this.formData.form?.dependantType,
      accompanyingPA: this.formData.form?.accompanyingPA?.toString(),
      reasonNotAccompanying: this.formData.form?.reasonNotAccompanying,
    });
  }

  private setValuesPersonalDetails(): void {
    this.personalDetailsForm?.patchValue({
      familyName: this.formData.form?.familyName,
      givenName: this.formData.form?.givenName,
      usedOtherName: this.formData.form?.usedOtherName,
      otherFamilyName: this.formData.form?.otherFamilyName,
      otherGivenName: this.formData.form?.otherGivenName,
      uci: this.formData.form?.uci,
      sex: this.formData.form?.sex,
      eyeColour: this.formData.form?.eyeColour,
      heightInCM: this.formData.form?.heightInCM,
      dob: this.formData.form?.dob,
      cityOfBirth: this.formData.form?.cityOfBirth,
      countryOfBirth: this.formData.form?.countryOfBirth,
      citizenship1: this.formData.form?.citizenship1,
      citizenship2: this.formData.form?.citizenship2,
      dateOfLastEntry: this.formData.form?.dateOfLastEntry,
      placeOfLastEntry: this.formData.form?.placeOfLastEntry,
      hasPreviousCountries: this.formData.form?.hasPreviousCountries,
      maritalStatus: this.formData.form?.maritalStatus,
      dateOfMarriageOrCommonLaw: this.formData.form?.dateOfMarriageOrCommonLaw,
      familyNameOfSpouse: this.formData.form?.familyNameOfSpouse,
      givenNameOfSpouse: this.formData.form?.givenNameOfSpouse,
      previouslyMarriedOrCommonLaw:
        this.formData.form?.previouslyMarriedOrCommonLaw,
      demoCheckbox: true,
      demoCheckbox2: false,
      demoTextarea: "Example text",
    });
  }

  private setValuesCurrentCountry(): void {
    this.currentCountry?.setValue({
      country: this.formData.form?.currentCountry.country,
      immigrationStatus: this.formData.form?.currentCountry.immigrationStatus,
      startDateOfImmigrationStatus:
        this.formData.form?.currentCountry.startDateOfImmigrationStatus,
      endDateOfImmigrationStatus:
        this.formData.form?.currentCountry.endDateOfImmigrationStatus,
      otherImmigrationStatus:
        this.formData.form?.currentCountry.otherImmigrationStatus,
    });
  }

  private setValuesPreviousCountries(): void {
    this.previousCountries.clear(); // reset the form array at first
    const savedCountries = this.formData?.form?.previousCountries;
    savedCountries?.forEach((c: any) => {
      const countryFields: FormGroup = this.createPreviousCountryFormGroup();
      countryFields?.setValue({
        country: c.country,
        immigrationStatus: c.immigrationStatus,
        startDateOfImmigrationStatus: c.startDateOfImmigrationStatus,
        endDateOfImmigrationStatus: c.endDateOfImmigrationStatus,
        otherImmigrationStatus: c.otherImmigrationStatus,
      });
      this.previousCountries.push(countryFields);
    });
  }

  private setValuesPreviousRelationship(): void {
    if (!this.isDependant) {
      this.previousRelationshipForm?.setValue({
        previousSpouseFamilyName: this.formData.form?.previousSpouseFamilyName,
        previousSpouseGivenName: this.formData.form?.previousSpouseGivenName,
        previousSpouseDob: this.formData.form?.previousSpouseDob,
        typeOfRelationship: this.formData.form?.typeOfRelationship,
        startDateOfRelationship: this.formData.form?.startDateOfRelationship,
        endDateOfRelationship: this.formData.form?.endDateOfRelationship,
      });
    } else {
      this.previousRelationshipForm?.setValue({
        previousSpouseFamilyName: this.formData.form?.previousSpouseFamilyName,
        previousSpouseGivenName: this.formData.form?.previousSpouseGivenName,
        typeOfRelationship: this.formData.form?.typeOfRelationship,
        startDateOfRelationship: this.formData.form?.startDateOfRelationship,
        endDateOfRelationship: this.formData.form?.endDateOfRelationship,
      });
    }
  }

  private watchCurrentCountry(): void {
    this.subscriptions.push(
      this.currentCountry?.controls.country?.valueChanges.subscribe((value) => {
        if (value === "511") {
          this.personalDetailsForm.controls.dateOfLastEntry.enable();
          this.personalDetailsForm.controls.placeOfLastEntry.enable();
        } else {
          this.personalDetailsForm.controls.dateOfLastEntry.reset();
          this.personalDetailsForm.controls.dateOfLastEntry.disable();
          this.personalDetailsForm.controls.dateOfLastEntry.updateValueAndValidity();
          this.personalDetailsForm.controls.placeOfLastEntry.reset();
          this.personalDetailsForm.controls.placeOfLastEntry.disable();
          this.personalDetailsForm.controls.placeOfLastEntry.updateValueAndValidity();
        }
      })
    );
  }

  // updates validators for date fields, "other" field.
  // if applicable, adds the validators, if not removes them
  public watchCurrentCountryStatus(): void {
    this.subscriptions.push(
      this.currentCountry.controls.immigrationStatus.valueChanges.subscribe(
        (value) => {
          //  check if dates required
          //  clear & reset if it's not applicable, add validators otherwise
          if (
            this.currentCountryImmigrationStatusesWithRequiredDates.includes(
              value
            )
          ) {
            this.currentCountry.controls.startDateOfImmigrationStatus.setValidators(
              [
                /* req'd if status is visitor, worker or student (03, 04, 05) (see template) */
                this.validators.validatorDateFormat,
                this.validators.validatorDate,
                this.validators.validatorDatePast,
                Validators.required,
              ]
            );
            this.currentCountry.controls.endDateOfImmigrationStatus.setValidators(
              [
                this.validators.validatorDateFormat,
                this.validators.validatorDate,
                this.validators.validatorDateFuture,
                Validators.required,
              ]
            );
          } else {
            this.currentCountry.controls.startDateOfImmigrationStatus.reset();
            this.currentCountry.controls.startDateOfImmigrationStatus.clearValidators();
            this.currentCountry.controls.endDateOfImmigrationStatus.reset();
            this.currentCountry.controls.endDateOfImmigrationStatus.clearValidators();
          }

          //  check if other field required:
          //  clear & reset if it's not applicable, add validators otherwise
          if (value === this.otherImmigrationStatusId) {
            this.currentCountry.controls.otherImmigrationStatus.setValidators([
              Validators.required,
              Validators.pattern(this.regexOpenText),
              Validators.maxLength(30),
            ]);
            this.currentCountry.controls.startDateOfImmigrationStatus.setValidators(
              [
                this.validators.validatorDateFormat,
                this.validators.validatorDate,
                this.validators.validatorDatePast,
              ]
            );
            this.currentCountry.controls.endDateOfImmigrationStatus.setValidators(
              [
                this.validators.validatorDateFormat,
                this.validators.validatorDate,
                this.validators.validatorDateFuture,
              ]
            );
          } else {
            this.currentCountry.controls.otherImmigrationStatus.reset();
            this.currentCountry.controls.otherImmigrationStatus.clearValidators();
          }

          this.currentCountry.controls.otherImmigrationStatus.updateValueAndValidity();
          this.currentCountry.controls.endDateOfImmigrationStatus.updateValueAndValidity();
          this.currentCountry.controls.startDateOfImmigrationStatus.updateValueAndValidity();
        }
      )
    );
  }

  private watchCommonLawOrMarriage(): void {
    this.subscriptions.push(
      this.personalDetailsForm?.controls.maritalStatus?.valueChanges.subscribe(
        () => {
          // manage fields based on marital status "Married" or "Commonlaw"
          if (this.hasMarriageOrCommonLaw) {
            this.personalDetailsForm.controls.dateOfMarriageOrCommonLaw.enable();
            this.personalDetailsForm.controls.familyNameOfSpouse.enable();
            // optional
            this.personalDetailsForm.controls.givenNameOfSpouse.enable();
          } else {
            this.personalDetailsForm.controls.dateOfMarriageOrCommonLaw.reset();
            this.personalDetailsForm.controls.dateOfMarriageOrCommonLaw.disable();
            this.personalDetailsForm.controls.dateOfMarriageOrCommonLaw.updateValueAndValidity();
            this.personalDetailsForm.controls.familyNameOfSpouse.reset();
            this.personalDetailsForm.controls.familyNameOfSpouse.disable();
            this.personalDetailsForm.controls.familyNameOfSpouse.updateValueAndValidity();
            // optional
            this.personalDetailsForm.controls.givenNameOfSpouse.reset();
            this.personalDetailsForm.controls.givenNameOfSpouse.disable();
            this.personalDetailsForm.controls.givenNameOfSpouse.updateValueAndValidity();
          }

          // Manage fields based on marriage status "Annuled marriage", "Divorced", "Separated", or "Widowed"
          if (this.hasPreviousMarriageOrCommonLawStatus) {
            this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.setValue(
              true
            );
            this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.disable();
          } else {
            if (!this.hasMarriageOrCommonLaw) {
              this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.reset();
              this.user.can("documents:write") &&
                this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.enable();
              this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.setValue(
                false
              );
            }
          }
          this.personalDetailsForm.controls.previouslyMarriedOrCommonLaw.updateValueAndValidity();
        }
      )
    );
  }

  private watchNickname(): void {
    this.subscriptions.push(
      this.personalDetailsForm?.controls.usedOtherName?.valueChanges.subscribe(
        (value) => {
          if (value === true) {
            this.personalDetailsForm.controls.otherFamilyName.enable();
            this.personalDetailsForm.controls.otherGivenName.enable();
          } else {
            this.personalDetailsForm.controls.otherFamilyName.reset();
            this.personalDetailsForm.controls.otherFamilyName.disable();
            this.personalDetailsForm.controls.otherFamilyName.updateValueAndValidity();
            this.personalDetailsForm.controls.otherGivenName.reset();
            this.personalDetailsForm.controls.otherGivenName.disable();
            this.personalDetailsForm.controls.otherGivenName.updateValueAndValidity();
          }
        }
      )
    );
  }

  private getHeightInCmValidators(): ValidatorFn[] {
    const validators = [
      this.validators.validatorOnlyNumbers,
      Validators.min(this.minHeight),
      Validators.max(this.maxHeight),
    ];
    if (this.isDependantAccompanying) {
      validators.push(Validators.required);
    }
    return validators;
  }

  private getEyeColorValidators(): ValidatorFn[] {
    const validators = [];
    if (this.isDependantAccompanying) {
      validators.push(Validators.required);
    }
    return validators;
  }

  private updateValidatorsForHeightAndEyeColour(): void {
    this.personalDetailsForm
      ?.get("heightInCM")
      ?.setValidators(this.getHeightInCmValidators());
    this.personalDetailsForm
      ?.get("eyeColour")
      ?.setValidators(this.getEyeColorValidators());
  }

  private watchIsNotAccompanyingPA(): void {
    this.subscriptions.push(
      this.dependantDetailsForm?.controls?.accompanyingPA?.valueChanges?.subscribe(
        (value) => {
          if (value === "false") {
            this.dependantDetailsForm.controls.reasonNotAccompanying.enable();
            this.isDependantAccompanying = false;
            this.updateValidatorsForHeightAndEyeColour();
          } else {
            this.dependantDetailsForm.controls.reasonNotAccompanying.reset();
            this.dependantDetailsForm.controls.reasonNotAccompanying.disable();
            this.dependantDetailsForm.controls.reasonNotAccompanying.updateValueAndValidity();
            this.isDependantAccompanying = true;
            this.updateValidatorsForHeightAndEyeColour();
          }

          // Handling dependant type validations if the depandant is required/visible
          if (this.isDependantTypeRequired) {
            this.dependantDetailsForm?.controls?.dependantType.setValidators(
              this.dependantTypeValidation
            );
          } else {
            // If the dependant type isn't required, clear the value
            this.dependantDetailsForm?.controls?.dependantType.clearValidators();
            this.dependantDetailsForm?.controls?.dependantType.reset();
          }
          this.dependantDetailsForm?.controls?.dependantType.updateValueAndValidity();
        }
      )
    );
  }

  private watchRelationshipToPA(): void {
    this.subscriptions.push(
      this.dependantDetailsForm?.controls?.relationshipToPA.valueChanges?.subscribe(
        (value) => {
          // Handling dependant type validations if the depandant is required/visible
          if (this.isDependantTypeRequired) {
            this.dependantDetailsForm?.controls?.dependantType.setValidators(
              this.dependantTypeValidation
            );
          } else {
            // If the dependant type isn't required, clear the value
            this.dependantDetailsForm?.controls?.dependantType.clearValidators();
            this.dependantDetailsForm?.controls?.dependantType.reset();
          }
          this.dependantDetailsForm?.controls?.dependantType.updateValueAndValidity();
          if (value === "85") {
            this.dependantDetailsForm.controls.otherRelationshipToPA.setValidators(
              [
                Validators.required,
                Validators.maxLength(30),
                this.validators.validatorAlphaNumPlusFew,
              ]
            );
          } else {
            this.dependantDetailsForm.controls.otherRelationshipToPA.reset();
            this.dependantDetailsForm.controls.otherRelationshipToPA.clearValidators();
          }
          this.dependantDetailsForm.controls.otherRelationshipToPA.updateValueAndValidity();
        }
      )
    );
  }

  private watchPreviousCountryImmigrationStatus(
    status: AbstractControl,
    otherField: AbstractControl
  ): void {
    this.subscriptions.push(
      status?.valueChanges.subscribe((value) => {
        if (value === this.otherImmigrationStatusId) {
          otherField.setValidators([
            Validators.required,
            Validators.maxLength(30),
            Validators.pattern(this.regexOpenText),
          ]);
        } else {
          otherField.reset();
          otherField.clearValidators();
        }
        otherField.updateValueAndValidity();
      })
    );
  }

  private watchPreviousRelationship(): void {
    this.subscriptions.push(
      this.personalDetailsForm?.controls.previouslyMarriedOrCommonLaw?.valueChanges.subscribe(
        (value) => {
          if (value === true) {
            this.previousRelationshipForm.enable();
          } else {
            this.previousRelationshipForm.reset();
            this.previousRelationshipForm.disable();
            this.previousRelationshipForm.updateValueAndValidity();
          }
        }
      )
    );
  }

  private getProgramId(): void {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.lob?.programId) {
          this.caseProgramId = caseData.lob.programId;
        }
      });
  }

  private watchMaritalStatus(): void {
    let isFamilySpouseLob = false;
    let isFamilyConjugalPartnerLob = false;
    let isFamilyCommonLawLob = false;
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        isFamilySpouseLob = caseData.lobId === LobIds.Spouse;
        isFamilyConjugalPartnerLob = caseData.lobId === LobIds.ConjugalPartner;
        isFamilyCommonLawLob = caseData.lobId === LobIds.CommonLaw;
      });
    const maritalStatusControl = this.personalDetailsForm?.get("maritalStatus");
    if (maritalStatusControl) {
      this.subscriptions.push(
        maritalStatusControl.valueChanges.subscribe((status) => {
          if (isFamilySpouseLob || isFamilyCommonLawLob) {
            if (["09", "04", "05", "02", "06"].includes(status)) {
              maritalStatusControl.setErrors({
                spouseMaritalStatusError: true,
              });
              return;
            }
            maritalStatusControl.setErrors(null);
          }
          if (isFamilyConjugalPartnerLob) {
            if (["03", "01"].includes(status)) {
              maritalStatusControl.setErrors({
                conjugalPartnerMaritalStatusError: true,
              });
              return;
            }
            maritalStatusControl.setErrors(null);
          }
        })
      );
    }
  }
}
