import {
  Component,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnChanges,
} from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AlertService, ValidationService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { IMM5562SectionBaseComponent } from "../imm5562-section-base/imm5562-section-base.component";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5562-section-c-form",
  templateUrl: "./imm5562-section-c.component.html",
  styleUrls: ["./imm5562-section-c.component.scss"],
})
export class IMM5562SectionCComponent
  extends IMM5562SectionBaseComponent
  implements OnInit
{
  public sectionCForm: FormGroup;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    protected cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private translate: TranslateService,
    private validationService: ValidationService,
    public user: UserService
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.sectionCForm = this.createSectionCFormGroup();
    this.addTrip();
  }

  public get isFormValid(): boolean {
    return this.sectionCForm?.valid;
  }

  public get isFormTouched(): boolean {
    return this.sectionCForm?.touched || this.isTripRemoved;
  }

  public get value(): any {
    return this.sectionCForm?.value;
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  public showFormInvalidErrors(): void {
    this.sectionCForm?.markAllAsTouched();
  }

  public patchFormData(data: any): void {
    if (data) {
      this.formValues = data.form;
      this.setFormValues();
      this.cdRef.detectChanges();
    }
  }

  public addTrip(index?: number): void {
    //  we call addTrip for the first time in ngOnInit, where there is no index value passed (index undefined)
    if (index === undefined || index < this.maxTrips - 1) {
      this.trips.push(this.createTripFormGroup());
    } else {
      this.alertService.danger(this.alertMaxTrips);
    }
  }

  public removeTrip(index: number): void {
    this.trips.removeAt(index);
    this.isTripRemoved = true;
  }
  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.isFormTouched;
  }

  //  formGroup for repeating trips formArray
  private createTripFormGroup(): FormGroup {
    return this.fb.group({
      givenName: [null, Validators.maxLength(this.maxFieldLengths.givenName)],
      length: [null, Validators.maxLength(this.maxFieldLengths.tripLength)],
      from: [null, Validators.maxLength(this.maxFieldLengths.dateFrom)],
      to: [null, Validators.maxLength(this.maxFieldLengths.dateTo)],
      destination: [
        null,
        Validators.maxLength(this.maxFieldLengths.destination),
      ],
      purposeOfTravel: [
        null,
        Validators.maxLength(this.maxFieldLengths.purpose),
      ],
    });
  }

  //  formGroup for entire section, including notrips checkbox and trips formArray
  private createSectionCFormGroup(): FormGroup {
    return this.fb.group({
      haveNotTravelled: [null],
      trips: this.trips,
    });
  }

  private setFormValues(): void {
    this.sectionCForm.controls?.haveNotTravelled.setValue(
      this.formValues.haveNotTravelled
    );
    this.setTrips();
  }

  //  set trips formArray, creating a formGroup for each
  private setTrips(): void {
    const savedTrips = this.formValues?.trips;
    if (savedTrips && savedTrips.length > 0) {
      this.trips.clear(); // reset the form array at first
      savedTrips?.forEach((trip: any) => {
        const tripFields: FormGroup = this.createTripFormGroup();
        tripFields?.patchValue({
          givenName: trip.givenName,
          length: trip.length,
          from: trip.from,
          to: trip.to,
          destination: trip.destination,
          purposeOfTravel: trip.purposeOfTravel,
        });
        this.trips.push(tripFields);
      });
    }
  }

  private get alertMaxTrips(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }
}
