import * as _ from "lodash";
import * as fromApp from "../../../store/app.reducer";

import { ActivatedRoute, Router } from "@angular/router";

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertService,
  ModalService,
  ValidationService,
  documentTypes,
} from "lib";
import { Observable, Subscription, from, of, zip } from "rxjs";

import { Store } from "@ngrx/store";

import { AuthService } from "../../../core/auth-module/services/auth.service";
import { WebFormService } from "../../../shared/services/web-form/web-form.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";

import { first, retry, switchMap, take } from "rxjs/operators";
import {
  loadCurrentFormForIMMDocument,
  loadCurrentIMMDocument,
} from "../../../shared/currentIMMDocument/store/actions/currentIMMDocument.actions";

import { CanComponentDeactivate } from "../../../routing/guards/nav.guard";
import { ComponentLoadDirective } from "../../../core/directives/componentLoad.directive";
import { Document } from "../../../core/models/document.model";
import FormNavbarItems from "./imm5669-form-nabvar-items.json";
import { IFormInfo } from "../../../shared/services/web-form/models";
import { IMM5669SectionAComponent } from "../../forms/IMM5669/imm5669-section-a/imm5669-section-a.component";
import { IMM5669StartComponent } from "../../forms/IMM5669/imm5669-start/imm5669-start.component";
import { IMM5669SectionBComponent } from "../../forms/IMM5669/imm5669-section-b/imm5669-section-b.component";
import { IMM5669SectionCComponent } from "../../forms/IMM5669/imm5669-section-c/imm5669-section-c.component";
import { IMM5669SectionDComponent } from "../../forms/IMM5669/imm5669-section-d/imm5669-section-d.component";
import { IMM5669SectionEComponent } from "../../forms/IMM5669/imm5669-section-e/imm5669-section-e.component";
import { IMM5669SectionFComponent } from "../../forms/IMM5669/imm5669-section-f/imm5669-section-f.component";
import { IMM5669SectionGComponent } from "../../forms/IMM5669/imm5669-section-g/imm5669-section-g.component";
import { IMM5669SectionHComponent } from "../../forms/IMM5669/imm5669-section-h/imm5669-section-h.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669",
  templateUrl: "./IMM5669page.component.html",
  styleUrls: [
    "./IMM5669page.component.scss",
    "../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM5669PageComponent
  implements OnInit, OnDestroy, AfterViewChecked, CanComponentDeactivate
{
  @ViewChild(ComponentLoadDirective, { static: true })
  praComponentHost: ComponentLoadDirective;

  public currentLoadedComponent: ComponentRef<any>;
  public loading: boolean = false;
  public goBackModalId = "goBackModalId";
  public isReadOnly: boolean;
  public nextCopyHTML: string;
  public FormNavbarItems = FormNavbarItems;
  public curr = 0;
  public formVersion: number;

  private caseId: string;
  private document?: Document;
  private formId?: string;

  private currentPage: string = "page1";
  private currentLang: string;
  private all5669Pages = [
    "page1",
    "page2",
    "page3",
    "page4",
    "page5",
    "page6",
    "page7",
    "page8",
    "page9",
  ];

  private mainSubscr$: Subscription;
  private selectedCaseSubs$: Subscription;
  private addFamilyMember$: Subscription;
  private interFormsSignal$: Subscription;
  private deleteFamMemberSubs$: Subscription;
  private formCRUDSubs$: Subscription;
  private allSubscriptions: Subscription[] = [];

  // navigation modal
  private navigationModalId = "navigationModal";
  private isNavigatingInsideForm = true;
  private navigationModalSelection = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private webFormSvc: WebFormService,
    private translate: TranslateService,
    private alertService: AlertService,
    private cdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private validationService: ValidationService,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle("INTAKE.IMM5669.PAGES.PAGE_TITLE");
    this.startSubscription();
    this.startFamilyMemberSubscription();
    this.isReadOnly = !this.user.can("documents:write");
  }

  ngOnDestroy(): void {
    this.allSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  // public

  public saveAndNext(): void {
    this.isNavigatingInsideForm = true;
    const formDataToSave = this.currentLoadedComponent.instance.getFormData();
    if (formDataToSave.isInvalid) {
      alert("There are errors on page. Your data can't be saved");
      return;
    }
    this.loading = true;
    if (this.formCRUDSubs$) {
      this.formCRUDSubs$.unsubscribe();
    }
    if (formDataToSave.sectionName === "startPage") {
      const newNestedPath = this.routeLocalizer.getNestedPath(
        this.currentLang,
        ["INTAKE", "INTAKE_CASE_DETAILS"]
      );
      this.router.navigate([
        `/${this.currentLang}/${newNestedPath}${this.caseId}`,
      ]);
    } else {
      if (!this.isReadOnly) {
        this.formCRUDSubs$ = from(
          this.webFormSvc.updateFormSection(
            { ...this.formInfo, sectionId: formDataToSave.sectionName },
            { form: formDataToSave.sectionInfo, version: this.formVersion }
          )
        ).subscribe(
          (data) => {
            this.navigateTo(this.nextPage);
          },
          (err) => {
            this.loading = false;
            if (err.response.status === 409) {
              this.alertService.danger(
                this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          }
        );
      } else {
        this.navigateTo(this.nextPage);
      }
    }
  }

  public navigateBack(forceNavigation?: boolean): void {
    this.isNavigatingInsideForm = true;
    const unsavedData =
      this.currentLoadedComponent.instance.isFormDataUnSaved();
    // this.hasCanDeactivateTriggeredAlready = false;
    if (unsavedData && !forceNavigation) {
      this.openNavigateModal();
      return;
    }

    if (this.currentPage === this.all5669Pages[0]) {
      const newNestedPath = this.routeLocalizer.getNestedPath(
        this.currentLang,
        ["INTAKE", "INTAKE_CASE_DETAILS"]
      );
      this.router.navigate([
        `/${this.currentLang}/${newNestedPath}${this.caseId}`,
      ]);
    } else {
      this.navigateTo(this.previousPage);
    }
  }

  formSideNavbarNavigation(event: number) {
    this.currentPage = this.all5669Pages[event + 2];
    this.navigateBack();
  }

  // Fires before the entire route is expected to change (not params in the route)
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // console.log ('can deactivate fired');
    this.allSubscriptions.push(
      this.modalService.navigateAwaySelection$.subscribe((selection) => {
        this.navigationModalSelection = selection;
      })
    );
    // check if form has been touched or saved
    const unsavedData =
      this.currentLoadedComponent?.instance?.isFormDataUnSaved();
    // console.log ("these are the values baby", unsavedData, !this.isFormSaved)
    if (!this.isNavigatingInsideForm && unsavedData) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$.pipe(take(1));
    } else {
      return true;
    }
  }

  // Fires when the params in the url change such as pages between the form itself
  public openNavigateModal(): void {
    this.modalService.open(this.goBackModalId);
  }

  public closeModal(): void {
    this.modalService.close(this.goBackModalId);
    this.isNavigatingInsideForm = false;
  }

  public forceGoBack() {
    this.closeModal();
    this.navigateBack(true);
  }

  public get disableNextButton(): boolean {
    if (this.currentPage === this.all5669Pages[0]) {
      return this.currentLoadedComponent?.instance?.forms?.length === 0;
    } else {
      return !this.currentLoadedComponent?.instance?.isFormValid;
    }
  }

  public get nextButtonKey(): string {
    if (this.currentPage === this.all5669Pages[0]) {
      this.nextCopyHTML = "INTAKE.FORM_PAGE_COMPLETE";
    } else {
      this.nextCopyHTML = this.isReadOnly
        ? "INTAKE.FORM_PAGE_NEXT"
        : "INTAKE.FORM_PAGE_SAVE";
    }
    return this.nextCopyHTML;
  }

  // private
  private get alertDeleteSuccess(): string {
    return this.translate.instant(
      "INTAKE.TABLE_ALERT.DELETE_FAMILY_MEMBER_SUCCESS"
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private get nextPage(): string {
    return this.all5669Pages[this.all5669Pages.indexOf(this.currentPage) + 1];
  }

  private get previousPage(): string {
    return this.all5669Pages[this.all5669Pages.indexOf(this.currentPage) - 1];
  }

  private get formInfo(): IFormInfo {
    const info: IFormInfo = {
      caseId: this.caseId || "",
      documentTypeId: documentTypes.imm5669.id,
    };
    if (this.document) {
      info.documentId = this.document.id;
    }
    if (this.formId) {
      info.formId = this.formId;
    }
    return info;
  }

  private navigateTo(pageId: string): void {
    if (pageId === undefined || pageId === this.all5669Pages[0]) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { pageId },
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { pageId },
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
    }
  }

  private startSubscription(): void {
    this.selectedCaseSubs$ = this.store
      .select("selectedCase")
      .subscribe((selectedCase) => {
        this.document = selectedCase.caseDocuments?.find(
          (item: any) => item.documentTypeId === documentTypes.imm5669.id
        );
      });
    this.mainSubscr$ = zip(
      this.store.select(fromApp.selectRouteParams),
      this.store.select(fromApp.selectQueryParams)
    )
      .pipe(
        switchMap((data) => {
          const [routeParams, queryParams] = data;
          const emptyPrams = _.isEmpty(routeParams) && _.isEmpty(queryParams);
          const isNavigatingAway =
            !this.isNavigatingInsideForm || this.navigationModalSelection;
          // checking the user is leaving the imm5406 form
          // console.log (this.hasCanDeactivateTriggeredAlready, this.navigationModalSelection);
          if (isNavigatingAway || emptyPrams) {
            this.loading = false;
            return of({ isNavigatingAway: true });
          }
          this.loading = true;
          this.praComponentHost.viewContainerRef.clear();
          this.caseId = routeParams?.caseId;
          this.formId = queryParams?.formId;
          this.currentPage = queryParams?.pageId || this.all5669Pages[0];

          if (this.document && !this.formId) {
            return from(this.webFormSvc.getDocumentData(this.formInfo)).pipe(
              retry(3)
            );
          } else if (!this.formId) {
            return this.webFormSvc.createDocument(this.formInfo);
          } else {
            return from(this.webFormSvc.getFormData(this.formInfo)).pipe(
              retry(3)
            );
          }
        })
      )
      .subscribe(
        (data) => {
          if (!data.isNavigatingAway) {
            if (this.formId && this.formId !== data.form.form._id) {
              console.error(
                `API is returning data for different form. Id passed is:
            ` +
                  this.formId +
                  " ID of form received is : " +
                  data.form.form._id
              );
            }
            if (this.formId) {
              this.store.dispatch(
                loadCurrentFormForIMMDocument({ currentForm: data.form })
              );
              this.formVersion = data.form.version;
              console.log("formVersion:", this.formVersion);
            } else {
              this.store.dispatch(
                loadCurrentIMMDocument({ documentInfo: data })
              );
              this.formVersion = data.version;
              console.log("formVersion on start page:", this.formVersion);
            }
            this.loadComponentForStep(this.currentPage);
            this.isNavigatingInsideForm = false;
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.alertService.danger(this.alertTechnicalError);
        }
      );
    this.allSubscriptions.push(this.selectedCaseSubs$);
    this.allSubscriptions.push(this.mainSubscr$);
  }

  private startFamilyMemberSubscription(): void {
    this.interFormsSignal$ = this.webFormSvc.immDocumentSignal$.subscribe(
      (signal) => {
        if (signal.message === "ADD_NEW_FAMILY_MEMBER") {
          this.isNavigatingInsideForm = true;
          this.addNewFamilyMember();
        } else if (signal.message === "EDIT_FAMILY_MEMBER" && signal.formId) {
          this.isNavigatingInsideForm = true;
          this.editFamilyMember(signal.formId);
        } else if (signal.message === "DELETE_FAMILY_MEMBER" && signal.formId) {
          this.deleteFamilyMember(signal.formId);
        }
      }
    );
    this.allSubscriptions.push(this.interFormsSignal$);
  }

  private loadComponentForStep(stepNbr: string): void {
    const viewContainerRef = this.praComponentHost.viewContainerRef;
    viewContainerRef.clear();
    let componentFactory;
    switch (stepNbr) {
      case this.all5669Pages[1]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionAComponent
          );
        this.curr = 1;
        break;
      }
      case this.all5669Pages[2]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionBComponent
          );
        this.curr = 2;
        break;
      }
      case this.all5669Pages[3]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionCComponent
          );
        this.curr = 3;
        break;
      }
      case this.all5669Pages[4]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionDComponent
          );
        this.curr = 4;
        break;
      }
      case this.all5669Pages[5]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionEComponent
          );
        this.curr = 5;
        break;
      }
      case this.all5669Pages[6]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionFComponent
          );
        this.curr = 6;
        break;
      }
      case this.all5669Pages[7]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionGComponent
          );
        this.curr = 7;
        break;
      }
      case this.all5669Pages[8]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669SectionHComponent
          );
        this.curr = 8;
        break;
      }
      case this.all5669Pages[0]:
      default: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5669StartComponent
          );
        this.curr = 0;
        break;
      }
    }
    this.currentLoadedComponent = viewContainerRef.createComponent<
      | IMM5669StartComponent
      | IMM5669SectionAComponent
      | IMM5669SectionBComponent
      | IMM5669SectionCComponent
      | IMM5669SectionDComponent
      | IMM5669SectionEComponent
      | IMM5669SectionFComponent
      | IMM5669SectionGComponent
      | IMM5669SectionHComponent
    >(componentFactory);
    this.currentLoadedComponent.instance.data = { formId: this.formId };
  }

  private addNewFamilyMember(): void {
    if (this.addFamilyMember$) {
      this.addFamilyMember$.unsubscribe();
    }
    this.addFamilyMember$ = from(
      this.webFormSvc.createFormData(this.formInfo)
    ).subscribe(
      (dataForm) => {
        this.formId = dataForm.form._id;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            pageId: this.all5669Pages[1],
            documentId: this.document?.id,
            formId: dataForm.form._id,
          },
          queryParamsHandling: "merge",
        });
      },
      (err) => {
        this.alertService.danger(this.alertTechnicalError);
      }
    );
    this.allSubscriptions.push(this.addFamilyMember$);
  }

  private editFamilyMember(formId: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageId: this.all5669Pages[1],
        documentId: this.document?.id,
        formId,
      },
      queryParamsHandling: "merge",
    });
  }

  private deleteFamilyMember(formId: string): void {
    if (this.deleteFamMemberSubs$) {
      this.deleteFamMemberSubs$.unsubscribe();
    }
    this.deleteFamMemberSubs$ = from(
      this.webFormSvc.deleteFormData({
        ...this.formInfo,
        formId,
      })
    ).subscribe(
      (data) => {
        this.alertService.success(this.alertDeleteSuccess);
        this.store.dispatch(loadCurrentIMMDocument({ documentInfo: data }));
      },
      (error) => {
        this.alertService.danger(this.alertTechnicalError);
      }
    );
    this.allSubscriptions.push(this.deleteFamMemberSubs$);
  }
}
