<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>

<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_D.FORM_HEADING' | translate"
  [body]="'INTAKE.IMM5669.FORMS.SECTION_D.FORM_SUBTITLE' | translate"
></lib-copy-block>

<lib-information-area [config]="infoConfig">
  {{ "INTAKE.IMM5669.FORMS.SECTION_D.WARNING_LABEL" | translate }}
</lib-information-area>
<form autocomplete="off" class="main-form">
  <ng-container
    *ngFor="
      let instance of personalHistory?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="personalHistory"
  >
    <fieldset [formGroup]="instance" class="form-array__fieldset">
      <legend>
        {{ "INTAKE.IMM5669.FORMS.SECTION_D.INSTANCE_LABEL" | translate }}
        {{ i + 1 }}
      </legend>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'from' + i"
        [formControl]="instance.controls.from"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'to' + i"
        [formControl]="instance.controls.to"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'activity' + i"
        [formControl]="instance.controls.activity"
        [label]="'INTAKE.IMM5669.FORMS.ACTIVITY_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.activity.touched &&
            instance.controls?.activity.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.activity }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'cityAndCountry' + i"
        [formControl]="instance.controls.cityAndCountry"
        [label]="'INTAKE.IMM5669.FORMS.ACTIVITY_LOCATION_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.cityAndCountry.touched &&
            instance.controls?.cityAndCountry.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.cityAndCountry }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'status' + i"
        [formControl]="instance.controls.status"
        [label]="'INTAKE.IMM5669.FORMS.STATUS_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.status.touched &&
            instance.controls?.status.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.status }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'nameOfEmployerOrSchool' + i"
        [formControl]="instance.controls.nameOfEmployerOrSchool"
        [label]="'INTAKE.IMM5669.FORMS.INSTITUTION_NAME_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.nameOfEmployerOrSchool.touched &&
            instance.controls?.nameOfEmployerOrSchool.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.nameOfEmployerOrSchool }
          }}
        </span>
      </lib-form-input>

      <!-- ADD/REMOVE BUTTONS -->
      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeInstance(i)"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.REMOVE" | translate }}
          <span class="visually-hidden"
            >{{ "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_LEGEND" | translate }}
            {{ i + 1 }}</span
          >
        </button>
        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addInstance()"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.ADD" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
