import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";
import * as CaseActions from "../../shared/case-module/store/case.actions";
import * as fromApp from "../../store/app.reducer";

import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../../../../../config/aws-exports";
import { AuthService } from "../../core/auth-module/services/auth.service";
import { CaseService } from "../../shared/case-module/case.service";
import { DocumentService } from "../../shared/services/document/document.service";

Amplify.configure(awsconfig);

@Injectable({
  providedIn: "root",
})
export class ConfirmationLandingPageResolver implements Resolve<any> {
  case: any;

  constructor(
    private store: Store<fromApp.State>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private caseService: CaseService,
    private documentService: DocumentService
  ) {
    this.authService.checkSession();
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    try {
      // get applicant case
      const caseId =
        route.paramMap.get("id") === null ? "" : route.paramMap.get("id");
      const user = await Auth.currentAuthenticatedUser();
      const applicantCase = (
        await this.caseService.getCaseByCaseId(user.attributes.email, caseId)
      )[0];
      this.store.dispatch(new CaseActions.SetCaseByEmail(applicantCase));

      // get case documents
      const { id, lobId, phaseId } = applicantCase;
      const caseDocuments = await this.documentService.getDocumentByCaseId(id);
      const caseWithDocuments = { ...applicantCase, caseDocuments };

      return caseWithDocuments;
    } catch (error) {
      return [];
    }
  }
}
