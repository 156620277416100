import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { UserGroup } from "lib";
import * as fromApp from "../../../store/app.reducer";
import { map, first } from "rxjs/operators";
import routePaths from "../../../routing/route-paths";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";

@Injectable({ providedIn: "root" })
export class FirstLoginGuard implements CanActivate {
  public isAuthenticated: boolean;
  public isFirstLogin: boolean | undefined;
  public userPool: string | undefined;

  constructor(
    private router: Router,
    private store: Store<fromApp.State>,
    private routeLocalizer: RouteLocalizerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): any {
    this.store
      .select("auth")
      .pipe(
        first(),
        map((authState) => authState.user)
      )
      .subscribe((user) => {
        this.isFirstLogin = user?.isFirstLogin;
        this.userPool = user?.userPool;
      });

    if (
      (this.isFirstLogin && this.userPool === UserGroup.Client) ||
      this.userPool === UserGroup.Rep
    ) {
      return true;
    } else {
      return this.router.parseUrl(
        `/${this.routeLocalizer.getCurrentRouteLang()}/${
          this.routeLocalizer.getCurrentRouteLang() === "fr"
            ? routePaths.fr.LOGIN
            : routePaths.en.LOGIN
        }`
      );
    }
  }
}
