import { Component, OnInit } from "@angular/core";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { Subscription } from "rxjs";

import { SponsorProfileService } from "./sponsor-profile.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import {
  PsrService,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";

@Component({
  selector: "pra-sponsor-profile",
  templateUrl: "./sponsor-profile.component.html",
  styleUrls: ["./sponsor-profile.component.scss"],
})
export class SponsorProfileComponent implements OnInit {
  public lang: string;
  public nextRoute: string;
  public isProcessing: boolean = false;
  public sponsorProfileForm = new FormGroup({
    familyName: new FormControl("", {
      validators: [Validators.required],
    }),
    givenName: new FormControl("", {
      validators: [],
    }),
  });

  private _subs: Subscription[] = [];
  private _caseId: number | null = null;
  private _lobId: number | null = null;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    public sponsorProfileService: SponsorProfileService,
    public userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private psrService: PsrService
  ) {}

  ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    const user = {
      givenName: this.route.snapshot.data["sponsorProfile"].givenName,
      familyName: this.route.snapshot.data["sponsorProfile"].familyName,
    };
    this.sponsorProfileForm.patchValue(user);
    this._subs.push(
      this.route.params.subscribe((params) => {
        if (params["caseId"] && params["lobId"]) {
          this._caseId = +params["caseId"];
          this._lobId = +params["lobId"];
        }
      })
    );
  }

  ngOnDestroy() {
    this._subs.forEach((sub) => sub.unsubscribe());
  }

  sponsorProfileFormSubmit(e: any) {
    if (this.sponsorProfileForm.invalid) {
      this.sponsorProfileForm.markAllAsTouched();
    } else {
      this.isProcessing = true;
      this._subs.push(
        this.sponsorProfileService
          .addMember(this.sponsorProfileForm.value)
          .subscribe({
            next: () => {
              if (
                this._caseId &&
                this._lobId &&
                this.psrService.isPSR(this._lobId)
              ) {
                this.addSponsorToCase(this._caseId);
              } else {
                this.redirect();
              }
            },
            error: (error) => {
              this.isProcessing = false;
              this.alertService.danger(this.alertTechnicalError);
              console.error("error saving sponsor profile", error);
            },
          })
      );
    }
  }

  private addSponsorToCase(caseId: number) {
    this.psrService
      .addMember(
        {
          givenName: this.sponsorProfileForm.controls.givenName.value,
          familyName: this.sponsorProfileForm.controls.familyName.value,
          emailAddress: this.userService.currentUserEmail,
        },
        caseId,
        RoleIds.PRIMARY_SPONSOR
      )
      .subscribe({
        next: () => {
          this.redirect();
        },
        error: (error) => {
          if (error.message === "Request failed with status code 409") {
            this.redirect();
          } else {
            this.isProcessing = false;
            this.alertService.danger(this.alertTechnicalError);
            console.error("error saving case member", error);
          }
        },
      });
  }

  private redirect(): void {
    this.isProcessing = false;
    const nextRoute = this._caseId
      ? this.routeLocalizer.getNestedPath(
          this.lang,
          ["INTAKE", "INTAKE_CASE_DETAILS"],
          ""
        ) + this._caseId
      : this.routeLocalizer.getNestedPath(
          this.lang,
          ["INTAKE", "VIEW_APPLICATIONS"],
          ""
        );
    this.router.navigate([`/${this.lang}/${nextRoute}`]);
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }
}
