import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RepAccountInfo } from "@pr-applicant/app/core/models/rep-account-info";
import { Subject, Subscription } from "rxjs";
import { RepIdentificationUploadTableComponent } from "../rep-identification-upload-table/rep-identification-upload-table.component";

@Component({
  selector: "pra-rep-info",
  templateUrl: "./rep-info.component.html",
})
export class RepInfoFormComponent implements OnInit, OnDestroy {
  @Input() accountInfo: Subject<RepAccountInfo>;
  @ViewChild(RepIdentificationUploadTableComponent, { static: false })
  repIdInfo: RepIdentificationUploadTableComponent;
  docLink = "";
  private subs: Subscription[] = [];

  public repInfoForm = this.fb.group({
    givenName: [null, [Validators.required, Validators.maxLength(100)]],
    familyName: [null, [Validators.required, Validators.maxLength(100)]],
    businessPhoneNumber: [
      null,
      [Validators.required, Validators.maxLength(34)],
    ],
    streetNumber: [null, [Validators.required, Validators.maxLength(10)]],
    streetName: [null, [Validators.required, Validators.maxLength(100)]],
    aptNumber: [null, [Validators.maxLength(10)]],
    city: [null, [Validators.required, Validators.maxLength(30)]],
    province: [null, [Validators.maxLength(40)]],
    postalCode: [null, [Validators.maxLength(10)]],
    country: [null, [Validators.required, Validators.maxLength(100)]],
    membershipId: [null, [Validators.required, Validators.maxLength(38)]],
    firmName: [null, [Validators.maxLength(100)]],
    governingBody: [null, [Validators.required, Validators.maxLength(100)]],
  });

  constructor(private fb: UntypedFormBuilder) {}

  isValid(): boolean {
    return this.repInfoForm.valid;
  }

  value(): any {
    const formData = this.repInfoForm.value;
    return {
      givenName: formData.givenName,
      familyName: formData.familyName,
      phoneNumber: formData.businessPhoneNumber,
      streetNumber: formData.streetNumber,
      streetName: formData.streetName,
      aptUnit: formData.aptNumber || null,
      city: formData.city,
      province: formData.province,
      postalCode: formData.postalCode || null,
      country: formData.country,
      membershipId: formData.membershipId,
      companyName: formData.firmName || null,
      governingBody: formData.governingBody,
    };
  }

  touched(): boolean {
    return this.repInfoForm.touched;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    this.subs.push(
      this.accountInfo.subscribe((repInfo) => {
        this.repInfoForm.patchValue({
          givenName: repInfo.givenName,
          familyName: repInfo.familyName,
          businessPhoneNumber: repInfo.phoneNumber,
          streetNumber: repInfo.streetNumber,
          streetName: repInfo.streetName,
          aptNumber: repInfo.aptUnit,
          city: repInfo.city,
          province: repInfo.province,
          postalCode: repInfo.postalCode,
          country: repInfo.country,
          membershipId: repInfo.membershipId,
          firmName: repInfo.companyName,
          governingBody: repInfo.governingBody,
          documentLink: repInfo.documentLink,
        });
      })
    );
  }
}
