import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "pra-form-buttons",
  templateUrl: "./form-buttons.component.html",
  styleUrls: ["./form-buttons.component.scss"],
})
export class FormButtonsComponent implements OnInit {
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
