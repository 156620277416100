import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalService } from "lib";

@Component({
  selector: "pra-invite-confirm",
  templateUrl: "./invite-confirm.component.html",
  styleUrls: ["./invite-confirm.component.scss"],
})
export class InviteConfirmComponent implements OnInit {
  @Input() modalId: string;
  @Input() title: string;
  @Input() description: string;
  @Input() sendText: string;
  @Input() cancelText: string;
  @Output() confirmation = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  public confirmInvite(check: boolean) {
    this.modalService.close(this.modalId);
    this.confirmation.emit(check);
  }
}
