<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_G.FORM_HEADING' | translate"
  [body]="'INTAKE.IMM5669.FORMS.SECTION_G.FORM_SUBTITLE' | translate"
></lib-copy-block>

<form autocomplete="off">
  <ng-container
    *ngFor="
      let instance of militaryPositions?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="militaryPositions"
  >
    <fieldset [formGroup]="instance" class="form-array__fieldset">
      <legend>
        {{ "INTAKE.IMM5669.FORMS.SECTION_G.INSTANCE_LABEL" | translate }}
        {{ i + 1 }}
      </legend>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'country' + i"
        [formControl]="instance.controls.country"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_G.LOCATION_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.country.touched &&
            instance.controls?.country.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.country }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'branchOfService' + i"
        [formControl]="instance.controls.branchOfService"
        [label]="
          'INTAKE.IMM5669.FORMS.SECTION_G.DUTY_DETAILS_LABEL' | translate
        "
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.branchOfService.touched &&
            instance.controls?.branchOfService.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.branchOfService }
          }}
        </span>
      </lib-form-input>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'from' + i"
        [formControl]="instance.controls.from"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'to' + i"
        [formControl]="instance.controls.to"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'rank' + i"
        [formControl]="instance.controls.rank"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_G.RANK_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.rank.touched &&
            instance.controls?.rank.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.rank }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'reasonsEndService' + i"
        [formControl]="instance.controls.reasonsEndService"
        [label]="
          'INTAKE.IMM5669.FORMS.SECTION_G.END_OF_SERVICE_LABEL' | translate
        "
        widthMd="true"
      >
        <span
          *ngIf="
            instance.controls?.reasonsEndService.touched &&
            instance.controls?.reasonsEndService.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.reasonsEndService }
          }}
        </span>
      </lib-form-input>

      <lib-form-textarea
        [editable]="user.can('documents:write')"
        [identifier]="'combatDetails' + i"
        [formControl]="instance.controls.combatDetails"
        [label]="
          'INTAKE.IMM5669.FORMS.SECTION_G.DATE_LOCATION_COMBAT_LABEL'
            | translate
        "
      >
        <span
          *ngIf="
            instance.controls?.combatDetails.touched &&
            instance.controls?.combatDetails.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.combatDetails }
          }}
        </span>
      </lib-form-textarea>

      <!-- ADD/REMOVE BUTTONS -->
      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeMilitaryService(i)"
        >
          {{
            "INTAKE.IMM5669.FORMS.SECTION_G.INSTANCE_REMOVE_BUTTON" | translate
          }}
          <span class="visually-hidden"
            >{{ "INTAKE.IMM5669.FORMS.SECTION_G.SERVICE_LEGEND" | translate }}
            {{ i + 1 }}</span
          >
        </button>
        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addMilitaryService()"
        >
          {{ "INTAKE.IMM5669.FORMS.SECTION_G.INSTANCE_ADD_BUTTON" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
