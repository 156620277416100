import { Component, OnInit, OnDestroy } from "@angular/core";
import { CanComponentDeactivate } from "../../../../routing/guards/nav.guard";
import { Observable } from "rxjs";
import { ModalService } from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Subscription } from "rxjs";

@Component({
  selector: "pra-imm0008-page10",
  templateUrl: "./page10.component.html",
  styleUrls: ["./page10.component.scss"],
})
export class IMM0008Page10Component
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  public lang: string;
  public caseId?: string | number;

  public navigationModalId = "navigationModal";

  public isFormTouched: boolean;
  public isFormSaved: boolean;

  private subs: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private modalService: ModalService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();

    this.subs.push(
      this.store.select("selectedCase").subscribe((caseData) => {
        this.caseId = caseData.id;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // check if form has been touched or saved
    this.isFormTouched = true;
    this.isFormSaved = false;

    if (this.isFormTouched && !this.isFormSaved) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }
}
