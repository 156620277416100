<section class="invite-principal-applicants-rep">
  <lib-layout-row mb="3">
    <header>
      <h1>
        {{
          "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.PAGE_HEADING" | translate
        }}
      </h1>
      <p
        [innerHTML]="
          'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.PAGE_DETAILS' | translate
        "
      ></p>
    </header>
  </lib-layout-row>
  <section>
    <lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
    <form
      focusInvalidInput
      [formGroup]="invitePrincipalApplicantsRep"
      autocomplete="off"
      (submit)="inviteConfirm($event)"
      *ngIf="!isProcessing"
    >
      <fieldset>
        <legend>
          {{
            "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.INTRO_HEADING" | translate
          }}
        </legend>
        <div
          [innerHTML]="
            'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.INTRO_DETAILS' | translate
          "
        ></div>
      </fieldset>
      <pra-member-fields
        [form]="invitePrincipalApplicantsRep"
        [translations]="translations"
      >
        <lib-form-radio
          [options]="[
            {
              id: 'yes',
              label:
                'PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_RADIO.YES'
                | translate,
              value: true
            },
            {
              id: 'no',
              label:
                'PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_RADIO.NO'
                | translate,
              value: false
            }
          ]"
          [legend]="
            'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.PAID_REP' | translate
          "
          nameVal="paidRep"
          [formControl]="invitePrincipalApplicantsRep.controls.paidRep"
          [required]="true"
        >
          <span
            *ngIf="
              invitePrincipalApplicantsRep.controls?.paidRep.touched &&
              invitePrincipalApplicantsRep.controls?.paidRep.hasError(
                'required'
              )
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              invitePrincipalApplicantsRep.controls?.paidRep.touched &&
              invitePrincipalApplicantsRep.controls?.paidRep.hasError(
                'isPaidRep'
              )
            "
          >
            {{ "PSR.MESSAGES.IS_PAID_REP" | translate }}
          </span>
          <span
            *ngIf="
              invitePrincipalApplicantsRep.controls?.paidRep.touched &&
              invitePrincipalApplicantsRep.controls?.paidRep.hasError(
                'isClient'
              )
            "
          >
            {{ "PSR.MESSAGES.IS_CLIENT" | translate }}
          </span>
          <span
            *ngIf="
              invitePrincipalApplicantsRep.controls?.paidRep.touched &&
              invitePrincipalApplicantsRep
                .get('paidRep')
                ?.hasError('missingEmail')
            "
          >
            {{ "PSR.MESSAGES.MISSING_EMAIL" | translate }}
          </span>
        </lib-form-radio>
      </pra-member-fields>
      <pra-form-buttons [form]="invitePrincipalApplicantsRep">
        <button type="submit" category="primary" [disabled]="isFetchingCognito">
          {{
            "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.SUBMIT" | translate
          }}
        </button>
        <button
          [routerLink]="
            routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'], '../')
          "
          type="button"
          category="secondary"
          role="link"
        >
          {{
            "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.CANCEL" | translate
          }}
        </button>
      </pra-form-buttons>
    </form>
  </section>
</section>

<pra-invite-confirm
  [modalId]="modalId"
  [title]="'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.MODAL.TITLE' | translate"
  [description]="
    'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.MODAL.DESCRIPTION' | translate
  "
  [sendText]="'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.MODAL.SEND' | translate"
  [cancelText]="
    'PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.MODAL.CANCEL' | translate
  "
  (confirmation)="inviteSend($event)"
></pra-invite-confirm>
