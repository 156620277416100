import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { filter } from "rxjs/operators";
import { Lob } from "../../../../core/models/lob.model";
import { FormSelectOption } from "lib";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
import { Subscription } from "rxjs";
import { DocumentService } from "projects/pr-applicant/src/app/shared/services/document/document.service";

@Component({
  selector: "pra-supporting-documents-upload",
  templateUrl: "./supporting-documents-upload.component.html",
  styleUrls: ["./supporting-documents-upload.component.scss"],
})
export class SupportingDocumentsUploadComponent
  implements OnInit, OnChanges, OnDestroy
{
  private lob: Lob;
  private case: any;
  //localized lob name

  public isCurrentLobAFamilyClassLob: boolean = false;
  public category?: string;
  public subscriptions: Subscription[] = [];
  public requiredPOP: boolean = false;
  public infoBoxText?: string;
  public categoryId: number;

  @Input() isLobUpdated: boolean;
  @Input() isHiddenLob: boolean = false;
  @Input() showPaymentTable: boolean = false;
  @Input() showMemberDocTable: boolean = false;
  @Input() showAnchorDocTable: boolean = false;
  @Input() caseMemberId: number | undefined;
  @Input() requiredDocumentOptions: FormSelectOption[];
  @Input() otherDocumentOptions: FormSelectOption[];
  @Input() memberDocumentOptions: FormSelectOption[];

  @Output() documentsUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private lobService: LobService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.getStoredCase();
    if (this.case.lob.programId === 2) {
      this.isCurrentLobAFamilyClassLob = true;
    }
    this.subscriptions.push(
      this.documentService.requiredPOP.subscribe((required: boolean) => {
        this.requiredPOP = required;
      })
    );
    this.getCategory();
  }

  ngOnChanges(): void {
    if (this.isLobUpdated) {
      this.getStoredCase();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /* PUBLIC ------------------------------------------------------------------------- */

  public get description(): string {
    return this.translate.instant("INTAKE.SUPPORTING_DOCUMENTS.DESC", {
      documentChecklistUrl: this.documentChecklistUrl,
    });
  }

  public get uploadConfig() {
    return {
      header: this.translate.instant(
        "INTAKE.SUPPORTING_DOCUMENT_REQUIREMENTS_LIST.DESCRIPTION"
      ),
      icon: "infoRegular",
      type: "info",
    };
  }

  public get emppConfig() {
    return {
      header: this.translate.instant(
        "INTAKE.PROOF_OF_PAYMENT.EMPP_NOTIFICATION_HEADER"
      ),
      icon: "infoRegular",
      type: "info",
    };
  }

  /* PRIVATE ------------------------------------------------------------------------- */

  private get documentChecklistUrl(): string {
    return this.lang === "fr"
      ? this.lob?.documentChecklistFr
      : this.lob?.documentChecklistEn;
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  public async getCategory(): Promise<void> {
    if (
      this.case.lob.categoryId === 1000 ||
      this.case.lob.categoryId === 1001
    ) {
      const emppCategoryLOB = await this.lobService.fetchLob(
        this.case.lob.categoryId
      );

      this.lang === "fr"
        ? (this.category = `${emppCategoryLOB?.categoryFr}/ ${this.case.lob.categoryFr}`)
        : (this.category = `${emppCategoryLOB?.categoryEn}/ ${this.case.lob.categoryEn}`);
    } else {
      this.lang === "fr"
        ? (this.category = this.case.lob.categoryFr)
        : (this.category = this.case.lob.categoryEn);
    }
  }

  private getStoredCase(): void {
    this.store
      .select("selectedCase")
      .pipe(filter((caseData: any) => caseData?.id !== ""))
      .subscribe((caseData: any) => {
        if (caseData) {
          this.case = caseData;
          this.lob = caseData.lob;
          this.categoryId = caseData.lob.categoryId;
        }
      });
  }

  public handleUpdate() {
    this.documentsUpdated.emit();
  }
}
