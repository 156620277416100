import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import routePaths from "@pr-applicant/app/routing/route-paths";

@Injectable({
  providedIn: "root",
})
export class ClientUserGuard implements CanActivate {
  constructor(
    private routeLocalizer: RouteLocalizerService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(a: ActivatedRouteSnapshot, r: RouterStateSnapshot): boolean {
    const isClient = this.userService.isClientAccount();
    if (!isClient) {
      const lang =
        this.routeLocalizer.getCurrentRouteLang() === "fr" ? "fr" : "en";
      this.router.navigateByUrl(
        `/${lang}/${routePaths[lang].INTAKE}/${routePaths[lang].INTAKE_DASHBOARD}`
      );
      return false;
    }
    return true;
  }
}
