import {
  AfterContentChecked,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, ValidationService, UserGroup } from "lib";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import routePaths from "../../../../routing/route-paths";
import * as fromApp from "../../../../store/app.reducer";
import { AuthService } from "../../services/auth.service";
import * as AuthActions from "../../store/auth.actions";
import { RepService } from "@pr-applicant/app/shared/services/rep/rep.service";
import { first } from "rxjs/operators";
import { environment } from "@pr-applicant/environments/environment";

@Component({
  selector: "pra-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterContentChecked, OnDestroy {
  public isProduction = environment.env === "prod";
  public env = environment.env;
  public currentLang = "en";
  public loginForm: FormGroup;
  public forgotPasswordRouteName: string;
  public autofill = false;
  public loading = false;

  public quickCreds: any[];
  public toggleLabel = "HIDE";

  @Output() loadingVal: EventEmitter<any> = new EventEmitter();

  // Alerts
  public isWrongCredentials = false;
  public isTechnicalError = false;
  private storeAuthSubsc: Subscription;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private alertService: AlertService,
    private translate: TranslateService,
    private validationService: ValidationService,
    private repService: RepService,
    private store: Store<fromApp.State>,
    private router: Router
  ) {}

  ngOnInit() {
    // update these with a simple crud lambda so users can easily add and remove quick creds in each environment right in the selector
    if (this.env === "local") {
      this.quickCreds = [
        {
          name: "Client",
          type: "client",
          username: "danielle.rose.cole+test10@gmail.com",
          password: "Password!1",
        },
        {
          name: "Rep",
          type: "rep",
          username: "danielle.rose.cole+rep4@gmail.com",
          password: "Password!1",
        },
        {
          name: "Client BM",
          type: "client",
          username: "bret.mcgee15@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Rep BM",
          type: "rep",
          username: "theios.inc@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Jordan",
          type: "client",
          username: "jordsousa@deloitte.ca",
          password: "Hockey1$",
        },
      ];
    }
    if (this.env === "dev") {
      this.quickCreds = [
        {
          name: "Client",
          type: "client",
          username: "danielle.rose.cole+test10@gmail.com",
          password: "Password!1",
        },
        {
          name: "Rep",
          type: "rep",
          username: "danielle.rose.cole+rep4@gmail.com",
          password: "Password!1",
        },
        {
          name: "Client BM",
          type: "client",
          username: "bret.mcgee15@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Rep BM",
          type: "rep",
          username: "theios.inc@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Jordan",
          type: "client",
          username: "jordsousa@deloitte.ca",
          password: "Hockey1$",
        },
      ];
    }
    if (this.env === "staging") {
      this.quickCreds = [
        {
          name: "Client",
          type: "client",
          username: "danielle.rose.cole+test10@gmail.com",
          password: "Password!1",
        },
        {
          name: "Rep",
          type: "rep",
          username: "danielle.rose.cole+rep4@gmail.com",
          password: "Password!1",
        },
        {
          name: "Client BM",
          type: "client",
          username: "bret.mcgee15@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Rep BM",
          type: "rep",
          username: "theios.inc@gmail.com",
          password: "Sugarmaple4096!",
        },
        {
          name: "Jordan",
          type: "client",
          username: "jordsousa@deloitte.ca",
          password: "Hockey1$",
        },
      ];
    }
    console.log("ENVIRONMENT: ", this.env);

    // get language
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    const repUrlPrefix = !this.routeLocalizer.isUrlRepPrefixed ? "" : "rep/";
    // if password reset was successful
    this.forgotPasswordRouteName = `/${this.currentLang}/${repUrlPrefix}${
      this.currentLang === "fr"
        ? routePaths.fr.FORGOT_PASSWORD
        : routePaths.en.FORGOT_PASSWORD
    }`;

    // checks if already logged in to route them to home page if they hit the login page
    this.storeAuthSubsc = this.store
      .select("auth")
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        const authenticated = !!user;
        if (authenticated && user && !user.isLoggingOut) {
          if (!this.routeLocalizer.isUrlRepPrefixed) {
            this.router.navigateByUrl(`/${this.currentLang}`);
          } else {
            const lang = this.currentLang === "fr" ? "fr" : "en";
            this.router.navigateByUrl(
              `/${this.currentLang}/${routePaths[lang].INTAKE}/${routePaths[lang].INTAKE_DASHBOARD}`
            );
          }
          return;
        } else if (user && user.isLoggingOut) {
          //  you get here if you press sign-out while editiong various forms
          // and you choosed to leave the page
          this.finishLogOut();
        }
      });
    this.loginForm = new FormGroup({
      username: new FormControl(null, [
        Validators.required,
        this.validationService.validatorEmail,
      ]),
      password: new FormControl(null, Validators.required),
    });
  }

  useQuickCreds(user: {
    name: string;
    type: string;
    username: string;
    password: string;
  }) {
    this.loginForm.controls.username.setValue(user.username);
    this.loginForm.controls.password.setValue(user.password);
    this.onSubmit();
  }

  toggleCreds() {
    this.toggleLabel = this.toggleLabel === "HIDE" ? "SHOW" : "HIDE";
  }

  finishLogOut() {
    this.authService.logout().then(() => {
      this.store.dispatch(
        new AuthActions.Logout({
          successLogout: true,
          user: null,
        })
      );

      this.launchLogOutSucceedAlert();
    });
  }

  async launchLogOutSucceedAlert() {
    await this.translate.get("LOGIN.ALERTS.SUCCESSFUL_LOGOUT").toPromise();
    this.alertService.success(this.alertLogoutSuccess);
  }

  ngAfterContentChecked() {
    /* CHROME AUTO FILL FIX */
    this.autofill = this.authService.checkAutofillOnLogin();
  }

  ngOnDestroy() {
    if (this.storeAuthSubsc) {
      this.storeAuthSubsc.unsubscribe();
    }
  }
  public onSubmit() {
    if (this.loginForm.status === "INVALID") {
      return;
    }

    this.loading = true;
    this.loadingVal.emit(this.loading);

    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    this.authService
      .login(username, password)
      .then(async (response: any) => {
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          if (
            response.challengeParam.userAttributes["custom:group"] ===
              UserGroup.Client ||
            response.challengeParam.userAttributes["custom:group"] ===
              UserGroup.Rep
          ) {
            this.store.dispatch(
              new AuthActions.Login({
                username,
                session: response.Session,
                isFirstLogin: true,
                token: "",
                userPool:
                  response.challengeParam.userAttributes["custom:group"],
                repId: "",
                membershipId: "",
              })
            );
          } else {
            this.alertService.danger(this.alertWrongCredentials);
            return;
          }
        } else {
          const group =
            response.signInUserSession.accessToken.payload["cognito:groups"][0];
          const dispatchLogin = (repId: string, membershipId?: any) => {
            this.store.dispatch(
              new AuthActions.Login({
                username,
                session: response.Session,
                isFirstLogin: false,
                token: response.signInUserSession.accessToken.jwtToken,
                userPool: group,
                repId,
                membershipId: membershipId,
              })
            );
          };
          if ([UserGroup.Client, UserGroup.Rep].includes(group)) {
            // updates identity ids for cases if needed (backend will handle this for multiple cases)
            this.authService.updateIdentityId();
            if (group === UserGroup.Client) {
              dispatchLogin("", "");
            } else if (group === UserGroup.Rep) {
              this.repService
                .getAccountDetails()
                .pipe(first())
                .subscribe(
                  (repInfo) => {
                    dispatchLogin(repInfo.id || "", repInfo.membershipId || "");
                  },
                  (err) => {
                    dispatchLogin("", "");
                  }
                );
            }
          }
        }
      })
      .catch((error: any) => {
        this.loading = false;

        switch (error.code) {
          case "NotAuthorizedException":
            if (
              error.message?.startsWith(
                "Temporary password has expired and must be reset by an administrator"
              )
            ) {
              this.alertService.danger(
                this.translate.instant("LOGIN.ALERTS.PASSWORD_EXPIRED_ERROR")
              );
            } else {
              this.alertService.danger(this.alertWrongCredentials);
            }
            break;
          case "UserNotFoundException":
          case "PasswordResetRequiredException":
            this.alertService.danger(this.alertWrongCredentials);
            break;

          case "UserNotConfirmedException":
            const url = `/${this.currentLang}/${
              this.currentLang === "fr"
                ? routePaths.fr.SIGNUP_VERIFICATION
                : routePaths.en.SIGNUP_VERIFICATION
            }`;
            this.authService.tempNewUserEmail = username;
            this.router.navigate([url]);
            break;

          case "UserLambdaValidationException":
            const message = error.message?.toString() || "";
            if (
              message.includes("ClientUserUsedWrongLoginPage") ||
              message.includes("RepUserUsedWrongLoginPage")
            ) {
              this.alertService.danger(this.alertWrongCredentials);
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
            break;
          default:
            this.alertService.danger(this.alertTechnicalError);
            break;
        }
      });
  }

  private get alertWrongCredentials(): string {
    return this.translate.instant("LOGIN.ALERTS.FORGOT_PASSWORD");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }
  private get alertLogoutSuccess(): string {
    return this.translate.instant("LOGIN.ALERTS.SUCCESSFUL_LOGOUT");
  }
}
