import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
//import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { filter } from "rxjs/operators";
import { FormSelectOption } from "lib";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
import { Subscription } from "rxjs";
import { DocumentService } from "projects/pr-applicant/src/app/shared/services/document/document.service";
import {
  Lob,
  RelatedDocumentType,
} from "@pr-applicant/app/core/models/lob.model";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
@Component({
  selector: "pra-referrel-partner-doc-table",
  templateUrl: "./referrel-partner-doc-table.component.html",
  styleUrls: ["./referrel-partner-doc-table.component.scss"],
})
export class ReferrelPartnerDocTableComponent implements OnInit, OnDestroy {
  private lob: Lob;
  private case: any;
  public memberDocs: RelatedDocumentType[] = [];
  public subscriptions: Subscription[] = [];

  @Input() isHiddenLob: boolean = false;
  @Input() caseMemberId?: number | undefined;
  @Input() requiredDocumentOptions: FormSelectOption[];
  @Output() documentsUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit(): void {
    this.getStoredCase();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  private getStoredCase(): void {
    this.store
      .select("selectedCase")
      .pipe(filter((caseData: any) => caseData?.id !== ""))
      .subscribe((caseData: any) => {
        if (caseData) {
          this.case = caseData;
          this.lob = caseData.lob;
        }
      });
  }

  public handleUpdate() {
    this.documentsUpdated.emit();
  }
}
