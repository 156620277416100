<form [formGroup]="educationOccupationForm" autocomplete="off">
  <fieldset>
    <legend
      [innerHTML]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.LEGEND'
          | translate
      "
    ></legend>
    <!-- Level of Education -->
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="educationLevel"
      data-cy="education-details-education-level"
      [formControl]="educationOccupationForm.controls.educationLevel"
      [label]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.HIGHEST_LEVEL_LABEL'
          | translate
      "
      [options]="educationList"
      [widthMd]="true"
      required="true"
    >
      <span
        *ngIf="
          educationOccupationForm.get('educationLevel')?.touched &&
          educationOccupationForm.get('educationLevel')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>

    <!-- # of Years of Education  -->
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="numberOfYear"
      [formControl]="educationOccupationForm.controls.numberOfYear"
      [label]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.EDUCATION_INFORMATION.NUMBERS_LABEL'
          | translate
      "
      [widthMd]="true"
      required="true"
    >
      <span *ngIf="numberOfYearError">
        {{
          "INTAKE.FORM_ERRORS.NUMBERS_MIN_MAX" | translate : numberOfYearMinMax
        }}
      </span>
      <span
        *ngIf="
          educationOccupationForm.get('numberOfYear')?.touched &&
          educationOccupationForm.get('numberOfYear')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <fieldset>
    <legend
      [innerHTML]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.LEGEND'
          | translate
      "
    ></legend>
    <!-- Current Occupation -->
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="currentOccupation"
      [formControl]="educationOccupationForm.controls.currentOccupation"
      [label]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.CURRENT_OCCUPATION_LABEL'
          | translate
      "
      [widthMd]="true"
      required="true"
    >
      <span
        *ngIf="
          educationOccupationForm.get('currentOccupation')?.touched &&
          educationOccupationForm.get('currentOccupation')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          educationOccupationForm.get('currentOccupation')?.touched &&
          educationOccupationForm
            .get('currentOccupation')
            ?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : occupationTextMinMax }}
      </span>
      <span
        *ngIf="
          educationOccupationForm.get('currentOccupation')?.touched &&
          educationOccupationForm
            .get('currentOccupation')
            ?.hasError('invalidChars')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>

    <!-- Intended  Occupation -->
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="intendedOccupation"
      [formControl]="educationOccupationForm.controls.intendedOccupation"
      [label]="
        'INTAKE.IMM0008.FORMS.EDUCATION_OCCUPATION_DETAILS.OCCUPATION_INFORMATION.INTENDED_OCCUPATION_LABEL'
          | translate
      "
      [widthMd]="true"
      required="true"
    >
      <span
        *ngIf="
          educationOccupationForm.get('intendedOccupation')?.touched &&
          educationOccupationForm
            .get('intendedOccupation')
            ?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          educationOccupationForm.get('intendedOccupation')?.touched &&
          educationOccupationForm
            .get('intendedOccupation')
            ?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : occupationTextMinMax }}
      </span>
      <span
        *ngIf="
          educationOccupationForm.get('intendedOccupation')?.touched &&
          educationOccupationForm
            .get('intendedOccupation')
            ?.hasError('invalidChars')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
  </fieldset>
</form>
