import { Action, createReducer, on } from "@ngrx/store";
import * as RenewalsApplicationsActions from "../actions/prcPrtdApplicationsActions";
import { prcPrtdApplicationsState } from "../prcPrtdState";

export const INITIAL_PRC_PRTD_APPLICATIONS_STATE: prcPrtdApplicationsState = {
  list: [],
  isLoaded: false,
  isLoading: false,
  hasError: false,
};

const reducePrcPrtdApplications = createReducer(
  INITIAL_PRC_PRTD_APPLICATIONS_STATE,

  on(
    RenewalsApplicationsActions.StartLoadRenewalsApplicationsList,
    (state, action) => {
      return {
        ...state,
        list: [],
        isLoaded: false,
        isLoading: true,
        hasError: false,
        error: "",
      };
    }
  ),

  on(
    RenewalsApplicationsActions.LoadRenewalsApplicationsList,
    (state, action) => {
      return {
        ...state,
        list: action.list,
        isLoaded: true,
        isLoading: false,
        hasError: false,
        error: "",
      };
    }
  ),

  on(
    RenewalsApplicationsActions.ErrorLoadRenewalsApplicationsList,
    (state, action) => {
      return {
        ...state,
        list: [],
        isLoaded: true,
        isLoading: false,
        hasError: true,
        error: action.error,
      };
    }
  )
);

export function prcPrtdApplicationsReducer(
  state: prcPrtdApplicationsState | undefined,
  action: Action
) {
  return reducePrcPrtdApplications(state, action);
}
