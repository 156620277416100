import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  AfterViewChecked,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertService,
  ModalService,
  ValidationService,
  caseStatuses,
  documentTypes,
  LobIds,
} from "lib";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { Case } from "../../../shared/case-module/case.model";
import {
  Document,
  RawAppForm,
  RequiredList,
} from "../../../core/models/document.model";
import { CaseService } from "../../../shared/case-module/case.service";
import * as fromApp from "../../../store/app.reducer";
import {
  distinctUntilChanged,
  switchMap,
  retry,
  finalize,
  filter,
  take,
} from "rxjs/operators";
import { from, of, Subscription } from "rxjs";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as CaseActions from "../../../shared/case-module/store/case.actions";
import { DocumentService } from "@pr-applicant/app/shared/services/document/document.service";
import { NGXLogger } from "ngx-logger";
import { zonedTimeToUtc } from "date-fns-tz";
import { FormSelectOption, IOptionMap } from "lib";
import {
  IPsrDetails,
  PsrService,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { IBannerConfig } from "ircc-ds-angular-component-library";
import { environment } from "@pr-applicant/environments/environment";
import { GarService } from "@pr-applicant/app/shared/services/gar/gar.service";

const PAYMENT_ID = 4;
const REFERRALDOC_ID = 401;

@Component({
  selector: "pra-intake-landing-page",
  templateUrl: "./intake-landing-page.component.html",
  styleUrls: ["./intake-landing-page.component.scss"],
})
export class IntakeLandingPageComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public canSubmitApplication = false;
  public case: Case;
  public caseMemberId?: number | null;
  private caseId: string | null;
  private caseStatusId: number;
  private subs: Subscription[] = [];
  public canViewExistingCase: boolean;
  public lang: string;
  public statusBoxType: string;
  public isRepSignatureComplete: boolean;
  public isHiddenLob: boolean = false;
  private updateDeclaratioSubs: Subscription;
  public statusKey: string;
  public isLobUpdated: boolean;
  public isNavigatingHome = false;
  public submitApplicationId = "submitApplicationId";
  public atCapacityId = "atCapacityId";
  form: FormGroup;
  private imm0008DocumentTypeId = documentTypes.imm0008.id;
  public isProcessing = false;
  public isProcessingRepDeclaration = false;
  public showCapModal: boolean = false;
  public showPayment: boolean = false;
  public showMemberTable: boolean = false;
  public showAnchorTable: boolean = false;
  private isPsr: boolean = false;
  private isGar: boolean = false;
  private lobId: any;
  public currentCasePsr: IPsrDetails | undefined;
  public hideIntake: boolean = false;

  //table data to passed down to child components

  // mapping queried from lob_document_type_relationships table
  // used sort required items (forms, docs) from optional items
  // used to create validation array
  public requiredDocs: any = [];
  public requiredForms: any = [];
  public requiredMemberDocs: any = [];

  public requiredDocumentOptions: FormSelectOption[] = [];
  public otherDocumentOptions: FormSelectOption[] = [];
  public memberDocumentOptions: FormSelectOption[] = [];

  public requiredRawAppForms: RawAppForm[] = [];
  public otherRawAppForms: RawAppForm[] = [];

  // required list keys define which forms are put into required forms section
  // required boolean defines if the red required text is rendered next to form
  public requiredList: { [key: string]: { required: boolean } } = {};

  public validationArray: number[];
  private defaultReadiness: { [key: string]: any } = {
    consentAndDeclaration: null,
    incompleteDocumentsResolved: null,
    validationArrayPassed: null,
    validationArrayIssues: [],
    lobIsAvailable: null,
    repDeclarationSigned: null,
    inASubmittableState: null,
    caseDataLoaded: null,
    hasPermission: null,
    g5CaseHas5Members: null,
    psrCaseMemberDocsComplete: null,
    hasDesignatedEntity: null,
  };
  public readiness = this.defaultReadiness;
  public JSON = JSON;
  public isProduction = environment.env === "prod";
  public Object = Object;

  constructor(
    private authService: AuthService,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private caseService: CaseService,
    private store: Store<fromApp.State>,
    private modalService: ModalService,
    private router: Router,
    private validationService: ValidationService,
    public user: UserService,
    private documentService: DocumentService,
    private cdRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private lobService: LobService,
    private psrService: PsrService,
    private garService: GarService
  ) {
    this.caseId = this.activatedRoute.snapshot.paramMap.get("caseId");
  }

  ngOnInit(): void {
    if (!this.caseId) {
      this.store.dispatch(new CaseActions.ResetCaseInfo());
    }
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle("INTAKE.LANDING_PAGE.PAGE_TITLE");
    if (this.isRep && !this.form) {
      this.form = new FormGroup({
        repDeclaration: new FormControl(false, [Validators.requiredTrue]),
      });
    }

    this.subs.push(
      this.user.currentCaseObs$.subscribe((data) => {
        this.currentCasePsr = data.psrDetails;
      })
    );

    this.checkCaseExists();
    this.setStatusBox();
    this.getRequiredDocumentsAndForms();
    this.getFormData();
  }
  ngAfterViewChecked(): void {
    if (this.case?.lob?.lobHiddenReasonsId === 1 && !this.showCapModal) {
      this.setStatusBox();
      this.modalService.open(this.atCapacityId);
      this.openModal(this.atCapacityId);
      this.showCapModal = true;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public get warningConfig() {
    return {
      header: this.translate.instant(
        "INTAKE.APPLICATION_SUBMIT.ENABLE_CONFIRM_REQUIREMENTS"
      ),
      icon: "exclamation",
      type: "critical",
    };
  }

  public digitalOrWetSignRequired(doc: RelatedDocumentType) {
    if (doc.id === documentTypes.wetSignature.id)
      return !this.case.paWillSignWebform;
    if (
      doc.id === documentTypes.declarationSignature.id ||
      doc.id === documentTypes.declarationSignatureWith5669.id
    )
      return this.case.paWillSignWebform;
    return true;
  }

  public getRequiredDocumentsAndForms(): void {
    this.store
      .select("selectedCase")
      .pipe(distinctUntilChanged())
      .subscribe((caseData) => {
        if (caseData && caseData.id !== "") {
          const requiredForms: RelatedDocumentType[] = [];
          const requiredDocs: RelatedDocumentType[] = [];
          caseData.lob.relatedDocumentTypes
            ?.filter(
              (doc: RelatedDocumentType) =>
                !doc.isMemberDocument && this.digitalOrWetSignRequired(doc)
            )
            .forEach((doc: RelatedDocumentType) => {
              //filter not required forms
              if (doc.isRequired === true) {
                if (doc.isSupplemental) {
                  requiredDocs.push(doc);
                } else {
                  requiredForms.push(doc);
                }
              }
            });

          this.requiredForms = requiredForms;
          this.requiredDocs = requiredDocs.map((form: any) => {
            return {
              value: form.id,
              text:
                this.lang === "en" ? form.documentTypeEn : form.documentTypeFr,
              isRequired: form.isRequired,
            };
          });
          if (this.isGar) {
            this.memberDocumentOptions = caseData.lob?.relatedDocumentTypes
              ?.filter(
                (doc: RelatedDocumentType) =>
                  doc.isMemberDocument && doc.isRequired
              )
              .map((doc: RelatedDocumentType) => {
                return {
                  value: doc.id,
                  isRequired: doc.isRequired,
                  documentTypeId: doc.id,
                  text:
                    this.lang === "en"
                      ? doc.documentTypeEn
                      : doc.documentTypeFr,
                };
              });
            if (this.user.can("group-members:write")) {
              this.showMemberTable = true;
            }
          }
          if (this.lobId === LobIds.Sudan) {
            this.requiredMemberDocs = caseData.lob?.relatedDocumentTypes
              ?.filter(
                (doc: RelatedDocumentType) =>
                  doc.isMemberDocument && doc.isRequired
              )
              .map((doc: RelatedDocumentType) => {
                return {
                  value: doc.id,
                  isRequired: doc.isRequired,
                  documentTypeId: doc.id,
                  text:
                    this.lang === "en"
                      ? doc.documentTypeEn
                      : doc.documentTypeFr,
                };
              });
            this.memberDocumentOptions = caseData.lob?.relatedDocumentTypes
              ?.filter((doc: RelatedDocumentType) => doc.isMemberDocument)
              .map((doc: RelatedDocumentType) => {
                if (!doc.isSupplemental) {
                  return {
                    value: doc.id,
                    isRequired: doc.isRequired,
                    documentTypeId: doc.id,
                    formId: doc.form.id,
                    link:
                      this.lang === "en" ? doc.form.linkEn : doc.form.linkFr,
                    text:
                      this.lang === "en"
                        ? doc.documentTypeEn
                        : doc.documentTypeFr,
                  };
                } else {
                  return {
                    value: doc.id,
                    isRequired: doc.isRequired,
                    documentTypeId: doc.id,
                    text:
                      this.lang === "en"
                        ? doc.documentTypeEn
                        : doc.documentTypeFr,
                  };
                }
              });

            this.showAnchorTable = true;
          }

          const allDocumentOptions = caseData.lob.relatedDocumentTypes
            ?.filter(
              (doc: RelatedDocumentType) =>
                !doc.isMemberDocument && doc.isSupplemental
            )
            .map((doc: RelatedDocumentType) => {
              return {
                value: doc.id,
                text: {
                  en: doc.documentTypeEn,
                  fr: doc.documentTypeFr,
                },
              };
            });
          this.createDocumentsTableData(allDocumentOptions, this.requiredDocs);
          if (this.user.is("clientWithRep") || this.user.is("representative")) {
            this.requiredForms = [
              ...this.requiredForms,
              this.documentService.IMM5476,
            ];
          }
        }
      });
    this.createValidationArray();
    this.checkRequiredPOP();
  }

  private createValidationArray() {
    let _validationArray: number[] = [];
    // push required docs/forms id's to validation array
    this.requiredDocs.forEach((doc: any) => _validationArray.push(doc.value));
    this.requiredForms.forEach((form: any) => _validationArray.push(form.id));
    //checks if client without rep

    if (this.user.is("client")) {
      _validationArray = _validationArray.filter((doc: any) => doc !== 31);
    }
    //Check if the case initiator is a referral partner
    if (this.user.is("referralPartner")) {
      this.memberDocumentOptions.forEach((doc: any) =>
        _validationArray.push(doc.value)
      );
    }

    if (this.lobId == LobIds.Sudan) {
      this.requiredMemberDocs.forEach((doc: any) =>
        _validationArray.push(doc.value)
      );
    }

    this.validationArray = _validationArray;
  }

  private checkRequiredPOP() {
    this.documentService.setRequiredPOP(
      this.validationArray.some(
        (docId: number) => docId === documentTypes.proofOfPayment.id
      )
    );
  }

  private getAllDocumentSelectOptions(): void {
    this.formatDocuments(1).then(
      (allDocumentOptions: FormSelectOption[]): void => {
        this.createDocumentsTableData(allDocumentOptions, this.requiredDocs);
      }
    );
  }
  private async formatDocuments(
    phaseId: number
  ): Promise<FormSelectOption[] | []> {
    const fetched = await this.documentService.fetchSupplementalDocumentTypes(
      phaseId
    );
    if (fetched.length > 0) {
      return fetched.map((docType) => {
        return {
          value: docType.id,
          text: { en: docType.documentTypeEn, fr: docType.documentTypeFr },
        };
      });
    } else {
      return [];
    }
  }
  private createDocumentsTableData(
    allSelectOptions: FormSelectOption[],
    requiredOptions: FormSelectOption[]
  ): void {
    //creates hashmap of required
    let optionMap: IOptionMap = {};
    let otherSelectOptionArray: FormSelectOption[] = [];
    let _requiredOptions = [...requiredOptions];
    _requiredOptions.forEach((option) => (optionMap[option.value] = option));
    if (allSelectOptions) {
      allSelectOptions.forEach((option) => {
        if (!optionMap[option.value]) {
          otherSelectOptionArray.push(option);
        }
      });
    }
    this.requiredDocumentOptions = _requiredOptions;
    this.otherDocumentOptions = otherSelectOptionArray;
  }

  private getFormData(): void {
    this.store
      .select("selectedCase")
      .pipe(
        filter((caseData: Case) => caseData.lob?.relatedDocumentTypes),
        take(1)
      )
      .subscribe((caseData: Case) => {
        const { relatedDocumentTypes } = caseData.lob;
        let allForms = relatedDocumentTypes.filter(
          (doc: RelatedDocumentType) => !!doc.form && !doc.isMemberDocument
        );

        if (this.isPsr || this.isGar) {
          const AdditionalFamilyInformationID = 28;
          allForms = allForms.filter(
            (doc: RelatedDocumentType) =>
              doc.id !== AdditionalFamilyInformationID
          );
        }

        const IMM5476 = this.documentService.IMM5476;
        //create hash map for lookups
        let requiredList: RequiredList = {};
        this.requiredForms.forEach((form: any) => {
          if (form.isRequired) {
            requiredList[form.id] = { required: form.isRequired };
          }
        });

        //manual form inserts
        if (this.user.is("clientWithRep") || this.user.is("representative")) {
          requiredList[IMM5476.id] = { required: IMM5476.isRequired };
        }
        //use lookup table to filter required forms and other forms
        const otherRawAppForms: RawAppForm[] = [];
        const requiredRawAppForms: RawAppForm[] = [];
        allForms.forEach((form: RawAppForm) => {
          if (form.documentLocation === "mongo" && requiredList[form.id]) {
            requiredRawAppForms.push({ ...form, optional: false });
          } else if (
            form.documentLocation === "mongo" &&
            !requiredList[form.id]
          ) {
            requiredRawAppForms.push({ ...form, optional: true });
          }
          // pdf forms
          else if (form.documentLocation === "s3" && requiredList[form.id]) {
            requiredRawAppForms.push({ ...form });
          } else {
            otherRawAppForms.push({ ...form });
          }
        });

        this.otherRawAppForms = otherRawAppForms;
        this.requiredRawAppForms = requiredRawAppForms;
        this.requiredList = requiredList;
      });
  }

  public get statusBoxHeading(): string {
    return `INTAKE.STATUS_BOX.${this.statusKey}.HEADING`;
  }

  public get statusBoxBody(): string {
    return `INTAKE.STATUS_BOX.${this.statusKey}.BODY`;
  }

  public get isRep(): boolean {
    return this.user.isRepAccount();
  }

  public get repCheckBoxValue(): boolean {
    this.isRepSignatureComplete = this.form.value;
    return this.isRepSignatureComplete;
  }

  public checkCaseLobUpdated(value: boolean) {
    if (this.isRep) {
      this.form.controls.repDeclaration.setValue(false);
    }
    this.isLobUpdated = value;
    this.setStatusBox();
    this.checkApplicationComplete();
  }

  private checkCaseExists(): void {
    // Get value from store if case Exists
    this.subs.push(
      this.store
        .select("selectedCase")
        .pipe(
          distinctUntilChanged(),
          filter((caseData: Case) => !!caseData.id)
        )
        .subscribe((caseData: Case) => {
          if (caseData) {
            this.case = caseData;
            if (this.isRep) {
              this.form.controls.repDeclaration.setValue(
                !!this.case.representativeConfirmationTimestamp
              );
              this.form.controls.repDeclaration.updateValueAndValidity();
            }
            /** If there is a case and case ID, this means it exists */
            if (
              this.case &&
              this.caseId &&
              this.caseId.toString() === this.case.id.toString()
            ) {
              /** If case is submitted or withdrawn, navigate to the applications page */
              if (
                this.caseService.isIntakeCaseSubmittedOrWithdrawn(
                  this.case?.caseStatusId
                ) ||
                this.caseService.isIntakeCaseExpired(this.case?.caseStatusId)
              ) {
                this.routeLocalizer.goTo(this.lang, "INTAKE_VIEW_APPLICATIONS");
              } else {
                this.canViewExistingCase = true;

                const proofOfPayment =
                  this.case?.lob?.relatedDocumentTypes.find(
                    (doc: any) => doc.id === PAYMENT_ID
                  )?.isRequired;

                this.showPayment =
                  proofOfPayment === null || proofOfPayment == undefined
                    ? false
                    : true;
              }
              if (this.isGar) {
                if (caseData.members.length > 0) {
                  this.caseMemberId = caseData.members[0].caseMemberId;
                }
              }
            }

            /** If the route is passed with a caseId, but the case doesn't
             * exist, navigate to home page with technical error message
             */
            // reset case info action  is used WRONG put this isNavigatingHome to correct the situation
            // until store for selected case is made robust
            else if (
              this.caseId &&
              (this.case === null || !this.case.id) &&
              !this.isNavigatingHome
            ) {
              // this.alertService.danger(this.caseNotExist);
              this.logger.error("Invalid case ID");
              this.isNavigatingHome = true;
              this.routeLocalizer.goTo(this.lang, "");
            } else if (!this.case && this.caseId === null) {
              /** If there is no case ID and no case, this means it's
               * new case route
               */
              this.canViewExistingCase = false;
            }
            this.lobId = this.case.lob?.id;

            this.isPsr = this.psrService.isPSR(this.lobId);
            this.isGar = this.garService.isGAR(this.lobId);
          }
        })
    );
  }

  public handleRepDecalarationChange() {
    this.isProcessingRepDeclaration = true;
    if (this.updateDeclaratioSubs) {
      this.updateDeclaratioSubs.unsubscribe();
    }
    // wait a tick for  Angular control to get the value
    setTimeout(() => {
      let updateToValue = this.form.controls.repDeclaration.value;
      this.updateDeclaratioSubs = of("signal")
        .pipe(
          switchMap((signal) =>
            from(
              this.caseService.submitRepDeclaration(this.case.id, updateToValue)
            )
          ),
          finalize(() => (this.isProcessingRepDeclaration = false)),
          retry(2)
        )
        .subscribe(
          (data) => {
            this.updateCaseInfo(this.case.id);
          },
          (err) => {
            this.alertService.danger(this.alertTechnicalError);
            //on error, if you intend but you fail, then you reset it
            this.form.controls.repDeclaration.setValue(!updateToValue);
          }
        );
    }, 1);
  }

  private get caseNotExist(): string {
    return this.translate.instant(
      "INTAKE.APPLICATIONS_PAGE.NO_APPLICATION_ERROR"
    );
  }

  private setStatusBox(): void {
    this.caseStatusId = this.case?.caseStatusId
      ? this.case?.caseStatusId
      : caseStatuses.intake.initiated.id;
    //if LOB is no longer active & status is initiated only show copy then
    if (this.case?.lob?.lobHiddenReasonsId === 1) {
      this.statusKey = "AT_CAPACITY";
      this.statusBoxType = "capacity";
    } else if (this.case?.lob?.isHidden) {
      this.statusKey = "INVALID_LOB";
      this.statusBoxType = "problem";
    } else {
      const statusBox = this.caseService.getIntakeCaseStatusBox(
        this.caseStatusId
      );
      this.statusKey = statusBox.statusKey;
      this.statusBoxType = statusBox.statusBoxType;
    }
  }

  public checkApplicationComplete(): boolean {
    // Check for client consent and declaration form or wet signature
    this.readiness.consentAndDeclaration =
      this.case.documents?.some(
        (document: Document) =>
          document.documentTypeId === documentTypes.declarationSignature.id ||
          document.documentTypeId ===
            documentTypes.declarationSignatureWith5669.id ||
          document.documentTypeId === documentTypes.wetSignature.id
      ) || false;
    //  check approvals for all existing docs in the case (required and optional)
    this.readiness.incompleteDocumentsResolved = true;
    this.case.documents?.forEach((document: Document) => {
      //  documentApproved === false can't be submitted (changes required). once changes are made, documentApproved is set to null
      if (document.documentApproved === false) {
        this.readiness.incompleteDocumentsResolved = false;
      }
    });
    this.readiness.validationArrayPassed = true;
    this.readiness.validationArrayIssues = [];
    this.validationArray.forEach((docId: number) => {
      const thisDoc = this.case?.caseDocuments?.filter(
        (doc: Document) => doc?.documentTypeId === docId
      )[0];
      const isWebform = thisDoc?.documentType?.documentLocation === "mongo";
      //  for PDFs, check if it exists
      //  for webforms, check isComplete & approved or not yet reviewed
      //      incomplete & approved -> enabled submit btn
      //      incomplete & rejected OR incomplete & not yet reviewed, disable submit btn
      if (
        (!isWebform && thisDoc === undefined) ||
        (isWebform &&
          thisDoc?.isComplete !== true &&
          thisDoc?.documentApproved !== true)
      ) {
        this.readiness.validationArrayPassed = false;
        this.readiness.validationArrayIssues.push(docId);
      }
    });
    this.readiness.lobIsAvailable = true;
    if (this.case?.lob?.isHidden) {
      this.readiness.lobIsAvailable = false;
      this.isHiddenLob = true;
    } else {
      this.isHiddenLob = false;
    }
    // Start up business has Designated Entity info
    if (this.case.lob.id === 12) {
      if (
        (this.case.commCertId && this.case.designatedEntityId) ||
        this.case.hasCommCert === false
      ) {
        this.readiness.hasDesignatedEntity = true;
      } else {
        this.readiness.hasDesignatedEntity = false;
      }
      // Not a start up business lob so ignore
    } else {
      this.readiness.hasDesignatedEntity = true;
    }
    // Rep declaration must be signed
    this.readiness.repDeclarationSigned = true;
    if (this.isRep && this.form.controls.repDeclaration?.value !== true) {
      this.readiness.repDeclarationSigned = false;
    }
    // for example status 11 = RPA Upload fail shoud not allow re-submit
    this.readiness.inASubmittableState = this.case.status?.id
      ? !this.caseService.isIntakeCaseInAlreadyInSubmittedStatus(
          this.case.status.id
        )
      : false;
    return !Object.values(this.readiness).some((item) => item === false);
  }

  public get applicationReadyToSubmit(): boolean {
    this.readiness = this.defaultReadiness;
    this.readiness.caseDataLoaded = false;
    if (this.canViewExistingCase) {
      this.readiness.caseDataLoaded = true;
      //  if client with rep, don't allow submission
      this.readiness.hasPermission = true;
      if (!this.user.can("case:submit")) {
        this.readiness.hasPermission = false;
      } else {
        this.readiness.g5CaseHas5Members = true;
        if (
          this.currentCasePsr &&
          this.currentCasePsr.isG5 &&
          !this.currentCasePsr.has5members
        ) {
          this.readiness.g5CaseHas5Members = false;
        }
        this.readiness.psrCaseMemberDocsComplete = true;
        if (this.currentCasePsr && !this.currentCasePsr.hasMemberDocsComplete) {
          this.readiness.psrCaseMemberDocsComplete = false;
        }
        return this.checkApplicationComplete();
      }
    }
    return false;
  }

  public get displayMemberDocsWarning(): boolean {
    if (this.canViewExistingCase) {
      if (!this.user.can("case:submit")) {
        return false;
      } else {
        if (this.isPsr && this.currentCasePsr) {
          return this.currentCasePsr.hasMemberDocsComplete ? false : true;
        }
      }
    }
    return false;
  }

  public get bannerConfig(): IBannerConfig {
    return {
      id: "general-warning",
      content: this.translate.instant(
        "INTAKE.LANDING_PAGE.SUBMIT_MISSING_DOCUMENTS"
      ),
      rounded: true,
      type: "critical",
    };
  }

  public get psrBannerConfig(): IBannerConfig {
    return {
      id: "psr-warning",
      content: this.translate.instant("PSR.MESSAGES.MISSING_INFO_DOCS"),
      rounded: true,
      type: "critical",
    };
  }

  public get isG5(): boolean {
    return this.psrService.isG5(Number(this.lobId));
  }

  public updateCaseInfo(caseId: string) {
    this.caseService
      .getCase(caseId)
      .then((data: Case) => {
        this.store.dispatch(new CaseActions.SetCaseByEmail(data));
      })
      .catch((err) => {
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  public closeModal(id: string): void {
    this.modalService.close(id);
  }

  public async callToSubmitApplication() {
    this.closeModal(this.submitApplicationId);
    window.scroll(0, 0);
    this.isProcessing = true;
    this.cdRef.detectChanges();
    const caseId = this.caseId ? this.caseId : "";
    if (caseId && this.checkApplicationComplete()) {
      const imm8Document = this.case.documents?.find(
        (doc: Document) => doc.documentTypeId === this.imm0008DocumentTypeId
      );
      const caseIMM0008Id = imm8Document?.id ? imm8Document?.id : 0;
      const imm008FormData = await this.documentService.getFormByDocumentId(
        caseId,
        caseIMM0008Id
      );
      // '511' Canada or  '461' US
      if (
        ["511", "461"].indexOf(
          imm008FormData.form.contactDetails.mailingAddress.country
        ) >= 0 &&
        (!imm008FormData.form.contactDetails.mailingAddress.province ||
          !imm008FormData.form.contactDetails.mailingAddress.postalCode)
      ) {
        this.alertService.danger(
          this.translate.instant(
            "INTAKE.IMM0008.DATA_ERRORS.PROVINCE_POSTAL_ERROR"
          )
        );
        this.isProcessing = false;
        return;
      }
      const submittedAt = zonedTimeToUtc(
        new Date(),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      // nationalIdentity expiryDate
      if (
        imm008FormData.form?.nationalIdentityDetails?.nationalIdentityDocument
      ) {
        const nationalIdentityExpiryDate =
          imm008FormData.form?.nationalIdentityDetails?.expiryDate;
        // we do not force this for now
        // if (this.validationService.isBeforeOrInvalidDate(nationalIdentityExpiryDate, submittedAt )) {
        //   this.alertService.danger(this.translate.instant('INTAKE.IMM0008.DATA_ERRORS.NATIONAL_IDENTITY_DATE_ERROR'));
        //   this.isProcessing = false;
        //     return;
        // }
        const nationalIdentityIssueDate =
          imm008FormData.form?.nationalIdentityDetails?.issueDate;
        if (
          this.validationService.isAfterOrInvalidDate(
            nationalIdentityIssueDate,
            submittedAt
          )
        ) {
          this.alertService.danger(
            this.translate.instant(
              "INTAKE.IMM0008.DATA_ERRORS.NATIONAL_IDENTITY_DATE_ERROR"
            )
          );
          this.isProcessing = false;
          return;
        }
      }
      // personal details currentCountry ( these dates are optional)
      const currentCountryEndDate =
        imm008FormData.form?.personalDetails?.currentCountry
          ?.endDateOfImmigrationStatus;
      if (
        currentCountryEndDate &&
        this.validationService.isBeforeOrInvalidDate(
          currentCountryEndDate,
          submittedAt
        )
      ) {
        this.alertService.danger(
          this.translate.instant(
            "INTAKE.IMM0008.DATA_ERRORS.CURRENT_COUNTRY_DATE_ERROR"
          )
        );
        this.isProcessing = false;
        return;
      }
      const currentCountryStartDate =
        imm008FormData.form?.personalDetails?.currentCountry
          ?.startDateOfImmigrationStatus;
      if (
        currentCountryStartDate &&
        this.validationService.isAfterOrInvalidDate(
          currentCountryStartDate,
          submittedAt
        )
      ) {
        this.alertService.danger(
          this.translate.instant(
            "INTAKE.IMM0008.DATA_ERRORS.CURRENT_COUNTRY_DATE_ERROR"
          )
        );
        this.isProcessing = false;
        return;
      }
      // previous countries
      const prevCountries = imm008FormData.form?.personalDetails
        ?.previousCountries as any[];
      const itemWithError = prevCountries
        ? prevCountries.find(
            (item) =>
              item.startDateOfImmigrationStatus ===
              item.endDateOfImmigrationStatus
          )
        : false;
      if (itemWithError) {
        this.alertService.danger(
          this.translate.instant(
            "INTAKE.IMM0008.DATA_ERRORS.PREV_COUNTRY_DATE_ERROR"
          )
        );
        this.isProcessing = false;
        return;
      }
      //passport dates
      if (imm008FormData.form?.passportDetails.validPassport) {
        const passportExpDate =
          imm008FormData.form?.passportDetails?.expiryDate?.split(" ")[0];
        if (
          passportExpDate &&
          this.validationService.isBeforeOrInvalidDate(
            passportExpDate,
            submittedAt
          )
        ) {
          this.alertService.danger(
            this.translate.instant(
              "INTAKE.IMM0008.DATA_ERRORS.PASSPORT_EXPIRY_ERROR"
            )
          );
          this.isProcessing = false;
          return;
        }
        const passportIssueDate =
          imm008FormData.form?.passportDetails?.issueDate?.split(" ")[0];
        if (
          passportIssueDate &&
          this.validationService.isAfterOrInvalidDate(
            passportIssueDate,
            submittedAt
          )
        ) {
          this.alertService.danger(
            this.translate.instant(
              "INTAKE.IMM0008.DATA_ERRORS.PASSPORT_EXPIRY_ERROR"
            )
          );
          this.isProcessing = false;
          return;
        }
      }
      this.submitApplication(caseId);
    }
  }
  public submitApplication(caseId: string) {
    // TODO: Replace with updateCase() once API endpoint /cases/:caseId/status is depreciated
    //  submission status should be changed through the case/:caseId endpoint
    //  (better practise, being used in updateCase) instead of the status endpoint
    this.caseService
      .submitIntakeCase(caseId)
      .then((response) => {
        if (response.status === 235) {
          this.isProcessing = false;
          this.store.dispatch(new CaseActions.SetCaseByEmail(response.data[0]));
          this.subs.forEach((sub) => sub.unsubscribe());
        } else {
          this.isProcessing = false;
          const nextRoute = this.routeLocalizer.getNestedPath(this.lang, [
            "INTAKE",
            this.isRep ? "INTAKE_DASHBOARD" : "INTAKE_VIEW_APPLICATIONS",
          ]);
          this.router.navigate([`/${this.lang}/${nextRoute}`]);
          this.alertService.success(this.alertSuccess);
          this.subs.forEach((sub) => sub.unsubscribe());
        }
      })
      .catch((error) => {
        this.isProcessing = false;
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }

  private get alertSuccess(): string {
    return this.translate.instant(
      "INTAKE.LANDING_PAGE.SUBMIT_MODAL.SUCCESS_BANNER"
    );
  }

  setHideIntake(value: boolean): void {
    this.hideIntake = value;
  }
}
