<h3 *ngIf="confirmationLanding" class="submit-heading">
  {{ "HOME.STEPS.STEP_ONE" | translate }}
</h3>
<lib-accordion-item
  *ngIf="selfDeclare.stepName === 'SELF_DECLARE'"
  [name]="selfDeclare.stepName"
  [isDisabled]="false"
  [ngClass]="{
    'selfDeclare-attention':
      selfDeclare.status !== '' && selfDeclare.status === 'REJECTED'
  }"
  (emitDeclarationCheck)="handleCheckedDeclaration($event)"
>
  <ng-container accordionHeader>
    <img
      class="status-icon"
      width="32"
      [src]="selfDeclare?.statusIconSrc"
      alt=""
    />
    <div class="step__details">
      <h5 class="step__title step__title--{{ selfDeclare.status }}">
        {{ selfDeclare?.title | translate }}
      </h5>
      <p class="step__status step__status--{{ selfDeclare.status }}">
        <strong>{{ selfDeclare?.statusLabel | translate }}</strong>
      </p>
    </div>
  </ng-container>

  <ng-container accordionBody>
    <div *ngIf="selfDeclare.stepName === 'SELF_DECLARE'" class="accordion-body">
      <div class="accordion-body__declare">
        <div class="checkbox-control">
          <input
            id="declaration"
            type="checkbox"
            [disabled]="addressSelfDeclaration"
            [checked]="addressSelfDeclaration"
            (change)="toggleDeclarationCheck()"
            [value]="addressChecked"
          />
          <label
            class="accordion-body__declare__label"
            for="declaration"
            [innerHTML]="
              'SELF_DECLARE.DECLARATION_TEXT' | translate : nameParam
            "
          >
          </label>
        </div>
      </div>
    </div>
  </ng-container>
</lib-accordion-item>

<div *ngIf="selfDeclare.stepName === 'SELF_DECLARE'" class="submit-container">
  <button
    class="btn btn-primary submit-btn"
    [disabled]="addressSelfDeclaration || !addressChecked"
    type="button"
    (click)="submitDeclaration()"
  >
    {{ "HOME.SUBMIT.DECLARATION_BUTTON" | translate }}
  </button>
</div>

<h3 *ngIf="confirmationLanding" class="submit-heading">
  {{ "HOME.STEPS.STEP_TWO" | translate }}
</h3>
<p *ngIf="confirmationLanding">
  {{ "HOME.STEPS.STEP_TWO_WARNING" | translate }}
</p>
<div *ngIf="steps[0].stepName !== 'SELF_DECLARE'">
  <lib-accordion-item
    *ngFor="let step of steps"
    [name]="step.stepName"
    [isDisabled]="!addressSelfDeclaration"
    [ngClass]="{
      'step-attention': step.status !== '' && step.status === 'REJECTED'
    }"
  >
    <ng-container accordionHeader>
      <img class="step__icon" width="32" [src]="step.statusIconSrc" alt="" />
      <div class="step__details">
        <h5 class="step__title step__title--{{ step.status }}">
          {{ step.title | translate }}
        </h5>
        <p class="step__status step__status--{{ step.status }}">
          <strong>{{ step.statusLabel | translate }}</strong>
        </p>
      </div>
    </ng-container>

    <ng-container accordionBody>
      <div class="step__body-description column">
        <p class="step__body-title">
          {{ step.subtitle | translate : nameParam }}
        </p>
        <p [innerHTML]="step.description | translate : nameParam"></p>
      </div>

      <div
        *ngIf="showValue || innerWidth < 992"
        class="step__body-status column"
      >
        <p class="step__body-info" *ngIf="!step.dataProvided">
          {{ step.provideInformation | translate }}
        </p>
        <p *ngIf="step.dataProvided" class="step__provided-title">
          {{ step.dataProvidedTitle | translate }}
        </p>
        <div
          class="step__body-rejection"
          *ngIf="step.rejectionReasons.length > 0"
        >
          <span id="step__body-rejection-title"
            >{{ "HOME.STEPS.REJECTION_REASONS" | translate }}
          </span>
          <ul
            aria-labelledby="step__body-rejection-title"
            class="step__body-rejection-list"
          >
            <li *ngFor="let reason of step.rejectionReasons">- {{ reason }}</li>
          </ul>
        </div>
        <p class="step__provided-info">{{ step.dataProvided | translate }}</p>
        <div class="step__preview-thumbnail" *ngIf="step.dataPreview">
          <lib-image-preview
            [coordinates]="step.dataPreviewCoordinates"
            [imageSrc]="step.dataPreview"
            [imageAlt]="'PHOTOS.PREVIEW_ALT' | translate"
          ></lib-image-preview>
          <!-- <img [src]="step.dataPreview" [alt]="'PHOTOS.PREVIEW_ALT' | translate" /> -->
        </div>
      </div>
      <div class="step__body-link column">
        <a
          *ngIf="!step.isBtnDisabled"
          [routerLink]="routeLocalizer.get(lang, step.pathKey)"
          category="primary"
        >
          {{ "HOME.STEPS.BUTTON" | translate }}
          <span class="visually-hidden">
            {{ step.title | translate }}
          </span>
        </a>
      </div>
    </ng-container>
  </lib-accordion-item>
</div>

<div *ngIf="steps[0].stepName !== 'SELF_DECLARE'" class="submit-container">
  <button
    class="btn btn-primary submit-btn"
    [disabled]="!canSubmitApplication"
    type="button"
    (click)="submitApplication()"
  >
    {{ "HOME.SUBMIT.DOCUMENTS_BUTTON" | translate }}
  </button>
</div>

<div
  class="submit-text"
  [innerHTML]="'HOME.SUBMIT.SUBMIT_TEXT' | translate : nameParam"
></div>
