import { Component, AfterViewInit, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { RepService } from "@pr-applicant/app/shared/services/rep/rep.service";
import { RepInfoFormComponent } from "../../forms/rep-info/rep-info.component";
import { RepAccountInfo } from "@pr-applicant/app/core/models/rep-account-info";
import { CanComponentDeactivate } from "@pr-applicant/app/routing/guards/nav.guard";
import { first } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "@pr-applicant/app/store/app.reducer";
import * as AuthActions from "@pr-applicant/app/core/auth-module/store/auth.actions";
import { AlertService, ModalService } from "lib";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pra-rep-account-page",
  templateUrl: "./rep-account.component.html",
  styleUrls: ["./rep-account.component.scss"],
})
export class RepAccountPageComponent
  implements AfterViewInit, OnInit, CanComponentDeactivate
{
  @ViewChild(RepInfoFormComponent) public repInfoForm: RepInfoFormComponent;

  private repId: string;
  private repMembershipId: string;
  private user: any;
  private isDocLinkUpdated = false;
  private updatesSaved = false;
  public isLoading = true;

  isAccountCreated: boolean;
  isAccountDetailCompleted: boolean;
  title = "REP_ACCOUNT_DETAIL.TITLE_UPDATE";
  infoLine = "REP_ACCOUNT_DETAIL.INFO_LINE_UPDATE";
  docLink: string;
  repInfo = new Subject<RepAccountInfo>();
  form = new FormGroup({
    terms: new FormControl("", [Validators.requiredTrue]),
  });

  constructor(
    private router: Router,
    private routeLocalizer: RouteLocalizerService,
    private repService: RepService,
    private store: Store<fromApp.State>,
    private alert: AlertService,
    private translate: TranslateService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((auth) => {
        this.user = auth.user;
        this.repId = auth.user?.repId || "";
        this.repMembershipId = auth.user?.membershipId || "";
        this.isAccountCreated = !!this.repId;
        this.isAccountDetailCompleted = !!this.repMembershipId;
      });
    if (!this.isAccountCreated) {
      this.title = "REP_ACCOUNT_DETAIL.TITLE_CREATE";
      this.infoLine = "REP_ACCOUNT_DETAIL.INFO_LINE_CREATE";
    }
  }

  ngAfterViewInit() {
    this.repService
      .getAccountDetails(this.repId)
      .pipe(first())
      .subscribe(
        (repInfo) => {
          this.repInfo.next(repInfo);
          this.updatedDoclinkForRep(repInfo.documentLink, false);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          if (err.status !== 404) {
            this.displayTechnicalError();
          }
        }
      );
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (
      this.form.touched ||
      this.repInfoForm.touched() ||
      this.isDocLinkUpdated
    ) {
      if (this.updatesSaved) {
        return true;
      }
      this.modalService.open("navigationModal");
      return this.modalService.navigateAwaySelection$;
    }
    return true;
  }

  get isRepInfoFormValid() {
    const isRepInfoValid = !!this.repInfoForm?.isValid();
    if (this.isAccountCreated) {
      return isRepInfoValid && !!this.docLink;
    } else {
      return this.form.valid && isRepInfoValid && !!this.docLink;
    }
  }

  updateRepAccountDetails() {
    const updatedAccountInfo = {
      ...this.repInfoForm?.value(),
      documentLink: this.docLink,
    };
    this.repService
      .updateAccountDetails(this.repId, updatedAccountInfo)
      .pipe(first())
      .subscribe(
        () => {
          this.updatesSaved = true;
          this.alert.success(
            this.translate.instant("REP_ACCOUNT_DETAIL.UPDATE_SUCCESS")
          );
          this.store.dispatch(
            new AuthActions.Login({
              ...this.user,
              repId: this.repId,
              membershipId: updatedAccountInfo.membershipId,
            })
          );
          this.router.navigateByUrl(
            `/${this.routeLocalizer.getCurrentRouteLang()}`
          );
        },
        (err) => {
          this.displayTechnicalError();
        }
      );
  }

  createRepAccountDetails() {
    const accountInfo = {
      ...this.repInfoForm?.value(),
      documentLink: this.docLink,
    };
    this.repService
      .createAccount(accountInfo)
      .pipe(first())
      .subscribe(
        (repInfo) => {
          this.updatesSaved = true;
          this.alert.success(
            this.translate.instant("REP_ACCOUNT_DETAIL.UPDATE_SUCCESS")
          );
          this.store.dispatch(
            new AuthActions.Login({
              ...this.user,
              repId: repInfo.id,
              membershipId: repInfo.membershipId,
            })
          );
        },
        (err) => {
          this.displayTechnicalError();
        }
      );
  }

  updatedDoclinkForRep(docLink: string, updatedDocLink: boolean): void {
    this.docLink = docLink;
    if (updatedDocLink === true) {
      this.isDocLinkUpdated = true;
    }
  }

  private displayTechnicalError(): void {
    this.alert.danger(
      this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
    );
  }
}
