import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LibModule } from "lib";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IntakeLandingPageComponent } from "./pages/intake-landing-page/intake-landing-page.component";
import { SharedModule } from "../shared/shared.module";
import { SupportingDocumentsUploadComponent } from "./pages/intake-landing-page/supporting-documents-upload/supporting-documents-upload.component";
import { DocumentsUploadTableComponent } from "./pages/intake-landing-page/supporting-documents-upload/documents-upload-table/documents-upload-table.component";
import { WebFormTableComponent } from "../intake-module/pages/intake-landing-page/web-form-table/web-form-table.component";
import { ProofOfPaymentTableComponent } from "./pages/intake-landing-page/proof-payment-table/proof-of-payment-table.component";
import { ESignatureDeclarationTableComponent } from "./pages/intake-landing-page/e-signature-declaration-table/e-signature-declaration-table.component";
import { WetSignatureDeclarationComponent } from "./pages/intake-landing-page/wet-signature-declaration/wet-signature-declaration.component";
import { ProgramSelectionFormComponent } from "./forms/program-selection-form/program-selection-form.component";
import { IMM0008ApplicationDetailsFormComponent } from "./forms/IMM0008/application-details-form/application-details-form.component";
import { IMM0008PersonalDetailsFormComponent } from "./forms/IMM0008/personal-details-form/personal-details-form.component";
import { IMM0008AddressFormComponent } from "./forms/IMM0008/address-form/address-form.component";
import { IMM0008EducationDetailsFormComponent } from "./forms/IMM0008/education-details-form/education-details-form.component";
import { IMM0008LanguageDetailsFormComponent } from "./forms/IMM0008/language-details-form/language-details-form.component";
import { IMM0008PassportDetailsFormComponent } from "./forms/IMM0008/passport-details-form/passport-details-form.component";
import { IMM0008NationalIdentityFormComponent } from "./forms/IMM0008/national-identity-form/national-identity-form.component";
import { IMM0008ViewDepandantFormComponent } from "./forms/IMM0008/view-dependant-form/view-dependant-form.component";
import { IMM0008ConsentDeclarationFormComponent } from "./forms/IMM0008/consent-declaration-form/consent-declaration-form.component";
import { IMM0008AddDependantFormComponent } from "./forms/IMM0008/add-dependant-form/add-dependant-form.component";
import { RepInfoFormComponent } from "./forms/rep-info/rep-info.component";
import { ProgramInfoLinksComponent } from "./pages/intake-landing-page/program-info-links/program-info-links.component";
import { CertificateNumberSectionComponent } from "./pages/intake-landing-page/certificate-number-section/certificate-number-section.component";
import { IMM0008Page1Component } from "./pages/IMM0008/page1/page1.component";
import { IMM0008Page2Component } from "./pages/IMM0008/page2/page2.component";
import { IMM0008Page3Component } from "./pages/IMM0008/page3/page3.component";
import { IMM0008Page4Component } from "./pages/IMM0008/page4/page4.component";
import { IMM0008Page5Component } from "./pages/IMM0008/page5/page5.component";
import { IMM0008Page6Component } from "./pages/IMM0008/page6/page6.component";
import { IMM0008Page7Component } from "./pages/IMM0008/page7/page7.component";
import { IMM0008Page8Component } from "./pages/IMM0008/page8/page8.component";
import { IMM0008Page9Component } from "./pages/IMM0008/page9/page9.component";
import { IMM0008Page10Component } from "./pages/IMM0008/page10/page10.component";
import { IMM0008DependantComponent } from "./pages/IMM0008/dependant/dependant.component";
import { RepAccountPageComponent } from "./pages/rep-account/rep-account.component";
import { FamilyTableComponent } from "./forms/components/family-table/family-table.component";
import { ESignatureDeclarationPageComponent } from "./pages/declaration/e-signature-declaration-page.component";
import { DeclarationFormComponent } from "./forms/declaration-form/declaration-form-component/declaration-form-component.component";
import { ComponentLoadDirective } from "../core/directives/componentLoad.directive";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

// IMM5669 Form
import { IMM5669PageComponent } from "./pages/IMM5669/IMM5669page.component";
import { IMM5669StartComponent } from "./forms/IMM5669/imm5669-start/imm5669-start.component";
import { IMM5669SectionAComponent } from "./forms/IMM5669/imm5669-section-a/imm5669-section-a.component";
import { IMM5669SectionBComponent } from "./forms/IMM5669/imm5669-section-b/imm5669-section-b.component";
import { IMM5669SectionCComponent } from "./forms/IMM5669/imm5669-section-c/imm5669-section-c.component";
import { IMM5669SectionDComponent } from "./forms/IMM5669/imm5669-section-d/imm5669-section-d.component";
import { IMM5669SectionEComponent } from "./forms/IMM5669/imm5669-section-e/imm5669-section-e.component";
import { IMM5669SectionFComponent } from "./forms/IMM5669/imm5669-section-f/imm5669-section-f.component";
import { IMM5669SectionGComponent } from "./forms/IMM5669/imm5669-section-g/imm5669-section-g.component";
import { IMM5669SectionHComponent } from "./forms/IMM5669/imm5669-section-h/imm5669-section-h.component";

// IMM5406 Form
import { IMM5406PageComponent } from "./pages/IMM5406/IMM5406page.component";
import { IMM5406SectionAComponent } from "./forms/IMM5406/imm5406-section-a/imm5406-section-a.component";
import { IMM5406SectionBComponent } from "./forms/IMM5406/imm5406-section-b/imm5406-section-b.component";
import { IMM5406SectionCComponent } from "./forms/IMM5406/imm5406-section-c/imm5406-section-c.component";
import { IMM5406StartComponent } from "./forms/IMM5406/imm5406-start/imm5406-start.component";
// IMM5562 Form
import { IMM5562PageComponent } from "./pages/IMM5562/IMM5562page.component";
import { IMM5562SectionAComponent } from "./forms/IMM5562/imm5562-section-a/imm5562-section-a.component";
import { IMM5562SectionBComponent } from "./forms/IMM5562/imm5562-section-b/imm5562-section-b.component";
import { IMM5562SectionCComponent } from "./forms/IMM5562/imm5562-section-c/imm5562-section-c.component";
import { RepIdentificationUploadTableComponent } from "./forms/rep-identification-upload-table/rep-identification-upload-table.component";

import { ProfileComponent } from "./pages/profile/profile.component";
import { MatTableModule } from "@angular/material/table";
import { IMM5562StartComponent } from "./forms/IMM5562/imm5562-start/imm5562-start.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { RepDashboardComponent } from "./pages/rep-dashboard/rep-dashboard.component";
import { FormNavBarComponent } from "./forms/components/form-nav-bar/form-nav-bar.component";
import { FormHamburgerComponent } from "./forms/components/form-nav-bar/form-hamburger/form-hamburger.component";
import { OtherFormsComponent } from "./pages/intake-landing-page/other-forms-and-documents/other-forms/other-forms.component";
import { OtherDocumentsComponent } from "./pages/intake-landing-page/other-forms-and-documents/other-documents/other-documents.component";
import { ManageGroupMembersComponent } from "./pages/privately-sponsored-refugees/manage-group-members/manage-group-members.component";
import { InviteGroupMembersComponent } from "./pages/privately-sponsored-refugees/manage-group-members/invite-group-members/invite-group-members.component";
import { MemberFieldsComponent } from "./pages/privately-sponsored-refugees/manage-group-members/member-fields/member-fields.component";
import { InvitePrincipalApplicantComponent } from "./pages/privately-sponsored-refugees/manage-group-members/invite-principal-applicant/invite-principal-applicant.component";
import { InviteConfirmComponent } from "./pages/privately-sponsored-refugees/manage-group-members/invite-confirm/invite-confirm.component";
import { InvitePrincipalApplicantsRepComponent } from "./pages/privately-sponsored-refugees/manage-group-members/invite-principal-applicants-rep/invite-principal-applicants-rep.component";
import { SponsorProfileComponent } from "./pages/privately-sponsored-refugees/sponsor-profile/sponsor-profile.component";
import { IntakeSideNavComponent } from "./pages/intake-landing-page/intake-side-nav/intake-side-nav.component";
import { IrccDsAngularNavigationModule } from "ircc-ds-angular-component-library";
import { DependantCardComponent } from "./pages/profile/dependant-card/dependant-card.component";
import { MemberCardComponent } from "./pages/privately-sponsored-refugees/manage-group-members/member-card/member-card.component";
import { IrccDsAngularBannerModule } from "ircc-ds-angular-component-library";
import { ResendPasswordButtonComponent } from "./pages/privately-sponsored-refugees/manage-group-members/resend-password-button/resend-password-button.component";
import { ReferrelPartnerDocTableComponent } from "./pages/intake-landing-page/referrel-partner-doc-table/referrel-partner-doc-table.component";
import { AnchorDocTableComponent } from "./pages/intake-landing-page/anchor-doc-table/anchor-doc-table.component";

@NgModule({
  declarations: [
    DocumentsUploadTableComponent,
    IntakeLandingPageComponent,
    ProgramSelectionFormComponent,
    ProofOfPaymentTableComponent,
    ESignatureDeclarationTableComponent,
    WetSignatureDeclarationComponent,
    SupportingDocumentsUploadComponent,
    SupportingDocumentsUploadComponent,
    WebFormTableComponent,
    IMM0008Page1Component,
    IMM0008Page2Component,
    IMM0008Page3Component,
    IMM0008Page4Component,
    IMM0008Page5Component,
    IMM0008Page6Component,
    IMM0008Page7Component,
    IMM0008Page8Component,
    IMM0008Page9Component,
    IMM0008Page10Component,
    IMM0008DependantComponent,
    RepInfoFormComponent,
    RepAccountPageComponent,
    IMM0008ApplicationDetailsFormComponent,
    IMM0008PersonalDetailsFormComponent,
    IMM0008AddressFormComponent,
    IMM0008EducationDetailsFormComponent,
    IMM0008LanguageDetailsFormComponent,
    IMM0008PassportDetailsFormComponent,
    IMM0008NationalIdentityFormComponent,
    IMM0008ViewDepandantFormComponent,
    IMM0008AddDependantFormComponent,
    IMM0008ConsentDeclarationFormComponent,
    ProgramInfoLinksComponent,
    CertificateNumberSectionComponent,
    ESignatureDeclarationPageComponent,
    DeclarationFormComponent,
    ComponentLoadDirective,
    FamilyTableComponent,
    IMM5669PageComponent,
    IMM5669StartComponent,
    IMM5669SectionAComponent,
    IMM5669SectionBComponent,
    IMM5669SectionCComponent,
    IMM5669SectionDComponent,
    IMM5669SectionEComponent,
    IMM5669SectionFComponent,
    IMM5669SectionGComponent,
    IMM5669SectionHComponent,
    IMM5406PageComponent,
    IMM5406StartComponent,
    IMM5406SectionAComponent,
    IMM5406SectionBComponent,
    IMM5406SectionCComponent,
    IMM5562PageComponent,
    IMM5562StartComponent,
    IMM5562SectionAComponent,
    IMM5562SectionBComponent,
    IMM5562SectionCComponent,
    RepIdentificationUploadTableComponent,
    RepDashboardComponent,
    FormNavBarComponent,
    FormHamburgerComponent,
    OtherFormsComponent,
    OtherDocumentsComponent,
    ManageGroupMembersComponent,
    InviteGroupMembersComponent,
    MemberFieldsComponent,
    InvitePrincipalApplicantComponent,
    InviteConfirmComponent,
    InvitePrincipalApplicantsRepComponent,
    SponsorProfileComponent,
    IntakeSideNavComponent,
    ProfileComponent,
    DependantCardComponent,
    MemberCardComponent,
    ResendPasswordButtonComponent,
    ReferrelPartnerDocTableComponent,
    AnchorDocTableComponent,
  ],
  imports: [
    IrccDsAngularNavigationModule,
    IrccDsAngularBannerModule,
    CommonModule,
    LibModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatAutocompleteModule,
  ],
  exports: [ProgramSelectionFormComponent],
})
export class IntakeModule {}
