<section class="referrel-partner-doc-table">
  <h2>
    <span class="referrel-partner-doc-table__required">*</span
    >{{ "INTAKE.LANDING_PAGE.SIDE_NAV.REFERRAL_PARTNER_DOCUMENTS" | translate }}
    <span class="referrel-partner-doc-table__required">{{
      "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
    }}</span>
  </h2>
  <pra-documents-upload-table
    [isHiddenLob]="isHiddenLob"
    (supportDocumentsUpdated)="handleUpdate()"
    [requiredDocumentOptions]="requiredDocumentOptions"
    [caseMemberId]="caseMemberId"
  ></pra-documents-upload-table>
</section>
