<form [formGroup]="passportDetailsForm" autocomplete="off">
  <!-- do you have a passport -->
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.VALID_PASSPORT.LEGEND'
          | translate
      "
      firstId="validPassportYes"
      secondId="validPassportNo"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="passport-check"
      [formControl]="passportDetailsForm.controls.validPassport"
      [required]="isPassportDetailsRadioRequired"
      (click)="setTouched()"
    >
      <span
        class="error-radio"
        *ngIf="
          passportDetailsForm.get('validPassport')?.touched &&
          passportDetailsForm.get('validPassport')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <!-- Section is shown when YES is selected above-->
  <!-- provide passport/document number -->
  <fieldset *ngIf="showPassportForm">
    <lib-form-input
      identifier="passportNumber"
      [formControl]="passportDetailsForm.controls.passportNumber"
      [helpText]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.LEGEND'
          | translate
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.DOCUMENT_NUMBER_LABEL'
          | translate
      "
      required="true"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          passportDetailsForm.get('passportNumber')?.touched &&
          passportDetailsForm.get('passportNumber')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          passportDetailsForm.get('passportNumber')?.touched &&
          passportDetailsForm.get('passportNumber')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max20 }}
      </span>
      <span
        *ngIf="
          passportDetailsForm.get('passportNumber')?.touched &&
          passportDetailsForm.get('passportNumber')?.hasError('alphaNum')
        "
      >
        {{ "INTAKE.FORM_ERRORS.ALPHANUMERIC_ONLY" | translate }}
      </span>
    </lib-form-input>

    <!-- Country of issue -->
    <lib-form-select
      identifier="countryOfIssue"
      data-cy="passport-details-country-of-issue"
      [formControl]="passportDetailsForm.controls.countryOfIssue"
      [label]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.COUNTRY_LABEL'
          | translate
      "
      [options]="countryOfIssueList"
      required="true"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          passportDetailsForm.get('countryOfIssue')?.touched &&
          passportDetailsForm.get('countryOfIssue')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>

    <!-- Issue date -->
    <lib-form-datepicker
      identifier="issueDate"
      [formControl]="passportDetailsForm.controls.issueDate"
      [label]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.ISSUE_DATE_LABEL'
          | translate
      "
      [required]="true"
      [pastDate]="true"
      [readOnly]="!user.can('documents:write')"
    >
    </lib-form-datepicker>

    <!-- Expiry date -->
    <lib-form-datepicker
      identifier="expiryDate"
      [formControl]="passportDetailsForm.controls.expiryDate"
      [label]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.DOCUMENT_NUMBER.EXPIRY_DATE_LABEL'
          | translate
      "
      [required]="true"
      [futureDate]="true"
      [readOnly]="!user.can('documents:write')"
    >
    </lib-form-datepicker>
  </fieldset>

  <!-- Taiwan Passport -->
  <fieldset *ngIf="isTaiwanSelected">
    <lib-form-radio
      [legend]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.TAIWAN_PASSPORT.LEGEND'
          | translate
      "
      firstId="taiwanYes"
      secondId="taiwanNo"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="taiwan-passport"
      [formControl]="passportDetailsForm.controls.usingTaiwanesePassportForTrip"
      [required]="isTaiwanRadioRequired"
      [editable]="user.can('documents:write')"
    >
      <span
        class="error-radio"
        *ngIf="
          passportDetailsForm.get('usingTaiwanesePassportForTrip')?.touched &&
          passportDetailsForm
            .get('usingTaiwanesePassportForTrip')
            ?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <!-- Israeli passport check -->
  <fieldset *ngIf="isIsraelSelected">
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.PASSPORT_DETAILS.ISRAEL_PASSPORT.LEGEND'
          | translate
      "
      firstId="israelYes"
      secondId="israelNo"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="taiwan-passport"
      [formControl]="passportDetailsForm.controls.usingIsraeliPassportForTrip"
      [required]="isIsraelRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          passportDetailsForm.get('usingIsraeliPassportForTrip')?.touched &&
          passportDetailsForm
            .get('usingIsraeliPassportForTrip')
            ?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>
</form>
