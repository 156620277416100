import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-b",
  templateUrl: "./imm5669-section-b.component.html",
  styleUrls: ["./imm5669-section-b.component.scss"],
})
export class IMM5669SectionBComponent implements OnInit, OnDestroy {
  public additionalDetailsMaxChar = { max: 679 };

  //  sets the order of the fields
  //  template loops through this array & gets associated formcontrol
  public questionnaireFields = [
    { controlName: "isConvictedInCanada", copyKey: "IS_CONVICTED_IN_CANADA" },
    {
      controlName: "isConvictedOutsideCanada",
      copyKey: "IS_CONVICTED_OUTSIDE_CANADA",
    },
    {
      controlName: "isClaimedRefugeeProtection",
      copyKey: "IS_CLAIMED_REFUGEE_PROTECTION",
    },
    {
      controlName: "isRefusedRefugeeOrVisa",
      copyKey: "IS_REFUSED_REFUGEE_OR_VISA",
    },
    {
      controlName: "isOrderedToLeaveCountry",
      copyKey: "IS_ORDERED_TO_LEAVE_COUNTRY",
    },
    { controlName: "isWarCriminal", copyKey: "IS_WAR_CRIMINAL" },
    {
      controlName: "isCommittedActOfViolence",
      copyKey: "IS_COMMITTED_ACT_OF_VIOLENCE",
    },
    {
      controlName: "isAssociatedWithViolentGroup",
      copyKey: "IS_ASSOCIATED_WITH_VIOLENT_GROUP",
    },
    {
      controlName: "isMemberOfCriminalOrg",
      copyKey: "IS_MEMBER_OF_CRIMINAL_ORG",
    },
    { controlName: "isDetainedOrJailed", copyKey: "IS_DETAINED_OR_JAILED" },
    {
      controlName: "isPhysicalOrMentalDisorder",
      copyKey: "IS_PHYSICAL_OR_MENTAL_DISORDER",
    },
  ];

  private currentForm: any;
  private storeSubsc$: Subscription;
  private formData: any;

  // FormGroups used to build reactive form
  // Important: Keep these below validation variables
  public sectionBForm: FormGroup = this.createSectionBFormGroup();

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.getStoredFormData();
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  /* PUBLIC ---------------------------------------------------- */

  public getFormData(): any {
    if (!this.isFormValid) {
      return { sectionName: "sectionB", isInvalid: true };
    }
    const result = _.cloneDeep(this.currentForm);
    const entryData = this.sectionBForm.getRawValue();
    result.form.sectionB = { ...entryData, _id: result.form.sectionB._id };
    return { sectionName: "sectionB", sectionInfo: result.form.sectionB };
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.sectionBForm.touched;
  }

  public get isFormValid(): boolean {
    return this.sectionBForm?.valid;
  }

  /* PRIVATE ---------------------------------------------------- */

  private getStoredFormData() {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.formData = currentImmDoc?.currentForm?.form?.sectionB;
        this.setFormValues();
      });
  }

  //  create a formgroup
  //  match with keys in questionnaireFields array
  private createSectionBFormGroup(): FormGroup {
    return this.fb.group({
      additionalDetails: [
        null,
        Validators.maxLength(this.additionalDetailsMaxChar.max),
      ],
      isAssociatedWithViolentGroup: [null],
      isClaimedRefugeeProtection: [null],
      isCommittedActOfViolence: [null],
      isConvictedInCanada: [null],
      isConvictedOutsideCanada: [null],
      isDetainedOrJailed: [null],
      isMemberOfCriminalOrg: [null],
      isOrderedToLeaveCountry: [null],
      isPhysicalOrMentalDisorder: [null],
      isRefusedRefugeeOrVisa: [null],
      isWarCriminal: [null],
    });
  }

  private setFormValues(): void {
    this.sectionBForm?.controls?.additionalDetails?.setValue(
      this.formData?.additionalDetails
    );
    this.questionnaireFields.forEach((field) => {
      this.sectionBForm
        ?.get(field.controlName)
        ?.setValue(this.formData[field.controlName]);
    });
  }
}
