<form [formGroup]="addressForm" autocomplete="off">
  <fieldset>
    <!-- CURRENT MAILING ADDRESS -->
    <legend>
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.MAILING.LEGEND" | translate }}
    </legend>

    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="1">
        <lib-form-input
          identifier="mailingPOBox"
          [formControl]="addressForm.controls.postOfficeBox"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POBOX_LABEL' | translate
          "
          [widthSm]="true"
          [editable]="user.can('documents:write')"
        >
          <span
            *ngIf="
              addressForm.get('postOfficeBox')?.touched &&
              addressForm.get('postOfficeBox')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max15 }}
          </span>
          <span
            *ngIf="
              addressForm.get('postOfficeBox')?.touched &&
              addressForm.get('postOfficeBox')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-col>
      <lib-layout-col flex="1"
        ><lib-form-input
          identifier="MailingAptUnit"
          [formControl]="addressForm.controls.apartmentUnit"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL' | translate
          "
          [widthSm]="true"
          [editable]="user.can('documents:write')"
        >
          <span
            *ngIf="
              addressForm.get('apartmentUnit')?.touched &&
              addressForm.get('apartmentUnit')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              addressForm.get('apartmentUnit')?.touched &&
              addressForm.get('apartmentUnit')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-col>
      <lib-layout-col flex="2">
        <lib-form-input
          identifier="MailingStreetNum"
          [formControl]="addressForm.controls.streetNumber"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL' | translate
          "
          [widthSm]="true"
          [editable]="user.can('documents:write')"
        >
          <span
            *ngIf="
              addressForm.get('streetNumber')?.touched &&
              addressForm.get('streetNumber')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              addressForm.get('streetNumber')?.touched &&
              addressForm.get('streetNumber')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input>
      </lib-layout-col>
      <lib-layout-col flex="3">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="MailingStreetName"
          [formControl]="addressForm.controls.streetName"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL' | translate
          "
          [widthMd]="true"
          [required]="isMailingStreetNameRequired"
        >
          <span
            *ngIf="
              addressForm.get('streetName')?.touched &&
              addressForm.get('streetName')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('streetName')?.touched &&
              addressForm.get('streetName')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
          </span>
          <span
            *ngIf="
              addressForm.get('streetName')?.touched &&
              addressForm.get('streetName')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>
    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="MailingCityTown"
          [formControl]="addressForm.controls.city"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL' | translate
          "
          required="true"
          [widthMd]="true"
        >
          <span
            *ngIf="
              addressForm.get('city')?.touched &&
              addressForm.get('city')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('city')?.touched &&
              addressForm.get('city')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              addressForm.get('city')?.touched &&
              addressForm.get('city')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="2">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="MailingCountry"
          class="country-dropdown"
          data-cy="address-form-mailing-country"
          [formControl]="addressForm.controls.country"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL' | translate
          "
          [options]="countriesList"
          [required]="true"
          [widthSm]="true"
        >
          <span
            *ngIf="
              addressForm.get('country')?.touched &&
              addressForm.get('country')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="MailingProvinceState"
          data-cy="address-form-mailing-province-state"
          [formControl]="addressForm.controls.province"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL'
              | translate
          "
          [options]="provinceOrStateListMailing()"
          [required]="isCanadaSelectedMailing || isUsaSelectedMailing"
          [widthSm]="true"
        >
          <span
            *ngIf="
              (addressForm.get('country')?.value ||
                addressForm.get('country')?.dirty ||
                addressForm.get('province')?.dirty) &&
              addressForm.get('province')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
    </lib-layout-row>
    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="MailingPostalCode"
          [formControl]="addressForm.controls.postalCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL' | translate
          "
          [required]="isCanadaSelectedMailing || isUsaSelectedMailing"
          [widthMd]="true"
        >
          <span
            *ngIf="
              (addressForm.get('country')?.value ||
                addressForm.get('country')?.dirty ||
                addressForm.get('postalCode')?.touched) &&
              addressForm.get('postalCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('postalCode')?.touched &&
              addressForm.get('postalCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              addressForm.get('postalCode')?.touched &&
              addressForm.get('postalCode')?.hasError('CAPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_CA" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('postalCode')?.touched &&
              addressForm.get('postalCode')?.hasError('USPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_US" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('postalCode')?.touched &&
              addressForm.get('postalCode')?.hasError('alphaNumPlusFew')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_NON_NA" | translate }}
          </span>
          <span
            *ngIf="
              addressForm.get('postalCode')?.touched &&
              addressForm
                .get('postalCode')
                ?.hasError('mustBeNonCanadianPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_MUSTBE_NON_CA" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="MailingDistrict"
          [formControl]="addressForm.controls.district"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL' | translate
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              addressForm.get('district')?.touched &&
              addressForm.get('district')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              addressForm.get('district')?.touched &&
              addressForm.get('district')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>
  </fieldset>

  <fieldset>
    <!-- RESIDENTIAL ADDRESS = MAILING ADDRESS ? -->
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="'INTAKE.IMM0008.FORMS.ADDRESS.SAME_ADDRESS.LEGEND' | translate"
      firstId="yes"
      secondId="no"
      [firstValue]="1"
      [secondValue]="0"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="radio-same-address"
      [formControl]="residentialForm.controls.residentialSameAsMailingAddress"
      [required]="isResidentialSameMailingRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          residentialForm.get('residentialSameAsMailingAddress')?.touched &&
          residentialForm
            .get('residentialSameAsMailingAddress')
            ?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        class="error-radio"
        *ngIf="
          residentialForm
            .get('residentialSameAsMailingAddress')
            ?.hasError('isValueEmpty') &&
          residentialForm.controls.residentialSameAsMailingAddress.value
        "
      >
        {{ "INTAKE.FORM_ERRORS.RESIDENTIAL_ADDRESS_WITHOUT_POBOX" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <fieldset *ngIf="!residentialSameMailing">
    <!-- RESIDENTIAL ADDRESS -->
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.ADDRESS.RESIDENTIAL_ADDRESS.LEGEND" | translate
      }}
    </legend>

    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialAptUnit"
          [formControl]="residentialForm.controls.apartmentUnit"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.APTUNIT_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              residentialForm.get('apartmentUnit')?.touched &&
              residentialForm.get('apartmentUnit')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              residentialForm.get('apartmentUnit')?.touched &&
              residentialForm.get('apartmentUnit')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('apartmentUnit')?.touched &&
              residentialForm.get('apartmentUnit')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialStreetNum"
          [formControl]="residentialForm.controls.streetNumber"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNUM_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              residentialForm.get('streetNumber')?.touched &&
              residentialForm.get('streetNumber')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>
          <span
            *ngIf="
              residentialForm.get('streetNumber')?.touched &&
              residentialForm.get('streetNumber')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('streetNumber')?.touched &&
              residentialForm.get('streetNumber')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="3">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialStreetName"
          [formControl]="residentialForm.controls.streetName"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.STREETNAME_LABEL' | translate
          "
          [widthMd]="true"
          [required]="!residentialSameMailing"
        >
          <span
            *ngIf="
              residentialForm.get('streetName')?.touched &&
              residentialForm.get('streetName')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
          </span>
          <span
            *ngIf="
              residentialForm.get('streetName')?.touched &&
              residentialForm.get('streetName')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('streetName')?.touched &&
              residentialForm.get('streetName')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>
    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialCityTown"
          [formControl]="residentialForm.controls.city"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.CITY_TOWN_LABEL' | translate
          "
          [widthMd]="true"
          [required]="!residentialSameMailing"
        >
          <span
            *ngIf="
              residentialForm.get('city')?.touched &&
              residentialForm.get('city')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('city')?.touched &&
              residentialForm.get('city')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              residentialForm.get('city')?.touched &&
              residentialForm.get('city')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="2">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="ResidentialCountry"
          data-cy="address-form-residential-country"
          [formControl]="residentialForm.controls.country"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.COUNTRY_LABEL' | translate
          "
          [options]="countriesList"
          [required]="!residentialSameMailing"
          [widthSm]="true"
          class="country-dropdown"
        >
          <span
            *ngIf="
              residentialForm.get('country')?.touched &&
              residentialForm.get('country')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="ResidentialProvinceState"
          data-cy="address-form-residential-province-state-2"
          [formControl]="residentialForm.controls.province"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.PROVINCE_STATE_LABEL'
              | translate
          "
          [options]="provinceOrStateListRes()"
          [required]="isCanadaSelectedRes || isUsaSelectedRes"
          [widthSm]="true"
        >
          <span
            *ngIf="
              (residentialForm.get('country')?.value ||
                residentialForm.get('country')?.dirty ||
                residentialForm.get('province')?.dirty) &&
              residentialForm.get('province')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
    </lib-layout-row>
    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialPostalCode"
          [formControl]="residentialForm.controls.postalCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.POSTAL_CODE_LABEL' | translate
          "
          [required]="isCanadaSelectedRes || isUsaSelectedRes"
          [widthMd]="true"
        >
          <span
            *ngIf="
              (residentialForm.get('country')?.value ||
                residentialForm.get('country')?.dirty ||
                residentialForm.get('province')?.dirty) &&
              residentialForm.get('postalCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('postalCode')?.touched &&
              residentialForm.get('postalCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
          </span>

          <span
            *ngIf="
              residentialForm.get('postalCode')?.touched &&
              residentialForm.get('postalCode')?.hasError('CAPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_CA" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('postalCode')?.touched &&
              residentialForm.get('postalCode')?.hasError('USPostalCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_US" | translate }}
          </span>
          <span
            *ngIf="
              residentialForm.get('postalCode')?.touched &&
              residentialForm.get('postalCode')?.hasError('alphaNumPlusFew')
            "
          >
            {{ "INTAKE.FORM_ERRORS.POSTAL_CODE_NON_NA" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="ResidentialDistrict"
          [formControl]="residentialForm.controls.district"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.MAILING.DISTRICT_LABEL' | translate
          "
          [widthMd]="true"
        >
          <span
            *ngIf="
              residentialForm.get('district')?.touched &&
              residentialForm.get('district')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              residentialForm.get('district')?.touched &&
              residentialForm.get('district')?.hasError('invalidChars')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>
  </fieldset>

  <fieldset>
    <legend class="visually-hidden">
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.PRIMARY_LEGEND" | translate }}
    </legend>
    <!-- PRIMARY PHONE NUMBER -->
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.PRIMARY_LEGEND' | translate"
      firstId="primaryNA"
      secondId="primaryOther"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_LABEL' | translate
      "
      [secondLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.OTHER_LABEL' | translate
      "
      nameVal="primaryPhone"
      [formControl]="primaryNumberForm.controls.canadaOrUSA"
      [required]="isPrimaryRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          isPrimaryRadioRequired &&
          primaryNumberForm.get('canadaOrUSA')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>

    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="2">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="PrimaryType"
          data-cy="address-form-primary-number"
          [formControl]="primaryNumberForm.controls.type"
          [label]="'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.TYPE_LABEL' | translate"
          [options]="phoneType"
          [widthSm]="true"
          [required]="isPrimaryRadioRequired"
        >
          <span
            *ngIf="
              primaryNumberForm.get('type')?.touched &&
              primaryNumberForm.get('type')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="PrimaryCountryCode"
          [formControl]="primaryNumberForm.controls.countryCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL' | translate
          "
          [widthSm]="true"
          [required]="isPrimaryRadioRequired"
        >
          <span
            *ngIf="
              primaryNumberForm.get('countryCode')?.touched &&
              primaryNumberForm.get('countryCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('countryCode')?.touched &&
              primaryNumberForm.get('countryCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : maxCountryCode }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('countryCode')?.touched &&
              primaryNumberForm.get('countryCode')?.hasError('numbersWithout11')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_NOT_START_11" | translate }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('countryCode')?.touched &&
              primaryNumberForm.get('countryCode')?.hasError('minlength') &&
              !primaryNumberForm
                .get('countryCode')
                ?.hasError('numbersWithout11')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.COUNTRY_CODE_START_WITH_1"
                | translate : maxCountryCode
            }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="3">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="PrimaryNumber"
          [formControl]="primaryNumberForm.controls.number"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL' | translate
          "
          [widthMd]="true"
          [required]="isPrimaryRadioRequired"
        >
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm.get('number')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm.get('number')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max20 }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm.get('number')?.hasError('phoneNumberFormat')
            "
          >
            {{ "INTAKE.FORM_ERRORS.PHONE_NUMBER_FORMAT" | translate }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm.get('number')?.hasError('invalidAreaCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.AREA_CODE_START_WITH_0_OR_1" | translate }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm
                .get('number')
                ?.hasError('invalidSecondGroupAreaCode')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.PHONE_NUMBER_START_WITH_0_OR_1" | translate
            }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('number')?.touched &&
              primaryNumberForm.get('number')?.hasError('numPlusSpace')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_WITH_SPACE" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="PrimaryExtension"
          [formControl]="primaryNumberForm.controls.extension"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              primaryNumberForm.get('extension')?.touched &&
              primaryNumberForm.get('extension')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max5 }}
          </span>
          <span
            *ngIf="
              primaryNumberForm.get('extension')?.touched &&
              primaryNumberForm.get('extension')?.hasError('onlyNumbers')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>

    <button
      [disabled]="isReadOnly"
      (click)="clearPrimaryNumberForm()"
      class="btn btn-primary-alt addressForm__clear-button"
    >
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.CLEAR_BUTTON" | translate }}
    </button>
  </fieldset>

  <fieldset>
    <legend class="visually-hidden">
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.ALTERNATE_LEGEND" | translate }}
    </legend>
    <!-- ALTERNATE PHONE NUMBER -->
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.ALTERNATE_LEGEND' | translate
      "
      firstId="altNA"
      secondId="altOther"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_LABEL' | translate
      "
      [secondLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.OTHER_LABEL' | translate
      "
      nameVal="altPhone"
      [formControl]="alternateNumberForm.controls.canadaOrUSA"
      [required]="isAlternateRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          isAlternateRadioRequired &&
          alternateNumberForm.get('canadaOrUSA')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>

    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="2">
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="AlternateType"
          data-cy="address-form-alternative-number"
          [formControl]="alternateNumberForm.controls.type"
          [label]="'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.TYPE_LABEL' | translate"
          [options]="phoneType"
          [widthSm]="true"
          [required]="isAlternateRadioRequired"
        >
          <span
            *ngIf="
              alternateNumberForm.get('type')?.touched &&
              alternateNumberForm.get('type')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select></lib-layout-col
      >
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="AlternateCountryCode"
          [formControl]="alternateNumberForm.controls.countryCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL' | translate
          "
          [widthSm]="true"
          [required]="isAlternateRadioRequired"
        >
          <span
            *ngIf="
              alternateNumberForm.get('countryCode')?.touched &&
              alternateNumberForm.get('countryCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('countryCode')?.touched &&
              alternateNumberForm.get('countryCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : maxCountryCode }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('countryCode')?.touched &&
              alternateNumberForm
                .get('countryCode')
                ?.hasError('numbersWithout11')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_NOT_START_11" | translate }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('countryCode')?.touched &&
              alternateNumberForm.get('countryCode')?.hasError('minlength') &&
              !alternateNumberForm
                .get('countryCode')
                ?.hasError('numbersWithout11')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.COUNTRY_CODE_START_WITH_1"
                | translate : maxCountryCode
            }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="3">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="AlternateNumber"
          [formControl]="alternateNumberForm.controls.number"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL' | translate
          "
          [widthMd]="true"
          [required]="isAlternateRadioRequired"
        >
          <span
            *ngIf="
              alternateNumberForm.get('number')?.touched &&
              alternateNumberForm.get('number')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('number')?.touched &&
              alternateNumberForm.get('number')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max20 }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('number')?.touched &&
              alternateNumberForm.get('number')?.hasError('phoneNumberFormat')
            "
          >
            {{ "INTAKE.FORM_ERRORS.PHONE_NUMBER_FORMAT" | translate }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('number')?.touched &&
              alternateNumberForm.get('number')?.hasError('numPlusSpace')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_WITH_SPACE" | translate }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('number')?.touched &&
              alternateNumberForm.get('number')?.hasError('invalidAreaCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.AREA_CODE_START_WITH_0_OR_1" | translate }}
          </span>
          <span *ngIf="alternateNumberForm.get('number')?.hasError('matching')">
            {{ "INTAKE.FORM_ERRORS.PRIMARY_PHONE_MATCH_ALTERNATE" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="AlternateExtension"
          [formControl]="alternateNumberForm.controls.extension"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              alternateNumberForm.get('extension')?.touched &&
              alternateNumberForm.get('extension')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max5 }}
          </span>
          <span
            *ngIf="
              alternateNumberForm.get('extension')?.touched &&
              alternateNumberForm.get('extension')?.hasError('onlyNumbers')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>

    <button
      [disabled]="isReadOnly"
      (click)="clearAlternateNumberForm()"
      class="btn btn-primary-alt addressForm__clear-button"
    >
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.CLEAR_BUTTON" | translate }}
    </button>
  </fieldset>

  <fieldset>
    <legend class="visually-hidden">
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.FAX_LEGEND" | translate }}
    </legend>
    <!-- FAX NUMBER -->
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.FAX_LEGEND' | translate"
      firstId="faxNA"
      secondId="faxOther"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_LABEL' | translate
      "
      [secondLabel]="
        'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.OTHER_LABEL' | translate
      "
      nameVal="faxNum"
      [formControl]="faxNumberForm.controls.canadaOrUSA"
      [required]="isFaxRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          isFaxRadioRequired &&
          faxNumberForm.get('canadaOrUSA')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>

    <lib-layout-row gap="one-and-half">
      <lib-layout-col flex="2">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="FaxCountryCode"
          [formControl]="faxNumberForm.controls.countryCode"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.COUNTRY_CODE_LABEL' | translate
          "
          [required]="isFaxRadioRequired"
          [widthSm]="true"
        >
          <span
            *ngIf="
              faxNumberForm.get('countryCode')?.touched &&
              faxNumberForm.get('countryCode')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('countryCode')?.touched &&
              faxNumberForm.get('countryCode')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : maxCountryCode }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('countryCode')?.touched &&
              faxNumberForm.get('countryCode')?.hasError('numbersWithout11')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_NOT_START_11" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('countryCode')?.touched &&
              faxNumberForm.get('countryCode')?.hasError('minlength') &&
              !faxNumberForm.get('countryCode')?.hasError('numbersWithout11')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.COUNTRY_CODE_START_WITH_1"
                | translate : maxCountryCode
            }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="3">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="FaxNumber"
          [formControl]="faxNumberForm.controls.number"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.NUMBER_LABEL' | translate
          "
          [required]="isFaxRadioRequired"
          [widthMd]="true"
        >
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm.get('number')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm.get('number')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm.get('number')?.hasError('phoneNumberFormat')
            "
          >
            {{ "INTAKE.FORM_ERRORS.PHONE_NUMBER_FORMAT" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm.get('number')?.hasError('numPlusSpace')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY_WITH_SPACE" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm.get('number')?.hasError('invalidAreaCode')
            "
          >
            {{ "INTAKE.FORM_ERRORS.AREA_CODE_START_WITH_0_OR_1" | translate }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('number')?.touched &&
              faxNumberForm
                .get('number')
                ?.hasError('invalidSecondGroupAreaCode')
            "
          >
            {{
              "INTAKE.FORM_ERRORS.PHONE_NUMBER_START_WITH_0_OR_1" | translate
            }}
          </span>
          <span *ngIf="faxNumberForm.get('number')?.hasError('matching')">
            {{ "INTAKE.FORM_ERRORS.PRIMARY_PHONE_MATCH_FAX" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
      <lib-layout-col flex="1">
        <lib-form-input
          [editable]="user.can('documents:write')"
          identifier="FaxExtension"
          [formControl]="faxNumberForm.controls.extension"
          [label]="
            'INTAKE.IMM0008.FORMS.ADDRESS.PHONE.EXTENSION_LABEL' | translate
          "
          [widthSm]="true"
        >
          <span
            *ngIf="
              faxNumberForm.get('extension')?.touched &&
              faxNumberForm.get('extension')?.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max5 }}
          </span>
          <span
            *ngIf="
              faxNumberForm.get('extension')?.touched &&
              faxNumberForm.get('extension')?.hasError('onlyNumbers')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
          </span>
        </lib-form-input></lib-layout-col
      >
    </lib-layout-row>

    <button
      type="button"
      [disabled]="isReadOnly"
      (click)="clearFaxNumberForm()"
      class="btn btn-primary-alt addressForm__clear-button"
    >
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.PHONE.CLEAR_BUTTON" | translate }}
    </button>
  </fieldset>

  <fieldset>
    <!-- EMAIL CONTACT? -->
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.ADDRESS.VERIFY_CONTACT.LEGEND' | translate
      "
      firstId="contactYes"
      secondId="contactNo"
      [firstValue]="1"
      [secondValue]="0"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="emailContact"
      [formControl]="emailContactForm.controls.contactUsingEmailAddress"
      [required]="isEmailRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          isPrimaryRadioRequired &&
          primaryNumberForm.get('canadaOrUSA')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <fieldset *ngIf="!emailSameAsFile">
    <!-- EMAIL CONTACT ADDRESS -->
    <legend>
      {{ "INTAKE.IMM0008.FORMS.ADDRESS.CONTACT_EMAIL.LEGEND" | translate }}
    </legend>
    <lib-form-input
      identifier="Email"
      [formControl]="emailContactForm.controls.emailAddress"
      [label]="
        'INTAKE.IMM0008.FORMS.ADDRESS.CONTACT_EMAIL.EMAIL_LABEL' | translate
      "
      [widthMd]="true"
      [required]="!emailSameAsFile"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          emailContactForm.get('emailAddress')?.touched &&
          emailContactForm.get('emailAddress')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          emailContactForm.get('emailAddress')?.touched &&
          emailContactForm.get('emailAddress')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          emailContactForm.get('emailAddress')?.touched &&
          (emailContactForm.get('emailAddress')?.hasError('email') ||
            emailContactForm.get('emailAddress')?.hasError('pipe') ||
            emailContactForm.get('emailAddress')?.hasError('plus')) &&
          emailContactForm.get('emailAddress')?.value?.length <= 100
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_EMAIL" | translate }}
      </span>
    </lib-form-input>
  </fieldset>
</form>
