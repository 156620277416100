import { APP_INITIALIZER, NgModule } from "@angular/core";
/* NgRx & state */
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import {
  AxiosConfigFactory,
  Cloud9TunnelInterceptorService,
} from "./shared/interceptors/cloud9-tunnel.interceptor";
import { BrowserModule, Title } from "@angular/platform-browser";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { HttpLoaderFactory, SharedModule } from "./shared/shared.module";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgIdleStorage, ValidationService } from "lib";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { AddressPageComponent } from "./confirmation-module/address/address-page/address-page.component";
/* components */
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./routing/app-routing.module";
import { ApplicantRenewalsEffects } from "./renewal-module/store/effects/renewals.effects";
import { ApplicantStepsComponent } from "./confirmation-module/confirmation-landing-page/applicant-steps/applicant-steps.component";
import { AuthEffects } from "./core/auth-module/store/auth.effects";
/* feature modules */
import { AuthModule } from "./core/auth-module/auth.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CardComponent } from "./core/card/card.component";
import { CaseModule } from "./shared/case-module/case.module";
/* pages */
import { ConfirmationLandingPageComponent } from "./confirmation-module/confirmation-landing-page/confirmation-landing-page.component";
import { CoprDownloadComponent } from "./confirmation-module/confirmation-landing-page/copr-download/copr-download.component";
import { EffectsModule } from "@ngrx/effects";
import { FooterComponent } from "./core/footer/footer.component";
import { HeaderComponent } from "./core/header/header.component";
import { IntakeModule } from "./intake-module/intake.module";
import { LangGuard } from "../../../lib/src/lib/services/auth-service/lang.guard";
import { LibModule } from "lib";
import { LocalStorage } from "@ng-idle/core";
import { LocalizedAppComponent } from "./core/localized-app/localized-app.component";
import { LogoutComponent } from "./core/auth-module/components/logout/logout.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { NavBarComponent } from "./core/navbar/navbar.component";
import { NavigationGuard } from "./routing/guards/nav.guard";
/* Ng idle */
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { NgIdleLogoutComponent } from "./shared/ng-idle/ng-idle-logout.component";
import { PRConfirmationGuard } from "./routing/guards/pr-confirmation.guard";
import { PRRenewalGuard } from "./routing/guards/pr-renewal.guard";
import { PhotosModule } from "./confirmation-module/photos/photos.module";
import { ReactiveFormsModule } from "@angular/forms";
import { RouteLocalizerService } from "./routing/route-localizer.service";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { TokenInterceptor } from "@pr-applicant/app/core/auth-module/services/token.interceptor";
import { allPrcPrtdReducersReducers } from "./renewal-module/store/reducers";
import { environment } from "../environments/environment";
import { localStorageSync } from "ngrx-store-localstorage";
import { prcPrtdRnewalKey } from "./renewal-module/store/prcPrtdState";
import { reducers } from "./store/app.reducer";
import { StartCasePageComponent } from "./core/pages/start-case/start-case-page.component";
/* DS Demo */
import { DesignSystemDocComponent } from "./core/pages/design-system-doc/design-system-doc.component";

export function sessionStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["auth", "casesCount", "profile"],
    rehydrate: true,
    removeOnUndefined: true,
    storage: sessionStorage,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [sessionStorageSyncReducer];

@NgModule({
  declarations: [
    AddressPageComponent,
    AppComponent,
    ApplicantStepsComponent,
    CardComponent,
    ConfirmationLandingPageComponent,
    CoprDownloadComponent,
    FooterComponent,
    HeaderComponent,
    StartCasePageComponent,
    LocalizedAppComponent,
    LogoutComponent,
    NgIdleLogoutComponent,
    NavBarComponent,
    DesignSystemDocComponent,
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    CaseModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    EffectsModule.forRoot([AuthEffects]),
    LibModule,
    IntakeModule,
    HttpClientModule,
    PhotosModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature(prcPrtdRnewalKey, allPrcPrtdReducersReducers),
    EffectsModule.forFeature([ApplicantRenewalsEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    SharedModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    LoggerModule.forRoot(environment.configLog),
  ],
  providers: [
    LangGuard,
    NavigationGuard,
    PRConfirmationGuard,
    PRRenewalGuard,
    RouteLocalizerService,
    Title,
    ValidationService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LocalStorage, useClass: NgIdleStorage },
    {
      provide: APP_INITIALIZER,
      useFactory: AxiosConfigFactory,
      deps: [Cloud9TunnelInterceptorService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
