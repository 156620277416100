<form class="search-form form-control" (ngSubmit)="onSubmit()">
  <fieldset>
    <lib-layout-row gap="one-and-half">
      <lib-form-input
        identifier="search"
        [formControl]="searchField"
        [label]="copyKey + '_PLACEHOLDER' | translate"
        class="search-form__input"
        [eraseMarginBottom]="true"
        [allowOverflow]="true"
      ></lib-form-input>
      <button type="submit" class="search-submit">
        {{ copyKey | translate }}
      </button>
      <button
        type="button"
        class="search-clear"
        (click)="onClear()"
        category="plain"
      >
        {{ "INTAKE.DASHBOARD.APPLICATIONS_PAGE.CLEAR" | translate }}
      </button>
    </lib-layout-row>
  </fieldset>
</form>
