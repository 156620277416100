<!-- Site Footer -->
<div class="footer-dark jds-escape">
  <ul class="footer-dark__list">
    <li
      class="footer-dark__list__item"
      *ngFor="let link of 'FOOTER.DARK_LINKS' | translate"
    >
      <pra-external-link
        [href]="link.URL"
        class="footer-dark__list__item__link"
      >
        {{ link.LABEL }}
      </pra-external-link>
    </li>
  </ul>
</div>

<div class="footer-light">
  <div class="footer-light__container">
    <ul class="footer-light__container__list">
      <li
        class="footer-light__container__list__item"
        *ngFor="let link of 'FOOTER.LIGHT_LINKS' | translate"
      >
        <pra-external-link
          [href]="link.URL"
          target="_blank"
          class="footer-light__container__list__item__link"
        >
          {{ link.LABEL }}
        </pra-external-link>
      </li>
    </ul>

    <div class="footer-light__container__footnote">
      <button
        category="plain"
        size="small"
        (click)="scrollToTop($event)"
        class="footer-light__container__footnote__link"
      >
        {{ "FOOTER.TOP" | translate }}
        <img
          src="assets/images/icons/chevron-up.svg"
          class="footer-light__container__footnote__link__chevron"
          alt=""
        />
      </button>

      <a [href]="'HEADER.LOGO.URL' | translate" target="_blank">
        <img
          [src]="'FOOTER.LOGO.URL' | translate"
          class="footer-light__container__footnote__logo"
          [alt]="'FOOTER.LOGO.LABEL' | translate"
        />
        <span class="visually-hidden">{{ "EXTERNAL_LINK" | translate }}</span>
      </a>
    </div>
  </div>
</div>
