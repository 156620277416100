<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_E.FORM_HEADING' | translate"
></lib-copy-block>
<p>
  {{ "INTAKE.IMM5669.FORMS.SECTION_E.SUMMARY_LEGEND" | translate }}
</p>
<form autocomplete="off">
  <ng-container
    *ngFor="
      let membership of memberships?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="memberships"
  >
    <fieldset [formGroup]="membership" class="form-array__fieldset">
      <legend>
        {{ "INTAKE.IMM5669.FORMS.SECTION_E.CURRENT_MEMBERSHIP" | translate }}
        {{ i + 1 }}
      </legend>
      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'from' + i"
        [formControl]="membership.controls.from"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>
      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'to' + i"
        [formControl]="membership.controls.to"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'nameOfOrganization' + i"
        [formControl]="membership.controls.nameOfOrganization"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_NAME' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            membership.get('nameOfOrganization')?.touched &&
            membership.get('nameOfOrganization')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.nameOfOrganization }
          }}
        </span>
      </lib-form-input>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'typeOfOrganization' + i"
        [formControl]="membership.controls.typeOfOrganization"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_TYPE' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            membership.get('typeOfOrganization')?.touched &&
            membership.get('typeOfOrganization')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.typeOfOrganization }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'activities' + i"
        [formControl]="membership.controls.activities"
        [label]="
          'INTAKE.IMM5669.FORMS.SECTION_E.ORGANIZATION_ACTIVITIES' | translate
        "
        widthMd="true"
      >
        <span
          *ngIf="
            membership.get('activities')?.touched &&
            membership.get('activities')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.activities }
          }}
        </span>
      </lib-form-input>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'cityAndCountry' + i"
        [formControl]="membership.controls.cityAndCountry"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_E.CITY_AND_COUNTRY' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            membership.get('cityAndCountry')?.touched &&
            membership.get('cityAndCountry')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.cityAndCountry }
          }}
        </span>
      </lib-form-input>
      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeMembership(i)"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.REMOVE" | translate }}
        </button>

        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addMembership()"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.ADD" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
