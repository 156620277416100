import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { User } from "../../auth-module/store/auth.reducer";
import { userSelector } from "../../auth-module/store/authSelectors";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { takeWhile } from "rxjs/operators";
import { AppPrcPrtdState } from "@pr-applicant/app/renewal-module/store/prcPrtdState";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { NewRenewalCase } from "@pr-applicant/app/shared/case-module/case.model";
import { AlertService, ValidationService, casePhases } from "lib";
import {
  PRC_PRTD_SITUATIONS_CODES,
  INSIDE_OUTSIDE_CANADA_SITUATION_CODES,
  PRA_PRC_PRTD_SITUATION_OPTIONS,
  INSIDE_OUTSIDE_CANADA_OPTIONS,
} from "@pr-applicant/app/renewal-module/pages/models/prcPrtdModels";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { NGXLogger } from "ngx-logger";

type applicationType = "PRC" | "PRTD";

@Component({
  selector: "pra-start-case-page",
  templateUrl: "./start-case-page.component.html",
  styleUrls: ["./start-case-page.component.scss"],
})
export class StartCasePageComponent implements OnInit, OnDestroy {
  public prcPrtdSituationCode = PRC_PRTD_SITUATIONS_CODES;
  public insideOutsideCanadaSituationCodes =
    INSIDE_OUTSIDE_CANADA_SITUATION_CODES;
  public prcPrtdSituationOptions = PRA_PRC_PRTD_SITUATION_OPTIONS;
  public insideOutsideCanadaOptions = INSIDE_OUTSIDE_CANADA_OPTIONS;
  public prcPrtdSituationForm: FormGroup;
  public isSubmitting = false;
  public isClient = true;
  public lang: string;
  public user: User;
  public alive = true;
  private allowToCreatePRC = true;
  private allowToCreatePRTD = true;

  constructor(
    private alertService: AlertService,
    private caseService: CaseService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private store: Store<AppPrcPrtdState>,
    private userService: UserService,
    private logger: NGXLogger,
    private validationService: ValidationService,
    public routeLocalizer: RouteLocalizerService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle(
      "PRCARDPRTD.RENEWAL_LANDINGPAGE.TITLE"
    );
    this.isClient = this.userService.isClientAccount();
    if (this.isClient) {
      this.caseService
        .getApplicationsByPhaseId(casePhases.renewals.id, 1000)
        .subscribe((data) => {
          this.logger.log("Phase 3 documents for users are:", data);
          if (data.count > 0) {
            if (data.list) {
              // check for existing PRC
              if (
                data.list.find(
                  (prcCase) =>
                    prcCase.lobId === 29 && prcCase.caseStatusId === 15
                )
              ) {
                this.allowToCreatePRC = false;
              }
              // check for existing PRTD
              if (
                data.list.find(
                  (prcCase) =>
                    prcCase.lobId === 30 && prcCase.caseStatusId === 15
                )
              ) {
                this.allowToCreatePRTD = false;
              }
            }
          }
        });
    }
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.prcPrtdSituationForm = this.fb.group({
      applicationType: [null, Validators.required],
      situation: [null, Validators.required],
      situationDisclaimer: [null],
    });
    this.store
      .select(userSelector)
      .pipe(takeWhile(() => this.alive))
      .subscribe((user) => {
        user ? (this.user = _.cloneDeep(user)) : "";
      });
  }

  resetDeclarationCheckbox() {
    this.prcPrtdSituationForm.controls.situationDisclaimer.reset();
  }
  resetApplicationTypeDropDown() {
    this.prcPrtdSituationForm.controls.applicationType.reset();
    this.resetDeclarationCheckbox();
  }

  public applyForPRCard(): void {
    if (this.allowToCreatePRC) {
      this.logger.log("Aplly for PR Card is triggered");
      this.createNewCase("PRC");
    } else {
      this.alertService.danger(
        this.translate.instant("RENEWAL.APPLICATION_ALREADY_CREATED")
      );
    }
  }

  public applyForTravelDocuments(): void {
    if (this.allowToCreatePRTD) {
      this.logger.log("Aplly for PRTD is triggered");
      this.createNewCase("PRTD");
    } else {
      this.alertService.danger(
        this.translate.instant("RENEWAL.APPLICATION_ALREADY_CREATED")
      );
    }
  }

  public createNewCase(appType: applicationType): void {
    this.isSubmitting = true;
    window.scroll(0, 0);
    const infoForCase = this.getInfoForNewRenewalCase(appType);
    this.caseService.createRenewalCase(infoForCase).subscribe(
      (res) => {
        this.alertService.success(this.alertSuccessfulSubmit);
        this.isSubmitting = false;
        if (appType === "PRC") {
          const nextPage =
            this.routeLocalizer.getNestedPath(
              this.lang,
              ["RENEWAL", "RENEWAL_PERMANENT_RESIDENT_CARD"],
              "../../"
            ) + res.id;
          this.router.navigate([nextPage], {
            relativeTo: this.route,
          });
        } else {
          const nextPage =
            this.routeLocalizer.getNestedPath(
              this.lang,
              ["RENEWAL", "RENEWAL_TRAVEL_DOCUMENTS"],
              "../../"
            ) + res.id;
          this.router.navigate([nextPage], {
            relativeTo: this.route,
          });
        }
      },
      (error) => {
        this.logger.error("Unable to create new PRC/PRT by client", error);
        this.alertService.danger(this.alertTechnicalError);
        this.isSubmitting = false;
      }
    );
  }

  private getInfoForNewRenewalCase(app: applicationType): NewRenewalCase {
    // 29 = PRC  , 30 = PRTD
    let lobId = 29;
    const phaseId = 3;
    if (app === "PRTD") {
      lobId = 30;
    }
    return {
      emailAddress: this.user.username ? this.user.username : "",
      lobId: lobId,
      phaseId,
    };
  }

  public applyToRenounceForPrStatus(): void {
    if (this.lang == "fr") {
      window.location.href =
        "https://www.canada.ca/fr/immigration-refugies-citoyennete/services/demande/formulaires-demande-guides/demande-renonciation-volontaire-statut-resident-permanent.html";
    } else {
      window.location.href =
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/application-voluntarily-renounce-permanent-resident-status.html";
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant(
      "INTAKE.PROGRAM_SELECTION_FORM.ALERTS.TECHNICAL_ERROR"
    );
  }
  private get alertSuccessfulSubmit(): string {
    return this.translate.instant(
      "RENEWAL.NEWAPPLICATION.ALERTS.CREATE_SUCCESS"
    );
  }

  private get alertSuccessfulUpdate(): string {
    return this.translate.instant(
      "INTAKE.PROGRAM_SELECTION_FORM.ALERTS.UPDATE_SUCCESS"
    );
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
