import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State as AuthState } from "./auth.reducer";
import * as fromApp from "../../../store/app.reducer";

export const authFeature = createFeatureSelector<fromApp.State, AuthState>(
  "auth"
);
export const userSelector = createSelector(
  authFeature,
  (state: AuthState) => state.user
);
