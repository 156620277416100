import { Component, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";

@Component({
  selector: "pra-imm0008-consent-declaration-form",
  templateUrl: "./consent-declaration-form.component.html",
  styleUrls: ["./consent-declaration-form.component.scss"],
})
export class IMM0008ConsentDeclarationFormComponent implements OnInit {
  public lang: string;

  constructor(public routeLocalizer: RouteLocalizerService) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }
}
