import { Action, createReducer, on } from "@ngrx/store";
import { CurrentImmDocActions } from "./actions";

export interface CurrentIMMDocumentState {
  caseId: number; // Related Case ID
  documentName: string; // The ID or the array of forms
  documentTypeId: string;
  currentForm: any;
  forms: Array<any>; // This will always start as a blank array
  id: string; // documentId
  declaration?: string; // applicant's declaration
  isComplete: boolean;
}

const INITIAL_CURRENT_IMM_DOCUMENT = {} as CurrentIMMDocumentState;

const reduceForProducts = createReducer(
  INITIAL_CURRENT_IMM_DOCUMENT,
  on(
    CurrentImmDocActions.createNewCurrentIMMDocument,
    (crImmDocState: CurrentIMMDocumentState, action) => {
      return Object.assign({ ...action.newDocument });
    }
  ),

  on(
    CurrentImmDocActions.loadCurrentIMMDocument,
    (crImmDocState: CurrentIMMDocumentState, action) => {
      return Object.assign({ ...action.documentInfo });
    }
  ),

  // below one to be corrected
  on(
    CurrentImmDocActions.updateCurrentIMMDocument,
    (crImmDocState: CurrentIMMDocumentState, action) => {
      return Object.assign({ ...crImmDocState });
    }
  ),

  // loadCurrentFormForIMMDocument
  on(
    CurrentImmDocActions.loadCurrentFormForIMMDocument,
    (crImmDocState: CurrentIMMDocumentState, action) => {
      return Object.assign({
        ...crImmDocState,
        currentForm: action.currentForm,
      });
    }
  )
);

export function currentIMMReducer(
  currentImMMDocState: CurrentIMMDocumentState | undefined,
  currentIMMDocAction: Action
) {
  return reduceForProducts(currentImMMDocState, currentIMMDocAction);
}
