import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as fromApp from "../../../store/app.reducer";
import { caseStatuses } from "lib";
import * as CaseActions from "../../../shared/case-module/store/case.actions";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import * as _ from "lodash";
import {
  from,
  Observable,
  of,
  Subject,
  BehaviorSubject,
  throwError,
} from "rxjs";
import {
  catchError,
  delay,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
} from "rxjs/operators";
import crypto from "crypto";
import { RawDocument } from "../../../core/models/document.model";
import {
  AwsSdkService,
  S3UploadResponse,
} from "@pr-applicant/app/shared/services/aws-sdk/aws-sdk.service";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { AdditionalDocForFormSelection } from "@pr-applicant/app/renewal-module/pages/permanent-resident-card/permanentResidentCardModels";

export interface responseUpload {
  response: HttpResponse<any>;
  s3Key: string;
}

const apiVersion = environment.API_VERSION;

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  caseId: string;
  apiName = environment.API_NAME;
  private apiVersion = environment.API_VERSION;
  private _isCompleteSubj: Subject<any> = new Subject();
  private _isCompleteObs$: Observable<RawDocument> =
    this._isCompleteSubj.asObservable();

  constructor(
    private store: Store<fromApp.State>,
    private awsService: AWSService,
    private httpClient: HttpClient,
    private awsSdkService: AwsSdkService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {
    this.store.select("selectedCase").subscribe((caseData) => {
      if (caseData.id) {
        this.caseId = caseData.id;
      }
    });
  }

  private _requiredPOPSubj: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private _requiredPOPObs$: Observable<boolean> =
    this._requiredPOPSubj.asObservable();

  public get requiredPOP() {
    return this._requiredPOPObs$;
  }

  public setRequiredPOP(required: boolean) {
    this._requiredPOPSubj.next(required);
  }

  public get isCompleteObs$() {
    return this._isCompleteObs$;
  }

  public async getDocumentByCaseId(caseId?: any): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };

    try {
      const applicantDocuments = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      this.store.dispatch(
        new CaseActions.SetDocumentByCaseId({
          caseDocuments: applicantDocuments.data,
        })
      );
      return applicantDocuments.data;
    } catch (error) {
      throw error;
    }
  }
  // phase 3 documents
  public async getRenewalDocumentByCaseId(caseId?: any): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };

    try {
      const applicantDocuments = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      return applicantDocuments.data;
    } catch (error) {
      throw error;
    }
  }

  public async getDocumentRejectionReasonsByDocumentId(
    caseId: string,
    documentId: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}/rejection-reasons`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  // This method is to be used to get an only file by document Type
  // where there is always one files to be uploaded for a selected document Type
  // If you are looking for a file by id for a document type, use getFileByDocumentTypeAndId
  // TO DO: Combine with next function
  public getFileByDocumentType(documentType: number, documents: any): any {
    let file = null;
    const fileOnly = documents?.filter(
      (doc: any) => doc.documentTypeId === documentType
    );

    if (fileOnly?.length > 0) {
      file = fileOnly[0];
    }
    return file;
  }

  // This method is to be used to get all the files by document type
  // If you are looking for only 1 file for a document type, use getFileByDocumentType
  public getFilesByDocumentType(documentType: number, documents: any): any {
    const files = documents?.filter(
      (doc: any) => doc.documentTypeId === documentType
    );

    return files;
  }

  // Use this method if you want to get a file by documentType and documentId when ID exists and this works
  // Best when you know the document type can have multiple files to upload
  public getFileByDocumentTypeAndId(
    documentType: number,
    documents: any,
    documentId: number
  ): any {
    let file = null;
    const fileOnly = documents?.filter(
      (doc: any) => doc.id === documentId && doc.documentTypeId === documentType
    );

    if (fileOnly?.length > 0) {
      file = fileOnly[0];
    }
    return file;
  }

  /*
   * for checking the status on forms and pdf documents
   * grabs the documentApproved status from the document/forms and returns either ready to submit, submitted, incomplete, complete
   */
  public checkDocumentOrFormStatus(
    existingFile: any,
    caseStatusId: number
  ): string {
    if (existingFile) {
      switch (existingFile.documentApproved) {
        // If the document is not approved (rejected), then set the status as incomplete
        case false:
          // see IPI-2373 along with IPI-2927 for correction in line bellow
          return existingFile.isComplete || _.isNull(existingFile.isComplete)
            ? "incomplete"
            : "";
        // If the document is approved, then set the status as complete
        case true:
          return existingFile.isComplete || _.isNull(existingFile.isComplete)
            ? "complete"
            : "";
        // If the document hasn't been reviewed, then check to see if it's submitted
        // Otherwise, check to see if the document is complete or is an uploaded PDF file, then set the status as ready
        // Otherwise, don't set a status (blank)
        case null: {
          // eslint-disable-next-line eqeqeq
          let status = "";
          if (caseStatusId === caseStatuses.intake.submitted.id) {
            status = "submitted";
          } else if (
            existingFile.isComplete == 1 ||
            existingFile.documentName.split("/")[1]
          ) {
            status = "ready";
          } else if (
            (existingFile.isComplete === false &&
              existingFile.documentTypeID !== 41) ||
            (existingFile.isComplete === null &&
              existingFile.documentTypeId == 41)
          ) {
            status = "initiated";
          }
          return status;
        }
        default:
          return "";
      }
    }
    return "";
  }

  // This method is to be used if you want to upload a file and DON'T replace existing
  // Upload a new file if previous files already exist. Create new file on each upload
  public async uploadNewFileByDocumentTypeId(
    caseId: any,
    fileSelected: any,
    documentTypeId: number,
    caseMemberId?: number
  ): Promise<any> {
    // preparing file name and file type so that we can upload it into the s3 bucket
    const timestamp = new Date().getTime(); // putting the files in unique folders with a timestamp
    const fileName = caseMemberId
      ? `${caseId}/${caseMemberId}/${timestamp}/${fileSelected.name}`
      : `${caseId}/${timestamp}/${fileSelected.name}`;
    return this.createFileChecksum(fileSelected)
      .pipe(
        switchMap((hash: any) => {
          return this.uploadFile(
            caseId,
            fileSelected,
            fileName,
            hash,
            caseMemberId
          );
        }),
        switchMap((status: any) => {
          if (status.response.status === 200) {
            if (status.s3Key) {
              return this.updateDocuments(
                caseId,
                documentTypeId,
                status.s3Key,
                caseMemberId
              );
            }
            throw new Error("Could not reach S3 endpoint");
          } else {
            throw new Error("Could not reach S3 endpoint");
          }
        }),
        retry({ count: 1, delay: 500 })
      )
      .toPromise();
  }
  private uploadFile(
    caseId: number,
    file: File,
    fileName: string,
    hash: string,
    caseMemberId?: number
  ) {
    const presignedPutURL$ = from(
      this.getS3PresignedPutURL(caseId, fileName, hash, caseMemberId)
    );
    const headers = new HttpHeaders({
      "content-type": file.type,
      "x-amz-checksum-sha256": hash,
    });
    const formData = new FormData();
    formData.append("file", file);

    return presignedPutURL$.pipe(
      switchMap((presignedPutUrl: any) => {
        let s3Documentkey = presignedPutUrl.data.key;
        return this.httpClient
          .put(presignedPutUrl.data.url, file, {
            headers,
            observe: "response",
          })
          .pipe(
            map((response) => {
              return { response: response, s3Key: s3Documentkey };
            })
          );
      })
    );
  }
  private async getS3PresignedPutURL(
    caseId: number,
    fileName: string,
    hash: string,
    caseMemberId?: number
  ) {
    const fileNameUriEncoded = encodeURIComponent(fileName);
    const hashUriEncoded = encodeURIComponent(hash);
    const path = `/v2/cases/${caseId}/filename/${fileNameUriEncoded}/hash/${hashUriEncoded}/upload`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
      queryStringParameters: {},
    };
    if (caseMemberId) init.queryStringParameters = { caseMemberId };
    return this.awsService.api.get(this.apiName, path, init);
  }

  private updateDocuments(
    caseId: number,
    documentTypeId: number,
    s3Documentkey: string,
    caseMemberId?: number
  ) {
    const token$ = from(this.awsService.getToken());
    return token$.pipe(
      switchMap((token) => {
        if (!!s3Documentkey && s3Documentkey !== "") {
          const pathPost = `/${apiVersion}/cases/${caseId}/documents/`;
          const init = {
            headers: { Authorization: `${token}` },
            body: {
              documentName: s3Documentkey,
              documentTypeId,
              caseMemberId,
            },
          };
          return from(this.awsService.api.post(this.apiName, pathPost, init));
        } else {
          throw new Error("Document upload failed");
        }
      })
    );
  }

  /*
    for deleting pdf form documents and supporting documents
  */
  public async deleteFileByDocumentTypeAndId(
    caseId: any,
    documentTypeId: number,
    documentId: number
  ): Promise<any> {
    try {
      const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}`;
      const init = {
        headers: { Authorization: await this.awsService.getToken() },
      };
      return await this.awsService.api.del(this.apiName, path, init);
    } catch (error) {
      throw error;
    }
  }

  /*
    Gets the entire form if needed (TBD)
    - Pass the caseId and documentId (no need to pass the documentTypeId)
  */
  public async getFormByDocumentId(
    caseId: string,
    documentId: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
    Gets a specific section or page from the form
    - Pass the caseId and documentId (no need to pass the documentTypeId)
    - Pass the page as a number, etc 0,1,2 or as the section name ex. 'passportDetails'

    - Returns an object
    To access the values... response.form
    to access the lists for drop downs... response.lists, ex. response.lists.city
  */
  public async getFormByDocumentIdAndPage(
    caseId: string,
    documentId: number,
    page: string | number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
    Gets a specific section or page from the form
    - Pass the caseId and documentId (no need to pass the documentTypeId)
    - Pass the section as the section name ex. 'passportDetails'

    - Returns an object
    To access the values... response.form
    to access the lists for drop downs... response.lists, ex. response.lists.city
  */
  public async getFormByDocumentIdAndSection(
    caseId: string,
    documentId: number,
    section: string | number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?section=${section}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
    Updates a specific section or page from the form
    - Pass the caseId and documentId (no need to pass the documentTypeId)
    - Pass the page as a number, etc 0, 1, 2 or as the section name ex. 'passportDetails'
    - Pass the formData, an object with the form values, to the body of the request - similar to the below example
      formData = {
        familyMembers: 1,
        correspondence: '01',
        interview: '012',
        interpreterRequested: true,
        province: '01',
        city: '1210',
        receivedCSQ: null,
        csqNumber: null,
        dateAppliedForCSQ: null,
      }
  */
  public async updateFormByDocumentIdAndPage(
    caseId: string,
    documentId: number,
    page: string | number,
    formData: any,
    version: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        form: {
          ...formData,
          version: version,
        },
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return { error, hasApiError: true };
    }
  }

  /*
    Updates a specific section or page from the form
    - Pass the caseId and documentId (no need to pass the documentTypeId)
    - Pass the page as a number, etc 0, 1, 2 or as the section name ex. 'passportDetails'
    - Pass the formData, an object with the form values, to the body of the request - similar to the below example
      formData = {
        familyMembers: 1,
        correspondence: '01',
        interview: '012',
        interpreterRequested: true,
        province: '01',
        city: '1210',
        receivedCSQ: null,
        csqNumber: null,
        dateAppliedForCSQ: null,
      }
  */
  public async updateFormByDocumentIdAndSection(
    caseId: string,
    documentId: number,
    section: string | number,
    formData: any
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?section=${section}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        form: {
          ...formData,
        },
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return { error, hasApiError: true };
    }
  }

  // This method is to add and create a new dependant to a form
  public async addDependantByDocumentIdAndPage(
    caseId: string | number,
    documentId: number,
    page: string | number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.post(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
     Gets the form data in the dependant page
     - Pass the caseId, documentId, and dependantId
     - Pass the page name 'dependantDetails'

     Returns an object
     -To access the values... response.form.<formName>
   */
  public async getDependantFormByDocumentIdAndPage(
    caseId: string,
    documentId: number,
    page: string | number,
    dependantId: string | number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}&dependantId=${dependantId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  /*
     Updates the forms in the dependant page
     - Pass the caseId, documentId, and dependantId
     - Pass the page name 'dependantDetails'
     - Pass the formData, an object with the form values, to the body of the request - similar to the below example
       formData = {
         personalDetails,
         languageDetails,
         nationalIdentityDetails,
         educationOccupationDetails,
         passportDetails,
       }
   */
  public async updateDependantFormByDocumentIdAndPage(
    caseId: string,
    documentId: number,
    page: string | number,
    dependantId: string | number,
    formData: any,
    version: number
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}&dependantId=${dependantId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {
        form: {
          ...formData,
          version: version,
        },
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response.data;
    } catch (error) {
      console.log("depError:", error);
      return { error, hasApiError: true };
    }
  }
  // This method is to delete a dependant from a form
  /* eslint-disable max-len */
  public async deleteFormDependantByDocumentIdAndPage(
    caseId: string | number,
    documentId: number,
    page: string | number,
    dependantId: string
  ): Promise<any> {
    /* eslint-disable max-len */
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?page=${page}&dependantId=${dependantId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.del(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async downloadFileFromPresignedUrl(
    caseId: string,
    documentId: number,
    documentName?: string
  ): Promise<any> {
    let file;
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}/read-only`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      if (response.data.url) {
        // get and download the file pdf
        file = this.httpClient
          .get(response.data.url, { responseType: "blob" as "json" })
          .subscribe((res: any) => {
            const url = URL.createObjectURL(res);
            const a = document.createElement("a");
            a.href = url;
            a.download = documentName || "download";
            const clickHandler = () => {
              setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener("click", clickHandler);
              }, 150);
            };
            a.addEventListener("click", clickHandler, false);
            a.click();
          });
      }
      return response.data.url;
    } catch (error) {
      return error;
    }
  }

  public async getDropdownData(
    documentTypeId: number,
    page?: string
  ): Promise<any> {
    let path = `/${apiVersion}/lists/${documentTypeId}`;
    if (page) {
      path = path.concat("?page=", page);
    }
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async fetchSupplementalDocumentTypes(phaseId: number): Promise<any[]> {
    const apiName = environment.API_NAME;
    const path = `/${this.apiVersion}/document-types?phaseId=${phaseId}&isSupplemental=true`;

    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(apiName, path, init);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getAditionalDocumentsTypes(
    lobId: number
  ): Observable<AdditionalDocForFormSelection[]> {
    const path = `/${this.apiVersion}/document-types?lobId=${lobId}`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        return data.data.map(
          (docType: {
            id: any;
            documentTypeEn: any;
            documentTypeFr: any;
            isUrgent: boolean;
            isSupplemental: boolean;
          }) => {
            return {
              value: docType.id,
              text: { en: docType.documentTypeEn, fr: docType.documentTypeFr },
              isUrgent: docType.isUrgent,
              isSupplemental: docType.isSupplemental,
            };
          }
        );
      }),
      retry(2)
    );
  }

  createFileChecksum(file: any): Observable<string> {
    return of("true").pipe(
      switchMap(() => from(new Response(file).arrayBuffer())),
      switchMap((fileBuffer) => {
        return of(
          crypto
            .createHash("sha256")
            .update(new Uint8Array(fileBuffer))
            .digest("base64")
        );
      })
    );
  }

  /**
   *  updates isComplete column of the postgres documents table
   *
   * @param caseId
   * @param documentId
   * @param isComplete update isComplete column for IMM5406
   */

  updateDocumentIsComplete(
    caseId: number,
    documentId: number,
    isComplete: boolean
  ) {
    let path = `/${apiVersion}/cases/${caseId}/documents/${documentId}?isComplete=${isComplete}`;
    return from(this.awsService.getToken())
      .pipe(
        mergeMap((val: Promise<any>) => {
          const init = {
            headers: {
              Authorization: val,
            },
          };
          return from(this.awsService.api.put(this.apiName, path, init));
        }),
        tap((data) => {
          this._isCompleteSubj.next(data);
        }),
        catchError((error) => {
          console.log("update isComplete error:", error);
          this.alertService.danger(
            this.translate.instant(this.alertTechnicalError)
          );
          return this._isCompleteSubj;
        })
      )
      .subscribe();
  }
  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public IMM5476 = {
    id: 31,
    documentTypeEn: "IMM5476",
    documentTypeFr: "IMM5476",
    documentLocation: "s3",
    phaseId: 1,
    formId: 17,
    isSupplemental: false,
    createdAt: "2021-02-19 22:44:04",
    updatedAt: "2021-02-19 22:44:04",
    isUrgent: null,
    isRequired: true,
    specialProgramId: null,
  };
}
