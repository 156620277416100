import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@pr-applicant/environments/environment";
import awsconfig from "../../../../../../../config/aws-exports";
import { RepAccountInfo } from "@pr-applicant/app/core/models/rep-account-info";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { Observable, from, of } from "rxjs";
import { delay, map, mergeMap, retry, switchMap } from "rxjs/operators";
import { DocumentService } from "../document/document.service";
import {
  AwsSdkService,
  S3UploadResponse,
} from "@pr-applicant/app/shared/services/aws-sdk/aws-sdk.service";

const apiVersion = environment.API_VERSION;

@Injectable({
  providedIn: "root",
})
export class RepService {
  private baseUrl = `${awsconfig.aws_cloud_logic_custom[0].endpoint}/${apiVersion}/representatives`;

  constructor(
    private httpClient: HttpClient,
    private awsService: AWSService,
    private documentService: DocumentService,
    private awsSdkService: AwsSdkService
  ) {}

  getAccountDetails(id?: string): Observable<RepAccountInfo> {
    const url = id ? `${this.baseUrl}/${id}` : this.baseUrl;
    return this.httpClient.get(url) as Observable<RepAccountInfo>;
  }

  createAccount(repAccountInfo: RepAccountInfo): Observable<RepAccountInfo> {
    return from(this.awsService.getIdentityId()).pipe(
      mergeMap((id) => {
        return this.httpClient.post(this.baseUrl, {
          ...repAccountInfo,
          identityId: id,
          consentTimestamp: new Date().toISOString(),
        }) as Observable<RepAccountInfo>;
      })
    );
  }

  updateAccountDetails(id: string, repAccountInfo: RepAccountInfo) {
    const url = `${this.baseUrl}/${id}`;
    return this.httpClient.put(url, {
      ...repAccountInfo,
      consentTimestamp: new Date().toISOString(),
    }) as Observable<RepAccountInfo>;
  }

  uploadIdentification(fileSelected: any): Observable<string> {
    const fileName = fileSelected.name;
    const fileType = fileSelected.type;
    let identityId: any;

    return this.documentService.createFileChecksum(fileSelected).pipe(
      switchMap((hash) =>
        this.awsSdkService.uploadFileToS3(
          fileName,
          fileSelected,
          fileType,
          hash
        )
      ),
      map((res) => {
        const uploadResponse = res as S3UploadResponse;
        return `${uploadResponse.identityId}/${uploadResponse.uploadedFileName}`;
      }),
      retry(2),
      delay(200)
    );
  }
}
