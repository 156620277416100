<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_H.FORM_HEADING' | translate"
  [body]="'INTAKE.IMM5669.FORMS.SECTION_H.FORM_BODY' | translate"
></lib-copy-block>

<form autocomplete="off">
  <ng-container
    *ngFor="
      let address of addresses?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="addresses"
  >
    <fieldset [formGroup]="address" class="form-array_fieldset">
      <legend>
        {{ "INTAKE.IMM5669.FORMS.SECTION_H.ADDRESS" | translate }} {{ i + 1 }}
      </legend>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'from' + i"
        [formControl]="address.controls.from"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>
      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'to' + i"
        [formControl]="address.controls.to"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'street' + i"
        [formControl]="address.controls.street"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_H.STREET' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            address.get('street')?.touched &&
            address.get('street')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.street }
          }}
        </span>
      </lib-form-input>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'city' + i"
        [formControl]="address.controls.city"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_H.CITY' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            address.get('city')?.touched &&
            address.get('city')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.city }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'provinceOrState' + i"
        [formControl]="address.controls.provinceOrState"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_H.PROVINCE' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            address.get('provinceOrState')?.touched &&
            address.get('provinceOrState')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.provinceOrState }
          }}
        </span>
      </lib-form-input>
      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'country' + i"
        [formControl]="address.controls.country"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_H.COUNTRY' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            address.get('country')?.touched &&
            address.get('country')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.country }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'postalCode' + i"
        [formControl]="address.controls.postalCode"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_H.POSTAL_CODE' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            address.get('postalCode')?.touched &&
            address.get('postalCode')?.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: formMaxLength.postalCode }
          }}
        </span>
      </lib-form-input>

      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeAddress(i)"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.REMOVE" | translate }}
        </button>
        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addAddress()"
        >
          {{ "INTAKE.IMM5669.FORMS.FIELDS.BUTTONS.ADD" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
