import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";

@Injectable({
  providedIn: "root",
})
export class RepAccountDetailsGuard implements CanActivate {
  private repId?: string;
  private membershipId?: string;

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private user: UserService,
    private store: Store<fromApp.State>
  ) {
    this.store.select("auth").subscribe((auth) => {
      this.repId = auth.user?.repId;
      this.membershipId = auth.user?.membershipId;
    });
  }

  canActivate(a: ActivatedRouteSnapshot, r: RouterStateSnapshot): boolean {
    const isClient = this.user.isClientAccount();
    if (isClient) {
      return true;
    }
    if (!this.repId || !this.membershipId) {
      this.routeLocalizer.goTo(
        this.routeLocalizer.getCurrentRouteLang(),
        "CREATE_REP_ACCOUNT"
      );
      return false;
    }
    return true;
  }
}
