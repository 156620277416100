<form
  [formGroup]="nationalIdentityForm"
  class="national-identity-form"
  autocomplete="off"
>
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_CHECK.LEGEND'
          | translate
      "
      firstId="NICYes"
      secondId="NICNo"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="national-identity-check"
      [formControl]="nationalIdentityForm.controls.nationalIdentityDocument"
      [required]="isNationalIdentityRadioRequired"
      (click)="setTouched()"
    >
      <span
        class="error-radio"
        *ngIf="
          nationalIdentityForm.get('nationalIdentityDocument')?.touched &&
          nationalIdentityForm
            .get('nationalIdentityDocument')
            ?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <fieldset *ngIf="showNationalIdentityForm">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LEGEND"
          | translate
      }}
    </legend>

    <!-- National Identity Number -->
    <lib-form-input
      identifier="nationalIdentityNumber"
      type="text"
      [formControl]="nationalIdentityForm.controls.nationalIdentityNumber"
      [label]="
        'INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.NATIONAL_IDENTITY_NUMBER.LABEL'
          | translate
      "
      required="showNationalIdentityForm"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          nationalIdentityForm.get('nationalIdentityNumber')?.touched &&
          nationalIdentityForm
            .get('nationalIdentityNumber')
            ?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          nationalIdentityForm.get('nationalIdentityNumber')?.touched &&
          nationalIdentityForm
            .get('nationalIdentityNumber')
            ?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : maxNICNumberChars }}
      </span>
      <span
        *ngIf="
          nationalIdentityForm.get('nationalIdentityNumber')?.touched &&
          nationalIdentityForm
            .get('nationalIdentityNumber')
            ?.hasError('alphaNum')
        "
      >
        {{ "INTAKE.FORM_ERRORS.ALPHANUMERIC_ONLY" | translate }}
      </span>
    </lib-form-input>

    <!-- Country of Issue -->
    <lib-form-select
      identifier="countryOfIssue"
      data-cy="national-identity-country-of-issue"
      [formControl]="nationalIdentityForm.controls.countryOfIssue"
      [label]="
        'INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.COUNTRY_ISSUE.LEGEND'
          | translate
      "
      [options]="countryList"
      required="showNationalIdentityForm"
      [editable]="user.can('documents:write')"
    >
      <span
        *ngIf="
          nationalIdentityForm.get('countryOfIssue')?.touched &&
          nationalIdentityForm.get('countryOfIssue')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <!-- National Identity Number Issuing Date -->
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="issueDate"
      [required]="true"
      [pastDate]="true"
      [formControl]="nationalIdentityForm.controls.issueDate"
      [label]="
        'INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.ISSUE_DATE.LEGEND' | translate
      "
    >
    </lib-form-datepicker>

    <!-- National Identity Number Expiry Date -->
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="expiryDate"
      [required]="true"
      [formControl]="nationalIdentityForm.controls.expiryDate"
      [label]="
        'INTAKE.IMM0008.FORMS.NATIONAL_IDENTITY.EXPIRY_DATE.LEGEND' | translate
      "
      class="expiry-date-datelib-form-datepicker"
    >
      <span
        *ngIf="
          verifyExpiryAndIssueDate &&
          nationalIdentityForm.get('expiryDate')?.hasError('incorrect')
        "
      >
        {{ "INTAKE.FORM_ERRORS.END_DATE_AFTER_START" | translate }}
      </span>
    </lib-form-datepicker>
  </fieldset>
</form>
