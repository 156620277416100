import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  env: "dev",
  production: false,
  API_NAME: "irccprsonapi",
  API_VERSION: "v2",
  configLog: {
    level: NgxLoggerLevel.TRACE,
    serverLogLevel: NgxLoggerLevel.TRACE,
  },
  localServerUrl: "",
};
