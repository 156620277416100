import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { FormSelectOption, LovService } from "lib";

@Component({
  selector: "pra-design-system-doc",
  templateUrl: "./design-system-doc.component.html",
  styleUrls: ["./design-system-doc.component.scss"],
})
export class DesignSystemDocComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private lovService: LovService) {}

  public options: FormSelectOption[];
  public radios: any[] = [
    {
      id: "Radio1",
      value: "1",
      label: "Radio 1",
    },
    {
      id: "Radio2",
      value: "2",
      label: "Radio 2",
    },
    {
      id: "Radio3",
      value: "3",
      label: "Radio 3",
    },
  ];
  public autoOptions: any[] = [
    {
      value: "01",
      text: "Option 1",
    },
    {
      value: "02",
      text: "Option 2",
    },
    {
      value: "03",
      text: "Option 3",
    },
    {
      value: "04",
      text: "Option 4",
    },
    {
      value: "05",
      text: "Option 5",
    },
    {
      value: "06",
      text: "Option 6",
    },
  ];
  public placeHolder = "Please select...";
  public form: FormGroup;

  ngOnInit(): void {
    this.options = this.lovService.lovs.province;
    this.form = this.fb.group({
      selectRequired: new FormControl(null, [Validators.required]),
      selectNotRequired: new FormControl(null, []),
      selectNotReadonly: new FormControl(null, []),
      selectReadonly: new FormControl(null, []),
      inputRequired: new FormControl("", [Validators.required]),
      inputRequired1: new FormControl("", [Validators.required]),
      inputRequired2: new FormControl("", [Validators.required]),
      inputRequired3: new FormControl("", [Validators.required]),
      inputRequired4: new FormControl("", [Validators.required]),
      inputNotRequired: new FormControl("", []),
      inputReadOnly: new FormControl("Readonly", []),
      textareaRequired: new FormControl("", [Validators.required]),
      textareaNotRequired: new FormControl("", []),
      textareaReadOnly: new FormControl(
        "Readonly textarea with filler text here",
        []
      ),
      checkboxRequired: new FormControl(null, [Validators.required]),
      checkboxNotRequired: new FormControl(true, []),
      checkboxReadOnly: new FormControl(true, []),
      radioRequired: new FormControl(null, [Validators.required]),
      radioRequired1: new FormControl(null, [Validators.required]),
      radioRequired2: new FormControl(null, [Validators.required]),
      autocomplete: new FormControl(null, []),
      autocompleteRequired: new FormControl(null, [Validators.required]),
      datepicker: new FormControl("NONE", []),
      datepickerReq: new FormControl("NONE", []),
      datepickerNull: new FormControl(null, []),
      datepickerLong: new FormControl("1999/10/10", []),
      datepickerShort2: new FormControl("1999/10", []),
      datepickerShort2req: new FormControl("1999/10", []),
      datepickerBefore: new FormControl(null, []),
      datepickerAfter: new FormControl(null, []),
      datepickerFuture: new FormControl(null, []),
      datepickerPast: new FormControl(null, []),
      datepickerRequired: new FormControl(null, [
        Validators.email,
        Validators.minLength(20),
      ]),
      datepickerShort: new FormControl(null, []),
      datepickerReadonly: new FormControl("2020/10/10", []),
    });
  }

  handleSubmit(e: any) {
    e.preventDefault();
    this.form.markAllAsTouched();
  }
}
