import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, ValidationService } from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { CaseService } from "../../../shared/case-module/case.service";
import { DocumentService } from "../../../shared/services/document/document.service";
import * as fromApp from "../../../store/app.reducer";
import { Subscription } from "rxjs";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { PsrService } from "@pr-applicant/app/shared/services/psr/psr.service";
import { GarService } from "@pr-applicant/app/shared/services/gar/gar.service";

@Component({
  selector: "pra-e-signature-declaration-page",
  templateUrl: "./e-signature-declaration-page.component.html",
  styleUrls: ["./e-signature-declaration-page.component.scss"],
})
export class ESignatureDeclarationPageComponent implements OnInit, OnDestroy {
  public lang: string;
  public checkedDeclaration = false;

  // case docs and case id
  public case: any;
  public caseId: string;
  public caseDocuments: any;
  public caseStatusId: number;
  public esigDocument: any;
  public formData: any;
  public isUploading: boolean = false;

  public caseHasBeenSubmitted: boolean;
  //
  public declarationForm = this.fb.group({
    declarationName: [
      null,
      [
        Validators.required,
        Validators.pattern(
          `^[a-zA-Z-éàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s ][a-zA-Z'-éàèùâêîôûëïöüæçœÉÀÈÙÂÊÎÔÛËÏÖÜÆÇŒ\r\s ]*$`
        ),
      ],
    ],
    canContact: [null, Validators.required],
    shareData: [null, Validators.required],
  });

  private subscriptions: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private caseService: CaseService,
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private alertService: AlertService,
    private router: Router,
    private documentService: DocumentService,
    public user: UserService,
    public psrService: PsrService,
    public garService: GarService
  ) {}

  ngOnInit() {
    this.lang = this.routeLocalizer.getCurrentRouteLang();

    this.getStoredCase();
    this.checkESig();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /* PUBLIC ---------------------------------------------------------------------------------- */

  public submitESig(): void {
    const canShareData = this.declarationForm.controls.shareData.value;
    const canContact = this.declarationForm.controls.canContact.value;
    const signedBy = this.declarationForm.controls.declarationName.value;

    if (this.esigDocument) {
      this.updateEsigNewSubmit(
        canShareData,
        canContact,
        this.esigDocument.id,
        signedBy
      );
    } else {
      this.firstEsigSubmit(canShareData, canContact, signedBy);
    }
  }

  /* PRIVATE ---------------------------------------------------------------------------------- */

  // if theres already an esig saved, check values
  private async checkESig(): Promise<void> {
    if (
      this.case?.documents?.filter(
        (docId: any) => docId?.documentTypeId === 301
      ).length === 1
    ) {
      this.esigDocument = this.case?.documents?.filter(
        (docId: any) => docId?.documentTypeId === 301
      )[0];

      await this.getESigData().then(() => {
        this.declarationForm.controls.declarationName?.setValue(
          this.formData.form.eSignature
        );
        this.declarationForm.controls.canContact?.setValue(
          this.formData.form.canContact
        );
        this.declarationForm.controls.shareData?.setValue(
          this.formData.form.canShareData
        );
        // if case status is 7, they cant edit their esignature
        if (this.caseHasBeenSubmitted) {
          this.declarationForm.controls.declarationName.disable();
          this.declarationForm.controls.canContact.disable();
          this.declarationForm.controls.shareData.disable();
        }
      });
    }
  }

  private getStoredCase(): void {
    this.subscriptions.push(
      this.store.select("selectedCase").subscribe((caseData: Case) => {
        if (caseData) {
          this.case = caseData;
          this.caseId = this.case.id;
          this.caseHasBeenSubmitted = this.case.caseStatusId === 7;
        }
      })
    );
  }

  private async getESigData() {
    const esigDocId = this.esigDocument.id;
    let response;
    try {
      response = await this.documentService.getFormByDocumentId(
        this.case.id,
        esigDocId
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
    return (this.formData = response);
  }

  private firstEsigSubmit(
    canShareData: boolean,
    canContact: boolean,
    signedBy?: string
  ) {
    this.isUploading = true;
    this.caseService
      .addEsigByCaseId(canShareData, canContact, signedBy)
      .then((response: any) => {
        return response;
      })
      .then(() => {
        // update state again
        return this.caseService.getCaseAndDocumentsByCaseId(
          this.case.emailAddress,
          this.case.id
        );
      })
      .then(() => {
        const newNestedPath = this.routeLocalizer.getNestedPath(this.lang, [
          "INTAKE",
          "INTAKE_CASE_DETAILS",
        ]);
        this.router.navigate([`/${this.lang}/${newNestedPath}${this.caseId}`]);
        this.isUploading = false;
        this.alertService.success(this.alertSuccessfulSubmit);
      })
      .catch(() => {
        this.isUploading = false;
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  private updateEsigNewSubmit(
    canShareData: boolean,
    canContact: boolean,
    documentId?: number,
    signedBy?: string
  ) {
    this.isUploading = true;
    this.caseService
      .updateEsigByCaseId(canShareData, canContact, documentId, signedBy)
      .then((response: any) => {
        return response;
      })
      .then(() => {
        // update state again
        return this.caseService.getCaseAndDocumentsByCaseId(
          this.case.emailAddress,
          this.case.id
        );
      })
      .then(() => {
        const newNestedPath = this.routeLocalizer.getNestedPath(this.lang, [
          "INTAKE",
          "INTAKE_CASE_DETAILS",
        ]);
        this.router.navigate([`/${this.lang}/${newNestedPath}${this.caseId}`]);
        this.isUploading = false;
        this.alertService.success(this.alertSuccessfulSubmit);
      })
      .catch(() => {
        this.isUploading = false;
        this.alertService.danger(this.alertTechnicalError);
      });
  }
  public get editable() {
    return this.canEditEsig();
  }

  private canEditEsig() {
    return this.psrService.isPSR(this.case.lobId) ||
      this.garService.isGAR(this.case.lobId)
      ? this.user.is("refugee") || this.user.is("client")
      : this.user.can("signature:write");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR");
  }

  private get alertSuccessfulSubmit(): string {
    return this.translate.instant("INTAKE.DECLARATION.ESIG.FORM.ERROR.SUCCESS");
  }
}
