import { Injectable } from "@angular/core";
import { Router, CanActivateChild } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";

@Injectable({ providedIn: "root" })
export class PRConfirmationGuard implements CanActivateChild {
  private currentLang: string;
  private confirmationCasesTotal = 0;

  constructor(private router: Router, private store: Store<fromApp.State>) {
    this.store.select("casesCount").subscribe((totalCasesData) => {
      this.confirmationCasesTotal = totalCasesData.confirmation;
    });
  }

  canActivateChild(): any {
    if (this.confirmationCasesTotal > 0) {
      return true;
    } else {
      return this.router.parseUrl(`/${this.currentLang}`);
    }
  }
}
