import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "../../routing/route-localizer.service";

@Component({
  selector: "pra-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public toggleLang: string;
  public currentLang: string;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setToggleLang();
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.loadHtmlLang();
  }

  private setToggleLang(): void {
    this.translate.get("HEADER.TOGGLE_LANG").subscribe((res) => {
      this.toggleLang = res;
    });
  }

  private loadHtmlLang() {
    const head = document.getElementsByTagName("html")[0];
    head.lang = this.currentLang;
  }
}
