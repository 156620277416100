import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { ValidationService, ModalService, AlertService, DOC_LIMITS } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { IInformationBoxConfig } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm0008-view-dependant-form",
  templateUrl: "./view-dependant-form.component.html",
  styleUrls: ["./view-dependant-form.component.scss"],
})
export class IMM0008ViewDepandantFormComponent implements OnInit, OnChanges {
  public lang: string;
  public dependantsList: any[] = [];
  public selectedDeleteDepId: string; // This is the ID of the selected dependant to delete
  public deleteModalId = "confirmDeleteIMM0008Dependant";

  public dependantsForm = this.fb.group({
    doYouHaveDependants: [
      { value: null, disabled: this.yesRadioSelected && this.dependantsExist },
      Validators.required,
    ],
  });

  public informationBoxConfig: IInformationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };

  @Input() formData: any;
  @Input() isLoading: boolean;
  @Input() isAddingDependant: boolean;

  @Output() addNewDependant: EventEmitter<any> = new EventEmitter();
  @Output() deleteDependant: EventEmitter<any> = new EventEmitter();
  @Output() saveFormData: EventEmitter<any> = new EventEmitter();

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private alertService: AlertService,
    private translate: TranslateService,
    private validationService: ValidationService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  ngOnChanges(): void {
    this.mapDependantsList();
    // Set the form values if saved already
    if (this.formData?.form) {
      this.setSavedFormData();
    }
  }

  public get isDependantRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.dependantsForm.controls.doYouHaveDependants
    );
  }

  public get dependantsExist(): boolean {
    const form = this.formData?.form;
    return form?.dependants?.length > 0 && form?.doYouHaveDependants
      ? true
      : false;
  }

  public get yesRadioSelected(): boolean {
    return this.dependantsForm?.controls?.doYouHaveDependants?.value === true;
  }

  public async handleRadioButtonsChange(): Promise<any> {
    // We need to set the selected option on change because we need the value
    // to be set before we can add a new dependant!
    await this.saveFormData.emit();

    if (this.yesRadioSelected && this.dependantsExist) {
      this.disableRadioButtons();
    } else {
      this.enableRadioButtons();
    }

    this.dependantsForm?.updateValueAndValidity();
  }

  public async addDependant(): Promise<any> {
    if (this.dependantsList.length >= DOC_LIMITS.IMM0008.MAX_DEPENDANTS) {
      this.alertService.danger(this.alertDependantLimit);
    } else {
      await this.addNewDependant.emit();
    }
  }

  public anIncompleteApp(): boolean {
    let flag = false;
    const dependants = this.formData?.form?.dependants;
    if (this.yesRadioSelected) {
      if (!dependants || dependants?.length <= 0) {
        flag = true;
      } else {
        dependants.forEach((dependant: any) => {
          const formsComplete =
            dependant.personalDetails.isComplete &&
            dependant.educationOccupationDetails.isComplete &&
            dependant.languageDetails.isComplete &&
            dependant.nationalIdentityDetails.isComplete &&
            dependant.passportDetails.isComplete;
          if (!formsComplete) {
            flag = true;
          }
        });
      }
    }
    return flag;
  }

  public mapDependantsList(): void {
    const dependants = this.formData?.form?.dependants;
    const dependantsArray: any[] = [];
    let appStatus: string;
    let appStatusLabel: string;

    dependants?.map((dependant: any) => {
      const formsComplete =
        dependant.personalDetails.isComplete &&
        dependant.educationOccupationDetails.isComplete &&
        dependant.languageDetails.isComplete &&
        dependant.nationalIdentityDetails.isComplete &&
        dependant.passportDetails.isComplete;

      if (formsComplete) {
        appStatus = "complete";
        appStatusLabel = `INTAKE.FORM_DOCUMENT.STATUS_COMPLETE`;
      } else {
        appStatus = "missingInformation";
        appStatusLabel = `INTAKE.FORM_DOCUMENT.STATUS_MISSING_INFORMATION`;
      }

      dependantsArray.push({
        familyName: dependant?.personalDetails?.familyName,
        givenName: dependant?.personalDetails?.givenName,
        dob: dependant?.personalDetails?.dob,
        id: dependant?._id,
        status: appStatus,
        statusLabel: appStatusLabel,
      });
    });
    this.dependantsList = dependantsArray;
  }

  public disableRadioButtons(): void {
    this.dependantsForm?.controls?.doYouHaveDependants?.disable();
    this.dependantsForm?.controls?.doYouHaveDependants?.updateValueAndValidity();
  }

  public enableRadioButtons(): void {
    this.dependantsForm?.controls?.doYouHaveDependants?.enable();
    this.dependantsForm?.controls?.doYouHaveDependants?.updateValueAndValidity();
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  public get dependantActionText(): string {
    return this.isReadOnly
      ? this.translate.instant("INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.VIEW")
      : this.translate.instant(
          "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.EDIT"
        );
  }

  // This method is to set saved values if they exists
  // and enable/disable buttons as needed
  private setSavedFormData(): void {
    if (this.formData?.form?.doYouHaveDependants !== null) {
      this.dependantsForm?.controls?.doYouHaveDependants?.setValue(
        this.formData?.form?.doYouHaveDependants === true ? true : false
      );
      // Check if we need to enable or disabled buttons based on saved data
      if (this.yesRadioSelected && this.dependantsExist) {
        this.disableRadioButtons();
      } else {
        this.enableRadioButtons();
      }
    }
  }

  private get alertDependantLimit(): string {
    return this.translate.instant(
      "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.DEPENDANT_LIMIT_REACHED"
    );
  }
}
