import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { IInformationBoxConfig } from "lib";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";

@Component({
  selector: "pra-imm5669-start",
  templateUrl: "./imm5669-start.component.html",
  styleUrls: ["./imm5669-start.component.scss"],
})
export class IMM5669StartComponent implements OnInit, OnDestroy {
  @ViewChild("declarationForm", { static: false })
  public informationBoxConfig: IInformationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };
  public lang: string = "";
  caseId: string = "";

  public forms: any = [];
  private currentForm: any = [];
  private storeSubsc$: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.State>,
    private user: UserService,
    public routeLocalizer: RouteLocalizerService
  ) {}

  ngOnInit(): void {
    this.getDataFromStore();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public isFormDataUnSaved(): boolean {
    return false;
  }

  public getFormData(): any {
    return {
      sectionName: "startPage",
      sectionInfo: {},
    };
  }

  // private

  private getDataFromStore(): void {
    this.subscriptions.push(
      (this.storeSubsc$ = this.store
        .select("currentIMMDocument")
        .subscribe((doc) => {
          this.forms = doc.forms;
          this.caseId = `${doc.caseId}`;
          this.currentForm = doc.currentForm?.form;
          if (doc.currentForm?.form) {
            this.forms = doc.currentForm?.form.map((currentForm: any) => {
              let fullName = null;
              if (
                currentForm.sectionA.givenName &&
                currentForm.sectionA.familyName
              ) {
                fullName =
                  currentForm.sectionA.givenName +
                  " " +
                  currentForm.sectionA.familyName;
              } else if (currentForm.sectionA.givenName) {
                fullName = currentForm.sectionA.givenName;
              } else if (currentForm.sectionA.familyName) {
                fullName = currentForm.sectionA.familyName;
              }
              return {
                formId: currentForm._id,
                fullName,
                dob: currentForm.sectionA.applicant?.dob,
              };
            });
          } else {
            this.forms = doc.forms?.map((form: any) => {
              let fullName = null;
              if (form.sectionA?.givenName && form.sectionA?.familyName) {
                fullName =
                  form.sectionA?.givenName + " " + form.sectionA.familyName;
              } else if (form.sectionA?.givenName) {
                fullName = form.sectionA?.givenName;
              } else if (form.sectionA?.familyName) {
                fullName = form.sectionA?.familyName;
              }
              return {
                formId: form._id,
                fullName,
                dob: form.sectionA?.dob,
              };
            });
          }
        }))
    );
  }
}
