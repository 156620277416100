import * as CasesCountActions from "./CasesCount.actions";

export interface CasesCountState {
  intake: number;
  confirmation: number;
}

const initialState = {
  intake: 0,
  confirmation: 0,
};

export function CasesCountReducer(
  state: CasesCountState = initialState,
  action: CasesCountActions.CasesCountActions
) {
  switch (action.type) {
    case CasesCountActions.SET_INTAKE_CASES_COUNT:
      const intake = action.payload;
      return {
        ...state,
        intake,
      };

    case CasesCountActions.SET_CONFIRMATION_CASES_COUNT:
      const confirmation = action.payload;
      return {
        ...state,
        confirmation,
      };

    case CasesCountActions.RESET_CASES_COUNT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
