<mat-sidenav-container class="page-container">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [items]="FormNavbarItems"
        [curr]="3"
        [isLoading]="isLoading"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="emphasis">
      {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
    </h1>
    <h2 class="emphasis">
      {{ "INTAKE.IMM0008.PAGES.ADDRESS.SUBHEADING" | translate }}
    </h2>

    <ul>
      <li class="body1">
        {{ "INTAKE.IMM0008.PAGES.ADDRESS.CORRESPONDANCE" | translate }}
      </li>
      <li>
        {{ "INTAKE.IMM0008.PAGES.ADDRESS.EMAIL_CORRESPONDANCE" | translate }}
      </li>
      <li>
        {{ "INTAKE.IMM0008.PAGES.ADDRESS.AUTH_RELEASE" | translate }}
      </li>
    </ul>

    <lib-spinner [showSpinner]="isLoading"> </lib-spinner>
    <pra-imm0008-address-form
      #addressForm
      [formData]="formData"
      [ngStyle]="{ display: isLoading ? 'none' : 'block' }"
    >
    </pra-imm0008-address-form>

    <lib-layout-row gap="one-and-half" mt="3">
      <a
        [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE3', '../')"
        category="secondary"
      >
        {{ "INTAKE.FORM_PAGE_BACK" | translate }}
      </a>
      <button
        (click)="saveForm()"
        [disabled]="!addressForm.isFormValid"
        category="primary"
      >
        {{ nextButtonKey | translate }}
      </button>
    </lib-layout-row>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isLoading"
></pra-form-hamburger>
