import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-d",
  templateUrl: "./imm5669-section-d.component.html",
  styleUrls: ["./imm5669-section-d.component.scss"],
})
export class IMM5669SectionDComponent implements OnInit, OnDestroy {
  public maxFieldLengths: { [key: string]: number } = {
    from: 7,
    to: 7,
    activity: 21,
    cityAndCountry: 30,
    status: 14,
    nameOfEmployerOrSchool: 27,
  };

  public personalHistory: UntypedFormArray = new UntypedFormArray([]);
  public sectionDData: any;
  public isReadOnly = !this.user.can("documents:write");
  public storeSubs$: Subscription;

  private buttonClicked: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private alertService: AlertService,
    private store: Store<fromApp.State>,
    private validationService: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.storeSubs$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((formData) => {
        this.sectionDData = formData?.currentForm?.form?.sectionD;
        if (this.sectionDData?.personalHistory?.length > 0) {
          this.setFormValues();
        } else {
          this.personalHistory.push(this.createpersonalHistoryForm());
        }
      });
  }

  ngOnDestroy(): void {
    this.storeSubs$.unsubscribe();
  }

  public get infoConfig() {
    return {
      icon: "exclamation",
      type: "critical",
    };
  }

  public get alertMaxMembershipLimit(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  public addInstance(): void {
    if (
      this.personalHistory.length < DOC_LIMITS.IMM5669.PERSONAL_DETAILS_LIMIT
    ) {
      this.buttonClicked = true;
      this.personalHistory.push(this.createpersonalHistoryForm());
    } else {
      this.buttonClicked = true;
      this.alertService.danger(this.alertMaxMembershipLimit);
    }
  }

  public removeInstance(index: number): void {
    this.personalHistory.removeAt(index);
  }

  public get isFormValid(): boolean {
    return this.personalHistory?.valid;
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.personalHistory.touched || this.buttonClicked;
  }

  public getFormData(): any {
    if (
      (this.personalHistory.controls as FormControl[]).find(
        (fc) => fc.invalid && fc.touched
      )
    ) {
      return { sectionName: "sectionD", isInvalid: true };
    }
    const formValue = this.personalHistory.getRawValue();
    return {
      sectionName: "sectionD",
      sectionInfo: { ...this.sectionDData, personalHistory: formValue },
    };
  }

  private createpersonalHistoryForm(): FormGroup {
    return this.fb.group({
      from: [null, Validators.maxLength(this.maxFieldLengths.from)],
      to: [null, Validators.maxLength(this.maxFieldLengths.to)],
      activity: [null, Validators.maxLength(this.maxFieldLengths.activity)],
      cityAndCountry: [
        null,
        Validators.maxLength(this.maxFieldLengths.cityAndCountry),
      ],
      status: [null, Validators.maxLength(this.maxFieldLengths.status)],
      nameOfEmployerOrSchool: [
        null,
        Validators.maxLength(this.maxFieldLengths.nameOfEmployerOrSchool),
      ],
    });
  }

  private setFormValues(): void {
    this.personalHistory.clear();
    const savedData = this.sectionDData?.personalHistory;
    savedData?.forEach((instance: any) => {
      const fields: FormGroup = this.createpersonalHistoryForm();
      fields?.setValue({
        from: instance.from,
        to: instance.to,
        activity: instance.activity,
        cityAndCountry: instance.cityAndCountry,
        status: instance.status,
        nameOfEmployerOrSchool: instance.nameOfEmployerOrSchool,
      });
      this.personalHistory.push(fields);
    });
  }
}
