import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm5669-section-a",
  templateUrl: "./imm5669-section-a.component.html",
  styleUrls: ["./imm5669-section-a.component.scss"],
})
export class IMM5669SectionAComponent implements OnInit, OnDestroy {
  public maxFieldLengths: { [key: string]: number } = {
    givenName: 57,
    familyName: 57,
    nativeFullName: 90,
    dob: 10,
    deceasedDate: 10,
    cityOfBirth: 30,
    countryOfBirth: 30,
  };
  private sectionAData: any;
  private storeSubsc$: Subscription;
  private currentForm: any;

  // FormGroups used to build reactive form
  // Important: Keep these below validation variables
  public sectionAForm: FormGroup = this.createSectionAFormGroup();
  public sectionAFormFather: FormGroup = this.createParentFormGroup();
  public sectionAFormMother: FormGroup = this.createParentFormGroup();

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.getStoredForm();
    this.disableForAutoComplete();
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  /* PUBLIC ----------------------------------------------- */

  public getFormData(): any {
    if (!this.isFormValid) {
      return { sectionName: "sectionA", isInvalid: true };
    }
    const result = _.cloneDeep(this.currentForm);
    const fromAPISectionA = result.form.sectionA;
    const entryData = {
      ...this.sectionAForm.getRawValue(),
      _id: fromAPISectionA._id,
      father: {
        ...this.sectionAFormFather.getRawValue(),
        _id: fromAPISectionA.father._id,
      },
      mother: {
        ...this.sectionAFormMother.getRawValue(),
        _id: fromAPISectionA.mother._id,
      },
    };
    result.form.sectionA = { ...entryData, _id: result.form.sectionA._id };
    return { sectionName: "sectionA", sectionInfo: result.form.sectionA };
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return (
      this.sectionAForm.touched ||
      this.sectionAFormFather.touched ||
      this.sectionAFormMother.touched
    );
  }

  private disableForAutoComplete() {
    const _disableForAutoComplete = [
      this.sectionAForm.controls.dob,
      this.sectionAForm.controls.givenName,
      this.sectionAForm.controls.principalApplicant,
      this.sectionAForm.controls.familyName,
    ];
    _disableForAutoComplete.forEach((control) => control.disable());
  }

  /* PRIVATE ----------------------------------------------- */

  private get isFormValid(): boolean {
    return (
      this.sectionAForm?.valid &&
      this.sectionAFormFather?.valid &&
      this.sectionAFormMother?.valid
    );
  }

  private getStoredForm(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.sectionAData = currentImmDoc?.currentForm?.form?.sectionA;
        this.setFormValues();
      });
  }

  private setFormValues(): void {
    /* main form */
    this.sectionAForm.patchValue({
      principalApplicant: this.sectionAData.principalApplicant,
      givenName: this.sectionAData.givenName,
      familyName: this.sectionAData.familyName,
      nativeFullName: this.sectionAData.nativeFullName,
      dob: this.sectionAData.dob,
    });
    /* father */
    this.sectionAFormFather.patchValue({
      familyName: this.sectionAData.father.familyName,
      givenName: this.sectionAData.father.givenName,
      dob: this.sectionAData.father.dob,
      deceasedDate: this.sectionAData.father.deceasedDate,
      cityOfBirth: this.sectionAData.father.cityOfBirth,
      countryOfBirth: this.sectionAData.father.countryOfBirth,
    });
    /* mother */
    this.sectionAFormMother.patchValue({
      familyName: this.sectionAData.mother.familyName,
      givenName: this.sectionAData.mother.givenName,
      dob: this.sectionAData.mother.dob,
      deceasedDate: this.sectionAData.mother.deceasedDate,
      cityOfBirth: this.sectionAData.mother.cityOfBirth,
      countryOfBirth: this.sectionAData.mother.countryOfBirth,
    });
  }

  private createSectionAFormGroup(): FormGroup {
    return this.fb.group({
      principalApplicant: [null],
      givenName: [null, Validators.maxLength(this.maxFieldLengths.givenName)],
      familyName: [null, Validators.maxLength(this.maxFieldLengths.familyName)],
      nativeFullName: [
        null,
        Validators.maxLength(this.maxFieldLengths.nativeFullName),
      ],
      dob: [null, Validators.maxLength(this.maxFieldLengths.dob)],
      father: this.sectionAFormFather,
      mother: this.sectionAFormMother,
    });
  }

  private createParentFormGroup(): FormGroup {
    return this.fb.group({
      familyName: [null, Validators.maxLength(this.maxFieldLengths.familyName)],
      givenName: [null, Validators.maxLength(this.maxFieldLengths.givenName)],
      dob: [null, Validators.maxLength(this.maxFieldLengths.dob)],
      deceasedDate: [
        null,
        Validators.maxLength(this.maxFieldLengths.deceasedDate),
      ],
      cityOfBirth: [
        null,
        Validators.maxLength(this.maxFieldLengths.cityOfBirth),
      ],
      countryOfBirth: [
        null,
        Validators.maxLength(this.maxFieldLengths.countryOfBirth),
      ],
    });
  }
}
