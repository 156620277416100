<div class="family-table__container">
  <table class="table dependants-table">
    <thead>
      <tr>
        <th>
          {{ "INTAKE.FAMILY_TABLE.FULLNAME_LABEL" | translate }}
        </th>
        <th>
          {{ "INTAKE.FAMILY_TABLE.DOB_LABEL" | translate }}
        </th>
        <th>
          {{ "INTAKE.FAMILY_TABLE.ACTION_LABEL" | translate }}
        </th>
      </tr>
    </thead>

    <tbody *ngIf="familyMembers?.length > 0">
      <tr *ngFor="let person of familyMembers">
        <!-- Full Name -->
        <td class="family-table__body">
          <ng-container *ngIf="person.fullName">
            {{ person.fullName }}
          </ng-container>
          <ng-container *ngIf="!person.fullName">
            {{ "INTAKE.FAMILY_TABLE.NOT_PROVIDED" | translate }}
          </ng-container>
        </td>
        <!-- DOB -->
        <td class="family-table__body">
          <ng-container *ngIf="person.dob">
            {{ person.dob }}
          </ng-container>
          <ng-container *ngIf="!person.dob">
            {{ "INTAKE.FAMILY_TABLE.NOT_PROVIDED" | translate }}
          </ng-container>
        </td>
        <!-- Actions -->
        <td class="family-table__body">
          <div class="family-table__action--buttons">
            <button
              class="btn btn-primary-alt"
              (click)="editFamilyMember(person.formId)"
              tabindex="0"
            >
              <!-- Edit Button -->
              <ng-container *ngIf="!isReadOnly">
                {{
                  "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.EDIT" | translate
                }}
              </ng-container>
              <!-- View Button -->
              <ng-container *ngIf="isReadOnly">
                {{ "INTAKE.APPLICATIONS_PAGE.TABLE.VIEW" | translate }}
              </ng-container>
              <span class="visually-hidden">
                <ng-container *ngIf="person.fullName">
                  {{ person.fullName }}
                </ng-container>
                <ng-container *ngIf="!person.fullName">
                  {{ "INTAKE.FAMILY_TABLE.NOT_PROVIDED" | translate }}
                </ng-container>
              </span>
            </button>
            <!-- Delete Button -->
          </div>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="familyMembers?.length === 0" class="empty-table">
      <tr>
        <td colspan="4">
          <p class="visually-hidden">
            {{ "INTAKE.FAMILY_TABLE.NO_DATA" | translate }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
