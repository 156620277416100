<lib-copy-block
  [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate"
></lib-copy-block>
<lib-copy-block
  class="intro-copy"
  headingTag="h2"
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_F.FORM_HEADING' | translate"
  [body]="'INTAKE.IMM5669.FORMS.SECTION_F.FORM_SUBTITLE' | translate"
></lib-copy-block>

<form autocomplete="off">
  <ng-container
    *ngFor="
      let jobPosition of governmentPositions?.controls;
      index as i;
      last as isLast;
      first as isFirst
    "
    formArray="governmentPositions"
  >
    <fieldset [formGroup]="jobPosition" class="form-array__fieldset">
      <legend>
        {{ "INTAKE.IMM5669.FORMS.SECTION_F.INSTANCE_LABEL" | translate }}
        {{ i + 1 }}
      </legend>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'dateFrom' + i"
        [formControl]="jobPosition.controls.from"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_FROM' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'to' + i"
        [formControl]="jobPosition.controls.to"
        [label]="'INTAKE.IMM5669.FORMS.FIELDS.DATE_TO' | translate"
        [shortFormat]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'cityAndCountry' + i"
        [formControl]="jobPosition.controls.cityAndCountry"
        [label]="
          'INTAKE.IMM5669.FORMS.SECTION_F.CITY_AND_COUNTRY_LABEL' | translate
        "
        widthMd="true"
      >
        <span
          *ngIf="
            jobPosition.controls?.cityAndCountry.touched &&
            jobPosition.controls?.cityAndCountry.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.cityAndCountry }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'department' + i"
        [formControl]="jobPosition.controls.department"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_F.DEPARTMENT_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            jobPosition.controls?.department.touched &&
            jobPosition.controls?.department.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.department }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'activities' + i"
        [formControl]="jobPosition.controls.activities"
        [label]="'INTAKE.IMM5669.FORMS.SECTION_F.ACTIVITIES_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            jobPosition.controls?.activities.touched &&
            jobPosition.controls?.activities.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.activities }
          }}
        </span>
      </lib-form-input>

      <!-- ADD/REMOVE BUTTONS -->
      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeJobPosition(i)"
        >
          {{
            "INTAKE.IMM5669.FORMS.SECTION_F.INSTANCE_REMOVE_BUTTON" | translate
          }}
        </button>
        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addJobPosition()"
        >
          {{ "INTAKE.IMM5669.FORMS.SECTION_F.INSTANCE_ADD_BUTTON" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
