import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import {
  FormGroup,
  Validators,
  FormControl,
  ValidatorFn,
  AbstractControl,
  UntypedFormBuilder,
} from "@angular/forms";
import { ValidationService, FormSelectOption, ProgramIds, LobIds } from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "../../../../shared/case-module/case.model";
import { first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { NGXLogger } from "ngx-logger";
import { LovService } from "lib";
import { autoPopulate } from "@pr-applicant/app/shared/helpers/auto-populate";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-application-details-form",
  templateUrl: "./application-details-form.component.html",
  styleUrls: ["./application-details-form.component.scss"],
})
export class IMM0008ApplicationDetailsFormComponent
  implements OnInit, OnChanges, OnDestroy, AfterContentChecked
{
  @Input() formData: any;

  public lang: string;
  public maxCSQNumberChars: number = 11;
  public formValues: any;
  public interpreterRequestedOptions: FormSelectOption[];
  public correspondenceOptions: FormSelectOption[] | null;
  public interviewOptions: FormSelectOption[] | null;
  public provinceOptions: FormSelectOption[] | null;

  public programId: number;

  private formLists: any;
  private quebecProvValue: string = "05"; // Use this value to check that QC has been selected

  private csqNumberValidations = [
    Validators.maxLength(this.maxCSQNumberChars),
    this.validationService.validatorNoPipe,
  ];
  private dateAppliedForCSQValidations = [
    this.validationService.validatorDateFormat,
    this.validationService.validatorDatePast,
  ];

  public applicationDetailsForm: FormGroup;

  private subscriptioins: Subscription[] = [];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
    private store: Store<fromApp.State>,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getProgramId();
    this.createForm();
    this.watchReceivedCSQ();
    this.watchProvince();
    const disabledForAutopopulate = [
      this.applicationDetailsForm.controls.correspondence,
    ];
    autoPopulate(disabledForAutopopulate);
  }

  ngOnChanges() {
    this.formValues = this.formData?.form;
    this.logger.log("corr: ", this.correspondenceOptions);
    this.logger.log("prov: ", this.provinceOptions);
    this.formLists = this.lovService.lovs;
    this.correspondenceOptions = this.formLists.preferenceLanguage;
    this.interviewOptions = this.formLists.contactLanguage;
    this.provinceOptions = this.formLists.provinceAbbrev;
    this.interpreterRequestedOptions = [
      { value: true, text: { en: "Yes", fr: "Oui" } },
      { value: false, text: { en: "No", fr: "Non" } },
    ];
    if (this.formData?.form) {
      this.setProvinceValidations();
      this.setFormDataIfSaved();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptioins.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  /*----------  PUBLIC ---------*/

  /*--- Setting City Options ---*/
  public get cityOptions(): FormSelectOption[] | null {
    const selectedProvinceValue =
      this.applicationDetailsForm.get("province")?.value;
    if (selectedProvinceValue) {
      // Return the cities for the select province
      return this.formLists?.city[selectedProvinceValue];
    }
    return null;
  }

  public get isQuebecSelected(): boolean {
    return (
      this.applicationDetailsForm?.controls?.province?.value ===
      this.quebecProvValue
    );
  }

  // This method sets the form values type to match API
  public getFormatedFormValues(): any {
    const formValues = this.applicationDetailsForm?.value;
    if (formValues) {
      return {
        ...this.applicationDetailsForm.value,
        interpreterRequested: formValues?.interpreterRequested,
        receivedCSQ: this.isQuebecSelected ? formValues?.receivedCSQ : null,
      };
    }
    return {};
  }

  public get isPlannedResidenceRequired(): boolean {
    return !(this.programId === ProgramIds.Refugee);
  }

  /*---------- CSQ Number Field ------*/

  // Return the max characters needed for error copy
  public get CSQNumberMaxChars(): object {
    return {
      max: this.maxCSQNumberChars,
    };
  }

  // Show the field if the radio button is not set or if YES is selected
  public get showCSQNumberField() {
    const radioField = this.applicationDetailsForm.get("receivedCSQ");
    return this.isQuebecSelected && radioField?.value === true;
  }

  /*---------- CSQ Date Field ------*/
  // Show the field if the radio button is not set or if NO is selected
  public get showCSQDateField() {
    const radioField = this.applicationDetailsForm.get("receivedCSQ");
    return this.isQuebecSelected && radioField?.value === false;
  }

  // This field is when there is is an error with the date pattern and is invalid
  public get CSQDateInvalidError() {
    const field = this.applicationDetailsForm.get("dateAppliedForCSQ");
    if (field?.touched && field?.hasError("dateFormat")) {
      return true;
    }
    return false;
  }

  // This field is when there is an error with past date, but not pattern
  public get CSQDatePastDateError() {
    const field = this.applicationDetailsForm.get("dateAppliedForCSQ");
    if (
      field?.touched &&
      field?.hasError("pastDate") &&
      !field?.hasError("dateFormat")
    ) {
      return true;
    }
    return false;
  }

  /*----------  PRIVATE ---------*/

  private createForm(): void {
    this.applicationDetailsForm = this.fb.group({
      correspondence: [null, Validators.required],
      interview: [null, Validators.required],
      interpreterRequested: [null, Validators.required],
      province: [
        null,
        this.isPlannedResidenceRequired ? Validators.required : null,
      ],
      city: [
        null,
        this.isPlannedResidenceRequired ? Validators.required : null,
      ],
      receivedCSQ: [null, this.isQuebecSelected ? Validators.required : null],
      csqNumber: new FormControl(null, { validators: [] }),
      dateAppliedForCSQ: new FormControl(null, { validators: [] }),
    });
  }
  // This method sets the form saved data if there is any
  private setFormDataIfSaved(): any {
    if (this.applicationDetailsForm.controls.correspondence) {
      this.applicationDetailsForm.controls.correspondence.setValue(
        this.formValues?.correspondence
      );
    }
    if (this.applicationDetailsForm.controls.interview) {
      this.applicationDetailsForm.controls.interview.setValue(
        this.formValues?.interview
      );
    }

    if (this.applicationDetailsForm.controls.interpreterRequested) {
      this.applicationDetailsForm.controls.interpreterRequested.setValue(
        this.formValues?.interpreterRequested
      );
    }

    if (this.applicationDetailsForm.controls.province) {
      this.applicationDetailsForm.controls.province.setValue(
        this.formValues?.province
      );
    }

    if (this.applicationDetailsForm.controls.city) {
      this.applicationDetailsForm.controls.city.setValue(this.formValues?.city);
    }

    if (this.applicationDetailsForm.controls.receivedCSQ) {
      // If we have value for receivedCSQ, set the value and enable the field
      if (this.formValues?.receivedCSQ !== null) {
        this.applicationDetailsForm.controls.receivedCSQ.setValue(
          this.formValues?.receivedCSQ
        );
      } else {
        this.clearQuebecFields();
      }
    }
    if (this.applicationDetailsForm.controls.csqNumber) {
      // If we have value for csqNumber, set the validators first then the value
      this.applicationDetailsForm?.controls?.csqNumber?.setValidators(
        this.csqNumberValidations
      );
      this.applicationDetailsForm?.controls?.csqNumber?.updateValueAndValidity();

      this.applicationDetailsForm.controls.csqNumber.setValue(
        this.formValues?.csqNumber
      );
    }

    if (this.applicationDetailsForm.controls.dateAppliedForCSQ) {
      // If we have value for dateAppliedForCSQ, set the validators first
      this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.setValidators(
        this.dateAppliedForCSQValidations
      );
      this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.updateValueAndValidity();

      this.applicationDetailsForm.controls.dateAppliedForCSQ.setValue(
        this.formValues?.dateAppliedForCSQ
      );
    }
  }

  // This method will set the validations on the province field based on some conditions
  private setProvinceValidations(): void {
    if (this.formData?.lobId === LobIds.StartUpBusinessClass) {
      this.setNotQuebecValidations();
    } else if (
      this.formData?.lobId === LobIds.QuebecEntrepreneur ||
      this.formData?.lobId === LobIds.QuebecSelectedInvestor ||
      this.formData?.lobId === LobIds.QuebecSelfEmployedPersons ||
      this.formData?.lobId === LobIds.QuebecSkilledWorkers ||
      this.formData?.lobId === LobIds.UkraineQC
    ) {
      this.setOnlyQuebecValidation();
    }
  }

  private setNotQuebecValidations(): void {
    this.applicationDetailsForm?.controls?.province.setValidators([
      Validators.required,
      this.notQuebec(),
    ]);
    this.applicationDetailsForm.controls.province.updateValueAndValidity();
  }

  private setOnlyQuebecValidation(): void {
    this.applicationDetailsForm.controls.province.setValidators([
      Validators.required,
      this.quebecOnly(),
    ]);
    this.applicationDetailsForm.controls.province.updateValueAndValidity();
  }

  private quebecOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = !(control.value === this.quebecProvValue);
      return forbidden ? { notQuebec: true } : null;
    };
  }

  private notQuebec(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value === this.quebecProvValue;
      return forbidden ? { isQuebec: true } : null;
    };
  }

  private setValidatorsForCSQNumberOnly(): void {
    // 1. set the csqNumber to required and add validations
    // 2. Clear the value if it was added to dateAppliedForCSQ
    this.applicationDetailsForm?.controls?.csqNumber?.setValidators(
      this.csqNumberValidations
    );
    this.applicationDetailsForm?.controls?.csqNumber?.updateValueAndValidity();

    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.clearValidators();
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.setValue(null);
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.updateValueAndValidity();
  }

  private setValidatorsForDateAppliedForCSQ(): void {
    // 1. set the dateAppliedForCSQ to required and add validations
    // 2. Clear the value if it was added to csqNumber
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.setValidators(
      this.dateAppliedForCSQValidations
    );
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.updateValueAndValidity();

    this.applicationDetailsForm?.controls?.csqNumber?.clearValidators();
    this.applicationDetailsForm?.controls?.csqNumber?.setValue(null);
    this.applicationDetailsForm?.controls?.csqNumber?.updateValueAndValidity();
  }

  private clearQuebecFields(): void {
    // Clear any data that could be added in the radio buttons
    this.applicationDetailsForm?.controls?.receivedCSQ?.setValue(null);
    this.applicationDetailsForm?.controls?.receivedCSQ?.clearValidators();
    this.applicationDetailsForm?.controls?.receivedCSQ?.updateValueAndValidity();

    // Clear any data that could be added in the dateAppliedForCSQ field
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.setValue(null);
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.clearValidators();
    this.applicationDetailsForm?.controls?.dateAppliedForCSQ?.updateValueAndValidity();

    // Clear any data that could be added in the csqNumber field
    this.applicationDetailsForm?.controls?.csqNumber?.setValue(null);
    this.applicationDetailsForm?.controls?.csqNumber?.clearValidators();
    this.applicationDetailsForm?.controls?.csqNumber?.updateValueAndValidity();
  }

  // This method watches the province dropdown is changing
  private watchProvince(): void {
    this.subscriptioins.push(
      this.applicationDetailsForm?.controls.province.valueChanges.subscribe(
        (value) => {
          // 1. clear the city value - so they need to re-select it!
          this.applicationDetailsForm?.controls?.city?.setValue(null);

          if (!this.isQuebecSelected) {
            // Disable check buttons and remove required on validation on QC
            // Clear the values in the radio buttons
            this.clearQuebecFields();
          }
        }
      )
    );
  }

  // This method watches the CSQ Field is changing
  // to add/remove proper validations to the dynamic fields
  private watchReceivedCSQ(): void {
    this.subscriptioins.push(
      this.applicationDetailsForm?.controls.receivedCSQ?.valueChanges.subscribe(
        (value) => {
          if (value === true) {
            // If the radio button changed to Yes,
            this.setValidatorsForCSQNumberOnly();
          } else if (value === false) {
            // If the radio button changed to No,
            this.setValidatorsForDateAppliedForCSQ();
          }

          // Clearing the validations happens at the change of province in watchProvince()
        }
      )
    );
  }

  private getProgramId(): void {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.lob.programId) {
          this.programId = caseData.lob.programId;
        }
      });
  }
}
