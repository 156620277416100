export const samplePhotos = [
  {
    filename: "photo-acceptable-1.jpg",
    label: "ACCEPTED",
  },
  {
    filename: "photo-acceptable-2.jpg",
    label: "ACCEPTED",
  },
  {
    filename: "photo-rejected-contrast.jpg",
    label: "REJECTED",
    detail: "CONTRAST",
  },
  {
    filename: "photo-rejected-shadow.jpg",
    label: "REJECTED",
    detail: "SHADOW",
  },
  {
    filename: "photo-rejected-glare.jpg",
    label: "REJECTED",
    detail: "GLARE",
  },
  {
    filename: "photo-rejected-tinted-glasses.jpg",
    label: "REJECTED",
    detail: "TINTED_GLASSES",
  },
  {
    filename: "photo-rejected-mouth-open.jpg",
    label: "REJECTED",
    detail: "MOUTH_OPEN",
  },
  {
    filename: "photo-rejected-smiling.jpg",
    label: "REJECTED",
    detail: "SMILING",
  },
  {
    filename: "photo-rejected-head-tilt.jpg",
    label: "REJECTED",
    detail: "HEAD_TILT",
  },
  {
    filename: "photo-rejected-face-cover.jpg",
    label: "REJECTED",
    detail: "FACE_COVER",
  },
];
