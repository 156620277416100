import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";

@Injectable({
  providedIn: "root",
})
export class RepUserGuard implements CanActivate {
  constructor(
    private routeLocalizer: RouteLocalizerService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(a: ActivatedRouteSnapshot, r: RouterStateSnapshot): boolean {
    const isClient = this.userService.isClientAccount();
    if (isClient) {
      this.router.navigateByUrl(
        `/${this.routeLocalizer.getCurrentRouteLang()}`
      );
      return false;
    }
    return true;
  }
}
