import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "pra-external-link",
  templateUrl: "./external-link.component.html",
  styleUrls: ["./external-link.component.scss"],
})
export class ExternalLinkComponent {
  @Input() href: string;
  @Input() class?: string;

  constructor(private elRef: ElementRef) {}

  get withIndicator(): boolean {
    /* prevents adding icons to image-based links */
    const hasImg =
      this.elRef.nativeElement.getElementsByTagName("img").length > 0
        ? true
        : false;
    return !hasImg;
  }
}
