import { Component, ChangeDetectorRef } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import { DOC_LIMITS } from "lib";

@Component({
  template: "",
})
export abstract class IMM5562SectionBaseComponent {
  formValues: any;
  isDataLoading = true;
  isTripRemoved = false;

  public trips: UntypedFormArray = new UntypedFormArray([]);
  public maxTrips = DOC_LIMITS.IMM5562.TRIP_MAX;
  public maxFieldLengths: { [key: string]: number } = {
    givenName: 100,
    familyName: 100,
    tripLength: 16,
    dateFrom: 7,
    dateTo: 7,
    destination: 31,
    purpose: 31,
  };

  constructor(protected cdRef: ChangeDetectorRef) {}

  public abstract get isFormValid(): boolean;
  public abstract get isFormTouched(): boolean;
  public abstract get value(): any;

  public abstract showFormInvalidErrors(): void;

  abstract patchFormData(formData: any): void;

  public setDataLoading(isDataBeingLoaded: boolean): void {
    this.isDataLoading = isDataBeingLoaded;
  }
}
