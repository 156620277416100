<lib-copy-block
  [body]="'INTAKE.IMM5406.FORMS.FORM_INSTRUCTIONS' | translate"
></lib-copy-block>
<form autocomplete="off">
  <ng-container
    *ngFor="
      let child of children.controls;
      index as i;
      first as isFirst;
      last as isLast
    "
    formArray="children"
  >
    <fieldset [formGroup]="child" class="form-array__fieldset">
      <legend>
        {{ "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_LEGEND" | translate }}
        {{ i + 1 }}
      </legend>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'relationship' + i"
        [formControl]="child.controls.relationship"
        [label]="'INTAKE.IMM5406.FORMS.RELATIONSHIP_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            child.controls?.relationship.touched &&
            child.controls?.relationship.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.relationship }
          }}
        </span>
      </lib-form-input>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'fullName' + i"
        [formControl]="child.controls.fullName"
        [label]="'INTAKE.IMM5406.FORMS.FULL_NAME_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            child.controls?.fullName.touched &&
            child.controls?.fullName.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.fullName }
          }}
        </span>
      </lib-form-input>

      <lib-form-datepicker
        [readOnly]="!user.can('documents:write')"
        [identifier]="'dob' + i"
        [formControl]="child.controls.dob"
        [label]="'INTAKE.IMM5406.FORMS.DOB_LABEL' | translate"
        [pastDate]="true"
      >
      </lib-form-datepicker>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'countryOfBirth' + i"
        [formControl]="child.controls.countryOfBirth"
        [label]="'INTAKE.IMM5406.FORMS.BIRTHPLACE_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            child.controls?.countryOfBirth.touched &&
            child.controls?.countryOfBirth.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.countryOfBirth }
          }}
        </span>
      </lib-form-input>

      <lib-form-select
        [editable]="user.can('documents:write')"
        [identifier]="'maritalStatus' + i"
        [formControl]="child.controls.maritalStatus"
        [label]="'INTAKE.IMM5406.FORMS.MARITAL_STATUS_LABEL' | translate"
        [options]="maritalStatusOptions"
        widthMd="true"
      ></lib-form-select>

      <lib-form-input
        [editable]="user.can('documents:write')"
        [identifier]="'emailAddress' + i"
        [formControl]="child.controls.emailAddress"
        [label]="'INTAKE.IMM5406.FORMS.EMAIL_LABEL' | translate"
        widthMd="true"
      >
        <span
          *ngIf="
            child.controls?.emailAddress.touched &&
            child.controls?.emailAddress.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.emailAddress }
          }}
        </span>
      </lib-form-input>

      <lib-form-textarea
        [editable]="user.can('documents:write')"
        [identifier]="'address' + i"
        [formControl]="child.controls.address"
        [label]="
          'INTAKE.IMM5406.FORMS.ADDRESS_LABEL'
            | translate : { max: maxFieldLengths.address }
        "
      >
        <span
          *ngIf="
            child.controls?.address.touched &&
            child.controls?.address.hasError('maxlength')
          "
        >
          {{
            "INTAKE.FORM_ERRORS.MAX_LENGTH"
              | translate : { max: maxFieldLengths.address }
          }}
        </span>
      </lib-form-textarea>
      <!-- ADD/REMOVE BUTTONS -->
      <lib-layout-row gap="one-and-half">
        <button
          *ngIf="!isFirst"
          [disabled]="isReadOnly"
          type="button"
          category="secondary"
          color="critical"
          (click)="removeChild(i)"
        >
          {{
            "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_REMOVE_BUTTON" | translate
          }}
          <span class="visually-hidden"
            >{{ "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_LEGEND" | translate }}
            {{ i + 1 }}</span
          >
        </button>
        <button
          *ngIf="isLast"
          [disabled]="isReadOnly"
          type="button"
          category="primary"
          (click)="addChild()"
        >
          {{ "INTAKE.IMM5406.FORMS.SECTION_B.CHILDREN_ADD_BUTTON" | translate }}
        </button>
      </lib-layout-row>
    </fieldset>
  </ng-container>
</form>
