import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  Router,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouterEvent,
  NavigationError,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, interval } from "rxjs";
import { Store } from "@ngrx/store";
import { map, take } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../auth-module/store/auth.actions";
import { AlertService, UserGroup } from "lib";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { HeartBeatService } from "@pr-applicant/app/shared/services/heart-beat/heart-beat.service";
import { ModalService } from "lib";
const version = require("../../../../../../package.json").version;

@Component({
  selector: "pra-localized-app",
  templateUrl: "./localized-app.component.html",
  styleUrls: ["./localized-app.component.scss"],
})
export class LocalizedAppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("mainContent") mainContent: ElementRef;

  private subs: Subscription[] = [];

  public lang: string;
  public loading = false;
  public isAuthenticated: boolean;
  public displayNavbar = false;
  public bannerBoxHeading = "Scheduled outage";
  public bannerBoxType = "problem";
  public bannerBoxBody = "";
  public applicationMode = "";
  public reloadModalId = "reloadModal";
  public countdownRelodLabel: string;
  public isReloading = false;

  private countdownReloadSeconds: number;

  constructor(
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private heartBeatService: HeartBeatService,
    private modalService: ModalService
  ) {}
  ngAfterViewInit(): void {
    this.subs.push(
      this.heartBeatService.getApplicationMode().subscribe((data) => {
        this.applicationMode = data && data.mode;

        this.countdownReloadSeconds = 30;
        this.countdownRelodLabel = this.translate.instant(
          "RELOAD.MODAL_BODY_TIMER",
          {
            seconds: this.countdownReloadSeconds,
          }
        );

        const majorVersionClient = version.split(".")[0];
        const majorVersionReleased = data.version && data.version.split(".")[0];
        if (
          data.version &&
          +majorVersionReleased >= +majorVersionClient &&
          +data.version.split(".").join("") > +version.split(".").join("")
        ) {
          this.subs.push(
            interval(1000)
              .pipe(take(30))
              .subscribe(() => {
                this.countdownReloadSeconds--;
                this.countdownRelodLabel = this.translate.instant(
                  "RELOAD.MODAL_BODY_TIMER",
                  {
                    seconds: this.countdownReloadSeconds,
                  }
                );
                if (this.countdownReloadSeconds === 1) this.reloadPage();
              })
          );

          this.openReloadModal();
        }
        const currentLang = this.routeLocalizer.getCurrentRouteLang();
        if (this.applicationMode === "banner") {
          this.bannerBoxHeading =
            currentLang === "fr" ? data.titleFr : data.titleEn;
          this.bannerBoxBody =
            currentLang === "fr" ? data.messageFr : data.messageEn;
        } else if (this.applicationMode === "outage") {
          if (this.isAuthenticated)
            this.store.dispatch(AuthActions.StartLogout());
        }
      })
    );
  }

  reloadPage() {
    this.isReloading = true;
    //Need the timeout for the porperty "this.isReloading" to propogate to UI for the overidde to work
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  public openReloadModal(): void {
    this.modalService.open(this.reloadModalId);
  }

  checkRouterEvent(event: RouterEvent): void {
    if (
      event instanceof NavigationStart &&
      !event?.url?.includes("#") &&
      !event?.url?.includes("%23")
    ) {
      this.loading = true;
    }

    if (event?.url?.includes("%23")) {
      location.pathname = decodeURI(location.pathname);
    }

    if (
      event instanceof NavigationEnd ||
      event instanceof NavigationCancel ||
      event instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  ngOnInit(): void {
    this.subs.push(
      this.router.events.subscribe((routerEvent: RouterEvent) => {
        this.checkRouterEvent(routerEvent);
      })
    );

    this.setLangFromData();
    this.subs.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState.user))
        .subscribe((user) => {
          if (user?.username) {
            this.isAuthenticated = true;
            this.displayNavbar =
              user.userPool === UserGroup.Client || !!user.repId;
          } else {
            this.isAuthenticated = false;
            this.displayNavbar = false;
          }
          if (
            user?.isLoggingOut === undefined &&
            this.isAuthenticated &&
            this.displayNavbar
          ) {
            const currentLang = this.routeLocalizer.getCurrentRouteLang();
            const isConfirmationUrl = (url: string) => {
              return url.includes("en/cases") || url.includes("fr/dossiers");
            };
            const isClientUser = (currUser: any) => {
              return currUser?.userPool === UserGroup.Client;
            };

            const urlBeforeRefresh = sessionStorage.getItem("previousUrl");
            if (
              isClientUser(user) &&
              isConfirmationUrl(urlBeforeRefresh || "")
            ) {
              this.routeLocalizer.goTo(currentLang, "CONFIRMATION_CASES");
            } else {
              this.routeLocalizer.goToLocalizedCurrentRoute(currentLang);
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  skipToMainContent(): void {
    if (this.mainContent) {
      this.mainContent.nativeElement.focus();
    }
  }

  public forceCloseAlert() {
    this.alertService.clear();
  }

  private setLangFromData(): void {
    this.subs.push(
      this.route.data.subscribe((data) => {
        this.lang = data.lang;
        this.translate.use(this.lang);
      })
    );
  }
}
