import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import {
  ICaseProfileModel,
  ProfileService,
} from "@pr-applicant/app/shared/services/profile/profile.service";
import { RouteLocalizerService } from "../route-localizer.service";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PrProfileGuard implements CanActivate {
  private lang: string;

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private routeLocalizer: RouteLocalizerService
  ) {}

  /* 
    A route guard for all routes in the case/:caseId/ > and children routes to see if profile is filled
    out and validated for purposes of autopopulate.
  */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.lang = this.routeLocalizer.getCurrentRouteLang(state.url);
    // if route has caseId param otherwise return to homepage
    if (route.paramMap.has("caseId")) {
      if (
        this.profileService.cachedProfile?.profile?.familyName !== null &&
        this.profileService.cachedProfile?.profile?.familyName !== "" &&
        this.profileService.cachedProfile?.profile?.isComplete !== null &&
        this.profileService.cachedProfile?.caseId === route.params.caseId
      ) {
        // has a cached profile no need to hit API
        return true;
      } else {
        // needs a fresh copy of profile so hit API and update observable subject and cache a profile through service
        this.profileService.getProfile(route.params.caseId);
        return this.profileService.profileObs$.pipe(
          map((data: ICaseProfileModel) => {
            if (
              data.profile.isComplete !== null &&
              data.profile.familyName !== null &&
              data.profile.familyName !== ""
            ) {
              // profile exists and is filled out, allow viewing of case page
              return true;
            } else {
              // profile is not filled out redirect to profile page to be filled out

              const newNestedPath = this.routeLocalizer.getNestedPath(
                this.lang,
                ["INTAKE", "PROFILE"]
              );
              return this.router.navigate([
                `/${this.lang}/${newNestedPath}${route.params.caseId}`,
              ]);
            }
          })
        );
      }
    } else {
      return this.router.navigate([`/${this.lang}`]);
    }
  }
}
