import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { Reason } from "../../../core/models/document.model";
import { DocumentService } from "../../../shared/services/document/document.service";

@Component({
  selector: "pra-upload-photo-page",
  templateUrl: "./upload-photo-page.component.html",
  styleUrls: ["./upload-photo-page.component.scss"],
})
export class UploadPhotoPageComponent implements OnInit {
  public case: any;
  public rejectionReasons: string[] = [];

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private authService: AuthService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.store.select("selectedCase").subscribe((caseData) => {
      this.case = caseData;
    });
    this.routeLocalizer.setTranslatedTitle("PHOTOS.PAGE_TITLE", this.nameParam);

    this.authService.checkSession();

    if (
      this.case?.documents !== undefined &&
      this.case?.documents[0]?.documentName !== undefined
    ) {
      this.getTranslatedReasons();
    }
  }

  public get nameParam(): object {
    const name =
      this.case?.givenName !== null
        ? `${this.case?.givenName} ${this.case?.surname}`
        : this.case?.surname;
    return {
      name,
    };
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  private get photoId() {
    if (this.case.documents.length > 0) {
      return this.case.documents[0].id;
    } else {
      return null;
    }
  }

  private getTranslatedReasons(): void {
    this.documentService
      .getDocumentRejectionReasonsByDocumentId(this.case.id, this.photoId)
      .then((reasons) => {
        const langKey =
          this.lang === "fr" ? "rejectionReasonFr" : "rejectionReasonEn";
        const selectedReasons = reasons.filter(
          (r: Reason) => Boolean(r.selected) === true
        );
        this.rejectionReasons = selectedReasons.map((r: Reason) => r[langKey]);
      });
  }
}
