import { Component, Input } from "@angular/core";

export interface IInformationBoxConfig {
  backgroundColor: string;
  borderColor: string;
  icon?: string;
  iconColor?: string;
  iconAltText?: string;
}

@Component({
  selector: "lib-information-box",
  templateUrl: "./information-box.component.html",
  styleUrls: ["./information-box.component.scss"],
})
export class InformationBoxComponent {
  @Input() config?: IInformationBoxConfig;
}
