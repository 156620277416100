import * as fromApp from "../../../../store/app.reducer";

import { ActivatedRoute, Router } from "@angular/router";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertService, ModalService, documentTypes } from "lib";

import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { CanComponentDeactivate } from "../../../../routing/guards/nav.guard";
import { Document } from "../../../../core/models/document.model";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { IMM0008LanguageDetailsFormComponent } from "../../../forms/IMM0008/language-details-form/language-details-form.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import FormNavbarItems from "../imm0008-form-navbar-items.json";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm0008-page8",
  templateUrl: "./page8.component.html",
  styleUrls: [
    "./page8.component.scss",
    "../../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM0008Page8Component
  implements OnInit, CanComponentDeactivate, AfterContentChecked
{
  public lang: string;

  public navigationModalId = "navigationModal";
  public isFormTouched: boolean;
  public isFormSaved = false;
  public formData: any;
  public imm0008_version: number;
  public isLoading = true;
  public FormNavbarItems = FormNavbarItems;
  mobileQuery: MediaQueryList;
  private case: any;
  private imm0008DocumentTypeId = documentTypes.imm0008.id;
  private formName = "languageDetails";

  @ViewChild("languageDetailsForm", { static: false })
  languageDetailsForm: IMM0008LanguageDetailsFormComponent;
  private _mobileQueryListener: () => void;
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private modalService: ModalService,
    private documentService: DocumentService,
    private store: Store<fromApp.State>,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseState) => {
        if (caseState) {
          this.case = caseState;
        }
      });
    this.getFormData();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  private async getFormData() {
    try {
      const imm0008DocId = this.case?.documents.filter(
        (doc: any) => doc.documentTypeId === this.imm0008DocumentTypeId
      )[0].id;
      this.formData = await this.documentService.getFormByDocumentIdAndPage(
        this.case.id,
        imm0008DocId,
        this.formName
      );
      this.imm0008_version = this.formData.version;
      this.isLoading = false;
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  public async saveForm() {
    const formInvalid = this.languageDetailsForm.languageDetailsForm.invalid;
    if (formInvalid) {
      return;
    }
    const formData = this.languageDetailsForm.preparedFormValue;
    const imm0008DocId = this.case?.documents.filter(
      (doc: Document) => doc.documentTypeId === this.imm0008DocumentTypeId
    )[0].id;
    const nextPage = this.routeLocalizer.get(this.lang, "FORM_PAGE9", "../");
    this.isFormTouched = this.languageDetailsForm.languageDetailsForm.touched;

    if (this.isFormTouched) {
      try {
        const response =
          await this.documentService.updateFormByDocumentIdAndPage(
            this.case.id,
            imm0008DocId,
            this.formName,
            formData,
            this.imm0008_version
          );
        if (response) {
          if (response?.hasApiError) {
            const errorStatus = response.error.response.status;
            if (errorStatus === 409) {
              this.alertService.danger(
                this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          } else {
            this.isFormSaved = true;
            this.router.navigate([nextPage], { relativeTo: this.route });
          }
        }

        //this.isFormSaved = true;
        //this.router.navigate([nextPage], { relativeTo: this.route });
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      // dont save (form hasnt been changed) and navigate
      this.router.navigate([nextPage], { relativeTo: this.route });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // check if form has been touched (not saved yet)
    this.isFormTouched = this.languageDetailsForm.languageDetailsForm.touched;
    if (this.isFormTouched && !this.isFormSaved) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public get nextButtonKey(): string {
    if (!this.user.can("documents:write")) {
      return "INTAKE.FORM_PAGE_NEXT";
    }
    return "INTAKE.FORM_PAGE_SAVE";
  }
}
