import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from "@angular/core";
import { ComponentLoadDirective } from "../../../core/directives/componentLoad.directive";
import { ActivatedRoute, Router } from "@angular/router";
import { IMM5406StartComponent } from "../../forms/IMM5406/imm5406-start/imm5406-start.component";
import { IMM5406SectionAComponent } from "../../forms/IMM5406/imm5406-section-a/imm5406-section-a.component";
import { IMM5406SectionBComponent } from "../../forms/IMM5406/imm5406-section-b/imm5406-section-b.component";
import { IMM5406SectionCComponent } from "../../forms/IMM5406/imm5406-section-c/imm5406-section-c.component";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import {
  selectQueryParams,
  selectRouteParams,
} from "../../../store/app.reducer";
import { retry, switchMap } from "rxjs/operators";
import { WebFormService } from "../../../shared/services/web-form/web-form.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import {
  loadCurrentFormForIMMDocument,
  loadCurrentIMMDocument,
} from "../../../shared/currentIMMDocument/store/actions/currentIMMDocument.actions";
import { from, of, zip, Observable, Subscription } from "rxjs";
import { IFormInfo } from "../../../shared/services/web-form/models";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertService,
  documentTypes,
  ModalService,
  ValidationService,
} from "lib";
import { Document } from "../../../core/models/document.model";
import { CanComponentDeactivate } from "../../../routing/guards/nav.guard";
import * as _ from "lodash";
import FormNavbarItems from "./imm5406-form-navbar-items.json";
import { MediaMatcher } from "@angular/cdk/layout";
import { DocumentService } from "@pr-applicant/app/shared/services/document/document.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5406",
  templateUrl: "./IMM5406page.component.html",
  styleUrls: [
    "./IMM5406page.component.scss",
    "../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM5406PageComponent
  implements OnInit, OnDestroy, AfterViewChecked, CanComponentDeactivate
{
  @ViewChild(ComponentLoadDirective, { static: true })
  praComponentHost: ComponentLoadDirective;

  public currentLoadedComponent: ComponentRef<any>;
  public loading = false;
  public FormNavbarItems = FormNavbarItems;
  public curr = 0;
  public formVersion: number;

  private caseId: string;
  private document?: Document;
  private formId?: string;
  public goBackModalId = "goBackModalId";
  public nextCopyHTML: string;
  public isReadOnly: boolean;
  private currentPage: string = "page1";
  private all5406StepPages = ["page1", "page2", "page3", "page4"];
  private currentLang: string;
  private mainSubscr$: Subscription;
  private selectedCaseSubs$: Subscription;
  private addFamMemberSubs$: Subscription;
  private deleteFamMemberSubs$: Subscription;
  private interFormsSignal$: Subscription;
  private formCRUDSubs$: Subscription; // subscription to web service when CREATE, REPLACE UPDATE form etc
  private allSubscriptions: Subscription[] = [];
  private isNavigatingInsideForm = true;
  private navigationModalSelection = false;
  private isCompleteSubsc$: Subscription;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private alertService: AlertService,
    private modalService: ModalService,
    private authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private webFormSvc: WebFormService,
    private cdRef: ChangeDetectorRef,
    private validationService: ValidationService,
    media: MediaMatcher,
    private documentService: DocumentService,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle("INTAKE.IMM5406.PAGES.PAGE_TITLE");
    this.allSubscriptions.push(
      this.modalService.navigateAwaySelection$.subscribe((selection) => {
        this.navigationModalSelection = selection;
      })
    );
    this.startMainSubscription();
    this.startFamilyMemberSubscription();
    this.isReadOnly = !this.user.can("documents:write");
  }

  ngOnDestroy(): void {
    this.allSubscriptions.forEach((subsc) => subsc.unsubscribe());
    this.isCompleteSubsc$?.unsubscribe();
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  canDeactivate(): boolean | Observable<boolean> {
    const component = this.currentLoadedComponent?.instance;
    if (
      !this.isNavigatingInsideForm &&
      component?.isFormDataUnSaved &&
      component.isFormDataUnSaved()
    ) {
      this.modalService.open("navigationModal");
      return this.modalService.navigateAwaySelection$;
    }
    return true;
  }

  /*********** PUBLIC ***********/

  public saveAndNext(): void {
    this.isNavigatingInsideForm = true;
    const newNestedPath = this.routeLocalizer.getNestedPath(this.currentLang, [
      "INTAKE",
      "INTAKE_CASE_DETAILS",
    ]);
    // Skipping the saving if it is read-only
    if (this.isReadOnly) {
      this.currentPage === this.all5406StepPages[0]
        ? this.router.navigate([
            `/${this.currentLang}/${newNestedPath}${this.caseId}`,
          ])
        : this.navigateTo(this.nextPage);
      return;
    }
    if (this.currentPage === this.all5406StepPages[0]) {
      // Navigating back to the main application page
      // Saved successfully, return success banner
      if (this.document?.isComplete === false) {
        this.isCompleteSubsc$ = this.documentService.isCompleteObs$.subscribe(
          () => {
            this.router.navigate([
              `/${this.currentLang}/${newNestedPath}${this.caseId}`,
            ]);
          }
        );
        this.documentService.updateDocumentIsComplete(
          Number(this.caseId),
          this.document.id,
          true
        );
        return;
      } else {
        this.router.navigate([
          `/${this.currentLang}/${newNestedPath}${this.caseId}`,
        ]);
      }
      // Saved successfully, return success banner
      this.alertService.success(
        this.translate.instant("INTAKE.ALERTS.DOCUMENTS_SAVED")
      );
    } else {
      const formDataToSave = this.currentLoadedComponent.instance.getFormData();
      if (formDataToSave.isInvalid) {
        alert("There are errors on page. Your data can't be saved");
        return;
      }
      this.loading = true;
      if (this.formCRUDSubs$) {
        this.formCRUDSubs$.unsubscribe();
      }
      this.formCRUDSubs$ = from(
        this.webFormSvc.updateFormSection(
          { ...this.formInfo, sectionId: formDataToSave.sectionName },
          { form: formDataToSave.sectionInfo, version: this.formVersion }
        )
      ).subscribe(
        (data) => {
          this.navigateTo(this.nextPage);
        },
        (err) => {
          this.loading = false;
          this.alertService.danger(this.alertTechnicalError);
        }
      );
    }
  }

  public navigateTo(pageId: string): void {
    if (pageId === undefined || pageId === this.all5406StepPages[0]) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { pageId },
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { pageId },
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
    }
  }

  public navigateBack(forceNavigation?: boolean): void {
    this.isNavigatingInsideForm = true;
    const unsavedData =
      this.currentPage !== this.all5406StepPages[0] &&
      this.currentLoadedComponent.instance.isFormDataUnSaved();
    if (unsavedData && !forceNavigation) {
      this.openDeleteModal();
      return;
    }
    if (this.currentPage === this.all5406StepPages[0]) {
      const newNestedPath = this.routeLocalizer.getNestedPath(
        this.currentLang,
        ["INTAKE", "INTAKE_CASE_DETAILS"]
      );
      this.router.navigate([
        `/${this.currentLang}/${newNestedPath}${this.caseId}`,
      ]);
    } else {
      this.navigateTo(this.previousPage);
    }
  }

  public openDeleteModal(): void {
    this.modalService.open(this.goBackModalId);
  }

  public closeModal(): void {
    this.modalService.close(this.goBackModalId);
    this.isNavigatingInsideForm = false;
  }

  public forceGoBack() {
    this.closeModal();
    this.navigateBack(true);
  }

  formSideNavbarNavigation(pageId: number) {
    this.currentPage = this.all5406StepPages[pageId + 2];
    this.navigateBack();
  }

  public get nextButtonKey(): string {
    if (this.currentPage === this.all5406StepPages[0]) {
      this.nextCopyHTML = this.isReadOnly
        ? "INTAKE.FORM_RETURN_TO_APPLICATION"
        : "INTAKE.FORM_PAGE_COMPLETE";
    } else {
      this.nextCopyHTML = this.isReadOnly
        ? "INTAKE.FORM_PAGE_NEXT"
        : "INTAKE.FORM_PAGE_SAVE";
    }
    return this.nextCopyHTML;
  }

  public get disableNextButton(): boolean {
    if (this.currentPage === this.all5406StepPages[0]) {
      return this.currentLoadedComponent?.instance?.forms?.length === 0;
    } else {
      return !this.currentLoadedComponent?.instance?.isFormValid;
    }
  }

  /*********** PRIVATE ***********/

  private get alertDeleteSuccess(): string {
    return this.translate.instant(
      "INTAKE.TABLE_ALERT.DELETE_FAMILY_MEMBER_SUCCESS"
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private get nextPage(): string {
    return this.all5406StepPages[
      this.all5406StepPages.indexOf(this.currentPage) + 1
    ];
  }

  private get previousPage(): string {
    return this.all5406StepPages[
      this.all5406StepPages.indexOf(this.currentPage) - 1
    ];
  }

  private get formInfo(): IFormInfo {
    const info: IFormInfo = {
      caseId: this.caseId || "",
      documentTypeId: documentTypes.imm5406.id,
    };
    if (this.document) {
      info.documentId = this.document.id;
    }
    if (this.formId) {
      info.formId = this.formId;
    }
    return info;
  }

  private startMainSubscription(): void {
    const self = this;
    this.selectedCaseSubs$ = this.store
      .select("selectedCase")
      .subscribe((selectedCase) => {
        this.document = selectedCase.caseDocuments?.find(
          (item: any) => item.documentTypeId === documentTypes.imm5406.id
        );
      });
    this.mainSubscr$ = zip(
      this.store.select(selectRouteParams),
      this.store.select(selectQueryParams)
    )
      .pipe(
        switchMap((data) => {
          self.loading = true;
          const [routeParams, queryParams] = data;
          this.caseId = routeParams?.caseId;
          this.formId = queryParams?.formId;
          this.currentPage = queryParams?.pageId || this.all5406StepPages[0];
          const isNavigatingAway =
            !this.isNavigatingInsideForm || this.navigationModalSelection;
          const isEmpty = _.isEmpty(routeParams) && _.isEmpty(queryParams);
          if (isNavigatingAway || isEmpty) {
            this.loading = false;
            return of({ isNavigatingAway: true });
          }
          this.praComponentHost.viewContainerRef.clear();
          if (this.document && !this.formId) {
            return from(this.webFormSvc.getDocumentData(this.formInfo)).pipe(
              retry(3)
            );
          } else if (!this.formId) {
            return this.webFormSvc.createDocument(this.formInfo);
          } else {
            return from(this.webFormSvc.getFormData(this.formInfo)).pipe(
              retry(3)
            );
          }
        })
      )
      .subscribe(
        (data) => {
          if (!data.isNavigatingAway) {
            if (this.formId && this.formId !== data.form._id) {
              console.error(
                `API is returning data for different form. Id passed is:
            ` +
                  this.formId +
                  " ID of form received is : " +
                  data.form._id
              );
            }
            if (this.formId) {
              this.store.dispatch(
                loadCurrentFormForIMMDocument({ currentForm: data })
              );
              this.formVersion = 1;
            } else {
              this.store.dispatch(
                loadCurrentIMMDocument({ documentInfo: data })
              );
            }
            this.loadComponentForStep(this.currentPage);
            this.isNavigatingInsideForm = false;
          }
          self.loading = false;
        },
        (error) => {
          self.loading = false;
          this.alertService.danger(this.alertTechnicalError);
        }
      );
    this.allSubscriptions.push(this.selectedCaseSubs$);
    this.allSubscriptions.push(this.mainSubscr$);
  }

  private startFamilyMemberSubscription(): void {
    this.interFormsSignal$ = this.webFormSvc.immDocumentSignal$.subscribe(
      (signal) => {
        if (signal.message === "ADD_NEW_FAMILY_MEMBER") {
          this.isNavigatingInsideForm = true;
          this.addNewFamilyMember();
        } else if (signal.message === "EDIT_FAMILY_MEMBER" && signal.formId) {
          this.isNavigatingInsideForm = true;
          this.editFamilyMember(signal.formId);
        } else if (signal.message === "DELETE_FAMILY_MEMBER" && signal.formId) {
          this.deleteFamilyMember(signal.formId);
        }
      }
    );
    this.allSubscriptions.push(this.interFormsSignal$);
  }

  private loadComponentForStep(stepNbr: string): void {
    const viewContainerRef = this.praComponentHost.viewContainerRef;
    viewContainerRef.clear();
    let componentFactory;
    switch (stepNbr) {
      case this.all5406StepPages[1]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5406SectionAComponent
          );
        this.curr = 1;
        break;
      }
      case this.all5406StepPages[2]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5406SectionBComponent
          );
        this.curr = 2;
        break;
      }
      case this.all5406StepPages[3]: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5406SectionCComponent
          );
        this.curr = 3;
        break;
      }
      case this.all5406StepPages[0]:
      default: {
        componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(
            IMM5406StartComponent
          );
        this.curr = 0;
        break;
      }
    }
    this.currentLoadedComponent = viewContainerRef.createComponent<
      | IMM5406StartComponent
      | IMM5406SectionAComponent
      | IMM5406SectionBComponent
      | IMM5406SectionCComponent
    >(componentFactory);
    this.currentLoadedComponent.instance.data = { formId: this.formId };
  }

  private addNewFamilyMember(): void {
    const self = this;
    if (this.addFamMemberSubs$) {
      this.addFamMemberSubs$.unsubscribe();
    }
    this.addFamMemberSubs$ = from(
      self.webFormSvc.createFormData(this.formInfo)
    ).subscribe(
      (dataForm) => {
        self.formId = dataForm.form._id;
        self.router.navigate([], {
          relativeTo: self.activatedRoute,
          queryParams: {
            pageId: self.all5406StepPages[1],
            documentId: self.document?.id,
            formId: dataForm.form._id,
          },
          queryParamsHandling: "merge",
        });
      },
      (error) => {
        this.alertService.danger(this.alertTechnicalError);
      }
    );
  }

  private editFamilyMember(formId: string): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        pageId: this.all5406StepPages[1],
        documentId: this.document?.id,
        formId,
      },
      queryParamsHandling: "merge",
    });
  }

  private deleteFamilyMember(formId: string): void {
    if (this.deleteFamMemberSubs$) {
      this.deleteFamMemberSubs$.unsubscribe();
    }
    this.deleteFamMemberSubs$ = from(
      this.webFormSvc.deleteFormData({
        ...this.formInfo,
        formId,
      })
    ).subscribe(
      (data) => {
        this.alertService.success(this.alertDeleteSuccess);
        this.store.dispatch(loadCurrentIMMDocument({ documentInfo: data }));
      },
      (error) => {
        this.alertService.danger(this.alertTechnicalError);
      }
    );
  }
}
