<h2 class="emphasis">{{ "PHOTOS.REQUIREMENTS_HEADING" | translate }}</h2>

<div class="requirements-container">
  <div class="requirements-text">
    <p>{{ "PHOTOS.REQUIREMENTS_TEXT" | translate : nameParam }}</p>

    <ng-container *ngFor="let set of requirementSets">
      <h3>{{ set.LABEL }}</h3>
      <ul>
        <li *ngFor="let item of set.LIST" [innerHTML]="item"></li>
      </ul>
    </ng-container>
  </div>

  <ul class="example-images">
    <li *ngFor="let photo of photos">
      <img [src]="'../../../assets/images/' + photo.filename" alt="" />
      <span
        class="photo-label"
        [ngClass]="{
          accepted: photo.label === 'ACCEPTED',
          rejected: photo.label === 'REJECTED'
        }"
      >
        {{ "PHOTOS.SAMPLE_PHOTOS." + photo.label | translate }}
      </span>
      <span *ngIf="photo.detail" class="photo-detail">
        {{ "PHOTOS.SAMPLE_PHOTOS." + photo.detail | translate }}
      </span>
    </li>
  </ul>
</div>
