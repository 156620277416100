<div>
  <div class="attachment">
    <div class="download">
      <ng-container [ngSwitch]="_fileState">
        <i
          class="fa-regular fa-circle-check static icon"
          *ngSwitchCase="'success'"
        ></i>
        <i
          class="fa-regular fa-spinner-third icon"
          *ngSwitchCase="'loading'"
        ></i>
        <i
          class="fa-regular fa-triangle-exclamation static icon"
          *ngSwitchCase="'warning'"
        ></i>
        <i
          class="fa-regular fa-circle-xmark static icon"
          *ngSwitchCase="'error'"
        ></i>
      </ng-container>
      <a class="table__link" (click)="handleDownload()">
        {{ fileName }}
      </a>
    </div>
    <button
      type="button"
      *ngIf="canDelete"
      (click)="handleDelete()"
      category="plain"
      color="critical"
    >
      <lib-icon-chooser icon="delete"></lib-icon-chooser>
      <!-- {{
        _fileState === "error"
          ? ("INTAKE.TABLE_BUTTON.DISMISS" | translate)
          : ("INTAKE.TABLE_BUTTON.DELETE" | translate)
      }} -->
      <span class="visually-hidden">{{ fileName }}</span>
    </button>
  </div>
  <div *ngIf="fileStatus === 'incomplete'" class="subtext">
    {{ "INTAKE.DOC_STATUS.INCOMPLETE" | translate }}
  </div>
  <div *ngIf="error" class="subtext__error">
    {{ error }}
  </div>
</div>
