<!-- main site header -->
<div class="header">
  <div class="header__container">
    <a
      class="header__container__a"
      [href]="'HEADER.LOGO.URL' | translate"
      target="_blank"
    >
      <img
        [src]="'HEADER.LOGO.SRC' | translate"
        class="header__container__logo"
        [alt]="'HEADER.LOGO.ALT' | translate"
      />
      <span class="visually-hidden">{{ "EXTERNAL_LINK" | translate }}</span>
    </a>
    <button
      class="header__container__lang btn-unstyled"
      category="plain"
      (click)="routeLocalizer.goToLocalizedCurrentRoute(toggleLang)"
    >
      <span class="visually-hidden" [lang]="'HEADER.TOGGLE_LANG' | translate">
        {{ "HEADER.TOGGLE_SR_TEXT" | translate }}
      </span>
      <span class="header__container__lang--mobile" aria-hidden="true">
        {{ "HEADER.LANGUAGE_TOGGLE_MOBILE" | translate }}
      </span>
      <span
        class="header__container__lang--desktop"
        aria-hidden="true"
        [lang]="'HEADER.TOGGLE_LANG' | translate"
      >
        {{ "HEADER.LANGUAGE_TOGGLE_DESKTOP" | translate }}
      </span>
    </button>
  </div>
</div>
