<h2>
  <strong>{{
    "INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_HEADING" | translate
  }}</strong>
</h2>
<lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
<div class="certificate-number-section" *ngIf="!isLoading">
  <lib-form-radio
    *ngIf="!viewOnly"
    [legend]="'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_RADIO' | translate"
    firstId="yes"
    secondId="no"
    [firstValue]="true"
    [secondValue]="false"
    [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
    [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
    [formControl]="form.controls.hasCommCert"
    [required]="true"
  ></lib-form-radio>
  <lib-information-box
    [config]="informationBoxConfig"
    *ngIf="form.controls.hasCommCert.value === false"
  >
    <div
      class="information-box-text"
      [innerHTML]="
        'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_DISCLAIMER_NO' | translate
      "
    ></div
  ></lib-information-box>
  <lib-information-box
    [config]="informationBoxConfig"
    *ngIf="form.controls.hasCommCert.value === true"
  >
    <div
      class="information-box-text"
      [innerHTML]="
        'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_DISCLAIMER_YES' | translate
      "
    ></div
  ></lib-information-box>
  <div class="editable" *ngIf="!viewOnly && form.controls.hasCommCert.value">
    <lib-form-autocomplete
      [label]="
        'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_ENTITY_LIST_LABEL' | translate
      "
      identifier="designated-entities-list"
      class="search-form__input"
      [options]="designatedEntityList"
      [placeholder]="'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_SEARCH' | translate"
      [formControl]="form.controls.designatedEntityId"
      [showSearch]="true"
      [preventAutoselect]="true"
      [required]="true"
    >
      <span
        *ngIf="
          form.get('designatedEntityId')?.touched &&
          form.get('designatedEntityId')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-autocomplete>
    <div class="field-container">
      <lib-form-input
        [label]="
          'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_COMM_CERT_NUMBER_LABEL'
            | translate
        "
        identifier="CommCertNumber"
        [placeholder]="
          'INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_CERTIFICATE_PLACEHOLDER'
            | translate
        "
        [formControl]="form.controls.commCert"
        [required]="true"
      >
        <span
          *ngIf="
            form.get('commCert')?.touched &&
            form.get('commCert')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            form.get('commCert')?.touched &&
            form.get('commCert')?.hasError('invalid')
          "
        >
          {{ "INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_INVALID" | translate }}
        </span>
      </lib-form-input>
    </div>
    <button class="btn btn-primary submit-btn" type="button" (click)="save()">
      {{ "INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_SAVE" | translate }}
    </button>
  </div>
  <div class="view-only" *ngIf="viewOnly">
    <ul class="list-unstyled">
      <li>
        <strong
          >{{
            "INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_ENTITY_LIST_LABEL"
              | translate
          }}{{ "COLON" | translate }}</strong
        >
        {{ designatedEntityText }}
      </li>
      <li>
        <strong
          >{{
            "INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_COMM_CERT_NUMBER_LABEL"
              | translate
          }}{{ "COLON" | translate }}</strong
        >
        {{ form.controls.commCert.value }}
      </li>
    </ul>
  </div>
</div>
