<h1 class="emphasis">{{ "ADDRESS.PAGE_HEADING" | translate }}</h1>
<div [innerHTML]="'ADDRESS.INTRO_TEXT' | translate : nameParam"></div>

<div *ngIf="rejectionReasons.length > 0" class="rejection-notice">
  <b>{{ "ADDRESS.REJECTION_NOTICE" | translate : nameParam }}</b>
  <ul>
    <li *ngFor="let reason of rejectionReasons">
      {{ reason }}
    </li>
  </ul>
</div>

<h2 class="emphasis">{{ "ADDRESS.FORM_HEADING" | translate : nameParam }}</h2>
<form [formGroup]="addressForm" autocomplete="off" (ngSubmit)="onSubmit()">
  <!-- line 1 -->
  <fieldset>
    <lib-form-input
      identifier="mailingAddress1"
      [formControl]="addressForm.controls.mailingAddress1"
      [label]="'ADDRESS.FORM_LABELS.ADDRESS_1' | translate"
      required="true"
    >
      <span
        *ngIf="
          addressForm.get('mailingAddress1')?.touched &&
          addressForm.get('mailingAddress1')?.hasError('required')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          addressForm.get('mailingAddress1')?.touched &&
          addressForm.get('mailingAddress1')?.hasError('whitespace')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.WHITESPACE" | translate }}
      </span>
    </lib-form-input>

    <!-- line 2 -->
    <lib-form-input
      identifier="mailingAddress2"
      [formControl]="addressForm.controls.mailingAddress2"
      [label]="'ADDRESS.FORM_LABELS.ADDRESS_2' | translate"
    ></lib-form-input>

    <!-- city -->
    <lib-form-input
      identifier="city"
      [formControl]="addressForm.controls.city"
      [label]="'ADDRESS.FORM_LABELS.CITY' | translate"
      required="true"
    >
      <span
        *ngIf="
          addressForm.get('city')?.touched &&
          addressForm.get('city')?.hasError('required')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-input>

    <!-- province -->
    <lib-form-select
      identifier="province"
      [formControl]="addressForm.controls.province"
      [label]="'ADDRESS.FORM_LABELS.PROVINCE' | translate"
      [options]="provinces"
      required="true"
    >
      <span
        *ngIf="
          addressForm.get('province')?.touched &&
          addressForm.get('province')?.hasError('required')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>

    <!-- postal code -->
    <lib-form-input
      identifier="postalCode"
      [formControl]="addressForm.controls.postalCode"
      [label]="'ADDRESS.FORM_LABELS.POSTAL_CODE' | translate"
      required="true"
    >
      <span
        *ngIf="
          addressForm.get('postalCode')?.touched &&
          addressForm.get('postalCode')?.hasError('required')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          addressForm.get('postalCode')?.touched &&
          addressForm.get('postalCode')?.hasError('whitespace')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.WHITESPACE" | translate }}
      </span>
      <span
        *ngIf="
          addressForm.get('postalCode')?.touched &&
          addressForm.get('postalCode')?.hasError('CAPostalCode')
        "
      >
        {{ "ADDRESS.FORM_ERRORS.POSTAL_CODE" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <lib-layout-row gap="one-and-half">
    <a [routerLink]="['../']" category="secondary">
      {{ "ADDRESS.BACK_LINK" | translate }}
    </a>
    <button type="submit" category="primary" [disabled]="enableNext()">
      {{ "ADDRESS.SUBMIT_BUTTON" | translate }}
    </button>
  </lib-layout-row>
</form>
