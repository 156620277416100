import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import * as CaseActions from "../../shared/case-module/store/case.actions";
import * as fromApp from "../../store/app.reducer";
import { Address } from "./address.model";
import { CaseService } from "../../shared/case-module/case.service";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";

const apiVersion = environment.API_VERSION;

@Injectable({
  providedIn: "root",
})
export class AddressService {
  private apiName: string;
  private caseId: string;
  private case: any;

  constructor(
    private store: Store<fromApp.State>,
    private caseService: CaseService,
    private awsService: AWSService
  ) {
    this.apiName = environment.API_NAME;
    this.getApplicantCaseId();
  }

  private getApplicantCaseId(): void {
    this.store.select("selectedCase").subscribe((caseState) => {
      if (caseState.id) {
        this.case = caseState;
        this.caseId = caseState.id;
      }
    });
  }

  async updateAddress(address: Address) {
    const apiPath = `/${apiVersion}/cases/${this.caseId}/addresses`;
    const init = {
      body: {
        ...address,
      },
      headers: {
        Authorization: await this.awsService.getToken(),
      },
    };

    try {
      const { data } = await this.awsService.api.put(
        this.apiName,
        apiPath,
        init
      );
      this.store.dispatch(new CaseActions.UpdateAddress(address));
      await this.caseService.getCaseAndDocumentsByCaseId(
        this.case.emailAddress,
        this.caseId
      ); // update state before navigating

      return data;
    } catch (error) {
      throw error;
    }
  }
}
