import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { ResetComponent } from "../../components/reset/reset.component";
import { AuthService } from "../../services/auth.service";
import routePaths from "../../../../routing/route-paths";
import { AlertService, ValidationService } from "lib";

@Component({
  selector: "pra-sign-up-page",
  templateUrl: "./sign-up-page.component.html",
  styleUrls: ["./sign-up-page.component.scss"],
})
export class SignUpPageComponent implements OnInit {
  public currentLang: string;
  public signUpForm: FormGroup;
  public isSignUp = true; // used for test
  public isClientUser = true;
  @ViewChild("passwordsForm" /* #name or Type*/, { static: false })
  passwordsForm: ResetComponent;

  public emailAlreadyExists = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    this.signUpForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      terms: new FormControl("", [Validators.requiredTrue]),
    });
    this.isClientUser = !this.routeLocalizer.isUrlRepPrefixed;
  }

  public onSubmitNewAccount() {
    if (
      this.signUpForm.status === "INVALID" ||
      this.passwordsForm.resetPasswordForm.status === "INVALID"
    ) {
      return;
    }
    const termsAccepted = this.signUpForm.value.terms;

    if (termsAccepted) {
      const newPassword = this.passwordsForm.resetPasswordForm.value.password;
      const email = this.signUpForm.value.email;

      //check if email has special characters
      const specialCharacters = /[`!#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;

      if (!specialCharacters.test(email)) {
        // call auth service to create new account
        this.authService
          .signUp(email, newPassword)
          .then((response) => {
            this.routeLocalizer.goTo(this.currentLang, "SIGNUP_VERIFICATION");
          })
          .catch((err) => {
            if (err.code === "UsernameExistsException") {
              this.emailAlreadyExists = true;
              this.alertService.danger(this.alertEmailExists);
              this.signUpForm.controls.email.setErrors({ emailExists: true });
            } else if (err.code === "InvalidPasswordException") {
              this.alertService.danger(this.alertBadPassword);
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          });
      } else {
        const errorAlertSpecialCharacters = this.translate.instant(
          "SIGNUP.ERRORS.EMAIL_WITH_SPECIAL_CHARACTERS"
        );
        return this.alertService.danger(errorAlertSpecialCharacters);
      }
    }
  }

  private get alertEmailExists(): string {
    return this.translate.instant("SIGNUP.ERRORS.EMAIL_EXISTS");
  }
  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }
  private get alertBadPassword(): string {
    return this.translate.instant("RESET.ALERTS.PASSWORD_SECURITY");
  }
}
