import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, uploadFileTypes, CropperCoordinatesBias } from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import * as fromApp from "../../../store/app.reducer";
import { PhotoService } from "../photo.service";
import { Router } from "@angular/router";
import { DocumentService } from "../../../shared/services/document/document.service";
import routePaths from "../../../routing/route-paths";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { CroppedEvent } from "ngx-photo-editor";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-photo-upload",
  templateUrl: "./photo-upload.component.html",
  styleUrls: ["./photo-upload.component.scss"],
})
export class PhotoUploadComponent implements OnInit, OnDestroy {
  @Input() public nameParam: any;
  public sizeError: string | null;
  public typeError: string | null;
  public case: any;
  public doc: any;
  public rejectionReasons: string[] = [];
  private maxFileSize = 4000000;
  public selfDeclaredPhoto: boolean;
  public currentLang: string;
  public photoEditorModal = "photoEditorModal";
  public acceptedFileTypes = uploadFileTypes.photo.types;
  public showPhotoContent: boolean = false;

  public photoisBeingValidated: boolean = false;
  public validationFeedback: any = null;
  public showValidationAcknowledgement: boolean = false;
  public validationAcknowledgment: boolean;

  public templatePhoto: any;
  public templateCoordinates: any;
  public templatePhotoLink: any;

  public showPreviewIMG: boolean = false;
  public disableReplaceBtn: boolean = false;
  public focusSet: Number = -1;

  public selectedPhoto: {
    image?: File;
    url?: string | SafeUrl;
    coordinates?: CropperCoordinatesBias;
  } = {};

  public subscriptions: Subscription[] = [];
  public tempOptionsList: any[] = [
    {
      id: "Photographer Digital",
      value: "1",
      label: "PHOTOS.PHOTO_TYPE_DIGITAL",
    },
    {
      id: "Photographer Scanned",
      value: "2",
      label: "PHOTOS.PHOTO_TYPE_PRINTED",
    },
  ];
  public photoTypeControl = new FormControl(null);
  constructor(
    private alertService: AlertService,
    private translate: TranslateService,
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService,
    private photoService: PhotoService,
    private documentService: DocumentService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.subscriptions.push(
      this.store.select("selectedCase").subscribe((caseData) => {
        this.case = caseData;
      })
    );

    if (this.case?.documents !== undefined && this.photoDocumentExist()) {
      this.showPhotoContent = true;
      this.getDocumentFromStorage(true);
      this.getTranslatedReasons();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public get buttonTextKey(): string {
    return this.selectedPhoto?.url
      ? "PHOTOS.REPLACE_BUTTON"
      : "PHOTOS.UPLOAD_BUTTON";
  }

  public get enableSubmitButton(): boolean {
    if (this.validationFeedback !== null) {
      if (this.allValidationPassed) {
        return !this.selfDeclaredPhoto || !this.photoTypeControl.value;
      } else {
        return (
          !this.selfDeclaredPhoto ||
          !this.photoTypeControl.value ||
          !this.validationAcknowledgment
        );
      }
    } else {
      return true;
    }
  }

  public get allValidationPassed(): boolean {
    return (
      this.validationFeedback == null ||
      (this.validationFeedback?.exposureApproved &&
        this.validationFeedback?.backgroundApproved &&
        this.validationFeedback?.pictureSizeApproved)
    );
  }

  photoDocumentExist(): boolean {
    if (this.case?.documents?.length > 0) {
      const doc = this.case.documents.find(
        (document: any) => document.documentTypeId === 1
      );
      if (!!doc) {
        this.doc = doc;
        this.photoTypeControl.setValue(
          this.doc.documentMetadata?.photoType?.toString()
        );
        this.validationFeedback = this.doc.documentMetadata;
        return true;
      }
    }
    return false;
  }

  //  todo add isFileValid function here (from other upload components) for consistency
  public selectFile(e: { target?: HTMLInputElement }): void {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const fileSizeValid = file && file.size <= this.maxFileSize;

    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);

    if (fileTypeValid && fileSizeValid) {
      this.sizeError = null;
      this.typeError = null;
      this.selfDeclaredPhoto = false;
      this.disableReplaceBtn = true;
      this.templateCoordinates = null;
      this.templatePhoto = file;
      this.templatePhotoLink = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
    } else if (!fileTypeValid && fileSizeValid) {
      this.getTranslatedFileTypeError();
      this.sizeError = null;
    } else if (fileTypeValid && !fileSizeValid) {
      this.getTranslatedFileSizeError();
      this.typeError = null;
    } else {
      this.getTranslatedFileTypeError();
      this.getTranslatedFileSizeError();
    }
  }

  public modalCloseCancel(event: boolean) {
    this.photoisBeingValidated = false;
    this.disableReplaceBtn = false;
    this.templatePhotoLink = null;
    this.templatePhoto = null;
    this.templateCoordinates = null;
  }

  public async setCoordinatesBias(coordinates: CropperCoordinatesBias) {
    this.validationFeedback = null;
    this.photoisBeingValidated = true;
    this.showPhotoContent = true;
    this.templateCoordinates = coordinates;
    this.showPreviewIMG = false;
    const metaDataObj = {
      heightCompare: coordinates.heightCompare.toString(),
      leftDiff: coordinates.leftDiff.toString(),
      topDiff: coordinates.topDiff.toString(),
      widthCompare: coordinates.widthCompare.toString(),
    };
    this.selectedPhoto = {
      image: this.templatePhoto,
      url: this.templatePhotoLink,
      coordinates,
    };
    this.templatePhotoLink = null;

    try {
      // save to s3 with metadata
      const response = await this.photoService.storePhototoS3(
        this.selectedPhoto.image,
        metaDataObj
      );
      // if response send to the endpoint
      if (!!response) {
        this.photoService
          .getPhotoValidationFeedback(response.uploadedFileName)
          .then((val) => {
            // receive greyscale photo, coordinates and feedback
            this.validationFeedback = val.validation;
            this.getDocumentFromStorage(false);
            this.setFocus("editImgButton1");
          })
          .catch((err) => {
            // this.getDocumentFromStorage(false);
            this.photoisBeingValidated = false;
            this.showPhotoContent = false;
            this.selectedPhoto.url = "";
            this.disableReplaceBtn = false;
            if (
              !err.response &&
              err?.message === "Network Error" &&
              err?.config?.data &&
              !err.config.data.includes("Network Error")
            ) {
              this.alertService.danger(this.alertValidationTimeOut);
            } else {
              this.alertService.danger(this.alertPhotoUploadError);
            }
          });
      }
    } catch (error) {
      this.alertService.danger(this.alertPhotoUploadError);
      this.photoisBeingValidated = false;
      this.disableReplaceBtn = false;
    }
  }

  public setFocus(id: string) {
    this.focusSet = 0;
    if (this.allValidationPassed) {
      setTimeout(() => document.getElementById(id)?.focus(), 1000);
    } else {
      setTimeout(() => document.getElementById("warning_label")?.focus(), 1000);
    }
  }

  public editPhoto(isPreview: boolean) {
    this.disableReplaceBtn = true;
    this.templateCoordinates = this.selectedPhoto.coordinates;
    this.templatePhotoLink = this.selectedPhoto.url;
    this.templatePhoto = this.selectedPhoto.image;
  }

  public async getDocumentFromStorage(firstTime: boolean) {
    if (firstTime) {
      this.showPreviewIMG = true;
    } else {
      this.showPreviewIMG = false;
    }
    const documents = this.case?.documents;
    const defaultFileExtension = "jpeg";

    const document = documents?.find((doc: any) => doc.documentTypeId === 1);
    let photoName = document
      ? document?.documentName.slice(document.documentName.indexOf("/") + 1)
      : `${this.case.id}/${this.case.uci}-photo.${defaultFileExtension}`;

    try {
      const s3response = await this.photoService?.getPhotoDetailsFromStorage(
        photoName
      );
      this.selectedPhoto = {
        image: s3response?.previewFile,
        url: s3response?.photoLink,
        coordinates: s3response?.coordinates,
      };
      this.disableReplaceBtn = false;
      this.photoisBeingValidated = false;
    } catch (error) {
      this.disableReplaceBtn = false;
      this.photoisBeingValidated = false;
      throw error;
    }
  }

  public async onSubmit(): Promise<any> {
    try {
      const metaDataObj = {
        heightCompare:
          this.selectedPhoto?.coordinates?.heightCompare.toString(),
        leftDiff: this.selectedPhoto?.coordinates?.leftDiff.toString(),
        topDiff: this.selectedPhoto?.coordinates?.topDiff.toString(),
        widthCompare: this.selectedPhoto?.coordinates?.widthCompare.toString(),
      };
      await this.photoService.uploadPhoto(
        this.selectedPhoto.image,
        this.selfDeclaredPhoto,
        metaDataObj,
        this.validationFeedback,
        this.photoTypeControl.value
      );
      const stringLang = this.currentLang === "fr" ? "fr" : "en";
      const translatedRoute = `${routePaths[stringLang].CONFIRMATION_CASES}/${routePaths[stringLang].CONFIRMATION_CASE_DETAILS}`;
      this.router.navigateByUrl(
        `/${this.currentLang}/${translatedRoute}/${this.case.id}`
      );

      this.alertService.success(this.alertUploadSuccess);
    } catch (error) {
      this.alertService.danger(this.alertUploadError);
    }
  }

  private get alertUploadError(): string {
    return this.translate.instant("PHOTOS.ERRORS.UPLOAD");
  }

  private get alertUploadSuccess(): string {
    return this.translate.instant("PHOTOS.ALERTS.SUCCESS", this.nameParam);
  }

  private get alertValidationTimeOut(): string {
    return this.translate.instant("PHOTOS.ERRORS.VALIDATION_TIME_OUT");
  }

  private get alertPhotoUploadError(): string {
    return this.translate.instant("PHOTOS.ERRORS.PHOTO_UPLOAD_FAILED");
  }

  /* creates readable file size used in error string in lang files */
  private get friendlyMaxFileSize(): string {
    return `${this.maxFileSize / 1000000}`;
  }

  public get lang() {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  private get photoId() {
    if (this.case.documents.length > 0 && this.photoDocumentExist()) {
      return this.doc.id;
    } else {
      return null;
    }
  }

  private getTranslatedFileSizeError(): void {
    this.subscriptions.push(
      this.translate
        .get("PHOTOS.ERRORS.TOO_BIG", { size: this.friendlyMaxFileSize })
        .subscribe((res) => {
          this.sizeError = res;
        })
    );
  }

  private getTranslatedFileTypeError(): void {
    this.subscriptions.push(
      this.translate.get("PHOTOS.ERRORS.INVALID_FILETYPE").subscribe((res) => {
        this.typeError = res;
      })
    );
  }

  private getTranslatedReasons(): void {
    this.documentService
      .getDocumentRejectionReasonsByDocumentId(this.case.id, this.photoId)
      .then((reasons) => {
        const langKey =
          this.lang === "fr" ? "rejectionReasonFr" : "rejectionReasonEn";
        const selectedReasons = reasons.filter(
          (r: any) => Boolean(r.selected) === true
        );
        this.rejectionReasons = selectedReasons.map((r: any) => r[langKey]);
      })
      .catch((error) => {});
  }

  selfDeclared() {
    this.selfDeclaredPhoto = !this.selfDeclaredPhoto;
  }

  photoValidationAcknowledgement() {
    this.validationAcknowledgment = !this.validationAcknowledgment;
  }
}
