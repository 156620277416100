import { Component, Input } from "@angular/core";
import { ModalService } from "lib";

@Component({
  selector: "pra-tool-tip",
  templateUrl: "./tool-tip.component.html",
  styleUrls: ["./tool-tip.component.scss"],
})
export class ToolTipComponent {
  @Input() public modalIndex: string;
  @Input() public documentId?: number;
  @Input() public tooltip_id?: string;
  @Input() public tooltip_title?: string;
  @Input() public tooltip_body?: string;

  constructor(private modalService: ModalService) {}

  public handleClick() {
    console.log(this.modalIndex);
  }

  public openModal() {
    this.modalService.open(`tool-tip-${this.tooltip_id}`);
  }
  public closeModal() {
    this.modalService.close(`tool-tip-${this.tooltip_id}`);
  }
}
