import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@pr-applicant/app/core/auth-module/services/auth.service";
import { IBannerConfig } from "ircc-ds-angular-component-library";
import { AlertService } from "lib";
import { timer } from "rxjs";

@Component({
  selector: "pra-resend-password-button",
  templateUrl: "./resend-password-button.component.html",
  styleUrls: ["./resend-password-button.component.scss"],
})
export class ResendPasswordButtonComponent implements OnInit {
  public status: "READY" | "LOADING" | "ERROR" | "SUCCESS" | "COOLDOWN" =
    "READY";

  @Input() caseId: string;
  @Input() caseMemberId: number;
  @Input() removeHoverEffect = false;

  constructor(
    private auth: AuthService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  async resendPassword(caseMemberId: number) {
    this.status = "LOADING";
    try {
      await this.auth.resendMemberPassword(this.caseId, caseMemberId);
    } catch (error) {
      console.log(error);
      this.status = "ERROR";
      this.alertService.danger(
        this.translate.instant(
          "INTAKE.PROGRAM_SELECTION_FORM.RESEND_TEMP_PASSWORD_ERROR"
        )
      );
      return;
    }
    this.alertService.success(
      this.translate.instant(
        "INTAKE.PROGRAM_SELECTION_FORM.RESEND_TEMP_PASSWORD_SENT_MEMBERS"
      )
    );
    this.status = "COOLDOWN";
    timer(5 * 60 * 1000).subscribe(() => (this.status = "READY"));
  }

  public get bannerConfig(): IBannerConfig {
    return {
      id: "resend-success-banner",
      content: this.translate.instant(
        "INTAKE.PROGRAM_SELECTION_FORM.RESEND_TEMP_PASSWORD_SENT_MEMBERS"
      ),
      rounded: true,
      type: "info",
    };
  }
}
