import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromApp from "../../../../store/app.reducer";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { IInformationBoxConfig } from "lib";
@Component({
  selector: "pra-imm5406-start",
  templateUrl: "./imm5406-start.component.html",
  styleUrls: ["./imm5406-start.component.scss"],
})
export class IMM5406StartComponent implements OnInit, OnDestroy {
  @Input() data?: any;

  public forms: any = [];
  private storeSubsc$: Subscription;
  public lang: string = "";
  caseId: string = "";

  constructor(
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getDataFromStore();
  }

  ngOnDestroy(): void {
    if (this.storeSubsc$) {
      this.storeSubsc$.unsubscribe();
    }
  }

  public informationBoxConfig: IInformationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };

  public isFormDataUnSaved(): boolean {
    return false;
  }

  /* PRIVATE ------------------------------------------------------------------------------------ */

  private getDataFromStore(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .subscribe((doc) => {
        this.caseId = `${doc.caseId}`;
        this.forms = doc.forms?.map((form: any) => {
          return {
            formId: form._id,
            fullName: form.sectionA.applicant.fullName,
            dob: form.sectionA.applicant.dob,
          };
        });
      });
  }
}
