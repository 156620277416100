<div class="wrapper">
  <h2 class="proof-of-payment__heading">
    <span *ngIf="required" class="payment-table__heading_required">*</span
    >{{ "INTAKE.PROOF_OF_PAYMENT.HEADING" | translate }}
    <span *ngIf="required" class="payment-table__heading_required">{{
      "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
    }}</span>
  </h2>
  <p>
    {{ "INTAKE.PROOF_OF_PAYMENT.SUBTITLE" | translate }}
  </p>
  <table class="table payment-table">
    <thead class="payment-table__head">
      <tr>
        <th class="payment-table__name">
          <!-- TO-DO Replace with document name  -->
          {{ "INTAKE.TABLE_HEADINGS.FORM_NAME" | translate }}
        </th>
        <th class="payment-table__status">
          {{ "INTAKE.TABLE_HEADINGS.STATUS" | translate }}
        </th>
        <th class="payment-table__attachment">
          {{ "INTAKE.TABLE_HEADINGS.ATTACHMENT" | translate }}
        </th>
        <th class="payment-table__actions">
          {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="payment-table__row">
        <td class="payment-table__row-title">
          <!-- Proof of payment description -->
          <h4 class="payment-table__heading">
            <span *ngIf="required" class="payment-table__required">*</span>
            {{ "INTAKE.PROOF_OF_PAYMENT.TITLE" | translate }}
            <span *ngIf="required" class="payment-table__required">{{
              "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
            }}</span>
          </h4>
          <p>
            {{ "INTAKE.PROOF_OF_PAYMENT.INSTRUCTIONS_HEADING" | translate }}
          </p>
          <ul>
            <li
              [innerHTML]="item"
              *ngFor="
                let item of 'INTAKE.PROOF_OF_PAYMENT.INSTRUCTIONS.LIST'
                  | translate
              "
            ></li>
          </ul>
        </td>
        <td class="payment-table__row-status">
          <!-- Status -->
          <lib-status-pill
            *ngIf="statusLabel && status"
            [text]="statusLabel | translate"
            [type]="status"
            [fontSize]="'0.8rem'"
          >
          </lib-status-pill>
          <p *ngIf="!status" class="visually-hidden">
            {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
          </p>
        </td>
        <td class="payment-table__row-actions-mobile">
          <!-- Actions -->
          <lib-file-upload
            fileTypes="{{ acceptedFileTypes }}"
            [disabled]="isHiddenLob"
            (change)="uploadFile($event)"
            buttonStyle="primary-alt"
            id="proof-of-payment"
            *ngIf="user.can('documents:write')"
          >
            {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
          </lib-file-upload>
        </td>
        <td class="payment-table__row-attachments">
          <!-- Attachment -->
          <div
            class="payment-table__row-divider"
            *ngIf="existingFiles && existingFiles.length > 0"
          >
            <div>
              <hr />
            </div>
            <h5>Uploaded Files</h5>
          </div>
          <div *ngFor="let receipt of existingFiles">
            <div
              class="payment-table__delete"
              *ngIf="receipt && receipt.documentName && receipt.documentName[0]"
            >
              <button category="link" (click)="downloadFile(receipt)">
                <span class="visually-hidden">
                  {{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }} </span
                >{{ fileName(receipt) }}
              </button>
              <button
                *ngIf="this.caseStatusId !== 7 && user.can('documents:write')"
                category="plain"
                color="critical"
                (click)="openDeleteModal(receipt)"
              >
                <lib-icon-chooser icon="delete"></lib-icon-chooser>
                <!-- {{ "INTAKE.TABLE_BUTTON.DELETE" | translate }} -->
                <span class="visually-hidden">{{ fileName(receipt) }}</span>
              </button>
            </div>
          </div>
          <h4
            class="payment-table__heading payment-table__no-files"
            *ngIf="areReceiptsEmpty()"
          >
            {{ "INTAKE.SUPPORTING_DOCUMENTS.NO_UPLOAD" | translate }}
          </h4>
        </td>
        <td class="payment-table__row-actions">
          <!-- Actions -->
          <lib-file-upload
            fileTypes="{{ acceptedFileTypes }}"
            [disabled]="isHiddenLob"
            (change)="uploadFile($event)"
            buttonStyle="primary-alt"
            id="proof-of-payment"
            *ngIf="user.can('documents:write')"
          >
            {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
          </lib-file-upload>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<lib-modal
  [id]="deleteModalId"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ "INTAKE.DELETE_MODAL.SUBHEADING" | translate }}
    </p>
    <p>
      {{ "INTAKE.DELETE_MODAL.DESCRIPTION" | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" color="critical" (click)="deleteFile()">
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
