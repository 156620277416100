import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { ValidationService, FormSelectOption } from "lib";
import { LovService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-language-details-form",
  templateUrl: "./language-details-form.component.html",
  styleUrls: ["./language-details-form.component.scss"],
})
export class IMM0008LanguageDetailsFormComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() formData: any;

  public lang: string;

  public languagesList: FormSelectOption[];
  public officialLanguageList: FormSelectOption[];
  public preferenceLanguageList: FormSelectOption[];
  public formValues: any;

  private bilingualDropDownValue = "04";

  public languageDetailsForm = this.fb.group({
    nativeLanguage: [null, Validators.required],
    language: [null, Validators.required],
    preferredLanguage: [null],
    takenTest: [null, Validators.required],
  });

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private cdr: ChangeDetectorRef,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.watchLanguage();
  }

  ngOnChanges(): void {
    this.languagesList = this.lovService.lovs.contactLanguage;
    this.officialLanguageList = this.lovService.lovs.officialLanguage;
    this.preferenceLanguageList = this.lovService.lovs.preferenceLanguage;
    this.formValues = this.formData?.form;
    if (this.formValues) {
      this.setFormValues();
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  public get isLanguageTestRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.languageDetailsForm.controls.takenTest
    );
  }

  public get isBilingualSelected(): boolean {
    return (
      this.languageDetailsForm?.value?.language === this.bilingualDropDownValue
    );
  }

  public get preparedFormValue(): any {
    return {
      ...this.languageDetailsForm?.value,
      preferredLanguage: this.isBilingualSelected
        ? this.languageDetailsForm?.controls?.preferredLanguage?.value
        : null,
    };
  }

  private setFormValues(): void {
    this.languageDetailsForm?.controls?.nativeLanguage?.setValue(
      this.formValues.nativeLanguage
    );
    this.languageDetailsForm?.controls?.language?.setValue(
      this.formValues.language
    );
    this.languageDetailsForm?.controls?.preferredLanguage?.setValue(
      this.formValues.preferredLanguage
    );
    this.languageDetailsForm?.controls?.takenTest?.setValue(
      this.formValues.takenTest
    );
  }

  private watchLanguage(): void {
    this.languageDetailsForm?.controls?.language.valueChanges.subscribe(
      (value) => {
        if (value === this.bilingualDropDownValue) {
          this.languageDetailsForm?.controls?.preferredLanguage?.setValidators(
            Validators.required
          );
        } else {
          this.languageDetailsForm?.controls?.preferredLanguage?.reset();
          this.languageDetailsForm?.controls?.preferredLanguage?.clearValidators();
        }

        this.languageDetailsForm?.controls?.preferredLanguage?.updateValueAndValidity();
      }
    );
  }
}
