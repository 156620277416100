import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { MatSidenav } from "@angular/material/sidenav";

export interface FormNavigationBarItems {
  name: string;
  link: string;
}

@Component({
  selector: "pra-form-nav-bar",
  templateUrl: "./form-nav-bar.component.html",
  styleUrls: ["./form-nav-bar.component.scss"],
})
export class FormNavBarComponent implements OnInit {
  @Input() hasBackButton: boolean = false;
  @Input() curr: number = 0;
  @Input() isLoading: boolean = false;
  @Input() items: FormNavigationBarItems[];
  @Input() formNavbarBackButtonTitle: string = "";
  @Input() formNavbarBackButtonLink: string = "";
  @Input() formNavbarBackButtonPath: string = "";
  @Output() NavButtonClickEvent = new EventEmitter<number>();

  public lang: string;
  public lgBreakpoint: number = 992;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    public router: Router,
    public route: ActivatedRoute,
    public matDrawer: MatSidenav
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  formSideNavbarNavigation(pageNum: number) {
    this.NavButtonClickEvent.emit(pageNum);
    if (window.innerWidth <= this.lgBreakpoint) {
      this.matDrawer.close();
    }
  }

  formNavbarNavigateBack() {
    if (this.formNavbarBackButtonLink && this.formNavbarBackButtonPath) {
      this.router.navigate(
        [
          this.routeLocalizer.get(
            this.lang,
            this.formNavbarBackButtonLink,
            this.formNavbarBackButtonPath
          ),
        ],
        { relativeTo: this.route }
      );
    } else {
      this.NavButtonClickEvent.emit(-1);
      if (window.innerWidth <= this.lgBreakpoint) {
        this.matDrawer.close();
      }
    }
  }
}
