<div class="debug">
  <h4 class="debug__heading">Debug</h4>
  <p class="debug__subheading">
    <span class="emphasis">Value: </span> {{ control.value | safeTrim }}
  </p>
  <p class="debug__subheading emphasis">Validators:</p>
  <div class="debug__list" *ngFor="let v of validators">
    <ng-container *ngIf="v.name">
      <div class="debug__list-item">- {{ v.name }}</div>
    </ng-container>
  </div>
  <p class="debug__subheading emphasis debug__subheading--error">Errors:</p>
  <div
    class="debug__list debug__list--error"
    *ngFor="let e of control.errors | keyvalue"
  >
    <div class="debug__list-item">- {{ e.key }} - {{ e.value }}</div>
  </div>
</div>
