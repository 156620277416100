import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AlertService,
  ModalService,
  DOC_LIMITS,
  documentSizes,
  uploadFileTypes,
  documentTypes,
  ValidationService,
} from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { LobService } from "../../../../../../../lib/src/lib/services/lob/lob.service";

import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { TranslateService } from "@ngx-translate/core";
import { DownloadService } from "../../../../shared/services/download.service";
import {
  AppForm,
  RawAppForm,
  Document,
  RequiredList,
} from "../../../../core/models/document.model";
import { CaseService } from "../../../../shared/case-module/case.service";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import * as CaseActions from "../../../../shared/case-module/store/case.actions";
import { Case } from "../../../../shared/case-module/case.model";

@Component({
  selector: "pra-web-form-table",
  templateUrl: "./web-form-table.component.html",
  styleUrls: ["./web-form-table.component.scss"],
})
export class WebFormTableComponent implements OnInit, OnDestroy, OnChanges {
  public get friendlyMaxFileSize(): string {
    return `${this.maxFileSize / this.megaFileConversion}`;
  }

  @Input() isHiddenLob: boolean = false;
  @Input() requiredForms: RawAppForm[] = [];
  @Input() otherForms: RawAppForm[];
  @Input() requiredList: RequiredList = {};
  // Case docs and case id
  public case: any;
  public caseId: string;
  public caseStatusId: number;
  public caseDocuments: any;
  public existingFile: any;

  // App forms (PDF and web)
  public appFormTableRows: AppForm[] = [];
  public appFormsList: RawAppForm[] = [];

  // File size, type and length
  public maxFileSize = documentSizes.forms.maxFileSize;
  public megaFileConversion = documentSizes.forms.megaFileConversion;
  public fileSelected: any;
  public acceptedFileTypes = uploadFileTypes.applicationForms.types;
  public maxFileNameLength = 180;

  // Status info
  public status: string;
  public statusLabel: string;
  public translatedStatusText: string;
  public currentLang: string;

  // Temp Upload Files
  public tempAttachments: { [key: number]: any[] } = {};
  public tempFileRowIndex: number = NaN;
  public tempFileName: string = "";

  // Delete
  public deleteModalId = "confirmDeletePdfForm";
  public tempDeleteDocument: Document | undefined;
  // this array includes document type IDs that the row should be removed if no PDFs available (ex: imm0008 dependant)
  public documentTypeIdsToDeleteIfNoPDFs: number[] = [
    documentTypes.imm0008Dependent.id,
  ];

  // Loading Spinner
  public webFormTableSpinner: boolean[] = [];

  public subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public isCurrentLobAFamilyClassLob: boolean = false;
  public category?: string;

  @Output() webFormsUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    public alertService: AlertService,
    public caseService: CaseService,
    public documentService: DocumentService,
    public store: Store<fromApp.State>,
    public downloadService: DownloadService,
    public routeLocalizer: RouteLocalizerService,
    public translate: TranslateService,
    public modalService: ModalService,
    public validationService: ValidationService,
    public httpClient: HttpClient,
    public user: UserService,
    public lobService: LobService
  ) {
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(filter((caseData: any) => caseData?.id !== ""))
        .subscribe((caseData) => {
          if (caseData) {
            this.case = caseData;
            this.caseId = this.case.id;
            this.caseStatusId = this.case.caseStatusId;
            this.caseDocuments = this.case.documents;
          }
        })
    );
  }

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    // If there isn't documents in the store, call the API to get the list
    this.subscriptions.push(
      this.caseService.caseUpdated.subscribe(() => {
        this.getFormsList();
      })
    );
    this.getCategory();
    if (this.case.lob.programId === 2) {
      this.isCurrentLobAFamilyClassLob = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFormsList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // Gets the full list of all the PDF forms
  public async getFormsList() {
    try {
      if (this.requiredForms !== undefined && this.requiredForms.length > 0) {
        this.appFormsList = this.sortRequiredForms(this.requiredForms);
        this.getFormRows();
      }
    } catch (err) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }
  public async getCategory(): Promise<void> {
    if (
      this.case.lob.categoryId === 1000 ||
      this.case.lob.categoryId === 1001
    ) {
      const emppCategoryLOB = await this.lobService.fetchLob(
        this.case.lob.categoryId
      );
      this.currentLang === "fr"
        ? (this.category = `${emppCategoryLOB?.categoryFr}/ ${this.case.lob.categoryFr}`)
        : (this.category = `${emppCategoryLOB?.categoryEn}/ ${this.case.lob.categoryEn}`);
    } else {
      this.currentLang === "fr"
        ? (this.category = this.case.lob.categoryFr)
        : (this.category = this.case.lob.categoryEn);
    }
  }

  // format the object from the API into the data for a single row in the table
  public async getFormRows() {
    try {
      this.appFormTableRows = this.appFormsList
        .map((doc: RawAppForm) => this.getFormattedRowData(doc))
        .filter((row) => {
          const isFormToRemove =
            this.documentTypeIdsToDeleteIfNoPDFs.includes(row.documentTypeId) &&
            row.attachments?.length === 0;
          return !isFormToRemove;
        });
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // This method will get a an individual form row data Data based on form document passed;
  public getFormattedRowData(doc: RawAppForm): AppForm {
    let isWebForm = false;
    let legacyPDFs = false;
    const uploadedDocuments = this.getUploadedfilesById(doc.id);
    const attachments: Array<any> = [];

    this.currentLang = this.routeLocalizer.getCurrentRouteLang();

    // check for legacy pdfs
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < uploadedDocuments.length; i++) {
      // if we find at least one file uploaded onto s3, this is not a web form
      // they must delete all their PDFs to fill out the web form
      if (uploadedDocuments[i].documentName.startsWith("ca-central-1")) {
        isWebForm = false;
        legacyPDFs = true;
        break;
      } else {
        isWebForm = true;
      }
    }

    // display warning if there are legacy PDFs and the doc is now on mongo
    const displayWarningMsg = doc.documentLocation === "mongo" && legacyPDFs;

    //  there may be more than one document - add attachments array
    //  with status and attachment properties for the different docs
    //  add attachments array to formattedFormRow obj
    uploadedDocuments.forEach((document: Document) => {
      attachments.push({
        uploadedRawDocument: document,
        status: this.checkStatus(document),
      });
    });

    // if there are no uploaded documents, its type is determined by the default documentLocation
    if (uploadedDocuments.length === 0) {
      isWebForm = doc.documentLocation === "mongo";
    }

    const webFormRoute = (
      this.currentLang === "fr" ? doc.form.nameFr : doc.form.nameEn
    )
      .toUpperCase()
      .replace(/ /g, "");
    const webFormRouteLabel = `INTAKE_FORM_${webFormRoute}`;

    const formattedFormRow: AppForm = {
      documentTypeId: doc.id,
      documentType:
        this.currentLang === "fr" ? doc.documentTypeFr : doc.documentTypeEn,
      formId: doc.form.id,
      name: this.currentLang === "fr" ? doc.form.nameFr : doc.form.nameEn,
      details:
        this.currentLang === "fr" ? doc.form.detailsFr : doc.form.detailsEn,
      link: this.currentLang === "fr" ? doc.form.linkFr : doc.form.linkEn,
      attachments,
      webFormTableSpinner: false,
      isWebForm,
      webFormRoute: webFormRouteLabel,
      displayWarningMsg,
      optional: doc.optional,
    };
    return formattedFormRow;
  }

  // get the name of the uploaded files
  public getUploadedfilesById(id: number): any[] {
    if (this.caseDocuments) {
      return this.documentService.getFilesByDocumentType(
        id,
        this.caseDocuments
      );
    }
    return [];
  }

  public async updateIndividualRow(id: number) {
    let isWebForm = false;
    // Check DB for existing form using the form id
    this.caseDocuments = await this.documentService.getDocumentByCaseId(
      this.caseId
    );
    // use form ID to match the uploaded form to the correct pdf form so the file appears in the correct row of the table
    const elementIndex = this.appFormTableRows.findIndex(
      (doc) => doc.documentTypeId === id
    );
    if (elementIndex) {
      const newDocuments = this.getUploadedfilesById(id);
      // If you update a form row by adding or deleteing, check that
      if (
        newDocuments &&
        newDocuments.length === 0 &&
        this.documentTypeIdsToDeleteIfNoPDFs.includes(id)
      ) {
        this.appFormTableRows.splice(elementIndex, 1);
        return;
      }
      const attachments: Array<any> = [];

      //  there may be more than one document - add attachments array
      //  with status and attachment properties to update the attachements and their status
      // When the form changes
      newDocuments.forEach((document: Document) => {
        attachments.push({
          uploadedRawDocument: document,
          status: this.checkStatus(document),
        });
      });
      // update the button from upload to start (if it is a web form)
      // if there are no uploaded documents, its type is determined by the default documentLocation
      this.appFormsList = await this.caseService.getAppForms();
      const refToForm = this.appFormsList.findIndex((doc) => doc.id === id);
      if (attachments.length === 0) {
        isWebForm = this.appFormsList[refToForm].documentLocation === "mongo";
      }
      this.appFormTableRows[elementIndex] = await {
        ...this.appFormTableRows[elementIndex],
        attachments,
        isWebForm,
      };
    }
  }

  public updateWebformStatus() {
    this.webFormsUpdated.emit();
  }

  // checks the status of the uploaded document
  public checkStatus(document: Document) {
    if (document) {
      const statusType = this.documentService.checkDocumentOrFormStatus(
        document,
        this.caseStatusId
      );
      if (statusType) {
        const statusLabel = `INTAKE.FORM_DOCUMENT.STATUS_${statusType.toUpperCase()}`;
        return {
          statusType,
          statusLabel,
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // click on name of the uploaded form to download
  public async downloadFile(document: Document) {
    const downloadName = document.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)S
    try {
      await this.downloadService.downloadDocument(
        document.caseId,
        document.id,
        downloadName
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  /* ------- PUBLIC ------------*/
  public file_size: string;
  public file_type: string;
  public file_name_length: string;

  ERROR_COPY = [
    {
      FILE_SIZE: {
        en: "File size exceeds the maximum of 4 MB.",
        fr: "La taille du fichier dépasse le maximum de 4 Mo.",
      },
      FILE_TYPE: {
        en: "Your document file type does not meet the required criteria.",
        fr: "Votre type de document ne répond pas aux critères requis.",
      },
      FILE_NAME_LENGTH: {
        en: "File name exceeds the maximum length of 180 characters.",
        fr: "Le nom du fichier dépasse la longueur maximale de 180 caractères.",
      },
      FILE_NAME_CHARACTERS: {
        en: "The name of the selected file contains invalid characters. Please ensure that files meet all the upload requirements indicated under the Application forms table.",
        fr: "Le nom du fichier sélectionné contient des caractères non valides. Veuillez-vous assurer que les fichiers répondent à toutes les exigences de téléchargement indiquées sous le tableau des formulaires de demande.",
      },
    },
  ];

  public isFileValid(file: File): boolean {
    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;
    const fileSizeValid = file && file.size <= this.maxFileSize;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);
    const maxFileNameLength = file.name.length <= this.maxFileNameLength;
    const validFileNameForUpload =
      this.validationService.isValidFileNameForUpload(file.name);

    if (
      !fileSizeValid ||
      !fileTypeValid ||
      !maxFileNameLength ||
      !validFileNameForUpload
    ) {
      if (this.currentLang === "en") {
        const en_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.en),
            (this.file_type = item.FILE_TYPE.en),
            (this.file_name_length = item.FILE_NAME_LENGTH.en);
        });
      } else {
        this.currentLang === "fr";
        const fr_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.fr),
            (this.file_type = item.FILE_TYPE.fr),
            (this.file_name_length = item.FILE_NAME_LENGTH.fr);
        });
      }
      if (fileSizeValid === false) {
        this.setTempFileErrorMessage(this.tempFileRowIndex, this.file_size);
      }
      if (validFileNameForUpload === false) {
        if (this.currentLang === "en") {
          this.setTempFileErrorMessage(
            this.tempFileRowIndex,
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.en
          );
        } else {
          this.setTempFileErrorMessage(
            this.tempFileRowIndex,
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.fr
          );
        }
      }
      if (fileTypeValid === false) {
        this.setTempFileErrorMessage(this.tempFileRowIndex, this.file_type);
      }
      if (maxFileNameLength === false) {
        this.setTempFileErrorMessage(
          this.tempFileRowIndex,
          this.file_name_length
        );
      }
      return false;
    }
    return true;
  }

  public getTotalFilesUploaded(documentTypeId: number) {
    const filteredDocs = this.caseDocuments?.filter((doc: any) => {
      if (doc.documentTypeId === documentTypeId) {
        return doc;
      }
    });
    return filteredDocs.length;
  }

  public async uploadFile(
    e: { target?: HTMLInputElement },
    documentTypeId: number,
    indexOfRow?: number,
    fileName?: string
  ): Promise<any> {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const fileValid = this.isFileValid(file);
    const numberOfFiles = this.getTotalFilesUploaded(documentTypeId);

    if (fileValid && numberOfFiles < DOC_LIMITS.PDF_FORM_ATTACHMENTS) {
      this.fileSelected = file;
      const elementIndex = this.appFormTableRows.findIndex(
        (doc) => doc.documentTypeId === documentTypeId
      );
      try {
        // upload
        await this.documentService.uploadNewFileByDocumentTypeId(
          this.caseId,
          this.fileSelected,
          documentTypeId
        );
        this.updateCaseInfo(this.caseId);
        // update the row to show the document name
        await this.updateIndividualRow(documentTypeId);
        if (indexOfRow !== undefined && fileName !== undefined)
          this.removeTempFileLoading(indexOfRow, fileName);
        this.alertService.success(this.alertSuccess);
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        if (indexOfRow !== undefined && fileName !== undefined)
          this.removeTempFileLoading(indexOfRow, fileName);
      }
    } else if (!fileValid) {
      await this.updateIndividualRow(documentTypeId);
    } else if (numberOfFiles >= DOC_LIMITS.PDF_FORM_ATTACHMENTS) {
      this.alertService.danger(this.alertMaximumPdfAttachments);
      if (indexOfRow !== undefined && fileName !== undefined)
        this.removeTempFileLoading(indexOfRow, fileName);
    }
  }

  public setTempFileInfoAndUpload(
    e: any,
    indexOfRow: number,
    documentTypeId: number
  ): void {
    if (!this.tempAttachments[indexOfRow]) {
      this.tempAttachments[indexOfRow] = [];
    }
    const newTempAttachment = {
      state: "loading",
      fileName: e.target?.files[0].name || "",
      error: "",
    };
    this.tempAttachments[indexOfRow].push(newTempAttachment);
    this.tempFileRowIndex = indexOfRow;

    this.uploadFile(e, documentTypeId, indexOfRow, newTempAttachment.fileName);
  }

  public setTempFileErrorMessage(index: number, error: string) {
    const length = this.tempAttachments[index].length;
    this.tempAttachments[index][length - 1].error = error;
    this.tempAttachments[index][length - 1].state = "error";
  }

  public removeTempFileError(rowIndex: number, attachmentIndex: number): void {
    this.tempAttachments[rowIndex].splice(attachmentIndex, 1);
  }
  public removeTempFileLoading(rowIndex: number, fileName: string): void {
    const removeTempFileIndex = this.tempAttachments[rowIndex].findIndex(
      (tempAttachment) => tempAttachment.fileName === fileName
    );
    if (removeTempFileIndex > -1) {
      this.tempAttachments[rowIndex].splice(removeTempFileIndex, 1);
    }
  }

  public openDeleteModal(document?: Document): void {
    this.modalService.open(this.deleteModalId);
    this.tempDeleteDocument = document;
  }

  public closeModal(): void {
    this.modalService.close(this.deleteModalId);
    this.tempDeleteDocument = undefined;
  }

  public async deleteFile(): Promise<any> {
    if (this.tempDeleteDocument && !this.isLoading) {
      try {
        this.isLoading = true;
        await this.documentService.deleteFileByDocumentTypeAndId(
          this.caseId,
          Number(this.tempDeleteDocument.documentTypeId),
          this.tempDeleteDocument.id
        );
        const elementIndex = this.appFormTableRows.findIndex(
          (doc) =>
            doc.documentTypeId ===
            Number(this.tempDeleteDocument?.documentTypeId)
        );
        this.appFormTableRows[elementIndex].attachments = this.appFormTableRows[
          elementIndex
        ].attachments.filter(
          (attahcment) =>
            attahcment.uploadedRawDocument.id !== this.tempDeleteDocument?.id
        );
        this.alertService.success(this.alertDeleteSuccess);
        this.updateCaseInfo(this.caseId);
        this.isLoading = false;
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        this.isLoading = false;
      }
      this.closeModal();
    }
  }

  /*
    - client, no rep
    - rep
  */
  public updateCaseInfo(caseId: string) {
    this.caseService
      .getCase(caseId)
      .then((data: Case) => {
        this.store.dispatch(new CaseActions.SetCaseByEmail(data));
      })
      .catch((err) => {
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  public get alertSuccess(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_SUCCESS");
  }

  public get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_ERROR");
  }

  public get alertDeleteSuccess(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.DELETE_SUCCESS");
  }

  public get alertMaximumPdfAttachments(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }

  public buttonStatus(doc: any): string | null {
    if (!this.user.can("documents:write")) {
      return this.translate.instant("INTAKE.TABLE_BUTTON.VIEW");
    }
    // if there are existing documents, check if they have been started
    if (doc.attachments.length > 0) {
      if (
        doc.attachments[0]?.uploadedRawDocument?.isComplete === null &&
        doc.documentTypeId !== 41
      ) {
        return this.translate.instant("INTAKE.TABLE_BUTTON.START");
      } else {
        return this.translate.instant("INTAKE.TABLE_BUTTON.EDIT");
      }
      // if no documents created, start button shows
    } else {
      return this.translate.instant("INTAKE.TABLE_BUTTON.START");
    }
  }
  public sortRequiredForms(arr: RawAppForm[]): RawAppForm[] {
    // avoid mutations
    let _arr = [...arr];
    _arr = _arr.sort((a, b) => a.id - b.id);
    _arr.sort((doc1, doc2) => {
      // sort IMM 5562 to the bottom of required form
      let arguements = [doc1.optional, doc2.optional].map((arg) => {
        return isNaN(Number(arg)) ? false : arg;
      });
      return Number(arguements[0]) - Number(arguements[1]);
    });
    return _arr;
  }
}
