import * as AuthActions from "./auth.actions";

export class User {
  constructor(
    public username: string | undefined,
    public session: string | undefined,
    public isFirstLogin: boolean | undefined,
    public expiryDate: Date | undefined,
    public token: string | undefined,
    public userPool: string | undefined,
    public repId: string,
    public membershipId: string,
    public isLoggingOut?: boolean
  ) {}
}

export interface State {
  user: User | null;
}

const initialState = {
  user: null,
};

export function authReducer(
  state: State = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.LOGIN:
      const dateExpiry = new Date();
      dateExpiry.setHours(dateExpiry.getHours() + 1); // need to come back to this
      const user = new User(
        action.payload.username.toLowerCase(),
        action.payload.session,
        action.payload.isFirstLogin,
        dateExpiry,
        action.payload.token,
        action.payload.userPool,
        action.payload.repId,
        action.payload.membershipId
      );
      return {
        ...state,
        user,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case AuthActions.FIRST_LOGIN_COMPLETE:
      return {
        ...state,
        user: null,
      };
    case AuthActions.START_LOGOUT:
      const userForLogout = Object.assign({}, state.user);
      userForLogout.isLoggingOut = true;
      return {
        ...state,
        user: userForLogout,
      };
    default:
      return state;
  }
}
