import { createAction, props } from "@ngrx/store";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";

export enum RenewalApplicationsPRCActionTypes {
  StartLoadPRCAction = "[Start Load PRC Application ] Load PRC Application",
  SetNewPRCAction = "[Set New PRC ] Set New PRC Application",
  LoadPRCAction = "[Load PRC ] Load PRC Application",
  LoadPRCErrorAction = "[Load PRC Application Error ] Load PRC Application Error",
  ResetPRCStateAction = "[SET PRC STATE TO INITIAL VALUES] Rest PRC state to initial values",
  UpdatePRCClientAndApplicationInfo = "[Update PRC case With Client And Apication Info] Update PRC client and application info",
  UpdatePRCIsUrgentAction = "[ Update PRC urgency status ] Update PRC urgency status",
}

export const StartLoadPRCAction = createAction(
  RenewalApplicationsPRCActionTypes.StartLoadPRCAction,
  props<{ caseId: number | string }>()
);

export const SetNewPRCAction = createAction(
  RenewalApplicationsPRCActionTypes.SetNewPRCAction
);

export const LoadPRCAction = createAction(
  RenewalApplicationsPRCActionTypes.LoadPRCAction,
  props<{ prcCard: Case }>()
);

export const LoadPRCErrorAction = createAction(
  RenewalApplicationsPRCActionTypes.LoadPRCErrorAction,
  props<{ id: number; error: string }>()
);

export const ResetPRCStateAction = createAction(
  RenewalApplicationsPRCActionTypes.ResetPRCStateAction
);

export const UpdatePRCWitClientAndAppInfoAction = createAction(
  RenewalApplicationsPRCActionTypes.UpdatePRCClientAndApplicationInfo,
  props<{
    emailAddress: string;
    givenName: string;
    surname: string;
    applicationName: string;
  }>()
);

export const UpdatePRCIsUrgentAction = createAction(
  RenewalApplicationsPRCActionTypes.UpdatePRCIsUrgentAction,
  props<{
    isUrgent: boolean;
    urgencyRequestDate: string;
  }>()
);
