<mat-sidenav-container class="page-container">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [items]="FormNavbarItems"
        [curr]="8"
        [isLoading]="isLoading"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="imm0008-page9__heading emphasis">
      {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
    </h1>
    <h2>
      {{ "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.SUBHEADING" | translate }}
    </h2>

    <lib-spinner [showSpinner]="isLoading" [fullPage]="false"> </lib-spinner>

    <div
      [ngStyle]="{ display: isLoading ? 'none' : 'block' }"
      [innerHTML]="
        'INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.DESCRIPTION' | translate
      "
    ></div>

    <pra-imm0008-view-dependant-form
      (addNewDependant)="addNewDependant()"
      (deleteDependant)="deleteDependant($event)"
      (saveFormData)="saveFormData()"
      [formData]="formData"
      [isLoading]="isLoading"
      [isAddingDependant]="isAddingDependant"
      #viewAndAddDependantsForm
    ></pra-imm0008-view-dependant-form>

    <lib-layout-row gap="one-and-half" mt="3">
      <a
        [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE8', '../')"
        category="secondary"
      >
        {{ "INTAKE.FORM_PAGE_BACK" | translate }}
      </a>

      <button
        type="button"
        category="primary"
        [disabled]="isFormSavedAndComplete"
        (click)="onSaveAndCompleteClick()"
      >
        {{ nextButtonKey | translate }}
      </button>
    </lib-layout-row>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isLoading"
></pra-form-hamburger>
