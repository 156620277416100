import { Component, HostListener } from "@angular/core";

@Component({
  selector: "pra-form-hamburger",
  template: `
    <div
      class="hamburger"
      (click)="this.isOpen = !this.isOpen"
      [class.show]="isOpen"
    >
      <div class="wrapper">
        <span class="icon-bar one"></span>
        <span class="icon-bar two"></span>
        <span class="icon-bar thr"></span>
      </div>
    </div>
  `,
  styleUrls: ["./form-hamburger.component.scss"],
})
export class FormHamburgerComponent {
  isOpen = false;
  mobileQuery: MediaQueryList;

  constructor() {}

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth >= 992) this.isOpen = false;
  }
}
