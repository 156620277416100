import { Action } from "@ngrx/store";

export const SET_INTAKE_CASES_COUNT =
  "[CasesCount] set total available intake cases";
export const SET_CONFIRMATION_CASES_COUNT =
  "[CasesCount] set total available confirmation cases";
export const RESET_CASES_COUNT = "[CasesCount] reset cases count";

export class SetIntakeCasesCount implements Action {
  readonly type = SET_INTAKE_CASES_COUNT;
  constructor(public payload: number) {}
}

export class SetConfirmationCasesCount implements Action {
  readonly type = SET_CONFIRMATION_CASES_COUNT;
  constructor(public payload: number) {}
}

export class ResetCasesCount implements Action {
  readonly type = RESET_CASES_COUNT;
}

export type CasesCountActions =
  | SetIntakeCasesCount
  | SetConfirmationCasesCount
  | ResetCasesCount;
