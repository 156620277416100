<div class="documents-upload-table mandatory-supporting-documents">
  <div class="documents-upload-table">
    <div class="documents-upload-table__container">
      <ng-container *ngIf="documents">
        <table class="table">
          <thead>
            <tr>
              <th>
                {{
                  "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.DOCUMENT_NAME"
                    | translate
                }}
              </th>
              <th>
                {{
                  "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.ATTACHMENT"
                    | translate
                }}
              </th>
              <th
                *ngIf="user.can('documents:write')"
                class="documents-upload-table__actions-header"
              >
                {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="loadingDocs">
            <tr>
              <td colspan="100%">
                <lib-spinner class="table__spinner" [fullPage]="false">
                </lib-spinner>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!loadingDocs">
            <tr
              *ngFor="
                let requiredDocument of requiredDocumentOptions;
                index as indexOfRow
              "
            >
              <!-- Document Type -->
              <td class="documents-upload-table__name">
                <div class="doc-name-container">
                  <span
                    class="documents-upload-table__required"
                    *ngIf="requiredDocument.isRequired"
                    >*</span
                  >
                  <span *ngIf="requiredDocument.details">{{
                    requiredDocument.details
                  }}</span>
                  <span *ngIf="!requiredDocument.details">
                    {{ requiredDocument.text }}</span
                  >
                  <span
                    class="documents-upload-table__required"
                    *ngIf="requiredDocument.isRequired"
                  >
                    {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}</span
                  >
                  <pra-tool-tip
                    *ngIf="
                      toolTipContent.hasOwnProperty(requiredDocument.value)
                    "
                    [tooltip_id]="requiredDocument.value"
                    [tooltip_title]="
                      toolTipContent[requiredDocument.value] + '.TOOLTIP_TITLE'
                        | translate
                    "
                    [tooltip_body]="
                      toolTipContent[requiredDocument.value] + '.TOOLTIP_BODY'
                        | translate
                    "
                  ></pra-tool-tip>
                </div>
                <div
                  *ngIf="requiredDocument.formId"
                  class="table__download_link"
                >
                  <a
                    *ngIf="requiredDocument.link && !requiredDocument.isWebForm"
                    href="{{ requiredDocument.link }}"
                    download="{{ requiredDocument.link.split('/')[12] }}"
                    target="_blank"
                    class="table__download external-link"
                  >
                    [{{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}]
                    <span class="visually-hidden">{{
                      (requiredDocument.details
                        ? requiredDocument.details + " "
                        : "") + ("EXTERNAL_LINK" | translate)
                    }}</span>
                  </a>
                </div>
                <div
                  *ngIf="requiredDocument.documentTypeId === 1000"
                  class="table__other_doc_banner"
                >
                  <ircc-cl-lib-banner
                    [config]="bannerConfig"
                  ></ircc-cl-lib-banner>
                </div>
              </td>
              <!-- Attachment -->
              <td class="documents-upload-table__attachment">
                <div
                  *ngFor="
                    let document of getFilesByDocumentType(
                      requiredDocument.value
                    )
                  "
                >
                  <pra-attachment-status
                    [state]="
                      document.statusType === 'incomplete'
                        ? 'warning'
                        : 'success'
                    "
                    [fileName]="document.documentName"
                    [fileStatus]="document.statusType"
                    [canDelete]="user.can('documents:write')"
                    (deleteFile)="
                      openDeleteModal(
                        document.rawDocument,
                        document.documentType
                      )
                    "
                    (downloadFile)="downloadFile(document.rawDocument)"
                  >
                  </pra-attachment-status>
                </div>
                <!-- temp attachment when uploading-->
                <div
                  *ngFor="
                    let temp of tempAttachments[indexOfRow];
                    let attachmentIndex = index
                  "
                >
                  <pra-attachment-status
                    [state]="temp.state"
                    [fileName]="temp.fileName"
                    [canDelete]="user.can('documents:write')"
                    (deleteFile)="
                      removeTempFileError(indexOfRow, attachmentIndex)
                    "
                    [error]="temp.error"
                  >
                  </pra-attachment-status>
                </div>
              </td>
              <td *ngIf="user.can('documents:write')">
                <lib-file-upload
                  fileTypes="{{ acceptedFileTypes }}"
                  (change)="
                    setTempFileInfoAndUpload(
                      $event,
                      indexOfRow,
                      requiredDocument.value
                    )
                  "
                  buttonStyle="primary-alt"
                  [disabled]="disableUpload()"
                  *ngIf="user.can('documents:write')"
                  id="supporting-documents"
                >
                  {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
                </lib-file-upload>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>

<div class="documents-upload-table mandatory-supporting-documents-mobile">
  <div class="documents-upload-table">
    <div class="documents-upload-table__container">
      <ng-container *ngIf="documents">
        <table class="table">
          <thead>
            <tr>
              <th>
                {{
                  "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.DOCUMENT_NAME"
                    | translate
                }}
              </th>
              <th>
                {{
                  "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TABLE_HEADINGS.ATTACHMENT"
                    | translate
                }}
              </th>
              <th *ngIf="user.can('documents:write')">
                {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="requiredDocumentOptions.length > 0">
            <tr
              *ngFor="
                let requiredDocument of requiredDocumentOptions;
                index as indexOfRow
              "
            >
              <!-- Document Type -->
              <td class="documents-upload-table__name">
                <span class="documents-upload-table__required">*</span
                >{{ requiredDocument.text }}
                <span class="documents-upload-table__required">
                  {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}</span
                >
              </td>
              <!-- Attachment -->
              <td class="documents-upload-table__attachment">
                <ng-container
                  *ngIf="
                    getFilesByDocumentType(requiredDocument.value).length > 0
                  "
                >
                  <div class="documents-upload-table__divider">
                    <hr />
                  </div>
                  <h5>Uploaded Files</h5>
                </ng-container>
                <div
                  *ngFor="
                    let document of getFilesByDocumentType(
                      requiredDocument.value
                    )
                  "
                >
                  <pra-attachment-status
                    [state]="
                      document.statusType === 'incomplete'
                        ? 'warning'
                        : 'success'
                    "
                    [fileName]="document.documentName"
                    [fileStatus]="document.statusType"
                    [canDelete]="user.can('documents:write')"
                    (deleteFile)="
                      openDeleteModal(
                        document.rawDocument,
                        document.documentType
                      )
                    "
                    (downloadFile)="downloadFile(document.rawDocument)"
                  >
                  </pra-attachment-status>
                </div>
                <div
                  *ngFor="
                    let temp of tempAttachments[indexOfRow];
                    let attachmentIndex = index
                  "
                >
                  <pra-attachment-status
                    [state]="temp.state"
                    [fileName]="temp.fileName"
                    (deleteFile)="
                      removeTempFileError(indexOfRow, attachmentIndex)
                    "
                    [error]="temp.error"
                  >
                  </pra-attachment-status>
                </div>
              </td>
              <td
                class="documents-upload-table__action"
                *ngIf="user.can('documents:write')"
              >
                <lib-file-upload
                  fileTypes="{{ acceptedFileTypes }}"
                  (change)="
                    setTempFileInfoAndUpload(
                      $event,
                      indexOfRow,
                      requiredDocument.value
                    )
                  "
                  buttonStyle="primary-alt"
                  [disabled]="disableUpload()"
                  *ngIf="user.can('documents:write')"
                  id="supporting-documents"
                >
                  {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
                </lib-file-upload>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>

<!-- DELETE CONFIRMATION MODAL -->
<lib-modal
  [id]="deleteModalId"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ "INTAKE.DELETE_MODAL.SUBHEADING" | translate }}
    </p>
    <p>
      {{ "INTAKE.DELETE_MODAL.DESCRIPTION" | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button
      type="button"
      category="primary"
      color="critical"
      (click)="deleteFile()"
    >
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button type="button" category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
