<div class="jds-escape">
  <nav class="navbar">
    <div class="navbar__container" *ngIf="isLoading">
      <!-- Left part of nav -->
      <div *ngIf="isClient" class="navbar__left-nav">
        <ul class="navbar__list">
          <!--HOME main menu item-->
          <li class="navbar__item">
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(this.lang, [
                  'APPLICATION',
                  'DASHBOARD'
                ])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="navbar__item-link"
              >{{ "INTAKE.APPLICATIONS_PAGE.TABLE.TITLE" | translate }}
            </a>
          </li>
        </ul>
      </div>
      <!-- Right part of nav -->
      <div
        class="navbar__right-nav"
        *ngIf="
          isClient &&
          (showMyApplicationLink || showMyProfileLink || isPSR || isGAR)
        "
      >
        <ul *ngIf="isClient" class="navbar__list-right">
          <!-- My Profile link -->
          <li
            *ngIf="showMyProfileLink"
            class="navbar__items"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              *ngIf="!isPSR && !isGAR"
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'PROFILE']) +
                currentCaseId
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.MY_PROFILE" | translate }}
            </a>

            <a
              *ngIf="isPSR"
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'PROFILE']) +
                currentCaseId
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.PRINCIPAL_APPLICANTS_PROFILE" | translate }}
            </a>
            <a
              *ngIf="isGAR"
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'PROFILE']) +
                currentCaseId
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.PRINCIPAL_APPLICANTS_PROFILE" | translate }}
            </a>
          </li>
          <!-- Manage Group Members link -->
          <li
            *ngIf="showManageGroupMembersLink && isPSR"
            class="navbar__items navbar__items--bell"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'CASE']) +
                currentCaseId +
                '/' +
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.MANAGE_GROUP_MEMBERS" | translate }}
            </a>
            <lib-notification-bell *ngIf="showBell"></lib-notification-bell>
          </li>

          <li
            *ngIf="showManageGroupMembersLink && isGAR && !isReturned"
            class="navbar__items"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'CASE']) +
                currentCaseId +
                '/' +
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.INVITE_PRINCIPAL_APPLICANT" | translate }}
            </a>
          </li>

          <li
            *ngIf="showManageGroupMembersLink && isGAR && isReturned"
            class="navbar__items navbar__item--disabled"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <button
              *ngIf="isReturned"
              class="btn-unstyled navbar__item-link-right navbar__item-link--disabled"
            >
              {{ "HEADER.NAV.INVITE_PRINCIPAL_APPLICANT" | translate }}
            </button>
          </li>

          <!-- View Group Members link -->
          <li
            *ngIf="showViewGroupMembersLink"
            class="navbar__items navbar__items--bell"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'CASE']) +
                currentCaseId +
                '/' +
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.VIEW_GROUP_MEMBERS" | translate }}
            </a>
            <lib-notification-bell
              *ngIf="showBellMember"
            ></lib-notification-bell>
          </li>
          <!-- My Application link -->
          <li
            *ngIf="showMyApplicationLink"
            class="navbar__items"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              *ngIf="!isPSR && !isGAR"
              [routerLink]="setMyApplicationURL()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.MY_APPLICATION" | translate }}
            </a>
            <a
              *ngIf="isPSR"
              [routerLink]="setMyApplicationURL()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.PRINCIPAL_APPLICANTS_APPLICATION" | translate }}
            </a>
            <a
              *ngIf="isGAR"
              [routerLink]="setMyApplicationURL()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.PRINCIPAL_APPLICANTS_APPLICATION" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <!-- rep profile below-->
      <div *ngIf="!isClient" class="navbar__left-nav">
        <ul class="navbar__list">
          <!--HOME main menu item-->
          <li class="navbar__item">
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(this.lang, [
                  'APPLICATION',
                  'DASHBOARD'
                ])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="navbar__item-link"
            >
              {{ "INTAKE.DASHBOARD.APPLICATIONS_PAGE.TITLE" | translate }}
            </a>
          </li>
          <!-- My account-->
          <li class="navbar__item">
            <a
              [routerLink]="routeLocalizer.get(lang, 'UPDATE_REP_ACCOUNT_INFO')"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="navbar__item-link"
            >
              {{ "HEADER.NAV.REP.ACCOUNT_DETAIL" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <div
        class="navbar__right-nav"
        *ngIf="!isClient && (showMyApplicationLink || showMyProfileLink)"
      >
        <ul class="navbar__list-right">
          <!-- My Profile link REP -->
          <li
            *ngIf="showMyProfileLink"
            class="navbar__items"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'PROFILE']) +
                currentCaseId
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.REP.CLIENT_PROFILE" | translate }}
            </a>
          </li>

          <!-- Manage Group Members link -->
          <li
            *ngIf="showManageGroupMembersLink"
            class="navbar__items navbar__items--bell"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'CASE']) +
                currentCaseId +
                '/' +
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.REP.MANAGE_GROUP_MEMBERS" | translate }}
            </a>
            <lib-notification-bell *ngIf="showBell"></lib-notification-bell>
          </li>

          <!-- View Group Members link -->
          <li
            *ngIf="showViewGroupMembersLink"
            class="navbar__items navbar__items--bell"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['INTAKE', 'CASE']) +
                currentCaseId +
                '/' +
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.REP.VIEW_GROUP_MEMBERS" | translate }}
            </a>
            <lib-notification-bell
              *ngIf="showBellMember"
            ></lib-notification-bell>
          </li>
          <!-- My Application link REP -->
          <li
            *ngIf="showMyApplicationLink"
            class="navbar__items"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="setMyApplicationURL()"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="btn-unstyled navbar__item-link-right"
            >
              {{ "HEADER.NAV.REP.CLIENT_APPLICATION" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
