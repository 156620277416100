import { Action } from "@ngrx/store";
import { Address } from "../../../confirmation-module/address/address.model";
import { Case, NewCase } from "../case.model";
import { Document, SelfDeclaration } from "../../../core/models/document.model";

export const SET_CASE_BY_EMAIL = "[Case] Set client case";
export const UPDATE_ADDRESS = "[Case] Update address";
export const SET_DOCUMENT_BY_CASE_ID = "[Case] Set client documents";
export const RESET_CASE_INFO = "[Case] Reset Case Info";
export const UPDATE_DECLARATION = "[Case] Update declaration";
export const UPDATE_DESIGNATED_ENTITY = "[Case] Update Designated Entity";

/* TODO: Rename this action: confusing, since payload is not email */
export class SetCaseByEmail implements Action {
  readonly type = SET_CASE_BY_EMAIL;
  constructor(public payload: NewCase | Case) {}
}

export class UpdateAddress implements Action {
  readonly type = UPDATE_ADDRESS;
  constructor(public payload: Address) {}
}

export class UpdateDeclaration implements Action {
  readonly type = UPDATE_DECLARATION;
  constructor(public payload: SelfDeclaration) {}
}

export class UpdateDesignatedEntity implements Action {
  readonly type = UPDATE_DESIGNATED_ENTITY;
  constructor(public payload: Case) {}
}

export class ResetCaseInfo implements Action {
  readonly type = RESET_CASE_INFO;
}
export class SetDocumentByCaseId implements Action {
  readonly type = SET_DOCUMENT_BY_CASE_ID;

  constructor(
    public payload: {
      caseDocuments: Document[] | [];
    }
  ) {}
}

export type CaseActions =
  | SetCaseByEmail
  | UpdateAddress
  | SetDocumentByCaseId
  | UpdateDeclaration
  | ResetCaseInfo
  | UpdateDesignatedEntity;
