import { createAction, props } from "@ngrx/store";

export const CREATE_NEW_IMM_DOCUMENT = "[Current IMM Doc] Create New Document";
export const UPDATE_IMM_DOCUMENT = "[Current IMM Doc] Update Document";
export const LOAD_CURRENT_IMM_DOCUMENT = "[Current IMM Doc] LOAD Document";
export const LOAD_CURRENT_FORM_FOR_IMM_DOCUMENT =
  "[Current IMM Doc] LOAD Current Form Document";

export const createNewCurrentIMMDocument = createAction(
  CREATE_NEW_IMM_DOCUMENT,
  props<{ newDocument: any }>()
);

export const loadCurrentIMMDocument = createAction(
  LOAD_CURRENT_IMM_DOCUMENT,
  props<{ documentInfo: any }>()
);

export const loadCurrentFormForIMMDocument = createAction(
  LOAD_CURRENT_FORM_FOR_IMM_DOCUMENT,
  props<{ currentForm: any }>()
);

export const updateCurrentIMMDocument = createAction(
  UPDATE_IMM_DOCUMENT,
  props<{ form: any }>()
);
