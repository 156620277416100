<section class="invite-group-member">
  <lib-layout-row mb="3">
    <header>
      <h1>
        {{ config.header | translate }}
      </h1>
      <p [innerHTML]="config.description | translate"></p>
    </header>
  </lib-layout-row>
  <section>
    <lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
    <form
      focusInvalidInput
      [formGroup]="inviteGroupMemberForm"
      autocomplete="off"
      (submit)="inviteGroupMember($event)"
      *ngIf="!isProcessing"
    >
      <fieldset *ngIf="!isPrimaryContact">
        <legend>
          {{ "PSR.INVITE_GROUP_MEMBER_PAGE.INTRO_HEADING" | translate }}
        </legend>
        <div
          [innerHTML]="'PSR.INVITE_GROUP_MEMBER_PAGE.INTRO_DETAILS' | translate"
        ></div>
      </fieldset>
      <pra-member-fields [form]="inviteGroupMemberForm"></pra-member-fields>
      <pra-form-buttons
        *ngIf="!isPrimaryContact"
        [form]="inviteGroupMemberForm"
      >
        <button type="submit" category="primary">
          {{ "PSR.INVITE_GROUP_MEMBER_PAGE.FORM.SUBMIT" | translate }}
        </button>
        <button
          [routerLink]="
            routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'], '../')
          "
          type="button"
          category="secondary"
          role="link"
        >
          {{ "PSR.INVITE_GROUP_MEMBER_PAGE.FORM.CANCEL" | translate }}
        </button>
      </pra-form-buttons>
    </form>
  </section>
</section>
<pra-invite-confirm
  [modalId]="modalId"
  [title]="'PSR.INVITE_GROUP_MEMBER_PAGE.MODAL.TITLE' | translate"
  [description]="'PSR.INVITE_GROUP_MEMBER_PAGE.MODAL.DESCRIPTION' | translate"
  [sendText]="'PSR.INVITE_GROUP_MEMBER_PAGE.MODAL.SEND' | translate"
  [cancelText]="'PSR.INVITE_GROUP_MEMBER_PAGE.MODAL.CANCEL' | translate"
  (confirmation)="inviteSend($event)"
></pra-invite-confirm>
