<div class="form-datepicker" aria-live="assertive">
  <label class="form-datepicker__label" [for]="identifier">
    <div class="form-datepicker__label-info">
      <div
        *ngIf="required && !hideRequired"
        class="form-datepicker__required-icon"
      >
        <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
      </div>
      <div class="form-datepicker__shift">
        {{ label }}
        <!-- <span *ngIf="required && !hideRequired" class="form-datepicker__required">
            ({{ requiredText }})
          </span> -->
      </div>
    </div>
    <div
      *ngIf="helpText !== ''"
      class="form-datepicker__help-text"
      [id]="identifier + '-help-text'"
      [innerHTML]="helpText"
    ></div>
  </label>

  <ng-container *ngIf="!readOnly">
    <div class="form-datepicker__field-container">
      <input
        class="form-datepicker__field"
        [type]="'text'"
        [id]="identifier"
        [name]="identifier"
        [mask]="shortFormat ? '0000/00' : '0000/00/00'"
        [showMaskTyped]="true"
        [formControl]="ngControl.control"
        [value]="value | safeTrim"
        [placeholder]="placeholder"
        (input)="onChange($event.target.value)"
        (blur)="handleBlur($event)"
        [attr.disabled]="readOnly ? true : null"
        [attr.aria-placeholder]="ariaPlaceHolder"
        [required]="required"
        [attr.aria-required]="required"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="readOnly">
    <!-- <span class="plaintext-field">
        {{ ngControl?.control?.value }}
      </span>
      <span *ngIf="!ngControl?.control?.value" class="visually-hidden">
        {{ noDataText }}
      </span> -->
    <div class="form-datepicker__field-container">
      <input
        class="form-datepicker__field"
        [type]="'text'"
        [value]="value | safeTrim"
        [attr.disabled]="true"
        [required]="required"
        [attr.aria-required]="required"
      />
    </div>
  </ng-container>

  <div
    *ngIf="ngControl.errors && ngControl.touched && !readOnly"
    class="form-datepicker__errors"
  >
    <span
      *ngIf="
        ngControl.hasError('required') ||
        ngControl.hasError('requiredDatePicker')
      "
    >
      {{ "FORM_ERRORS.REQUIRED" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('dateFormat')">
      {{ "INTAKE.FORM_ERRORS.DATE_FORMAT" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('dateFormatShort')">
      {{ "INTAKE.FORM_ERRORS.DATE_FORMAT_SHORT" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('isADate')">
      {{ "INTAKE.FORM_ERRORS.DATE" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('invalidFutureDate')">
      {{ "INTAKE.FORM_ERRORS.INVALID_FUTURE_DATE" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('futureDate')">
      {{ "INTAKE.FORM_ERRORS.FUTURE_DATE" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('isDateBeforeCompare')">
      {{ "INTAKE.FORM_ERRORS.DATE_COMPARE_BEFORE" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('isDateBeforeCompareEqualTo')">
      {{ "INTAKE.FORM_ERRORS.DATE_COMPARE_BEFORE_NOT_EQUAL" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('isDateAfterCompare')">
      {{ "INTAKE.FORM_ERRORS.END_DATE_AFTER_START" | translate }}
    </span>
    <span *ngIf="ngControl.hasError('isDateAfterCompareEqualTo')">
      {{ "INTAKE.FORM_ERRORS.DATE_COMPARE_AFTER_NOT_EQUAL" | translate }}
    </span>
    <span
      *ngIf="
        ngControl.hasError('pastDate') || ngControl.hasError('greaterThan1900')
      "
    >
      {{ "INTAKE.FORM_ERRORS.PAST_DATE" | translate }}
    </span>
    <ng-content>
      <!-- error spans -->
    </ng-content>
  </div>
  <lib-debug
    *ngIf="debug"
    [validators]="validators"
    [control]="ngControl"
  ></lib-debug>
</div>
