import {
  Component,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnChanges,
} from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AlertService, ValidationService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { IMM5562SectionBaseComponent } from "../imm5562-section-base/imm5562-section-base.component";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5562-section-b-form",
  templateUrl: "./imm5562-section-b.component.html",
  styleUrls: ["./imm5562-section-b.component.scss"],
})
export class IMM5562SectionBComponent
  extends IMM5562SectionBaseComponent
  implements OnInit
{
  public sectionBForm: FormGroup;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    protected cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private alertService: AlertService,
    private translate: TranslateService,
    private validationService: ValidationService,
    public user: UserService
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.sectionBForm = this.createSectionBFormGroup();
    this.addTrip();
  }

  public get isFormValid(): boolean {
    return this.sectionBForm?.valid;
  }

  public get isFormTouched(): boolean {
    return this.sectionBForm?.touched || this.isTripRemoved;
  }

  public get value(): any {
    return this.sectionBForm?.value;
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  public showFormInvalidErrors(): void {
    this.sectionBForm?.markAllAsTouched();
  }

  public patchFormData(data: any): void {
    if (data) {
      this.formValues = data.form;
      this.setFormValues();
      this.cdRef.detectChanges();
    }
  }

  public addTrip(index?: number): void {
    //  we call addTrip for the first time in ngOnInit, where there is no index value passed (index undefined)
    if (index === undefined || index < this.maxTrips - 1) {
      this.trips.push(this.createTripFormGroup());
    } else {
      this.alertService.danger(this.alertMaxTrips);
    }
  }

  public removeTrip(index: number): void {
    this.trips.removeAt(index);
    this.isTripRemoved = true;
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.isFormTouched;
  }

  //  formGroup for repeating trips formArray
  private createTripFormGroup(): FormGroup {
    return this.fb.group({
      length: [null, Validators.maxLength(this.maxFieldLengths.tripLength)],
      from: [null, Validators.maxLength(this.maxFieldLengths.dateFrom)],
      to: [null, Validators.maxLength(this.maxFieldLengths.dateTo)],
      destination: [
        null,
        Validators.maxLength(this.maxFieldLengths.destination),
      ],
      purposeOfTravel: [
        null,
        Validators.maxLength(this.maxFieldLengths.purpose),
      ],
    });
  }

  //  formGroup for entire section, including notrips checkbox and trips formArray
  private createSectionBFormGroup(): FormGroup {
    return this.fb.group({
      haveNotTravelled: [null],
      trips: this.trips,
    });
  }

  private setFormValues(): void {
    this.sectionBForm.controls?.haveNotTravelled.setValue(
      this.formValues.haveNotTravelled
    );
    this.setTrips();
  }

  //  set trips formArray, creating a formGroup for each
  private setTrips(): void {
    const savedTrips = this.formValues?.trips;
    if (savedTrips && savedTrips.length > 0) {
      this.trips.clear(); // reset the form array at first
      savedTrips.forEach((trip: any) => {
        const tripFields: FormGroup = this.createTripFormGroup();
        tripFields?.patchValue({
          length: trip.length,
          from: trip.from,
          to: trip.to,
          destination: trip.destination,
          purposeOfTravel: trip.purposeOfTravel,
        });
        this.trips.push(tripFields);
      });
    }
  }

  private get alertMaxTrips(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }
}
