<h1 *ngIf="isRep" class="view-my-applications__heading emphasis">
  {{ "INTAKE.DASHBOARD.APPLICATIONS_PAGE.TITLE" | translate }}
</h1>
<h1 *ngIf="isClient" class="view-my-applications__heading">
  {{ "INTAKE.APPLICATIONS_PAGE.TABLE.TITLE" | translate }}
</h1>

<div class="view-my-applications__table-container">
  <ng-container *ngIf="tableCases">
    <div class="create-new-btn" *ngIf="isClient">
      <button
        type="button"
        class="btn btn-primary view-my-applications__new-application"
        [routerLink]="routeLocalizer.get(lang, 'START', '../')"
      >
        <lib-icon-chooser icon="add"></lib-icon-chooser>
        {{
          "INTAKE.DASHBOARD.APPLICATIONS_PAGE.CREATE_NEW_APPLICATION"
            | translate
        }}
      </button>
    </div>
    <div class="create-new-btn" *ngIf="isRep">
      <button
        type="button"
        class="btn btn-primary view-my-applications__new-application"
        [routerLink]="
          routeLocalizer.getNestedPath(lang, ['APPLICATION', 'NEW'], '../../')
        "
      >
        <lib-icon-chooser icon="add"></lib-icon-chooser>
        {{
          "INTAKE.DASHBOARD.APPLICATIONS_PAGE.NEW_PR_APPLICATION" | translate
        }}
        <!-- New PR application -->
      </button>
    </div>

    <div class="create-new-btn" *ngIf="isRep">
      <button
        type="button"
        class="btn btn-primary view-my-applications__new-application"
        [routerLink]="
          routeLocalizer.getNestedPath(
            lang,
            ['RENEWAL', 'RENEWAL_REP_NEWCASE'],
            '../../'
          )
        "
      >
        <lib-icon-chooser icon="add"></lib-icon-chooser>
        {{
          "INTAKE.DASHBOARD.APPLICATIONS_PAGE.NEW_PRCPRTD_APPLICATION"
            | translate
        }}
      </button>
    </div>

    <div class="search-row">
      <pra-cases-search
        *ngIf="isRep"
        [copyKey]="
          'INTAKE.DASHBOARD.CASE_LIST_PAGE.TABLE_HEADINGS.EMAIL_OR_NAME_SEARCH'
        "
        (searchedTerm)="searchedTerm($event)"
        class="email-search medium-margin-top"
      >
      </pra-cases-search>
      <div class="right-side">
        <lib-form-select
          identifier="statusFilter"
          [formControl]="statusFilter"
          [label]="
            'INTAKE.DASHBOARD.CASE_LIST_PAGE.TABLE_HEADINGS.STATUS_SEARCH'
              | translate
          "
          [options]="statusFilterOptions"
          class="status-search medium-margin-top"
          [eraseMarginBottom]="true"
        ></lib-form-select>

        <lib-form-select
          identifier="lobFilter"
          [formControl]="lobFilter"
          [label]="'INTAKE.DASHBOARD.APPLICATIONS_PAGE.LOB_FILTER' | translate"
          [options]="lobFilterOptions"
          class="status-search medium-margin-top"
          [eraseMarginBottom]="true"
        ></lib-form-select>
      </div>
    </div>
    <div class="wrapper">
      <lib-spinner
        [showSpinner]="tableSpinner"
        [fullPage]="false"
        [useOverlay]="true"
      ></lib-spinner>
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortActive]="sortActive"
        (matSortChange)="sortData($event)"
        [matSortDirection]="sortDirection"
        class="table"
      >
        <!--- column order is based on array list order -->

        <!-- Client details (client) -->
        <ng-container matColumnDef="clientDetailsClient">
          <th
            *matHeaderCellDef
            class="header-clientDetailsClient"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{
              "INTAKE.DASHBOARD.APPLICATIONS_PAGE.APPLICATION_DETAILS"
                | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <!-- {{ element.id }} -->
            <div *ngIf="element.phaseId === 2">
              <div class="no-wrap-ellipsis small-margin-bottom">
                <strong [title]="element.clientName"
                  >{{ element.clientName }}
                </strong>
              </div>
              <div class="small-margin-bottom">
                <strong
                  >{{ "CASELIST.TABLE_HEADINGS.UCI" | translate }}:</strong
                >
                {{ element.uci }}
              </div>
              <div class="small-margin-bottom">
                <strong
                  >{{ "CASELIST.TABLE_HEADINGS.DOB" | translate }}:</strong
                >
                {{ formatDate(element.dob, false, false) }}
              </div>

              <br />
            </div>

            <div *ngIf="element.phaseId === 1" class="no-wrap-ellipsis">
              <div class="small-margin-bottom">
                <strong [title]="element.appName">{{ element.appName }}</strong>
              </div>
              <div
                *ngIf="element.statusId === 7 && element.showDownloadLink"
                class="no-wrap-ellipsis"
              >
                <i class="fa-light fa-file-arrow-down"></i>&nbsp;
                <a
                  (click)="downloadAllDocuments(element.id, element.appName)"
                  class="download-file-button"
                  >{{
                    "INTAKE.SUBMITTED_CASE_DOWNLOAD_ALL_DOCUMENTS" | translate
                  }}
                </a>
              </div>
            </div>
            <div *ngIf="element.phaseId === 3">
              <span *ngIf="element.urgencyRequestDate">
                <div class="urgent-requested-label small-margin-bottom">
                  {{
                    "INTAKE.DASHBOARD.APPLICATIONS_PAGE.URGENCY_REQUESTED"
                      | translate
                  }}
                </div>
                <span class="urgent-requested-date">
                  {{ formatDate(element.urgencyRequestDate, false, false) }}
                </span>
              </span>
              <span
                class="urgent-requested-label"
                *ngIf="element.isUrgent && !element.urgencyRequestDate"
                >{{
                  "INTAKE.DASHBOARD.APPLICATIONS_PAGE.URGENCY_REQUESTED"
                    | translate
                }}</span
              >
            </div>
            <p *ngIf="element.clientName === null" class="visually-hidden">
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </ng-container>

        <!-- Client details (rep) -->
        <ng-container matColumnDef="clientDetailsRep">
          <th
            *matHeaderCellDef
            class="header-clientDetailsRep"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{
              "INTAKE.DASHBOARD.APPLICATIONS_PAGE.APPLICATION_DETAILS"
                | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <!-- {{ element.id }} -->
            <div *ngIf="element.phaseId === 1">
              <div class="no-wrap-ellipsis small-margin-bottom">
                <strong [title]="element.appName">{{ element.appName }}</strong>
              </div>
              <div
                class="no-wrap-ellipsis small-margin-bottom"
                [title]="element.clientName"
              >
                {{ element.clientName }}
              </div>
              <div
                class="no-wrap-ellipsis small-margin-bottom"
                [title]="element.clientEmail"
              >
                {{ element.clientEmail }}
              </div>
              <div *ngIf="element.statusId === 7" class="no-wrap-ellipsis">
                <i class="fa-light fa-file-arrow-down"></i>&nbsp;
                <a
                  (click)="downloadAllDocuments(element.id, element.appName)"
                  class="download-file-button"
                  >{{
                    "INTAKE.SUBMITTED_CASE_DOWNLOAD_ALL_DOCUMENTS" | translate
                  }}
                </a>
              </div>
            </div>
            <div *ngIf="element.phaseId === 3">
              <div class="no-wrap-ellipsis small-margin-bottom">
                <strong [title]="element.appName">{{ element.appName }}</strong>
              </div>
              <div
                class="no-wrap-ellipsis small-margin-bottom"
                [title]="element.clientName"
              >
                {{ element.clientName }}
              </div>
              <div
                class="no-wrap-ellipsis small-margin-bottom"
                [title]="element.clientEmail"
              >
                {{ element.clientEmail }}
              </div>
              <span *ngIf="element.urgencyRequestDate">
                <div class="urgent-requested-label small-margin-bottom">
                  {{
                    "INTAKE.DASHBOARD.APPLICATIONS_PAGE.URGENCY_REQUESTED"
                      | translate
                  }}
                </div>
                <span class="urgent-requested-date">
                  {{ formatDate(element.urgencyRequestDate, false, false) }}
                </span>
              </span>
              <span
                class="urgent-requested-label"
                *ngIf="element.isUrgent && !element.urgencyRequestDate"
                >{{
                  "INTAKE.DASHBOARD.APPLICATIONS_PAGE.URGENCY_REQUESTED"
                    | translate
                }}</span
              >
            </div>
            <p *ngIf="element.clientName === null" class="visually-hidden">
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </ng-container>

        <!-- Status Date -->
        <ng-container matColumnDef="statusDate">
          <th
            *matHeaderCellDef
            mat-sort-header
            class="header-statusDate"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{
              "INTAKE.DASHBOARD.APPLICATIONS_PAGE.STATUS_UPDATED" | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <!-- {{ _moment.utc(element.statusDate).local().fromNow() }} <br /> -->
            <!-- _moment.utc(element.statusDate).local().format("yyyy/MM/DD HH:mm") -->
            <span [title]="formatDate(element.statusDate, true)">{{
              formatDate(element.statusDate)
            }}</span>
          </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="categoryName">
          <th
            *matHeaderCellDef
            mat-sort-header
            class="header-categoryName"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{ "INTAKE.APPLICATIONS_PAGE.TABLE.CATEGORY" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{ lobService.getCategoryDisplayText(element.lob) }}
            <p *ngIf="element.lob.category === null" class="visually-hidden">
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </ng-container>

        <!-- Submitted Column -->
        <ng-container matColumnDef="submittedAt">
          <th
            *matHeaderCellDef
            mat-sort-header
            class="header-submittedAt"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{
              "INTAKE.DASHBOARD.APPLICATIONS_PAGE.SUBMISSION_DATE" | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <span
              [title]="formatDate(element.submittedAt, true)"
              *ngIf="element.submittedAt"
              >{{ formatDate(element.submittedAt) }}</span
            >
            <p *ngIf="!element.submittedAt" class="visually-hidden">
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th
            tabindex="0"
            *matHeaderCellDef
            class="header-status"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{ "INTAKE.APPLICATIONS_PAGE.TABLE.APP_STATUS" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <div class="small-margin-bottom">
              <lib-status-pill
                [text]="element.status.label | translate"
                [type]="element.status.type"
                [wordBreak]="true"
              >
              </lib-status-pill>
            </div>

            <div
              *ngIf="
                element.status.type === 'returned' && element.phaseId === 3
              "
            >
              <div class="rejection-reasons-list">
                <strong *ngIf="element.rejectionReasons.length === 1">{{
                  "INTAKE.DASHBOARD.APPLICATIONS_PAGE.RETURN_REASON" | translate
                }}</strong>
                <strong *ngIf="element.rejectionReasons.length > 1">{{
                  "INTAKE.DASHBOARD.APPLICATIONS_PAGE.RETURN_REASONs"
                    | translate
                }}</strong>
                <ul class="small-margin-top">
                  <ng-container *ngFor="let reason of element.rejectionReasons">
                    <li>
                      <span
                        class="view-my-applications__rejection-reason-row"
                        *ngIf="lang === 'en'; else elseFrenchRejection"
                        >{{ reason.rejectionReasonEn }}</span
                      >
                      <ng-template
                        class="view-my-applications__rejection-reason-row"
                        #elseFrenchRejection
                        >{{ reason.rejectionReasonFr }}</ng-template
                      >
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Client signatures -->
        <ng-container matColumnDef="clientSignedDocs">
          <th
            *matHeaderCellDef
            class="header-clientSignedDocs"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
            tabindex="0"
          >
            {{
              "INTAKE.DASHBOARD.APPLICATIONS_PAGE.CLIENT_SIGNATURES" | translate
            }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <span *ngIf="element.mandatoryDocsSignedByClient"
              ><span
                class="fa-light fa-file-contract fa-xl signatures-icon-margin"
              ></span>
              {{ "INTAKE.DASHBOARD.APPLICATIONS_PAGE.SIGNED" | translate }}
            </span>

            <span *ngIf="!element.mandatoryDocsSignedByClient">
              {{
                "INTAKE.DASHBOARD.APPLICATIONS_PAGE.NO_SIGNATURE" | translate
              }}
            </span>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th
            tabindex="0"
            *matHeaderCellDef
            class="header-action"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            {{ "INTAKE.APPLICATIONS_PAGE.TABLE.ACTION" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ isRep: isRep, isClient: isClient }"
          >
            <div class="table-action-container">
              <a
                *ngIf="element.showViewButton"
                [routerLink]="element.link"
                category="primary"
              >
                {{ "INTAKE.APPLICATIONS_PAGE.TABLE.VIEW" | translate }}
                <span class="visually-hidden">{{ element.appName }}</span>
              </a>
              <button
                class="btn btn-primary-alt"
                *ngIf="element.showDisabledViewButton"
                [disabled]="element.showDisabledViewButton"
              >
                {{ "INTAKE.APPLICATIONS_PAGE.TABLE.VIEW" | translate }}
                <span class="visually-hidden">{{ element.appName }}</span>
              </button>
              <button
                type="button"
                [disabled]="!element.enableDeleteButton"
                *ngIf="element.showDeleteButton"
                category="plain"
                color="critical"
                (click)="openModal('confirmDeleteCase', element.id)"
              >
                <lib-icon-chooser icon="delete"></lib-icon-chooser>
                <!-- {{ "INTAKE.APPLICATIONS_PAGE.TABLE.DELETE" | translate }} -->
              </button>

              <p
                *ngIf="element.showExpiredText"
                [ngClass]="{ 'expired-case': element.showExpiredText }"
                [innerHTML]="
                  'INTAKE.APPLICATIONS_PAGE.TABLE.EXPIRED' | translate
                "
              >
                <span class="visually-hidden">{{ element.appName }}</span>
              </p>
              <p
                *ngIf="element.showNoReturnedAccessSponsorText"
                [ngClass]="{
                  'expired-case': element.showNoReturnedAccessSponsorText
                }"
                [innerHTML]="
                  'INTAKE.APPLICATIONS_PAGE.TABLE.NO_RETURNED_ACCESS_PRIMARY_SPONSOR'
                    | translate
                "
              >
                <span class="visually-hidden">{{ element.appName }}</span>
              </p>
              <p
                *ngIf="element.showNoReturnedAccessRepresentativeText"
                [ngClass]="{
                  'expired-case': element.showNoReturnedAccessRepresentativeText
                }"
                [innerHTML]="
                  'INTAKE.APPLICATIONS_PAGE.TABLE.NO_RETURNED_ACCESS_REPRESENTATIVE'
                    | translate
                "
              >
                <span class="visually-hidden">{{ element.appName }}</span>
              </p>
            </div>
          </td>
        </ng-container>
        <thead>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        </thead>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9999">
            <span class="no-match-records">{{
              "INTAKE.DASHBOARD.CASE_LIST_PAGE.TABLE.NO_MATCH_RECORDS"
                | translate
            }}</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="paginator" style="display: none">
      <mat-paginator
        [pageSizeOptions]="[50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
    <lib-bttns-paginator
      *ngIf="hasRecordsToShow"
      (checkedPageNumber)="goToPageNumber($event)"
      [listNumbers]="paginationNumber"
    ></lib-bttns-paginator>
  </ng-container>
</div>

<!-- MODALS -->
<lib-modal
  id="confirmDeleteCase"
  [title]="'INTAKE.DELETE_CASE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p>
      {{ "INTAKE.DELETE_CASE_MODAL.DESCRIPTION" | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="deleteCase()">
      {{ "INTAKE.DELETE_CASE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal('confirmDeleteCase')">
      {{ "INTAKE.DELETE_CASE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
