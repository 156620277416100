import { Component, Input } from "@angular/core";

export type CaseStatusBoxType =
  | "edit"
  | "wait"
  | "problem"
  | "success"
  | "capacity";

@Component({
  selector: "lib-case-status-box",
  templateUrl: "./case-status-box.component.html",
  styleUrls: ["./case-status-box.component.scss"],
})
export class CaseStatusBoxComponent {
  @Input() title?: string;
  @Input() type: CaseStatusBoxType;

  public icons: any = {
    edit: "circle-edit",
    wait: "circle-timer",
    problem: "triangle-danger",
    success: "circle-check",
    capacity: "triangle-danger",
  };

  constructor() {}
}
