import { Component, ChangeDetectorRef, OnInit, Input } from "@angular/core";
import { IMM5562SectionBaseComponent } from "../imm5562-section-base/imm5562-section-base.component";

@Component({
  selector: "pra-imm5562-start",
  templateUrl: "./imm5562-start.component.html",
  styleUrls: ["./imm5562-start.component.scss"],
})
export class IMM5562StartComponent extends IMM5562SectionBaseComponent {
  constructor(protected cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  public get isFormValid(): boolean {
    return true;
  }
  public get isFormTouched(): boolean {
    return true;
  }
  public get value(): any {
    return {};
  }

  public showFormInvalidErrors(): void {}
  public patchFormData(data: any): void {}

  // always returns false because there is no form on this page
  public isFormDataUnSaved(): boolean {
    return false;
  }
}
