import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LibModule } from "lib";
import { HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "./services/auth.service";
import { ValidationService } from "lib";
import { CaseService } from "../../shared/case-module/case.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory, SharedModule } from "../../shared/shared.module";
import { RouterModule } from "@angular/router";

import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { FirstResetPageComponent } from "./pages/first-login-reset-page/first-reset-page.component";

import { LoginComponent } from "./components/login/login.component";
import { ResetComponent } from "./components/reset/reset.component";
import { ResetPasswordPageComponent } from "./pages/reset-password-page/reset-password-page.component";
import { ForgotPasswordPageComponent } from "./pages/forgot-password-page/forgot-password-page.component";
import { SignUpPageComponent } from "./pages/sign-up-page/sign-up-page.component";
import { SignUpVerificationPageComponent } from "./pages/sign-up-verification-page/sign-up-page-verification.component";

@NgModule({
  declarations: [
    LoginPageComponent,
    FirstResetPageComponent,
    LoginComponent,
    ResetComponent,
    ResetPasswordPageComponent,
    ForgotPasswordPageComponent,
    SignUpPageComponent,
    SignUpVerificationPageComponent,
  ],
  providers: [AuthService, ValidationService, CaseService],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    LibModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    SharedModule,
  ],
})
export class AuthModule {}
