import { Component, OnInit } from "@angular/core";
import {
  Validators,
  FormControl,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { AlertService, ModalService, ValidationService } from "lib";
import { first, Subscription, debounceTime, merge } from "rxjs";
import {
  IPrincipalApplicant,
  IPrincipalApplicantsRep,
  PsrService,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { PsrValidationService } from "@pr-applicant/app/shared/services/psr/psr-validation.service";
import { CognitoHelperService } from "@pr-applicant/app/shared/services/utils/cognitoHelper.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pra-invite-principal-applicants-rep",
  templateUrl: "./invite-principal-applicants-rep.component.html",
  styleUrls: ["./invite-principal-applicants-rep.component.scss"],
})
export class InvitePrincipalApplicantsRepComponent implements OnInit {
  constructor(
    public routeLocalizer: RouteLocalizerService,
    public activeRoute: ActivatedRoute,
    private validationService: ValidationService,
    private modalService: ModalService,
    private psrService: PsrService,
    private psrValidationService: PsrValidationService,
    private cognitoHelper: CognitoHelperService,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  public lang: string;
  public caseId: string = "";
  private subscriptions: Subscription[] = [];
  public principalApplicantsRep: IPrincipalApplicant;
  public isProcessing: boolean = false; // toggle true/false based on api response
  public modalId = "confirmSendInvite";
  public returnPath: string;
  public isFetchingCognito: boolean = false;
  public invitePrincipalApplicantsRep: FormGroup = new FormGroup(
    {
      familyName: new FormControl("", {
        validators: [Validators.required],
      }),
      givenName: new FormControl("", {
        validators: [],
      }),
      emailAddress: new FormControl("", {
        validators: [Validators.required, Validators.email],
      }),
      emailConfirm: new FormControl("", {
        validators: [Validators.email],
      }),
      paidRep: new FormControl("", {
        validators: [],
      }),
    },
    {
      validators: [
        this.validationService.validatorEmailMatching,
        //this.validationService.validatorCheckGroupByEmailAndRadio,
      ],
    }
  );

  public translations = {
    familyName: "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.FAMILY_NAME",
    givenName: "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.GIVEN_NAME",
    emailAddress: "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.EMAIL",
    emailConfirm: "PSR.INVITE_PRINCIPAL_APPLICANTS_REP_PAGE.FORM.EMAIL_CONFIRM",
  };

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.returnPath = this.routeLocalizer.getNestedPath(
      this.lang,
      ["MANAGE_GROUP_MEMBERS"],
      "../"
    );
    this.subscriptions.push(
      this.activeRoute.params.subscribe((params) => {
        this.caseId = params.caseId;
      })
    );
    this.subscriptions.push(
      merge(
        this.invitePrincipalApplicantsRep.controls.emailAddress.valueChanges,
        this.invitePrincipalApplicantsRep.controls.emailConfirm.valueChanges,
        this.invitePrincipalApplicantsRep.controls.paidRep.valueChanges
      ).subscribe(() => {
        if (this.invitePrincipalApplicantsRep.controls.touched) {
          this.invitePrincipalApplicantsRep.controls.paidRep.setErrors(null);
        }
      })
    );

    this.subscriptions.push(
      this.checkCognito(
        this.invitePrincipalApplicantsRep.controls.emailAddress
      ),
      this.checkCognito(
        this.invitePrincipalApplicantsRep.controls.emailConfirm
      ),
      this.checkCognito(this.invitePrincipalApplicantsRep.controls.paidRep),
      this.invitePrincipalApplicantsRep.controls.paidRep.valueChanges.subscribe(
        () => {
          this.invitePrincipalApplicantsRep.controls.paidRep.markAsTouched();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  inviteConfirm(e: any) {
    if (this.isFetchingCognito) {
      return;
    }
    if (this.invitePrincipalApplicantsRep.invalid) {
      this.invitePrincipalApplicantsRep.markAllAsTouched();
    } else {
      this.modalService.open(this.modalId);
    }
  }

  public checkCognito(formControl: AbstractControl): Subscription {
    return formControl.valueChanges.pipe(debounceTime(20)).subscribe((form) => {
      if (formControl.parent?.valid) {
        this.isFetchingCognito = true;
        this.cognitoHelper
          .isCorrectUserGroup(
            this.invitePrincipalApplicantsRep.value.emailAddress.toString()
          )
          .subscribe((cognitoResponse) => {
            this.setPaidRepErrors(cognitoResponse);
            this.isFetchingCognito = false;
          });
      }
    });
  }

  private setPaidRepErrors(cognitoResponse: string) {
    const paidRepToggle = this.invitePrincipalApplicantsRep.controls.paidRep;

    if (paidRepToggle.value === true) {
      if (cognitoResponse === "Not a Rep") {
        paidRepToggle.setErrors({ isClient: true });
      } else {
        paidRepToggle.setErrors(null);
      }
    } else if (this.invitePrincipalApplicantsRep.value.paidRep === false) {
      if (cognitoResponse === "Not an Applicant") {
        paidRepToggle.setErrors({ isPaidRep: true });
      } else {
        paidRepToggle.setErrors(null);
      }
    }
  }

  inviteSend(check: boolean) {
    if (this.isFetchingCognito) {
      return;
    }
    const applicantRep = this.invitePrincipalApplicantsRep.value;
    const roleId = applicantRep.paidRep
      ? RoleIds.PAID_REFUGEE_REP
      : RoleIds.UNPAID_REFUGEE_REP;
    delete applicantRep.emailConfirm;
    if (check) {
      this.psrService
        .addMember(
          applicantRep as IPrincipalApplicantsRep,
          Number(this.caseId),
          roleId
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.router.navigate([`${this.returnPath}`], {
              relativeTo: this.activeRoute,
            });
          },
          error: (error) => {
            console.log("Invite Error", error.response.status);
            if (error.response.status === 409) {
              this.alertService.danger(
                this.translate.instant(
                  "PSR.INVITE_GROUP_MEMBER_PAGE.MEMBER_EXISTS"
                )
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          },
        });
    }
  }
  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }
}
