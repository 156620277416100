import { Injectable } from "@angular/core";
import { LobIds } from "lib";

@Injectable({
  providedIn: "root",
})
export class GarService {
  public isGAR(lobId: number | null): boolean {
    return lobId && lobId === LobIds.GovernmentAssistedRefugeesProgram
      ? true
      : false;
  }
}
export interface IReferralPartner {
  referralPartnerGivenName: string;
  referralPartnerFamilyName: string;
  isReferralPartner: boolean;
  referralPartnerOrganization: boolean;
}
