<div class="status-box" class="status-box--{{ type }}">
  <img
    class="status-box__icon"
    src="/assets/lib-assets/icons/{{ icons[type] }}.svg"
    aria-hidden="true"
    alt=""
  />
  <div class="status-box__text">
    <p *ngIf="title" class="status-box__title">{{ title }}</p>
    <ng-content><!-- status-box content gets projected here --></ng-content>
  </div>
</div>
