<div class="confirmation-landing">
  <h1 class="confirmation-landing__heading emphasis">
    {{ "HOME.HEADING" | translate : headingParams }}
  </h1>
  <hr class="confirmation-landing__ruler" />

  <p class="confirmation-landing__content" *ngIf="case.caseStatusId === 1">
    {{ "HOME.PARAGRAPH" | translate }}
  </p>
  <lib-case-status-box [type]="statusBoxType" [title]="statusLabel | translate">
    <div [innerHTML]="actionLabel | translate"></div>
  </lib-case-status-box>

  <h2 class="emphasis confirmation-landing__subheading">
    {{ "HOME.SUBHEADING" | translate }}
  </h2>
  <pra-applicant-steps [confirmationLanding]="true"></pra-applicant-steps>
  <pra-copr-download [documents]="case.documents"></pra-copr-download>

  <a
    class="case-button btn btn-secondary"
    [routerLink]="
      routeLocalizer.get(currentLang, 'CONFIRMATION_CASES', '../../../../../')
    "
    category="secondary"
  >
    {{ "HOME.BUTTON_PREVIOUS" | translate }}
  </a>
</div>
