import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormSelectOption } from "lib";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DocumentsUploadTableComponent } from "../../supporting-documents-upload/documents-upload-table/documents-upload-table.component";
import {
  Document,
  RawAppForm,
} from "../../../../../core/models/document.model";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "pra-other-documents",
  templateUrl: "./other-documents.component.html",
  styleUrls: ["./other-documents.component.scss"],
})
export class OtherDocumentsComponent
  extends DocumentsUploadTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isHiddenLob: boolean = false;
  @Input() otherDocumentOptions: FormSelectOption[];

  public formControl = new FormControl("");
  public options: any[];
  public filteredOptions: Observable<string[]>;
  public otherDocumentsRows: FormSelectOption[] = [];
  public documents: any[] = [];
  public allDocuments: any[] = [];
  public lang: string;
  public toolTipContent: any;
  public deleteModalId = "confirmDeleteOtherDoc";
  public deleteFn: Function = () => undefined;
  public deleteFnParams: number[] = [];
  public deleteHeading: string = "";
  public deleteDescription: string = "";
  public loadingDocs: boolean;

  @ViewChild("otherDocumentsInput") tableInputElement!: ElementRef<any>;
  @ViewChild("otherDocumentsContainer") scrollToRef!: ElementRef<any>;

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getStoredCase();
    this.updateSupportingDocuments().then(() => {
      this.createTableRows();
      this.loadingDocs = false;
    });
    this.initAutocomplete();
    if (!this.user.can("documents:write")) {
      this.formControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initAutocomplete();
    if (this.otherDocumentOptions.length > 0) {
      this.filterSupportingDocuments();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public filterSupportingDocuments(): void {
    const supplementalDocsTableRows: any[] = [];
    this.supplementalDocsIds = this.otherDocumentOptions.map(
      (option) => option.value
    );
    const supplementalDocs = this.allDocuments?.filter((doc: Document) =>
      this.supplementalDocsIds.includes(doc.documentTypeId)
    );
    supplementalDocs?.map((doc: Document) => {
      const tableDoc = this.formatDocumentForTable(doc);
      supplementalDocsTableRows.push(tableDoc);
    });
    this.documents = supplementalDocsTableRows;
  }

  public async updateSupportingDocuments(): Promise<void> {
    try {
      this.allDocuments = (
        await this.documentService.getDocumentByCaseId(this.caseId)
      ).filter((doc: any) => !doc.caseMemberId);

      this.filterSupportingDocuments();
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // controls Autocomplete select array
  public initAutocomplete(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(""),
      map((value) => {
        this.options = this.otherDocumentOptions;
        return this.options.filter((option) =>
          this.match(option.text[this.lang], value!)
        );
      })
    );
  }

  // populate rows if matching document has been uploaded to case
  public createTableRows(): void {
    const tableRows: FormSelectOption[] = [];
    this.otherDocumentOptions.forEach((document: FormSelectOption) => {
      if (this.getFilesByDocumentType(document.value).length > 0) {
        tableRows.push(document);
      }
    });
    this.otherDocumentsRows = tableRows;
  }

  public addRow(newDoc: FormSelectOption, event: any): void {
    if (
      event.isUserInput &&
      this.otherDocumentsRows.filter(
        (doc: FormSelectOption) => doc.value === newDoc.value
      ).length === 0
    ) {
      this.otherDocumentsRows = [...this.otherDocumentsRows, newDoc];
      this.updateSupportingDocuments();
    }
  }

  public removeRow(rowIndex: number, documentType: number): void {
    this.deleteAllFilesByType(documentType)
      .then(() => {
        this.shiftTempAttachments(rowIndex);
        this.otherDocumentsRows = this.otherDocumentsRows.filter(
          (row: FormSelectOption, index) => index !== rowIndex
        );
        this.alertService.success(this.alertDeleteSuccess);
        this.updateSupportingDocuments();
        this.closeModal();
        this.documentsUpdated();
      })
      .catch((err) => {
        this.alertService.danger(this.alertTechnicalError);
        this.closeModal();
      });
  }

  public shiftTempAttachments(removeIndex: number) {
    let errorMap: { [key: number]: any[] } = {};
    Object.keys(this.tempAttachments).forEach((key) => {
      const _key = Number(key);
      if (Number(_key) <= removeIndex - 1) {
        errorMap[_key] = this.tempAttachments[_key];
      } else if (_key > removeIndex) {
        errorMap[Number(key) - 1] = this.tempAttachments[_key];
      }
    });
    this.tempAttachments = errorMap;
  }

  public async deleteAllFilesByType(documentType: number): Promise<any> {
    try {
      const documents = await this.getFilesByDocumentType(documentType);
      if (documents.length > 0) {
        const promises: Promise<any>[] = [];
        documents.forEach((document: any) => {
          this.tempDeleteDocument = document.rawDocument;
          promises.push(this.deleteFile());
        });
        return Promise.all(promises);
      }
    } catch {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // sets tempDelete properties before calling deleteFile()
  public removeFile(document: any, documentType: string): void {
    this.tempDeleteDocument = document;
    this.tempDeleteDocumentType = documentType;
    this.deleteFile()
      .then((res) => {
        this.documents = this.documents.filter(
          (doc) => doc.rawDocument.id !== this.tempDeleteDocument?.id
        );
        this.lastDeletedFileType = this.tempDeleteDocumentType;
        this.alertService.success(this.alertDeleteSuccess);
        this.closeModal();
        this.documentsUpdated();
      })
      .catch((err) => {
        this.alertService.danger(this.alertTechnicalError);
        this.closeModal();
      });
  }
  public async deleteFile(): Promise<any> {
    if (this.tempDeleteDocument) {
      await this.documentService.deleteFileByDocumentTypeAndId(
        this.caseId,
        Number(this.tempDeleteDocument.documentTypeId),
        this.tempDeleteDocument.id
      );
    }
  }

  public resetInput(): void {
    this.formControl.setValue("");
    this.tableInputElement.nativeElement.blur();
  }
  private match(string1: string, string2: string) {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }
  public disableUpload(): boolean {
    return this.isHiddenLob ? true : false;
  }

  // delete confirmation modal can only render one instance
  // made it dynamic to accomodate delete/deleteAllFile functions
  public openDynamicDeleteModal(
    subHeading: string,
    description: string,
    deleteFn: Function,
    ...params: number[]
  ): void {
    this.modalService.open(this.deleteModalId);
    this.deleteFn = deleteFn;
    this.deleteFnParams = params;
    this.deleteHeading = subHeading;
    this.deleteDescription = description;
  }
  // method to open autocomplete on first and subsequent input clicks
  // scrolls input to top if view height cannot fit autocomplete dropdown
  // useful for mobile when scroll closes select window
  public handleClick(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    setTimeout(() => {
      if (
        window.visualViewport?.height &&
        window.visualViewport?.height < 480
      ) {
        this.scrollToRef.nativeElement.scrollIntoView();
      }
    }, 350);
  }
}
