import { Component, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { Subscription, filter, takeWhile } from "rxjs";
import {
  IPrincipalApplicant,
  IPrincipalApplicantsRep,
  IGroupMember,
  RoleIds,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import { IBannerConfig } from "ircc-ds-angular-component-library";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { AlertService, caseStatuses } from "lib";
import { GarService } from "../../../../shared/services/gar/gar.service";

@Component({
  selector: "pra-manage-group-members",
  templateUrl: "./manage-group-members.component.html",
  styleUrls: ["./manage-group-members.component.scss"],
})
export class ManageGroupMembersComponent implements OnInit {
  public lang: string;
  public caseId: string = "";
  public enablePaInvite: Boolean = false;
  public showRepButton: Boolean = true;
  public showMessage: Boolean = false;
  private subscriptions: Subscription[] = [];
  public isProcessing: boolean = false; // toggle true/false based on api response
  public showInviteMemberButton: Boolean = true;
  public canEditGroupMembers: boolean;
  public canInviteDeleteMembers: boolean;
  public groupMembersForm = new FormGroup({
    paWillSignWebform: new FormControl<boolean | null>(null, {
      validators: [Validators.required],
    }),
  });

  public groupMembers: IGroupMember[] = [];
  public principalApplicant: IPrincipalApplicant;
  public principalApplicantsRep: IPrincipalApplicantsRep;
  public memberDocs: RelatedDocumentType[] = [];
  private currentCaseLobId: number | null;
  public submitting = false;
  public canResendPassword = false;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private translateService: TranslateService,
    private router: Router,
    public user: UserService,
    private activeRoute: ActivatedRoute,
    private caseService: CaseService,
    private alertService: AlertService,
    private translate: TranslateService,
    private garService: GarService
  ) {}

  ngOnInit() {
    this.canActivate();
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(filter((caseData) => !!caseData))
        .subscribe((caseData: Case) => {
          this.memberDocs = caseData.lob?.relatedDocumentTypes?.filter(
            (doc: RelatedDocumentType) => doc.isMemberDocument
          );
          this.groupMembers = caseData.members
            .filter(
              (member) =>
                member.roleId === RoleIds.PRIMARY_SPONSOR ||
                member.roleId === RoleIds.SPONSOR_MEMBER ||
                member.roleId === RoleIds.PRIMARY_CONTACT
            )
            .sort((a) =>
              a.roleId === RoleIds.PRIMARY_SPONSOR ||
              a.roleId === RoleIds.PRIMARY_CONTACT
                ? -1
                : 0
            );
          this.principalApplicantsRep = caseData.members.find(
            (member) =>
              member.roleId === RoleIds.PAID_REFUGEE_REP ||
              member.roleId === RoleIds.UNPAID_REFUGEE_REP
          ) as IPrincipalApplicantsRep;
          this.principalApplicant = caseData.members.find(
            (member) => member.roleId === RoleIds.REFUGEE
          ) as IPrincipalApplicant;
          if (
            this.principalApplicantsRep &&
            this.principalApplicantsRep.familyName !== ""
          ) {
            this.togglePrincipalApplicantsRepButton(false);
          }

          const { paWillSignWebform } = this.groupMembersForm.controls;
          if (paWillSignWebform.value !== caseData.paWillSignWebform) {
            paWillSignWebform.setValue(caseData.paWillSignWebform);
          }
          this.currentCaseLobId = caseData.lobId;
          this.caseId = caseData.id;
          if (this.user.can("group-members:write")) {
            this.canEditGroupMembers = true;
          } else {
            this.groupMembersForm.controls.paWillSignWebform.removeValidators([
              Validators.required,
            ]);
            this.groupMembersForm.updateValueAndValidity();
          }
          if (
            Number(caseData.status?.id) === caseStatuses.intake.incomplete.id
          ) {
            this.canInviteDeleteMembers = false;
          } else {
            this.canInviteDeleteMembers = true;
          }
        })
    );
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.watchInvitePrincipalApplicant();
  }

  canActivate(): any {
    console.log("canActivate");
    if (
      !this.user.can("group-members:write") &&
      !this.user.can("member:write")
    ) {
      this.router.navigate(["."], { relativeTo: this.activeRoute.parent });
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  /*Removing Group member card*/
  removeGroupMember(caseMember: any) {
    this.groupMembers = this.groupMembers.filter(
      (mem) => mem.caseMemberId != caseMember
    );
  }

  validateForm() {
    if (!this.canEditGroupMembers) {
      this.router.navigate([`../`], { relativeTo: this.activeRoute });
    }
    if (this.groupMembersForm.invalid) {
      this.groupMembersForm.markAllAsTouched();
      return;
    }
    this.router.navigate([`../`], { relativeTo: this.activeRoute });
  }

  async manageGroupMembersSubmit() {
    console.log(
      "manageGroupMembersSubmit this.groupMembersForm.invalid",
      this.groupMembersForm.invalid
    );
    if (!this.canEditGroupMembers) {
      return;
    }
    if (this.groupMembersForm.invalid) {
      this.groupMembersForm.markAllAsTouched();
      return;
    }
    this.submitting = true;
    try {
      console.log(
        "this.groupMembersForm.getRawValue()",
        this.groupMembersForm.getRawValue()
      );
      await this.caseService.updateCase(this.groupMembersForm.getRawValue());
    } catch (error) {
      this.alertService.danger(
        this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
      );
      this.submitting = false;
      return;
    }
    this.submitting = false;
  }

  watchInvitePrincipalApplicant() {
    this.subscriptions.push(
      this.groupMembersForm.valueChanges
        .pipe(
          takeWhile(() => !this.principalApplicant),
          filter((value) => value.paWillSignWebform !== null)
        )
        .subscribe(async (value) => {
          this.enablePaInvite = !!value;
          await this.manageGroupMembersSubmit();
        })
    );
  }

  togglePrincipalApplicantsRepButton(showButton: Boolean) {
    this.showRepButton = showButton;
  }

  public get isG5Warning(): boolean {
    return (
      this.currentCaseLobId !== null &&
      this.currentCaseLobId === 46 &&
      this.groupMembers &&
      this.groupMembers.length < 5
    );
  }
  public get isGAR() {
    return this.garService.isGAR(this.currentCaseLobId);
  }

  public get signatureBannerConfig(): IBannerConfig {
    return {
      id: "signatureBanner",
      content: this.translateService.instant(
        this.isGAR
          ? "PSR.INVITE_GROUP_MEMBER_PAGE.PRINCIPAL_APPLICANT_SIGN_OWN_WARNING_AS_GAR"
          : "PSR.INVITE_GROUP_MEMBER_PAGE.PRINCIPAL_APPLICANT_SIGN_OWN_WARNING"
      ),
      rounded: true,
      type: "info",
    };
  }

  public get paSignatureBannerConfig(): IBannerConfig {
    return {
      id: "paSignatureBanner",
      content: this.translateService.instant(
        "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_BUTTON_YES_WARNING"
      ),
      rounded: true,
      type: "warning",
    };
  }

  public get bannerConfig(): IBannerConfig {
    return {
      id: "g5banner",
      content: this.translateService.instant(
        "PSR.INVITE_GROUP_MEMBER_PAGE.G5_WARNING_BODY"
      ),
      rounded: true,
      type: "warning",
    };
  }
}
