import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as CasesCountActions from "../../../store/CasesCount/CasesCount.actions";
import {
  AlertService,
  FormSelectOption,
  casePhases,
  DOC_LIMITS,
  ValidationService,
} from "lib";
import { Case, NewCase } from "../../../shared/case-module/case.model";
import { CaseService } from "../../../shared/case-module/case.service";
import { Observable, Subscription, timer } from "rxjs";
import { distinctUntilChanged, filter, first, map, tap } from "rxjs/operators";
import { LobService } from "../../../../../../lib/src/lib/services/lob/lob.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { valueTextHiddenFormatter } from "@pr-applicant/app/shared/helpers/valueTextHiddenFormatter";
import { GarService } from "@pr-applicant/app/shared/services/gar/gar.service";
import { IBannerConfig } from "ircc-ds-angular-component-library";
import {
  PsrService,
  INewMemberForm,
  INewMember,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { LobIds } from "lib";
import { LovService } from "lib";
import { RepService } from "@pr-applicant/app/shared/services/rep/rep.service";
import { lovModel } from "projects/lib/src/public-api";
@Component({
  selector: "pra-program-selection-form",
  templateUrl: "./program-selection-form.component.html",
  styleUrls: ["./program-selection-form.component.scss"],
})
export class ProgramSelectionFormComponent implements OnInit, OnDestroy {
  public isClient: boolean;
  public isRep: boolean;
  public handledByRep: boolean;
  public canResetPassword: boolean = false;

  public programs: FormSelectOption[];
  public categories: FormSelectOption[];
  public subcategories: FormSelectOption[];
  public applicationName: string;
  public caseId: number;
  public isEditing: boolean = false;
  public isSubmitting: boolean = false;
  public primaryContactForm: FormGroup<INewMemberForm>;
  public referralPartners: FormSelectOption[];
  public case: Case;
  private intakeCasesTotal: number;
  private subscriptions: Subscription[] = [];
  public lobIds = LobIds;

  public programSelectionForm = this.fb.group({
    program: [null, Validators.required],
    category: [null, Validators.required],
    subcategory: [null],
    applicationName: [null],
  });

  public clientEditForm = this.fb.group({
    clientEmail: [
      null,
      [
        Validators.required,
        this.validator.validatorEmail,
        Validators.maxLength(100),
      ],
    ],
    clientEmailVerified: [null, Validators.required],
  });
  public sponsorProfileForm = this.fb.group({
    familyName: [null, [Validators.required]],
    givenName: [null],
  });
  public garReferralForm = this.fb.group({
    givenName: [null],
    familyName: [null],
    isReferralPartner: [null, [Validators.required]],
    referralOrganization: [null],
  });

  private caseLob: any;
  private userEmail: string;
  public clientGivenName: string;
  public clientFamilyName: string;
  public clientEmail: string;
  public verifyEmailErrors: ValidationErrors | null;
  public isDisabled: boolean = false;
  public countDown: Subscription;
  public counter = 0;
  public tick = 1000;
  public emailSent: boolean = false;
  public isLoading: boolean = true;
  public primarySponsorStepper: boolean = false;
  public formState: string = "new";
  public stepperInformationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };
  public hasMemberInfo: any;

  @Output() caseLobUpdated: EventEmitter<boolean> = new EventEmitter();
  @Output() hideIntake = new EventEmitter<boolean>();

  constructor(
    private alertService: AlertService,
    private caseService: CaseService,
    private fb: UntypedFormBuilder,
    private lobService: LobService,
    private routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private awsService: AWSService,
    private user: UserService,
    private validator: ValidationService,
    private psrService: PsrService,
    private garService: GarService,
    public lovService: LovService,
    private repService: RepService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isClient = this.user.isClientAccount();
    this.isRep = this.user.isRepAccount();
    if (!this.isClient) {
      this.clientEditForm.setValidators(this.validatorEmailMatching.bind(this));
      this.clientEditForm.updateValueAndValidity();
      this.setVerifyEmailErrors();
    }
    await this.initializeComponent();
    this.subscriptions.push(
      this.garReferralForm.controls.isReferralPartner.valueChanges.subscribe(
        (isReferralPartner) => {
          if (isReferralPartner) {
            this.garReferralForm.controls.familyName.setValidators(
              Validators.required
            );
            this.garReferralForm.controls.referralOrganization.setValidators(
              Validators.required
            );
          } else {
            this.garReferralForm.controls.familyName.setValidators(null);
            this.garReferralForm.controls.referralOrganization.setValidators(
              null
            );
            if (!this.hasMemberInfo) {
              this.garReferralForm.controls.givenName.reset();
              this.garReferralForm.controls.familyName.reset();
            }
            this.garReferralForm.controls.referralOrganization.reset();
          }
          this.garReferralForm.updateValueAndValidity();
        }
      )
    );
    this.getMemberInfo();
  }

  private async initializeComponent() {
    this.isLoading = true;
    await this.lobService.updateLobs();
    this.setUserEmail();
    this.getStoredCase();
    this.programs = await this.lobService.fetchPrograms(1);
    await this.manageCategoryOptions();
    this.watchProgramAndCategory();
    this.subscriptions.push(
      this.store.select("casesCount").subscribe((totalCasesData) => {
        this.intakeCasesTotal = totalCasesData.intake;
      })
    );
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /* PUBLIC ------------------------------------------------------------------------- */

  public get isFormInvalid(): boolean {
    if (this.isClient) {
      if (this.isGAR) {
        return (
          this.programSelectionForm.invalid || this.garReferralForm.invalid
        );
      }
      return this.programSelectionForm.invalid;
    } else if (this.isRep && this.isPSR && this.primaryContactForm) {
      return (
        this.programSelectionForm.invalid || this.primaryContactForm.invalid
      );
    } else {
      return this.programSelectionForm.invalid || this.clientEditForm.invalid;
    }
  }

  public get isClientEmailFormValid(): boolean {
    return this.clientEditForm.invalid;
  }

  public getMemberInfo(): any {
    if (this.isClient) {
      return this.repService.getAccountDetails().subscribe((res) => {
        if (Object.keys(res).length > 0) {
          this.sponsorProfileForm.controls.givenName.setValue(res.givenName);
          this.sponsorProfileForm.controls.givenName.disable();
          this.sponsorProfileForm.controls.familyName.setValue(res.familyName);
          this.sponsorProfileForm.controls.familyName.disable();
          this.garReferralForm.controls.givenName.setValue(res.givenName);
          this.garReferralForm.controls.givenName.disable();
          this.garReferralForm.controls.familyName.setValue(res.familyName);
          this.garReferralForm.controls.familyName.disable();
          this.hasMemberInfo = true;
        } else {
          this.hasMemberInfo = false;
        }
      });
    }
  }

  public get program(): string | null {
    if (this.caseLob) {
      return this.lang === "fr"
        ? this.caseLob.programFr
        : this.caseLob.programEn;
    } else {
      return null;
    }
  }

  public get category(): string | null {
    if (this.caseLob.categoryId) {
      const fetchedCategory = this.lobService.fetchCategoryViaSubcategory(
        +this.caseLob.categoryId
      );
      return this.lang === "fr"
        ? fetchedCategory.categoryFr
        : fetchedCategory.categoryEn;
    } else if (this.caseLob) {
      return this.lang === "fr"
        ? this.caseLob.categoryFr
        : this.caseLob.categoryEn;
    } else {
      return null;
    }
  }

  public get subcategory(): string | null {
    if (this.caseLob.categoryId) {
      if ([41, 43].includes(this.caseLob.id)) {
        return this.translate.instant("INTAKE.IN_CANADA_CATEGORY");
      }
      if ([42, 44].includes(this.caseLob.id)) {
        return this.translate.instant("INTAKE.OUTSIDE_CANADA_CATEGORY");
      }
      return this.lang === "fr"
        ? this.caseLob.categoryFr
        : this.caseLob.categoryEn;
    } else {
      return null;
    }
  }

  public get showSubcategoryDropdown(): boolean {
    return this.caseLob.categoryId ? true : false;
  }

  public get isPSR(): boolean {
    return this.psrService.isPSR(this.newCase.lobId);
  }

  public get isGAR(): boolean {
    return this.garService.isGAR(this.getLobID());
  }

  public get sortReferralOrganization(): lovModel[] {
    const referralPartnerList = this.lovService.lovs.referralPartnerList;
    if (this.lang === "fr") {
      return referralPartnerList.sort(function (a: lovModel, b: lovModel) {
        let textA = a.text.fr.toUpperCase();
        let textB = b.text.fr.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      return referralPartnerList.sort(function (a: lovModel, b: lovModel) {
        let textA = a.text.en.toUpperCase();
        let textB = b.text.en.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }

  public get garBannerConfig(): IBannerConfig {
    return {
      id: "gar-warning",
      content: this.translate.instant("GAR.REFERRAL_PARTNER_FORM.WARNING"),
      rounded: true,
      type: "info",
    };
  }

  public get garNoReferralPartnerBannerConfig(): IBannerConfig {
    return {
      id: "gar-no-referral-partner-warning",
      content: this.translate.instant(
        "GAR.REFERRAL_PARTNER_FORM.NO_REFERRAL_PARTNER_WARNING"
      ),
      rounded: true,
      type: "info",
    };
  }

  public get isCurrentCasePSR(): boolean {
    return this.psrService.isPSR(Number(this.case.lobId));
  }

  public get viewOnly(): boolean | null {
    //  if isClient & has rep
    return this.isClient && this.case.representativeId ? true : false;
  }

  /** When creating a new case, navigate to the new route and
   *  display the success message, otherwise, through the technical erro
   * message
   */
  public async onSubmit() {
    this.isSubmitting = true;

    if (this.programSelectionForm.controls.applicationName.value === "") {
      this.programSelectionForm.controls.applicationName.setValue(null);
    }

    if (
      this.intakeCasesTotal >=
      (this.isClient
        ? DOC_LIMITS.INTAKE_APPLICATIONS
        : DOC_LIMITS.INTAKE_REP_APPLICATIONS)
    ) {
      this.alertService.danger(this.alertMaxApplicationLimit);
      return;
    }
    const identityId = await this.awsService.getIdentityId();
    this.caseService
      .createCase(this.newCase, identityId)
      .then(async (res: any) => {
        this.alertService.success(this.alertSuccessfulSubmit);
        this.updateStoreCasesCount();
        const newNestedPath = this.routeLocalizer.getNestedPath(this.lang, [
          "INTAKE",
          "PROFILE",
        ]);
        this.router.navigate([`/${this.lang}/${newNestedPath}${res.id}`]);
        this.isSubmitting = false;
      })
      .catch(() => {
        this.alertService.danger(this.alertTechnicalError);
        this.isSubmitting = false;
      });
  }
  public toggleEditing(): void {
    this.setEmailValue();
    this.isEditing = !this.isEditing;
  }

  public stopTimer(): void {
    this.countDown.unsubscribe();
  }

  public cancelUpdate(): void {
    this.isEditing = !this.isEditing;
  }

  public clearTimer(caseId: number): void {
    if (!caseId) {
      return;
    }
    const disabledLocalStorage = localStorage.getItem("disabledTempPassCases");
    const disabledApplications = disabledLocalStorage
      ? JSON.parse(disabledLocalStorage)
      : [];
    const filteredDisabledApplications = disabledApplications.filter(
      (app: any) => app.caseID !== caseId
    );
    localStorage.setItem(
      "disabledTempPassCases",
      JSON.stringify(filteredDisabledApplications)
    );
    this.clientEditForm.markAsPristine();
    this.canResetPassword = true;
  }
  public showPrimarySponsorStepper(value: boolean) {
    this.primarySponsorStepper = value;
    this.hideIntake.emit(true);
  }

  public checkTimeout(): void {
    const currentTime = new Date();
    //if nothing in localstorage just return //
    if (!(localStorage.getItem("disabledTempPassCases") || "")) {
      this.canResetPassword = true;
      return;
    }
    let resendDisabledApplications = JSON.parse(
      localStorage.getItem("disabledTempPassCases") || ""
    );
    //if a timed out case exists
    if (
      resendDisabledApplications.length !== 0 &&
      Array.isArray(resendDisabledApplications)
    ) {
      const disabledCasePassword = resendDisabledApplications.filter(
        (application: any) => application.caseID === this.caseId
      );
      if (disabledCasePassword.length !== 0) {
        //if case expiry time already added check its time and compare to current time
        const timeoutDate = new Date(disabledCasePassword[0].timeout).getTime();
        //timeouttime + 5 mins = expiry time
        const expiryTime = new Date(timeoutDate + 300000);
        //calculate difference between expiry time & current time
        let diffInMilliSeconds =
          Math.abs(currentTime.valueOf() - expiryTime.valueOf()) / 1000;
        //if current time - expiry time differnce < 5 mins start timer of difference
        const differenceInMinutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= differenceInMinutes * 60;
        if (
          differenceInMinutes < 5 &&
          expiryTime > new Date(disabledCasePassword[0].timeout)
        ) {
          this.canResetPassword = false;
          this.emailSent = true;
          //start the timer
          this.counter = Math.floor(
            Math.abs(currentTime.valueOf() - expiryTime.valueOf()) / 1000
          );
          if (this.countDown && !this.countDown.closed) {
            this.countDown.unsubscribe();
          }
          this.countDown = timer(0, this.tick).subscribe(() => {
            if (this.counter === 0) {
              this.canResetPassword = true;
              this.emailSent = false;
              const removeExpiredCase = resendDisabledApplications.filter(
                (application: any) => application.caseID !== this.caseId
              );
              //remove from the list & add list back to the localstorage
              localStorage.setItem(
                "disabledTempPassCases",
                JSON.stringify(removeExpiredCase)
              );
              this.stopTimer();
            } else {
              --this.counter;
            }
          });
        } else {
          //if greater then 5 mins remove from the storage
          const removeExpiredCase = resendDisabledApplications.filter(
            (application: any) => application.caseID !== this.caseId
          );
          //remove from the list & add list back to the localstorage
          localStorage.setItem(
            "disabledTempPassCases",
            JSON.stringify(removeExpiredCase)
          );
          this.canResetPassword = true;
        }
      }
    }
  }
  public resendPassword(): void {
    this.sendEmail()
      .then(() => {
        this.emailSent = true;
        const currentTime = new Date();
        const timeoutObj = { caseID: this.caseId, timeout: currentTime };
        let resendDisabledApplications = JSON.parse(
          localStorage.getItem("disabledTempPassCases") || "[]"
        );
        this.canResetPassword = false;
        resendDisabledApplications.push(timeoutObj);

        localStorage.setItem(
          "disabledTempPassCases",
          JSON.stringify(resendDisabledApplications)
        );
        this.counter = 300; //5 mins in seconds

        this.countDown = timer(0, this.tick).subscribe(() => {
          if (this.counter === 0) {
            this.canResetPassword = true;
            this.emailSent = false;
            //find the case in local storage & removed it
            const removeExpiredCase = resendDisabledApplications.filter(
              (application: any) => application.caseID !== this.caseId
            );
            //remove from the list & add list back to the localstorage
            localStorage.setItem(
              "disabledTempPassCases",
              JSON.stringify(removeExpiredCase)
            );
            this.stopTimer();
          } else {
            --this.counter;
          }
        });
      })
      .catch((error) => {
        this.canResetPassword = true;
        this.emailSent = false;
      });
  }

  public async sendEmail(): Promise<any> {
    try {
      await this.authService.resendClientPassword(this.case.id);
    } catch (error) {
      console.error(error);
    }
  }
  /*This function only be called if the user is a representative*/
  public updateCase(): void {
    if (!this.clientEditForm.pristine && this.clientEditForm.valid) {
      this.clearTimer(this.caseId);
      this.clientEditForm.markAsPristine();
    }
    if (!this.isClient) {
      this.isLoading = true;
      this.caseService
        .updateCase({
          emailAddress: (
            (this.clientEditForm.controls.clientEmail.value || "") as string
          ).toLowerCase(),
        })
        .then(
          (res) => {
            this.alertService.success(this.alertSuccessfulUpdate);
          },
          () => {
            this.alertService.danger(this.alertTechnicalError);
          }
        );
    }

    this.isEditing = false;
    this.isLoading = false;
  }

  /* PRIVATE ------------------------------------------------------------------------- */

  private get alertTechnicalError(): string {
    return this.translate.instant(
      "INTAKE.PROGRAM_SELECTION_FORM.ALERTS.TECHNICAL_ERROR"
    );
  }

  private get alertMaxApplicationLimit(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }

  private get alertSuccessfulSubmit(): string {
    return this.translate.instant(
      "INTAKE.PROGRAM_SELECTION_FORM.ALERTS.CREATE_SUCCESS"
    );
  }

  private get alertSuccessfulUpdate(): string {
    return this.translate.instant(
      "INTAKE.PROGRAM_SELECTION_FORM.ALERTS.UPDATE_SUCCESS"
    );
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }
  private getLobID(): number {
    if (this.programSelectionForm.controls.subcategory.value) {
      return +this.programSelectionForm.controls.subcategory.value;
    } else {
      return this.programSelectionForm.controls.category.value;
    }
  }

  public get newCase(): NewCase {
    if (this.isClient) {
      let sponsorProfileForm = this.sponsorProfileForm?.getRawValue();
      let garReferralFormValues = { ...this.garReferralForm.getRawValue() };
      delete garReferralFormValues.isReferralPartner;
      return {
        emailAddress: this.userEmail,
        lobId: this.getLobID(),
        applicationName:
          this.programSelectionForm.controls.applicationName.value,
        newMember:
          this.primarySponsorStepper && sponsorProfileForm
            ? sponsorProfileForm
            : null,
        referralPartner:
          this.isGAR && this.garReferralForm.controls.isReferralPartner.value
            ? garReferralFormValues
            : null,
      };
    } else {
      let sponsorProfileForm = this.primaryContactForm?.value;
      delete sponsorProfileForm?.emailConfirm;
      delete sponsorProfileForm?.isPrimaryContact;
      return {
        emailAddress: (
          (this.clientEditForm.controls.clientEmail.value ||
            this.primaryContactForm?.controls.emailAddress.value ||
            "") as string
        ).toLowerCase(),
        lobId: this.getLobID(),
        applicationName:
          this.programSelectionForm.controls.applicationName.value,
        newMember:
          this.primaryContactForm && (sponsorProfileForm as INewMember),
      };
    }
  }
  public setPrimaryContactForm(form: FormGroup) {
    this.primaryContactForm = form;
  }
  private isClientUserConfirmed(): boolean {
    return this.case && (this.case as any).userStatus === "CONFIRMED";
  }

  public validatorEmailMatching(fGroup: FormGroup): ValidationErrors | null {
    if (fGroup.value.clientEmail !== null) {
      if (
        this.userEmail &&
        fGroup.value.clientEmail.trim().toLowerCase() ===
          this.userEmail.toLowerCase()
      ) {
        fGroup.controls.clientEmail.setErrors({
          clientEmailMatchRepEmail: true,
        });
        return { clientEmailMatchRepEmail: true };
      }
      if (fGroup.value.clientEmail === fGroup.value.clientEmailVerified) {
        fGroup.controls.clientEmailVerified.setErrors(null);
        return null;
      } else {
        fGroup.controls.clientEmailVerified.setErrors({ matches: true });
        return { matches: true };
      }
    } else {
      return { matches: true };
    }
  }

  //TO DO: Refactor once instruction is given on edit functionality
  private getStoredCase(): void {
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(
          // eliminates initial state and any confirmation cases that could be in store
          tap((caseData) => {
            this.formState =
              caseData.id !== "" && caseData.phaseId === casePhases.intake.id
                ? "intake"
                : "new";
          }),
          filter(
            (caseData: any) =>
              caseData.id !== "" && caseData.phaseId === casePhases.intake.id
          ),
          map((caseData: any) => {
            this.caseLob = caseData.lob;
            this.applicationName = caseData.applicationName;
            this.caseId = caseData.id;
            this.clientEmail = (
              (caseData.emailAddress || "") as string
            ).toLowerCase();
            this.clientFamilyName = caseData.surname;
            this.clientGivenName = caseData.givenName;
            this.handledByRep = caseData?.representative ? true : false;
            this.canResetPassword =
              caseData?.userStatus === "FORCE_CHANGE_PASSWORD" ? true : false;
            this.case = caseData;
            if (this.isClientUserConfirmed()) {
              this.emailSent = false;
              this.canResetPassword = false;
            } else {
              this.checkTimeout();
            }
            return caseData;
          })
        )
        .subscribe()
    );
  }

  //  category dropdown options dependant on program selected
  private async manageCategoryOptions(): Promise<void> {
    this.subscriptions.push(
      this.programSelectionForm.controls.program.valueChanges.subscribe(
        async (id) => {
          if (id) {
            this.programSelectionForm.controls.category.setValue(null);
            this.categories = await this.formatCategory(+id);
            if (this.isRep) {
              this.categories = this.categories.filter(
                (category) =>
                  category.value !== LobIds.GovernmentAssistedRefugeesProgram
              );
            }
          }
        }
      )
    );
  }

  private async formatCategory(programId: number) {
    const fetched = await this.lobService.fetchCategoriesByProgram(programId);
    if (fetched.length > 0) {
      return fetched
        .filter((lob) => lob.isHidden === false)
        .map((lob) => {
          return valueTextHiddenFormatter(lob);
        });
    } else {
      return [];
    }
  }

  private formatSubcategory(categoryId: number) {
    if (categoryId) {
      const filteredSubcategories =
        this.lobService.fetchSubcategory(categoryId);
      return filteredSubcategories
        .filter((lob) => lob.isHidden === false)
        .map((lob) => {
          return valueTextHiddenFormatter(lob);
        });
    } else {
      return [];
    }
  }

  private setEmailValue(): void {
    if (!this.isClient) {
      this.clientEditForm.controls.clientEmail.setValue(this.clientEmail);
      this.clientEditForm.controls.clientEmailVerified.setValue(
        this.clientEmail
      );
    }
  }

  private setUserEmail(): void {
    this.store
      .select("auth")
      .pipe(
        first(),
        map((authState: any) => authState.user)
      )
      .subscribe((user: any) => {
        if (user) {
          this.userEmail = user.username;
        }
      });
  }

  // Increase the number of intake cases in the store
  private updateStoreCasesCount(): void {
    let currentIntakeCasesCount;
    this.store
      .select("casesCount")
      .pipe(first())
      .subscribe((totalCasesData) => {
        currentIntakeCasesCount = totalCasesData.intake;
      });
    const newCount = Number(currentIntakeCasesCount) + 1;
    this.store.dispatch(new CasesCountActions.SetIntakeCasesCount(newCount));
  }

  private watchProgramAndCategory(): void {
    this.subscriptions.push(
      this.programSelectionForm.controls.category.valueChanges.subscribe(
        (categoryId) => {
          const filteredSubcategories = this.formatSubcategory(categoryId);
          if (filteredSubcategories.length > 0) {
            this.programSelectionForm.controls["subcategory"].setValidators([
              Validators.required,
            ]);
            this.programSelectionForm.controls.subcategory.setValue(null);
            this.subcategories = filteredSubcategories;
          } else {
            this.programSelectionForm.controls["subcategory"].removeValidators([
              Validators.required,
            ]);
            this.programSelectionForm.controls.subcategory.setValue(null);
          }
        }
      )
    );
  }

  private setVerifyEmailErrors() {
    const verifyEmailErrors$ = this.clientEditForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((v) => {
        if (
          this.clientEditForm.controls.clientEmail.touched &&
          this.clientEditForm.controls.clientEmailVerified.touched
        ) {
          if (v.clientEmail !== v.clientEmailVerified) {
            this.verifyEmailErrors = { matches: true };
          } else {
            this.verifyEmailErrors = null;
          }
        }
      });
    this.subscriptions.push(verifyEmailErrors$);
  }
}
