import { Component, OnDestroy, OnInit, AfterViewInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-h",
  templateUrl: "./imm5669-section-h.component.html",
  styleUrls: ["./imm5669-section-h.component.scss"],
})
export class IMM5669SectionHComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public formMaxLength: { [key: string]: number } = {
    from: 7,
    to: 7,
    street: 100,
    city: 14,
    provinceOrState: 30,
    country: 14,
    postalCode: 9,
  };

  addresses: UntypedFormArray = new UntypedFormArray([]);
  sectionHData: any;
  subscription$: Subscription;

  private buttonClicked: boolean = false;
  private isPrimaryApplicant: boolean;
  public isReadOnly = !this.user.can("documents:write");

  constructor(
    public fb: UntypedFormBuilder,
    public alertservice: AlertService,
    public translate: TranslateService,
    private store: Store<fromApp.State>,
    private validationService: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.subscription$ = this.store
      .select("currentIMMDocument")
      .pipe(distinctUntilChanged())
      .subscribe((formData) => {
        this.sectionHData = formData.currentForm?.form?.sectionH;
        this.isPrimaryApplicant =
          formData.currentForm?.form?.sectionA?.principalApplicant;
        if (this.sectionHData.addresses.length > 0) {
          this.fillFormWithSavedValues();
        } else {
          this.addresses.push(this.createNewAddress());
        }
      });
  }

  ngAfterViewInit(): void {
    this.disableForAutoComplete();
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  // public

  public get isFormValid(): boolean {
    return this.addresses.valid;
  }

  public get alertMaxSiblingLimit(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  public addAddress(): void {
    if (this.addresses.length < DOC_LIMITS.IMM5669.ADDRESSES_LIMIT) {
      this.buttonClicked = true;
      this.addresses.push(this.createNewAddress());
    } else {
      this.alertservice.danger(this.alertMaxSiblingLimit);
    }
  }

  public removeAddress(index: number): void {
    this.buttonClicked = true;
    this.addresses.removeAt(index);
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.addresses.touched || this.buttonClicked;
  }

  public getFormData(): any {
    if (
      (this.addresses.controls as FormControl[]).find(
        (fc) => fc.invalid && fc.touched
      )
    ) {
      return { sectionName: "sectionH", isInvalid: true };
    }
    const formValue = this.addresses.getRawValue();
    return {
      sectionName: "sectionH",
      sectionInfo: { ...this.sectionHData, addresses: formValue },
    };
  }

  // private

  private createNewAddress(): FormGroup {
    const form = this.fb.group({
      from: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.from)],
        },
      ],
      to: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.to)],
        },
      ],
      street: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.street)],
        },
      ],
      city: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.city)],
        },
      ],
      provinceOrState: [
        null,
        {
          validators: [
            Validators.maxLength(this.formMaxLength.provinceOrState),
          ],
        },
      ],
      country: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.country)],
        },
      ],
      postalCode: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.postalCode)],
        },
      ],
    });
    return form;
  }

  private fillFormWithSavedValues(): void {
    this.addresses.clear();
    const savedAddresses = this.sectionHData?.addresses;
    savedAddresses?.forEach((address: any) => {
      const addressField: FormGroup = this.createNewAddress();
      addressField?.setValue({
        from: address.from,
        to: address.to,
        street: address.street,
        city: address.city,
        provinceOrState: address.provinceOrState,
        country: address.country,
        postalCode: address.postalCode,
      });
      this.addresses.push(addressField);
    });
  }
  private disableForAutoComplete() {
    if (this.isPrimaryApplicant) {
      this.addresses.controls[0].disable();
      this.addresses.controls[0].get("from")?.enable();
    }
  }
}
