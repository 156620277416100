import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  Input,
  EventEmitter,
} from "@angular/core";
import {
  AlertService,
  ModalService,
  documentSizes,
  documentTypes,
  uploadFileTypes,
  caseStatuses,
} from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { DownloadService } from "../../../shared/services/download.service";
import { RepService } from "../../../shared/services/rep/rep.service";
import { RepAccountInfo } from "../../../core/models/rep-account-info";
import { TranslateService } from "@ngx-translate/core";
import { Document } from "../../../core/models/document.model";
import { Subject, Subscription } from "rxjs";
import { first } from "rxjs/operators";

@Component({
  selector: "pra-rep-identification-upload-table",
  templateUrl: "./rep-identification-upload-table.component.html",
  styleUrls: ["./rep-identification-upload-table.component.scss"],
})
export class RepIdentificationUploadTableComponent
  implements OnInit, OnDestroy
{
  // file size and type
  private maxFileSize = documentSizes.forms.maxFileSize;
  private megaFileConversion = documentSizes.forms.megaFileConversion;
  private subs: Subscription[] = [];

  public fileSelected: any;
  public acceptedFileTypes = uploadFileTypes.proofOfPayment.types;
  public updatedDocLink = "";
  public fileName = "";

  @Input() accountInfo: Subject<RepAccountInfo>;
  //  emit up to rep account details component to re-check if application is ready to be submitted
  @Output() proofOfIdentificationUploaded: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(
    private alertService: AlertService,
    private downloadService: DownloadService,
    private translate: TranslateService,
    public repService: RepService
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.accountInfo.subscribe((repInfo) => {
        this.updatedDocLink = repInfo.documentLink;
        this.updateFileName();
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public downloadFile(docLink: any) {
    const fileName = docLink;
    const downloadName = docLink.split("/").pop(); // the last string is the filename (incase its in a folder)
    this.downloadService.downloadFile(fileName, downloadName);
  }

  public isFileValid(file: File): boolean {
    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;

    const fileSizeValid = file && file.size <= this.maxFileSize;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);

    // if invalid, show errors & return false
    if (!fileSizeValid && fileTypeValid) {
      //  file too big
      this.alertService.warning(this.alertUploadSize);
      return false;
    } else if (fileSizeValid && !fileTypeValid) {
      //  file wrong type
      this.alertService.warning(this.alertFileType);
      return false;
    } else if (!fileSizeValid && !fileTypeValid) {
      //  file too big and wrong type
      this.alertService.warning(this.alertFileTypeAndSize);
      return false;
    }

    return true;
  }

  public uploadFile(e: { target?: HTMLInputElement }): void {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const fileValid = this.isFileValid(file);

    if (fileValid) {
      this.fileSelected = file;
      this.repService
        .uploadIdentification(this.fileSelected)
        .pipe(first())
        .subscribe(
          (docLink: string) => {
            this.updatedDocLink = docLink;
            this.updateFileName();
            this.proofOfIdentificationUploaded.emit(docLink);
          },
          (err) => {
            this.alertService.danger(
              this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
            );
          }
        );
    }
  }

  private getFileName(docLink: string): string {
    return docLink.substring(docLink.lastIndexOf("/") + 1);
  }

  private updateFileName(): void {
    if (this.updatedDocLink) {
      this.fileName = this.getFileName(this.updatedDocLink);
    } else {
      this.fileName = "";
    }
  }

  private get friendlyMaxFileSize(): string {
    return `${this.maxFileSize / this.megaFileConversion}`;
  }

  private get alertUploadSize(): string {
    return this.translate.instant("INTAKE.PROOF_OF_PAYMENT.UPLOAD_SIZE", {
      size: this.friendlyMaxFileSize,
    });
  }

  private get alertFileType(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_TYPE");
  }

  private get alertFileTypeAndSize(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_TYPE_AND_SIZE", {
      size: this.friendlyMaxFileSize,
    });
  }
}
