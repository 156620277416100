<h2 class="emphasis">{{ "REP_ACCOUNT_DETAIL.FORM.TITLE" | translate }}</h2>
<form [formGroup]="repInfoForm">
  <ng-container accordionBody>
    <fieldset>
      <lib-form-input
        type="text"
        identifier="givenName"
        [formControl]="repInfoForm.controls.givenName"
        [label]="'REP_ACCOUNT_DETAIL.FORM.GIVEN_NAME' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('givenName')?.touched &&
            repInfoForm.get('givenName')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('givenName')?.touched &&
            repInfoForm.get('givenName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="familyName"
        [formControl]="repInfoForm.controls.familyName"
        [label]="'REP_ACCOUNT_DETAIL.FORM.FAMILY_NAME' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('familyName')?.touched &&
            repInfoForm.get('familyName')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('familyName')?.touched &&
            repInfoForm.get('familyName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="businessPhone"
        [formControl]="repInfoForm.controls.businessPhoneNumber"
        [label]="'REP_ACCOUNT_DETAIL.FORM.BUSINESS_PHONE' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('businessPhoneNumber')?.touched &&
            repInfoForm.get('businessPhoneNumber')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('businessPhoneNumber')?.touched &&
            repInfoForm.get('businessPhoneNumber')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 34 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="streetNumber"
        [formControl]="repInfoForm.controls.streetNumber"
        [label]="'REP_ACCOUNT_DETAIL.FORM.STREET_NO' | translate"
        [widthSm]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('streetNumber')?.touched &&
            repInfoForm.get('streetNumber')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('streetNumber')?.touched &&
            repInfoForm.get('streetNumber')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 10 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="streetName"
        [formControl]="repInfoForm.controls.streetName"
        [label]="'REP_ACCOUNT_DETAIL.FORM.STREET_NAME' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('streetName')?.touched &&
            repInfoForm.get('streetName')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('streetName')?.touched &&
            repInfoForm.get('streetName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="aptNumber"
        [formControl]="repInfoForm.controls.aptNumber"
        [label]="'REP_ACCOUNT_DETAIL.FORM.APT_NO' | translate"
        [widthSm]="true"
      >
        <span
          *ngIf="
            repInfoForm.get('aptNumber')?.touched &&
            repInfoForm.get('aptNumber')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 10 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="city"
        [formControl]="repInfoForm.controls.city"
        [label]="'REP_ACCOUNT_DETAIL.FORM.CITY' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('city')?.touched &&
            repInfoForm.get('city')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('city')?.touched &&
            repInfoForm.get('city')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 30 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="province"
        [formControl]="repInfoForm.controls.province"
        [label]="'REP_ACCOUNT_DETAIL.FORM.PROVINCE' | translate"
        [widthSm]="true"
      >
        <span
          *ngIf="
            repInfoForm.get('province')?.touched &&
            repInfoForm.get('province')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 40 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="postalCode"
        [formControl]="repInfoForm.controls.postalCode"
        [label]="'REP_ACCOUNT_DETAIL.FORM.POSTAL_CODE' | translate"
        [widthSm]="true"
      >
        <span
          *ngIf="
            repInfoForm.get('postalCode')?.touched &&
            repInfoForm.get('postalCode')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 10 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="country"
        [formControl]="repInfoForm.controls.country"
        [label]="'REP_ACCOUNT_DETAIL.FORM.COUNTRY' | translate"
        [widthMd]="true"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('country')?.touched &&
            repInfoForm.get('country')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('country')?.touched &&
            repInfoForm.get('country')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="membershipId"
        [formControl]="repInfoForm.controls.membershipId"
        [label]="'REP_ACCOUNT_DETAIL.FORM.MEMBER_ID' | translate"
        [widthMd]="true"
        [helpText]="'REP_ACCOUNT_DETAIL.FORM.MEMBER_ID_HELP' | translate"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('membershipId')?.touched &&
            repInfoForm.get('membershipId')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('membershipId')?.touched &&
            repInfoForm.get('membershipId')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 38 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="firmName"
        [formControl]="repInfoForm.controls.firmName"
        [label]="'REP_ACCOUNT_DETAIL.FORM.COMPANY_NAME' | translate"
        [widthMd]="true"
      >
        <span
          *ngIf="
            repInfoForm.get('firmName')?.touched &&
            repInfoForm.get('firmName')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
      <lib-form-input
        type="text"
        identifier="governingBody"
        [formControl]="repInfoForm.controls.governingBody"
        [label]="'REP_ACCOUNT_DETAIL.FORM.GOVERNING_BODY' | translate"
        [widthMd]="true"
        [helpText]="'REP_ACCOUNT_DETAIL.FORM.GOVERNING_BODY_HELP' | translate"
        required="true"
      >
        <span
          *ngIf="
            repInfoForm.get('governingBody')?.touched &&
            repInfoForm.get('governingBody')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            repInfoForm.get('governingBody')?.touched &&
            repInfoForm.get('governingBody')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : { max: 100 } }}
        </span>
      </lib-form-input>
    </fieldset>
  </ng-container>
</form>
