import { Injectable } from "@angular/core";
import Amplify, { Auth } from "aws-amplify";
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { CaseService } from "../../../shared/case-module/case.service";
import { LobService } from "../../../../../../lib/src/lib/services/lob/lob.service";
import { Case } from "../../../shared/case-module/case.model";
import { Lob } from "../../../core/models/lob.model";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import awsconfig from "../../../../../../../config/aws-exports";
import { Store } from "@ngrx/store";
import * as fromApp from "@pr-applicant/app/store/app.reducer";
import * as CasesCountActions from "@pr-applicant/app/store/CasesCount/CasesCount.actions";
import { TranslateService } from "@ngx-translate/core";
import { SponsorProfileService } from "../privately-sponsored-refugees/sponsor-profile/sponsor-profile.service";

Amplify.configure(awsconfig);

@Injectable({
  providedIn: "root",
})
export class DashboardApplicationsPageResolver implements Resolve<boolean> {
  lobs: Lob[] = [];
  lang: string;

  constructor(
    private caseService: CaseService,
    private lobService: LobService,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService,
    private router: Router,
    private translate: TranslateService,
    public sponsorProfileService: SponsorProfileService
  ) {
    this.authService.checkSession();
  }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    this.lang = this.routeLocalizer.getCurrentRouteLang(state.url);
    const user = await Auth.currentAuthenticatedUser();

    this.sponsorProfileService.updateSponsor({
      currentCase: { caseId: null, lobId: null },
    });

    // Get Lobs
    this.lobs = await this.lobService.fetchAllLobs();
    const applicantIntakeCases = await this.fetchAndSetApplicantCases(
      user.attributes.email
    );
    // Map the list of cases coming form the API into table cases
    const tableCases = await this.setTableCases(applicantIntakeCases);
    return tableCases;
  }

  private async fetchAndSetApplicantCases(userEmail: string): Promise<Case[]> {
    try {
      const response = await this.caseService.getCasesByEmail(userEmail);
      this.store.dispatch(
        new CasesCountActions.SetIntakeCasesCount(
          parseInt(response.intakeCount, 10)
        )
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  private async setTableCases(cases: Case[]): Promise<any[]> {
    const allCases = cases || [];
    return allCases.map((data: Case, index: number) => ({
      id: data.id,
      lob: this.getCaseLob(data.phaseId!, data.lobId),
      appName: data.applicationName,
      clientName: data.givenName
        ? data.givenName + " " + data.surname
        : data.surname,
      clientEmail: data.emailAddress,
      submittedAt: data.submittedAt, // will be added later to the case to track submitted date
      ...this.getCaseStatus(data),
      statusId: data.caseStatusId,
      rawAPiResp: data, // this might be eliminated later after one sprint or 2 but would be usefull
      roleId: data.roleId,
      // to have it while we progress
      link: this.getCaseLink(data),
      lobId: data.lobId,
      phaseId: data.phaseId,
      updatedAt: data.updatedAt,
      createdAt: data.createdAt,
      surname: data.surname,
      uci: data.uci,
      givenName: data.givenName,
      applicationNumber: data.applicationNumber,
      dob: data.dob,
      isUrgent: data.isUrgent,
      urgencyRequestDate: data.urgencyRequestDate,
      rejectionReasons: data.rejectionReasons,
      members: data.members,
    }));
  }

  private getCaseLob(phaseId: number, lobId: number | null): any {
    const value = {
      programEn: null,
      categoryEn: null,
      programFr: null,
      categoryFr: null,
    };
    if (phaseId === 2) {
      return {
        programEn: null,
        categoryEn: this.translate.instant(
          "MAIN_LANDING_PAGE.APPLICATION_PROCESS_TILES.TILES.CONFIRMATION.HEADING"
        ),
        programFr: null,
        categoryFr: this.translate.instant(
          "MAIN_LANDING_PAGE.APPLICATION_PROCESS_TILES.TILES.CONFIRMATION.HEADING"
        ),
      };
    }
    if (lobId) {
      const caseLob = this.lobs.find((lob) => lob.id === lobId);
      return caseLob;
    }
    return value;
  }

  getCaseLink(row: any): string {
    // console.log(row);
    if (row.phaseId === 2) {
      return (
        this.routeLocalizer.getNestedPath(
          this.lang,
          ["CONFIRMATION_CASES", "CONFIRMATION_CASE_DETAILS"],
          "../../"
        ) + row.id
      );
    } else if (row.lobId === 29) {
      return (
        this.routeLocalizer.getNestedPath(
          this.lang,
          ["RENEWAL", "RENEWAL_PERMANENT_RESIDENT_CARD"],
          "../../"
        ) + row.id
      );
    } else if (row.lobId === 30) {
      return (
        this.routeLocalizer.getNestedPath(
          this.lang,
          ["RENEWAL", "RENEWAL_TRAVEL_DOCUMENTS"],
          "../../"
        ) + row.id
      );
    } else {
      return (
        this.routeLocalizer.getNestedPath(
          this.lang,
          ["INTAKE", "INTAKE_CASE_DETAILS"],
          "../../"
        ) + row.id
      );
    }
  }

  // Get the case status and then set status pill based on the status type
  private getCaseStatus(data: Case): any {
    switch (String(data.status!.id)) {
      case "1": // CONFIRMATION
        return {
          statusDate: data.createdAt,
          status: {
            type: "initiated",
            label: "CASELIST.TABLE.STATUS_PILL.INITIATED",
          },
        };
      case "2": // CONFIRMATION
        return {
          statusDate: data.createdAt,
          status: {
            type: "review",
            label: "CASELIST.TABLE.STATUS_PILL.REVIEW",
          },
        };
      case "3": // CONFIRMATION
        return {
          statusDate: data.updatedAt,
          status: {
            type: "revision",
            label: "CASELIST.TABLE.STATUS_PILL.REVISION",
          },
        };
      case "4": // CONFIRMATION
        return {
          statusDate: data.updatedAt,
          status: {
            type: "acepted",
            label: "CASELIST.TABLE.STATUS_PILL.CLOSED",
          },
        };
      case "5": // CONFIRMATION
        return {
          statusDate: data.updatedAt,
          status: {
            type: "rejected",
            label: "CASELIST.TABLE.STATUS_PILL.REJECTED",
          },
        };
      case "6": // INTAKE
        return {
          statusDate: data.createdAt,
          status: {
            type: "initiated",
            label: "INTAKE.CASE_STATUS.STATUS_INITIATED",
          },
        };
      case "7": // INTAKE
      case "10": // INTAKE
      case "11": // INTAKE
      case "12": // INTAKE
      case "13": // INTAKE
      case "14": // INTAKE
        return {
          statusDate: data.submittedAt,
          status: {
            type: "submitted",
            label: "INTAKE.CASE_STATUS.STATUS_SUBMITTED",
          },
        };
      case "8": // INTAKE
        return {
          statusDate: data.updatedAt,
          status: {
            type: "returned",
            label: "INTAKE.CASE_STATUS.STATUS_RETURNED",
          },
        };
      case "9": // INTAKE
        return {
          statusDate: data.updatedAt,
          status: {
            type: "withdrawn",
            label: "INTAKE.CASE_STATUS.STATUS_WITHDRAWN",
          },
        };
      case "21": // INTAKE
        return {
          statusDate: data.updatedAt,
          status: {
            type: "expired",
            label: "INTAKE.CASE_STATUS.STATUS_EXPIRED",
          },
        };
      case "15": // RENEWAL
        return {
          statusDate: data.createdAt,
          status: {
            type: "initiated",
            label: "INTAKE.RENEWAL_CASE_STATUS.INITIATED",
          },
        };
      case "16": // RENEWAL
      case "18": // RENEWAL
      case "19": // RENEWAL
      case "20": // RENEWAL
        return {
          statusDate: data.submittedAt,
          status: {
            type: "submitted",
            label: "INTAKE.RENEWAL_CASE_STATUS.SUBMITTED",
          },
        };
      case "17": // RENEWAL
        return {
          statusDate: data.updatedAt,
          status: {
            type: "returned",
            label: "INTAKE.RENEWAL_CASE_STATUS.RETURNED",
          },
        };
    }

    const caseStatusType = this.caseService.checkIntakeCaseStatus(
      Number(data.status!.id)
    );
    const statusLabel = `INTAKE.CASE_STATUS.STATUS_${caseStatusType.toUpperCase()}`;
    return {
      label: statusLabel,
      type: caseStatusType,
    };
  }
}
