import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { first } from "rxjs/operators";

@Component({
  selector: "pra-imm0008-page1",
  templateUrl: "./page1.component.html",
  styleUrls: ["./page1.component.scss"],
})
export class IMM0008Page1Component implements OnInit {
  public lang: string;
  public caseId?: string | number;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private store: Store<fromApp.State>
  ) {}

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();

    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData) => {
        this.caseId = caseData.id;
      });
  }
}
