<mat-sidenav-container
  class="page-container"
  [ngClass]="{ transparent: curr === 0 }"
>
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [hasBackButton]="true"
        formNavbarBackButtonTitle="INTAKE.IMM5562.FORMS.INSTANCE_LABEL"
        [items]="FormNavbarItems"
        [isLoading]="isDataLoading"
        [curr]="curr"
        (NavButtonClickEvent)="formSideNavbarNavigation($event)"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="imm5562-page__main-heading emphasis">
      {{ "INTAKE.IMM5562.PAGES.PAGE_HEADING" | translate }}
    </h1>
    <lib-spinner [showSpinner]="isDataLoading" [fullPage]="false"></lib-spinner>
    <ng-template praComponentHost></ng-template>

    <lib-navigation-buttons
      [nextCopy]="nextButtonKey | translate"
      (clickNextButton)="saveAndNext()"
      [backCopy]="'INTAKE.FORM_PAGE_BACK' | translate"
      (clickBackButton)="navigateBack()"
      [disableNext]="disableNextButton"
    >
    </lib-navigation-buttons>

    <!-- GO BACK CONFIRMATION MODAL -->
    <lib-modal
      [id]="goBackModalId"
      [title]="'INTAKE.NAVIGATION_MODAL.TITLE' | translate"
    >
      <ng-container modalBody>
        <strong>
          {{ "INTAKE.NAVIGATION_MODAL.SUBHEADING" | translate }}
        </strong>
        <p>
          {{ "INTAKE.NAVIGATION_MODAL.DESCRIPTION" | translate }}
        </p>
      </ng-container>
      <ng-container modalButtons>
        <button category="primary" (click)="closeModal()">
          {{ "INTAKE.NAVIGATION_MODAL.STAY_ACTION" | translate }}
        </button>
        <button category="secondary" (click)="forceGoBack()">
          {{ "INTAKE.NAVIGATION_MODAL.LEAVE_ACTION" | translate }}
        </button>
      </ng-container>
    </lib-modal>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isDataLoading"
  [ngClass]="{ hideHamburger: curr === 0 }"
></pra-form-hamburger>
