import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FormSelectOption, ValidationService } from "lib";
import * as fromApp from "@pr-applicant/app/store/app.reducer";
import { Store } from "@ngrx/store";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { merge, Subscription } from "rxjs";
import { LovService } from "lib";
import { autoPopulate } from "@pr-applicant/app/shared/helpers/auto-populate";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
})
export class IMM0008AddressFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() formData: any;
  public max4 = { max: "4" };
  public max3 = { max: "3" };
  public maxCountryCode = this.max4;
  public max5 = { max: "5" };
  public max10 = { max: "10" };
  public max15 = { max: "15" };
  public max20 = { max: "20" };
  public max30 = { max: "30" };
  public max100 = { max: "100" };
  public lang: string;
  public countriesList: FormSelectOption[];
  public statesList: FormSelectOption[];
  public provinceList: FormSelectOption[];
  public phoneType: any;
  public formValues: any;
  public areFieldsCleared: boolean = false; // Used to validate form changes when fields are cleared/not touched
  public isMailingStreetNameRequired: boolean = false;

  private userEmail: string;
  private canadaCountryValue: string = "511";
  private usaCountryValue: string = "461";
  private subscriptionsList: Subscription[] = [];

  private emailValidation = [
    Validators.maxLength(100),
    Validators.email,
    this.validationService.validatorNoPipe,
    this.validationService.validatorNoPlus,
    Validators.required,
  ];

  private phoneCountryCodeValidations = [
    Validators.maxLength(4),
    this.validationService.validatorCountryCodeWithout11,
  ];

  private phoneExtensionValidations = [
    Validators.maxLength(5),
    this.validationService.validatorOnlyNumbers,
  ];
  private primPhoneAndAlternatePhoneMatch = false;
  private primPhoneAndFaxMatch = false;
  public addressForm = this.fb.group({
    postOfficeBox: [null, []],
    apartmentUnit: [null],
    streetNumber: [null],
    streetName: [null],
    city: [null],
    country: [null],
    province: [null],
    postalCode: [null],
    district: [null],
  });

  public residentialForm = this.fb.group({
    residentialSameAsMailingAddress: [null],
    postOfficeBox: [null],
    apartmentUnit: [null],
    streetNumber: [null],
    streetName: [null],
    city: [null],
    country: [null],
    province: [null],
    postalCode: [null],
    district: [null],
  });

  public primaryNumberForm = this.fb.group({
    canadaOrUSA: [null],
    type: [null],
    countryCode: [null, this.phoneCountryCodeValidations],
    number: [null],
    extension: [null, this.phoneExtensionValidations],
  });

  public alternateNumberForm = this.fb.group({
    canadaOrUSA: [null],
    type: [null],
    countryCode: [null, this.phoneCountryCodeValidations],
    number: [null],
    extension: [null, this.phoneExtensionValidations],
  });

  public faxNumberForm = this.fb.group({
    canadaOrUSA: [null],
    countryCode: [null, this.phoneCountryCodeValidations],
    number: [null],
    extension: [null, this.phoneExtensionValidations],
  });

  public emailContactForm = this.fb.group({
    contactUsingEmailAddress: [null, [Validators.required]],
    emailAddress: [null],
  });

  private disabledForAutopopulate: AbstractControl[] = [
    this.addressForm.controls.postOfficeBox,
    this.addressForm.controls.apartmentUnit,
    this.addressForm.controls.streetNumber,
    this.addressForm.controls.streetName,
    this.addressForm.controls.city,
    this.addressForm.controls.country,
    this.addressForm.controls.province,
    this.addressForm.controls.postalCode,
    this.addressForm.controls.district,
    this.residentialForm.controls.residentialSameAsMailingAddress,
    this.residentialForm.controls.postOfficeBox,
    this.residentialForm.controls.apartmentUnit,
    this.residentialForm.controls.streetNumber,
    this.residentialForm.controls.streetName,
    this.residentialForm.controls.city,
    this.residentialForm.controls.country,
    this.residentialForm.controls.province,
    this.residentialForm.controls.postalCode,
    this.residentialForm.controls.district,
  ];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    private validationService: ValidationService,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.provinceList = this.lovService.lovs.provinceAbbrev;
    this.statesList = this.lovService.lovs.stateAbbrev;
    this.countriesList = this.lovService.lovs.countryOfBirth;
    this.phoneType = this.lovService.lovs.phoneType;
    this.getUserEmail();
    this.watchEmailSameAsFileValue();
    this.watchPrimaryPhone();
    this.watchAlternativePhone();
    this.watchFaxNumber();
    autoPopulate(this.disabledForAutopopulate);
  }

  ngOnChanges(): void {
    this.formValues = this.formData?.form;
    if (this.formValues) {
      this.setFormValues();
    }
    this.handleUserEmailInvalid();
  }

  ngOnDestroy() {
    this.subscriptionsList.forEach((subsc) => subsc.unsubscribe());
  }

  /* ----------- PUBLIC ---------- */

  public get isResidentialSameMailingRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.residentialForm.controls.residentialSameAsMailingAddress
    );
  }

  public get isEmailRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.emailContactForm.controls.contactUsingEmailAddress
    );
  }

  public provinceOrStateListMailing(): FormSelectOption[] | null {
    if (this.isCanadaSelectedMailing) {
      return this.provinceList;
    } else if (this.isUsaSelectedMailing) {
      return this.statesList;
    } else {
      return null;
    }
  }

  public provinceOrStateListRes(): FormSelectOption[] | null {
    if (this.isCanadaSelectedRes) {
      return this.provinceList;
    } else if (this.isUsaSelectedRes) {
      return this.statesList;
    } else {
      return null;
    }
  }

  public clearPrimaryNumberForm(): void {
    this.primaryNumberForm?.reset();
    this.areFieldsCleared = true;
    this.resetPhoneFormValidations(this.primaryNumberForm, "PRIMARY");
  }

  public clearAlternateNumberForm(): void {
    this.alternateNumberForm?.reset();
    this.areFieldsCleared = true;
    this.resetPhoneFormValidations(this.alternateNumberForm, "ALTERNATE");
  }

  public clearFaxNumberForm(): void {
    this.faxNumberForm?.reset();
    this.areFieldsCleared = true;
    this.resetPhoneFormValidations(this.faxNumberForm, "FAX");
  }

  public get isCanadaSelectedRes(): boolean {
    return (
      this.residentialForm.controls?.country?.value === this.canadaCountryValue
    );
  }

  public get isUsaSelectedRes(): boolean {
    return (
      this.residentialForm.controls?.country?.value === this.usaCountryValue
    );
  }

  public get isCanadaSelectedMailing(): boolean {
    return (
      this.addressForm.controls?.country?.value === this.canadaCountryValue
    );
  }

  public get isUsaSelectedMailing(): boolean {
    return this.addressForm.controls?.country?.value === this.usaCountryValue;
  }

  public get residentialSameMailing(): boolean {
    if (
      this.residentialForm?.controls.residentialSameAsMailingAddress.value ===
      undefined
    ) {
      return true;
    }
    return (
      this.residentialForm?.controls.residentialSameAsMailingAddress.value === 1
    );
  }

  public get emailSameAsFile(): boolean {
    if (
      this.emailContactForm?.controls.contactUsingEmailAddress.value === null
    ) {
      return true;
    }
    return this.emailContactForm?.controls.contactUsingEmailAddress.value === 1;
  }

  public get userEmailOnFileInvalid(): boolean {
    return this.userEmail ? this.userEmail.includes("+") : true;
  }

  public get isPrimaryRadioRequired(): boolean {
    return (
      this.primaryNumberForm?.controls?.canadaOrUSA.value !== null ||
      this.primaryNumberForm?.controls?.type.value !== null ||
      (this.primaryNumberForm?.controls?.number.value !== null &&
        this.primaryNumberForm?.controls?.number.value !== "") ||
      (this.primaryNumberForm?.controls?.countryCode.value !== null &&
        this.primaryNumberForm?.controls?.countryCode.value !== "") ||
      (this.primaryNumberForm?.controls?.extension.value !== null &&
        this.primaryNumberForm?.controls?.extension.value !== "")
    );
  }

  public get isAlternateRadioRequired(): boolean {
    return (
      this.alternateNumberForm?.controls?.canadaOrUSA.value !== null ||
      this.alternateNumberForm?.controls?.type.value !== null ||
      (this.alternateNumberForm?.controls?.number.value !== null &&
        this.alternateNumberForm?.controls?.number.value !== "") ||
      (this.alternateNumberForm?.controls?.countryCode.value !== null &&
        this.alternateNumberForm?.controls?.countryCode.value !== "") ||
      (this.alternateNumberForm?.controls?.extension.value !== null &&
        this.alternateNumberForm?.controls?.extension.value !== "")
    );
  }

  public get isFaxRadioRequired(): boolean {
    return (
      this.faxNumberForm?.controls?.canadaOrUSA.value !== null ||
      (this.faxNumberForm?.controls?.number.value !== null &&
        this.faxNumberForm?.controls?.number.value !== "") ||
      (this.faxNumberForm?.controls?.countryCode.value !== null &&
        this.faxNumberForm?.controls?.countryCode.value !== "") ||
      (this.faxNumberForm?.controls?.extension.value !== null &&
        this.faxNumberForm?.controls?.extension.value !== "")
    );
  }

  public get isFormDirty(): boolean {
    return (
      this.addressForm?.dirty ||
      this.residentialForm.dirty ||
      this.primaryNumberForm.dirty ||
      this.alternateNumberForm.dirty ||
      this.faxNumberForm.dirty ||
      this.emailContactForm.dirty ||
      this.areFieldsCleared
    );
  }

  public get isFormValid(): boolean {
    const forms = [
      this.addressForm,
      this.residentialForm,
      this.primaryNumberForm,
      this.alternateNumberForm,
      this.faxNumberForm,
      this.emailContactForm,
    ];

    //check validators per form group only if form is not disabled
    return !forms.some(
      (form: FormGroup) => form.valid === false && form.disabled === false
    );
  }

  public get preparedFormValue(): any {
    const formData = {
      mailingAddress: this.addressForm?.value,
      residentialSameAsMailingAddress:
        this.residentialForm?.value.residentialSameAsMailingAddress,
      residentialAddress: this.residentialForm?.value,
      // Using getRawValue() for the phone fields to get the disabled fields' values. form.value doesn't read disable fields
      primaryTelephone: this.primaryNumberForm?.getRawValue(),
      alternateTelephone: this.alternateNumberForm?.getRawValue(),
      faxNumber: this.faxNumberForm?.getRawValue(),
      contactUsingEmailAddress:
        this.emailContactForm?.controls.contactUsingEmailAddress.value,
      emailAddress: this.emailContactForm?.value.emailAddress || this.userEmail,
    };
    return formData;
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  /* ----------- PRIVATE ---------- */

  private getUserEmail(): void {
    this.subscriptionsList.push(
      this.store.select("auth").subscribe((authState) => {
        if (authState.user?.username) {
          this.userEmail = authState.user.username;
        }
      })
    );
  }

  private handleUserEmailInvalid(): void {
    if (this.userEmailOnFileInvalid) {
      this.emailContactForm.controls.contactUsingEmailAddress.setValue(0);
      this.emailContactForm.controls.contactUsingEmailAddress.disable();
    } else {
      this.user.can("documents:write") &&
        this.emailContactForm.controls.contactUsingEmailAddress.enable();
    }
    this.emailContactForm.controls.contactUsingEmailAddress.updateValueAndValidity();
  }

  private watchEmailSameAsFileValue(): void {
    this.subscriptionsList.push(
      this.emailContactForm.controls.contactUsingEmailAddress.valueChanges.subscribe(
        (value) => {
          if (value === 1) {
            // true, same email
            this.emailContactForm.controls.emailAddress.reset();
            this.emailContactForm.controls.emailAddress.setValidators(null);
          } else {
            this.emailContactForm.controls.emailAddress.setValidators(
              this.emailValidation
            );
          }
          this.emailContactForm.controls.emailAddress.updateValueAndValidity();
        }
      )
    );
  }

  private watchPrimaryPhone(): void {
    // Watch the radio button was selected
    this.subscriptionsList.push(
      this.primaryNumberForm?.controls?.canadaOrUSA.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value !== null) {
            if (value === true) {
              // phone number is Canada or USA
              this.setNorthAmericaPhoneValidations(
                this.primaryNumberForm,
                "PRIMARY"
              );
            } else if (value === false) {
              this.setOtherPhoneValidations(this.primaryNumberForm, "PRIMARY");
            }
          } else {
            // If values are cleared, reset validators
            this.resetPhoneFormValidations(this.primaryNumberForm, "PRIMARY");
          }
        })
    );
    // Add subscription for cross phone check trigger
    this.subscriptionsList.push(
      merge(
        this.primaryNumberForm?.controls?.countryCode.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.primaryNumberForm?.controls?.number.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.primaryNumberForm?.controls?.extension.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
      ).subscribe((value) => this.crossValidatePhones())
    );

    // Watch the country code method
    this.subscriptionsList.push(
      this.primaryNumberForm?.controls?.countryCode.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.validateCountryCodeStartWithOne(value, this.primaryNumberForm);
          }
        })
    );
  }

  private watchAlternativePhone(): void {
    // Watch the radio button was selected
    this.subscriptionsList.push(
      this.alternateNumberForm?.controls?.canadaOrUSA.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value !== null) {
            if (value === true) {
              // phone number is Canada or USA
              this.setNorthAmericaPhoneValidations(
                this.alternateNumberForm,
                "ALTERNATE"
              );
            } else if (value === false) {
              this.setOtherPhoneValidations(
                this.alternateNumberForm,
                "ALTERNATE"
              );
            }
          } else {
            // If values are cleared, reset validators
            this.resetPhoneFormValidations(
              this.alternateNumberForm,
              "ALTERNATE"
            );
          }
        })
    );

    // Add subscription for cross phone check trigger
    this.subscriptionsList.push(
      merge(
        this.alternateNumberForm?.controls?.countryCode.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.alternateNumberForm?.controls?.number.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.alternateNumberForm?.controls?.extension.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
      ).subscribe((value) => this.crossValidatePhones())
    );

    // Watch the country code method
    this.subscriptionsList.push(
      this.alternateNumberForm?.controls?.countryCode.valueChanges.subscribe(
        (value) => {
          if (value) {
            this.validateCountryCodeStartWithOne(
              value,
              this.alternateNumberForm
            );
          }
        }
      )
    );
  }

  private watchFaxNumber(): void {
    // Watch the radio button was selected
    this.subscriptionsList.push(
      this.faxNumberForm?.controls?.canadaOrUSA.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value !== null) {
            if (value === true) {
              // if Fax is Canada or USA
              this.setNorthAmericaPhoneValidations(this.faxNumberForm, "FAX");
            } else if (value === false) {
              this.setOtherPhoneValidations(this.faxNumberForm, "FAX");
            }
          } else {
            // If values are cleared, reset validators
            this.resetPhoneFormValidations(this.faxNumberForm, "FAX");
          }
        })
    );
    // Add subscription for cross phone check trigger
    this.subscriptionsList.push(
      merge(
        this.faxNumberForm?.controls?.countryCode.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.faxNumberForm?.controls?.number.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        ),
        this.faxNumberForm?.controls?.extension.valueChanges.pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
      ).subscribe((value) => this.crossValidatePhones())
    );
    // Watch the country code method
    this.subscriptionsList.push(
      this.faxNumberForm?.controls?.countryCode.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.validateCountryCodeStartWithOne(value, this.faxNumberForm);
          }
        })
    );
  }
  extraAlternatePhoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.primPhoneAndAlternatePhoneMatch ? { matching: true } : null;
    };
  }
  extraFaxValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.primPhoneAndFaxMatch ? { matching: true } : null;
    };
  }

  // validates in between phones
  crossValidatePhones() {
    const primaryPhoneNbrControl = this.primaryNumberForm?.controls?.number;
    const primaryPhoneNbrExtControl =
      this.primaryNumberForm?.controls?.extension;
    const primaryPhoneCountryCodeControl =
      this.primaryNumberForm?.controls?.countryCode;
    const alternateNbrControl = this.alternateNumberForm?.controls?.number;
    const alternateNbrExtensionControl =
      this.alternateNumberForm?.controls?.extension;
    const alternateNbrCountryCodeControl =
      this.alternateNumberForm?.controls?.countryCode;
    const faxNbrControl = this.faxNumberForm?.controls?.number;
    const faxNbrExtensionControl = this.faxNumberForm?.controls?.extension;
    const faxNbrCountryCodeControl = this.faxNumberForm?.controls?.countryCode;
    const primaryPhoneValueToCompare =
      (primaryPhoneCountryCodeControl.value
        ? primaryPhoneCountryCodeControl.value
        : "") +
      (primaryPhoneNbrControl?.value ? primaryPhoneNbrControl?.value : "") +
      (primaryPhoneNbrExtControl?.value
        ? primaryPhoneNbrExtControl?.value
        : "");
    const alternatePhoneValueToCompare =
      (alternateNbrCountryCodeControl.value
        ? alternateNbrCountryCodeControl.value
        : "") +
      (alternateNbrControl?.value ? alternateNbrControl?.value : "") +
      (alternateNbrExtensionControl?.value
        ? alternateNbrExtensionControl?.value
        : "");
    const faxNbrValueToCompare =
      (faxNbrCountryCodeControl.value ? faxNbrCountryCodeControl.value : "") +
      (faxNbrControl?.value ? faxNbrControl?.value : "") +
      (faxNbrExtensionControl?.value ? faxNbrExtensionControl?.value : "");

    // validate alternate nbr vs phone number
    if (
      primaryPhoneValueToCompare === alternatePhoneValueToCompare &&
      alternatePhoneValueToCompare
    ) {
      this.primPhoneAndAlternatePhoneMatch = true;
      setTimeout(() => {
        alternateNbrControl.updateValueAndValidity();
      }, 100);
    } else {
      this.primPhoneAndAlternatePhoneMatch = false;
      this.validateCountryCodeStartWithOne(
        alternateNbrControl.value,
        this.alternateNumberForm
      );
      alternateNbrControl.updateValueAndValidity();
    }
    // validate fax number vs primary number
    if (
      faxNbrValueToCompare === primaryPhoneValueToCompare &&
      faxNbrValueToCompare
    ) {
      this.primPhoneAndFaxMatch = true;
      setTimeout(() => {
        faxNbrControl?.setErrors({ matching: true });
        faxNbrControl.updateValueAndValidity();
      }, 100);
    } else {
      this.primPhoneAndFaxMatch = false;
      this.validateCountryCodeStartWithOne(
        faxNbrControl.value,
        this.faxNumberForm
      );
      faxNbrControl.updateValueAndValidity();
    }
  }

  private setFormValues(): void {
    // Mailing Address
    this.addressForm.controls.postOfficeBox.setValue(
      this.formValues.mailingAddress.postOfficeBox
    );
    this.addressForm.controls.apartmentUnit.setValue(
      this.formValues.mailingAddress.apartmentUnit
    );
    this.addressForm.controls.streetNumber.setValue(
      this.formValues.mailingAddress.streetNumber
    );
    this.addressForm.controls.streetName.setValue(
      this.formValues.mailingAddress.streetName
    );
    this.addressForm.controls.city.setValue(
      this.formValues.mailingAddress.city
    );
    this.addressForm.controls.country.setValue(
      this.formValues.mailingAddress.country
    );
    this.addressForm.controls.province.setValue(
      this.formValues.mailingAddress.province
    );
    this.addressForm.controls.postalCode.setValue(
      this.formValues.mailingAddress.postalCode
    );
    this.addressForm.controls.district.setValue(
      this.formValues.mailingAddress.district
    );

    // Residential Address
    this.residentialForm.controls.residentialSameAsMailingAddress.setValue(
      this.formValues.residentialSameAsMailingAddress
    );
    this.residentialForm.controls.apartmentUnit.setValue(
      this.formValues.residentialAddress.apartmentUnit
    );
    this.residentialForm.controls.streetNumber.setValue(
      this.formValues.residentialAddress.streetNumber
    );
    this.residentialForm.controls.streetName.setValue(
      this.formValues.residentialAddress.streetName
    );
    this.residentialForm.controls.city.setValue(
      this.formValues.residentialAddress.city
    );
    this.residentialForm.controls.country.setValue(
      this.formValues.residentialAddress.country
    );
    this.residentialForm.controls.province.setValue(
      this.formValues.residentialAddress.province
    );
    this.residentialForm.controls.postalCode.setValue(
      this.formValues.residentialAddress.postalCode
    );
    this.residentialForm.controls.district.setValue(
      this.formValues.residentialAddress.district
    );

    // Primary Number
    this.primaryNumberForm.controls.canadaOrUSA.setValue(
      this.formValues.primaryTelephone.canadaOrUSA
    );
    this.primaryNumberForm.controls.type.setValue(
      this.formValues.primaryTelephone.type
    );
    this.primaryNumberForm.controls.countryCode.setValue(
      this.formValues.primaryTelephone.countryCode
    );
    this.primaryNumberForm.controls.number.setValue(
      this.formValues.primaryTelephone.number
    );
    this.primaryNumberForm.controls.extension.setValue(
      this.formValues.primaryTelephone.extension
    );
    if (this.formValues?.primaryTelephone?.canadaOrUSA === true) {
      this.setNorthAmericaPhoneValidations(this.primaryNumberForm, "PRIMARY");
    } else if (this.formValues?.primaryTelephone?.canadaOrUSA === false) {
      this.setOtherPhoneValidations(this.primaryNumberForm, "PRIMARY");
    }
    // Alternate Number
    this.alternateNumberForm.controls.canadaOrUSA.setValue(
      this.formValues.alternateTelephone.canadaOrUSA
    );
    this.alternateNumberForm.controls.type.setValue(
      this.formValues.alternateTelephone.type
    );
    this.alternateNumberForm.controls.countryCode.setValue(
      this.formValues.alternateTelephone.countryCode
    );
    this.alternateNumberForm.controls.number.setValue(
      this.formValues.alternateTelephone.number
    );
    this.alternateNumberForm.controls.extension.setValue(
      this.formValues.alternateTelephone.extension
    );
    if (this.formValues?.alternateTelephone?.canadaOrUSA === true) {
      this.setNorthAmericaPhoneValidations(
        this.alternateNumberForm,
        "ALTERNATE"
      );
    } else if (this.formValues?.alternateTelephone?.canadaOrUSA === false) {
      this.setOtherPhoneValidations(this.alternateNumberForm, "ALTERNATE");
    }
    // Fax Number
    this.faxNumberForm.controls.canadaOrUSA.setValue(
      this.formValues.faxNumber.canadaOrUSA
    );
    this.faxNumberForm.controls.countryCode.setValue(
      this.formValues.faxNumber.countryCode
    );
    this.faxNumberForm.controls.number.setValue(
      this.formValues.faxNumber.number
    );
    this.faxNumberForm.controls.extension.setValue(
      this.formValues.faxNumber.extension
    );
    if (this.formValues?.faxNumber?.canadaOrUSA === true) {
      this.setNorthAmericaPhoneValidations(this.faxNumberForm, "FAX");
    } else if (this.formValues?.faxNumber?.canadaOrUSA === false) {
      this.setOtherPhoneValidations(this.faxNumberForm, "FAX");
    }
    // Email Address
    this.emailContactForm.controls.contactUsingEmailAddress.setValue(
      this.formValues.contactUsingEmailAddress
    );
    if (!this.emailSameAsFile) {
      this.emailContactForm.controls.emailAddress.setValue(
        this.formValues.emailAddress
      );
    }
    if (this.formValues.mailingAddress.postOfficeBox) {
      this.residentialForm.controls.residentialSameAsMailingAddress.setValidators(
        [
          this.validationService.isValueEmpty(
            this.addressForm.value.postOfficeBox
          ),
          Validators.required,
        ]
      );
      this.residentialForm.controls.residentialSameAsMailingAddress.updateValueAndValidity();
    }
  }

  // This method will set northAmerica Validations to phone number fields
  private setNorthAmericaPhoneValidations(
    phoneForm: FormGroup,
    phoneType: string
  ): void {
    phoneForm?.controls?.type?.setValidators(Validators.required);
    phoneForm?.controls?.type?.updateValueAndValidity();

    // Set Country code to 1 and disable
    phoneForm?.controls?.countryCode?.setValue("1");
    phoneForm?.controls?.countryCode?.disable();
    phoneForm?.controls?.countryCode?.setValidators(
      this.phoneCountryCodeValidations
    );
    phoneForm?.controls?.countryCode?.updateValueAndValidity();
    const validators = [
      Validators.required,
      this.validationService.validatorPhoneNumber,
      this.validationService.validatorPhoneNumberAreaCode(true), // check first area code
      this.validationService.validatorPhoneNumberAreaCode(false), // check second group code
      this.validationService.autoFormatPhoneNumber("countryCode"),
    ];
    if (phoneType === "FAX") {
      validators.push(this.extraFaxValidator());
    }
    if (phoneType === "ALTERNATE") {
      validators.push(this.extraAlternatePhoneValidator());
    }

    // Set number required and validate to be ###-###-####
    phoneForm?.controls?.number?.setValidators(validators);
    phoneForm?.controls?.number?.updateValueAndValidity();
  }

  // This method will set other Validations to phone number fields
  private setOtherPhoneValidations(
    phoneForm: FormGroup,
    phoneType: string
  ): void {
    // Set type as required if exists
    phoneForm?.controls?.type?.setValidators(Validators.required);
    phoneForm?.controls?.type?.updateValueAndValidity();

    // Enable country code and validators
    phoneForm?.controls?.countryCode?.enable();
    phoneForm?.controls?.countryCode?.setValidators(
      this.phoneCountryCodeValidations.concat(Validators.required)
    );
    phoneForm?.controls?.countryCode?.updateValueAndValidity();
    this.validateCountryCodeStartWithOne(
      phoneForm?.controls?.countryCode?.value,
      phoneForm
    );
    const validators = [
      Validators.required,
      this.validationService.validatorNumericPlusSpace,
      Validators.maxLength(20),
    ];
    if (phoneType === "FAX") {
      validators.push(this.extraFaxValidator());
    }
    if (phoneType === "ALTERNATE") {
      validators.push(this.extraAlternatePhoneValidator());
    }
    // Set number required, max 20 characters and numeric with space
    phoneForm?.controls?.number?.setValidators(validators);
    phoneForm?.controls?.number?.updateValueAndValidity();
  }

  // This method will reset phone form fields to the initial minimal/non required validations
  private resetPhoneFormValidations(
    phoneForm: FormGroup,
    phoneType: string
  ): void {
    phoneForm?.controls?.type?.clearValidators();
    phoneForm?.controls?.type?.updateValueAndValidity();
    phoneForm?.controls?.countryCode?.setValidators(
      this.phoneCountryCodeValidations
    );
    phoneForm?.controls?.countryCode?.enable();
    phoneForm?.controls?.countryCode?.updateValueAndValidity();
    const validators = [
      this.validationService.validatorNumericPlusSpace,
      Validators.maxLength(20),
    ];
    if (phoneType === "FAX") {
      validators.push(this.extraFaxValidator());
    }
    if (phoneType === "ALTERNATE") {
      validators.push(this.extraAlternatePhoneValidator());
    }
    phoneForm?.controls?.number?.setValidators(validators);
    phoneForm?.controls?.number?.updateValueAndValidity();
  }

  // This method is to specifically validates the country code if it starts with 1, it HAS to be 4 digits
  private validateCountryCodeStartWithOne(
    value: string | null,
    form: FormGroup
  ): void {
    // If the value isn't null or empty, check the typed in chars and validations
    if (value !== null && value !== "") {
      const firstChar = value.charAt(0);
      const secondChar = value.charAt(1);
      const otherSelected =
        form?.controls?.canadaOrUSA?.value === false ||
        form?.controls?.canadaOrUSA?.value === null;
      if (
        otherSelected &&
        firstChar === "1" &&
        secondChar &&
        secondChar !== "1" &&
        value.length < 4
      ) {
        form?.controls?.countryCode?.setValidators(
          this.phoneCountryCodeValidations.concat([
            Validators.required,
            Validators.minLength(4),
          ])
        );
        this.maxCountryCode = this.max4;
      } else if (otherSelected && firstChar !== "1") {
        this.maxCountryCode = this.max3;
        form?.controls?.countryCode?.setValidators(
          this.phoneCountryCodeValidations.concat(
            Validators.required,
            Validators.maxLength(3)
          )
        );
      } else {
        form?.controls?.countryCode?.setValidators(
          this.phoneCountryCodeValidations.concat(Validators.required)
        );
      }
    }
  }
}
