import { FormSelectOption } from "lib";

export const provinces: FormSelectOption[] = [
  {
    value: "AB",
    text: {
      en: "Alberta",
      fr: "Alberta",
    },
  },
  {
    value: "BC",
    text: {
      en: "British Columbia",
      fr: "Colombie-Britannique",
    },
  },
  {
    value: "MB",
    text: {
      en: "Manitoba",
      fr: "Manitoba",
    },
  },
  {
    value: "NB",
    text: {
      en: "New Brunswick",
      fr: "Nouveau-Brunswick",
    },
  },
  {
    value: "NL",
    text: {
      en: "Newfoundland & Labrador",
      fr: "Terre-Neuve et Labrador",
    },
  },
  {
    value: "NS",
    text: {
      en: "Nova Scotia",
      fr: "Nouvelle-Écosse",
    },
  },
  {
    value: "NT",
    text: {
      en: "Northwest Territories",
      fr: "Territoires du Nord-Ouest",
    },
  },
  {
    value: "NU",
    text: {
      en: "Nunavut",
      fr: "Nunavut",
    },
  },
  {
    value: "ON",
    text: {
      en: "Ontario",
      fr: "Ontario",
    },
  },
  {
    value: "PE",
    text: {
      en: "Prince Edward Island",
      fr: "Île-du-Prince-Édouard",
    },
  },
  {
    value: "QC",
    text: {
      en: "Quebec",
      fr: "Québec",
    },
  },
  {
    value: "SK",
    text: {
      en: "Saskatchewan",
      fr: "Saskatchewan",
    },
  },
  {
    value: "YT",
    text: {
      en: "Yukon",
      fr: "Yukon",
    },
  },
];
