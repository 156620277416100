import { SelectedPermanentResidentCardState } from "../prcPrtdState";

import { Action, createReducer, on } from "@ngrx/store";
import * as SelectedPRCActions from "../actions/selectedPRCActions";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";

export const INITIAL_SELECTED_PRC_CARD: SelectedPermanentResidentCardState = {
  prcData: null,
  isLoaded: false,
  isLoading: false,
  hasError: false,
};

const reduceSelectedPRC = createReducer(
  INITIAL_SELECTED_PRC_CARD,
  on(SelectedPRCActions.StartLoadPRCAction, (state, action) => {
    return { ...state, isLoading: true };
  }),
  on(SelectedPRCActions.SetNewPRCAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      hasError: false,
      prcData: { id: "ISNEW" } as Case,
    };
  }),
  on(SelectedPRCActions.LoadPRCAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      hasError: false,
      prcData: action.prcCard,
    };
  }),
  on(SelectedPRCActions.LoadPRCErrorAction, (state, action) => {
    return {
      ...state,
      isLoaded: true,
      isLoading: false,
      hasError: true,
      error: action.error,
    };
  }),
  on(SelectedPRCActions.ResetPRCStateAction, (state, action) => {
    return INITIAL_SELECTED_PRC_CARD;
  }),
  on(SelectedPRCActions.UpdatePRCWitClientAndAppInfoAction, (state, action) => {
    const prcData = { ...state.prcData, ...action } as Case;
    return { ...state, prcData };
  }),
  on(SelectedPRCActions.UpdatePRCIsUrgentAction, (state, action) => {
    const prcData = { ...state.prcData, ...action } as Case;
    return { ...state, prcData };
  })
);

export function selectedPRCReducer(
  state: SelectedPermanentResidentCardState | undefined,
  action: Action
) {
  return reduceSelectedPRC(state, action);
}
