export const DOC_UPLOAD_ERROR_COPY = [
  {
    FILE_SIZE: {
      en: "File size exceeds the maximum of 4 MB.",
      fr: "La taille du fichier dépasse le maximum de 4 Mo.",
    },
    FILE_TYPE: {
      en: "Your document file type does not meet the required criteria.",
      fr: "Votre type de document ne répond pas aux critères requis.",
    },
    FILE_NAME_LENGTH: {
      en: "File name exceeds the maximum length of 180 characters.",
      fr: "Le nom du fichier dépasse la longueur maximale de 180 caractères.",
    },
    FILE_NAME_CHARACTERS: {
      en: "The name of the selected file contains invalid characters. Please ensure that files meet all the upload requirements indicated under the Application forms table.",
      fr: "Le nom du fichier sélectionné contient des caractères non valides. Veuillez-vous assurer que les fichiers répondent à toutes les exigences de téléchargement indiquées sous le tableau des formulaires de demande.",
    },
  },
];
