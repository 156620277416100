import * as fromApp from "../../../../store/app.reducer";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertService, ModalService, documentTypes } from "lib";

import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { CanComponentDeactivate } from "../../../../routing/guards/nav.guard";
import { Case } from "../../../../shared/case-module/case.model";
import { Document } from "../../../../core/models/document.model";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { IMM0008ApplicationDetailsFormComponent } from "../../../forms/IMM0008/application-details-form/application-details-form.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import FormNavbarItems from "../imm0008-form-navbar-items.json";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm0008-page2",
  templateUrl: "./page2.component.html",
  styleUrls: [
    "./page2.component.scss",
    "../../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM0008Page2Component
  implements OnInit, CanComponentDeactivate, AfterContentChecked, OnDestroy
{
  public lang: string;
  public navigationModalId = "navigationModal";
  public isFormTouched: boolean;
  public isFormSaved: boolean = false;
  public isLoading: boolean = true;
  public formData: object;
  public imm0008_version: number;
  public disableNextButton: boolean = false; // if save and next button is to be enabled
  public FormNavbarItems = FormNavbarItems;

  private imm0008PageName: string = "applicationDetails";
  private caseId: string | number;
  private lobId: number;
  private documentId?: number;
  mobileQuery: MediaQueryList;

  @ViewChild("appDetailsForm", { static: false })
  appDetailsForm: IMM0008ApplicationDetailsFormComponent;
  private _mobileQueryListener: () => void;
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private modalService: ModalService,
    private store: Store<fromApp.State>,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdr.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getStoreData();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // check if form has been touched or saved
    this.isFormTouched = this.appDetailsForm.applicationDetailsForm.touched;

    if (this.isFormTouched && !this.isFormSaved) {
      this.modalService.open(this.navigationModalId);
      return this.modalService.navigateAwaySelection$;
    } else {
      return true;
    }
  }

  /*----------  PUBLIC ---------*/

  public async saveApplicationDetailsForm() {
    this.isFormTouched = this.appDetailsForm?.applicationDetailsForm?.touched;
    const nextPage = this.routeLocalizer.get(this.lang, "FORM_PAGE3", "../");

    // Check, if the form isn't touched, don't save and navigate directly
    const formData = this.appDetailsForm?.getFormatedFormValues();
    const isValidForm = this.appDetailsForm?.applicationDetailsForm?.valid;

    // Submit form if form is valid and data is available to submit
    if (isValidForm && formData && this.documentId) {
      try {
        const response =
          await this.documentService.updateFormByDocumentIdAndPage(
            this.caseId.toString(),
            this.documentId,
            this.imm0008PageName,
            formData,
            this.imm0008_version
          );
        if (response) {
          if (response?.hasApiError) {
            console.log("response status:", response.error.response.status);
            const errorStatus = response.error.response.status;
            if (errorStatus === 409) {
              this.alertService.danger(
                this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
              );
            } else {
              this.alertService.danger(this.alertTechnicalError);
            }
          } else {
            this.isFormSaved = true;
            this.router.navigate([nextPage], { relativeTo: this.route });
          }
        }
      } catch (err) {
        // Show an error alert if there is an issue with saving the
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      // Show an error alert if for any reason the form isn't valid, or documentId or FormData
      // is having issues
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  public async getApplicationDetailsForm() {
    if (this.caseId && this.documentId) {
      try {
        const formData = await this.documentService.getFormByDocumentIdAndPage(
          this.caseId.toString(),
          this.documentId,
          this.imm0008PageName
        );
        console.log("IMM0081:", formData.version);
        this.imm0008_version = formData.version;
        this.formData = {
          ...formData,
          lobId: this.lobId,
        };

        console.log("IMM008:", this.formData);
        this.isLoading = false;
      } catch (err) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
  }

  /*----------  PRIVATE ---------*/

  private getStoreData() {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.lobId) {
          this.lobId = caseData.lobId;
        }
        if (caseData.id !== "" && caseData.documents) {
          this.caseId = caseData.id;
          const imm8Document = caseData.documents?.find(
            (doc: Document) => doc.documentTypeId === documentTypes.imm0008.id
          );
          this.documentId = imm8Document?.id;
        }
        this.getApplicationDetailsForm();
      });
  }

  public get nextButtonKey(): string {
    if (!this.user.can("documents:write")) {
      return "INTAKE.FORM_PAGE_NEXT";
    }
    return "INTAKE.FORM_PAGE_SAVE";
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }
}
