import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import {
  AlertService,
  ModalService,
  documentSizes,
  documentTypes,
  uploadFileTypes,
  ValidationService,
} from "lib";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { DownloadService } from "../../../../shared/services/download.service";
import { TranslateService } from "@ngx-translate/core";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { Document } from "../../../../core/models/document.model";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { PROOF_OF_PAYMENT_ERROR_COPY } from "./proof-of-payment-models";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: "pra-proof-of-payment-table",
  templateUrl: "./proof-of-payment-table.component.html",
  styleUrls: ["./proof-of-payment-table.component.scss"],
})
export class ProofOfPaymentTableComponent implements OnInit, OnDestroy {
  @Input() isHiddenLob: boolean = false;

  //lang
  public lang: string;

  // case docs and case id
  public case: any;
  public caseId: string;
  public caseStatusId: number;
  public caseDocuments: Document[];
  public existingFiles: any;
  private tempDeleteReceipt: any;
  private isLoading: boolean = false;

  // proof of payment id
  public documentTypeId = documentTypes.proofOfPayment.id;

  // file size, type and length
  private maxFileSize = documentSizes.forms.maxFileSize;
  private megaFileConversion = documentSizes.forms.megaFileConversion;
  private maxFileNameLength = 180;

  public fileSelected: any;
  public acceptedFileTypes = uploadFileTypes.proofOfPayment.types;
  private subscriptions: Subscription[] = [];

  // status info
  public status: string | null;
  public statusLabel: string | null;
  public translatedStatusText: string;
  public required: boolean = true;
  public deleteModalId = "confirmDeletePayment";

  //  emit up to intake-landing-page component to re-check if application is ready to be submitted
  @Output() proofOfPaymentUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private store: Store<fromApp.State>,
    private downloadService: DownloadService,
    private translate: TranslateService,
    private documentService: DocumentService,
    private modalService: ModalService,
    private validationService: ValidationService,
    public routeLocalizer: RouteLocalizerService,
    private lobService: LobService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.store.select("selectedCase").subscribe((caseData) => {
      if (caseData) {
        this.case = caseData;
        this.caseId = this.case.id;
        this.caseStatusId = this.case.caseStatusId;
      }
    });
    this.updateCaseDocuments();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.subscriptions.push(
      this.documentService.requiredPOP.subscribe((required) => {
        this.required = required;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public async updateCaseDocuments() {
    this.caseDocuments = await this.documentService.getDocumentByCaseId(
      this.caseId
    );
    // Check DB for existing file
    this.existingFiles = this.documentService.getFilesByDocumentType(
      this.documentTypeId,
      this.caseDocuments
    );
    this.checkStatus();

    this.proofOfPaymentUpdated.emit();
  }

  public checkStatus() {
    let statusType = null;
    if (this.existingFiles[0]) {
      statusType = this.documentService.checkDocumentOrFormStatus(
        this.existingFiles[0],
        this.caseStatusId
      );
    }
    if (statusType) {
      this.status = statusType;
      this.statusLabel = `INTAKE.FORM_DOCUMENT.STATUS_${statusType.toUpperCase()}`;
    } else {
      this.status = "notStarted";
      this.statusLabel = "INTAKE.TABLE_HEADINGS.EMPTY_CELL.NOT_STARTED";
    }
  }

  public async downloadFile(receipt: Document) {
    const downloadName = receipt.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)
    try {
      await this.downloadService.downloadDocument(
        receipt.caseId,
        receipt.id,
        downloadName
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  /* ------- PUBLIC ------------*/
  public file_size: string;
  public file_type: string;
  public file_name_length: string;

  ERROR_COPY = PROOF_OF_PAYMENT_ERROR_COPY;

  public isFileValid(file: File): boolean {
    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;
    const fileSizeValid = file && file.size <= this.maxFileSize;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);
    const maxFileNameLength = file.name.length <= this.maxFileNameLength;
    const validFileNameForUpload =
      this.validationService.isValidFileNameForUpload(file.name);

    if (
      !fileSizeValid ||
      !fileTypeValid ||
      !maxFileNameLength ||
      !validFileNameForUpload
    ) {
      if (this.lang === "en") {
        const en_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.en),
            (this.file_type = item.FILE_TYPE.en),
            (this.file_name_length = item.FILE_NAME_LENGTH.en);
        });
      } else {
        this.lang === "fr";
        const fr_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.fr),
            (this.file_type = item.FILE_TYPE.fr),
            (this.file_name_length = item.FILE_NAME_LENGTH.fr);
        });
      }
      if (fileSizeValid === false) {
        this.alertService.warning(this.file_size);
      }
      if (validFileNameForUpload === false) {
        if (this.lang === "en") {
          this.alertService.warning(this.ERROR_COPY[0].FILE_NAME_CHARACTERS.en);
        } else {
          this.alertService.warning(this.ERROR_COPY[0].FILE_NAME_CHARACTERS.fr);
        }
      }
      if (fileTypeValid === false) {
        this.alertService.warning(this.file_type);
      }
      if (maxFileNameLength === false) {
        this.alertService.warning(this.file_name_length);
      }
      return false;
    }
    return true;
  }

  public async uploadFile(e: { target?: HTMLInputElement }): Promise<any> {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const fileValid = this.isFileValid(file);

    if (fileValid) {
      this.fileSelected = file;
      try {
        // upload
        await this.documentService.uploadNewFileByDocumentTypeId(
          this.caseId,
          this.fileSelected,
          this.documentTypeId
        );
        this.alertService.success(this.alertSuccess);
        // update
        this.updateCaseDocuments();
        this.checkStatus();
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
  }

  public openDeleteModal(receipt: Document): void {
    this.modalService.open(this.deleteModalId);
    this.tempDeleteReceipt = receipt;
  }

  public closeModal(): void {
    this.modalService.close(this.deleteModalId);
    this.tempDeleteReceipt = undefined;
  }

  public async deleteFile(): Promise<any> {
    if (this.tempDeleteReceipt && !this.isLoading) {
      try {
        this.isLoading = true;
        await this.documentService.deleteFileByDocumentTypeAndId(
          this.caseId,
          this.documentTypeId,
          this.tempDeleteReceipt.id
        );
        this.fileSelected = null;
        this.caseDocuments = this.caseDocuments.filter(
          (doc) => doc.id !== this.tempDeleteReceipt.id
        );
        this.existingFiles = this.documentService.getFilesByDocumentType(
          this.documentTypeId,
          this.caseDocuments
        );
        this.checkStatus();
        this.proofOfPaymentUpdated.emit();
        this.alertService.success(this.alertDeleteSuccess);
        this.isLoading = false;
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        this.isLoading = false;
      }
    }

    this.closeModal();
  }

  public fileName(receipt: Document) {
    return receipt.documentName.substring(
      receipt.documentName.lastIndexOf("/") + 1
    );
  }

  public areReceiptsEmpty() {
    return this?.existingFiles?.length === 0 ? true : false;
  }

  private get alertSuccess(): string {
    return this.translate.instant("INTAKE.PROOF_OF_PAYMENT.UPLOAD_SUCCESS");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.PROOF_OF_PAYMENT.UPLOAD_ERROR");
  }

  private get alertDeleteSuccess(): string {
    return this.translate.instant("INTAKE.PROOF_OF_PAYMENT.DELETE_SUCCESS");
  }
}
