import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { environment } from "@pr-applicant/environments/environment";
import { Observable, from, map, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CognitoHelperService {
  constructor(private awsService: AWSService) {}

  private apiVersion = environment.API_VERSION;
  private apiName = environment.API_NAME;

  public isCorrectUserGroup(email: string) {
    const path = `/${this.apiVersion}/get-user-group`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
          body: {
            email: email,
          },
        };
        return from(this.awsService.api.post(this.apiName, path, httpOptions));
      })
    );
  }
}
