import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Subscription } from "rxjs";
import { distinctUntilChanged, filter, take } from "rxjs/operators";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import * as CaseActions from "../../../../shared/case-module/store/case.actions";
import { LovService, AlertService, FilteredFormSelectOption } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { CaseService } from "../../../../shared/case-module/case.service";
import { Case } from "../../../../shared/case-module/case.model";
import { FormGroup, Validators, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "pra-certificate-number-section",
  templateUrl: "./certificate-number-section.component.html",
  styleUrls: ["./certificate-number-section.component.scss"],
})
export class CertificateNumberSectionComponent implements OnInit, OnChanges {
  @Input() formData: FormGroup;

  constructor(
    private routeLocalizer: RouteLocalizerService,
    private lovService: LovService,
    private caseService: CaseService,
    private alertService: AlertService,
    public translate: TranslateService,
    private store: Store<fromApp.State>,
    private fb: UntypedFormBuilder
  ) {}

  public designatedEntityText: string;
  public viewOnly: boolean = false;
  public case: Case;
  public form = this.fb.group({
    designatedEntityId: [null, Validators.required],
    commCert: [null, Validators.required],
    hasCommCert: [null, Validators.required],
  });
  public isLoading: boolean = false;

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(
          distinctUntilChanged(),
          filter((caseData: Case) => !!caseData.id),
          take(1)
        )
        .subscribe((caseData: Case) => {
          this.case = caseData;
          this.form.setValue({
            hasCommCert: caseData.hasCommCert,
            designatedEntityId: null,
            commCert: null,
          });

          if (
            this.case.commCert?.designatedEntityId &&
            this.case.designatedEntityId
          ) {
            this.designatedEntityText = this.findSingleDesignatedEntity(
              this.case.commCert?.designatedEntityId.toString()
            );
            this.viewOnly = true;
            console.log("this.designatedEntityText", this.designatedEntityText);
            this.form.setValue({
              hasCommCert: caseData.hasCommCert,
              designatedEntityId: caseData.designatedEntityId,
              commCert: caseData.commCert?.commCert,
            });
            console.log(this.form.controls.commCert.value);
          }
          this.watchHasCommCert();
        })
    );
  }
  ngOnChanges(): void {}

  watchHasCommCert() {
    this.subscriptions.push(
      this.form.controls.hasCommCert.valueChanges.subscribe(async (value) => {
        if (this.viewOnly) return;
        this.store.dispatch(
          new CaseActions.UpdateDesignatedEntity({
            ...this.case,
            commCertId: null,
            designatedEntityId: null,
            hasCommCert: value,
          })
        );
        await this.caseService.putDesignatedEntity({
          hasCommCert: value,
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public get designatedEntityList(): FilteredFormSelectOption[] {
    if (this.lang === "fr") {
      const frenchEntityList = this.lovService.lovs.designatedEntityList.map(
        (item) => ({
          value: item.value,
          text: item.text.fr,
        })
      );
      return frenchEntityList.sort(
        (a: FilteredFormSelectOption, b: FilteredFormSelectOption) =>
          a.text.localeCompare(b.text)
      );
    } else {
      const englishEntityList = this.lovService.lovs.designatedEntityList.map(
        (item) => ({
          value: item.value,
          text: item.text.en,
        })
      );
      return englishEntityList.sort(
        (a: FilteredFormSelectOption, b: FilteredFormSelectOption) =>
          a.text.localeCompare(b.text)
      );
    }
  }
  public get infoBoxLink(): string {
    return this.lang === "fr"
      ? "https://www.canada.ca/fr/immigration-refugies-citoyennete/services/immigrer-canada/visa-demarrage/participer/trouver-une-entreprise.html"
      : "https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/start-visa/participate/find-a-startup.html";
  }

  public async save(): Promise<any> {
    this.form.markAllAsTouched();
    if (!this.form.valid) return;
    const payload = {
      ...this.form.value,
      designatedEntityId: this.form.value.designatedEntityId.value,
      commCert: this.form.value.commCert.toUpperCase(),
    };
    this.isLoading = true;
    try {
      await this.caseService.putDesignatedEntity(payload);
      this.alertService.success("Success");
      this.designatedEntityText = this.findSingleDesignatedEntity(
        payload.designatedEntityId
      );
      this.viewOnly = true;
      this.store.dispatch(
        new CaseActions.UpdateDesignatedEntity({
          ...this.case,
          ...payload,
        })
      );
    } catch (error) {
      this.alertService.danger(
        this.translate.instant("INTAKE.LANDING_PAGE.DESIGNATED_ENTITY_INVALID")
      );
      setTimeout(
        // setError doesn't work without setTimeout when used in conjunction with required validator
        () => this.form.controls.commCert.setErrors({ invalid: true }),
        1
      );
    }
    this.isLoading = false;
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  public findSingleDesignatedEntity(entityValue: string) {
    const displayedValue = this.lovService.lovs.designatedEntityList.find(
      (entity) => entity.value === entityValue
    );
    if (displayedValue) {
      return this.lang === "fr"
        ? displayedValue.text.fr
        : displayedValue.text.en;
    } else {
      return "";
    }
  }

  public informationBoxConfig = {
    backgroundColor: "#EDF7FA",
    borderColor: "1px solid #269ABC",
    icon: "fa-regular fa-circle-info",
    iconColor: "#1F83A1",
    iconAltText: "information icon",
  };
}
