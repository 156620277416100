<div class="header">
  <h1 class="header__page emphasis">
    {{ "SIGNUP.VERIFICATION.PAGE_TITLE" | translate }}
  </h1>
  <h2 class="emphasis header__form">
    {{ "SIGNUP.VERIFICATION.FORM_HEADER" | translate }}
  </h2>
  <p class="header__description">
    {{ "SIGNUP.VERIFICATION.FORM_DESCRIPTION" | translate }}
  </p>
</div>

<div class="form">
  <p class="form__account">
    {{ "SIGNUP.VERIFICATION.FIELDS.EMAIL" | translate }}
  </p>
  <span class="form__account-email">
    <strong>{{ this.email }}</strong>
  </span>
  <p class="form__account">
    {{ "SIGNUP.VERIFICATION.FIELDS.VERIFICATION_CODE" | translate }}
  </p>
  <form [formGroup]="verificationForm">
    <fieldset>
      <lib-form-input
        identifier="code"
        type="text"
        [formControl]="verificationForm.controls.code"
        [label]="'SIGNUP.VERIFICATION.FIELDS.VERIFICATION_LABEL' | translate"
        required="true"
        (keyup.enter)="onConfirmSignUp()"
      >
        <span
          *ngIf="
            verificationForm.controls.code.touched &&
            verificationForm.controls.code.errors?.required
          "
        >
          {{ "SIGNUP.ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-input>
    </fieldset>
  </form>

  <p>
    {{ "SIGNUP.VERIFICATION.NO_CODE" | translate }}
    <button category="link" (click)="resendCode()">
      {{ "SIGNUP.VERIFICATION.RESEND_BUTTON" | translate }}
    </button>
  </p>
  <p>
    {{ "SIGNUP.VERIFICATION.INCORRECT_EMAIL" | translate }}
    <button
      role="link"
      category="link"
      [routerLink]="routeLocalizer.get(currentLang, 'CLIENT_SIGNUP', '../')"
    >
      {{ "SIGNUP.VERIFICATION.CREATE_BUTTON" | translate }}
    </button>
  </p>
  <br />
  <!-- Create account -->
  <button
    [disabled]="!verificationForm.valid"
    class="form__button btn btn-primary"
    (click)="onConfirmSignUp()"
  >
    {{ "SIGNUP.VERIFICATION.SUBMIT" | translate }}
  </button>
</div>
