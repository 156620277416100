<h1 class="page__title emphasis">{{ title | translate }}</h1>
<p>{{ infoLine | translate }}</p>
<div class="spinner" *ngIf="isLoading">
  <lib-spinner [showSpinner]="isLoading" [fullPage]="true"></lib-spinner>
</div>

<div [hidden]="isLoading">
  <pra-rep-info #repInfoForm [accountInfo]="repInfo"></pra-rep-info>
  <pra-rep-identification-upload-table
    [accountInfo]="repInfo"
    (proofOfIdentificationUploaded)="updatedDoclinkForRep($event, true)"
  ></pra-rep-identification-upload-table>
  <div *ngIf="!isAccountCreated" class="form__terms checkbox-control">
    <input
      value="true"
      id="terms"
      name="terms"
      [formControl]="form.controls.terms"
      type="checkbox"
    />
    <label
      [innerHTML]="'SIGNUP.FORM.FIELDS.TERMS' | translate"
      for="terms"
    ></label>
  </div>

  <lib-layout-row mt="3">
    <ng-template #update>
      <button
        [disabled]="!isRepInfoFormValid"
        type="button"
        (click)="updateRepAccountDetails()"
      >
        {{ "REP_ACCOUNT_DETAIL.SAVE_BUTTON" | translate }}
      </button>
    </ng-template>
  </lib-layout-row>
  <lib-layout-row mt="3">
    <button
      *ngIf="!isAccountCreated; else update"
      [disabled]="!isRepInfoFormValid"
      type="button"
      (click)="createRepAccountDetails()"
    >
      {{ "INTAKE.FORM_PAGE_SAVE" | translate }}
    </button>
  </lib-layout-row>
</div>
