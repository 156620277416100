<div class="side-nav jds-escape">
  <div class="side-nav__go-back" *ngIf="hasBackButton">
    <a (click)="formNavbarNavigateBack()" class="side-nav__slide-left">
      <span> < </span>
      {{ formNavbarBackButtonTitle | translate }}
    </a>
    <span *ngIf="isLoading" class="side-nav__skeleton"></span>
  </div>

  <ul class="side-nav__list">
    <li
      *ngFor="let item of items; let i = index"
      class="side-nav__list-item"
      [ngClass]="{
        active: i === curr - 1 && isLoading === false,
        hover: i < curr - 1,
        disabled: i > curr - 1
      }"
    >
      <ng-container *ngIf="i < curr - 1; else after_curr">
        <a
          *ngIf="!hasBackButton"
          [routerLink]="routeLocalizer.get(lang, item.link, '../')"
          class="side-nav__anchor"
        >
          <span class="side-nav__check-icon">
            <lib-icon-chooser icon="check"></lib-icon-chooser>
          </span>
          {{ item.name | translate }}
        </a>
        <a
          *ngIf="hasBackButton"
          class="side-nav__anchor"
          (click)="formSideNavbarNavigation(i)"
        >
          <span class="side-nav__check-icon">
            <lib-icon-chooser icon="check"></lib-icon-chooser>
          </span>
          {{ item.name | translate }}
        </a>
      </ng-container>

      <ng-template #after_curr>
        <div
          class="side-nav__anchor"
          [class.side-nav__anchor--disabled]="i !== curr - 1"
        >
          <span class="side-nav__number-icon" [class.active]="i === curr - 1">{{
            i + 1
          }}</span>
          <span>
            {{ item.name | translate }}
          </span>
        </div>
      </ng-template>
      <span *ngIf="isLoading" class="side-nav__skeleton"></span>
    </li>
  </ul>
</div>
