import { Component, OnInit, OnDestroy } from "@angular/core";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { DocumentService } from "@pr-applicant/app/shared/services/document/document.service";
import {
  uploadFileTypes,
  documentSizes,
  ValidationService,
  AlertService,
  ModalService,
  documentTypes,
} from "lib";
import { Subscription, filter } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { Case } from "../../../../shared/case-module/case.model";
import * as CaseActions from "../../../../shared/case-module/store/case.actions";
import { TranslateService } from "@ngx-translate/core";
import { DownloadService } from "../../../../shared/services/download.service";
import { Document } from "../../../../core/models/document.model";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-wet-signature-declaration",
  templateUrl: "./wet-signature-declaration.component.html",
  styleUrls: ["./wet-signature-declaration.component.scss"],
})
export class WetSignatureDeclarationComponent implements OnInit, OnDestroy {
  public case: any;
  public caseId: string;
  public caseStatusId: number;
  public caseDocuments: any;
  public consentAndDeclarationDoc: Document;

  public acceptedFileTypes = uploadFileTypes.applicationForms.types;
  public maxFileNameLength = 180;
  public maxFileSize = documentSizes.forms.maxFileSize;
  public megaFileConversion = documentSizes.forms.megaFileConversion;
  public currentLang: string;

  public file_size: string;
  public file_type: string;
  public file_name_length: string;
  public fileValid: boolean = false;
  public fileSelected: any;

  public ERROR_COPY = [
    {
      FILE_SIZE: {
        en: "File size exceeds the maximum of 4 MB.",
        fr: "La taille du fichier dépasse le maximum de 4 Mo.",
      },
      FILE_TYPE: {
        en: "Your document file type does not meet the required criteria.",
        fr: "Votre type de document ne répond pas aux critères requis.",
      },
      FILE_NAME_LENGTH: {
        en: "File name exceeds the maximum length of 180 characters.",
        fr: "Le nom du fichier dépasse la longueur maximale de 180 caractères.",
      },
      FILE_NAME_CHARACTERS: {
        en: "The name of the selected file contains invalid characters. Please ensure that files meet all the upload requirements indicated under the Application forms table.",
        fr: "Le nom du fichier sélectionné contient des caractères non valides. Veuillez-vous assurer que les fichiers répondent à toutes les exigences de téléchargement indiquées sous le tableau des formulaires de demande.",
      },
    },
  ];
  public subscriptions: Subscription[] = [];
  constructor(
    public routeLocalizer: RouteLocalizerService,
    public validationService: ValidationService,
    public documentService: DocumentService,
    public store: Store<fromApp.State>,
    public caseService: CaseService,
    public alertService: AlertService,
    public translate: TranslateService,
    public downloadService: DownloadService,
    public modalService: ModalService,
    public user: UserService
  ) {}
  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(filter((caseData: any) => caseData?.id !== ""))
        .subscribe((caseData) => {
          if (caseData) {
            this.case = caseData;
            this.caseId = this.case.id;
            this.caseStatusId = this.case.caseStatusId;
            this.caseDocuments = this.case.documents;
            this.consentAndDeclarationDoc = this.caseDocuments?.find(
              (doc: any) => {
                if (doc.documentTypeId === 305) {
                  return doc;
                }
              }
            );
            if (this.consentAndDeclarationDoc) {
              this.setAttachmentStatusPill(
                "success",
                this.consentAndDeclarationDoc?.documentName?.split("/").pop() ??
                  ""
              );
            } else {
              this.setAttachmentStatusPill();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public attachmentState = "";
  public attachmentName = "";
  public attachmentError = "";

  public async uploadFile(e: { target?: HTMLInputElement }): Promise<any> {
    if (this.consentAndDeclarationDoc) {
      this.alertService.danger(this.alertFileLimitReached);
      return;
    }
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.setAttachmentStatusPill("loading", file.name || "");
    this.fileValid = this.isFileValid(file);
    if (this.fileValid) {
      this.fileSelected = file;
      try {
        // upload
        await this.documentService.uploadNewFileByDocumentTypeId(
          this.caseId,
          this.fileSelected,
          documentTypes.wetSignature.id
        );
        //replenishing caseDocuments property in store as completeness of case uses both caseDocuments and documents property in store
        await this.documentService.getDocumentByCaseId(this.caseId);
        this.updateCaseInfo(this.caseId);

        this.setAttachmentStatusPill();
        this.alertService.success(this.alertSuccess);
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
  }

  public isFileValid(file: File): boolean {
    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;
    const fileSizeValid = file && file.size <= this.maxFileSize;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);
    const maxFileNameLength = file.name.length <= this.maxFileNameLength;
    const validFileNameForUpload =
      this.validationService.isValidFileNameForUpload(file.name);
    if (
      !fileSizeValid ||
      !fileTypeValid ||
      !maxFileNameLength ||
      !validFileNameForUpload
    ) {
      if (this.currentLang === "en") {
        const en_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.en),
            (this.file_type = item.FILE_TYPE.en),
            (this.file_name_length = item.FILE_NAME_LENGTH.en);
        });
      } else {
        this.currentLang === "fr";
        const fr_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.fr),
            (this.file_type = item.FILE_TYPE.fr),
            (this.file_name_length = item.FILE_NAME_LENGTH.fr);
        });
      }
      if (fileSizeValid === false) {
        this.setTempFileErrorMessage(this.file_size);
      }
      if (validFileNameForUpload === false) {
        if (this.currentLang === "en") {
          this.setTempFileErrorMessage(
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.en
          );
        } else {
          this.setTempFileErrorMessage(
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.fr
          );
        }
      }
      if (fileTypeValid === false) {
        this.setTempFileErrorMessage(this.file_type);
      }
      if (maxFileNameLength === false) {
        this.setTempFileErrorMessage(this.file_name_length);
      }
      return false;
    }
    return true;
  }

  public setTempFileErrorMessage(error: string) {
    this.attachmentState = "error";
    this.attachmentError = error;
  }

  public updateCaseInfo(caseId: string) {
    this.caseService
      .getCase(caseId)
      .then((data: Case) => {
        this.store.dispatch(new CaseActions.SetCaseByEmail(data));
      })
      .catch((err) => {
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  public get alertSuccess(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_SUCCESS");
  }
  public get alertDeleteSuccess(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.DELETE_SUCCESS");
  }
  public get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.TABLE_ALERT.UPLOAD_ERROR");
  }
  public get alertFileLimitReached(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }

  public async downloadFile(document: Document) {
    if (!document) return;
    const downloadName = document.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)
    try {
      await this.downloadService.downloadDocument(
        document.caseId,
        document.id,
        downloadName
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }
  public deleteModalId = "confirmDeleteWetSignature";
  public openDeleteModal(document?: Document): void {
    this.modalService.open(this.deleteModalId);
  }
  public closeModal(): void {
    this.modalService.close(this.deleteModalId);
  }

  public async deleteFile(): Promise<any> {
    if (!this.fileValid) {
      this.setAttachmentStatusPill();
      this.closeModal();
    }
    if (this.consentAndDeclarationDoc) {
      try {
        //this.isLoading = true;
        await this.documentService.deleteFileByDocumentTypeAndId(
          this.caseId,
          Number(this.consentAndDeclarationDoc.documentTypeId),
          this.consentAndDeclarationDoc.id
        );
        this.setAttachmentStatusPill();
        this.alertService.success(this.alertDeleteSuccess);
        this.updateCaseInfo(this.caseId);
        //this.isLoading = false;
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        //this.isLoading = false;
      }
      this.closeModal();
    }
  }

  public downloadConsentDoc(event: Event) {
    let link = document.createElement("a");
    event.preventDefault();
    link.download = this.translate.instant(
      "INTAKE.DECLARATION.WET_SIGNATURE.DOCUMENT_NAME"
    );
    link.href = this.translate.instant(
      "INTAKE.DECLARATION.WET_SIGNATURE.DOCUMENT_LOCATION"
    );
    link.click();
  }

  public setAttachmentStatusPill(
    state = "",
    attachmentName = "",
    attachmentError = ""
  ) {
    this.attachmentName = attachmentName;
    this.attachmentState = state;
    this.attachmentError = attachmentError;
  }

  public get disableUpload() {
    return (
      !this.user.can("signature:write") || this.case.paWillSignWebform === null
    );
  }

  public get showWarningIndicateInvitePA() {
    return this.case.paWillSignWebform === null;
  }
}
