import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "lib-accordion-item",
  template: `
    <div class="accordion__container jds-escape">
      <!-- header -->
      <button
        class="accordion__head"
        [disabled]="this.isDisabled"
        category="plain"
        (click)="toggle($event)"
        attr.aria-controls="region-{{ name }}"
        attr.aria-expanded="{{ active }}"
      >
        <ng-content select="[accordionHeader]"></ng-content>
        <img
          class="collapse-icon"
          alt=""
          [ngClass]="{ 'flip-icon': active === false }"
          width="32"
          src="../../../../../../../assets/images/icons/circle-collapse.svg"
        />
      </button>
      <!-- body -->
      <div
        class="accordion__body"
        [class.active]="active"
        id="region-{{ name }}"
      >
        <div class="accordion__body-container">
          <ng-content select="[accordionBody]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .accordion__container {
        position: relative;
        max-height: inherit;
      }
      .accordion__head {
        cursor: pointer;
        background: #f3f3f3;
        border: 1px solid #dedede;
        color: #245269;
        height: 85px;
        width: 100%;
        display: flex;
        align-items: center;
      }
      .accordion__head:focus {
        outline: 1px solid #0a75c6;
      }
      .accordion__head:disabled {
        cursor: not-allowed;
      }
      .accordion__body {
        display: none;
      }
      .accordion__body.active {
        display: block;
        -webkit-animation: fadeIn 0.2s;
        animation: fadeIn 0.2s;
      }
      .accordion__body-container {
        display: flex;
        flex-direction: column;
      }
      .collapse-icon {
        margin-right: 10px;
      }
      .flip-icon {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      @media screen and (min-width: 992px) {
        .accordion__head {
          height: 64px;
        }
        .accordion__body-container {
          display: flex;
          flex-direction: row;
        }
      }
    `,
  ],
})
export class AccordionItemComponent implements OnInit, OnChanges {
  @Input() active: boolean;

  // Accordion name everything else will be projected using use ng-content
  @Input() name: string;
  @Input() isDisabled: boolean;
  @Input() formError: boolean;

  public noFormError: true;

  constructor(private route: ActivatedRoute, private router: Router) {}

  public get lang(): string {
    return this.router.url?.split("/")[1];
  }

  ngOnInit(): void {
    if (this.isDisabled) {
      this.active = false;
    } else {
      this.active = true;
    }
  }

  ngOnChanges(): void {
    if (this.isDisabled) {
      this.active = false;
    } else {
      this.active = true;
    }
  }

  toggle(event: Event): void {
    event.preventDefault();
    if (!this.isDisabled) {
      // can toggle only if accordion is not disabled
      this.active = !this.active;
    }
  }
}
