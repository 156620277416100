import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { UserGroup } from "lib";
import { map, tap } from "rxjs/operators";
import * as fromAuthActions from "./auth.actions";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as CaseActions from "../../../shared/case-module/store/case.actions";
import * as CasesCountActions from "../../../store/CasesCount/CasesCount.actions";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import routePaths from "@pr-applicant/app/routing/route-paths";

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions<fromAuthActions.AuthActions>,
    private router: Router,
    private store: Store<fromApp.State>,
    private routeLocalizer: RouteLocalizerService
  ) {}

  Login$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.LOGIN),
        map((action) => action.payload),
        tap((payload) => {
          if (
            payload.userPool === UserGroup.Client ||
            payload.userPool === UserGroup.Rep
          ) {
            if (payload.isFirstLogin) {
              this.routeLocalizer.goTo(this.currentLang, "FIRST_RESET");
            } else {
              this.router.navigateByUrl(`/${this.currentLang}`);
            }
          } else {
            const lang = this.currentLang === "fr" ? "fr" : "en";
            // navigate to home as well
            // this.router.navigateByUrl(`/${this.currentLang}/${routePaths[lang].INTAKE}/${routePaths[lang].INTAKE_DASHBOARD}`);
            this.router.navigateByUrl(`/${this.currentLang}`);
          }
        })
      ),
    { dispatch: false }
  );

  FirstLogin$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.FIRST_LOGIN_COMPLETE),
        tap(() => {
          this.routeLocalizer.goTo(this.currentLang, "LOGIN");
        })
      ),
    { dispatch: false }
  );

  Logout$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.LOGOUT),
        tap(() => {
          this.store.dispatch(new CaseActions.ResetCaseInfo());
          this.store.dispatch(new CasesCountActions.ResetCasesCount());
        })
      ),
    { dispatch: false }
  );

  // I use this createEffect sintax because when you will upgrade the project
  // to next Angular versions above sintax based on decorators is deprecated
  startLogoutEffect$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromAuthActions.START_LOGOUT),
        tap(() => {
          this.routeLocalizer.goTo(this.currentLang, "LOGIN");
        })
      ),
    { dispatch: false }
  );

  private get currentLang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }
}
