import { Injectable } from "@angular/core";
import { BehaviorSubject, from, switchMap, Observable } from "rxjs";
import { environment } from "@pr-applicant/environments/environment";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";

export interface ISponsorProfile {
  currentCase: {
    caseId: string | null;
    lobId: number | null;
  };
  lobs: number[];
  isComplete: boolean;
  user: {
    givenName: string | null;
    familyName: string | null;
  };
}

export const defaultSponsorProfile: ISponsorProfile = {
  user: {
    givenName: null,
    familyName: null,
  },
  lobs: [45, 46, 47, 48],
  isComplete: false,
  currentCase: {
    caseId: null,
    lobId: null,
  },
};

@Injectable({
  providedIn: "root",
})
export class SponsorProfileService {
  private apiVersion = environment.API_VERSION;
  private apiName = environment.API_NAME;
  private _sponsorSubj: BehaviorSubject<any> = new BehaviorSubject(
    defaultSponsorProfile
  );
  private _sponsorObs$: Observable<ISponsorProfile> =
    this._sponsorSubj.asObservable();

  constructor(private awsService: AWSService) {
    this.updateSponsor(defaultSponsorProfile);
  }

  public get sponsorObs$() {
    return this._sponsorObs$;
  }

  updateSponsor(data: Partial<ISponsorProfile>) {
    this._sponsorSubj.next({
      ...this._sponsorSubj.value,
      ...data,
    });
  }

  getSponsorProfile() {
    const path = `/${this.apiVersion}/member`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
        };
        return from(this.awsService.api.get(this.apiName, path, httpOptions));
      })
    );
  }

  addMember(user: any): Observable<any> {
    const path = `/${this.apiVersion}/member`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
          body: {
            ...user,
          },
        };
        return from(this.awsService.api.put(this.apiName, path, httpOptions));
      })
    );
  }
}
