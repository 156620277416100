import { Injectable } from "@angular/core";
import { environment } from "@pr-applicant/environments/environment";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { from, Observable, Subject, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";

export interface ICaseProfileModel {
  caseId: String;
  profile: IProfileModel;
}

export interface IProfileKey {
  [key: string]: any;
}

export interface IProfileModel extends IProfileKey {
  profileVersion: number;
  isComplete: string;
  correspondence: string;
  familyName: string;
  givenName: string;
  dob: string;
  residentialSameAsMailingAddress: number;
  mailingAddress: {
    postOfficeBox: string;
    apartmentUnit: string;
    streetNumber: string;
    streetName: string;
    province: string;
    country: string;
    city: string;
    postalCode: string;
    district: string;
  };
  residentialAddress: {
    postOfficeBox: string;
    apartmentUnit: string;
    streetNumber: string;
    streetName: string;
    province: string;
    country: string;
    city: string;
    postalCode: string;
    district: string;
  };
  dependants: Array<IDependantModel>;
}

export interface IDependantModel {
  relationshipToPA: string;
  accompanyingPA: boolean | null;
  familyName: string;
  givenName: string;
  dob: string;
  id: string;
}

export const INITIAL_PROFILE: ICaseProfileModel = {
  caseId: "",
  profile: {
    profileVersion: 0,
    isComplete: "",
    correspondence: "",
    familyName: "",
    givenName: "",
    dob: "",
    residentialSameAsMailingAddress: 0,
    mailingAddress: {
      postOfficeBox: "",
      apartmentUnit: "",
      streetNumber: "",
      streetName: "",
      province: "",
      country: "",
      city: "",
      postalCode: "",
      district: "",
    },
    residentialAddress: {
      postOfficeBox: "",
      apartmentUnit: "",
      streetNumber: "",
      streetName: "",
      province: "",
      country: "",
      city: "",
      postalCode: "",
      district: "",
    },
    dependants: [],
  },
};

export const INITIAL_DEPENDANT_PROFILE: IDependantModel = {
  relationshipToPA: "",
  accompanyingPA: null,
  familyName: "",
  givenName: "",
  dob: "",
  id: "",
};

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private apiVersion = environment.API_VERSION;
  private apiName = environment.API_NAME;
  private _cachedProfile: ICaseProfileModel;
  private _profileSubj: Subject<any> = new Subject();
  private _profileObs$: Observable<ICaseProfileModel> =
    this._profileSubj.asObservable();

  constructor(
    private awsService: AWSService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  public get profileObs$() {
    return this._profileObs$;
  }

  public get cachedProfile() {
    return this._cachedProfile;
  }

  getProfile(caseId: any) {
    const path = `/${this.apiVersion}/cases/${caseId}/profile`;
    from(this.awsService.getToken())
      .pipe(
        switchMap((token) => {
          const httpOptions = {
            headers: {
              Authorization: token,
            },
            observe: "body",
          };
          return this.awsService.api.get(this.apiName, path, httpOptions);
        }),
        map((response) => response.form),
        tap((profile) => {
          this._cachedProfile = { caseId: caseId, profile: profile };
          this._profileSubj.next({ caseId: caseId, profile: profile });
        }),
        catchError((error) => {
          console.log("Get Profile Error: ", error);
          this.alertService.danger(
            this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
          );
          return this._profileSubj;
        })
      )
      .subscribe();
  }

  updateProfile(form: any, caseId: any) {
    const path = `/${this.apiVersion}/cases/${caseId}/profile`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
          body: { form: form },
        };
        return this.awsService.api.put(this.apiName, path, httpOptions);
      }),
      catchError((error) => {
        console.log("Update Profile Error", error.response.status);
        //const apiError = new Error(error);
        //if (apiError.message.match("409")) {
        if (error.response.status === 409) {
          this.alertService.danger(
            this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
          );
        } else {
          this.alertService.danger(
            this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
          );
        }
        return this._profileSubj;
      })
    );
  }

  deleteDependantProfile(caseId: any, dependantId: any) {
    console.log("delete call");
    const path = `/${this.apiVersion}/cases/${caseId}/profile/${dependantId}`;
    return from(this.awsService.getToken()).pipe(
      switchMap((token) => {
        const httpOptions = {
          headers: {
            Authorization: token,
          },
          observe: "body",
        };
        return this.awsService.api.del(this.apiName, path, httpOptions);
      }),
      map((response) => response.form),
      tap((profile) => {
        this._cachedProfile = { caseId: caseId, profile: profile };
        this._profileSubj.next({ caseId: caseId, profile: profile });
        this.alertService.success(
          this.translate.instant(
            "INTAKE.PROFILE.DELETE_DEPENDANT_MODAL.DELETE_SUCCESS"
          )
        );
        return true;
      }),
      catchError((error) => {
        this.alertService.danger(
          this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
        );
        return this._profileSubj;
      })
    );
  }
}
