import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-f",
  templateUrl: "./imm5669-section-f.component.html",
  styleUrls: ["./imm5669-section-f.component.scss"],
})
export class IMM5669SectionFComponent implements OnInit, OnDestroy {
  private currentForm: any;
  private storeSubsc$: Subscription;
  private formData: any;

  private buttonClicked: boolean = false;

  public maxFieldLengths: { [key: string]: number } = {
    from: 7,
    to: 7,
    cityAndCountry: 29,
    department: 30,
    activities: 21,
  };

  public governmentPositions: UntypedFormArray = new UntypedFormArray([]);
  public isReadOnly = !this.user.can("documents:write");

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    public translate: TranslateService,
    private validationService: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.formData = currentImmDoc?.currentForm?.form?.sectionF;
        if (
          this.formData?.governmentPositions &&
          this.formData?.governmentPositions.length > 0
        ) {
          this.setFormValues();
        } else {
          this.governmentPositions.push(this.createGovernmentPositionForm());
        }
      });
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  public get isFormValid(): boolean {
    return this.governmentPositions.valid;
  }

  public addJobPosition(): void {
    if (
      this.governmentPositions.length <
      DOC_LIMITS.IMM5669.GOVERNMENT_POSITIONS_LIMIT
    ) {
      this.buttonClicked = true;
      this.governmentPositions.push(this.createGovernmentPositionForm());
    } else {
      this.alertService.danger(this.alertMaxGovernmentPosition);
    }
  }

  public removeJobPosition(index: number): void {
    this.buttonClicked = true;
    this.governmentPositions.removeAt(index);
  }
  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.governmentPositions.touched || this.buttonClicked;
  }

  public getFormData(): any {
    if (
      (this.governmentPositions.controls as FormControl[]).find(
        (fc) => fc.invalid && fc.touched
      )
    ) {
      return { sectionName: "sectionF", isInvalid: true };
    }
    const governmentPositions = this.governmentPositions.getRawValue();
    let result = _.cloneDeep(this.currentForm);
    const fromAPISectionF = result.form.sectionF;
    result = {
      sectionName: "sectionF",
      sectionInfo: { ...fromAPISectionF, governmentPositions },
    };
    return result;
  }

  /* PRIVATE ---------------------------------------------------- */

  private get alertMaxGovernmentPosition(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  private createGovernmentPositionForm(): FormGroup {
    return this.fb.group({
      from: [null, Validators.maxLength(this.maxFieldLengths.from)],
      to: [null, Validators.maxLength(this.maxFieldLengths.to)],
      cityAndCountry: [
        null,
        Validators.maxLength(this.maxFieldLengths.cityAndCountry),
      ],
      department: [null, Validators.maxLength(this.maxFieldLengths.department)],
      activities: [null, Validators.maxLength(this.maxFieldLengths.activities)],
    });
  }

  private setFormValues(): void {
    this.governmentPositions.clear();
    const savedData = this.formData?.governmentPositions;
    savedData?.forEach((instance: any) => {
      const fields: FormGroup = this.createGovernmentPositionForm();
      fields.setValue({
        from: instance.from,
        to: instance.to,
        cityAndCountry: instance.cityAndCountry,
        department: instance.department,
        activities: instance.activities,
      });
      this.governmentPositions.push(fields);
    });
  }
}
