import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "lib-navigation-buttons",
  template: `
    <div class="buttons-container">
      <button (click)="handleBack()" category="secondary">
        {{ backCopy }}
      </button>
      <button
        [disabled]="disableNext"
        (click)="handleNext()"
        category="primary"
      >
        {{ nextCopy }}
      </button>
    </div>
  `,
  styles: [
    `
      .buttons-container {
        display: flex;
        gap: 1.5rem;
        margin: 3rem 0 2rem 0;
      }

      @media (max-width: $md-breakpoint - 1) {
        .buttons-container {
          flex-direction: column;
        }
      }
    `,
  ],
})
export class NavigationButtonsComponent {
  @Input() disableNext?: boolean = false; // optional
  @Input() nextCopy?: string;
  @Input() backCopy?: string;

  @Output() clickNextButton: EventEmitter<any> = new EventEmitter();
  @Output() clickBackButton: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public handleNext(): void {
    this.clickNextButton.emit();
  }

  public handleBack(): void {
    this.clickBackButton.emit();
  }
}
