import { Component } from "@angular/core";

@Component({
  selector: "pra-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  constructor() {}

  scrollToTop(e: Event) {
    //  prevents page from reloading
    e.preventDefault();
    //  scrolls to top of window
    window.scroll(0, 0);
  }
}
