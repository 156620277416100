<section class="manage-group-members">
  <lib-layout-row mb="2">
    <header *ngIf="!isGAR">
      <h1>
        <ng-container *ngIf="canEditGroupMembers">
          {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.PAGE_HEADING" | translate }}
        </ng-container>
        <ng-container *ngIf="!canEditGroupMembers">
          {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.PAGE_HEADING_VIEW" | translate }}
        </ng-container>
      </h1>
      <p>
        <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
          {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.PAGE_DESCRIPTION" | translate }}
        </ng-container>
        <ng-container *ngIf="!canEditGroupMembers">
          {{
            "PSR.MANAGE_GROUP_MEMBERS_PAGE.PAGE_DESCRIPTION_VIEW" | translate
          }}
        </ng-container>
      </p>
    </header>
  </lib-layout-row>
  <section>
    <lib-spinner [showSpinner]="isProcessing" [fullPage]="false"></lib-spinner>
    <form
      focusInvalidInput
      [formGroup]="groupMembersForm"
      autocomplete="off"
      *ngIf="!isProcessing"
    >
      <!-- GAR -->
      <ng-container *ngIf="isGAR; else default">
        <ng-container [ngTemplateOutlet]="invitePATemplate"></ng-container>
      </ng-container>

      <ng-template #default>
        <ng-container [ngTemplateOutlet]="groupMembersTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="invitePATemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="invitePARepTemplate"></ng-container>
      </ng-template>

      <pra-form-buttons [form]="groupMembersForm"
        ><button type="submit" category="primary" (click)="validateForm()">
          {{ "INTAKE.FORM_PAGE_NEXT" | translate }}
        </button>
      </pra-form-buttons>
    </form>
  </section>
</section>

<!-- templates -->

<ng-template #groupMembersTemplate>
  <fieldset>
    <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
      <legend>
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_GROUP_MEMBER_HEADING"
            | translate
        }}
      </legend>
      <p>
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_GROUP_MEMBER_DESCRIPTION"
            | translate
        }}
      </p>
      <div>
        <button
          [routerLink]="
            routeLocalizer.getNestedPath(lang, ['INVITE_GROUP_MEMBER'], '../')
          "
          type="button"
          category="secondary"
          role="link"
        >
          {{
            "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_GROUP_MEMBER_BUTTON"
              | translate
          }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="groupMembers && groupMembers.length > 0">
      <section class="manage-group-members__cards">
        <pra-member-card
          *ngFor="let member of groupMembers; let i = index"
          [member]="member"
          [caseMemberId]="member.caseMemberId"
          [index]="i"
          [showActions]="canEditGroupMembers"
          [canDelete]="canInviteDeleteMembers"
          [showMoreDetails]="
            member.emailAddress === user.currentMember.emailAddress ||
            canEditGroupMembers
          "
          (removeGroupMember)="removeGroupMember($event)"
        ></pra-member-card>
      </section>
    </ng-container>
    <ng-container *ngIf="isG5Warning && canEditGroupMembers">
      <div class="manage-group-members__g5-warning">
        <ircc-cl-lib-banner [config]="bannerConfig"></ircc-cl-lib-banner>
      </div>
    </ng-container>
  </fieldset>
</ng-template>
<ng-template #invitePATemplate>
  <fieldset>
    <legend>
      <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_HEADING"
            | translate
        }}
      </ng-container>
      <ng-container *ngIf="!canEditGroupMembers || !canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_HEADING_VIEW"
            | translate
        }}
      </ng-container>
    </legend>
    <p>
      <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_DESCRIPTION"
            | translate
        }}
      </ng-container>
      <ng-container *ngIf="!canEditGroupMembers || !canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_DESCRIPTION_VIEW"
            | translate
        }}
      </ng-container>
    </p>
    <div
      *ngIf="
        !principalApplicant && canEditGroupMembers && canInviteDeleteMembers
      "
    >
      <lib-form-radio
        [editable]="!submitting"
        [legend]="
          'PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_RADIO.TITLE'
            | translate
        "
        nameVal="paWillSignWebform"
        [formControl]="groupMembersForm.controls.paWillSignWebform"
        [required]="true"
        [options]="[
          {
            id: 'yes',
            label:
              'PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_RADIO.YES'
              | translate,
            value: true
          },
          {
            id: 'no',
            label:
              'PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_RADIO.NO'
              | translate,
            value: false
          }
        ]"
      >
        <span
          *ngIf="
            groupMembersForm.controls?.paWillSignWebform.touched &&
            groupMembersForm.controls?.paWillSignWebform.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-radio>
      <ng-container
        *ngIf="groupMembersForm.controls?.paWillSignWebform.value === false"
      >
        <span>
          <div class="manage-group-members__signature-info">
            <ircc-cl-lib-banner
              [config]="signatureBannerConfig"
            ></ircc-cl-lib-banner>
          </div>
        </span>
      </ng-container>
    </div>
    <div
      *ngIf="
        !principalApplicant && canEditGroupMembers && canInviteDeleteMembers
      "
    >
      <button
        type="button"
        category="secondary"
        role="link"
        [disabled]="!groupMembersForm.controls?.paWillSignWebform.value"
        [routerLink]="
          routeLocalizer.getNestedPath(
            lang,
            ['INVITE_PRINCIPAL_APPLICANT'],
            '../'
          )
        "
      >
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_BUTTON"
            | translate
        }}
      </button>
    </div>
    <div
      *ngIf="
        !principalApplicant &&
        groupMembersForm.controls?.paWillSignWebform.value
      "
      class="manage-group-members__pa-sig-warning"
    >
      <ircc-cl-lib-banner
        [config]="paSignatureBannerConfig"
      ></ircc-cl-lib-banner>
    </div>
    <div *ngIf="principalApplicant && principalApplicant">
      <lib-card maxWidth="30rem">
        <div class="manage-group-members__card">
          <div class="manage-group-members__title">
            <div class="manage-group-members__pill">
              <lib-status-pill
                [type]="'complete'"
                [text]="'Complete' | translate"
              ></lib-status-pill>
            </div>
            <h5 class="emphasis">
              {{
                principalApplicant.givenName
                  ? principalApplicant.givenName +
                    " " +
                    principalApplicant.familyName
                  : principalApplicant.familyName
              }}
            </h5>
            <p>
              {{
                "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_TITLE"
                  | translate
              }}
            </p>
          </div>
          <p>{{ principalApplicant.emailAddress }}</p>
          <pra-resend-password-button
            *ngIf="principalApplicant.canResendPassword"
            [caseId]="caseId"
            [caseMemberId]="principalApplicant.caseMemberId"
            [removeHoverEffect]="true"
          ></pra-resend-password-button>
        </div>
      </lib-card>
    </div>
  </fieldset>
</ng-template>
<ng-template #invitePARepTemplate>
  <fieldset>
    <legend>
      <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_REP_HEADING"
            | translate
        }}
      </ng-container>
      <ng-container *ngIf="!canEditGroupMembers || !canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_REP_HEADING_VIEW"
            | translate
        }}
      </ng-container>
    </legend>
    <p>
      <ng-container *ngIf="canEditGroupMembers && canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_REP_DESCRIPTION"
            | translate
        }}
      </ng-container>
      <ng-container *ngIf="!canEditGroupMembers || !canInviteDeleteMembers">
        {{
          "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_REP_DESCRIPTION_VIEW"
            | translate
        }}
      </ng-container>
    </p>
    <ng-container
      *ngIf="showRepButton && canEditGroupMembers && canInviteDeleteMembers"
    >
      <div>
        <button
          type="button"
          category="secondary"
          role="link"
          [routerLink]="
            routeLocalizer.getNestedPath(
              lang,
              ['INVITE_PRINCIPAL_APPLICANTS_REP'],
              '../'
            )
          "
        >
          {{
            "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANT_REP_BUTTON"
              | translate
          }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!showRepButton && principalApplicantsRep">
      <lib-card maxWidth="30rem">
        <div class="manage-group-members__card">
          <div class="manage-group-members__title">
            <div class="manage-group-members__pill">
              <lib-status-pill
                [type]="'complete'"
                [text]="'Complete' | translate"
              ></lib-status-pill>
            </div>
            <h5 class="emphasis">
              {{
                principalApplicantsRep.givenName
                  ? principalApplicantsRep.givenName +
                    " " +
                    principalApplicantsRep.familyName
                  : principalApplicantsRep.familyName
              }}
            </h5>
            <p>
              {{
                "PSR.MANAGE_GROUP_MEMBERS_PAGE.INVITE_PRINCIPAL_APPLICANTS_REP_TITLE"
                  | translate
              }}
            </p>
          </div>
          <p>{{ principalApplicantsRep.emailAddress }}</p>
          <pra-resend-password-button
            *ngIf="principalApplicantsRep.canResendPassword"
            [caseId]="caseId"
            [caseMemberId]="principalApplicantsRep.caseMemberId"
            [removeHoverEffect]="true"
          ></pra-resend-password-button>
        </div>
      </lib-card>
    </ng-container>
  </fieldset>
</ng-template>
