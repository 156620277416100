import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";
import {
  ControlContainer,
  FormControl,
  AbstractControl,
  FormGroup,
  FormArray,
} from "@angular/forms";

@Directive({
  selector: "[focusInvalidInput]",
})
export class FormDirective {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private container: ControlContainer
  ) {}

  @HostListener("submit")
  onFormSubmit() {
    // maybe best to handle the form submit here to markastouched rather than in parent?
    // doesn't work for nested forms currently
    // const control = this.container.control as FormGroup;
    // this.markAllControlsAsDirty(control);

    const invalidControl = this.el.nativeElement.querySelector(".ng-invalid");

    if (invalidControl) {
      const input = this.findInputElement(invalidControl);
      if (input) {
        this.scrollInputToElement(input);
      }
    }
  }

  private findInputElement(parentElement: HTMLElement): any {
    const inputs = Array.from(
      parentElement.querySelectorAll("input, select, textarea, radio, span")
    );
    const found = inputs.find((input) =>
      input.classList.contains("ng-invalid")
    );
    return found ? found : null;
  }

  private scrollInputToElement(inputElement: HTMLElement) {
    inputElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
    setTimeout(() => {
      this.renderer.selectRootElement(inputElement, true).focus();
    }, 750);
    //This causes problem with forms with Select as the first input. Further investigation required
    // setTimeout(() => {
    //   this.renderer.selectRootElement(inputElement).focus();
    // }, 750);
  }

  private markAllControlsAsDirty(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((abstractControl) => {
      if (abstractControl instanceof FormControl) {
        abstractControl.markAsDirty({ onlySelf: true });
        abstractControl.markAsTouched({ onlySelf: true });
      } else if (abstractControl instanceof FormGroup) {
        this.markAllControlsAsDirty(abstractControl);
      }
      //   else if (abstractControl instanceof FormArray) {
      //   console.log("formArray", abstractControl);
      //   this.markAllControlsAsDirty((abstractControl as FormArray).controls);
      // }
    });
  }
}
