import * as fromApp from "../../../../store/app.reducer";

import { ActivatedRoute, Router } from "@angular/router";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertService, ValidationService, documentTypes } from "lib";

import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { Case } from "../../../../shared/case-module/case.model";
import { Document } from "../../../../core/models/document.model";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { IMM0008ViewDepandantFormComponent } from "../../../forms/IMM0008/view-dependant-form/view-dependant-form.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import FormNavbarItems from "../imm0008-form-navbar-items.json";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm0008-page9",
  templateUrl: "./page9.component.html",
  styleUrls: [
    "./page9.component.scss",
    "../../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM0008Page9Component
  implements OnInit, AfterViewChecked, OnDestroy
{
  public lang: string;
  public navigationModalId = "navigationModal";

  public isFormTouched: boolean;
  public isFormSaved: boolean;
  public isAddingDependant: boolean = false;
  mobileQuery: MediaQueryList;
  public isLoading: boolean = true;
  public formData: any;
  public imm0008_version: number;
  public caseId: string | number;
  public FormNavbarItems = FormNavbarItems;

  private imm0008PageName: string = "dependantDetails";
  private documentId?: number;

  @ViewChild("viewAndAddDependantsForm", { static: false })
  viewAndAddDependantsForm: IMM0008ViewDepandantFormComponent;
  private _mobileQueryListener: () => void;
  constructor(
    public routeLocalizer: RouteLocalizerService,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    private alertService: AlertService,
    private translate: TranslateService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private validationService: ValidationService,
    media: MediaMatcher,
    private user: UserService
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.getStoreData();
  }

  ngAfterViewChecked() {
    // Detect any changes when the radio buttons change - this solved the error with disabled button
    if (
      this.viewAndAddDependantsForm?.dependantsForm?.controls
        ?.doYouHaveDependants?.valueChanges
    ) {
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  /*----------  PUBLIC ---------*/

  // This method will get the form data
  public async getFormData(): Promise<any> {
    if (this.caseId && this.documentId) {
      try {
        const formData = await this.documentService.getFormByDocumentIdAndPage(
          this.caseId.toString(),
          this.documentId,
          this.imm0008PageName
        );
        this.formData = formData;
        this.imm0008_version = formData.version;
        this.isLoading = false;
      } catch (err) {
        this.alertService.danger(this.alertTechnicalError);
      }
    }
  }

  // This method is to add a new dependant
  public async addNewDependant(): Promise<any> {
    if (this.caseId && this.documentId) {
      this.isAddingDependant = true;
      try {
        // Send an empty body to create a dependant
        const res = await this.documentService
          .addDependantByDocumentIdAndPage(
            this.caseId,
            this.documentId,
            this.imm0008PageName
          )
          .then((data) => {
            const dependantId = data._id;
            const nextPage = this.routeLocalizer.get(
              this.lang,
              "INTAKE_FORM_IMM0008_PAGE9_DEPENDANT",
              "../"
            );
            this.router.navigate([nextPage, dependantId], {
              relativeTo: this.route,
            });
            // this.alertService.success(this.alertNewDependantAddedSuccess);
            this.isAddingDependant = false;
          });
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        this.isAddingDependant = false;
      }
    } else {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  //  Delete dependant based on dependant ID
  public async deleteDependant(dependantId: string): Promise<any> {
    this.isLoading = true;
    try {
      if (this.caseId && this.documentId) {
        const response = await this.documentService
          .deleteFormDependantByDocumentIdAndPage(
            this.caseId,
            this.documentId,
            this.imm0008PageName,
            dependantId
          )
          .then((data) => {
            this.formData = data;
            this.isLoading = false;
          });
        this.alertService.success(this.alertDependantDeletedSuccess);
      }
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
      this.isLoading = false;
    }
  }

  // This method will save form value (The radio button option for this case)
  public async saveFormData(): Promise<any> {
    // Check, if the form isn't touched, don't save and navigate directly
    const isValidForm = !this.viewAndAddDependantsForm?.dependantsForm?.invalid;

    if (!isValidForm) {
      return;
    }

    const formData =
      this.viewAndAddDependantsForm?.dependantsForm?.getRawValue();
    // Submit form if form is valid and data is available to submit
    if (formData && this.documentId) {
      try {
        const response =
          await this.documentService.updateFormByDocumentIdAndPage(
            this.caseId.toString(),
            this.documentId,
            this.imm0008PageName,
            formData,
            this.imm0008_version
          );
        if (response) {
          this.formData = response;
          this.isFormSaved = true;
        }
      } catch (err) {
        // Show an error alert if there is an issue with saving the
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      // Show an error alert if for any reason the form isn't valid, or documentId or FormData has issues
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // This method will check if we can enable/disable the save and complete button
  // 1. Disabled: if form invalid or Yes selected but not dependants added
  // 2. Enabled: if form is valid and if Yes selected, there are dependants added
  public get isFormSavedAndComplete(): boolean {
    this.viewAndAddDependantsForm?.dependantsForm?.updateValueAndValidity();
    if (
      this.viewAndAddDependantsForm?.dependantsForm?.invalid ||
      this.viewAndAddDependantsForm?.anIncompleteApp()
    ) {
      return true;
    } else {
      //  If it's valid and the option selected is Yes, we need to make sure
      // at least one dependant exists
      if (
        this.viewAndAddDependantsForm?.dependantsForm?.controls
          ?.doYouHaveDependants?.value === "true" &&
        this.formData?.form?.dependants?.length === 0
      ) {
        return true;
      }
      return false;
    }
  }

  // This method to be called on 'Save and continue' click and navigate to intake case
  public async onSaveAndCompleteClick(): Promise<any> {
    const nextPage =
      this.routeLocalizer.getNestedPath(this.lang, [
        "INTAKE",
        "INTAKE_CASE_DETAILS",
      ]) + this.caseId;
    this.router.navigateByUrl(`/${this.lang}/${nextPage}`);
    if (this.user.can("documents:write")) {
      this.alertService.success(
        this.translate.instant("INTAKE.ALERTS.DOCUMENTS_SAVED")
      );
    }
  }

  /*----------  PRIVATE ---------*/

  private getStoreData(): void {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.id !== "" && caseData.documents) {
          this.caseId = caseData.id;
          const imm8Document = caseData.documents?.find(
            (doc: Document) => doc.documentTypeId === documentTypes.imm0008.id
          );
          this.documentId = imm8Document?.id;
          this.getFormData();
        }
      });
  }

  private get alertNewDependantAddedSuccess(): string {
    return this.translate.instant(
      "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.ADD_DEPENDANT_ALERT_SUCCESS"
    );
  }

  private get alertDependantDeletedSuccess(): string {
    return this.translate.instant(
      "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.DELETE_DEPENDANT_ALERT_SUCCESS"
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  public get nextButtonKey(): string {
    if (!this.user.can("documents:write")) {
      return "INTAKE.FORM_PAGE_RETURN";
    }
    return "INTAKE.FORM_PAGE_COMPLETE";
  }
}
