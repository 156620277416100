<div>
  <!-- Personal Details component-->
  <ng-container
    *ngIf="selectedPage === '1' && formDetails?.personalDetailsData"
  >
    <h2 class="emphasis">
      {{
        "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.ACCORDION.TITLES.PERSONAL_DETAILS"
          | translate
      }}
    </h2>
    <pra-imm0008-personal-details-form
      [isDependant]="true"
      [formData]="formDetails?.personalDetailsData"
      #personalDetailsForm
    ></pra-imm0008-personal-details-form>
  </ng-container>

  <!-- Education Details component-->
  <ng-container
    *ngIf="selectedPage === '2' && formDetails?.educationDetailsData"
  >
    <h2 class="emphasis">
      {{
        "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.ACCORDION.TITLES.EDUCATION_DETAILS"
          | translate
      }}
    </h2>
    <pra-imm0008-education-details-form
      #educationOccupationForm
      [formData]="formDetails?.educationDetailsData"
    ></pra-imm0008-education-details-form>
  </ng-container>

  <!-- Language Details component-->
  <ng-container
    *ngIf="selectedPage === '3' && formDetails?.languageDetailsData"
  >
    <h2 class="emphasis">
      {{
        "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.ACCORDION.TITLES.LANGUAGE_DETAILS"
          | translate
      }}
    </h2>
    <pra-imm0008-language-details-form
      #languageDetailsForm
      [formData]="formDetails?.languageDetailsData"
    ></pra-imm0008-language-details-form>
  </ng-container>

  <!-- Passport Details component-->
  <ng-container
    *ngIf="selectedPage === '4' && formDetails?.passportDetailsData"
  >
    <h2 class="emphasis">
      {{
        "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.ACCORDION.TITLES.PASSPORT_DETAILS"
          | translate
      }}
    </h2>
    <pra-imm0008-passport-details-form
      #passportDetailsForm
      [formData]="formDetails?.passportDetailsData"
    ></pra-imm0008-passport-details-form>
  </ng-container>

  <!-- National Identity Details component-->
  <ng-container
    *ngIf="selectedPage === '5' && formDetails?.nationalIdentityDetailsData"
  >
    <h2 class="emphasis">
      {{
        "INTAKE.IMM0008.PAGES.ADD_DEPENDANT.ACCORDION.TITLES.NATIONAL_IDENTITY"
          | translate
      }}
    </h2>
    <pra-imm0008-national-identity-form
      #nationalIdentityForm
      [formData]="formDetails?.nationalIdentityDetailsData"
    ></pra-imm0008-national-identity-form>
  </ng-container>
</div>
