import { Injectable } from "@angular/core";
import { Storage } from "aws-amplify";
import awsconfig from "../../../../../../config/aws-exports";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, from, of, retry, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";

Storage.configure(awsconfig);
Storage.configure({ level: "private" });

@Injectable({
  providedIn: "root",
})

/* Allows cross-origin image downloading */
export class DownloadService {
  apiVersion = environment.API_VERSION;
  apiName = environment.API_NAME;
  constructor(private awsService: AWSService, private httpClient: HttpClient) {}
  /*
  documentName: full file name of image (identityId + file name)
  downloadName: what to rename image on download (optional)
  */
  public downloadFile(documentName: string, downloadName?: string) {
    const s3Object = documentName.split("/");
    const [identityId, ...rest] = documentName.split("/");
    const filename = rest.join("/");
    const fileDownloadName = downloadName || "download";
    if (filename) {
      Storage.get(filename, { identityId, download: true })
        // @ts-ignore (something weird about res.Body? ¯\_(ツ)_/¯)
        .then((res) => this.downloadBlob(res.Body, fileDownloadName));
    }
  }

  private downloadBlob(blob: Blob, downloadName: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = downloadName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  public async downloadDocument(
    caseId: number,
    documentId: number,
    downloadName?: string
  ) {
    const path = `/${this.apiVersion}/cases/${caseId}/document/${documentId}/download`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };

    try {
      const downloadUrlResponse = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      this.httpClient
        .get(downloadUrlResponse.data, { responseType: "blob" })
        .subscribe(
          (data) => {
            this.downloadBlob(data, downloadName || "download");
          },
          (error) => {
            throw error;
          }
        );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public clientApplicationObs$ = new Observable<any>();

  public downloadClientApplication(caseId: string, appName?: string) {
    this.clientApplicationObs$ = this.proceedWithDownloadClientApplication(
      caseId,
      appName
    );
  }

  public proceedWithDownloadClientApplication(
    caseId: string,
    appName?: string
  ): Observable<any> {
    const path = `/${this.apiVersion}/cases/${caseId}/download`;
    return of("signal").pipe(
      switchMap(() => {
        return from(this.awsService.getToken());
      }),
      switchMap((Authorization) => {
        return from(
          this.awsService.api.get(this.apiName, path, {
            headers: { Authorization: Authorization },
            response: true,
          })
        ).pipe(
          switchMap((dataFromAPI) => {
            if (dataFromAPI.status === 204) {
              return throwError(() => "errors");
            }
            return of(dataFromAPI);
          }),
          switchMap((val) => {
            return from(
              this.httpClient.get(val.data, { responseType: "blob" })
            );
          }),
          switchMap((val) => {
            this.downloadBlob(val, appName + ".zip" || "download.zip");
            return of(val);
          })
        );
      }),

      retry({
        count: 2,
        delay: 3000,
      })
    );
  }
}
