<div class="information-area">
  <div [class]="iconClass">
    <lib-icon-chooser [icon]="icon"></lib-icon-chooser>
  </div>
  <div class="information-area__details">
    <ng-container *ngIf="config.header != null">
      <h4 class="information-area__heading">
        {{ config.header | translate }}
      </h4>
    </ng-container>
    <ng-container *ngIf="config.listItems">
      <ul class="information-area__list">
        <li
          *ngFor="let item of config.listItems"
          class="information-area__list-items"
        >
          {{ item | translate }}
        </li>
      </ul>
    </ng-container>
    <ng-content> </ng-content>
  </div>
</div>
