import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as CaseActions from "../../shared/case-module/store/case.actions";
import * as fromApp from "../../store/app.reducer";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { CaseService } from "../../shared/case-module/case.service";
import { Case } from "../../shared/case-module/case.model";
import { firstValueFrom } from "rxjs";
import { ValidationService } from "lib";

@Injectable({
  providedIn: "root",
})
export class WebFormCaseResolver implements Resolve<boolean> {
  case: any;

  constructor(
    private caseService: CaseService,
    private store: Store<fromApp.State>,
    private validationService: ValidationService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const caseId = route.params.caseId;
    const caseData = await firstValueFrom(this.store.select("selectedCase"));

    if (caseId && caseData && caseData.id === "" && +caseId !== +caseData.id) {
      await this.caseService
        .getCase(caseId)
        .then((data: Case) => {
          this.store.dispatch(new CaseActions.SetCaseByEmail(data));
          this.case = data;
        })
        .catch(() => {
          this.store.dispatch(new CaseActions.ResetCaseInfo());
          this.case = null;
        });
    }
    return this.case;
  }
}
