import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import routePaths from "../../../../routing/route-paths";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "pra-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"],
})
export class ForgotPasswordPageComponent implements OnInit {
  public currentLang: string;
  public emailForm: FormGroup;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("RESET.EMAIL.PAGE_TITLE");
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.emailForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  public onSubmit(): void {
    if (this.emailForm.status === "INVALID") {
      return;
    } else {
      const email = this.emailForm.value.email;
      this.authService
        .sendEmailToResetPassword(email)
        .then((response) => {
          this.routeLocalizer.goTo(this.currentLang, "RESET_PASSWORD");
        })
        .catch((err) => {
          this.alertService.danger(this.alertTechnicalError);
        });
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.FORGOT_PASSWORD_RESET_ERROR");
  }
}
