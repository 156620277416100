import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LibModule } from "lib";
/* Components */
import { ExternalLinkComponent } from "./external-link/external-link.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CasesSearchComponent } from "./cases-search/cases-search.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { UrgencyReasonComponent } from "../renewal-module/components/urgency-reason/urgency-reason.component";
import { AttachmentStatusComponent } from "./attachment-status/attachment-status.component";
import { ToolTipComponent } from "./tool-tip/tool-tip.component";
import { FormDirective } from "./directives/form.directive";
import { ErrorLinkComponent } from "./error-link/error-link.component";
import { FormButtonsComponent } from "./form-buttons/form-buttons.component";
import { MatchingValuePipe } from "./pipes/matchingValue.pipe";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ExternalLinkComponent,
    CasesSearchComponent,
    UploadDocumentsComponent,
    UrgencyReasonComponent,
    AttachmentStatusComponent,
    ToolTipComponent,
    FormDirective,
    ErrorLinkComponent,
    FormButtonsComponent,
    MatchingValuePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LibModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
      isolate: false,
    }),
  ],
  exports: [
    TranslateModule,
    LibModule,
    ExternalLinkComponent,
    CasesSearchComponent,
    UploadDocumentsComponent,
    AttachmentStatusComponent,
    ToolTipComponent,
    FormDirective,
    ErrorLinkComponent,
    FormButtonsComponent,
    MatchingValuePipe,
  ],
})
export class SharedModule {}
