import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { caseStatuses, documentTypes, ValidationService } from "lib";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { ActivatedRoute, Router } from "@angular/router";
import { GarService } from "@pr-applicant/app/shared/services/gar/gar.service";
import {
  IGroupMember,
  PsrService,
} from "@pr-applicant/app/shared/services/psr/psr.service";

@Component({
  selector: "pra-e-signature-declaration-table",
  templateUrl: "./e-signature-declaration-table.component.html",
  styleUrls: ["./e-signature-declaration-table.component.scss"],
})
export class ESignatureDeclarationTableComponent implements OnInit, OnDestroy {
  @Input() isHiddenLob: boolean = false;

  public lang: string;

  public case: any;
  public caseId: string;
  public caseStatusId: number;
  public caseDocuments: any;
  public existingFile: any;

  public status: string;
  public statusLabel: string;
  public statusLabelTranslated: string;

  private subscriptions: Subscription[] = [];

  public buttonText: string;

  public declarationDocId = documentTypes.declarationSignatureWith5669
    ? documentTypes.declarationSignatureWith5669.id
    : 0;

  public get declarationDoc(): any {
    return this.case?.documents?.filter(
      (docId: any) => docId?.documentTypeId === this.declarationDocId
    )[0];
  }

  public get applicationStatus(): any {
    return this.case?.caseStatusId;
  }

  public get declarationDocExists(): boolean {
    return this.declarationDoc ? true : false;
  }

  public get declarationDocStatus(): any {
    const statusType = this.documentService.checkDocumentOrFormStatus(
      this.declarationDoc,
      this.applicationStatus
    );
    if (!statusType) {
      this.status = "notStarted";
      this.setActionButtonText();
      this.setStatusLabel();
      return "notStarted";
    }
    return statusType;
  }

  public isPsr: boolean = false;
  public isGar: boolean = false;
  public paInvited: boolean = false;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private documentService: DocumentService,
    private validationService: ValidationService,
    public user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public psrService: PsrService,
    public garService: GarService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.setCaseDate();
    this.setStatus();
  }

  public setStatus() {
    if (this.declarationDocStatus) {
      this.status = this.declarationDocStatus;
      this.setStatusLabel();
    }
    this.setActionButtonText();
  }

  public setStatusLabel() {
    this.status === "notStarted"
      ? (this.statusLabel = `INTAKE.TABLE_HEADINGS.EMPTY_CELL.NOT_STARTED`)
      : (this.statusLabel = `INTAKE.FORM_DOCUMENT.STATUS_${this.status.toUpperCase()}`);
  }

  public setCaseDate(): void {
    this.subscriptions.push(
      this.store.select("selectedCase").subscribe((caseData) => {
        if (caseData) {
          this.case = caseData;
          this.caseId = this.case.id;
          this.caseStatusId = this.case.caseStatusId;
          this.isPsr = this.psrService.isPSR(this.case.lobId);
          this.isGar = this.garService.isGAR(this.case.lobId);
          if (this.case.paWillSignWebform) {
            this.paInvited = this.case.members.some(
              (member: IGroupMember) => member.roleId == 3
            );
          }
        }
      })
    );
  }

  public setActionButtonText() {
    // For Reps - it should always be View
    // For Clients w/o Reps / Clients w Reps - it should be View IF this.status === 'submitted' || this.status === 'ready'
    this.buttonText =
      !this.user.can("signature:write") ||
      this.status === "submitted" ||
      this.status === "ready" ||
      ((this.isPsr || this.isGar) &&
        !(this.user.is("refugee") || this.user.is("client")) &&
        this.case.paWillSignWebform)
        ? "INTAKE.TABLE_BUTTON.VIEW"
        : "INTAKE.TABLE_BUTTON.START";
  }

  public navigateSignatureDetailPage() {
    const url = this.routeLocalizer.get(this.lang, "INTAKE_DECLARATION_PAGE");
    this.router.navigate([url], {
      relativeTo: this.route,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
