import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "@pr-applicant/app/store/app.reducer";
import { DOC_LIMITS } from "lib";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CasesCountService {
  private casesCount: number | undefined;

  constructor(private store: Store<fromApp.State>) {
    this.store
      .select("casesCount")
      .pipe(distinctUntilChanged())
      .subscribe((casesCount) => {
        this.casesCount = casesCount?.intake;
      });
  }

  reachLimit(): boolean {
    if (!!this.casesCount) {
      if (this.casesCount < DOC_LIMITS.INTAKE_REP_APPLICATIONS) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
