import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { DOC_LIMITS, documentTypes } from "lib";
import * as fromApp from "../../store/app.reducer";
import { DocumentService } from "../services/document/document.service";
import { Case, NewCase, NewRenewalCase } from "./case.model";
import * as CaseActions from "./store/case.actions";
import { from, Observable, of, Subject } from "rxjs";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { map, retry, switchMap } from "rxjs/operators";
import { RawRenewalAppForm } from "@pr-applicant/app/core/models/document.model";
import { LobService } from "projects/lib/src/lib/services/lob/lob.service";

const apiVersion = environment.API_VERSION;
@Injectable()
export class CaseService {
  caseId: string;
  apiName = environment.API_NAME;
  caseUpdated = new Subject<boolean>();

  constructor(
    private store: Store<fromApp.State>,
    private documentService: DocumentService,
    private awsService: AWSService,
    private lobService: LobService
  ) {
    this.store.select("selectedCase").subscribe((caseData) => {
      if (caseData.id) {
        this.caseId = caseData.id;
      }
    });
  }

  public async createCase(newCase: NewCase, identityId: any): Promise<any> {
    const lob = await this.lobService.fetchLob(newCase.lobId);
    //create cases only for lobs that are not hidden
    if (!lob?.isHidden) {
      const path = `/${apiVersion}/cases`;
      const init = {
        body: { ...newCase, identityId },
        headers: { Authorization: await this.getToken() },
      };
      try {
        const response = await this.awsService.api.post(
          this.apiName,
          path,
          init
        );
        this.store.dispatch(new CaseActions.SetCaseByEmail(response));
        return response;
      } catch (error) {
        throw error;
      }
    } else {
      throw "Lob is hidden - cannot create case.";
    }
  }

  // this to replace the one above once full refactoring of project
  // these type of methods should not dispatch actions to store....
  public createRenewalCase(newRenewalCase: NewRenewalCase): Observable<any> {
    const path = `/${apiVersion}/cases`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getIdentityId())),
      switchMap((identityId) =>
        from(this.awsService.getToken()).pipe(
          map((authData) => {
            return { identityId, authData };
          })
        )
      ),
      switchMap(({ identityId, authData }) => {
        const init = {
          body: { ...newRenewalCase, identityId },
          headers: {
            Authorization: authData,
          },
        };
        return from(this.awsService.api.post(this.apiName, path, init));
      }),
      retry(2)
    );
  }

  public updateRenewalCase(updatedCase: any, caseId: string): Observable<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          body: { ...updatedCase },
          headers: {
            Authorization: authData,
          },
        };
        return from(this.awsService.api.put(this.apiName, path, init));
      }),
      retry(2)
    );
  }

  public async getCase(id: number | string): Promise<Case> {
    const path = `/${apiVersion}/cases/${id}`;
    const init = {
      headers: { Authorization: await this.getToken() },
      response: true,
    };
    try {
      const { data } = await this.awsService.api.get(this.apiName, path, init);
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async updateCase(updatedCase: any): Promise<any> {
    const path = `/${apiVersion}/cases/${this.caseId}`;
    const init = {
      body: { ...updatedCase },
      headers: { Authorization: await this.getToken() },
    };
    try {
      return this.awsService.api.put(this.apiName, path, init).then((res) => {
        this.store.dispatch(new CaseActions.SetCaseByEmail(res));
        this.caseUpdated.next(true);
        return res;
      });
    } catch (error) {
      throw error;
    }
  }

  public async deleteCaseByCaseId(caseId?: string): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}`;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      response: true,
    };
    try {
      const deleteCase = await this.awsService.api.del(
        this.apiName,
        path,
        init
      );
      return { deleteCase };
    } catch (error) {
      throw error;
    }
  }

  public async updateSelfDeclareByCaseID(
    selfDeclaration?: boolean
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${this.caseId}`;
    const init = {
      headers: { Authorization: await this.getToken() },
      body: {
        selfDeclaration,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async addEsigByCaseId(
    canShareData: boolean,
    canContact: boolean,
    signedBy?: string
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${this.caseId}/documents`;
    const init = {
      headers: { Authorization: await this.getToken() },
      body: {
        documentTypeId: documentTypes.declarationSignatureWith5669.id,
        form: {
          eSignature: signedBy,
          canShareData,
          canContact,
        },
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.post(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateEsigByCaseId(
    canShareData: boolean,
    canContact: boolean,
    documentId?: number,
    signedBy?: string
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${this.caseId}/documents/${documentId}`;
    const init = {
      headers: { Authorization: await this.getToken() },
      body: {
        form: {
          eSignature: signedBy,
          canShareData,
          canContact,
        },
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getAddressRejectionReasonsByCaseId(
    caseId: string
  ): Promise<any[]> {
    const path = `/${apiVersion}/cases/${caseId}/addresses/rejection-reasons`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  // Deprecated - will be removing it soon
  public async getPrimaryCaseByEmail(): Promise<any> {
    const path = `/${apiVersion}/cases`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };

    try {
      const applicantCase = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      return applicantCase.data[0];
    } catch (error) {
      throw error;
    }
  }

  public async getCaseByCaseId(email: string, caseId: any): Promise<any> {
    try {
      const applicantCases = await this.getCasesByEmail(email);
      const caseById = this.filterCasesById(applicantCases.data, caseId);
      return caseById;
    } catch (error) {
      throw error;
    }
  }

  // public getDesignatedEntities() {
  //   const path = `/${apiVersion}/designated-entities`;
  //   if (this.designatedEntities.length > 0) return of({});
  //   return from(this.awsService.getToken()).pipe(
  //     switchMap((authData) => {
  //       const init = {
  //         headers: {
  //           Authorization: authData,
  //         },
  //         response: true,
  //       };
  //       return from(this.awsService.api.get(this.apiName, path, init));
  //     }),
  //     map((data) => {
  //       this.designatedEntities = data.data;
  //     }),
  //     retry(2)
  //   );
  // }

  public async putDesignatedEntity(payload: {
    commCert?: string | null;
    designatedEntityId?: number | null;
    hasCommCert: boolean | null;
  }): Promise<any> {
    console.log(payload);
    const path = `/${apiVersion}/cases/${this.caseId}/designated-entities`;
    const init = {
      body: payload,
      headers: { Authorization: await this.getToken() },
    };
    const response = await this.awsService.api.put(this.apiName, path, init);
    return response;
  }

  public async getIntakeApplicationsByEmail(count?: number): Promise<any> {
    const path = `/${apiVersion}/cases?phaseId=1&count=${count}`;

    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };

    try {
      const responseData = [];
      const data = await this.awsService.api.get(this.apiName, path, init);
      const intakeCases = data.headers["x-total-count"];
      responseData.push(data.data, intakeCases);
      return responseData;
    } catch (error) {
      throw error;
    }
  }

  public async getCasesByEmail(
    email?: string
  ): Promise<{ data: Case[]; intakeCount: string }> {
    const path = `/${apiVersion}/cases?count=${DOC_LIMITS.INTAKE_APPLICATIONS}`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const applicantCases = await this.awsService.api.get(
        this.apiName,
        path,
        init
      );
      return {
        data: applicantCases.data,
        intakeCount: applicantCases.headers["x-intake-count"],
      };
    } catch (error) {
      throw error;
    }
  }

  public async getCaseAndDocumentsByCaseId(
    email: string,
    caseId: string
  ): Promise<any> {
    try {
      const applicantCase = (await this.getCaseByCaseId(email, caseId))[0];
      this.store.dispatch(new CaseActions.SetCaseByEmail(applicantCase));
      const applicantDocuments = await this.documentService.getDocumentByCaseId(
        caseId
      );
      // this.store.dispatch(new CaseActions.SetDocumentByCaseId({ caseDocuments: applicantDocuments }));
    } catch (error) {
      throw error;
    }
  }

  public async submitApplicationToCW(): Promise<any> {
    // sets the status to 2 - IN REVIEW
    const path = `/${apiVersion}/cases/${this.caseId}/status`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      body: {
        caseStatusId: 2,
      },
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  private async getToken(): Promise<any> {
    return await this.awsService.getToken();
  }

  // Utility function that filters cases based on the phase
  public filterCasesByPhase(
    applicantCases: Case[],
    phaseId: number
  ): Case[] | [] {
    return applicantCases.filter(
      (applicantCase: Case) => applicantCase.phaseId === phaseId
    );
  }

  // Utility function that filters cases based on caseId
  private filterCasesById(
    applicantCases: Case[],
    caseId: string | number
  ): Case[] | [] {
    /* eslint-disable eqeqeq */
    return applicantCases.filter(
      (applicantCase: Case) => applicantCase.id.toString() === caseId.toString()
    );
  }

  public async getAppForms(): Promise<any> {
    const apiName = environment.API_NAME;
    const path = `/${apiVersion}/document-types?phaseId=1&isForm=true`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const appForms = await this.awsService.api.get(apiName, path, init);
      return appForms.data;
    } catch (error) {
      throw error;
    }
  }

  // Phase 3 ONLY
  public getApplicationFormsByLobId(
    lobId: number | null,
    isForm: boolean
  ): Observable<{ forms: RawRenewalAppForm[] }> {
    const path = `/${apiVersion}/document-types?lobId=${lobId}&isForm=${isForm}`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        const responseData = { forms: data.data };
        return responseData;
      }),
      retry(2)
    );
  }

  /** -------- INTAKE ONLY METHODS ----------- */

  // This method will check the status of the case and return
  // the case status that can then be used for the
  public checkIntakeCaseStatus(caseStatusId: number): string {
    switch (caseStatusId) {
      case 6:
        return "initiated";
      case 7:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
        return "submitted";
      case 8:
      case 17:
        return "returned";
      case 9:
        return "withdrawn";
      case 21:
        return "expired";
      default:
        return "initiated";
    }
  }
  // THIS IS IMPORTANT TO BE KEPT IN SYNCH WITH the one above

  public isIntakeCaseInAlreadyInSubmittedStatus(
    caseStatusId: number | string
  ): boolean {
    return (
      ["7", "10", "11", "12", "13", "14"].indexOf(caseStatusId.toString()) >= 0
    );
  }

  public isIntakeCaseSubmittedOrCompleted(
    caseStatusId: number | string
  ): boolean {
    return (
      ["7", "10", "11", "12", "13", "14"].indexOf(caseStatusId.toString()) >= 0
    );
  }
  public isIntakeCaseSubmittedOrWithdrawn(
    caseStatusId: number | string
  ): boolean {
    return (
      ["7", "10", "11", "12", "13", "14", "9"].indexOf(
        caseStatusId.toString()
      ) >= 0
    );
  }

  public isIntakeCaseExpired(caseStatusId: number | string): boolean {
    return ["21"].indexOf(caseStatusId.toString()) >= 0;
  }

  public getIntakeCaseStatusBox(caseStatusId: number): any {
    const statusText = this.checkIntakeCaseStatus(caseStatusId);

    switch (statusText) {
      case "initiated":
        return {
          statusKey: statusText.toUpperCase(),
          statusBoxType: "edit",
        };
      case "returned":
        return {
          statusKey: statusText.toUpperCase(),
          statusBoxType: "problem",
        };
      case "submitted":
        return {
          statusKey: statusText.toUpperCase(),
          statusBoxType: "wait",
        };
      // This is out of scope for MVP1 but will be needed either ways!
      case "withdrawn":
        return {
          statusKey: statusText.toUpperCase(),
          statusBoxType: "edit",
        };
      case "expired":
        return {
          statusKey: statusText.toUpperCase(),
          statusBoxType: "problem",
        };
      default:
        return {
          statusKey: "INITIATED",
          statusBoxType: "edit",
        };
    }
  }

  // TODO: Depreciate once we have the updateCase() end point handle the submission by updating
  // the case statusId using /cases/:caseId
  public async submitIntakeCase(caseId: string): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/status`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      body: {
        caseStatusId: 7,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async submitRepDeclaration(
    caseId: string,
    confirmation: boolean
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/representative-confirmation`;
    const init = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      body: { representativeConfirmation: confirmation },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public getApplicationsByPhaseId(
    phaseId: number = 3,
    count?: number
  ): Observable<{ list: Case[]; count: number }> {
    const path = `/${apiVersion}/cases?phaseId=${phaseId}&count=${count}`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          response: true,
        };
        return from(this.awsService.api.get(this.apiName, path, init));
      }),
      map((data) => {
        const responseData = {
          list: data.data,
          count: data.headers["x-total-count"],
        };
        return responseData;
      }),
      retry(2)
    );
  }

  public submitRenewalCase(caseId: string, isUrgent: boolean): Observable<any> {
    const path = `/${apiVersion}/cases/${caseId}/status`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          body: {
            caseStatusId: 16,
            isUrgent,
          },
          response: true,
        };
        return from(this.awsService.api.put(this.apiName, path, init));
      }),
      map((response) => {
        const responseData = response.data;
        return responseData;
      }),
      retry(2)
    );
  }
  public updateUrgencyRenewalCase(
    caseId: string,
    isUrgent: boolean,
    urgencyRequestDate?: string
  ): Observable<any> {
    const path = `/${apiVersion}/cases/${caseId}/is-urgent`;
    return of("signal").pipe(
      switchMap((signal) => from(this.awsService.getToken())),
      switchMap((authData) => {
        const init = {
          headers: {
            Authorization: authData,
          },
          body: {
            isUrgent,
            urgencyRequestDate: urgencyRequestDate || null,
          },
          response: true,
        };
        return from(this.awsService.api.put(this.apiName, path, init));
      }),
      map((response) => {
        const responseData = response.data;
        return responseData;
      }),
      retry(2)
    );
  }

  public async addRenewalDeclarationByCaseId(
    caseId: String,
    formData: Object
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents`;
    const init = {
      headers: { Authorization: await this.getToken() },
      body: {
        documentTypeId: documentTypes.renewalDeclaration.id,
        form: formData,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.post(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateRenewalDeclarationByCaseId(
    caseId: String,
    documentId: number,
    formData: Object
  ): Promise<any> {
    const path = `/${apiVersion}/cases/${caseId}/documents/${documentId}`;
    const init = {
      headers: { Authorization: await this.getToken() },
      body: {
        form: formData,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
