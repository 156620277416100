import { Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import routePaths from "../../../routing/route-paths";
import { Auth } from "aws-amplify";
import { UserGroup } from "lib";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Injectable({ providedIn: "root" })
export class AWSGuard implements CanActivate {
  public isAuthenticated: boolean | undefined;
  private currentLang: string;

  constructor(
    private router: Router,
    private store: Store<fromApp.State>,
    private routeLocalizer: RouteLocalizerService,
    private user: UserService
  ) {
    this.store.select(fromApp.selectRouteData).subscribe((data) => {
      this.currentLang = data?.lang;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): any {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const group = user.signInUserSession.idToken.payload["custom:group"];
          if ([UserGroup.Client, UserGroup.Rep].includes(group)) {
            resolve(true);
          } else {
            resolve(this.getLoginUrl());
          }
        })
        .catch(() => {
          resolve(this.getLoginUrl());
        });
    });
  }

  private getLoginUrl(): UrlTree {
    const repPrefix = this.user.isRepAccount() ? "/rep" : "";
    return this.router.parseUrl(
      `/${this.routeLocalizer.getCurrentRouteLang()}${repPrefix}/${
        this.routeLocalizer.getCurrentRouteLang() === "fr"
          ? routePaths.fr.LOGIN
          : routePaths.en.LOGIN
      }`
    );
  }
}
