import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Router } from "@angular/router";
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { EMPTY, from, of } from "rxjs";
import { AlertService } from "lib";
import { TranslateService } from "@ngx-translate/core";
import { AppPrcPrtdState } from "../prcPrtdState";
import { NGXLogger } from "ngx-logger";
import {
  LoadPRCAction,
  LoadPRCErrorAction,
  RenewalApplicationsPRCActionTypes,
  LoadPRTDAction,
  LoadPRTDErrorAction,
  RenewalApplicationsPRTDActionTypes,
  RenewalApplicationsActionTypes,
  LoadRenewalsApplicationsList,
  ErrorLoadRenewalsApplicationsList,
} from "../actions";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Injectable()
export class ApplicantRenewalsEffects {
  public currentLang: string;

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    public routeLocalizer: RouteLocalizerService,
    private router: Router,
    private alertService: AlertService,
    private store: Store<AppPrcPrtdState>,
    private user: UserService,
    private caseService: CaseService,
    private logger: NGXLogger
  ) {}

  startLoadCurrentRenewalApplicationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RenewalApplicationsActionTypes.StartLoadRenewalsApplicationsList),
      switchMap((action: any) => {
        return from(this.caseService.getApplicationsByPhaseId(3, 1000)).pipe(
          catchError((err) => {
            this.logger.error(
              "API ERROR when load Renewals Applications DATA",
              err
            );
            return of(null);
          }),
          map((data) => {
            if (data) {
              return LoadRenewalsApplicationsList({ list: data.list });
            } else if (
              this.user.isRepAccount() &&
              !this.user.isRegisteredRep()
            ) {
              return ErrorLoadRenewalsApplicationsList({
                error:
                  "API ERROR when load Renewals Applications DATA for rep but not registered yet",
              });
            } else {
              this.alertService.danger(this.alertTechnicalError);
              return ErrorLoadRenewalsApplicationsList({
                error: "API ERROR when load Renewals Applications DATA",
              });
            }
          })
        );
      })
    )
  );

  startLoadCurrentRenewalPRCCaseEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RenewalApplicationsPRCActionTypes.StartLoadPRCAction),
      switchMap((action: any) => {
        const caseId = action?.caseId;
        return from(this.caseService.getCase(caseId)).pipe(
          catchError((err) => {
            this.logger.error("API ERROR when load PRC DATA", err);
            return of(null);
          }),
          map((data) => {
            if (data) {
              return LoadPRCAction({ prcCard: data });
            } else {
              this.alertService.danger(this.alertTechnicalError);
              return LoadPRCErrorAction({
                id: caseId,
                error: "API ERROR when load PRC DATA",
              });
            }
          })
        );
      })
    )
  );

  startLoadCurrentPRTDCaseEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RenewalApplicationsPRTDActionTypes.StartLoadPRTDAction),
      switchMap((action: any) => {
        const caseId = action?.caseId;
        return from(this.caseService.getCase(caseId)).pipe(
          catchError((err) => {
            this.logger.error("API ERROR when load PRTD DATA", err);
            return of(null);
          }),
          map((data) => {
            if (data) {
              return LoadPRTDAction({ prtd: data });
            } else {
              this.alertService.danger(this.alertTechnicalError);
              return LoadPRTDErrorAction({
                id: caseId,
                error: "API ERROR when load PRTD DATA",
              });
            }
          })
        );
      })
    )
  );

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private delMsgSuccessByCase(caseInput: Case): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.SUCCESS_DELETE_CASE", {
      uci: caseInput.uci,
    });
  }
}
