import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertService,
  DOC_LIMITS,
  FormSelectOption,
  ValidationService,
} from "lib";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { LovService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm5406-section-c-form",
  templateUrl: "./imm5406-section-c.component.html",
  styleUrls: ["./imm5406-section-c.component.scss"],
})
export class IMM5406SectionCComponent implements OnInit, OnDestroy {
  public siblingsFormArray: UntypedFormArray = new UntypedFormArray([]);
  public maritalStatusOptions: FormSelectOption[];
  public formMaxLength: { [key: string]: number } = {
    relationship: 27,
    fullName: 63,
    dob: 10,
    countryOfBirth: 30,
    emailAddress: 40,
    address: 80,
  };
  public isReadOnly = !this.user.can("documents:write");

  private formData: any;
  private currentForm: any;
  private storeSubsc$: Subscription;

  private buttonClicked: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    public validators: ValidationService,
    public alertService: AlertService,
    private store: Store<fromApp.State>,
    public translate: TranslateService,
    public lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.maritalStatusOptions = this.lovService.lovs.otherMaritalStatus;
        this.formData = currentImmDoc.currentForm.form.sectionC;
        if (this.formData?.siblings && this.formData?.siblings.length > 0) {
          this.fillFormWithSavedValues();
        } else {
          this.siblingsFormArray.push(this.createNewSiblingFormGroup());
        }
      });
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  public get isFormValid(): boolean {
    return this.siblingsFormArray.valid;
  }

  public get alertMaxSiblingLimit(): string {
    return this.translate.instant(
      "INTAKE.IMM5406.FORMS.SECTION_C.MAX_SIBLING_REACHED"
    );
  }

  public addSibling(): void {
    if (this.siblingsFormArray.length < DOC_LIMITS.IMM5406.SIBLING_MAX) {
      this.buttonClicked = true;
      this.siblingsFormArray.push(this.createNewSiblingFormGroup());
    } else {
      this.alertService.danger(this.alertMaxSiblingLimit);
    }
  }

  public removeSibling(index: number): void {
    this.buttonClicked = true;
    this.siblingsFormArray.removeAt(index);
  }

  public getFormData(): any {
    if (!this.isFormValid) {
      return { sectionName: "sectionC", isInvalid: true };
    }
    let result = _.cloneDeep(this.currentForm);
    const fromAPISectionC = result.form.sectionC;
    const siblings = this.siblingsFormArray.getRawValue();
    result = {
      sectionName: "sectionC",
      sectionInfo: { ...fromAPISectionC, siblings },
    };
    return result;
  }

  public isFormDataUnSaved(): boolean {
    return this.siblingsFormArray.touched || this.buttonClicked;
  }

  /* PRIVATE ---------------------------------------------------- */

  private createNewSiblingFormGroup(): FormGroup {
    const form = this.fb.group({
      relationship: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.relationship)],
        },
      ],
      fullName: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.fullName)],
        },
      ],
      dob: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.dob)],
        },
      ],
      countryOfBirth: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.countryOfBirth)],
        },
      ],
      maritalStatus: [null],
      emailAddress: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.emailAddress)],
        },
      ],
      address: [
        null,
        {
          validators: [Validators.maxLength(this.formMaxLength.address)],
        },
      ],
    });
    return form;
  }

  private fillFormWithSavedValues(): void {
    this.siblingsFormArray.clear(); // reset the form array
    const savedSiblings = this.formData.siblings;
    savedSiblings?.forEach((sibling: any) => {
      const siblingField: FormGroup = this.createNewSiblingFormGroup();
      siblingField?.setValue({
        relationship: sibling.relationship,
        fullName: sibling.fullName || "",
        dob: sibling.dob,
        countryOfBirth: sibling.countryOfBirth,
        maritalStatus: sibling.maritalStatus,
        emailAddress: sibling.emailAddress,
        address: sibling.address,
      });
      this.siblingsFormArray.push(siblingField);
    });
  }
}
