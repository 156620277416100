import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormSelectOption } from "lib";
import { Subscription } from "rxjs";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";

@Component({
  selector: "pra-anchor-doc-table",
  templateUrl: "./anchor-doc-table.component.html",
  styleUrls: ["./anchor-doc-table.component.scss"],
})
export class AnchorDocTableComponent implements OnInit {
  public memberDocs: RelatedDocumentType[] = [];
  public subscriptions: Subscription[] = [];

  @Input() isHiddenLob: boolean = false;
  @Input() caseMemberId?: number | undefined;
  @Input() memberDocumentOptions: FormSelectOption[];
  @Output() documentsUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private routeLocalizer: RouteLocalizerService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  public handleUpdate() {
    this.documentsUpdated.emit();
  }
}
