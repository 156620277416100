import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import routePaths from "../../../routing/route-paths";
import { CaseService } from "../../../shared/case-module/case.service";
import { DocumentService } from "../../../shared/services/document/document.service";
import * as fromApp from "../../../store/app.reducer";
import { PhotoService } from "../../photos/photo.service";
import { SelfDeclare, Step } from "./step.model";

@Component({
  selector: "pra-applicant-steps",
  templateUrl: "./applicant-steps.component.html",
  styleUrls: ["./applicant-steps.component.scss"],
})
export class ApplicantStepsComponent implements OnInit {
  @Input() confirmationLanding: boolean;

  public canSubmitApplication = false;
  public case: any;
  public lang: string;
  private documents: any;
  public addressSelfDeclaration: boolean;
  public addressChecked = false;

  //  Responsiveness mobile layout
  public innerWidth: number;
  public showValue: boolean;

  steps: Step[] = [
    {
      stepName: "ADDRESS",
      title: "HOME.STEPS.ADDRESS.TITLE",
      subtitle: "HOME.STEPS.ADDRESS.SUBTITLE",
      description: "HOME.STEPS.ADDRESS.DESCRIPTION",
      status: "",
      statusLabel: "",
      dataProvidedTitle: "HOME.STEPS.ADDRESS.PROVIDED_HEADER",
      dataProvided: "",
      isBtnDisabled: false,
      pathKey: "ADDRESS",
      statusIconSrc: "",
      provideInformation: "HOME.STEPS.ADDRESS.PROVIDE_INFO",
      dataHeader: "HOME.STEPS.ADDRESS.DETAILS_HEADER",
      rejectionReasons: [],
    },
    {
      stepName: "PHOTO",
      title: "HOME.STEPS.PHOTO.TITLE",
      subtitle: "HOME.STEPS.PHOTO.SUBTITLE",
      description: "HOME.STEPS.PHOTO.DESCRIPTION",
      status: "",
      statusLabel: "",
      dataProvidedTitle: "HOME.STEPS.PHOTO.PROVIDED_HEADER",
      dataProvided: "",
      dataPreview: undefined,
      dataPreviewCoordinates: undefined,
      isBtnDisabled: false,
      pathKey: "PHOTO",
      statusIconSrc: "",
      provideInformation: "HOME.STEPS.PHOTO.PROVIDE_INFO",
      dataHeader: "HOME.STEPS.PHOTO.DETAILS_HEADER",
      rejectionReasons: [],
    },
  ];

  selfDeclare: SelfDeclare = {
    stepName: "SELF_DECLARE",
    title: "SELF_DECLARE.DECLARATION_HEADING",
    status: "",
    statusLabel: "",
    statusIconSrc: "",
  };

  constructor(
    private alertService: AlertService,
    private store: Store<fromApp.State>,
    private caseService: CaseService,
    private photoService: PhotoService,
    private router: Router,
    private route: ActivatedRoute,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private documentService: DocumentService
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth >= 992) {
      this.showValue = true;
    }
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 992) {
      this.showValue = true;
    }
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.route.data.subscribe((data) => {
      this.case = data.case;
      this.documents = data.case.caseDocuments;
      if (this.case && this.case.caseId !== "") {
        this.addressSelfDeclaration =
          this.case.addressDeclarationTimestamp !== null;
        this.updateStatuses();
        this.checkForRejectionReasons();
        this.checkIfDeclared();
      }
    });
  }

  public get nameParam(): object {
    const name =
      this.case.givenName !== null
        ? `${this.case.givenName} ${this.case.surname}`
        : this.case.surname;
    return {
      name,
    };
  }

  private checkIfDeclared(): void {
    if (this.addressSelfDeclaration || this.addressChecked) {
      this.selfDeclare.statusIconSrc =
        "../../../../../../../assets/images/icons/circle-check.svg";
      this.selfDeclare.status = "APPROVED";
      this.selfDeclare.statusLabel = "HOME.STEPS.DECLARATION_STATUSES.APPROVED";
    } else {
      this.selfDeclare.statusIconSrc =
        "../../../../../../../assets/images/icons/circle-pending.svg";
      this.selfDeclare.status = "NOT_COMPLETED";
      this.selfDeclare.statusLabel =
        "HOME.STEPS.DECLARATION_STATUSES.NOT_COMPLETED";
    }
  }

  handleCheckedDeclaration($event: boolean) {
    this.addressChecked = $event;
  }

  toggleDeclarationCheck() {
    this.addressChecked = !this.addressChecked;
  }

  submitDeclaration() {
    this.caseService
      .updateSelfDeclareByCaseID(this.addressChecked)
      .then((response: any) => {
        return response;
      })
      .then(async () => {
        // update state again
        return this.caseService.getCaseAndDocumentsByCaseId(
          this.case.emailAddress,
          this.case.id
        );
      })
      .then(() => {
        const currentUrl = this.router.url;
        this.alertService.success(this.alertSuccesCheck);
        this.getCaseData();
        this.addressSelfDeclaration = true;
        this.updateStatuses();
        this.checkIfDeclared();
      })
      .catch(() => {
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  public submitApplication(): void {
    if (this.canSubmitApplication) {
      this.caseService
        .submitApplicationToCW()
        .then((response: any) => {
          return response;
        })
        .then(async () => {
          // update state again
          return this.caseService.getCaseAndDocumentsByCaseId(
            this.case.emailAddress,
            this.case.id
          );
        })
        .then(() => {
          this.getCaseData();
          const stringLang = this.lang === "fr" ? "fr" : "en";
          this.router.navigateByUrl(
            `/${this.lang}/${routePaths[stringLang].CONFIRMATION_CASES}`
          );
          this.alertService.success(this.alertSuccessfulSubmit);
        })
        .catch(() => {
          this.alertService.danger(this.alertTechnicalError);
        });
    }
  }

  private checkIfApproved(approvedStatus: boolean, index: number): void {
    switch (approvedStatus) {
      case null:
        // Under review by case worker since photo is uploaded and case was submitted to case worker
        if (this.case.caseStatusId === 2) {
          this.steps[index].status = "IN_REVIEW";
          this.steps[index].isBtnDisabled = true;
          // Case in revision, and one document is still in review
        } else if (this.case.caseStatusId === 3) {
          this.steps[index].status = "PROVIDED";
          this.steps[index].isBtnDisabled = false;
        } else {
          // Provided but not submitted yet
          this.steps[index].status = "PROVIDED";
        }
        break;
      case true:
        // Case worker approved document
        this.steps[index].status = "APPROVED";
        this.steps[index].isBtnDisabled = true;
        break;
      case false:
        // Case worker rejected document
        this.steps[index].status = "REJECTED";
        break;
      default:
        break;
    }
  }

  private friendlyPhotoFileName(fileName: string): string {
    // strips AWS identityID off documentName, stored as:
    // 'ca-central-1:87abbff1-5f6a-4fe8-a38c-397d8c19095a/12987668-Glenanne-photo.png'
    return fileName.split("/")[fileName.split("/").length - 1];
  }

  private getCaseData(): void {
    this.store.select("selectedCase").subscribe((caseData) => {
      this.case = caseData;
      if (this.case && this.case.caseId !== "") {
        this.updateStatuses();
      }
    });
  }

  private setStatusIcon(status: string, index: number): void {
    switch (status) {
      case "NOT_COMPLETED":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/circle-pending.svg";
        break;
      case "PROVIDED":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/circle-edit.svg";
        break;
      case "APPROVED":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/circle-check.svg";
        break;
      case "IN_REVIEW":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/circle-timer.svg";
        break;
      case "REJECTED":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/triangle-danger.svg";
        break;
      case "DISABLED":
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/initiated.svg";
        break;
      default:
        this.steps[index].statusIconSrc =
          "../../../../../../../assets/images/icons/circle-pending.svg";
        break;
    }
  }

  private updateStatuses(): void {
    this.steps.forEach(async (step, index) => {
      if (!this.addressSelfDeclaration) {
        this.steps[index].status = "DISABLED";
        this.setStatusIcon(this.steps[index].status, index);
        return;
      }

      if (step.stepName === "ADDRESS") {
        // If no address was entered the step was not completed
        if (this.case.mailingAddress1 === null) {
          this.steps[index].status = "NOT_COMPLETED";
        } else {
          // eslint-disable-next-line max-len
          this.steps[index].dataProvided = `${this.case.mailingAddress1}, ${
            this.case.mailingAddress2 ? this.case.mailingAddress2 + ", " : ""
          } ${this.case.city}, ${this.case.province}, ${this.case.postalCode}`;

          // The mailing address was entered so proceed to check if address was approved
          this.checkIfApproved(this.case.addressApproved, index);
        }
      } else if (step.stepName === "PHOTO") {
        let photo;
        const photosOnly = this.documents?.filter(
          (doc: any) => doc.documentTypeId === 1
        );
        photo = photosOnly !== undefined && photosOnly[0];

        // If photo exists in the s3
        if (photo) {
          this.steps[index].dataProvided = this.friendlyPhotoFileName(
            photo.documentName
          );
          this.checkIfApproved(photo.documentApproved, index);

          // Check if there is a preview image available for the photo provided
          const { photoLink: previewURL, coordinates } =
            await this.getPreviewPhotoFromStorage();
          if (previewURL) {
            this.steps[index].dataPreview = previewURL;
          }
          if (coordinates) {
            this.steps[index].dataPreviewCoordinates = coordinates;
          }
        } else {
          this.steps[index].status = "NOT_COMPLETED";
        }
      }

      this.steps[
        index
      ].statusLabel = `HOME.STEPS.DOCUMENT_STATUSES.${this.steps[index].status}`;
      this.setStatusIcon(this.steps[index].status, index);
    });
    this.updateSubmitBtn();
  }

  private updateSubmitBtn(): void {
    // compare total steps with completed steps using data provided property for each step
    const totalSteps = this.steps.length;
    let completedSteps = 0;
    this.steps.forEach((step) => {
      if (
        step.dataProvided &&
        (step.status === "PROVIDED" ||
          step.status === "APPROVED" ||
          step.status === "IN_REVIEW")
      ) {
        completedSteps += 1;
      }
    });

    // if all steps are completed (i.e data has been provided, and the step status is provided), they can submit
    // if the case has been accepted or closed or in review they cannot submit anything
    const isAppLocked =
      this.case.caseStatusId === 2 ||
      this.case.caseStatusId === 4 ||
      this.case.caseStatusId === 5
        ? true
        : false;
    this.canSubmitApplication =
      totalSteps === completedSteps && !isAppLocked ? true : false;
  }

  private async checkForRejectionReasons() {
    const photo = this.documents?.filter(
      (doc: any) => doc.documentTypeId === 1
    )[0];

    this.steps.forEach(async (step, index) => {
      if (step.dataProvided && step.status === "REJECTED") {
        try {
          const reasons =
            step.stepName === "ADDRESS"
              ? await this.caseService.getAddressRejectionReasonsByCaseId(
                  this.case.id
                )
              : await this.documentService.getDocumentRejectionReasonsByDocumentId(
                  this.case.id,
                  photo.id
                );

          const langKey =
            this.lang === "fr" ? "rejectionReasonFr" : "rejectionReasonEn";
          const onlySelectedReasons = reasons?.filter(
            (r: { selected: boolean | number }) => Boolean(r.selected) === true
          );
          const translatedReasons = onlySelectedReasons.map(
            (r: { [x: string]: any }) => r[langKey]
          );
          this.steps[index].rejectionReasons = translatedReasons;
        } catch (error) {
          this.alertService.danger(this.alertTechnicalError);
        }
      }
    });
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR");
  }

  private get alertSuccessfulSubmit(): string {
    const displayName = this.case.givenName
      ? `${this.case.givenName} ${this.case.surname}`
      : `${this.case.surname}`;
    return this.translate.instant("HOME.STEPS.ALERTS.SUCCESS", {
      name: displayName,
    });
  }

  private get alertSuccesCheck(): string {
    const displayName = this.case.givenName
      ? `${this.case.givenName} ${this.case.surname}`
      : `${this.case.surname}`;
    return this.translate.instant("SELF_DECLARE.DECLARATION_ALERTS.SUCCESS", {
      name: displayName,
    });
  }

  private async getPreviewPhotoFromStorage() {
    const uci = this.case.uci;
    const document = this.documents?.find(
      (doc: any) => doc.documentTypeId === 1
    );
    const photoName = document?.documentName.slice(
      document.documentName.indexOf("/") + 1
    );

    try {
      const { photoLink, coordinates } =
        await this.photoService.getPhotoDetailsFromStorage(photoName);
      return { photoLink, coordinates };
    } catch (error) {
      throw error;
    }
  }
}
