<div
  id="intake-side-nav-container"
  class="side-nav-primary-container"
  [ngClass]="{
    'open-animation': showNav === true,
    'close-animation': showNav === false
  }"
>
  <ircc-cl-lib-navigation> </ircc-cl-lib-navigation>
</div>
<div
  id="intake-animation-backdrop"
  class="backdrop"
  [ngClass]="{
    'backdrop-fadein': showNav === true,
    'backdrop-fadeout': showNav === false
  }"
></div>
<pra-form-hamburger (click)="mobileShowNav()"></pra-form-hamburger>
