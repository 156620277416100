<!--Site Logout -->
<div class="logout__container" [ngStyle]="{ display: loggedIn ? '' : 'none' }">
  <span class="logout__user" *ngIf="!isLoading">
    {{ displayName }}
  </span>
  <span
    (click)="logRole()"
    *ngIf="!isProduction"
    style="color: red; font-weight: bold; cursor: pointer"
    >{{
      userService.caseId ? "Case " + userService.caseId + ":" : "case role: "
    }}
    {{ userService.getCaseRole() || "not set" }}
  </span>
  <span *ngIf="!isLoading">
    <button type="button" (click)="logout()" category="plain">
      {{ "LOGOUT.LOGOUT.LABEL" | translate }}
    </button>
  </span>
</div>
