import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "lib";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { AuthService } from "../../services/auth.service";
import routePaths from "../../../../routing/route-paths";

@Component({
  selector: "pra-sign-up-page-verification",
  templateUrl: "./sign-up-page-verification.component.html",
  styleUrls: ["./sign-up-page-verification.component.scss"],
})
export class SignUpVerificationPageComponent implements OnInit {
  public currentLang: string;
  public verificationForm: FormGroup;
  public email: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService,
    private authService: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    if (!this.authService.tempNewUserEmail) {
      this.routeLocalizer.goTo(this.currentLang, "LOGIN");
    }

    this.email = this.authService.tempNewUserEmail;

    this.verificationForm = new FormGroup({
      code: new FormControl(null, [Validators.required]),
    });
  }

  public onConfirmSignUp() {
    if (this.verificationForm.status === "INVALID") {
      return;
    }
    const code = this.verificationForm.value.code;

    this.authService
      .confirmSignUp(code, this.email)
      .then((response) => {
        this.alertService.success(this.alertSuccessBanner);
        this.routeLocalizer.goTo(this.currentLang, "LOGIN");
      })
      .catch((err) => {
        if (err.code === "CodeMismatchException") {
          this.alertService.danger(this.alertIncorrectCode);
        } else {
          this.alertService.danger(this.alertTechnicalError);
        }
      });
  }

  public async resendCode() {
    try {
      await this.authService.resendCodeSignUp(this.email);
      this.alertService.success(this.alertResendMsg);
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  private get alertResendMsg(): string {
    return this.translate.instant("SIGNUP.RESEND_CODE_BANNER");
  }

  private get alertIncorrectCode(): string {
    return this.translate.instant("SIGNUP.ERRORS.WRONG_CODE");
  }

  private get alertSuccessBanner(): string {
    return this.translate.instant("SIGNUP.SUCCESS_BANNER");
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("LOGIN.ALERTS.TECHNICAL_ERROR");
  }
}
