import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  FormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import {
  AlertService,
  DOC_LIMITS,
  FormSelectOption,
  ValidationService,
} from "lib";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { LovService } from "lib";
import {
  ICaseProfileModel,
  ProfileService,
} from "@pr-applicant/app/shared/services/profile/profile.service";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5406-section-b",
  templateUrl: "./imm5406-section-b.component.html",
  styleUrls: ["./imm5406-section-b.component.scss"],
})
export class IMM5406SectionBComponent implements OnInit, OnDestroy {
  public children: UntypedFormArray = new UntypedFormArray([]);
  public maritalStatusOptions: FormSelectOption[] = [];
  public maxFieldLengths: { [key: string]: number } = {
    relationship: 27,
    fullName: 63,
    dob: 10,
    countryOfBirth: 30,
    emailAddress: 40,
    address: 80,
  };

  private currentForm: any;
  private storeSubsc$: Subscription;
  private profileSubsc$: Subscription;
  public profileChildrenIndex: number = 0;
  public formData: any;
  public caseId: number;

  private buttonClicked: boolean = false;
  public isReadOnly = !this.user.can("documents:write");

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private store: Store<fromApp.State>,
    private translate: TranslateService,
    public lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.caseId = currentImmDoc.caseId;
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.maritalStatusOptions = this.lovService.lovs.otherMaritalStatus;
        this.formData = currentImmDoc.currentForm.form.sectionB;
        if (this.formData?.children && this.formData?.children.length > 0) {
          this.setFormValues();
        } else {
          this.children.push(this.createChildFormGroup());
        }
      });
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  public addChild(): void {
    if (this.children.length < DOC_LIMITS.IMM5406.CHILDREN) {
      this.buttonClicked = true;
      this.children.push(this.createChildFormGroup());
    } else {
      this.alertService.danger(this.alertMaxChildrenLimit);
    }
  }

  public removeChild(index: number): void {
    this.buttonClicked = true;
    this.children.removeAt(index);
  }

  public get isFormValid(): boolean {
    return this.children.valid;
  }

  public getFormData(): any {
    if (!this.isFormValid) {
      return { sectionName: "sectionB", isInvalid: true };
    }
    let result = _.cloneDeep(this.currentForm);
    const fromAPISectionB = result.form.sectionB;
    const children = this.children.getRawValue();
    result = {
      sectionName: "sectionB",
      sectionInfo: { ...fromAPISectionB, children },
    };
    return result;
  }

  public isFormDataUnSaved(): boolean {
    return this.children.touched || this.buttonClicked;
  }

  /* PRIVATE ---------------------------------------------------- */

  private get alertMaxChildrenLimit(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  private createChildFormGroup(): FormGroup {
    return this.fb.group({
      relationship: [
        null,
        Validators.maxLength(this.maxFieldLengths.relationship),
      ],
      fullName: [null, Validators.maxLength(this.maxFieldLengths.fullName)],
      dob: [null, Validators.maxLength(this.maxFieldLengths.dob)],
      countryOfBirth: [
        null,
        Validators.maxLength(this.maxFieldLengths.countryOfBirth),
      ],
      maritalStatus: [null],
      emailAddress: [
        null,
        Validators.maxLength(this.maxFieldLengths.emailAddress),
      ],
      address: [null, Validators.maxLength(this.maxFieldLengths.address)],
    });
  }

  private setFormValues(): void {
    this.children.clear(); // reset the form array to start
    const savedValues = this.formData?.children;
    savedValues.forEach((v: any) => {
      const fields: FormGroup = this.createChildFormGroup();
      fields.setValue({
        relationship: v.relationship,
        fullName: v.fullName,
        dob: v.dob,
        countryOfBirth: v.countryOfBirth,
        maritalStatus: v.maritalStatus,
        emailAddress: v.emailAddress,
        address: v.address,
      });
      this.children.push(fields);
    });
  }
}
