<div class="intake-main-container">
  <div *ngIf="canViewExistingCase">
    <pra-intake-side-nav
      [ApplicationFormsData]="requiredRawAppForms"
      [requiredFormList]="requiredList"
      [supportingDocsData]="requiredDocumentOptions"
      [caseData]="case"
    ></pra-intake-side-nav>
  </div>
  <div>
    <pra-program-info-links
      *ngIf="canViewExistingCase"
      [isLobUpdated]="isLobUpdated"
    ></pra-program-info-links>

    <ng-container *ngIf="!hideIntake">
      <lib-spinner
        [showSpinner]="isProcessing"
        [fullPage]="false"
      ></lib-spinner>
    </ng-container>
    <pra-program-selection-form
      (caseLobUpdated)="checkCaseLobUpdated($event)"
      (hideIntake)="setHideIntake($event)"
    ></pra-program-selection-form>

    <ng-container *ngIf="canViewExistingCase">
      <section
        class="status-flex"
        *ngIf="statusKey === 'INITIATED' || statusKey === 'SUBMITTED'"
      >
        <div>
          <strong>
            {{ "INTAKE.LANDING_PAGE.STATUS" | translate }}
          </strong>
        </div>
        <lib-status-pill
          [text]="'INTAKE.CASE_STATUS.STATUS_' + statusKey | translate"
          [type]="statusKey.toLowerCase()"
          [wordBreak]="true"
        >
        </lib-status-pill>
      </section>

      <lib-case-status-box
        *ngIf="statusKey !== 'INITIATED' && statusKey !== 'SUBMITTED'"
        [title]="statusBoxHeading | translate"
        [type]="statusBoxType"
      >
        <div [innerHTML]="statusBoxBody | translate"></div>
      </lib-case-status-box>
      <pra-certificate-number-section
        *ngIf="case && case.lob.id === 12"
      ></pra-certificate-number-section>
      <pra-web-form-table
        id="requiredFormsAccordionAnchor"
        [isHiddenLob]="isHiddenLob"
        (webFormsUpdated)="updateCaseInfo(case.id)"
        [requiredForms]="requiredRawAppForms"
        [requiredList]="requiredList"
      >
      </pra-web-form-table>
      <pra-other-forms
        id="additionalFormsAnchor"
        [isHiddenLob]="isHiddenLob"
        [otherForms]="otherRawAppForms"
      >
      </pra-other-forms>
      <pra-supporting-documents-upload
        id="supportingDocumentsAnchor"
        (documentsUpdated)="updateCaseInfo(case.id)"
        [isLobUpdated]="isLobUpdated"
        [isHiddenLob]="isHiddenLob"
        [showPaymentTable]="showPayment"
        [showMemberDocTable]="showMemberTable"
        [showAnchorDocTable]="showAnchorTable"
        [caseMemberId]="caseMemberId"
        [requiredDocumentOptions]="requiredDocumentOptions"
        [otherDocumentOptions]="otherDocumentOptions"
        [memberDocumentOptions]="memberDocumentOptions"
      ></pra-supporting-documents-upload>
      <pra-e-signature-declaration-table
        id="declarationAnchor"
        [isHiddenLob]="isHiddenLob"
        *ngIf="case.paWillSignWebform"
      >
      </pra-e-signature-declaration-table>
      <pra-wet-signature-declaration
        id="declarationAnchor"
        *ngIf="!case.paWillSignWebform"
      ></pra-wet-signature-declaration>
      <div
        class="submit-text"
        [innerHTML]="'INTAKE.APPLICATION_SUBMIT.BLOCK' | translate"
      ></div>

      <!-- Rep Declaration -->
      <!-- is a Rep? -->
      <ng-container *ngIf="isRep">
        <h3>{{ "INTAKE.REP_DECLARATION_TITLE" | translate }}</h3>
        <lib-spinner
          [showSpinner]="isProcessingRepDeclaration"
          [fullPage]="false"
        ></lib-spinner>
        <lib-layout-row gap="one-and-half">
          <lib-layout-col flex="1">
            <input
              value="true"
              id="repDeclaration"
              name="repDeclaration"
              [formControl]="form.controls.repDeclaration"
              [attr.disabled]="isProcessingRepDeclaration ? true : null"
              type="checkbox"
              (click)="handleRepDecalarationChange()"
            />
          </lib-layout-col>
          <div
            [innerHTML]="'INTAKE.REP_DECLARATION' | translate"
            for="repDeclaration"
            class="margin-fix"
          ></div>
        </lib-layout-row>
      </ng-container>

      <div class="intake-landing-page_submit-application">
        <button
          *ngIf="user.can('case:submit')"
          class="btn btn-primary submit-btn"
          type="button"
          (click)="openModal(submitApplicationId)"
          tabindex="0"
          [disabled]="!applicationReadyToSubmit"
        >
          {{ "INTAKE.APPLICATION_SUBMIT.BUTTON" | translate }}
        </button>
        <p
          *ngIf="isHiddenLob"
          class="inActiveLOB"
          aria-labelledby="'INTAKE.INACTIVE_LOB'"
        >
          {{ "INTAKE.INACTIVE_LOB" | translate }}
        </p>
        <div class="submit-messages">
          <ng-container *ngIf="displayMemberDocsWarning">
            <ircc-cl-lib-banner [config]="psrBannerConfig"></ircc-cl-lib-banner>
          </ng-container>
          <ng-container *ngIf="!applicationReadyToSubmit">
            <ircc-cl-lib-banner [config]="bannerConfig"></ircc-cl-lib-banner>
          </ng-container>
        </div>
      </div>
      <div class="readiness-container" *ngIf="!isProduction">
        <h5>Disabled in production</h5>
        <table class="table" id="readiness">
          <thead>
            <tr>
              <th>Requirement</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of Object.keys(readiness)"
              [ngClass]="{
                'red-text': readiness[item] === false,
                'green-text': readiness[item] === true
              }"
            >
              <td>{{ item }}</td>
              <td>{{ readiness[item] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
<lib-modal
  [id]="submitApplicationId"
  [title]="'INTAKE.LANDING_PAGE.SUBMIT_MODAL.HEADING' | translate"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ "INTAKE.LANDING_PAGE.SUBMIT_MODAL.TITLE" | translate }}
    </p>
    <div
      [innerHTML]="'INTAKE.LANDING_PAGE.SUBMIT_MODAL.TEXT' | translate"
    ></div>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" (click)="callToSubmitApplication()">
      {{ "INTAKE.LANDING_PAGE.SUBMIT_MODAL.SUBMIT" | translate }}
    </button>
    <button category="secondary" (click)="closeModal(submitApplicationId)">
      {{ "INTAKE.LANDING_PAGE.SUBMIT_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>

<lib-modal [id]="atCapacityId" [title]="'At Capacity'">
  <ng-container modalBody>
    <p>
      {{ "INTAKE.LANDING_PAGE.AT_CAPACITY_MODAL.BODY" | translate }}
      {{ "INTAKE.LANDING_PAGE.AT_CAPACITY_MODAL.HEADING" | translate }}
    </p>
    <a
      href="{{ 'INTAKE.LANDING_PAGE.AT_CAPACITY_MODAL.WEBLINK' | translate }}"
      >{{ "INTAKE.LANDING_PAGE.AT_CAPACITY_MODAL.LINKTEXT" | translate }}</a
    >
  </ng-container>
  <ng-container modalButtons>
    <button category="secondary" (click)="closeModal(atCapacityId)">
      {{ "INTAKE.LANDING_PAGE.AT_CAPACITY_MODAL.CLOSE" | translate }}
    </button>
  </ng-container>
</lib-modal>
