import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[libClickOutside]",
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @Output()
  public libClickOutside = new EventEmitter();

  @HostListener("document:click", ["$event.target"])
  public onClick(targetElement: ElementRef) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.libClickOutside.emit(null);
    }
  }
}
