<h2>
  <span class="declaration-table__required">*</span
  >{{ "INTAKE.DECLARATION.TABLE.TITLE" | translate }}
  <span class="declaration-table__required">{{
    "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
  }}</span>
</h2>
<div [innerHTML]="'INTAKE.DECLARATION.TABLE.DESCRIPTION' | translate"></div>

<div class="wrapper">
  <table class="table">
    <thead class="table__head">
      <tr>
        <th class="declaration-table__name">
          {{
            "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.DOCUMENT_NAME" | translate
          }}
        </th>
        <th class="declaration-table__desc">
          {{ "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.DETAILS" | translate }}
        </th>
        <th class="declaration-table__status">
          {{ "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.STATUS" | translate }}
        </th>
        <th class="declaration-table__actions">
          {{ "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.ACTION" | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="table__row">
        <td class="table__row-name">
          <p>
            <span class="declaration-table__consent-required">*</span
            >{{ "INTAKE.DECLARATION.TABLE.DOCUMENT_NAME" | translate }}
            <span class="declaration-table__consent-required">{{
              "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
            }}</span>
          </p>
        </td>
        <td class="table__row-details">
          <p>{{ "INTAKE.DECLARATION.TABLE.DOCUMENT_DETAILS" | translate }}</p>
        </td>
        <td class="table__row-status">
          <div *ngIf="declarationDocStatus">
            <lib-status-pill
              [text]="statusLabel | translate"
              [type]="status"
              [fontSize]="'0.8rem'"
            >
            </lib-status-pill>
          </div>
          <p *ngIf="!declarationDocStatus" class="visually-hidden">
            {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
          </p>
        </td>
        <td
          class="table__row-actions"
          [ngClass]="[
            (isPsr || isGar) && user.can('group-members:write') && !paInvited
              ? 'psr'
              : ''
          ]"
        >
          <button
            (click)="navigateSignatureDetailPage()"
            class="btn btn-primary-alt"
            [disabled]="
              isHiddenLob ||
              (!user.can('signature:write') && !user.is('representative'))
            "
          >
            {{ buttonText | translate }}
          </button>
          <div
            *ngIf="
              (isPsr || isGar) && user.can('group-members:write') && !paInvited
            "
          >
            <span>{{
              "INTAKE.DECLARATION.TABLE.WARNING_MUST_INVITE_PA.TEXT" | translate
            }}</span
            ><a
              [routerLink]="
                routeLocalizer.getNestedPath(lang, ['MANAGE_GROUP_MEMBERS'])
              "
              [innerHTML]="
                'INTAKE.DECLARATION.TABLE.WARNING_MUST_INVITE_PA.LINK_TEXT'
                  | translate
              "
            ></a
            >{{
              "INTAKE.DECLARATION.TABLE.WARNING_MUST_INVITE_PA.SUFFIX"
                | translate
            }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
