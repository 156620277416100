import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "@pr-applicant/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PrDevEnvGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (!environment.production) {
      return true;
    } else {
      this.router.navigate(["/"]);
      return false;
    }
  }
}
