<h1>
  {{
    (isClientUser ? "LOGIN.NEW_PORTAL_TITLE" : "LOGIN.NEW_REP_PORTAL_TITLE")
      | translate
  }}
</h1>
<div class="about">
  <h2 *ngIf="!isClientUser">
    {{ "LOGIN.NEW_REP_HEADER" | translate }}
  </h2>
  <p *ngIf="isClientUser" [innerHTML]="'LOGIN.NEW_BODY' | translate"></p>
  <p *ngIf="!isClientUser" [innerHTML]="'LOGIN.NEW_REP_BODY' | translate"></p>
  <div
    *ngIf="!isClientUser"
    [innerHTML]="'LOGIN.NEW_REP_BODY_2' | translate"
  ></div>
</div>

<pra-login (loadingVal)="checkLoadingValue($event)"></pra-login>

<div class="new-account">
  <p class="new-account__header">
    {{ "LOGIN.NEW_APPLICANT_HEADER" | translate }}
  </p>
  <a
    [routerLink]="routeLocalizer.get(currentLang, 'CLIENT_SIGNUP', '../')"
    category="secondary"
    type="button"
    class="new-account__btn"
  >
    {{ "LOGIN.CREATE_ACCOUNT" | translate }}
  </a>
  <p [innerHTML]="'LOGIN.UNSUPPORTED_BROWSER' | translate"></p>
</div>

<ng-container *ngIf="isClientUser; else repOtherInfo">
  <lib-layout-row mt="3" mb="3">
    <lib-information-area [config]="infoConfig">
      <p [innerHTML]="'LOGIN.MORE_INFO_BODY.SUBHEADING' | translate"></p>
      <ul class="more-info__list">
        <li
          class="more-info__list-item"
          *ngFor="let link of 'LOGIN.MORE_INFO_BODY.LIST' | translate"
        >
          <a class="external-link" target="_blank" [href]="link.URL"
            >{{ link.LABEL }}
            <span class="visually-hidden">{{
              "EXTERNAL_LINK" | translate
            }}</span>
          </a>
        </li>
      </ul>
    </lib-information-area>
  </lib-layout-row>
</ng-container>
<ng-template #repOtherInfo>
  <lib-layout-row mt="3" mb="3">
    <lib-information-area [config]="infoConfig">
      <p [innerHTML]="'LOGIN.MORE_INFO_BODY.SUBHEADING' | translate"></p>
      <ul class="more-info__list">
        <li
          class="more-info__list-item"
          *ngFor="let link of 'LOGIN.REP_MORE_INFO_BODY.LIST' | translate"
        >
          <a class="external-link" target="_blank" [href]="link.URL"
            >{{ link.LABEL }}
            <span class="visually-hidden">{{
              "EXTERNAL_LINK" | translate
            }}</span>
          </a>
        </li>
      </ul>
    </lib-information-area>
  </lib-layout-row>
</ng-template>
