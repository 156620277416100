import * as fromApp from "../../../../store/app.reducer";

import { ActivatedRoute, Router } from "@angular/router";
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertService, ModalService, documentTypes } from "lib";
import { first, take } from "rxjs/operators";

import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { CanComponentDeactivate } from "../../../../routing/guards/nav.guard";
import { Case } from "../../../../shared/case-module/case.model";
import { Document } from "../../../../core/models/document.model";
import { DocumentService } from "../../../../shared/services/document/document.service";
import { IMM0008AddDependantFormComponent } from "../../../forms/IMM0008/add-dependant-form/add-dependant-form.component";
import { MediaMatcher } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import FormNavbarItems from "../imm0008-dependants-form-navbar-items.json";

@Component({
  selector: "pra-imm0008-dependant",
  templateUrl: "./dependant.component.html",
  styleUrls: [
    "./dependant.component.scss",
    "../../../forms/components/form-nav-bar/form-nav-bar-page.scss",
  ],
})
export class IMM0008DependantComponent
  implements OnInit, AfterContentChecked, CanComponentDeactivate, OnDestroy
{
  @ViewChild("dependantsForm", { static: false })
  dependantsForm: IMM0008AddDependantFormComponent;
  @ViewChild("dependantFormId") dependantFormId: ElementRef<HTMLInputElement>;

  public lang: string;
  public formData: any;
  public formVersion: number;
  public navigationModalId: string = "navigationModal";

  public isFormSaved: boolean = false;
  public isFormSaving: boolean = false;
  public isLoading: boolean = true;
  public dependantId?: any;
  public selectedPage: string = "1";
  public pages: string[] = ["1", "2", "3", "4", "5"]; // pageId for all pages
  public currentFormValues: any;
  public FormNavbarItems = FormNavbarItems;

  private imm0008DocumentTypeId = documentTypes.imm0008.id;
  private formName = "dependantDetails";
  private imm8DocumentId?: number;
  private caseId: string;

  private formsTracker = {
    1: {
      name: "personalDetails",
      back: "0",
      next: "2",
      isValid: false,
    },
    2: {
      name: "educationOccupationDetails",
      back: "1",
      next: "3",
      isValid: false,
    },
    3: {
      name: "languageDetails",
      back: "2",
      next: "4",
      isValid: false,
    },
    4: {
      name: "passportDeatils",
      back: "3",
      next: "5",
      isValid: false,
    },
    5: {
      name: "nationalIdentityDetails",
      back: "4",
      next: "0",
      isValid: false,
    },
  };
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  // Track all forms values when going back and next
  private trackAllFormsValues = {
    personalDetails: null,
    educationOccupationDetails: null,
    languageDetails: null,
    passportDetails: null,
    nationalIdentityDetails: null,
  };

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private store: Store<fromApp.State>,
    private alertService: AlertService,
    private translate: TranslateService,
    private documentService: DocumentService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 992px)");
    this._mobileQueryListener = () => cdRef.detectChanges();
    this.mobileQuery.addEventListener("change", this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authService.checkSession();
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    // Get the dependant ID from the route
    this.dependantId = this.route.snapshot.paramMap.get("dependantId");

    if (this.dependantId) {
      this.getCaseFromStore();
    } else {
      this.navigateToDependantsList();
    }
  }

  ngAfterContentChecked() {
    // Detect any changes when the radio buttons change - this solved the error with disabled button
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener("change", this._mobileQueryListener);
  }

  // Todo: this function only work for first page back button since we don't change the page url when swithing between pages
  // need to make this component work as what we expected.
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    const isPersonalDetailsFormTouched =
      this.dependantsForm?.personalDetailsForm?.isFormTouched;
    const isEducationOccupationFormTouched =
      this.dependantsForm?.educationOccupationForm?.educationOccupationForm
        ?.touched;
    const isLanguageDetailsFormTouched =
      this.dependantsForm?.languageDetailsForm?.languageDetailsForm?.touched;
    const isPassportDetailsFormTouched =
      this.dependantsForm?.passportDetailsForm?.passportDetailsForm?.touched;
    const isNationalIdentityFormTouched =
      this.dependantsForm?.nationalIdentityForm?.nationalIdentityForm?.touched;
    switch (this.selectedPage) {
      case this.pages[0]:
        if (isPersonalDetailsFormTouched) {
          this.modalService.open(this.navigationModalId);
          return this.modalService.navigateAwaySelection$;
        } else {
          return true;
        }
      case this.pages[1]:
        if (isEducationOccupationFormTouched) {
          this.modalService.open(this.navigationModalId);
          return this.modalService.navigateAwaySelection$;
        } else {
          return true;
        }
      case this.pages[2]:
        if (isLanguageDetailsFormTouched) {
          this.modalService.open(this.navigationModalId);
          return this.modalService.navigateAwaySelection$;
        } else {
          return true;
        }
      case this.pages[3]:
        if (isPassportDetailsFormTouched) {
          this.modalService.open(this.navigationModalId);
          return this.modalService.navigateAwaySelection$;
        } else {
          return true;
        }
      case this.pages[4]:
        if (!this.isFormSaved && isNationalIdentityFormTouched) {
          this.modalService.open(this.navigationModalId);
          return this.modalService.navigateAwaySelection$;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  /*----------  PUBLIC ---------*/

  public get isAllFormsValid(): boolean {
    // Since the last form doesn't enable 'Next' button, we need to validate it here
    this.formsTracker["5"].isValid =
      this.dependantsForm?.nationalIdentityForm?.nationalIdentityForm?.valid;
    const formValid =
      this.formsTracker["1"].isValid &&
      this.formsTracker["2"].isValid &&
      this.formsTracker["3"].isValid &&
      this.formsTracker["4"].isValid &&
      this.formsTracker["5"].isValid;

    return formValid;
  }

  public setSelectedPage(pageNumber: string): void {
    this.selectedPage = pageNumber;
  }

  public getNextPageNumber(currentPage: string): string {
    // @ts-ignore
    return this.formsTracker[currentPage].next;
  }

  public getPreviousPageNumber(currentPage: string): string {
    // @ts-ignore
    return this.formsTracker[currentPage].back;
  }

  // This method will handle the click on Back navigation within the forms
  public async onBackButtonClick(): Promise<any> {
    this.isLoading = true;

    const flag = this.getCurrentFormTouched();
    if (flag) {
      this.modalService.open(this.navigationModalId);
      this.modalService.navigateAwaySelection$
        .pipe(take(1))
        .subscribe((value) => {
          if (value) {
            this.setSelectedPage(this.getPreviousPageNumber(this.selectedPage));
            this.dependantFormId?.nativeElement?.focus();
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
    } else {
      this.setSelectedPage(this.getPreviousPageNumber(this.selectedPage));
      this.dependantFormId?.nativeElement?.focus();
      this.isLoading = false;
    }
  }

  public async onNextButtonClick(): Promise<any> {
    this.isLoading = true;

    const isTouched = this.getCurrentFormTouched();

    if (isTouched) {
      // 1. Save and update form Data values
      await this.updateFormData();

      // 2. Update validity
      const formValidity = this.getFormValidityByPageNumber(this.selectedPage);
      this.setFormValidity(this.selectedPage, formValidity);

      if (this.caseId && this.imm8DocumentId) {
        const updatedForm = this.getFormValueByPageNumber(this.selectedPage);
        this.isFormSaving = true;
        try {
          await this.documentService
            .updateDependantFormByDocumentIdAndPage(
              this.caseId,
              this.imm8DocumentId,
              this.formName,
              this.dependantId,
              updatedForm,
              this.formVersion
            )
            .then((res) => {
              this.formVersion = res.updatedVersion;
              if (res?.hasApiError) {
                const errorStatus = res.error.response.status;
                if (errorStatus === 409) {
                  this.alertService.danger(
                    this.translate.instant("HOME.STEPS.ALERTS.DATA_SAVE_ERROR")
                  );
                } else {
                  this.alertService.danger(this.alertTechnicalError);
                }
              } else {
                if (this.selectedPage !== this.pages[4]) {
                  this.isFormSaving = false;
                  this.setSelectedPage(
                    this.getNextPageNumber(this.selectedPage)
                  );
                  this.dependantFormId?.nativeElement?.focus();
                  this.isLoading = false;
                } else {
                  this.isFormSaved = true;
                  this.navigateToDependantsList();
                }
              }
            });
        } catch (error) {
          console.log(error);
          this.alertService.danger(this.alertTechnicalError);
        }
      } else {
        this.navigateToDependantsList();
      }
    } else {
      if (this.selectedPage !== this.pages[4]) {
        this.setSelectedPage(this.getNextPageNumber(this.selectedPage));
        this.dependantFormId?.nativeElement?.focus();
        this.isLoading = false;
      } else {
        this.isFormSaved = true;
        this.navigateToDependantsList();
      }
    }
  }

  public async onNavButtonClick(selectedPage: number): Promise<any> {
    this.setSelectedPage((selectedPage + 2).toString());
    this.onBackButtonClick();
  }

  // This method is used to enable/disable 'Next' buttons based on the current form validation
  public get isCurrentFormValid(): boolean {
    return this.getFormValidityByPageNumber(this.selectedPage);
  }

  /*----------  PRIVATE ---------*/

  private getCaseFromStore(): void {
    this.store
      .select("selectedCase")
      .pipe(first())
      .subscribe((caseData: Case) => {
        if (caseData.id !== "" && caseData.documents) {
          this.caseId = caseData.id;
          const imm8Document = caseData.documents?.find(
            (doc: Document) => doc.documentTypeId === this.imm0008DocumentTypeId
          );
          this.imm8DocumentId = imm8Document?.id || undefined;
          this.getFormData();
        }
      });
  }

  private async getFormData(): Promise<any> {
    if (this.caseId && this.imm8DocumentId) {
      try {
        await this.documentService
          .getDependantFormByDocumentIdAndPage(
            this.caseId,
            this.imm8DocumentId,
            this.formName,
            this.dependantId
          )
          .then((formData) => {
            this.formData = formData;
            this.formVersion = formData.version;
            console.log("parentversion:", this.formVersion);
            this.trackAllFormsValues = {
              ...formData?.form,
            };
          });
        this.isLoading = false;
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
      }
    } else {
      this.alertService.danger(this.alertTechnicalError);
      this.navigateToDependantsList();
    }
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.ALERTS.TECHNICAL_ERROR");
  }

  private navigateToDependantsList() {
    // ammend next line as routes have been changed
    const nextPage = this.routeLocalizer.getNestedPath(
      this.lang,
      ["FORM_PAGE9"],
      "../../"
    );
    this.router.navigate([nextPage], { relativeTo: this.route });
  }

  private getFormValidityByPageNumber(pageNumber: string): boolean {
    switch (pageNumber) {
      case "1":
        return this.dependantsForm?.personalDetailsForm?.isFormValid;
      case "2":
        return this.dependantsForm?.educationOccupationForm
          ?.educationOccupationForm?.valid;
      case "3":
        return this.dependantsForm?.languageDetailsForm?.languageDetailsForm
          ?.valid;
      case "4":
        return this.dependantsForm?.passportDetailsForm?.passportDetailsForm
          ?.valid;
      case "5":
        return this.dependantsForm?.nationalIdentityForm?.nationalIdentityForm
          ?.valid;
      default:
        return false;
    }
  }

  // This method is to get the form values by page number
  private getFormValueByPageNumber(pageNumber: string): any {
    switch (pageNumber) {
      case "1":
        return {
          personalDetails:
            this.dependantsForm?.personalDetailsForm?.preparedFormValue,
        };
      case "2":
        return {
          educationOccupationDetails:
            this.dependantsForm?.educationOccupationForm
              ?.educationOccupationForm?.value,
        };
      case "3":
        return {
          languageDetails:
            this.dependantsForm?.languageDetailsForm?.languageDetailsForm
              ?.value,
        };
      case "4":
        return {
          passportDetails:
            this.dependantsForm?.passportDetailsForm?.getFormatedFormValues(),
        };
      case "5":
        return {
          nationalIdentityDetails:
            this.dependantsForm?.nationalIdentityForm?.getFormatedFormValues(),
        };
      default:
        return null;
    }
  }

  private getCurrentFormTouched(): boolean {
    const isPersonalDetailsFormTouched =
      this.dependantsForm?.personalDetailsForm?.isFormTouched;
    const isEducationOccupationFormTouched =
      this.dependantsForm?.educationOccupationForm?.educationOccupationForm
        ?.touched;
    const isLanguageDetailsFormTouched =
      this.dependantsForm?.languageDetailsForm?.languageDetailsForm?.touched;
    const isPassportDetailsFormTouched =
      this.dependantsForm?.passportDetailsForm?.passportDetailsForm?.touched;
    const isNationalIdentityFormTouched =
      this.dependantsForm?.nationalIdentityForm?.nationalIdentityForm?.touched;
    switch (this.selectedPage) {
      case this.pages[0]:
        return isPersonalDetailsFormTouched;
      case this.pages[1]:
        return isEducationOccupationFormTouched;
      case this.pages[2]:
        return isLanguageDetailsFormTouched;
      case this.pages[3]:
        return isPassportDetailsFormTouched;
      case this.pages[4]:
        return isNationalIdentityFormTouched;
      default:
        return false;
    }
  }

  // This method will set the form validity in formTracker to update the over All forms validity
  // for the last button to save form
  private setFormValidity(pageNumber: string, value: boolean): void {
    // @ts-ignore
    this.formsTracker[pageNumber].isValid = value;
  }

  private async updateFormData(): Promise<any> {
    const formValues = await this.getFormValueByPageNumber(this.selectedPage);

    // 1. Update the form values to track ALL form values while navigating between
    // them to submit at the last page
    // {personalDetail: {...}, passportDetails: {...}, ...}
    this.trackAllFormsValues = await {
      ...this.trackAllFormsValues,
      ...formValues,
    };

    // 2: update the formData values that will be passed to form component
    //  { id: , caseId: list: { }, form: { personalDetails:{...}, {...} }}
    this.formData.form = await this.trackAllFormsValues;
  }
}
