import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, FormSelectOption, ValidationService } from "lib";
import { AuthService } from "../../../core/auth-module/services/auth.service";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import { CaseService } from "../../../shared/case-module/case.service";
import * as fromApp from "../../../store/app.reducer";
import { AddressService } from "../address.service";
import { provinces } from "../provinces.data";
import { Router } from "@angular/router";
import routePaths from "../../../routing/route-paths";
import { Reason } from "../../../core/models/document.model";

@Component({
  selector: "pra-address-page",
  templateUrl: "./address-page.component.html",
  styleUrls: ["./address-page.component.scss"],
})
export class AddressPageComponent implements OnInit {
  public addressForm = this.fb.group({
    mailingAddress1: [
      "",
      [Validators.required, this.validationService.validatorNotWhitespace],
    ],
    mailingAddress2: [""],
    city: [
      "",
      [Validators.required, this.validationService.validatorNotWhitespace],
    ],
    province: [
      null,
      [Validators.required, this.validationService.validatorNotWhitespace],
    ],
    postalCode: [
      "",
      [
        Validators.required,
        this.validationService.validatorCanadianPostalCode,
        this.validationService.validatorNotWhitespace,
      ],
    ],
  });

  public case: any;

  public declarationChecked = false;
  public addressSelfDeclaration: boolean;
  public provinces: FormSelectOption[];
  public currentLang: string;
  public rejectionReasons: string[] = [];

  constructor(
    private addressService: AddressService,
    private alertService: AlertService,
    private caseService: CaseService,
    private fb: UntypedFormBuilder,
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private validationService: ValidationService,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("ADDRESS.PAGE_TITLE");

    this.store.select("selectedCase").subscribe((caseData) => {
      this.case = caseData;
      this.getFormDataIfSaved();
    });
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.provinces = provinces;
    this.getTranslatedReasons();
    this.authService.checkSession();
  }

  public get nameParam(): object {
    const name =
      this.case.givenName !== null
        ? `${this.case.givenName} ${this.case.surname}`
        : this.case.surname;
    return {
      name,
    };
  }

  getFormDataIfSaved() {
    if (this.addressForm.controls.mailingAddress1) {
      this.addressForm.controls.mailingAddress1.setValue(
        this.case.mailingAddress1
      );
    }
    if (this.addressForm.controls.mailingAddress2) {
      this.addressForm.controls.mailingAddress2.setValue(
        this.case.mailingAddress2
      );
    }
    if (this.addressForm.controls.city) {
      this.addressForm.controls.city.setValue(this.case.city);
    }
    if (this.addressForm.controls.province) {
      this.addressForm.controls.province.setValue(this.case.province);
    }
    if (this.addressForm.controls.postalCode) {
      this.addressForm.controls.postalCode.setValue(this.case.postalCode);
    }
  }

  public enableNext(): boolean {
    //Scenario 1: if all the fields are valid and there is no rejection reason - enable next
    if (this.addressForm.valid && this.rejectionReasons.length == 0) {
      return !this.addressForm.valid;
    } else {
      //Scenario 2: if any of the form field is touched and there is a rejection reason - enable next
      if (
        this.addressForm.valid &&
        this.addressForm.touched &&
        this.rejectionReasons.length > 0
      ) {
        this.rejectionReasons = [];
        return !this.addressForm.valid;
      } else {
        return this.addressForm.valid;
      }
    }
  }

  public onSubmit() {
    this.addressService
      .updateAddress(this.addressForm.value)
      .then(() => {
        this.caseService.getCaseAndDocumentsByCaseId(
          this.case.emailAddress,
          this.case.id
        ); // syncing state
      })
      .then(() => {
        const stringLang = this.currentLang === "fr" ? "fr" : "en";
        const translatedRoute = `${routePaths[stringLang].CONFIRMATION_CASES}/${routePaths[stringLang].CONFIRMATION_CASE_DETAILS}`;
        this.router.navigateByUrl(
          `/${this.currentLang}/${translatedRoute}/${this.case.id}`
        );
        this.alertService.success(this.alertAddressUpdated);
      })
      .catch((error) => {
        this.alertService.danger(this.alertTechnicalError);
      });
  }

  private getTranslatedReasons(): void {
    this.caseService
      .getAddressRejectionReasonsByCaseId(this.case.id)
      .then((reasons) => {
        const langKey =
          this.currentLang === "fr" ? "rejectionReasonFr" : "rejectionReasonEn";
        const selectedReasons = reasons.filter(
          (r: Reason) => Boolean(r.selected) === true
        );
        this.rejectionReasons = selectedReasons.map((r: Reason) => r[langKey]);
      });
  }

  private get alertAddressUpdated(): string {
    return this.translate.instant("ADDRESS.ALERTS.SUCCESS", this.nameParam);
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("ADDRESS.ALERTS.TECHNICAL_ERROR");
  }
}
