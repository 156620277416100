import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import { Subscription, filter, first, timer } from "rxjs";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import {
  PsrService,
  RoleIds,
  IGroupMember,
} from "@pr-applicant/app/shared/services/psr/psr.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@pr-applicant/app/core/auth-module/services/auth.service";
import { AlertService, ModalService } from "lib";

@Component({
  selector: "pra-member-card",
  templateUrl: "./member-card.component.html",
  styleUrls: ["./member-card.component.scss"],
})
export class MemberCardComponent implements OnInit {
  public lang: string;
  public memberDocs: RelatedDocumentType[] = [];
  public caseMembers: any;
  public caseData: Case;
  public caseId: string;
  public isProcessing = false;
  public deleteModalId = "confirmDeleteMember"; // + this.caseMemberId;
  public status: "NOT_STARTED" | "IN_PROGRESS" | "COMPLETE" = "NOT_STARTED";
  public RoleIds = RoleIds;
  public statusMapToPillType = {
    NOT_STARTED: "notStarted",
    IN_PROGRESS: "initiated",
    COMPLETE: "complete",
  };
  public statusMapToText = {
    NOT_STARTED: "PSR.MEMBER_STATUS.STATUS_NOT_STARTED",
    IN_PROGRESS: "PSR.MEMBER_STATUS.STATUS_INITIATED",
    COMPLETE: "PSR.MEMBER_STATUS.STATUS_COMPLETE",
  };
  private subscriptions: Subscription[] = [];

  @Input() member: IGroupMember;
  @Input() caseMemberId: number;
  @Input() index: any;
  @Input() showMoreDetails: boolean;
  @Input() showActions: boolean;
  @Input() canDelete: boolean;
  @Output() removeGroupMember = new EventEmitter<any>();

  constructor(
    private store: Store<fromApp.State>,
    public routeLocalizer: RouteLocalizerService,
    private psrService: PsrService,
    private translate: TranslateService,
    private auth: AuthService,
    private alertService: AlertService,
    public modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    if (this.caseMemberId) this.deleteModalId += this.caseMemberId;
    this.subscriptions.push(
      this.store
        .select("selectedCase")
        .pipe(filter((caseData) => !!caseData))
        .subscribe((caseData: Case) => {
          this.caseId = caseData.id;
          this.caseData = caseData;
          this.caseMembers = caseData.members;
          this.memberDocs = caseData.lob?.relatedDocumentTypes
            ?.filter((doc: RelatedDocumentType) => doc.isMemberDocument)
            .map((doc: RelatedDocumentType) => {
              if (doc.form)
                return {
                  documentTypeId: doc.id,
                  documentType:
                    this.lang === "fr"
                      ? doc.documentTypeFr
                      : doc.documentTypeEn,
                  formId: doc.form.id,
                  name: this.lang === "fr" ? doc.form.nameFr : doc.form.nameEn,
                  details:
                    this.lang === "fr"
                      ? doc.form.detailsFr
                      : doc.form.detailsEn,
                  link: this.lang === "fr" ? doc.form.linkFr : doc.form.linkEn,
                  // attachments,
                  // displayWarningMsg,
                  value: doc.id,
                  isRequired: doc.isRequired,
                  text:
                    this.lang === "en"
                      ? doc.documentTypeEn
                      : doc.documentTypeFr,
                };
              return {
                value: doc.id,
                isRequired: doc.isRequired,
                documentTypeId: doc.id,
                text:
                  this.lang === "en" ? doc.documentTypeEn : doc.documentTypeFr,
              };
            });
        })
    );
  }

  uploadDocument(index: any) {
    console.log("upload document for member index: ", index);
  }

  getRoleNameById(id: number): string {
    return this.psrService.getRoleNameById(id);
  }

  public openModal() {
    this.modalService.open(this.deleteModalId);
  }

  public deleteMember() {
    this.isProcessing = true;
    this.psrService
      .deleteGroupMember(Number(this.caseId), this.caseMemberId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.removeGroupMember.emit(this.caseMemberId);
          this.modalService.close(this.deleteModalId);
          this.isProcessing = false;
          this.alertService.success(
            this.translate.instant(
              "PSR.MANAGE_GROUP_MEMBERS_PAGE.REMOVE_MEMBER_SUCCESS"
            )
          );
        },
        error: (error) => {
          this.modalService.close(this.deleteModalId);
          this.alertService.danger(
            this.translate.instant("HOME.STEPS.ALERTS.TECHNICAL_ERROR")
          );
        },
      });
  }

  closeModal(): void {
    this.modalService.close(this.deleteModalId);
  }

  public handleUpdate() {}
}
