<div class="inline-block">
  <button
    *ngIf="status === 'READY' || status === 'ERROR'"
    category="plain"
    [ngClass]="{ leftAlignNoHover: removeHoverEffect }"
    (click)="resendPassword(caseMemberId)"
    type="button"
  >
    {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.RESEND_PASSWORD" | translate }}
  </button>
  <button
    *ngIf="status === 'LOADING'"
    category="plain"
    [ngClass]="{ leftAlignNoHover: removeHoverEffect }"
    type="button"
    disabled="disabled"
  >
    {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.RESEND_PASSWORD" | translate }}
  </button>
  <div
    [ngClass]="{
      hide: status !== 'LOADING',
      show: status === 'LOADING'
    }"
  >
    <i class="fa-regular fa-spinner-third icon"></i>
  </div>
  <div *ngIf="status === 'COOLDOWN'" class="show">
    <ircc-cl-lib-banner [config]="bannerConfig"></ircc-cl-lib-banner>
  </div>
</div>
