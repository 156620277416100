import { Component, OnInit, Input } from "@angular/core";

interface IInformationAreaConfig {
  icon: string;
  header: string | null;
  listItems?: Array<string>;
  type: string;
}

const DEFAULT: IInformationAreaConfig = {
  icon: "infoRegular",
  header: null,
  type: "info",
};

@Component({
  selector: "lib-information-area",
  templateUrl: "./information-area.component.html",
  styleUrls: ["./information-area.component.scss"],
})
export class InformationAreaComponent implements OnInit {
  constructor() {}

  @Input() config: IInformationAreaConfig = DEFAULT;

  get icon() {
    return this.config.icon;
  }

  get iconClass() {
    return `information-area__icon information-area__icon--${this.config.type}`;
  }

  ngOnInit(): void {}
}
