import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import {
  ValidationService,
  FormSelectOption,
  AlertService,
  ModalService,
} from "lib";
import * as fromApp from "../../../../../store/app.reducer";
import { RouteLocalizerService } from "../../../../../routing/route-localizer.service";
import { DocumentService } from "../../../../../shared/services/document/document.service";
import { DownloadService } from "../../../../../shared/services/download.service";
import {
  DOC_LIMITS,
  documentSizes,
  documentTypes,
  uploadFileTypes,
  casePhases,
} from "lib";
import { Document } from "../../../../../core/models/document.model";
import { DOC_UPLOAD_ERROR_COPY } from "./documents_upload_models";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { IBannerConfig } from "ircc-ds-angular-component-library";

@Component({
  selector: "pra-documents-upload-table",
  templateUrl: "./documents-upload-table.component.html",
  styleUrls: ["./documents-upload-table.component.scss"],
})
export class DocumentsUploadTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isHiddenLob: boolean = false;
  @Input() deleteModalIdAnchor = "";

  public case: any;
  public caseId: string;
  public caseStatusId: number;
  public documents: any[] = [];
  public allDocuments: any[] = [];
  public documentTypeOptions: any[];
  public isLoading: boolean = false;
  public lobId: number | null;
  public lang: string;
  public lastDeletedFileType?: string; // to track the deleted file type to pass to the alert
  public lastUploadedFileType: string; // to track the uploaded file type to pass to the alert
  public supplementalDocsIds: number[] = [];
  public subscriptions: Subscription[] = [];
  public loadingDocs: boolean = true;

  // Constants
  public acceptedFileTypes = uploadFileTypes.supportingDocuments.types;
  public maxFileSize = documentSizes.forms.maxFileSize;
  public megaFileConversion = documentSizes.forms.megaFileConversion;
  public maxFileNameLength = 180;

  // delete
  public deleteModalId = "confirmDeleteSupportingDoc";
  public tempDeleteDocument?: Document;
  public tempDeleteDocumentType?: string;
  public ERROR_COPY = DOC_UPLOAD_ERROR_COPY;

  public documentTypeForm = this.fb.group({
    documentType: [
      null,
      [Validators.required, this.validationService.validatorNotWhitespace],
    ],
  });

  //map the document id to the specified tooltip content
  public toolTipContent: any = {
    "8": "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.IDENTITY_AND_CIVIL_STATUS_DOCUMENTS",
    "999":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_STATUS",
    "175":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.EMPLOYMENT/SOURCE_OF_INCOME",
    "177":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_RELATIONSHIP_TO_SPONSOR",
    "161":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_WORK_EXPERIENCE_IN_CANADA",
    "162":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_YOUR_ABILITY_TO_PERFORM_WORK",
    "137":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_PREVIOUS_RELEVANT_WORK_EXPERIENCE",
    "191":
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_TABLE.TOOLTIP_CONTENTS.PROOF_OF_FEDERAL_EMPP_PUBLIC_POLICY_ELIGIBILITY",
  };
  private validateDocs: any[] = [];

  @Output() supportDocumentsUpdated: EventEmitter<any> = new EventEmitter();
  @Input() requiredDocumentOptions: FormSelectOption[] = [];
  @Input() caseMemberId?: number | undefined;
  @Input() status: "NOT_STARTED" | "IN_PROGRESS" | "COMPLETE" = "NOT_STARTED";
  @Output() statusChange: EventEmitter<
    "NOT_STARTED" | "IN_PROGRESS" | "COMPLETE"
  > = new EventEmitter();
  constructor(
    public store: Store<fromApp.State>,
    public fb: UntypedFormBuilder,
    public validationService: ValidationService,
    public translate: TranslateService,
    public alertService: AlertService,
    public downloadService: DownloadService,
    public routeLocalizer: RouteLocalizerService,
    public documentService: DocumentService,
    public modalService: ModalService,
    public user: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    if (this.caseMemberId) this.deleteModalId += this.caseMemberId;
    if (this.deleteModalIdAnchor)
      this.deleteModalId += this.deleteModalIdAnchor;
    this.getStoredCase();
    this.updateSupportingDocuments().then(() => {
      this.loadingDocs = false;
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.requiredDocumentOptions.length > 0) {
      this.validateDocs = this.requiredDocumentOptions.filter(
        (doc: any) => doc.isRequired
      );
      this.filterSupportingDocuments();
    }
  }

  /* ------- PUBLIC ------------*/
  public file_size: string;
  public file_type: string;
  public file_name_length: string;

  public tempAttachments: { [key: number]: any[] } = {};
  public tempFileRowIndex: number = NaN;

  public isFileValid(file: File): boolean {
    const fileType = file.type;
    const acceptedFileTypes = this.acceptedFileTypes;
    const fileSizeValid = file && file.size <= this.maxFileSize;
    const fileTypeValid = fileType && acceptedFileTypes.includes(fileType);
    const maxFileNameLength = file.name.length <= this.maxFileNameLength;
    const validFileNameForUpload =
      this.validationService.isValidFileNameForUpload(file.name);

    if (
      !fileSizeValid ||
      !fileTypeValid ||
      !maxFileNameLength ||
      !validFileNameForUpload
    ) {
      if (this.lang === "en") {
        const en_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.en),
            (this.file_type = item.FILE_TYPE.en),
            (this.file_name_length = item.FILE_NAME_LENGTH.en);
        });
      } else {
        const fr_lang_errors = this.ERROR_COPY.map((item) => {
          (this.file_size = item.FILE_SIZE.fr),
            (this.file_type = item.FILE_TYPE.fr),
            (this.file_name_length = item.FILE_NAME_LENGTH.fr);
        });
      }
      if (fileSizeValid === false) {
        this.setTempFileErrorMessage(this.tempFileRowIndex, this.file_size);
      }
      if (validFileNameForUpload === false) {
        if (this.lang === "en") {
          this.setTempFileErrorMessage(
            this.tempFileRowIndex,
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.en
          );
        } else {
          this.setTempFileErrorMessage(
            this.tempFileRowIndex,
            this.ERROR_COPY[0].FILE_NAME_CHARACTERS.fr
          );
        }
      }
      if (fileTypeValid === false) {
        this.setTempFileErrorMessage(this.tempFileRowIndex, this.file_type);
      }
      if (maxFileNameLength === false) {
        this.setTempFileErrorMessage(
          this.tempFileRowIndex,
          this.file_name_length
        );
      }
      return false;
    }
    return true;
  }

  // This method will upload a new file and then update the list of available files
  // in the table
  public async uploadFile(
    e: { target?: HTMLInputElement },
    documentTypeId: number,
    indexOfRow?: number,
    attachmentName?: string,
    caseMemberId?: number
  ): Promise<any> {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const fileValid = this.isFileValid(file);
    // Get the option value and convert it to a number for the API
    const tempAttachments = indexOfRow ? this.tempAttachments[indexOfRow] : [];
    const tempAttatchmentLoading = tempAttachments?.filter(
      (attachment) => attachment.state === "loading"
    );
    const tempAttachmentCount =
      tempAttatchmentLoading.length === 0
        ? 0
        : tempAttatchmentLoading.length - 1; // minus 1 since target doc is in the list
    const docCount =
      this.totalSupportingDocs(documentTypeId) + tempAttachmentCount;
    if (!fileValid) return;
    let tooManyDocs = false;
    if (
      documentTypeId != documentTypes.Other.id &&
      docCount >= DOC_LIMITS.SUPPORTING_DOCS
    )
      tooManyDocs = true;
    if (
      documentTypeId == documentTypes.Other.id &&
      docCount >= DOC_LIMITS.OTHER_SUPPORTING_DOCS
    )
      tooManyDocs = true;
    if (
      caseMemberId &&
      documentTypeId != documentTypes.Other.id &&
      docCount >= DOC_LIMITS.MEMBER_DOCS.DEFAULT
    )
      tooManyDocs = true;
    if (
      caseMemberId &&
      documentTypeId == documentTypes.Other.id &&
      docCount >= DOC_LIMITS.MEMBER_DOCS.OTHER
    )
      tooManyDocs = true;

    if (tooManyDocs) {
      this.alertService.danger(this.alertMaximumSupportingDocs);
      if (indexOfRow !== undefined && attachmentName !== undefined)
        this.removeTempFileLoading(indexOfRow, attachmentName);
      return;
    }

    try {
      this.isLoading = true;
      await this.documentService.uploadNewFileByDocumentTypeId(
        this.caseId,
        file,
        documentTypeId,
        caseMemberId
      );
      await this.updateSupportingDocuments();
      this.isLoading = false;
      this.documentsUpdated();
      this.alertService.success(this.alertSuccess);
      if (indexOfRow !== undefined && attachmentName !== undefined)
        this.removeTempFileLoading(indexOfRow, attachmentName);
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
      this.isLoading = false;
      if (indexOfRow !== undefined && attachmentName !== undefined)
        this.removeTempFileLoading(indexOfRow, attachmentName);
    }
  }
  public setTempFileInfoAndUpload(
    e: any,
    indexOfRow: number,
    documentTypeId: number
  ): void {
    if (!this.tempAttachments[indexOfRow]) {
      this.tempAttachments[indexOfRow] = [];
    }
    const newTempAttachment = {
      state: "loading",
      fileName: e.target?.files[0].name || "",
      error: "",
    };
    this.tempAttachments[indexOfRow].push(newTempAttachment);
    this.tempFileRowIndex = indexOfRow;
    this.uploadFile(
      e,
      documentTypeId,
      indexOfRow,
      newTempAttachment.fileName,
      this.caseMemberId
    );
  }

  public setTempFileErrorMessage(index: number, error: string) {
    const length = this.tempAttachments[index].length;
    this.tempAttachments[index][length - 1].error = error;
    this.tempAttachments[index][length - 1].state = "error";
  }

  public removeTempFileError(rowIndex: number, attachmentIndex: number) {
    this.tempAttachments[rowIndex].splice(attachmentIndex, 1);
  }

  public removeTempFileLoading(rowIndex: number, fileName: string): void {
    const removeTempFileIndex = this.tempAttachments[rowIndex].findIndex(
      (tempAttachment) => tempAttachment.fileName === fileName
    );
    if (removeTempFileIndex > -1) {
      this.tempAttachments[rowIndex].splice(removeTempFileIndex, 1);
    }
  }

  public openDeleteModal(document?: Document, documentType?: string): void {
    this.modalService.open(this.deleteModalId);
    this.tempDeleteDocument = document;
    this.tempDeleteDocumentType = documentType;
  }

  public closeModal(): void {
    this.modalService.close(this.deleteModalId);
    this.tempDeleteDocument = undefined;
    this.tempDeleteDocumentType = "";
  }

  // This method deletes a specific file and updates the list of documents
  // First it will check if the confirmation modal has been opened yet
  public async deleteFile(): Promise<any> {
    if (this.tempDeleteDocument && !this.isLoading) {
      this.isLoading = true;
      await this.documentService
        .deleteFileByDocumentTypeAndId(
          this.caseId,
          Number(this.tempDeleteDocument.documentTypeId),
          this.tempDeleteDocument.id
        )
        .then((res) => {
          this.documents = this.documents?.filter(
            (doc) => doc.rawDocument.id != this.tempDeleteDocument?.id
          );
          this.allDocuments = this.allDocuments.filter(
            (doc) => doc.id != this.tempDeleteDocument?.id
          );
          this.lastDeletedFileType = this.tempDeleteDocumentType;
          this.alertService.success(this.alertDeleteSuccess);
          this.isLoading = false;
          this.updateSupportingDocuments();
          this.documentsUpdated();
          this.closeModal();
        })
        .catch((err) => {
          this.alertService.danger(this.alertTechnicalError);
          this.isLoading = false;
          this.closeModal();
        });
    }
  }

  // This method downloads a selected file based on the document passed in
  public async downloadFile(document: Document) {
    const downloadName = document.documentName.split("/").pop(); // the last string is the filename (incase its in a folder)
    try {
      await this.downloadService.downloadDocument(
        document.caseId,
        document.id,
        downloadName
      );
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // This method will disable the upload button when there is no selection yet
  public disableUpload(): boolean {
    if (this.isHiddenLob) {
      return true;
    }
    return false;
  }

  // This method handles the change of the select option to capture the last uploaded file
  public handleSelectChanges(e: { target?: HTMLSelectElement }): void {
    // Get the last uploaded file text to add to the alert message
    const target = e.target as HTMLSelectElement;
    if (target) {
      const selectedOptionText = target?.selectedOptions[0]?.innerText;
      this.lastUploadedFileType = selectedOptionText;
    }
  }

  public get alertSuccess(): string {
    return this.translate.instant(
      "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_SUCCESS",
      { documentType: this.lastUploadedFileType }
    );
  }

  public get alertTechnicalError(): string {
    return this.translate.instant("INTAKE.SUPPORTING_DOCUMENTS.UPLOAD_ERROR");
  }

  public get alertMaximumSupportingDocs(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }

  public get alertMaximumOtherSupportingDocs(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }

  public get alertDeleteSuccess(): string {
    return this.translate.instant(
      "INTAKE.SUPPORTING_DOCUMENTS.DELETE_SUCCESS",
      { documentType: this.lastDeletedFileType }
    );
  }

  public get friendlyMaxFileSize(): string {
    return `${this.maxFileSize / this.megaFileConversion}`;
  }

  public get alertFileNameExeedsMaxLength(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LENGTH");
  }

  public totalSupportingDocs(documentTypeId: number): number {
    const onlyUploadedSupportingDoc = this.documents?.filter((doc: any) => {
      if (doc.rawDocument.documentTypeId === documentTypeId) {
        return doc;
      }
    });
    return onlyUploadedSupportingDoc.length;
  }

  public getStoredCase(): void {
    this.subscriptions.push(
      this.store.select("selectedCase").subscribe((caseData) => {
        if (caseData) {
          this.case = caseData;
          this.caseId = this.case.id;
          this.caseStatusId = this.case.caseStatusId;
        }
      })
    );
  }

  public filterSupportingDocuments(): void {
    this.supplementalDocsIds = this.requiredDocumentOptions.map(
      (option) => option.value
    );
    const supplementalDocs = this.allDocuments?.filter((doc: Document) =>
      this.supplementalDocsIds.includes(doc.documentTypeId)
    );

    let supplementalDocsTableRows = supplementalDocs?.map((doc: Document) =>
      this.formatDocumentForTable(doc)
    );

    if (this.caseMemberId) {
      supplementalDocsTableRows = supplementalDocsTableRows.filter(
        (doc) => doc.rawDocument.caseMemberId === this.caseMemberId
      );
    } else {
      supplementalDocsTableRows = supplementalDocsTableRows.filter(
        (doc) => !doc.rawDocument.caseMemberId
      );
    }
    this.documents = supplementalDocsTableRows;
    this.validateDocuments();
  }

  // Update uploaded files shown in the table
  public async updateSupportingDocuments(): Promise<void> {
    try {
      this.allDocuments = await this.documentService.getDocumentByCaseId(
        this.caseId
      );
      this.filterSupportingDocuments();
    } catch (error) {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // Restructure the document object that will be displyed in the table
  public formatDocumentForTable(document: Document): any {
    const documentStatusType = this.documentService.checkDocumentOrFormStatus(
      document,
      this.caseStatusId
    );
    const statusLabel = `INTAKE.FORM_DOCUMENT.STATUS_${documentStatusType.toUpperCase()}`;
    const documentName = document.documentName.substring(
      document.documentName.lastIndexOf("/") + 1
    );
    const documentType =
      this.lang === "fr"
        ? document.documentType?.documentTypeFr
        : document.documentType?.documentTypeEn;

    return {
      documentType,
      documentName,
      statusType: documentStatusType,
      statusLabel,
      rawDocument: document,
    };
  }

  public documentsUpdated() {
    this.validateDocuments();
    this.supportDocumentsUpdated.emit(true);
  }

  public validateDocuments() {
    const validationStatuses = this.validateDocs.map((docToValidate) => {
      if (!this.documents) return false;
      const attachments = this.getFilesByDocumentType(
        docToValidate.documentTypeId
      );
      if (attachments.length === 0) return false;
      return attachments[0].statusType === "incomplete" ? false : true;
    });
    //Other doc type is not required but if the document is returned it needs to show the right status pill
    const attachments = this.getFilesByDocumentType(documentTypes.Other.id);
    if (attachments.length) {
      validationStatuses.push(
        attachments.every((doc) => doc.statusType !== "incomplete")
      );
    }
    if (validationStatuses.every((status) => status)) this.status = "COMPLETE";
    else if (
      validationStatuses.some((status) => status) ||
      this.documents.length > 0
    )
      this.status = "IN_PROGRESS";
    else this.status = "NOT_STARTED";
    this.statusChange.emit(this.status);
  }

  public getFilesByDocumentType(docTypeId: number): any[] {
    const files = this.documents.filter((file: any) => {
      return file.rawDocument.documentTypeId === docTypeId;
    });
    return files;
  }

  public get bannerConfig(): IBannerConfig {
    return {
      id: "otherDocBanner",
      content: this.translateService.instant(
        "PSR.MANAGE_GROUP_MEMBERS_PAGE.DOC_TABLE_OTHER_DOC_NOTE"
      ),
      rounded: true,
      type: "info",
    };
  }
}
