import { Component, OnInit, Input } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";

@Component({
  selector: "lib-debug",
  templateUrl: "./debug.component.html",
  styleUrls: ["./debug.component.scss"],
})
export class DebugComponent implements OnInit {
  constructor() {}

  @Input() control: FormControl | AbstractControl;
  @Input() validators: any[] = [];

  ngOnInit(): void {}
}
