<lib-copy-block [body]="'INTAKE.IMM5669.PAGES.PAGE_DESCRIPTION_2' | translate">
</lib-copy-block>
<lib-copy-block
  [heading]="'INTAKE.IMM5669.FORMS.SECTION_B.FORM_HEADING' | translate"
  [body]="'INTAKE.IMM5669.FORMS.SECTION_B.FORM_INSTRUCTIONS' | translate"
>
</lib-copy-block>

<form autocomplete="off">
  <fieldset *ngFor="let question of questionnaireFields; let i = index">
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        i +
        1 +
        '. ' +
        ('INTAKE.IMM5669.FORMS.FIELDS.QUESTIONNAIRE.' + question.copyKey
          | translate)
      "
      [firstId]="question.controlName + '_yes'"
      [secondId]="question.controlName + '_no'"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM5669.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM5669.FORMS.FIELDS.RADIO_NO' | translate"
      [nameVal]="question.controlName"
      [formControl]="sectionBForm.get(question.controlName)"
    >
    </lib-form-radio>
  </fieldset>

  <lib-form-textarea
    [editable]="user.can('documents:write')"
    identifier="additionalDetails"
    [label]="'INTAKE.IMM5669.FORMS.FIELDS.ADDITIONAL_DETAILS' | translate"
    [formControl]="sectionBForm.controls.additionalDetails"
    [editable]="user.can('documents:write')"
  >
    <span
      *ngIf="
        sectionBForm.get('additionalDetails')?.touched &&
        sectionBForm.get('additionalDetails')?.hasError('maxlength')
      "
    >
      {{
        "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : additionalDetailsMaxChar
      }}
    </span>
  </lib-form-textarea>
</form>
