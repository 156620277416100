<ng-container *ngIf="!isLoading">
  <!-- Form -->
  <!-- Dependants Table -->
  <lib-information-box [config]="informationBoxConfig">
    <p class="information-box-text">
      {{ "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX" | translate }}
      <a
        [routerLink]="
          routeLocalizer.getNestedPath(
            lang,
            ['INTAKE', 'PROFILE'],
            '../../../../../'
          ) + formData.caseId
        "
        >{{
          "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX_LINK" | translate
        }}</a
      >
    </p>
  </lib-information-box>
  <div class="dependants-table__container table-responsive">
    <table
      class="table dependants-table"
      *ngIf="yesRadioSelected && !isLoading"
    >
      <thead>
        <tr>
          <th scope="col">
            {{
              "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.LAST_NAME" | translate
            }}
          </th>
          <th scope="col">
            {{
              "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.GIVEN_NAME" | translate
            }}
          </th>
          <th scope="col">
            {{ "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.DOB" | translate }}
          </th>
          <th scope="col">
            {{ "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.STATUS" | translate }}
          </th>
          <th class="dependants-table__action" scope="col">
            {{
              "INTAKE.IMM0008.PAGES.VIEW_DEPENDANT.TABLE.ACTIONS" | translate
            }}
          </th>
        </tr>
      </thead>
      <tbody *ngIf="dependantsList">
        <tr *ngFor="let dependant of dependantsList" scope="row">
          <!-- Family Name -->
          <td class="dependants-table__body">
            {{ dependant.familyName }}
          </td>
          <!-- Give Name -->
          <td class="dependants-table__body">
            {{ dependant.givenName }}
            <p *ngIf="dependant.givenName === null" class="visually-hidden">
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
          <!-- DOB -->
          <td class="dependants-table__body">
            {{ dependant.dob }}
          </td>
          <!-- Status -->
          <td class="dependants-table__body">
            <lib-status-pill
              [text]="dependant.statusLabel | translate"
              [type]="dependant.status"
            >
            </lib-status-pill>
          </td>
          <!-- Action -->
          <td class="dependants-table__body">
            <div class="dependants-table__action--buttons">
              <!-- Edit dependant -->
              <a
                [routerLink]="
                  routeLocalizer.getNestedPath(
                    lang,
                    ['INTAKE_FORM_IMM0008_PAGE9_DEPENDANT'],
                    '../'
                  ) + dependant.id
                "
                category="secondary"
              >
                {{ dependantActionText | translate }}
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
