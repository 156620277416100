import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as CaseActions from "../../../shared/case-module/store/case.actions";
import * as fromApp from "../../../store/app.reducer";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { CaseService } from "../../../shared/case-module/case.service";
import { Case } from "../../../shared/case-module/case.model";

@Injectable({
  providedIn: "root",
})
export class IntakeLandingPageResolver implements Resolve<boolean> {
  case: Case | null;
  hasNotUnloaded: boolean;
  caseId: string | null;

  constructor(
    private caseService: CaseService,
    private store: Store<fromApp.State>
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    // Always clear any selected case in the store when e first route to here
    // this.store.dispatch(new CaseActions.ResetCaseInfo());
    if (
      sessionStorage?.getItem("previousUrl")?.split("#")[0] ===
        state.url?.split("#")[0] &&
      this.hasNotUnloaded
    ) {
      return this.case;
    }
    this.hasNotUnloaded = true;
    const caseId = route.params.caseId;

    if (caseId) {
      await this.caseService
        .getCase(caseId)
        .then((data: Case) => {
          this.store.dispatch(new CaseActions.SetCaseByEmail(data));
          this.case = data;
        })
        .catch((error) => {
          console.error("IntakeLandingPageResolver error:", error);
          this.store.dispatch(new CaseActions.ResetCaseInfo());
          this.case = null;
        });
    }
    return this.case;
  }
}
