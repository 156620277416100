<div class="wrapper">
  <table class="table id-table">
    <thead>
      <tr>
        <th class="id-table__name" colspan="3">
          <!-- TO-DO add symbol  -->
          <span>
            <img
              class="id-table__edit_icon"
              src="../../../../assets/images/icons/circle-pending.svg"
              alt=""
            />
          </span>
          <span class="title">{{
            "REP_IDENTIFICATION.TABLE_TITLE" | translate
          }}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <!-- ID Instructions -->
          <p class="id-table__instructions">
            {{ "REP_IDENTIFICATION.MAIN_INSTRUCTIONS" | translate }}
          </p>

          <p class="id-table__instructions">
            {{ "REP_IDENTIFICATION.ID_INSTRUCTIONS" | translate }}
          </p>
          <ul class="id-table__instructions_list">
            <li
              [innerHTML]="item"
              *ngFor="
                let item of 'REP_IDENTIFICATION.VALID_ID_LIST.LIST' | translate
              "
            ></li>
          </ul>

          <p class="id-table__file_instructions">
            {{ "REP_IDENTIFICATION.FILE_INSTRUCTIONS" | translate }}
          </p>
        </td>
        <td>
          <!-- Attachment -->
          <div class="id-table__delete" *ngIf="!!updatedDocLink">
            <button category="link" (click)="downloadFile(updatedDocLink)">
              <span class="visually-hidden">
                {{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }} </span
              >{{ fileName }}
            </button>
          </div>
          <h4 class="id-table__heading" *ngIf="!updatedDocLink">
            {{ "REP_IDENTIFICATION.NO_ATTACHEMENTS" | translate }}
          </h4>
        </td>
        <td>
          <!-- Actions -->
          <lib-file-upload
            fileTypes="{{ acceptedFileTypes }}"
            (change)="uploadFile($event)"
            buttonStyle="primary-alt"
            id="proof-of-payment"
          >
            {{ "INTAKE.SUPPORTING_DOCUMENTS.UPLOAD" | translate }}
          </lib-file-upload>
        </td>
      </tr>
    </tbody>
  </table>
</div>
