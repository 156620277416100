import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LangGuard } from "../../../../lib/src/lib/services/auth-service/lang.guard";
import { AddressPageComponent } from "../confirmation-module/address/address-page/address-page.component";
import { ConfirmationLandingPageResolver } from "../confirmation-module/confirmation-landing-page/confirmation-landing-page-resolver.service";
/* Pages */
import { ConfirmationLandingPageComponent } from "../confirmation-module/confirmation-landing-page/confirmation-landing-page.component";
import { UploadPhotoPageComponent } from "../confirmation-module/photos/upload-photo-page/upload-photo-page.component";
import { FirstResetPageComponent } from "../core/auth-module/pages/first-login-reset-page/first-reset-page.component";
import { ForgotPasswordPageComponent } from "../core/auth-module/pages/forgot-password-page/forgot-password-page.component";
import { LoginPageComponent } from "../core/auth-module/pages/login-page/login-page.component";
import { ResetPasswordPageComponent } from "../core/auth-module/pages/reset-password-page/reset-password-page.component";
import { SignUpPageComponent } from "../core/auth-module/pages/sign-up-page/sign-up-page.component";
import { SignUpVerificationPageComponent } from "../core/auth-module/pages/sign-up-verification-page/sign-up-page-verification.component";
import { IntakeLandingPageComponent } from "../intake-module/pages/intake-landing-page/intake-landing-page.component";
import { IntakeLandingPageResolver } from "../intake-module/pages/intake-landing-page/intake-landing-page-resolver.service";
import { AWSGuard } from "../core/auth-module/services/AWS.guard";
import { RepUserGuard } from "../core/auth-module/services/rep-user.guard";
import { ClientUserGuard } from "../core/auth-module/services/client-user.guard";
import { FirstLoginGuard } from "../core/auth-module/services/firstLogin.guard";
import { RepAccountDetailsGuard } from "../core/auth-module/services/rep-account-details.guard";
import { NavigationGuard } from "./guards/nav.guard";
import { LocalizedAppComponent } from "../core/localized-app/localized-app.component";
import routePaths from "./route-paths";
import { SelectedCaseResetResolver } from "../shared/case-module/selected-case-reset-resolver.service";

/* Intake forms */
import { IMM0008Page1Component } from "../intake-module/pages/IMM0008/page1/page1.component";
import { IMM0008Page2Component } from "../intake-module/pages/IMM0008/page2/page2.component";
import { IMM0008Page3Component } from "../intake-module/pages/IMM0008/page3/page3.component";
import { IMM0008Page4Component } from "../intake-module/pages/IMM0008/page4/page4.component";
import { IMM0008Page5Component } from "../intake-module/pages/IMM0008/page5/page5.component";
import { IMM0008Page6Component } from "../intake-module/pages/IMM0008/page6/page6.component";
import { IMM0008Page7Component } from "../intake-module/pages/IMM0008/page7/page7.component";
import { IMM0008Page8Component } from "../intake-module/pages/IMM0008/page8/page8.component";
import { IMM0008Page9Component } from "../intake-module/pages/IMM0008/page9/page9.component";
import { IMM0008Page10Component } from "../intake-module/pages/IMM0008/page10/page10.component";
import { IMM0008DependantComponent } from "../intake-module/pages/IMM0008/dependant/dependant.component";
import { IMM5669PageComponent } from "../intake-module/pages/IMM5669/IMM5669page.component";
import { IMM5406PageComponent } from "../intake-module/pages/IMM5406/IMM5406page.component";
import { IMM5562PageComponent } from "../intake-module/pages/IMM5562/IMM5562page.component";
import { RepAccountPageComponent } from "../intake-module/pages/rep-account/rep-account.component";
import { PrDevEnvGuard } from "./guards/pr-dev-env.guard";
import { ProfileComponent } from "../intake-module/pages/profile/profile.component";

// Declaration page
import { ESignatureDeclarationPageComponent } from "../intake-module/pages/declaration/e-signature-declaration-page.component";
import { RepDashboardComponent } from "../intake-module/pages/rep-dashboard/rep-dashboard.component";
import { DashboardApplicationsPageResolver } from "../intake-module/pages/rep-dashboard/dashboard-applications-page-resolver";
import { StartCasePageComponent } from "../core/pages/start-case/start-case-page.component";
import { ManageGroupMembersComponent } from "../intake-module/pages/privately-sponsored-refugees/manage-group-members/manage-group-members.component";
import { InviteGroupMembersComponent } from "../intake-module/pages/privately-sponsored-refugees/manage-group-members/invite-group-members/invite-group-members.component";
import { InvitePrincipalApplicantComponent } from "../intake-module/pages/privately-sponsored-refugees/manage-group-members/invite-principal-applicant/invite-principal-applicant.component";
import { InvitePrincipalApplicantsRepComponent } from "../intake-module/pages/privately-sponsored-refugees/manage-group-members/invite-principal-applicants-rep/invite-principal-applicants-rep.component";
import { SponsorProfileComponent } from "../intake-module/pages/privately-sponsored-refugees/sponsor-profile/sponsor-profile.component";
import { DesignSystemDocComponent } from "@pr-applicant/app/core/pages/design-system-doc/design-system-doc.component";
import { PrProfileGuard } from "./guards/pr-profile.guard";
import { CaseDocumentsResolver } from "../intake-module/resolvers/case-documents-resolver.service";
import { WebFormCaseResolver } from "../intake-module/resolvers/webform-case-resolver.service";

const localizedRoutes: Routes = Object.entries(routePaths).map(
  ([lang, paths]) => {
    return {
      path: lang,
      data: { lang },
      component: LocalizedAppComponent,
      children: [
        { path: paths.LOGIN, component: LoginPageComponent },
        {
          path: "",
          redirectTo: paths.APPLICATION + "/" + paths.DASHBOARD,
          pathMatch: "full",
        },
        /* AUTH ROUTES */
        {
          path: paths.FIRST_RESET,
          component: FirstResetPageComponent,
          canActivate: [FirstLoginGuard],
        },
        {
          path: paths.FORGOT_PASSWORD,
          component: ForgotPasswordPageComponent,
          data: { lang },
        },
        {
          path: paths.RESET_PASSWORD,
          component: ResetPasswordPageComponent,
          data: { lang },
        },
        {
          path: paths.CLIENT_SIGNUP,
          component: SignUpPageComponent,
        },
        {
          path: paths.SIGNUP_VERIFICATION,
          component: SignUpVerificationPageComponent,
        },
        /* REP AUTH ROUTEs */
        {
          path: paths.REP,
          children: [
            {
              path: paths.LOGIN,
              component: LoginPageComponent,
            },
            {
              path: paths.FIRST_RESET,
              component: FirstResetPageComponent,
              canActivate: [FirstLoginGuard],
            },
            {
              path: paths.FORGOT_PASSWORD,
              component: ForgotPasswordPageComponent,
            },
            {
              path: paths.RESET_PASSWORD,
              component: ResetPasswordPageComponent,
            },
            {
              path: paths.CLIENT_SIGNUP,
              component: SignUpPageComponent,
            },
            {
              path: paths.SIGNUP_VERIFICATION,
              component: SignUpVerificationPageComponent,
            },
          ],
        },
        /* INTAKE ROUTES */
        {
          path: paths.APPLICATION,
          canActivate: [AWSGuard, RepAccountDetailsGuard],
          children: [
            {
              path: `${paths.NEW}`,
              component: IntakeLandingPageComponent,
              runGuardsAndResolvers: "always",
              pathMatch: "full",
            },
            {
              path: paths.SPONSOR_PROFILE,
              component: SponsorProfileComponent,
              canActivate: [AWSGuard],
              pathMatch: "full",
            },
            /* ROUTES BY CASE */
            {
              path: `${paths.PROFILE}/:caseId`,
              component: ProfileComponent,
              canDeactivate: [NavigationGuard],
              runGuardsAndResolvers: "always",
              resolve: { case: IntakeLandingPageResolver },
              pathMatch: "full",
            },
            {
              path: `${paths.CASE}/:caseId`,
              canActivate: [PrProfileGuard],
              children: [
                {
                  path: "",
                  canActivate: [AWSGuard],
                  component: IntakeLandingPageComponent,
                  runGuardsAndResolvers: "always",
                  resolve: { case: IntakeLandingPageResolver },
                  pathMatch: "full",
                },
                /* Intake IMM5562 Supplementary application Form */
                {
                  path: paths.INTAKE_FORM_IMM5562,
                  component: IMM5562PageComponent,
                  runGuardsAndResolvers: "always",
                  resolve: {
                    case: WebFormCaseResolver,
                    caseDocuments: CaseDocumentsResolver,
                  },
                  canDeactivate: [NavigationGuard],
                  canActivate: [AWSGuard],
                },

                /* Intake IMM5406 Additional family info Form */
                {
                  path: paths.INTAKE_FORM_IMM5406,
                  canDeactivate: [NavigationGuard],
                  component: IMM5406PageComponent,
                  runGuardsAndResolvers: "always",
                  resolve: {
                    case: WebFormCaseResolver,
                    caseDocuments: CaseDocumentsResolver,
                  },
                },

                /* Intake IMM5669 Schedule A (background/declaration) Form */
                {
                  path: paths.INTAKE_FORM_IMM5669,
                  component: IMM5669PageComponent,
                  runGuardsAndResolvers: "always",
                  resolve: {
                    case: WebFormCaseResolver,
                    caseDocuments: CaseDocumentsResolver,
                  },
                  canDeactivate: [NavigationGuard],
                },

                /* Intake IMM0008 Form */
                {
                  path: paths.INTAKE_FORM_IMM0008,
                  canActivate: [AWSGuard],
                  runGuardsAndResolvers: "always",
                  resolve: { case: WebFormCaseResolver },
                  children: [
                    {
                      path: "",
                      redirectTo: paths.FORM_PAGE1,
                      pathMatch: "full",
                    },
                    {
                      path: paths.FORM_PAGE1,
                      component: IMM0008Page1Component,
                    },
                    {
                      path: paths.FORM_PAGE2,
                      component: IMM0008Page2Component,
                      canDeactivate: [NavigationGuard],
                    },
                    {
                      path: paths.FORM_PAGE3,
                      component: IMM0008Page3Component,
                      canDeactivate: [NavigationGuard],
                    },
                    {
                      path: paths.FORM_PAGE4,
                      canDeactivate: [NavigationGuard],
                      component: IMM0008Page4Component,
                    },
                    {
                      path: paths.FORM_PAGE5,
                      canDeactivate: [NavigationGuard],
                      component: IMM0008Page5Component,
                    },
                    {
                      path: paths.FORM_PAGE6,
                      canDeactivate: [NavigationGuard],
                      component: IMM0008Page6Component,
                    },
                    {
                      path: paths.FORM_PAGE7,
                      canDeactivate: [NavigationGuard],
                      component: IMM0008Page7Component,
                    },
                    {
                      path: paths.FORM_PAGE8,
                      canDeactivate: [NavigationGuard],
                      component: IMM0008Page8Component,
                    },
                    // Dependants page
                    {
                      path: paths.FORM_PAGE9,
                      component: IMM0008Page9Component,
                    },
                    {
                      path: `${paths.INTAKE_FORM_IMM0008_PAGE9_DEPENDANT}/:dependantId`,
                      component: IMM0008DependantComponent,
                      canDeactivate: [NavigationGuard],
                    },
                    {
                      path: paths.FORM_PAGE10,
                      component: IMM0008Page10Component,
                      canDeactivate: [NavigationGuard],
                    },
                  ],
                },
                // TODO: Change components to the right ones when pages are built!
                /* Intake IMM1344 Use of a representative Form */
                {
                  path: paths.INTAKE_FORM_IMM1344,
                  component: IMM0008Page1Component,
                },
                // TODO: Change components to the right ones when pages are built!
                /* Intake IMM5476 Use of a representative Form */
                {
                  path: paths.INTAKE_FORM_IMM5476,
                  component: IMM0008Page1Component,
                },
                // Declaration page
                {
                  path: paths.INTAKE_DECLARATION_PAGE,
                  component: ESignatureDeclarationPageComponent,
                  runGuardsAndResolvers: "always",
                  resolve: { case: WebFormCaseResolver },
                },
                {
                  path: paths.MANAGE_GROUP_MEMBERS,
                  component: ManageGroupMembersComponent,
                  resolve: { case: IntakeLandingPageResolver },
                },
                {
                  path: paths.INVITE_GROUP_MEMBER,
                  component: InviteGroupMembersComponent,
                },
                {
                  path: paths.INVITE_PRINCIPAL_APPLICANT,
                  component: InvitePrincipalApplicantComponent,
                },
                {
                  path: paths.INVITE_PRINCIPAL_APPLICANTS_REP,
                  component: InvitePrincipalApplicantsRepComponent,
                },
              ],
            },
            {
              path: paths.DASHBOARD,
              component: RepDashboardComponent,
              resolve: {
                reset: SelectedCaseResetResolver,
                cases: DashboardApplicationsPageResolver,
              },
            },
            {
              path: paths.START,
              component: StartCasePageComponent,
              resolve: {
                reset: SelectedCaseResetResolver,
              },
            },
          ],
        },

        /* CONFIRMATION ROUTES */
        {
          path: paths.CONFIRMATION_CASES,
          runGuardsAndResolvers: "always",
          canActivate: [AWSGuard, ClientUserGuard],
          children: [
            {
              path: "",
              redirectTo: "/",
              pathMatch: "full",
            },
            {
              path: `${paths.CONFIRMATION_CASE_DETAILS}/:id`,
              runGuardsAndResolvers: "always",
              resolve: { case: ConfirmationLandingPageResolver },
              children: [
                {
                  path: "",
                  pathMatch: "full",
                  component: ConfirmationLandingPageComponent,
                  data: { lang },
                },
                {
                  path: paths.ADDRESS,
                  component: AddressPageComponent,
                },
                {
                  path: paths.PHOTO,
                  component: UploadPhotoPageComponent,
                },
              ],
            },
          ],
        },

        {
          path: paths.CREATE_REP_ACCOUNT,
          canActivate: [AWSGuard, RepUserGuard],
          component: RepAccountPageComponent,
        },
        {
          path: paths.UPDATE_REP_ACCOUNT_INFO,
          canActivate: [AWSGuard, RepUserGuard, RepAccountDetailsGuard],
          canDeactivate: [NavigationGuard],
          component: RepAccountPageComponent,
        },
        // RENWEAL ROUTES
        {
          path: paths.RENEWAL,
          loadChildren: () =>
            import("../renewal-module/renewal.module").then(
              (m) => m.RenewalModule
            ),
          canActivate: [AWSGuard, RepAccountDetailsGuard],
        },
        // DESIGN SYSTEM ROUTE
        {
          path: paths.DESIGN_SYSTEM,
          component: DesignSystemDocComponent,
          canActivate: [PrDevEnvGuard],
        },
        // lazy-loaded path
        {
          path: "**",
          redirectTo: paths.APPLICATION + "/" + paths.DASHBOARD,
        },
      ],
    };
  }
);

export const routes: Routes = [
  { path: "", pathMatch: "full", children: [], canActivate: [LangGuard] },
  ...localizedRoutes,
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
  providers: [FirstLoginGuard, AWSGuard],
})
export class AppRoutingModule {}
