import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  UntypedFormBuilder,
} from "@angular/forms";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { ValidationService, FormSelectOption } from "lib";
import { LovService } from "lib";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-education-details-form",
  templateUrl: "./education-details-form.component.html",
  styleUrls: ["./education-details-form.component.scss"],
})
export class IMM0008EducationDetailsFormComponent implements OnInit, OnChanges {
  @Input() formData: any;

  public lang: string;
  public formValues: any;
  public case: any;
  public educationList: FormSelectOption[];

  private maxLengthText: number = 50;
  private minEducationYears: number = 0;
  private maxEducationYears: number = 99;

  public educationOccupationForm: FormGroup = this.fb.group({
    educationLevel: [null, Validators.required],
    numberOfYear: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.maxLength(2),
        this.validationService.validatorOnlyNumbers,
      ],
    }),
    currentOccupation: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.maxLength(this.maxLengthText),
        this.validationService.validatorAlphaNumericPlusAllSymbols,
      ],
    }),
    intendedOccupation: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.maxLength(this.maxLengthText),
        this.validationService.validatorAlphaNumericPlusAllSymbols,
      ],
    }),
  });

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  ngOnChanges(): void {
    this.educationList = this.lovService.lovs.educationLevel;
    this.formValues = this.formData?.form;
    if (this.formValues) {
      this.setFormValues();
    }
  }

  /* Error Messages */
  /* Years of Education = 0 - 99 */
  public get numberOfYearError(): boolean {
    const field = this.educationOccupationForm?.get("numberOfYear");

    if (
      field?.touched &&
      !field.hasError("required") &&
      (field?.hasError("onlyNumbers") ||
        field?.hasError("min") ||
        field?.hasError("max") ||
        field?.hasError("minlength") ||
        field?.hasError("maxlength"))
    ) {
      return true;
    }
    return false;
  }

  public get numberOfYearMinMax(): object {
    return {
      min: this.minEducationYears,
      max: this.maxEducationYears,
    };
  }

  public get occupationTextMinMax(): object {
    return {
      max: this.maxLengthText,
    };
  }

  private setFormValues(): void {
    this.educationOccupationForm?.controls?.educationLevel?.setValue(
      this.formValues.educationLevel
    );
    this.educationOccupationForm?.controls?.numberOfYear?.setValue(
      this.formValues.numberOfYear
    );
    this.educationOccupationForm?.controls?.currentOccupation?.setValue(
      this.formValues.currentOccupation
    );
    this.educationOccupationForm?.controls?.intendedOccupation?.setValue(
      this.formValues.intendedOccupation
    );
  }
}
