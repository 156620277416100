<mat-sidenav-container class="page-container">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
  >
    <div class="nav-bar-container">
      <pra-form-nav-bar
        [items]="FormNavbarItems"
        [curr]="1"
        [isLoading]="isLoading"
      ></pra-form-nav-bar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <h1 class="imm0008-page1__heading emphasis">
      {{ "INTAKE.IMM0008.PAGES.MAIN_HEADING" | translate }}
    </h1>
    <h2 class="emphasis imm0008-page1__subheading">
      {{ "INTAKE.IMM0008.PAGES.APPLICATION_DETAILS.SUBHEADING" | translate }}
    </h2>

    <ng-container *ngIf="isLoading">
      <div class="spinner">
        <lib-spinner [showSpinner]="isLoading" [fullPage]="false"></lib-spinner>
      </div>
    </ng-container>

    <pra-imm0008-application-details-form
      [ngStyle]="{ display: isLoading ? 'none' : 'block' }"
      [formData]="formData"
      #appDetailsForm
    ></pra-imm0008-application-details-form>

    <lib-layout-row gap="one-and-half" mt="3">
      <a
        [routerLink]="routeLocalizer.get(lang, 'FORM_PAGE1', '../')"
        category="secondary"
      >
        {{ "INTAKE.FORM_PAGE_BACK" | translate }}
      </a>
      <button
        [disabled]="appDetailsForm?.applicationDetailsForm?.invalid"
        (click)="saveApplicationDetailsForm()"
        category="primary"
      >
        {{ nextButtonKey | translate }}
      </button>
    </lib-layout-row>
  </mat-sidenav-content>
</mat-sidenav-container>
<pra-form-hamburger
  (click)="snav.toggle()"
  *ngIf="!isLoading"
></pra-form-hamburger>
