import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
// AWS
import { environment } from "../../../../environments/environment";
import * as fromApp from "../../../store/app.reducer";
import * as AuthActions from "../store/auth.actions";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { UserGroup } from "lib";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";

const apiVersion = environment.API_VERSION;

@Injectable()
export class AuthService {
  authenticated: boolean;
  expiryTime: Date | undefined;
  public tempNewUserEmail: string;
  public tempForgotPasswordUserEmail: string;

  private tempNewUserPassword: string;

  constructor(
    private http: HttpClient,
    private store: Store<fromApp.State>,
    private awsService: AWSService,
    private routeLocalizer: RouteLocalizerService
  ) {
    this.store
      .select("auth")
      .pipe(map((authState) => authState.user))
      .subscribe((user) => {
        this.authenticated = !!user;
        this.expiryTime = user?.expiryDate;
      });
  }

  async signUp(username: string, password: string) {
    try {
      const { user } = await this.awsService.auth.signUp({
        username: username.toLowerCase(),
        password,
        attributes: {
          "custom:group": !this.routeLocalizer.isUrlRepPrefixed
            ? UserGroup.Client
            : UserGroup.Rep,
        },
      });
      this.tempNewUserEmail = username;
      this.tempNewUserPassword = password;
      return user;
    } catch (error) {
      throw error;
    }
  }

  async confirmSignUp(code: string, username?: string) {
    try {
      // sign them up using code
      const email = username ? username : this.tempNewUserEmail;
      await this.awsService.auth.confirmSignUp(email.toLowerCase(), code);
      this.tempNewUserEmail = "";
      this.tempNewUserPassword = "";
    } catch (error) {
      throw error;
    }
  }

  async resendCodeSignUp(username: string) {
    try {
      await this.awsService.auth.resendSignUp(username.toLowerCase());
    } catch (error) {
      throw error;
    }
  }

  //resend the client password for the case ID
  async resendClientPassword(caseId: string) {
    const apiName = environment.API_NAME;
    const path = `/v2/cases/${caseId}/resend-password`;
    const myInit = {
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, myInit);
      return response;
    } catch (error) {
      throw error.response.data.error;
    }
  }

  async resendMemberPassword(caseId: string, caseMemberId: number) {
    const apiName = environment.API_NAME;
    const path = `/v2/cases/${caseId}/resend-password`;
    const myInit = {
      body: {
        isForCaseMember: true,
        caseMemberId,
      },
      headers: {
        Authorization: await this.awsService.getToken(),
      },
      response: true,
    };
    const response = await this.awsService.api.put(apiName, path, myInit);
    return response;
  }

  async login(username: string, password: string) {
    const group = !this.routeLocalizer.isUrlRepPrefixed
      ? UserGroup.Client
      : UserGroup.Rep;
    try {
      const user = await this.awsService.auth.signIn(
        username.toLowerCase(),
        password,
        { group }
      );
      return user;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailToResetPassword(username: string) {
    this.tempForgotPasswordUserEmail = username.toLowerCase();
    try {
      const result = await this.awsService.auth.forgotPassword(
        username.toLowerCase()
      );
      return result;
    } catch (error) {
      // if email does not exist on the system we don't want to let the user know
      if (
        error.code !== "NotAuthorizedException" &&
        error.code !== "UserNotFoundException"
      ) {
        throw error;
      }
    }
  }

  async forgotPasswordReset(
    username: string,
    code: string,
    newPassword: string
  ) {
    const email = username ? username : this.tempForgotPasswordUserEmail;
    try {
      const result = await this.awsService.auth.forgotPasswordSubmit(
        email.toLowerCase(),
        code,
        newPassword
      );
      return result;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const result = await this.awsService.auth.signOut();
      return result;
    } catch (error) {
      throw error;
    }
  }

  checkSession(): boolean {
    if (this.authenticated) {
      return true;
    } else {
      this.awsService.auth
        .signOut()
        .then((response) => {
          this.store.dispatch(AuthActions.StartLogout());
        })
        .catch((error) => {
          console.error("error signing you out", error);
        });
      return false;
    }
  }

  async changeClientPassword(session: string, password: string, email: string) {
    const apiName = environment.API_NAME;
    const path = "/user";

    const myInit = {
      body: {
        email: email.toLowerCase(),
        session,
        password,
      },
    };

    try {
      const { data } = await this.awsService.api.put(apiName, path, myInit);
      return data;
    } catch (error) {
      throw error.response.data.error;
    }
  }

  async updateIdentityId() {
    const identityId = await this.awsService.getIdentityId();

    const apiName = environment.API_NAME;
    const token = await this.awsService.getToken();
    const path = `/${apiVersion}/cases`;
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        identityId,
      },
      response: true,
    };
    try {
      const response = await this.awsService.api.put(apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  public checkAutofillOnLogin(): boolean {
    /* CHROME AUTO FILL FIX */
    let username;
    let password;
    let isAutofill = false;

    if (!(navigator.userAgent.toLowerCase().indexOf("firefox") > -1)) {
      // eslint-disable-next-line @typescript-eslint/quotes
      username = document.querySelector("input[type='email']:-webkit-autofill");
      // eslint-disable-next-line @typescript-eslint/quotes
      password = document.querySelector(
        "input[type='password']:-webkit-autofill"
      );
      if (username && password) {
        isAutofill = true;
      }
    }
    return isAutofill;
  }
}
