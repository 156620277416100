import { Component, OnInit } from "@angular/core";
import { RouteLocalizerService } from "@pr-applicant/app/routing/route-localizer.service";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "pra-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent implements OnInit {
  public loading = false;
  public isClientUser = true;
  public currentLang: string;
  form: FormGroup = new FormGroup({});

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.routeLocalizer.getCurrentRouteLang();
    this.routeLocalizer.setTranslatedTitle("LOGIN.PAGE_TITLE");
    this.isClientUser = !this.routeLocalizer.isUrlRepPrefixed;
  }

  public checkLoadingValue(value: any): void {
    if (value === true) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  }

  public get infoConfig() {
    return {
      header: this.translate.instant("LOGIN.MORE_INFO_HEADER"),
      icon: "infoRegular",
      type: "info",
    };
  }
}
