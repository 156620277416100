<!-- FOR DEPENDANTS ONLY 
------------------------------------------------------------------------------------------------>
<lib-layout-row mb="3" *ngIf="isDependant">
  <form
    *ngIf="isDependant"
    [formGroup]="dependantDetailsForm"
    autocomplete="off"
  >
    <!-- Will accompany primary applicant? -->
    <fieldset>
      <lib-form-radio
        [editable]="user.can('documents:write')"
        [legend]="
          'INTAKE.IMM0008.FORMS.DEPENDANT.ACCOMPANYING_PA.LEGEND' | translate
        "
        firstId="dependantDetailsForm-accompanyingPA-yes"
        secondId="dependantDetailsForm-accompanyingPA-no"
        [firstValue]="'true'"
        [secondValue]="'false'"
        [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
        [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
        nameVal="dependantDetailsForm-accompanyingPA"
        [formControl]="dependantDetailsForm?.controls?.accompanyingPA"
        [required]="isDependantAccompanyingPARadioRequired"
      >
        <span
          class="error-radio"
          *ngIf="
            dependantDetailsForm.get('accompanyingPA')?.touched &&
            dependantDetailsForm.get('accompanyingPA')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-radio>
    </fieldset>
    <fieldset *ngIf="isNotAccompanyingPA">
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.DEPENDANT.REASON_NOT_ACCOMPANYING.LEGEND"
            | translate
        }}
      </legend>
      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="dependantDetailsForm-reasonNotAccompanying"
        [required]="isNotAccompanyingPA"
        [formControl]="dependantDetailsForm.controls.reasonNotAccompanying"
        [label]="
          'INTAKE.IMM0008.FORMS.DEPENDANT.REASON_NOT_ACCOMPANYING.REASON_LABEL'
            | translate
        "
      >
        <span
          *ngIf="
            dependantDetailsForm.get('reasonNotAccompanying')?.touched &&
            dependantDetailsForm
              .get('reasonNotAccompanying')
              ?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            dependantDetailsForm.get('reasonNotAccompanying')?.dirty &&
            dependantDetailsForm
              .get('reasonNotAccompanying')
              ?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max50 }}
        </span>
        <span
          *ngIf="
            dependantDetailsForm.get('reasonNotAccompanying')?.touched &&
            dependantDetailsForm
              .get('reasonNotAccompanying')
              ?.hasError('alphaNumPlusFew')
          "
        >
          {{ "INTAKE.FORM_ERRORS.ALPHA_NUM_PLUS_FEW" | translate }}
        </span>
      </lib-form-input>
    </fieldset>

    <!-- Relationship to primary applicant -->
    <fieldset>
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.LEGEND" | translate
        }}
      </legend>
      <lib-form-select
        [editable]="user.can('documents:write')"
        identifier="dependantDetailsForm-relationshipToPA"
        data-cy="personal-details-relationship-to-pa"
        widthSm="true"
        required="true"
        [formControl]="dependantDetailsForm.controls.relationshipToPA"
        [label]="
          'INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.RELATIONSHIP_TYPE_LABEL'
            | translate
        "
        [options]="relationshipToPA"
        [editable]="user.can('documents:write')"
      >
        <span
          *ngIf="
            dependantDetailsForm.get('relationshipToPA')?.touched &&
            dependantDetailsForm.get('relationshipToPA')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <lib-form-input
        [editable]="user.can('documents:write')"
        identifier="dependantDetailsForm-otherRelationshipToPA"
        widthMd="true"
        [required]="hasOtherRelationshipToPA"
        *ngIf="hasOtherRelationshipToPA"
        [formControl]="dependantDetailsForm.controls.otherRelationshipToPA"
        [label]="
          'INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.OTHER_RELATIONSHIP_LABEL'
            | translate
        "
        [editable]="user.can('documents:write')"
      >
        <span
          *ngIf="
            dependantDetailsForm.get('otherRelationshipToPA')?.touched &&
            dependantDetailsForm
              .get('otherRelationshipToPA')
              ?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
        <span
          *ngIf="
            dependantDetailsForm.get('otherRelationshipToPA')?.dirty &&
            dependantDetailsForm
              .get('otherRelationshipToPA')
              ?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
        </span>
        <span
          *ngIf="
            dependantDetailsForm.get('otherRelationshipToPA')?.touched &&
            dependantDetailsForm
              .get('otherRelationshipToPA')
              ?.hasError('alphaNumPlusFew')
          "
        >
          {{ "INTAKE.FORM_ERRORS.ALPHA_NUM_PLUS_FEW" | translate }}
        </span>
      </lib-form-input>
      <!-- Show Dependant type only if the program isn't Refugee -->
      <lib-form-select
        [editable]="user.can('documents:write')"
        *ngIf="isDependantTypeRequired"
        identifier="dependantDetailsForm-dependantType"
        data-cy="personal-details-dependant-type"
        widthSm="true"
        [required]="isDependantTypeRequired"
        [formControl]="dependantDetailsForm.controls.dependantType"
        [label]="
          'INTAKE.IMM0008.FORMS.DEPENDANT.RELATIONSHIP_TO_PA.DEPENDANT_TYPE_LABEL'
            | translate
        "
        [options]="dependantType"
        [editable]="user.can('documents:write')"
      >
        <span
          *ngIf="
            dependantDetailsForm.get('dependantType')?.touched &&
            dependantDetailsForm.get('dependantType')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
    </fieldset>
  </form>
</lib-layout-row>

<!-- MAIN FORM BODY
--------------------------------------------------------------------------------------------->
<form [formGroup]="personalDetailsForm" autocomplete="off">
  <!-- Full Name -->
  <fieldset>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-familyName"
      widthMd="true"
      required="true"
      [helpText]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.LEGEND' | translate
      "
      (sendBlurEvent)="
        handleTrimByControl($event, personalDetailsForm.controls.familyName)
      "
      [formControl]="personalDetailsForm.controls.familyName"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.SURNAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('familyName')?.touched &&
          personalDetailsForm.get('familyName')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('familyName')?.touched &&
          personalDetailsForm.get('familyName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('familyName')?.touched &&
          personalDetailsForm.get('familyName')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-givenName"
      widthMd="true"
      (sendBlurEvent)="
        handleTrimByControl($event, personalDetailsForm.controls.givenName)
      "
      [formControl]="personalDetailsForm.controls.givenName"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.FULL_NAME.GIVEN_NAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('givenName')?.touched &&
          personalDetailsForm.get('givenName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          (personalDetailsForm.get('givenName')?.value ||
            personalDetailsForm.get('givenName')?.touched) &&
          personalDetailsForm.get('givenName')?.value &&
          personalDetailsForm.get('givenName')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Nickname -->
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.NICKNAME.LEGEND' | translate
      "
      firstId="personalDetailsForm-usedOtherName-yes"
      secondId="personalDetailsForm-usedOtherName-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="second-name-used"
      [formControl]="personalDetailsForm.controls.usedOtherName"
      [required]="isUsedOtherNameRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          personalDetailsForm.get('usedOtherName')?.touched &&
          personalDetailsForm.get('usedOtherName')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>
  <fieldset *ngIf="hasNickname">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.LEGEND"
          | translate
      }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-otherFamilyName"
      widthMd="true"
      [required]="hasNickname"
      [formControl]="personalDetailsForm.controls.otherFamilyName"
      (sendBlurEvent)="
        handleTrimByControl(
          $event,
          personalDetailsForm.controls.otherFamilyName
        )
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.SURNAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('otherFamilyName')?.touched &&
          personalDetailsForm.get('otherFamilyName')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('otherFamilyName')?.touched &&
          personalDetailsForm.get('otherFamilyName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('otherFamilyName')?.touched &&
          personalDetailsForm.get('otherFamilyName')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-otherGivenName"
      widthMd="true"
      [formControl]="personalDetailsForm.controls.otherGivenName"
      (sendBlurEvent)="
        handleTrimByControl($event, personalDetailsForm.controls.otherGivenName)
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_NICKNAME.GIVEN_NAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('otherGivenName')?.touched &&
          personalDetailsForm.get('otherGivenName')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('otherGivenName')?.touched &&
          personalDetailsForm.get('otherGivenName')?.value &&
          personalDetailsForm.get('otherGivenName')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <div class="error" *ngIf="personalDetailsForm.errors?.matches">
      <span>
        {{ "INTAKE.FORM_ERRORS.MATCHING_NICKNAME" | translate }}
      </span>
    </div>
  </fieldset>

  <!-- UCI -->
  <fieldset>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-uci"
      widthMd="true"
      [formControl]="personalDetailsForm.controls.uci"
      [helpText]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.LEGEND' | translate
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.UCI.UCI_LABEL' | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('uci')?.touched &&
          personalDetailsForm.get('uci')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max10 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('uci')?.touched &&
          personalDetailsForm.get('uci')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Physical Characteristics -->
  <fieldset>
    <legend>
      {{ "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.LEGEND" | translate }}
    </legend>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-sex"
      data-cy="personal-details-sex"
      widthSm="true"
      required="true"
      [formControl]="personalDetailsForm.controls.sex"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.SEX_LABEL' | translate
      "
      [options]="genderOptions"
    >
      <span
        *ngIf="
          personalDetailsForm.get('sex')?.touched &&
          personalDetailsForm.get('sex')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-eyeColour"
      data-cy="personal-details-eye-color"
      widthSm="true"
      [required]="isDependantAccompanying"
      [formControl]="personalDetailsForm.controls.eyeColour"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.EYE_COLOUR_LABEL'
          | translate
      "
      [options]="eyeColorOptions"
    >
      <span
        *ngIf="
          personalDetailsForm.get('eyeColour')?.touched &&
          personalDetailsForm.get('eyeColour')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-heightInCM"
      widthSm="true"
      [required]="isDependantAccompanying"
      [formControl]="personalDetailsForm.controls.heightInCM"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PHYSICAL.HEIGHT_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('heightInCM')?.touched &&
          personalDetailsForm.get('heightInCM')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('heightInCM')?.touched &&
          personalDetailsForm.get('heightInCM')?.hasError('onlyNumbers')
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_ONLY" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('heightInCM')?.touched &&
          (personalDetailsForm.get('heightInCM')?.hasError('min') ||
            personalDetailsForm.get('heightInCM')?.hasError('max'))
        "
      >
        {{ "INTAKE.FORM_ERRORS.NUMBERS_MIN_MAX" | translate : minMaxHeight }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Birth info -->
  <fieldset>
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.LEGEND" | translate
      }}
    </legend>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="personalDetailsForm-dob"
      widthMd="true"
      required="true"
      [formControl]="personalDetailsForm.controls.dob"
      [required]="true"
      [pastDate]="true"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.DOB_LABEL' | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-cityOfBirth"
      widthSm="true"
      required="true"
      [formControl]="personalDetailsForm.controls.cityOfBirth"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.CITY_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('cityOfBirth')?.touched &&
          personalDetailsForm.get('cityOfBirth')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('cityOfBirth')?.touched &&
          personalDetailsForm.get('cityOfBirth')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('cityOfBirth')?.touched &&
          personalDetailsForm.get('cityOfBirth')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
      </span>
    </lib-form-input>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-countryOfBirth"
      data-cy="personal-details-country-of-birth"
      widthSm="true"
      required="true"
      [formControl]="personalDetailsForm.controls.countryOfBirth"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.BIRTH_INFO.COUNTRY_LABEL'
          | translate
      "
      [options]="countryOfBirthOptions"
    >
      <span
        *ngIf="
          personalDetailsForm.get('countryOfBirth')?.touched &&
          personalDetailsForm.get('countryOfBirth')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
  </fieldset>

  <!-- Citizenship -->
  <fieldset>
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.LEGEND" | translate
      }}
    </legend>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-citizenship1"
      data-cy="personal-details-citizenship-1"
      widthSm="true"
      required="true"
      [formControl]="personalDetailsForm.controls.citizenship1"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_LABEL'
          | translate
      "
      [options]="countryOfCitizenshipOptions"
    >
      <span
        *ngIf="
          personalDetailsForm.get('citizenship1')?.touched &&
          personalDetailsForm.get('citizenship1')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-citizenship2"
      data-cy="personal-details-citizenship-2"
      widthSm="true"
      [formControl]="personalDetailsForm.controls.citizenship2"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.CITIZENSHIP.COUNTRY_2_LABEL'
          | translate
      "
      [options]="countryOfCitizenshipOptions"
    ></lib-form-select>
  </fieldset>

  <!-- Residence -->
  <fieldset>
    <legend>
      {{ "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.LEGEND" | translate }}
    </legend>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-currentCountry"
      data-cy="personal-details-current-country"
      widthSm="true"
      required="true"
      [formControl]="currentCountry.controls.country"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.COUNTRY_LABEL'
          | translate
      "
      [options]="countryOptions"
    >
      <span
        *ngIf="
          currentCountry.get('country')?.touched &&
          currentCountry.get('country')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-immigrationStatus"
      data-cy="personal-details-immigration-status"
      widthSm="true"
      required="true"
      [formControl]="currentCountry.controls.immigrationStatus"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.IMMIGRATION_STATUS_LABEL'
          | translate
      "
      [options]="immigrationStatusOptions"
    >
      <span
        *ngIf="
          currentCountry.get('immigrationStatus')?.touched &&
          currentCountry.get('immigrationStatus')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="personalDetailsForm-startDateofImmigrationStatus"
      widthMd="true"
      *ngIf="countryDatesRequired || hasOtherImmigrationStatus"
      [required]="countryDatesRequired"
      [pastDate]="true"
      [formControl]="currentCountry.controls.startDateOfImmigrationStatus"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.START_DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="personalDetailsForm-endDateOfImmigrationStatus"
      widthMd="true"
      *ngIf="countryDatesRequired || hasOtherImmigrationStatus"
      [required]="countryDatesRequired"
      [futureDate]="true"
      [compareDate]="{
        startControl: currentCountry.controls.startDateOfImmigrationStatus,
        direction: 'after'
      }"
      [formControl]="currentCountry.controls.endDateOfImmigrationStatus"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.END_DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-otherImmigrationStatus"
      widthMd="true"
      [required]="hasOtherImmigrationStatus"
      [formControl]="currentCountry.controls.otherImmigrationStatus"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.RESIDENCE.OTHER_DETAILS_LABEL'
          | translate
      "
      *ngIf="hasOtherImmigrationStatus"
    >
      <span
        *ngIf="
          currentCountry.get('otherImmigrationStatus')?.touched &&
          currentCountry.get('otherImmigrationStatus')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          currentCountry.get('otherImmigrationStatus')?.touched &&
          currentCountry.get('otherImmigrationStatus')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
      <span
        *ngIf="
          currentCountry.get('otherImmigrationStatus')?.touched &&
          currentCountry.get('otherImmigrationStatus')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Last Entry to Canada -->
  <fieldset *ngIf="isCurrentCountryCanada">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.LEGEND" | translate
      }}
    </legend>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="personalDetailsForm-dateOfLastEntry"
      widthMd="true"
      [required]="true"
      [pastDate]="true"
      [formControl]="personalDetailsForm.controls.dateOfLastEntry"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-placeOfLastEntry"
      widthMd="true"
      required="true"
      [formControl]="personalDetailsForm.controls.placeOfLastEntry"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.LAST_ENTRY.PLACE_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('placeOfLastEntry')?.touched &&
          personalDetailsForm.get('placeOfLastEntry')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('placeOfLastEntry')?.touched &&
          personalDetailsForm.get('placeOfLastEntry')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('placeOfLastEntry')?.touched &&
          personalDetailsForm.get('placeOfLastEntry')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Previous countries check -->
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_COUNTRIES.LEGEND'
          | translate
      "
      firstId="personalDetailsForm-hasPreviousCountries-yes"
      secondId="personalDetailsForm-hasPreviousCountries-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="previous-countries-check"
      [formControl]="personalDetailsForm.controls.hasPreviousCountries"
      [required]="isPreviousCountriesRadioRequired"
      (change)="toggleFieldsPreviousCountries()"
    >
      <span
        class="error-radio"
        *ngIf="
          personalDetailsForm.get('hasPreviousCountries')?.touched &&
          personalDetailsForm.get('hasPreviousCountries')?.hasError('required')
        "
      >
        {{ "FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>

  <!-- Previous countries info -->
  <fieldset *ngIf="hasPreviousCountries">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.LEGEND"
          | translate
      }}
    </legend>
    <ng-container
      *ngFor="
        let country of previousCountries?.controls;
        index as i;
        first as isFirst;
        last as isLast
      "
      formArrayName="previousCountries"
    >
      <!-- repeating fieldset -->
      <fieldset [formGroupName]="i" class="form-array__fieldset">
        <lib-form-select
          [editable]="user.can('documents:write')"
          widthSm="true"
          [identifier]="'personalDetailsForm-prevCountry' + i"
          data-cy="personal-details-previous-country"
          [required]="hasPreviousCountries"
          [formControl]="country.controls.country"
          [helpText]="
            ('INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LEGEND'
              | translate) +
            ' #' +
            (i + 1)
          "
          [label]="
            'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.COUNTRY_LABEL'
              | translate
          "
          [options]="countryOptions"
        >
          <span
            *ngIf="
              country.controls.country.touched &&
              country.controls.country.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>
        <lib-form-select
          [editable]="user.can('documents:write')"
          widthSm="true"
          [identifier]="'personalDetailsForm-prevImmigrationStatus' + i"
          data-cy="personal-details-previous-immigration-status"
          [required]="hasPreviousCountries"
          [formControl]="country.controls.immigrationStatus"
          [label]="
            'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.IMMIGRATION_STATUS_LABEL'
              | translate
          "
          [options]="immigrationStatusOptions"
        >
          <span
            *ngIf="
              country.controls.immigrationStatus.touched &&
              country.controls.immigrationStatus.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>

        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          widthMd="true"
          [identifier]="
            'personalDetailsForm-prevStartDateOfImmigrationStatus' + i
          "
          [required]="hasPreviousCountries"
          [pastDate]="true"
          [required]="true"
          [formControl]="country.controls.startDateOfImmigrationStatus"
          [label]="
            'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.START_DATE_LABEL'
              | translate
          "
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          [readOnly]="!user.can('documents:write')"
          widthMd="true"
          [identifier]="
            'personalDetailsForm-prevEndDateOfImmigrationStatus' + i
          "
          [required]="hasPreviousCountries"
          [formControl]="country.controls.endDateOfImmigrationStatus"
          [pastDate]="true"
          [required]="true"
          [compareDate]="{
            startControl: country.controls.startDateOfImmigrationStatus,
            direction: 'after'
          }"
          [label]="
            'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.END_DATE_LABEL'
              | translate
          "
        >
        </lib-form-datepicker>
        <lib-form-input
          [editable]="user.can('documents:write')"
          *ngIf="
            country.controls.immigrationStatus.value ===
            otherImmigrationStatusId
          "
          widthMd="true"
          [identifier]="'personalDetailsForm-prevOtherImmigrationStatus' + i"
          [formControl]="country.controls.otherImmigrationStatus"
          [label]="
            'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.OTHER_DETAILS_LABEL'
              | translate
          "
          [required]="
            country.controls.immigrationStatus.value ===
            otherImmigrationStatusId
          "
        >
          <span
            *ngIf="
              country.controls.otherImmigrationStatus.touched &&
              country.controls.otherImmigrationStatus.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              country.controls.otherImmigrationStatus.touched &&
              country.controls.otherImmigrationStatus.hasError('pattern')
            "
          >
            {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
          </span>
          <span
            *ngIf="
              country.controls.otherImmigrationStatus.touched &&
              country.controls.otherImmigrationStatus.hasError('maxlength')
            "
          >
            {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max30 }}
          </span>
        </lib-form-input>

        <div class="form-array__buttons">
          <button
            *ngIf="!isFirst"
            [disabled]="isReadOnly"
            type="button"
            category="secondary"
            (click)="removePreviousCountry(i)"
          >
            {{
              "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.REMOVE"
                | translate
            }}
          </button>
          <button
            *ngIf="isLast && canAddAnotherCountry"
            [disabled]="isReadOnly"
            type="button"
            category="primary"
            (click)="addPreviousCountry()"
          >
            {{
              "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_COUNTRIES.ADD"
                | translate
            }}
          </button>
        </div>
      </fieldset>
    </ng-container>
  </fieldset>

  <!-- Marital status -->
  <fieldset>
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.LEGEND"
          | translate
      }}
    </legend>
    <lib-information-box [config]="informationBoxConfig" *ngIf="formData">
      <div>
        {{ "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX" | translate }}
        <a
          [routerLink]="
            routeLocalizer.getNestedPath(
              lang,
              ['INTAKE', 'PROFILE'],
              '../../../../../'
            ) + formData.caseId
          "
          >{{
            "INTAKE.IMM0008.FORMS.DEPENDANT.INFORMATION_BOX_LINK" | translate
          }}</a
        >
      </div>
    </lib-information-box>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-maritalStatus"
      data-cy="personal-details-marital-status"
      widthMd="true"
      required="true"
      [formControl]="personalDetailsForm.controls.maritalStatus"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_STATUS.MARITAL_STATUS_LABEL'
          | translate
      "
      [options]="maritalStatusOptions"
    >
      <span
        *ngIf="
          personalDetailsForm.get('maritalStatus')?.touched &&
          personalDetailsForm.get('maritalStatus')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm
            .get('maritalStatus')
            ?.hasError('spouseMaritalStatusError')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MARITAL_STATUS_SPOUSE" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm
            .get('maritalStatus')
            ?.hasError('conjugalPartnerMaritalStatusError')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MARITAL_STATUS_CONJUGAL" | translate }}
      </span>
    </lib-form-select>
  </fieldset>

  <fieldset *ngIf="hasMarriageOrCommonLaw">
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="personalDetailsForm-dateOfMarriageOrCommonLaw"
      [required]="hasMarriageOrCommonLaw"
      [pastDate]="true"
      [formControl]="personalDetailsForm.controls.dateOfMarriageOrCommonLaw"
      [helpText]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.LEGEND' | translate
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.MARITAL_DATE.DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
  </fieldset>

  <!-- Spouse -->
  <fieldset *ngIf="hasMarriageOrCommonLaw">
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-familyNameOfSpouse"
      widthMd="true"
      [required]="hasMarriageOrCommonLaw"
      [formControl]="personalDetailsForm.controls.familyNameOfSpouse"
      [helpText]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.LEGEND' | translate
      "
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.SURNAME_LABEL' | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('familyNameOfSpouse')?.touched &&
          personalDetailsForm.get('familyNameOfSpouse')?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('familyNameOfSpouse')?.touched &&
          personalDetailsForm.get('familyNameOfSpouse')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('familyNameOfSpouse')?.touched &&
          personalDetailsForm.get('familyNameOfSpouse')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="personalDetailsForm-givenNameOfSpouse"
      widthMd="true"
      [formControl]="personalDetailsForm.controls.givenNameOfSpouse"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.SPOUSE.GIVEN_NAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          personalDetailsForm.get('givenNameOfSpouse')?.touched &&
          personalDetailsForm.get('givenNameOfSpouse')?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          personalDetailsForm.get('givenNameOfSpouse')?.touched &&
          personalDetailsForm.get('givenNameOfSpouse')?.value &&
          personalDetailsForm.get('givenNameOfSpouse')?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
  </fieldset>

  <!-- Previous Relationships -->
  <fieldset>
    <lib-form-radio
      [editable]="user.can('documents:write')"
      [legend]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PREV_RELATIONSHIP.LEGEND'
          | translate
      "
      firstId="personalDetailsForm-previouslyMarriedOrCommonLaw-yes"
      secondId="personalDetailsForm-previouslyMarriedOrCommonLaw-no"
      [firstValue]="true"
      [secondValue]="false"
      [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
      [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
      nameVal="previous-relationships-check"
      [formControl]="personalDetailsForm.controls.previouslyMarriedOrCommonLaw"
      [required]="isPreviousRelationshipRadioRequired"
    >
      <span
        class="error-radio"
        *ngIf="
          personalDetailsForm.get('previouslyMarriedOrCommonLaw')?.touched &&
          hasPreviousMarriageOrCommonLawRadio === null
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-radio>
  </fieldset>
  <fieldset *ngIf="hasPreviousMarriageOrCommonLawRadio">
    <legend>
      {{
        "INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.LEGEND"
          | translate
      }}
    </legend>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="previousRelationshipForm-previousSpouseFamilyName"
      widthMd="true"
      [required]="hasPreviousMarriageOrCommonLawRadio"
      [formControl]="previousRelationshipForm.controls.previousSpouseFamilyName"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.SURNAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          previousRelationshipForm.get('previousSpouseFamilyName')?.touched &&
          previousRelationshipForm
            .get('previousSpouseFamilyName')
            ?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
      <span
        *ngIf="
          previousRelationshipForm.get('previousSpouseFamilyName')?.touched &&
          previousRelationshipForm
            .get('previousSpouseFamilyName')
            ?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          previousRelationshipForm.get('previousSpouseFamilyName')?.touched &&
          previousRelationshipForm
            .get('previousSpouseFamilyName')
            ?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <lib-form-input
      [editable]="user.can('documents:write')"
      identifier="previousRelationshipForm-previousSpouseGivenName"
      widthMd="true"
      [formControl]="previousRelationshipForm.controls.previousSpouseGivenName"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.GIVEN_NAME_LABEL'
          | translate
      "
    >
      <span
        *ngIf="
          previousRelationshipForm.get('previousSpouseGivenName')?.touched &&
          previousRelationshipForm
            .get('previousSpouseGivenName')
            ?.hasError('maxlength')
        "
      >
        {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : max100 }}
      </span>
      <span
        *ngIf="
          previousRelationshipForm.get('previousSpouseGivenName')?.touched &&
          previousRelationshipForm.get('previousSpouseGivenName')?.value &&
          previousRelationshipForm
            .get('previousSpouseGivenName')
            ?.hasError('pattern')
        "
      >
        {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
      </span>
    </lib-form-input>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      *ngIf="!isDependant"
      identifier="previousRelationshipForm-previousSpouseDob"
      widthMd="true"
      [required]="hasPreviousMarriageOrCommonLawRadio"
      [pastDate]="true"
      [formControl]="previousRelationshipForm.controls.previousSpouseDob"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.DOB_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-select
      [editable]="user.can('documents:write')"
      identifier="previousRelationshipForm-typeOfRelationship"
      data-cy="personal-details-type-of-relationship"
      widthSm="true"
      [required]="hasPreviousMarriageOrCommonLawRadio"
      [formControl]="previousRelationshipForm.controls.typeOfRelationship"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.RELATIONSHIP_TYPE_LABEL'
          | translate
      "
      [options]="maritalStatusHistoryOptions"
    >
      <span
        *ngIf="
          previousRelationshipForm.get('typeOfRelationship')?.touched &&
          previousRelationshipForm
            .get('typeOfRelationship')
            ?.hasError('required')
        "
      >
        {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
      </span>
    </lib-form-select>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="previousRelationshipForm-startDateofRelationship"
      widthMd="true"
      [required]="hasPreviousMarriageOrCommonLawRadio"
      [pastDate]="true"
      [formControl]="previousRelationshipForm.controls.startDateOfRelationship"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.START_DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
    <lib-form-datepicker
      [readOnly]="!user.can('documents:write')"
      identifier="previousRelationshipForm-endDateOfRelationship"
      widthMd="true"
      [required]="hasPreviousMarriageOrCommonLawRadio"
      [pastDate]="true"
      [compareDate]="{
        startControl: previousRelationshipForm.controls.startDateOfRelationship,
        direction: 'after'
      }"
      [formControl]="previousRelationshipForm.controls.endDateOfRelationship"
      [label]="
        'INTAKE.IMM0008.FORMS.PERSONAL_DETAILS.PROVIDE_PREV_RELATIONSHIP.END_DATE_LABEL'
          | translate
      "
    >
    </lib-form-datepicker>
  </fieldset>
</form>
