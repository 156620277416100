import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";

@Component({
  selector: "lib-bttns-paginator",
  templateUrl: "./bttns-paginator.component.html",
  styleUrls: ["./bttns-paginator.component.scss"],
})
export class BttnsPaginatorComponent implements OnChanges {
  @Input() listNumbers: number;

  public numbers: number[];
  public pageInView: number = 1;
  public lastSet: number;
  public pageSets: any[] = [];
  public pageSetInView: number = 0;

  @Output() checkedPageNumber = new EventEmitter<number>();

  constructor() {}

  ngOnChanges(): void {
    this.numbers = Array(this.listNumbers)
      .fill(this.listNumbers)
      .map((_, i) => i + 1);

    // Accomodates for filter/search functionality
    if (this.pageSets.length !== 0) {
      this.pageSets = [];
    }

    // Creating an array of arrays, each subarray is a pageset (i.e. [1-10], or [11-20])
    while (this.numbers.length) {
      this.pageSets.push(this.numbers.splice(0, 10));
    }
    this.lastSet = this.pageSets.length - 1;
  }

  changePage(pageNumber: number) {
    this.checkedPageNumber.emit(pageNumber - 1);
    this.pageInView = pageNumber;
  }

  goToPrevious() {
    this.pageSetInView -= 1;
  }

  goToNext() {
    this.pageSetInView += 1;
  }
}
