<div class="form-textarea">
  <label class="form-textarea__label" [for]="identifier">
    <span
      *ngIf="required && !hideRequired"
      class="form-textarea__required-icon"
    >
      <lib-icon-chooser icon="asterisk"></lib-icon-chooser>
    </span>
    <span class="form-textarea__shift">
      {{ label }}
      <!-- <span *ngIf="required && !hideRequired" class="form-textarea__required">
        ({{ requiredText }})
      </span> -->
    </span>
  </label>

  <ng-container *ngIf="editable">
    <textarea
      [rows]="rows"
      type="text"
      #formTextAreaInput
      [id]="identifier"
      [name]="identifier"
      class="form-textarea__field"
      [formControl]="ngControl.control"
      [value]="value"
      [placeholder]="placeholder"
      (input)="onChange($event.target.value)"
      (blur)="onTouched($event)"
      [required]="required"
      [attr.aria-required]="required"
    ></textarea>
  </ng-container>

  <ng-container *ngIf="!editable">
    <textarea
      [rows]="rows"
      type="text"
      #formTextAreaInput
      [id]="identifier"
      [name]="identifier"
      class="form-textarea__field"
      [value]="value"
      [placeholder]="placeholder"
      [required]="required"
      [attr.aria-required]="required"
      [disabled]="true"
      [attr.disabled]="true"
    ></textarea>
  </ng-container>

  <div
    *ngIf="ngControl.errors && ngControl.touched"
    class="form-textarea__error"
  >
    <ng-content>
      <!-- error spans -->
    </ng-content>
  </div>
</div>
