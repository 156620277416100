import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { IMM0008PersonalDetailsFormComponent } from "../personal-details-form/personal-details-form.component";
import { IMM0008EducationDetailsFormComponent } from "../education-details-form/education-details-form.component";
import { IMM0008LanguageDetailsFormComponent } from "../../../forms/IMM0008/language-details-form/language-details-form.component";
import { IMM0008PassportDetailsFormComponent } from "../passport-details-form/passport-details-form.component";
import { IMM0008NationalIdentityFormComponent } from "../../../forms/IMM0008/national-identity-form/national-identity-form.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-add-dependant-form",
  templateUrl: "./add-dependant-form.component.html",
  styleUrls: ["./add-dependant-form.component.scss"],
})
export class IMM0008AddDependantFormComponent implements OnInit, OnChanges {
  @Input() selectedPage: string = "1";
  @Input() formData: any;

  @ViewChild("personalDetailsForm", { static: false })
  personalDetailsForm: IMM0008PersonalDetailsFormComponent;
  @ViewChild("educationOccupationForm", { static: false })
  educationOccupationForm: IMM0008EducationDetailsFormComponent;
  @ViewChild("languageDetailsForm", { static: false })
  languageDetailsForm: IMM0008LanguageDetailsFormComponent;
  @ViewChild("passportDetailsForm", { static: false })
  passportDetailsForm: IMM0008PassportDetailsFormComponent;
  @ViewChild("nationalIdentityForm", { static: false })
  nationalIdentityForm: IMM0008NationalIdentityFormComponent;

  public lang: string;
  public formDetails = {
    personalDetailsData: {},
    educationDetailsData: {},
    languageDetailsData: {},
    passportDetailsData: {},
    nationalIdentityDetailsData: {},
  };

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  async ngOnChanges() {
    if (this.formData) {
      await this.setFormData();
      this.cd.detectChanges();
    }
  }

  public async setFormData(): Promise<any> {
    switch (this.selectedPage) {
      case "1":
        this.formDetails.personalDetailsData = await this.parseFormData(
          this.formData?.form?.personalDetails
        );
        return;
      case "2":
        this.formDetails.educationDetailsData = await this.parseFormData(
          this.formData?.form?.educationOccupationDetails
        );
        return;
      case "3":
        this.formDetails.languageDetailsData = await this.parseFormData(
          this.formData?.form?.languageDetails
        );
        return;
      case "4":
        this.formDetails.passportDetailsData = await this.parseFormData(
          this.formData?.form?.passportDetails
        );
        return;
      case "5":
        this.formDetails.nationalIdentityDetailsData = await this.parseFormData(
          this?.formData?.form?.nationalIdentityDetails
        );
        return;
    }
  }

  /* This method will parse the data back to match the expected component form data
   * It will received only form values as an object ex:
   * {
   *   countryOfIssue: null
   *   expiryDate: null
   *   isComplete: null
   *   issueDate: null
   *   passportNumber: null
   *   usingIsraeliPassportForTrip: null
   *   usingTaiwanesePassportForTrip: null
   *   validPassport: null
   * }
   * and return as ex:
   * {
   *  caseId: 1257
   *  createdAt: "2021-02-24 21:11:44"
   *  documentApproved: null
   *  documentDeclarationTimestamp: null
   *  documentName: "6036c1107bca82000943f7f4"
   *  .... all fields in a form
   *  form: { THE OFORM OBJECT PASSED TO THE METHOD WITH ALL THE VALUES}
   * }
   *
   * this will match the values need for the children forms to render
   *
   */
  public parseFormData(form: any): any {
    // deep clone original dependants form coming from the API
    const parsedFormData = JSON.parse(JSON.stringify(this.formData));
    // Replace the form field with the intended form values
    parsedFormData.form = form;
    return parsedFormData;
  }
}
