import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "pra-attachment-status",
  templateUrl: "./attachment-status.component.html",
  styleUrls: ["./attachment-status.component.scss"],
})
export class AttachmentStatusComponent implements OnInit {
  @Input() fileName: string;
  @Input() fileStatus?: string;
  @Input() canDelete: boolean;
  @Input() state: "loading" | "warning" | "success" | "error";
  @Input() error?: string = "";
  @Output() deleteFile = new EventEmitter();
  @Output() downloadFile = new EventEmitter();

  public _fileState: "loading" | "warning" | "success" | "error";

  constructor() {}

  ngOnInit(): void {
    this._fileState = this.state;
  }

  public handleDownload(): void {
    this.downloadFile.emit();
  }
  public handleDelete(): void {
    this.deleteFile.emit();
  }
}
