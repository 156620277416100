import { Component, OnInit } from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";

@Component({
  selector: "pra-first-reset-page",
  templateUrl: "./first-reset-page.component.html",
  styleUrls: ["./first-reset-page.component.scss"],
})
export class FirstResetPageComponent implements OnInit {
  public isFirstLogin = true;

  constructor(private routeLocalizer: RouteLocalizerService) {}

  ngOnInit(): void {
    this.routeLocalizer.setTranslatedTitle("RESET.PAGE_TITLE");
  }
}
