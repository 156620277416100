import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
} from "@angular/core";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import {
  FormGroup,
  Validators,
  FormControl,
  UntypedFormBuilder,
} from "@angular/forms";
import { ValidationService, FormSelectOption } from "lib";
import { AuthService } from "../../../../core/auth-module/services/auth.service";
import { Subscription } from "rxjs";
import { LovService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "pra-imm0008-passport-details-form",
  templateUrl: "./passport-details-form.component.html",
  styleUrls: ["./passport-details-form.component.scss"],
})
export class IMM0008PassportDetailsFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() formData: any;

  public lang: string;
  public countryOfIssueList: FormSelectOption[];
  public formValues: any;
  public case: any;
  public validPassport: boolean = false;
  public usingTaiwanesePassportForTrip: boolean = false;
  public usingIsraeliPassportForTrip: boolean = false;
  public max20 = { max: "20" };

  public passportDetailsForm: FormGroup = this.fb.group({
    validPassport: [null, Validators.required],
    usingTaiwanesePassportForTrip: [null],
    usingIsraeliPassportForTrip: [null],
    passportNumber: new FormControl(null, {
      validators: [],
    }),
    countryOfIssue: [null],
    expiryDate: new FormControl(null, {
      validators: [],
    }),
    issueDate: new FormControl(null, {
      validators: [],
    }),
  });

  private taiwanDropDownValue = "203";
  private israelDropDownValue = "206";
  private subs: Subscription[] = [];

  private passportNumberValidation = [
    Validators.required,
    Validators.maxLength(20),
    this.validationService.validatorAlphaNumeric,
  ];
  private countryOfIssueValidation = [Validators.required];
  private issueDateValidation = [
    Validators.required,
    this.validationService.validatorDateFormat,
    this.validationService.validatorDate,
    this.validationService.validatorDatePast,
  ];
  private expiryDateValidation = [
    Validators.required,
    this.validationService.validatorDateFormat,
    this.validationService.validatorDate,
    this.validationService.validatorDateFuture,
  ];
  private usingTaiwanesePassportForTripValidation = [Validators.required];
  private usingIsraeliPassportForTripValidation = [Validators.required];

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private fb: UntypedFormBuilder,
    private validationService: ValidationService,
    private authService: AuthService,
    private lovService: LovService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
    this.authService.checkSession();
    this.watchPassportValue();
    this.watchCountryValue();
  }

  ngOnChanges(): void {
    this.countryOfIssueList = this.lovService.lovs.countryOfIssue;
    this.formValues = this.formData?.form;
    if (this.formValues) {
      this.setFormDataIfSaved();
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  /* PUBLIC -------------------------------------------------------------------------------- */

  public get isPassportDetailsRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.passportDetailsForm.controls.validPassport
    );
  }

  public get isTaiwanRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.passportDetailsForm.controls.usingTaiwanesePassportForTrip
    );
  }

  public get isIsraelRadioRequired(): any {
    return this.validationService.isRadioFieldRequired(
      this.passportDetailsForm.controls.usingIsraeliPassportForTrip
    );
  }

  public get passportNumberError(): boolean {
    const field = this.passportDetailsForm.get("passportNumber");
    // eslint-disable-next-line max-len
    if (
      field?.touched &&
      !field.hasError("required") &&
      (field?.hasError("numbers") ||
        field?.hasError("min") ||
        field?.hasError("max") ||
        field?.hasError("minlength") ||
        field?.hasError("maxlength"))
    ) {
      return true;
    }
    return false;
  }

  public get isTaiwanSelected(): boolean {
    return (
      this.passportDetailsForm?.value.countryOfIssue ===
      this.taiwanDropDownValue
    );
  }

  public get isIsraelSelected(): boolean {
    return (
      this.passportDetailsForm?.value.countryOfIssue ===
      this.israelDropDownValue
    );
  }

  public get showPassportForm() {
    const radioField = this.passportDetailsForm.get("validPassport");
    return radioField?.value === true;
  }

  // This method sets the form values type to match API
  public getFormatedFormValues(): any {
    const formValues = this.passportDetailsForm?.value;
    if (formValues) {
      return {
        ...this.passportDetailsForm.value,
        validPassport:
          formValues?.validPassport !== null
            ? formValues?.validPassport === true
              ? true
              : false
            : null,
        usingTaiwanesePassportForTrip:
          formValues?.usingTaiwanesePassportForTrip !== null
            ? formValues?.usingTaiwanesePassportForTrip === true
              ? true
              : false
            : null,
        usingIsraeliPassportForTrip:
          formValues?.usingIsraeliPassportForTrip !== null
            ? formValues?.usingIsraeliPassportForTrip === true
              ? true
              : false
            : null,
      };
    }
    return {};
  }

  public setTouched(): void {
    this.passportDetailsForm.markAsTouched();
  }

  /* PRIVATE -------------------------------------------------------------------------------- */

  private setFormDataIfSaved(): any {
    // If we have value for the passport set the value and enable the field
    if (this.formValues?.validPassport === null) {
      this.passportDetailsForm.controls.validPassport.setValue(null);
    } else {
      this.passportDetailsForm.controls.validPassport.setValue(
        this.formValues?.validPassport
      );
      if (this.formValues?.validPassport === true) {
        if (this.passportDetailsForm.controls.passportNumber) {
          this.passportDetailsForm.controls.passportNumber.setValue(
            this.formValues.passportNumber
          );
        }
        if (this.passportDetailsForm.controls.countryOfIssue) {
          this.passportDetailsForm.controls.countryOfIssue.setValue(
            this.formValues.countryOfIssue
          );
          const countryData = this.formValues.countryOfIssue;
          if (countryData === this.taiwanDropDownValue) {
            this.passportDetailsForm.controls.usingTaiwanesePassportForTrip.setValue(
              this.formValues?.usingTaiwanesePassportForTrip
            );
          }
          if (countryData === this.israelDropDownValue) {
            this.passportDetailsForm.controls.usingIsraeliPassportForTrip.setValue(
              this.formValues?.usingIsraeliPassportForTrip
            );
          }
        }
        if (this.passportDetailsForm.controls.issueDate) {
          this.passportDetailsForm.controls.issueDate.setValue(
            this.formValues.issueDate
          );
        }
        if (this.passportDetailsForm.controls.expiryDate) {
          this.passportDetailsForm.controls.expiryDate.setValue(
            this.formValues.expiryDate
          );
        }
        this.setValidatorsForPassportDetails();
      } else if (this.formValues?.validPassport === false) {
        this.clearFieldData();
      }
    }
  }

  private setValidatorsForPassportDetails(): void {
    this.passportDetailsForm?.controls.passportNumber.setValidators(
      this.passportNumberValidation
    );
    this.passportDetailsForm?.controls.passportNumber.updateValueAndValidity();

    this.passportDetailsForm?.controls.countryOfIssue.setValidators(
      this.countryOfIssueValidation
    );
    this.passportDetailsForm?.controls.countryOfIssue.updateValueAndValidity();

    this.passportDetailsForm?.controls.issueDate.setValidators(
      this.issueDateValidation
    );
    this.passportDetailsForm?.controls.issueDate.updateValueAndValidity();

    this.passportDetailsForm?.controls.expiryDate.setValidators(
      this.expiryDateValidation
    );
    this.passportDetailsForm?.controls.expiryDate.updateValueAndValidity();

    if (
      this.passportDetailsForm?.controls.countryOfIssue?.value ===
      this.taiwanDropDownValue
    ) {
      this.setTaiwanOnlyValidator();
    }
    if (
      this.passportDetailsForm?.controls.countryOfIssue?.value ===
      this.israelDropDownValue
    ) {
      this.setIsraelOnlyValidator();
    }
  }

  private setTaiwanOnlyValidator(): void {
    this.passportDetailsForm?.controls.usingTaiwanesePassportForTrip.setValidators(
      this.usingTaiwanesePassportForTripValidation
    );
    this.passportDetailsForm?.controls.usingTaiwanesePassportForTrip.updateValueAndValidity();
  }

  private setIsraelOnlyValidator(): void {
    this.passportDetailsForm?.controls.usingIsraeliPassportForTrip.setValidators(
      this.usingIsraeliPassportForTripValidation
    );
    this.passportDetailsForm?.controls.usingIsraeliPassportForTrip.updateValueAndValidity();
  }

  private clearTaiwanValidator(): void {
    this.passportDetailsForm?.controls?.usingTaiwanesePassportForTrip?.setValue(
      null
    );
    this.passportDetailsForm?.controls?.usingTaiwanesePassportForTrip?.clearValidators();
    this.passportDetailsForm?.controls?.usingTaiwanesePassportForTrip?.updateValueAndValidity();
  }

  private clearIsraelValidator(): void {
    this.passportDetailsForm?.controls?.usingIsraeliPassportForTrip?.setValue(
      null
    );
    this.passportDetailsForm?.controls?.usingIsraeliPassportForTrip?.clearValidators();
    this.passportDetailsForm?.controls?.usingIsraeliPassportForTrip?.updateValueAndValidity();
  }

  private clearFieldData(): void {
    this.passportDetailsForm?.controls?.passportNumber?.setValue(null);
    this.passportDetailsForm?.controls?.passportNumber?.clearValidators();
    this.passportDetailsForm?.controls?.passportNumber?.updateValueAndValidity();

    this.passportDetailsForm?.controls?.countryOfIssue?.setValue(null);
    this.passportDetailsForm?.controls?.countryOfIssue?.clearValidators();
    this.passportDetailsForm?.controls?.countryOfIssue?.updateValueAndValidity();

    this.passportDetailsForm?.controls?.issueDate?.setValue(null);
    this.passportDetailsForm?.controls?.issueDate?.clearValidators();
    this.passportDetailsForm?.controls?.issueDate?.updateValueAndValidity();

    this.passportDetailsForm?.controls?.expiryDate?.setValue(null);
    this.passportDetailsForm?.controls?.expiryDate?.clearValidators();
    this.passportDetailsForm?.controls?.expiryDate?.updateValueAndValidity();

    this.clearTaiwanValidator();
    this.clearIsraelValidator();
  }

  // This method watches the country dropdown is changing
  private watchCountryValue(): void {
    this.subs.push(
      this.passportDetailsForm.controls.countryOfIssue.valueChanges.subscribe(
        (value) => {
          // 2. Check if the country selected is taiwan or israel or neither
          if (value === this.taiwanDropDownValue) {
            // Enable check buttons and set required validation
            this.setTaiwanOnlyValidator();
            this.clearIsraelValidator();
          } else if (value === this.israelDropDownValue) {
            this.setIsraelOnlyValidator();
            this.clearTaiwanValidator();
          } else {
            // Disable check buttons and remove required on validation
            // Clear the values in the radio buttons
            this.clearIsraelValidator();
            this.clearTaiwanValidator();
          }
        }
      )
    );
  }

  private watchPassportValue(): void {
    this.subs.push(
      this.passportDetailsForm.controls.validPassport.valueChanges.subscribe(
        (value) => {
          // possible values = boolean true, boolean false, and null
          // check for boolean true which means that the person has indicated that they
          // have a passport.
          if (value === true) {
            this.setValidatorsForPassportDetails();
          }
          // the control is either null (no value when the page is first opened) or explicitly false
          // therefore remove the validators
          else {
            this.clearFieldData();
          }
        }
      )
    );
  }
}
