<h2>
  <span class="declaration-table__required">*</span
  >{{ "INTAKE.DECLARATION.TABLE.TITLE" | translate }}
  <span class="declaration-table__required">{{
    "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
  }}</span>
</h2>
<div [innerHTML]="'INTAKE.DECLARATION.TABLE.DESCRIPTION' | translate"></div>

<div class="wet-signature__container">
  <div class="wet-signature">
    <table class="table">
      <thead class="wet-signature__table-head">
        <tr>
          <th>
            {{
              "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.DOCUMENT_NAME"
                | translate
            }}
          </th>
          <th>
            {{ "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.DETAILS" | translate }}
          </th>
          <th>{{ "INTAKE.TABLE_HEADINGS.ATTACHMENT" | translate }}</th>
          <th>
            {{ "INTAKE.DECLARATION.TABLE.TABLE_HEADINGS.ACTION" | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="wet-signature__table-body">
        <tr class="wet-signature__table-row">
          <td class="wet-signature__name">
            <p>
              <span class="wet-signature__consent-required">*</span
              >{{ "INTAKE.DECLARATION.TABLE.DOCUMENT_NAME" | translate }}
              <span class="wet-signature__consent-required">{{
                "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate
              }}</span>
            </p>
            <!-- <span>Consent and declaration - Wet signature</span><br /> -->
            <div
              class="table__download_link"
              *ngIf="user.can('signature:write')"
            >
              <a
                href=""
                (click)="downloadConsentDoc($event)"
                class="table__download external-link"
              >
                {{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}
              </a>
            </div>
          </td>
          <td class="wet-signature__details">
            <div>
              {{ "INTAKE.DECLARATION.TABLE.DOCUMENT_DETAILS" | translate }}
              <p class="visually-hidden">HEADINGS.EMPTY_CELL.ARIA_LABEL</p>
            </div>
          </td>
          <td class="attachments wet-signature__attachment">
            <div class="wet-signature__divider" *ngIf="true">
              <div>
                <hr />
              </div>
              <h5>Uploaded File</h5>
            </div>
            <pra-attachment-status
              *ngIf="attachmentName"
              [state]="attachmentState"
              [fileName]="attachmentName"
              [fileStatus]="attachmentState"
              [error]="attachmentError"
              (deleteFile)="openDeleteModal(consentAndDeclarationDoc)"
              (downloadFile)="
                user.can('signature:write') &&
                  downloadFile(consentAndDeclarationDoc)
              "
              [canDelete]="attachmentName && user.can('signature:write')"
            >
            </pra-attachment-status>
            <p class="visually-hidden">
              "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL"
            </p>
          </td>
          <td class="wet-signature__action">
            <div class="wet-signature__button-container">
              <lib-file-upload
                fileTypes="acceptedFileTypes"
                id="consent"
                (change)="uploadFile($event)"
                buttonStyle="fixed-width"
                [disabled]="disableUpload"
              >
                {{ "INTAKE.TABLE_BUTTON.UPLOAD" | translate }}
                <span class="visually-hidden">form.name</span>
              </lib-file-upload>
            </div>
            <div class="pa-invite-warning" *ngIf="showWarningIndicateInvitePA">
              <span>{{
                "INTAKE.DECLARATION.WET_SIGNATURE.WARNING_MUST_INDICATE_INVITE_PA.TEXT"
                  | translate
              }}</span
              ><a
                [routerLink]="
                  routeLocalizer.getNestedPath(currentLang, [
                    'MANAGE_GROUP_MEMBERS'
                  ])
                "
                [innerHTML]="
                  'INTAKE.DECLARATION.WET_SIGNATURE.WARNING_MUST_INDICATE_INVITE_PA.LINK_TEXT'
                    | translate
                "
              ></a
              >{{
                "INTAKE.DECLARATION.WET_SIGNATURE.WARNING_MUST_INDICATE_INVITE_PA.SUFFIX"
                  | translate
              }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<lib-modal
  id="confirmDeleteWetSignature"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">{{ "INTAKE.DELETE_MODAL.SUBHEADING" | translate }}</p>
    <p>{{ "INTAKE.DELETE_MODAL.DESCRIPTION" | translate }}</p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" color="critical" (click)="deleteFile()">
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
