<ng-container *ngIf="!isDataLoading">
  <lib-copy-block
    [heading]="'INTAKE.IMM5562.FORMS.START.INTRO_HEADING_1' | translate"
    [body]="'INTAKE.IMM5562.FORMS.START.INTRO_COPY_1' | translate"
  >
  </lib-copy-block>
  <lib-copy-block
    [heading]="'INTAKE.IMM5562.FORMS.START.INTRO_HEADING_2' | translate"
    [body]="'INTAKE.IMM5562.FORMS.START.INTRO_COPY_2' | translate"
  >
  </lib-copy-block>
  <!-- this copy is reused on all imm5562 pages, so added into PAGES obj to be shared -->
  <lib-copy-block [body]="'INTAKE.IMM5562.PAGES.PAGE_DESCRIPTION' | translate">
  </lib-copy-block>
  <lib-copy-block
    [body]="'INTAKE.IMM5562.FORMS.START.INTRO_COPY_3' | translate"
  >
  </lib-copy-block>
</ng-container>
