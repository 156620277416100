<header>
  <h1>Design System PRDI</h1>
</header>
<div class="design-system-doc">
  <section class="design-system-doc__typography">
    <h2>Typography</h2>
    <hr />
    <lib-layout-row>
      <p>
        Headers using the
        <code class="design-system-doc__code">h1 - h6</code> tags or by applying
        a class of
        <code class="design-system-doc__code">.h1 - .h6</code> respectively
      </p>
    </lib-layout-row>
    <lib-layout-row>
      <lib-layout-col>
        <div>
          <h4>Headers - With class emphasis</h4>
          <h1 class="emphasis">h1</h1>
          <h2 class="emphasis">h2</h2>
          <h3 class="emphasis">h3</h3>
          <h4 class="emphasis">h4</h4>
          <h5 class="emphasis">h5</h5>
          <h6 class="emphasis">h6</h6>
        </div>
      </lib-layout-col>
      <lib-layout-col>
        <div>
          <h4>Headers - Without class emphasis</h4>
          <h1>h1</h1>
          <h2 class="emphasis">h2</h2>
          <h3>h3</h3>
          <h4>h4</h4>
          <h5>h5</h5>
          <h6>h6</h6>
        </div>
      </lib-layout-col>
    </lib-layout-row>
    <lib-layout-row>
      <lib-layout-col>
        <h4>Body Text</h4>
        <p>
          Body text defaults to
          <code class="design-system-doc__code">.body3</code> for size small and
          <code class="design-system-doc__code">.body2</code> large however by
          applying a class of
          <code class="design-system-doc__code">.body1 - .body4</code>
          respectively changes font size to the containing element eg
          <code class="design-system-doc__code">div, p, span, etc</code>
        </p>
      </lib-layout-col>
    </lib-layout-row>
    <lib-layout-row>
      <lib-layout-col>
        <h5>.body1</h5>
        <p class="body1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod
          nisi a ultricies consequat. Sed mattis, metus et posuere convallis,
          lectus orci feugiat nulla, nec placerat augue sem sit amet nulla.
        </p>
      </lib-layout-col>
      <lib-layout-col>
        <h5>.body2</h5>
        <p class="body2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod
          nisi a ultricies consequat. Sed mattis, metus et posuere convallis,
          lectus orci feugiat nulla, nec placerat augue sem sit amet nulla.
        </p>
      </lib-layout-col>
    </lib-layout-row>
    <lib-layout-row>
      <lib-layout-col>
        <h5>.body3</h5>
        <p class="body3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod
          nisi a ultricies consequat. Sed mattis, metus et posuere convallis,
          lectus orci feugiat nulla, nec placerat augue sem sit amet nulla.
        </p>
      </lib-layout-col>
      <lib-layout-col>
        <h5>.body4</h5>
        <p class="body4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod
          nisi a ultricies consequat. Sed mattis, metus et posuere convallis,
          lectus orci feugiat nulla, nec placerat augue sem sit amet nulla.
        </p>
      </lib-layout-col>
    </lib-layout-row>
  </section>
  <section class="design-system-doc__buttons">
    <h2 class="emphasis">Buttons</h2>
    <hr />
    <lib-layout-row>
      <lib-layout-col gap="one">
        <h4>Primary</h4>
        <button category="primary">category="Primary"</button>
        <button category="primary" color="critical">color="Critical"</button>
        <button category="primary" size="small">size="Small"</button>
        <button category="primary" disabled="disabled">Disabled</button>
      </lib-layout-col>
      <lib-layout-col gap="one">
        <h4>Secondary</h4>
        <button category="secondary">category="Secondary"</button>
        <button category="secondary" color="critical">color="Critical"</button>
        <button category="secondary" size="small">size="Small"</button>
        <button category="secondary" disabled="disabled">Disabled</button>
      </lib-layout-col>
      <lib-layout-col gap="one">
        <h4>Plain</h4>
        <button category="plain">category="Plain"</button>
        <button category="plain" color="critical">color="Critical"</button>
        <button category="plain" size="small">size="Small"</button>
        <button category="plain" disabled="disabled">Disabled</button>
      </lib-layout-col>
      <lib-layout-col gap="one">
        <h4>Link</h4>
        <button category="link">category="Link"</button>
        <button category="link" color="critical">color="Critical"</button>
        <button category="link" size="small">size="Small"</button>
        <button category="link" disabled="disabled">Disabled</button>
      </lib-layout-col>
    </lib-layout-row>
  </section>
  <section class="design-system-doc__links">
    <h2 class="emphasis">Links/Anchors</h2>
    <hr />
    <lib-layout-row>
      <lib-layout-col gap="one">
        <h4>Default</h4>
        <a>category="null"</a>
      </lib-layout-col>
      <lib-layout-col gap="one">
        <h4>Primary</h4>
        <a category="primary">category="Primary"</a>
        <a category="primary" color="critical">color="Critical"</a>
        <a category="primary" size="small">size="Small"</a>
      </lib-layout-col>
      <lib-layout-col gap="one">
        <h4>Secondary</h4>
        <a category="secondary">category="Secondary"</a>
        <a category="secondary" color="critical">color="Critical"</a>
        <a category="secondary" size="small">size="Small"</a>
      </lib-layout-col>
    </lib-layout-row>
  </section>
  <section class="design-system-doc__inputs">
    <h2 class="emphasis">Inputs</h2>
    <hr />
    <form (submit)="handleSubmit($event)" focusInvalidInput [formGroup]="form">
      <fieldset>
        <legend>Checkboxes</legend>
        <lib-form-checkbox
          identifier="checkboxRequired"
          [label]="'Checkbox'"
          [description]="'this is a description'"
          nameVal="checkbox"
          [formControl]="form.controls.checkboxRequired"
          [isRequired]="true"
        >
          <span
            *ngIf="
              form.get('checkboxRequired')?.touched &&
              form.get('checkboxRequired')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-checkbox>
        <lib-form-checkbox
          identifier="checkboxNotRequired"
          [label]="'Checkbox Not Required'"
          [description]="'this is a description'"
          nameVal="checkbox"
          [formControl]="form.controls.checkboxNotRequired"
          [isRequired]="false"
        ></lib-form-checkbox>
        <lib-form-checkbox
          identifier="checkboxReadOnly"
          [label]="'Checkbox Readonly'"
          [description]="'this is a description'"
          nameVal="checkbox"
          [formControl]="form.controls.checkboxReadOnly"
          [editable]="false"
          [isRequired]="false"
        ></lib-form-checkbox>
      </fieldset>
      <fieldset>
        <legend>Inputs</legend>
        <lib-layout-row>
          <lib-form-input
            [identifier]="'testInput13'"
            [formControl]="form.controls.inputRequired"
            [label]="'Required Input'"
            required="true"
          >
            <span
              *ngIf="
                form.get('inputRequired')?.touched &&
                form.get('inputRequired')?.hasError('required')
              "
            >
              {{ "FORM_ERRORS.REQUIRED" | translate }}
            </span>
          </lib-form-input>
          <lib-form-input
            [identifier]="'testInput1'"
            [formControl]="form.controls.inputNotRequired"
            [label]="'Not Required Input'"
          >
          </lib-form-input>
        </lib-layout-row>
        <lib-form-input
          [identifier]="'testInput138'"
          [formControl]="form.controls.inputRequired1"
          [label]="'Required Input'"
          required="true"
        >
          <span
            *ngIf="
              form.get('inputRequired1')?.touched &&
              form.get('inputRequired1')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-input>
        <lib-form-input
          [identifier]="'testInput11'"
          [formControl]="form.controls.inputNotRequired"
          [label]="'Not Required Input'"
          [helpText]="'here is some help text'"
        >
        </lib-form-input>
        <lib-form-input
          [identifier]="'testInput1122'"
          [formControl]="form.controls.inputRequired2"
          [label]="'Type = Password'"
          required="true"
          [type]="'password'"
        >
          <span
            *ngIf="
              form.get('inputRequired2')?.touched &&
              form.get('inputRequired2')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-input>
        <lib-form-input
          [identifier]="'testInput112233'"
          [formControl]="form.controls.inputRequired3"
          [label]="'Type = Email'"
          required="true"
          [type]="'email'"
        >
          <span
            *ngIf="
              form.get('inputRequired3')?.touched &&
              form.get('inputRequired3')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-input>
        <lib-form-input
          [identifier]="'testInput111'"
          [formControl]="form.controls.inputReadOnly"
          [label]="'Readonly Input'"
          [editable]="false"
        >
        </lib-form-input>
      </fieldset>
      <fieldset>
        <legend>Selects</legend>
        <lib-form-select
          [identifier]="'testSelect1'"
          [formControl]="form.controls.selectRequired"
          [label]="'Test Select'"
          [options]="options"
          required="true"
        >
          <span
            *ngIf="
              form.get('selectRequired')?.touched &&
              form.get('selectRequired')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>
        <lib-form-select
          [identifier]="'testSelect'"
          [formControl]="form.controls.selectNotRequired"
          [label]="'Select Not Required'"
          [options]="options"
        >
        </lib-form-select>
        <lib-form-select
          [identifier]="'testSelect2'"
          [formControl]="form.controls.selectReadonly"
          [label]="'Select Readonly'"
          [options]="options"
          [editable]="false"
        >
        </lib-form-select>
      </fieldset>
      <fieldset>
        <legend>Textarea</legend>
        <lib-form-textarea
          identifier="textarea2"
          [label]="'Textarea'"
          [required]="true"
          [formControl]="form.controls.textareaRequired"
        >
          <span
            *ngIf="
              form.get('textareaRequired')?.touched &&
              form.get('textareaRequired')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-textarea>
        <lib-form-textarea
          identifier="textarea"
          [label]="'Textarea Not Required'"
          [formControl]="form.controls.textareaNotRequired"
        >
        </lib-form-textarea>
        <lib-form-textarea
          identifier="textarea1"
          [label]="'Textarea Readonly'"
          [formControl]="form.controls.textareaReadOnly"
          [editable]="false"
        >
          <span
            *ngIf="
              form.get('radioRequired')?.touched &&
              form.get('radioRequired')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-textarea>
      </fieldset>
      <fieldset>
        <legend>Radios</legend>
        <lib-form-radio
          [options]="radios"
          [legend]="'Radios Required'"
          [required]="true"
          nameVal="radioOptionsRequired"
          [formControl]="form.controls.radioRequired1"
        >
          <span
            *ngIf="
              form.get('radioRequired1')?.touched &&
              form.get('radioRequired1')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span></lib-form-radio
        >
        <lib-form-radio
          [legend]="'Radios Required not options'"
          firstId="yes"
          secondId="no"
          [firstValue]="1"
          [secondValue]="0"
          [firstLabel]="'Yes'"
          [secondLabel]="'No'"
          nameVal="radioNoOptions"
          [formControl]="form.controls.radioRequired2"
          [required]="true"
        >
          <span
            *ngIf="
              form.get('radioRequired2')?.touched &&
              form.get('radioRequired2')?.hasError('required')
            "
          >
            {{ "FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-radio>
        <lib-form-radio
          [legend]="'Radios Readonly'"
          firstId="yes2"
          secondId="no2"
          [firstValue]="1"
          [secondValue]="0"
          [firstLabel]="'Yes'"
          [secondLabel]="'No'"
          nameVal="radioNoOptions2"
          [formControl]="form.controls.radioRequired"
          [editable]="false"
        >
        </lib-form-radio>
      </fieldset>
      <fieldset>
        <legend>Autocomplete</legend>
        <lib-form-autocomplete
          identifier="testAuto"
          [label]="'Autocomplete Field'"
          [options]="autoOptions"
          [formControl]="form.controls.autocomplete"
          [placeholder]="'Autocomplete'"
          [showChevron]="true"
        >
        </lib-form-autocomplete>
        <lib-form-autocomplete
          identifier="testAuto2"
          [label]="'Autocomplete Readonly'"
          [options]="autoOptions"
          [formControl]="form.controls.autocompleteRequired"
          [placeholder]="'Autocomplete'"
          [showChevron]="true"
          [readOnly]="true"
        >
        </lib-form-autocomplete>
      </fieldset>
      <fieldset>
        <legend>Datepicker</legend>
        <lib-form-datepicker
          identifier="date1"
          [formControl]="form.controls.datepicker"
          [label]="'Datepicker (has DB value of NONE)'"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date1a"
          [formControl]="form.controls.datepickerReq"
          [label]="'Datepicker (has DB value of NONE but required)'"
          [required]="true"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date122"
          [formControl]="form.controls.datepickerNull"
          [label]="'Datepicker (has DB value of null but required)'"
          [required]="true"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date1b"
          [formControl]="form.controls.datepickerLong"
          [label]="'Datepicker (has DB value of 1999/10/10)'"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date1c"
          [formControl]="form.controls.datepickerShort2"
          [label]="'Datepicker (has DB value of 1999/10)'"
          [shortFormat]="true"
        >
        </lib-form-datepicker>

        <lib-form-datepicker
          identifier="date1d"
          [formControl]="form.controls.datepickerShort2req"
          [label]="'Datepicker (has DB value of 1999/10 but required)'"
          [shortFormat]="true"
          [required]="true"
        >
        </lib-form-datepicker>
        <!-- <lib-form-datepicker
          identifier="date8"
          [formControl]="form.controls.datepickerBefore"
          [label]="'Datepicker Field'"
          [helpText]="'Compare before 2020/10/10'"
          [compareDate]="'2020/10/10'"
          [compareDateAllowEqualTo]="true"
          [compareDateDirection]="'past'"
        >
        </lib-form-datepicker> -->
        <!-- <lib-form-datepicker
          identifier="date9"
          [formControl]="form.controls.datepickerAfter"
          [label]="'Datepicker Field'"
          [helpText]="'Compare after but not equal to 2020/10/10'"
          [compareDate]="'2020/10/10'"
          [compareDateAllowEqualTo]="false"
          [compareDateDirection]="'future'"
        >
        </lib-form-datepicker> -->
        <lib-form-datepicker
          identifier="date3"
          [formControl]="form.controls.datepickerFuture"
          [label]="'Datepicker FutureDate'"
          [futureDate]="true"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date4"
          [formControl]="form.controls.datepickerPast"
          [label]="'Datepicker PastDate'"
          [pastDate]="true"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date2"
          [formControl]="form.controls.datepickerRequired"
          [label]="'Datepicker Field Required'"
          [required]="true"
          [helpText]="'has custom validation added'"
        >
          <span
            *ngIf="
              form.get('datepickerRequired')?.touched &&
              form.get('datepickerRequired')?.hasError('email')
            "
          >
            Custom validation applied (no date will validate).
          </span>
          <span
            *ngIf="
              form.get('datepickerRequired')?.touched &&
              form.get('datepickerRequired')?.hasError('minlength')
            "
          >
            Min length to showcase multiple custom validations.
          </span>
        </lib-form-datepicker>

        <lib-form-datepicker
          identifier="date23"
          [formControl]="form.controls.datepickerShort"
          [label]="'Datepicker Field Short Date'"
          [shortFormat]="true"
        >
        </lib-form-datepicker>
        <lib-form-datepicker
          identifier="date2"
          [formControl]="form.controls.datepickerReadonly"
          [label]="'Datepicker Field Readonly'"
          [readOnly]="true"
        >
        </lib-form-datepicker>
      </fieldset>
      <button>Submit</button>
    </form>
  </section>
  <section class="design-system-doc__card">
    <h2 class="emphasis">Card</h2>
    <hr />
    <p>
      Cards are a library component which accept a group of content by injecting
      it with <code class="design-system-doc__code">ng-content</code>. You can
      see below using the new form layout components of
      <code class="design-system-doc__code">lib-row</code> and
      <code class="design-system-doc__code">lib-col</code>, you can size
      components relative to each other.
    </p>
    <lib-layout-row>
      <lib-layout-col>
        <lib-card>
          <p class="h4">Card</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            euismod nisi a ultricies consequat. Sed mattis, metus et posuere
            convallis, lectus orci feugiat nulla, nec placerat augue sem sit
            amet nulla.
          </p>
        </lib-card>
      </lib-layout-col>
      <lib-layout-col>
        <lib-card maxWidth="10rem">
          <p class="h4">Card - max width of 10rem</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            euismod nisi a ultricies consequat.
          </p>
        </lib-card>
      </lib-layout-col>
      <lib-layout-col flex="2">
        <lib-card>
          <p class="h4">Card - Layout Col with Flex="2"</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            euismod nisi a ultricies consequat. Sed mattis, metus et posuere
            convallis, lectus orci feugiat nulla, nec placerat augue sem sit
            amet nulla.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            euismod nisi a ultricies consequat. Sed mattis, metus et posuere
            convallis, lectus orci feugiat nulla, nec placerat augue sem sit
            amet nulla.
          </p>
        </lib-card>
      </lib-layout-col>
    </lib-layout-row>
  </section>
</div>
