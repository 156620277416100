<h1 class="page__title">
  <!-- {{ "PRCARDPRTD.RENEWAL_LANDINGPAGE.TITLE" | translate }} -->
  {{ "INTAKE.DASHBOARD.START_CASE_PAGE.TITLE" | translate }}
</h1>
<section>
  <!-- <h2>{{ "PRCARDPRTD.RENEWAL_LANDINGPAGE.SUB_TITLE" | translate }}</h2>
  <div
    [innerHTML]="
      'PRCARDPRTD.RENEWAL_LANDINGPAGE.SITUATION_DESCRIPTION' | translate
    "
  ></div> -->
</section>
<lib-spinner [showSpinner]="isSubmitting" [fullPage]="true"></lib-spinner>
<div class="entryFormConatiner">
  <form [formGroup]="prcPrtdSituationForm" autocomplete="off">
    <fieldset>
      <legend>
        <!-- {{
          "PRCARDPRTD.RENEWAL_LANDINGPAGE.CHOOSE_SITUATION_LEGEND" | translate
        }} -->
      </legend>

      <!-- [label]="'PRCARDPRTD.RENEWAL_LANDINGPAGE.SITUATION' | translate" -->
      <lib-form-select
        identifier="situation"
        required="true"
        [formControl]="prcPrtdSituationForm.controls.situation"
        [options]="prcPrtdSituationOptions"
        widthMd="true"
        (change)="resetApplicationTypeDropDown()"
        [label]="
          'INTAKE.PROGRAM_SELECTION_FORM.PROGRAM_SET.PROGRAM_LABEL' | translate
        "
      >
        <span
          *ngIf="
            prcPrtdSituationForm.get('situation')?.touched &&
            prcPrtdSituationForm.get('situation')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <lib-form-select
        *ngIf="
          prcPrtdSituationForm.get('situation')?.value ===
          prcPrtdSituationCode.PRRenewal
        "
        identifier="applicationType"
        required="true"
        [formControl]="prcPrtdSituationForm.controls.applicationType"
        [options]="insideOutsideCanadaOptions"
        widthMd="true"
        (change)="resetDeclarationCheckbox()"
        label="Situation"
      >
        <span
          *ngIf="
            prcPrtdSituationForm.get('applicationType')?.touched &&
            prcPrtdSituationForm.get('applicationType')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
    </fieldset>
    <div
      class="disclaimerMain"
      *ngIf="
        prcPrtdSituationForm.get('applicationType')?.value ===
          insideOutsideCanadaSituationCodes.OutsideOfCanada ||
        prcPrtdSituationForm.get('applicationType')?.value ===
          insideOutsideCanadaSituationCodes.InCanada
      "
    >
      <div class="warning-text">
        <p
          class="disclaimer-title"
          [innerHTML]="'PRCARDPRTD.RENEWAL_LANDINGPAGE.DISCLAIMER' | translate"
        ></p>
        <p
          *ngIf="
            prcPrtdSituationForm.get('applicationType')?.value ===
            insideOutsideCanadaSituationCodes.InCanada
          "
          [innerHTML]="
            'PRCARDPRTD.RENEWAL_LANDINGPAGE.DISCALIMER_TXT_INCAN' | translate
          "
        ></p>
        <p
          *ngIf="
            prcPrtdSituationForm.get('applicationType')?.value ===
            insideOutsideCanadaSituationCodes.OutsideOfCanada
          "
          [innerHTML]="
            'PRCARDPRTD.RENEWAL_LANDINGPAGE.DISCALIMER_TXT_OUTSIDECAN'
              | translate
          "
        ></p>
        <div class="confirm-disclaimer">
          <lib-form-checkbox
            identifier="situationDisclaimer"
            [description]="
              'PRCARDPRTD.RENEWAL_LANDINGPAGE.I_READ_DISCLAIMER' | translate
            "
            nameVal="situationDisclaimer"
            [formControl]="prcPrtdSituationForm.controls.situationDisclaimer"
            [isRequired]="false"
          >
          </lib-form-checkbox>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        prcPrtdSituationForm.get('situation')?.value ===
        prcPrtdSituationCode.PRIntake
      "
    >
      <pra-program-selection-form></pra-program-selection-form>
    </div>
  </form>
</div>
<div
  class="form__buttons"
  *ngIf="
    prcPrtdSituationForm.get('applicationType')?.value !==
    prcPrtdSituationCode.PRIntake
  "
>
  <button
    type="button"
    class="btn"
    category="secondary"
    *ngIf="
      prcPrtdSituationForm.get('applicationType')?.value &&
      prcPrtdSituationForm.get('applicationType')?.value ===
        insideOutsideCanadaSituationCodes.OutsideOfCanada
    "
    (click)="applyToRenounceForPrStatus()"
  >
    {{ "PRCARDPRTD.RENEWAL_LANDINGPAGE.BTTN.RENOUNCEPRC" | translate }}
  </button>
  <button
    type="button"
    category="primary"
    [ngClass]="{
      'btn-primary-alt':
        prcPrtdSituationForm.get('applicationType')?.value ===
        insideOutsideCanadaSituationCodes.OutsideOfCanada
    }"
    *ngIf="
      prcPrtdSituationForm.get('applicationType')?.value &&
      prcPrtdSituationForm.get('applicationType')?.value !==
        insideOutsideCanadaSituationCodes.InCanada
    "
    (click)="applyForTravelDocuments()"
    [disabled]="!prcPrtdSituationForm.controls.situationDisclaimer.value"
  >
    {{ "PRCARDPRTD.RENEWAL_LANDINGPAGE.BTTN.PRTD" | translate }}
  </button>
  <button
    type="button"
    category="primary"
    *ngIf="
      prcPrtdSituationForm.get('applicationType')?.value &&
      prcPrtdSituationForm.get('applicationType')?.value ===
        insideOutsideCanadaSituationCodes.InCanada
    "
    (click)="applyForPRCard()"
    [disabled]="!prcPrtdSituationForm.controls.situationDisclaimer.value"
  >
    {{ "PRCARDPRTD.RENEWAL_LANDINGPAGE.BTTN.PRCARD" | translate }}
  </button>
</div>
