<h3>{{ "INTAKE.OTHER.FORMS.SUBHEADING" | translate }}</h3>
<p>{{ "INTAKE.OTHER.DESC" | translate }}</p>
<div class="other-forms__container" #otherFormContainer>
  <form>
    <div class="other-forms__input-container">
      <lib-icon-chooser icon="search"></lib-icon-chooser>
      <input
        class="other-forms__input"
        type="text"
        [formControl]="formControl"
        [matAutocomplete]="auto"
        [placeholder]="'INTAKE.OTHER.FORMS.INPUT_PLACEHOLDER' | translate"
        #trigger="matAutocompleteTrigger"
        #otherFormsInput
        (click)="handleClick(trigger)"
      />
      <div class="chevron"></div>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="resetInput()">
      <mat-option
        *ngFor="let option of filteredOptions | async; let i = index"
        [value]="option.documentType"
        (onSelectionChange)="addRow(option, $event)"
      >
        {{ option.details }}
      </mat-option>
    </mat-autocomplete>
  </form>
  <div class="other-forms">
    <table class="table">
      <thead class="other-forms__table-head">
        <tr>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.FORM_NAME" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.DETAILS" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.ATTACHMENT" | translate }}
          </th>
          <th *ngIf="user.can('documents:write')">
            {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="other-forms__table-body">
        <ng-container *ngIf="otherFormRows.length > 0; else noDocuments">
          <tr
            *ngFor="let form of otherFormRows; let indexOfRow = index"
            class="other-forms__table-row"
          >
            <td class="other-forms__name">
              <span>{{ form.name }}</span
              ><br />
              <div class="table__download_link">
                <a
                  *ngIf="form.link && !form.isWebForm"
                  href="{{ form.link }}"
                  download="{{ form.link.split('/')[12] }}"
                  target="_blank"
                  class="table__download external-link"
                >
                  [{{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}]
                  <span class="visually-hidden">{{
                    (form.details ? form.details + " " : "") +
                      ("EXTERNAL_LINK" | translate)
                  }}</span>
                </a>
              </div>
            </td>
            <td class="other-forms__details">
              <div *ngIf="form.details">
                {{ form.details }}
                <p *ngIf="!form.details" class="visually-hidden">
                  {{
                    "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate
                  }}
                </p>
              </div>
              <!-- Only displayed if there are attachments and the  document is type Web form -->
              <div
                class="table__warning-container"
                *ngIf="
                  form.displayWarningMsg &&
                  form.attachments &&
                  form.attachments[0]
                "
              >
                <img
                  class="alert-icon"
                  src="../../../../../assets/images/icons/triangle-warning.svg"
                  aria-hidden="true"
                  alt=""
                />
                <div
                  class="table__warning-text"
                  [innerHTML]="'INTAKE.NEW_WEBFORM_WARNING' | translate"
                ></div>
              </div>

              <!-- Only display for legacy IMM0008 dependents (Deprecated)-->
              <div
                class="table__warning-container"
                *ngIf="
                  form.attachments &&
                  form.attachments[0] &&
                  form.name === 'IMM 0008 DEP'
                "
              >
                <img
                  class="alert-icon"
                  src="../../../../../assets/images/icons/triangle-warning.svg"
                  aria-hidden="true"
                  alt=""
                />
                <div
                  class="table__warning-text"
                  [innerHTML]="
                    'INTAKE.EXISTING_DEPENDANTS_PDF_WARNING' | translate
                  "
                ></div>
              </div>
            </td>
            <td class="attachments other-forms__attachment">
              <div
                class="other-forms__divider"
                *ngIf="form.attachments.length > 0"
              >
                <div>
                  <hr />
                </div>
                <h5>Uploaded Files</h5>
              </div>
              <div *ngFor="let attachedForm of form.attachments">
                <pra-attachment-status
                  [state]="
                    attachedForm.status.statusType === 'incomplete'
                      ? 'warning'
                      : 'success'
                  "
                  [fileName]="
                    attachedForm?.uploadedRawDocument?.documentName
                      ?.split('/')
                      .pop()
                  "
                  [fileStatus]="attachedForm.status.statusType"
                  [canDelete]="user.can('documents:write')"
                  (deleteFile)="
                    openDynamicDeleteModal(
                      'INTAKE.DELETE_MODAL.SUBHEADING',
                      'INTAKE.DELETE_MODAL.DESCRIPTION',
                      removeForm,
                      attachedForm?.uploadedRawDocument
                    )
                  "
                  (downloadFile)="
                    downloadFile(attachedForm?.uploadedRawDocument)
                  "
                >
                </pra-attachment-status>
              </div>
              <div
                *ngFor="
                  let temp of tempAttachments[indexOfRow];
                  let attachmentIndex = index
                "
              >
                <pra-attachment-status
                  [state]="temp.state"
                  [fileName]="temp.fileName"
                  [canDelete]="user.can('documents:write')"
                  (deleteFile)="
                    removeTempFileError(indexOfRow, attachmentIndex)
                  "
                  [error]="temp.error"
                >
                </pra-attachment-status>
              </div>

              <p
                *ngIf="
                  !form.attachments || !form.attachments[0] || form.isWebForm
                "
                class="visually-hidden"
              >
                {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
              </p>
            </td>
            <td *ngIf="user.can('documents:write')" class="other-forms__action">
              <!--Actions-->
              <div class="other-forms__button-container">
                <lib-file-upload
                  fileTypes="{{ acceptedFileTypes }}"
                  id="{{ form.name }}"
                  (change)="
                    setTempFileInfoAndUpload(
                      $event,
                      indexOfRow,
                      form.documentTypeId
                    )
                  "
                  buttonStyle="fixed-width"
                  [disabled]="isHiddenLob"
                >
                  {{ "INTAKE.TABLE_BUTTON.UPLOAD" | translate }}
                  <span class="visually-hidden">{{ form.name }}</span>
                </lib-file-upload>

                <!--
                  show if:
                  - is webform & one of the following is true:
                    - is client & can edit (no rep)
                    - is client & document exists (rep created doc)
                    - is rep
                -->

                <button
                  *ngIf="
                    form.isWebForm &&
                    (user.can('documents:write') || form.attachments.length > 0)
                  "
                  [routerLink]="
                    routeLocalizer.get(currentLang, form.webFormRoute)
                  "
                  category="secondary"
                  [disabled]="isHiddenLob"
                >
                  {{ buttonStatus(form) }}
                  <span class="visually-hidden">{{ form.name }}</span>
                </button>
                <button
                  category="plain"
                  color="critical"
                  (click)="
                    openDynamicDeleteModal(
                      'INTAKE.DELETE_MODAL.DELETE_ROW_SUBHEADING',
                      'INTAKE.DELETE_MODAL.DELETE_ROW_DESCRIPTION',
                      removeRow,
                      indexOfRow,
                      form.documentTypeId
                    )
                  "
                >
                  <lib-icon-chooser icon="delete"></lib-icon-chooser>
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #noDocuments>
          <tr *ngIf="!loadingDocs">
            <td class="other-documents__no-documents" colspan="4">
              {{
                "INTAKE.TABLE_HEADINGS.EMPTY_CELL.NO_DOCUMENTS_UPLOADED"
                  | translate
              }}
            </td>
          </tr>
          <tr *ngIf="loadingDocs">
            <td colspan="100%">
              <lib-spinner class="table__spinner" [fullPage]="false">
              </lib-spinner>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
<lib-modal
  [id]="deleteModalId"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ deleteHeading | translate }}
    </p>
    <p>
      {{ deleteDescription | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button
      category="primary"
      color="critical"
      (click)="
        deleteFn(
          deleteFnParams[0],
          deleteFnParams[1] ? deleteFnParams[1] : null
        )
      "
    >
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
