<h2>{{ "INTAKE.LANDING_PAGE.APPLICATION_TITLE" | translate }}</h2>
<p [innerHTML]="'INTAKE.LANDING_PAGE.APPLICATION_DESCRIPTION' | translate"></p>
<h3 *ngIf="!isCurrentLobAFamilyClassLob">
  {{ "INTAKE.LANDING_PAGE.FORM_TITLE" | translate }}
  {{ category }}
</h3>
<h3 *ngIf="isCurrentLobAFamilyClassLob">
  {{ "INTAKE.LANDING_PAGE.FORM_TITLE_FAMILY_CLASS" | translate }}
</h3>
<p>{{ "INTAKE.LANDING_PAGE.FORM_SUBTITLE" | translate }}</p>
<div class="wrapper">
  <div class="mandatory-web-forms-container">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.FORM_NAME" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.DETAILS" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.STATUS" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let doc of appFormTableRows; let i = index"
          [class.isHidden]="!doc.isWebForm"
        >
          <td
            [id]="doc.isWebForm ? 'requiredForm' + i + 'Anchor' : ''"
            class="mandatory-web-forms-form-name"
          >
            <span class="mandatory-web-forms__form-name">
              <span
                class="digitial-and-pdf-forms__form-required"
                *ngIf="!doc.optional"
              >
                *
              </span>
              {{ doc.name }}
              <span
                class="digitial-and-pdf-forms__form-required"
                *ngIf="!doc.optional"
              >
                {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}
              </span>
            </span>
            <br />
            <div class="table__download_link">
              <a
                *ngIf="doc.link && !doc.isWebForm"
                href="{{ doc.link }}"
                download="{{ doc.link.split('/')[12] }}"
                target="_blank"
                class="table__download external-link"
              >
                [{{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}]
                <span class="visually-hidden">{{
                  (doc.details ? doc.details + " " : "") +
                    ("EXTERNAL_LINK" | translate)
                }}</span>
              </a>
            </div>
          </td>
          <td class="mandatory-web-forms-details">
            <div *ngIf="doc.details">
              {{ doc.details }}
              <p *ngIf="!doc.details" class="visually-hidden">
                {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
              </p>
            </div>
            <!-- Only displayed if there are attachments and the document is type Web form -->
            <div
              class="table__warning-container"
              *ngIf="
                doc.displayWarningMsg && doc.attachments && doc.attachments[0]
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="'INTAKE.NEW_WEBFORM_WARNING' | translate"
              ></div>
            </div>

            <!-- Only display for legacy IMM0008 dependents (Deprecated)-->
            <div
              class="table__warning-container"
              *ngIf="
                doc.attachments &&
                doc.attachments[0] &&
                doc.name === 'IMM 0008 DEP'
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="
                  'INTAKE.EXISTING_DEPENDANTS_PDF_WARNING' | translate
                "
              ></div>
            </div>
          </td>
          <td class="attachments mandatory-web-forms-status">
            <div *ngIf="doc.attachments && doc.attachments[0]">
              <div *ngFor="let attachedForm of doc.attachments">
                <lib-status-pill
                  *ngIf="attachedForm.status"
                  [text]="attachedForm.status.statusLabel | translate"
                  [type]="attachedForm.status.statusType"
                  [fontSize]="'0.8rem'"
                >
                </lib-status-pill>
              </div>
            </div>
            <!-- Check if there is no status -->
            <div
              *ngIf="
                (doc.attachments[0]?.uploadedRawDocument?.isComplete === null &&
                  doc.documentTypeId !== 41) ||
                (doc.attachments[0]?.uploadedRawDocument?.isComplete === null &&
                  doc.attachments.length === 0 &&
                  doc.documentTypeId === 41)
              "
            >
              <div>
                <lib-status-pill
                  [text]="
                    'INTAKE.TABLE_HEADINGS.EMPTY_CELL.NOT_STARTED' | translate
                  "
                  [fontSize]="'0.8rem'"
                  [type]="doc.attachments[0]?.status"
                >
                </lib-status-pill>
              </div>
            </div>
          </td>

          <td class="mandatory-web-forms-actions">
            <!--Actions-->
            <lib-spinner
              *ngIf="!doc.isWebForm && doc.webFormTableSpinner"
              class="table__spinner"
              [fullPage]="false"
            >
            </lib-spinner>
            <lib-file-upload
              *ngIf="
                !doc.isWebForm &&
                !doc.webFormTableSpinner &&
                user.can('documents:write')
              "
              fileTypes="{{ acceptedFileTypes }}"
              id="{{ doc.name }}"
              (change)="uploadFile($event, doc.documentTypeId)"
              buttonStyle="primary-alt"
              [disabled]="isHiddenLob"
            >
              {{ "INTAKE.TABLE_BUTTON.UPLOAD" | translate }}
              <span class="visually-hidden">{{ doc.name }}</span>
            </lib-file-upload>

            <!--
            show if:
            - is webform & one of the following is true:
              - is client & can edit (no rep)
              - is client & document exists (rep created doc)
              - is rep
          -->
            <button
              *ngIf="
                doc.isWebForm &&
                (user.can('documents:write') || doc.attachments.length > 0)
              "
              [routerLink]="routeLocalizer.get(currentLang, doc.webFormRoute)"
              class="btn btn-primary-alt"
              [disabled]="isHiddenLob"
            >
              {{ buttonStatus(doc) }}
              <span class="visually-hidden">{{ doc.name }}</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="appFormTableRows.length === 0">
        <tr>
          <td colspan="100%">
            <lib-spinner class="table__spinner" [fullPage]="false">
            </lib-spinner>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <h3 *ngIf="!isCurrentLobAFamilyClassLob" id="requiredPdfFormsAnchor">
    <span class="digitial-and-pdf-forms__required"> * </span>
    {{ "INTAKE.LANDING_PAGE.PDF_FORM_TITLE" | translate }} {{ category }}
    <span class="digitial-and-pdf-forms__required">
      {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}
    </span>
  </h3>
  <h3 *ngIf="isCurrentLobAFamilyClassLob" id="requiredPdfFormsAnchor">
    <span class="digitial-and-pdf-forms__required"> * </span>
    {{ "INTAKE.LANDING_PAGE.PDF_FORM_TITLE_FAMILY_CLASS" | translate }}
    <span class="digitial-and-pdf-forms__required">
      {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}
    </span>
  </h3>
  <p>{{ "INTAKE.LANDING_PAGE.PDF_FORM_SUBTITLE" | translate }}</p>
  <div class="mandatory-forms-container">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.FORM_NAME" | translate }}
          </th>
          <th class="mandatory-forms-container__header-details">
            {{ "INTAKE.TABLE_HEADINGS.DETAILS" | translate }}
          </th>
          <th class="mandatory-forms-container__header-attachments">
            {{ "INTAKE.TABLE_HEADINGS.ATTACHMENT" | translate }}
          </th>
          <th
            *ngIf="user.can('documents:write')"
            class="mandatory-forms-container__header-actions"
          >
            {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let doc of appFormTableRows; let indexOfRow = index"
          [class.isHidden]="
            doc.isWebForm || (user.is('client') && doc.documentTypeId === 31)
          "
        >
          <!-- hiding representative IMM5476 form(31) from clients or showing representative IMM5476 form(31) only to representative -->
          <td>
            <span class="mandatory-forms-container__form-name">
              <span class="digitial-and-pdf-forms__form-required"> * </span>
              <span>
                {{ doc.name }}
              </span>
              <span class="digitial-and-pdf-forms__form-required">
                {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}
              </span>
            </span>
            <br />
            <div class="table__download_link">
              <a
                *ngIf="doc.link && !doc.isWebForm"
                href="{{ doc.link }}"
                download="{{ doc.link.split('/')[12] }}"
                target="_blank"
                class="table__download external-link"
              >
                [{{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}]
                <span class="visually-hidden">{{
                  (doc.details ? doc.details + " " : "") +
                    ("EXTERNAL_LINK" | translate)
                }}</span>
              </a>
            </div>
          </td>
          <td>
            <div *ngIf="doc.details">
              {{ doc.details }}
              <p *ngIf="!doc.details" class="visually-hidden">
                {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
              </p>
            </div>
            <!-- Only displayed if there are attachments and the document is type Web form -->
            <div
              class="table__warning-container"
              *ngIf="
                doc.displayWarningMsg && doc.attachments && doc.attachments[0]
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="'INTAKE.NEW_WEBFORM_WARNING' | translate"
              ></div>
            </div>

            <!-- Only display for legacy IMM0008 dependents (Deprecated)-->
            <div
              class="table__warning-container"
              *ngIf="
                doc.attachments &&
                doc.attachments[0] &&
                doc.name === 'IMM 0008 DEP'
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="
                  'INTAKE.EXISTING_DEPENDANTS_PDF_WARNING' | translate
                "
              ></div>
            </div>
          </td>
          <td class="attachments">
            <div *ngIf="doc && !doc.isWebForm">
              <div *ngFor="let attachedForm of doc.attachments">
                <pra-attachment-status
                  [state]="
                    attachedForm.status.statusType === 'incomplete'
                      ? 'warning'
                      : 'success'
                  "
                  [fileName]="
                    attachedForm?.uploadedRawDocument?.documentName
                      ?.split('/')
                      .pop()
                  "
                  [fileStatus]="attachedForm.status.statusType"
                  [canDelete]="user.can('documents:write')"
                  (deleteFile)="
                    openDeleteModal(attachedForm?.uploadedRawDocument)
                  "
                  (downloadFile)="
                    downloadFile(attachedForm?.uploadedRawDocument)
                  "
                >
                </pra-attachment-status>
              </div>
              <div
                *ngFor="
                  let temp of tempAttachments[indexOfRow];
                  let attachmentIndex = index
                "
              >
                <pra-attachment-status
                  [state]="temp.state"
                  [fileName]="temp.fileName"
                  [canDelete]="user.can('documents:write')"
                  (deleteFile)="
                    removeTempFileError(indexOfRow, attachmentIndex)
                  "
                  [error]="temp.error"
                >
                </pra-attachment-status>
              </div>
            </div>
            <p
              *ngIf="!doc.attachments || !doc.attachments[0] || doc.isWebForm"
              class="visually-hidden"
            >
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
          <td *ngIf="user.can('documents:write')">
            <!--Actions-->
            <lib-file-upload
              *ngIf="!doc.isWebForm && user.can('documents:write')"
              fileTypes="{{ acceptedFileTypes }}"
              id="{{ doc.name }}"
              (change)="
                setTempFileInfoAndUpload($event, indexOfRow, doc.documentTypeId)
              "
              buttonStyle="primary-alt"
              [disabled]="isHiddenLob"
            >
              {{ "INTAKE.TABLE_BUTTON.UPLOAD" | translate }}
              <span class="visually-hidden">{{ doc.name }}</span>
            </lib-file-upload>

            <!--
                show if:
                - is webform & one of the following is true:
                  - is client & can edit (no rep)
                  - is client & document exists (rep created doc)
                  - is rep
              -->
            <button
              *ngIf="
                doc.isWebForm &&
                (user.can('documents:write') || doc.attachments.length > 0)
              "
              [routerLink]="routeLocalizer.get(currentLang, doc.webFormRoute)"
              class="btn btn-primary-alt"
              [disabled]="isHiddenLob"
            >
              {{ buttonStatus(doc) }}
              <span class="visually-hidden">{{ doc.name }}</span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="appFormTableRows.length === 0">
        <tr>
          <td colspan="100%">
            <lib-spinner class="table__spinner" [fullPage]="false">
            </lib-spinner>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mandatory-forms-container-mobile">
    <table class="table">
      <thead>
        <tr>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.FORM_NAME" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.DETAILS" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.ACTIONS" | translate }}
          </th>
          <th>
            {{ "INTAKE.TABLE_HEADINGS.ATTACHMENT" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let doc of appFormTableRows; let indexOfRow = index"
          [class.isHidden]="
            doc.isWebForm || (user.is('client') && doc.documentTypeId === 31)
          "
        >
          <!-- hiding representative IMM5476 form(31) from clients or showing representative IMM5476 form(31) only to representative -->
          <td>
            <span class="mandatory-forms-container__form-name">
              <span class="digitial-and-pdf-forms__form-required"> * </span>
              {{ doc.name }}
              <span class="digitial-and-pdf-forms__form-required">
                {{ "INTAKE.LANDING_PAGE.REQUIRED_LABEL" | translate }}
              </span>
            </span>
          </td>
          <td>
            <div *ngIf="doc.details">
              {{ doc.details }}
              <p *ngIf="!doc.details" class="visually-hidden">
                {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
              </p>
            </div>
            <!-- Only displayed if there are attachments and the document is type Web form -->
            <div
              class="table__warning-container"
              *ngIf="
                doc.displayWarningMsg && doc.attachments && doc.attachments[0]
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="'INTAKE.NEW_WEBFORM_WARNING' | translate"
              ></div>
            </div>

            <!-- Only display for legacy IMM0008 dependents (Deprecated)-->
            <div
              class="table__warning-container"
              *ngIf="
                doc.attachments &&
                doc.attachments[0] &&
                doc.name === 'IMM 0008 DEP'
              "
            >
              <img
                class="alert-icon"
                src="../../../../../assets/images/icons/triangle-warning.svg"
                aria-hidden="true"
                alt=""
              />
              <div
                class="table__warning-text"
                [innerHTML]="
                  'INTAKE.EXISTING_DEPENDANTS_PDF_WARNING' | translate
                "
              ></div>
            </div>
          </td>
          <td>
            <!--Actions-->
            <div class="download-button-container">
              <a
                *ngIf="doc.link && !doc.isWebForm"
                href="{{ doc.link }}"
                download="{{ doc.link.split('/')[12] }}"
                target="_blank"
                class="btn btn-primary-alt"
              >
                {{ "INTAKE.TABLE_BUTTON.DOWNLOAD" | translate }}
                <span class="visually-hidden">{{
                  (doc.details ? doc.details + " " : "") +
                    ("EXTERNAL_LINK" | translate)
                }}</span>
              </a>
            </div>
            <div class="upload-button-container">
              <lib-file-upload
                *ngIf="!doc.isWebForm && user.can('documents:write')"
                fileTypes="{{ acceptedFileTypes }}"
                id="{{ doc.name }}"
                (change)="
                  setTempFileInfoAndUpload(
                    $event,
                    indexOfRow,
                    doc.documentTypeId
                  )
                "
                buttonStyle="primary-alt"
                [disabled]="isHiddenLob"
              >
                {{ "INTAKE.TABLE_BUTTON.UPLOAD" | translate }}
                <span class="visually-hidden">{{ doc.name }}</span>
              </lib-file-upload>

              <!--
                show if:
                - is webform & one of the following is true:
                  - is client & can edit (no rep)
                  - is client & document exists (rep created doc)
                  - is rep
              -->
              <button
                *ngIf="
                  doc.isWebForm &&
                  (user.can('documents:write') || doc.attachments.length > 0)
                "
                [routerLink]="routeLocalizer.get(currentLang, doc.webFormRoute)"
                class="btn btn-primary-alt"
                [disabled]="isHiddenLob"
              >
                {{ buttonStatus(doc) }}
                <span class="visually-hidden">{{ doc.name }}</span>
              </button>
            </div>
          </td>
          <td class="attachments mandatory-forms-mobile-attachment-td">
            <div
              *ngIf="doc.attachments && doc.attachments[0] && !doc.isWebForm"
            >
              <div class="mandatory-forms-mobile-attachment-heading-divider">
                <hr />
              </div>
              <div class="mandatory-forms-attachment-heading-container">
                <strong>Uploaded files</strong>
              </div>
              <div *ngFor="let attachedForm of doc.attachments">
                <div *ngIf="doc.link">
                  <div>
                    <pra-attachment-status
                      [state]="
                        doc.attachments[0].status.statusType === 'incomplete'
                          ? 'warning'
                          : 'success'
                      "
                      [fileName]="
                        attachedForm?.uploadedRawDocument?.documentName
                          ?.split('/')
                          .pop()
                      "
                      [fileStatus]="doc.attachments[0].status.statusType"
                      [canDelete]="user.can('documents:write')"
                      (deleteFile)="
                        openDeleteModal(attachedForm?.uploadedRawDocument)
                      "
                      (downloadFile)="
                        downloadFile(attachedForm?.uploadedRawDocument)
                      "
                    >
                    </pra-attachment-status>
                  </div>

                  <div class="clear"></div>
                </div>
              </div>
            </div>
            <div
              *ngFor="
                let temp of tempAttachments[indexOfRow];
                let attachmentIndex = index
              "
            >
              <pra-attachment-status
                [state]="temp.state"
                [fileName]="temp.fileName"
                (deleteFile)="removeTempFileError(indexOfRow, attachmentIndex)"
                [error]="temp.error"
              >
              </pra-attachment-status>
            </div>
            <p
              *ngIf="!doc.attachments || !doc.attachments[0] || doc.isWebForm"
              class="visually-hidden"
            >
              {{ "INTAKE.TABLE_HEADINGS.EMPTY_CELL.ARIA_LABEL" | translate }}
            </p>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="appFormTableRows.length === 0">
        <tr>
          <td colspan="100%">
            <lib-spinner class="table__spinner" [fullPage]="false">
            </lib-spinner>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<lib-modal
  [id]="deleteModalId"
  [title]="'INTAKE.DELETE_MODAL.TITLE' | translate"
  [deleteModal]="true"
>
  <ng-container modalBody>
    <p class="subheading">
      {{ "INTAKE.DELETE_MODAL.SUBHEADING" | translate }}
    </p>
    <p>
      {{ "INTAKE.DELETE_MODAL.DESCRIPTION" | translate }}
    </p>
  </ng-container>
  <ng-container modalButtons>
    <button category="primary" color="critical" (click)="deleteFile()">
      {{ "INTAKE.DELETE_MODAL.DELETE" | translate }}
    </button>
    <button category="secondary" (click)="closeModal()">
      {{ "INTAKE.DELETE_MODAL.CANCEL" | translate }}
    </button>
  </ng-container>
</lib-modal>
