import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "pra-cases-search",
  templateUrl: "./cases-search.component.html",
  styleUrls: ["./cases-search.component.scss"],
})
export class CasesSearchComponent implements OnInit {
  public searchField: FormControl;

  @Input() copyKey: string;
  @Output() searchedTerm = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.searchField = new FormControl("");
  }

  onSubmit() {
    const formatSearchValue = this.searchField.value.trim();
    this.searchedTerm.emit(formatSearchValue);
  }

  onClear() {
    this.searchField.setValue("");
    this.onSubmit();
  }

  public resetSearchValue(): void {
    this.searchField.setValue("");
  }
}
