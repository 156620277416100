import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
} from "@angular/core";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { RelatedDocumentType } from "@pr-applicant/app/core/models/lob.model";
import {
  INavigationItem,
  NavigationItemType,
  INavigationItemAccordion,
  INavigationItemLink,
  NavigationService,
  INavigationConfig,
  IndicatorStatus,
} from "ircc-ds-angular-component-library";
import { createSideNav } from "./intake-side-nav.config";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { LobIds } from "lib";

const PAYMENT_ID = 4;
const WET_SIGNATURE_ID = 305;
const DECLARATION_ID = 301;
const REFERRALDOC_ID = 401;
const S3 = "s3";
const MONGO = "mongo";
const INCOMPLETE_ICON = "fa-light fa-circle";
const COMPLETE_ICON = "fa-regular fa-circle-check";

@Component({
  selector: "pra-intake-side-nav",
  templateUrl: "./intake-side-nav.component.html",
  styleUrls: ["./intake-side-nav.component.scss"],
})
export class IntakeSideNavComponent implements OnInit, OnChanges {
  @Input() ApplicationFormsData: any[];
  @Input() requiredFormList: any;
  @Input() supportingDocsData: any[];
  @Input() caseData: Case;

  subscription: Subscription[] = [];

  navConfig: INavigationConfig = createSideNav();

  showNav: boolean | null = null;

  private mongoDocs: any[] = [];
  private requiredPdfDocs: any[] = [];

  constructor(
    private navigationService: NavigationService,
    private translateService: TranslateService,
    public userService: UserService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.ApplicationFormsData && changes?.requiredFormList) {
      /*code block to sort the mongo docs so that they match the order displayed
      in the content table (stolen from web-form-table, arguments is misspelled 
      because it's reserved)*/
      this.mongoDocs = [...this.ApplicationFormsData];
      this.mongoDocs = this.mongoDocs.sort((a, b) => a.id - b.id);
      this.mongoDocs.sort((doc1, doc2) => {
        let args = [doc1.optional, doc2.optional].map((arg) => {
          return isNaN(Number(arg)) ? false : arg;
        });
        return Number(args[0]) - Number(args[1]);
      });

      this.requiredPdfDocs = this.ApplicationFormsData.filter(
        (doc) =>
          doc.documentLocation === S3 &&
          doc.id !== PAYMENT_ID &&
          this.requiredFormList[doc.id]
      ).map((doc) => doc.id);
    }

    if (
      this.mongoDocs.length > 0 &&
      this.requiredPdfDocs.length > 0 &&
      this.caseData.id !== ""
    ) {
      let updateNavItem = this.findTopLevelNavItem("requiredFormsAccordion");
      if (updateNavItem?.children?.length === 0) {
        this.mongoDocs.forEach((doc, index) => {
          if (doc.documentLocation === MONGO && doc.id !== DECLARATION_ID) {
            let navItem: INavigationItemLink = {
              id: "requiredForm" + doc.id,
              label:
                this.translateService.currentLang === "en"
                  ? doc?.form?.nameEn
                  : doc?.form?.nameFr,
              type: NavigationItemType.link,
              header: true,
              href: window.location.pathname,
              anchor: "requiredForm" + index + "Anchor",
              indicator: {
                status: IndicatorStatus.neutral,
                icon: INCOMPLETE_ICON,
              },
              children: [],
            };
            updateNavItem?.children?.push(navItem);
          }
        });
        this.navigationService.setNavItem(
          updateNavItem as INavigationItemAccordion
        );
      }

      this.mongoDocs.forEach((doc) => {
        let mongoStatus = this.caseData?.documents?.some(
          (caseDoc: any) =>
            caseDoc.documentTypeId === doc.id && caseDoc.isComplete
        );
        updateNavItem = this.findTopLevelNavItem(
          "requiredFormsAccordion"
        )?.children.find((navItem) => navItem.id === "requiredForm" + doc.id);
        if (updateNavItem) {
          this.updateNavLink(updateNavItem, mongoStatus);
        }
      });

      let pdfComplete = this.requiredPdfDocs.every((id) => {
        return this.caseData?.documents?.some(
          (doc: any) => doc.documentTypeId === id
        );
      });
      updateNavItem = this.findTopLevelNavItem("requiredPdfForms");
      if (updateNavItem) this.updateNavLink(updateNavItem, pdfComplete);
    }

    if (this.supportingDocsData.length > 0 && this.caseData.id !== "") {
      let supportingDocs = this.supportingDocsData.every((requiredDoc) => {
        return this.caseData?.documents?.some(
          (doc: any) => doc.documentTypeId === requiredDoc.value
        );
      });
      let updateNavItem = this.findTopLevelNavItem("supportingDocuments");
      if (updateNavItem) this.updateNavLink(updateNavItem, supportingDocs);
    }

    if (this.caseData.id !== "") {
      let payment = this.caseData?.documents?.some(
        (doc: any) => doc.documentTypeId === PAYMENT_ID
      );
      let updateNavItem = this.findTopLevelNavItem("paymentDocuments");
      if (updateNavItem) this.updateNavLink(updateNavItem, payment);

      let refDocument = this.caseData?.documents?.some(
        (doc: any) => doc.documentTypeId === REFERRALDOC_ID
      );
      updateNavItem = this.findTopLevelNavItem("referralPartnerDocuments");
      if (updateNavItem) this.updateNavLink(updateNavItem, refDocument);

      if (this.caseData.lobId === LobIds.Sudan) {
        let anchorDocuments = this.caseData.lob.relatedDocumentTypes
          .filter(
            (document: RelatedDocumentType) =>
              document.isMemberDocument && document.isRequired
          )
          .every((requiredDoc: RelatedDocumentType) => {
            return this.caseData?.documents?.some(
              (doc: any) => doc.documentTypeId === requiredDoc.id
            );
          });
        updateNavItem = this.findTopLevelNavItem("anchorDocuments");
        if (updateNavItem) this.updateNavLink(updateNavItem, anchorDocuments);
      }

      let declaration = this.caseData?.documents?.some(
        (doc: any) =>
          (doc.documentTypeId === DECLARATION_ID && doc.isComplete) ||
          doc.documentTypeId === WET_SIGNATURE_ID
      );
      updateNavItem = this.findTopLevelNavItem("declarationDocuments");
      if (updateNavItem) this.updateNavLink(updateNavItem, declaration);
    }
  }

  ngOnInit(): void {
    if (
      !this.caseData?.lob?.relatedDocumentTypes.find(
        (doc: any) => doc.id === PAYMENT_ID
      )?.isRequired
    ) {
      this.navConfig?.navigationConfig?.splice(
        this.navConfig?.navigationConfig?.findIndex(
          (navItem: INavigationItem) => {
            return navItem.id === "paymentDocuments";
          }
        ),
        1
      );
    }
    const canSee = this.userService.can("group-members:write");
    const isMemberDoc = this.caseData?.lob?.relatedDocumentTypes.find(
      (doc: any) => doc.id === REFERRALDOC_ID
    )?.isMemberDocument
      ? true
      : false;

    if (!(canSee && isMemberDoc)) {
      this.navConfig?.navigationConfig?.splice(
        this.navConfig?.navigationConfig?.findIndex(
          (navItem: INavigationItem) => {
            return navItem.id === "referralPartnerDocuments";
          }
        ),
        1
      );
    }
    if (this.caseData.lobId !== LobIds.Sudan) {
      this.navConfig?.navigationConfig?.splice(
        this.navConfig?.navigationConfig?.findIndex(
          (navItem: INavigationItem) => {
            return navItem.id === "anchorDocuments";
          }
        ),
        1
      );
    }

    this.navigationService.setNavConfig(this.navConfig);
  }

  mobileShowNav() {
    this.showNav = !this.showNav;
    if (this.showNav) {
      this.swapHiddenState();
    } else {
      setTimeout(() => {
        this.swapHiddenState();
      }, 400);
    }
  }

  swapHiddenState() {
    let sideNav = document.getElementById("intake-side-nav-container");
    let animationBackdrop = document.getElementById(
      "intake-animation-backdrop"
    );
    if (sideNav)
      sideNav.setAttribute(
        "style",
        "display: " + (this.showNav ? "block" : "none")
      );
    if (animationBackdrop)
      animationBackdrop.setAttribute(
        "style",
        "display: " + (this.showNav ? "block" : "none")
      );
  }

  updateNavLink(updateNavItem: INavigationItem, state: boolean | undefined) {
    updateNavItem.indicator = {
      status: state ? IndicatorStatus.success : IndicatorStatus.neutral,
      icon: state ? COMPLETE_ICON : INCOMPLETE_ICON,
    };
    this.navigationService.setNavItem(updateNavItem as INavigationItemLink);
  }

  findTopLevelNavItem(id: string): INavigationItem | undefined {
    return this.navConfig?.navigationConfig?.find(
      (navItem: INavigationItem) => {
        return navItem.id === id;
      }
    );
  }
}
