<fieldset>
  <legend *ngIf="legend !== ''">{{ legend }}</legend>
  <lib-form-input
    identifier="familyName"
    [formControl]="form.controls.familyName"
    [label]="
      translations && translations.familyName
        ? (translations.familyName | translate)
        : ('PSR.MEMBER_FIELDS.FAMILY_NAME' | translate)
    "
    required="true"
  >
    <span
      *ngIf="
        form.get('familyName')?.touched &&
        form.get('familyName')?.hasError('required')
      "
    >
      {{ "PSR.MESSAGES.REQUIRED" | translate }}
    </span>
  </lib-form-input>
  <lib-form-input
    identifier="givenName"
    [formControl]="form.controls.givenName"
    [label]="
      translations && translations.givenName
        ? (translations.givenName | translate)
        : ('PSR.MEMBER_FIELDS.GIVEN_NAME' | translate)
    "
  >
    <span
      *ngIf="
        form.get('givenName')?.touched &&
        form.get('givenName')?.hasError('required')
      "
    >
      {{ "PSR.MESSAGES.REQUIRED" | translate }}
    </span>
  </lib-form-input>
  <lib-form-input
    identifier="emailAddress"
    [formControl]="form.controls.emailAddress"
    [label]="
      translations && translations.emailAddress
        ? (translations.emailAddress | translate)
        : ('PSR.MEMBER_FIELDS.EMAIL' | translate)
    "
    required="true"
  >
    <span
      *ngIf="
        form.get('emailAddress')?.touched &&
        form.get('emailAddress')?.hasError('required')
      "
    >
      {{ "PSR.MESSAGES.REQUIRED" | translate }}
    </span>
    <span
      *ngIf="
        form.get('emailAddress')?.touched &&
        form.get('emailAddress')?.hasError('email')
      "
    >
      {{ "PSR.MESSAGES.INVALID_EMAIL" | translate }}
    </span>
    <span
      *ngIf="
        form.get('emailAddress')?.touched &&
        form.get('emailAddress')?.hasError('isPaidRep')
      "
    >
      {{ "PSR.MESSAGES.IS_PAID_REP" | translate }}
    </span>
  </lib-form-input>
  <lib-form-input
    identifier="emailConfirm"
    [formControl]="form.controls.emailConfirm"
    [label]="
      translations && translations.emailConfirm
        ? (translations.emailConfirm | translate)
        : ('PSR.MEMBER_FIELDS.EMAIL_CONFIRM' | translate)
    "
    required="true"
    (paste)="preventPaste($event)"
    [autocomplete]="'prevent-email-autofill-with-a-bogus-value'"
  >
    <span
      *ngIf="
        form.get('emailConfirm')?.touched &&
        form.get('emailConfirm')?.hasError('required')
      "
    >
      {{ "PSR.MESSAGES.REQUIRED" | translate }}
    </span>
    <span
      *ngIf="
        form.get('emailConfirm')?.touched &&
        form.get('emailConfirm')?.hasError('email')
      "
    >
      {{ "PSR.MESSAGES.INVALID_EMAIL" | translate }}
    </span>
    <span
      *ngIf="
        form.get('emailConfirm')?.touched &&
        form.get('emailConfirm')?.hasError('matches')
      "
    >
      {{ "PSR.MESSAGES.EMAIL_MATCH" | translate }}
    </span>
    <span *ngIf="form.get('emailConfirm')?.hasError('pasted')">
      {{ "PSR.MESSAGES.PASTE_ERROR" | translate }}
    </span>
    <span
      *ngIf="
        form.get('emailConfirm')?.touched &&
        form.get('emailConfirm')?.hasError('isPaidRep')
      "
    >
      {{ "PSR.MESSAGES.IS_PAID_REP" | translate }}
    </span>
  </lib-form-input>
  <ng-content></ng-content>
</fieldset>
