import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
@Component({
  selector: "pra-imm5669-section-g",
  templateUrl: "./imm5669-section-g.component.html",
  styleUrls: ["./imm5669-section-g.component.scss"],
})
export class IMM5669SectionGComponent implements OnInit, OnDestroy {
  private currentForm: any;
  private storeSubsc$: Subscription;
  private formData: any;

  public maxFieldLengths: { [key: string]: number } = {
    country: 30,
    branchOfService: 63,
    from: 7,
    to: 7,
    rank: 14,
    reasonsEndService: 30,
    combatDetails: 679,
  };

  public militaryPositions: UntypedFormArray = new UntypedFormArray([]);
  public isReadOnly = !this.user.can("documents:write");

  private buttonClicked: boolean = false;

  constructor(
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    private store: Store<fromApp.State>,
    public translate: TranslateService,
    private validationService: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.storeSubsc$ = this.store
      .select("currentIMMDocument")
      .pipe(take(1))
      .subscribe((currentImmDoc) => {
        this.currentForm = _.cloneDeep(currentImmDoc.currentForm);
        this.formData = currentImmDoc?.currentForm?.form?.sectionG;
        if (
          this.formData?.militaryPositions &&
          this.formData?.militaryPositions.length > 0
        ) {
          this.setFormValues();
        } else {
          this.militaryPositions.push(this.createMilitaryServiceForm());
        }
      });
  }

  ngOnDestroy(): void {
    this.storeSubsc$.unsubscribe();
  }

  public get isFormValid(): boolean {
    return this.militaryPositions.valid;
  }

  public addMilitaryService(): void {
    if (
      this.militaryPositions.length <
      DOC_LIMITS.IMM5669.MILITARY_POSITIONS_LIMIT
    ) {
      this.buttonClicked = true;
      this.militaryPositions.push(this.createMilitaryServiceForm());
    } else {
      this.alertService.danger(this.alertMaxMilitaryPosition);
    }
  }

  public removeMilitaryService(index: number): void {
    this.buttonClicked = true;
    this.militaryPositions.removeAt(index);
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.militaryPositions.touched || this.buttonClicked;
  }
  public getFormData(): any {
    if (
      (this.militaryPositions.controls as FormControl[]).find(
        (fc) => fc.invalid && fc.touched
      )
    ) {
      return { sectionName: "sectionG", isInvalid: true };
    }
    const militaryPositions = this.militaryPositions.getRawValue();
    let result = _.cloneDeep(this.currentForm);
    const fromAPISectionG = result.form.sectionG;
    result = {
      sectionName: "sectionG",
      sectionInfo: { ...fromAPISectionG, militaryPositions },
    };
    return result;
  }
  /* PRIVATE ---------------------------------------------------- */

  private get alertMaxMilitaryPosition(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  private createMilitaryServiceForm(): FormGroup {
    return this.fb.group({
      country: [null, Validators.maxLength(this.maxFieldLengths.country)],
      branchOfService: [
        null,
        Validators.maxLength(this.maxFieldLengths.branchOfService),
      ],
      from: [null, Validators.maxLength(this.maxFieldLengths.from)],
      to: [null, Validators.maxLength(this.maxFieldLengths.to)],
      rank: [null, Validators.maxLength(this.maxFieldLengths.rank)],
      reasonsEndService: [
        null,
        Validators.maxLength(this.maxFieldLengths.reasonsEndService),
      ],
      combatDetails: [
        null,
        Validators.maxLength(this.maxFieldLengths.combatDetails),
      ],
    });
  }

  private setFormValues(): void {
    this.militaryPositions.clear();
    const savedData = this.formData?.militaryPositions;
    savedData?.forEach((instance: any) => {
      const fields: FormGroup = this.createMilitaryServiceForm();
      fields.setValue({
        country: instance.country,
        branchOfService: instance.branchOfService,
        from: instance.from,
        to: instance.to,
        rank: instance.rank,
        reasonsEndService: instance.reasonsEndService,
        combatDetails: instance.combatDetails,
      });
      this.militaryPositions.push(fields);
    });
  }
}
