<section>
  <form [formGroup]="applicationDetailsForm" autocomplete="off">
    <!-- Language preference-->
    <fieldset>
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.LEGEND"
            | translate
        }}
      </legend>
      <!-- <lib-layout-row gap="one-and-half"> -->
      <!-- Correspondence -->
      <lib-form-select
        [editable]="user.can('documents:write')"
        identifier="correspondence"
        data-cy="application-details-correspondence"
        [formControl]="applicationDetailsForm.controls.correspondence"
        [label]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.CORRESPONDENCE_LABEL'
            | translate
        "
        [options]="correspondenceOptions"
        required="true"
      >
        <span
          *ngIf="
            applicationDetailsForm.get('correspondence')?.touched &&
            applicationDetailsForm.get('correspondence')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <!-- Interview -->
      <lib-form-select
        [editable]="user.can('documents:write')"
        identifier="interview"
        data-cy="application-details-interview"
        [formControl]="applicationDetailsForm.controls.interview"
        [label]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.INTERVIEW_LABEL'
            | translate
        "
        [options]="interviewOptions"
        required="true"
      >
        <span
          *ngIf="
            applicationDetailsForm.get('interview')?.touched &&
            applicationDetailsForm.get('interview')?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <!-- Interpreter -->
      <lib-form-select
        [editable]="user.can('documents:write')"
        identifier="interpreterRequested"
        data-cy="application-details-interpreter-requested"
        [formControl]="applicationDetailsForm.controls.interpreterRequested"
        [label]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LANGUAGE_PREFERENCE.INTERPRETER_LABEL'
            | translate
        "
        [options]="interpreterRequestedOptions"
        required="true"
      >
        <span
          *ngIf="
            applicationDetailsForm.get('interpreterRequested')?.touched &&
            applicationDetailsForm
              .get('interpreterRequested')
              ?.hasError('required')
          "
        >
          {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-select>
      <!-- </lib-layout-row> -->
    </fieldset>

    <!-- Location in Canada-->
    <fieldset>
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.LEGEND" | translate
        }}
      </legend>
      <lib-layout-row gap="one-and-half">
        <!-- Province -->
        <lib-form-select
          identifier="province"
          data-cy="application-details-province"
          [formControl]="applicationDetailsForm.controls.province"
          [label]="
            'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.PROVINCE_LABEL'
              | translate
          "
          [options]="provinceOptions"
          [required]="isPlannedResidenceRequired"
          [editable]="user.can('documents:write')"
        >
          <span
            *ngIf="
              applicationDetailsForm.get('province')?.touched &&
              applicationDetailsForm.get('province')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
          <span
            *ngIf="
              applicationDetailsForm.get('province')?.touched &&
              applicationDetailsForm.get('province')?.hasError('isQuebec')
            "
          >
            {{ "INTAKE.FORM_ERRORS.NOT_QUEBEC" | translate }}
          </span>
          <span
            *ngIf="
              applicationDetailsForm.get('province')?.touched &&
              applicationDetailsForm.get('province')?.hasError('notQuebec')
            "
          >
            {{ "INTAKE.FORM_ERRORS.ONLY_QUEBEC" | translate }}
          </span>
        </lib-form-select>

        <!-- City/Town -->
        <lib-form-select
          [editable]="user.can('documents:write')"
          identifier="city"
          data-cy="application-details-city"
          [formControl]="applicationDetailsForm.controls.city"
          [label]="
            'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.LOCATION.CITY_LABEL'
              | translate
          "
          [options]="cityOptions"
          [required]="isPlannedResidenceRequired"
          [widthMd]="true"
        >
          <span
            *ngIf="
              applicationDetailsForm.get('city')?.touched &&
              applicationDetailsForm.get('city')?.hasError('required')
            "
          >
            {{ "INTAKE.FORM_ERRORS.REQUIRED" | translate }}
          </span>
        </lib-form-select>
      </lib-layout-row>
    </fieldset>

    <!-- CSQ Radio buttons -->
    <fieldset *ngIf="isQuebecSelected">
      <lib-form-radio
        [editable]="user.can('documents:write')"
        [legend]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.LEGEND' | translate
        "
        firstId="CSQYes"
        secondId="CSQNo"
        [firstValue]="true"
        [secondValue]="false"
        [firstLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_YES' | translate"
        [secondLabel]="'INTAKE.IMM0008.FORMS.FIELDS.RADIO_NO' | translate"
        nameVal="receivedCSQ"
        [formControl]="applicationDetailsForm.controls.receivedCSQ"
        [required]="isQuebecSelected"
      >
        <span
          class="error-radio"
          *ngIf="
            applicationDetailsForm.get('receivedCSQ')?.touched &&
            applicationDetailsForm.get('receivedCSQ')?.hasError('required')
          "
        >
          {{ "FORM_ERRORS.REQUIRED" | translate }}
        </span>
      </lib-form-radio>
    </fieldset>

    <!-- If CSQ Yes, CSQ Number -->
    <fieldset *ngIf="showCSQNumberField">
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.NUMBER_LEGEND"
            | translate
        }}
      </legend>
      <lib-form-input
        identifier="csqNumber"
        type="text"
        [formControl]="applicationDetailsForm.controls.csqNumber"
        [label]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.NUMBER_LABEL'
            | translate
        "
        [editable]="user.can('documents:write')"
      >
        <span
          *ngIf="
            applicationDetailsForm.get('csqNumber')?.touched &&
            applicationDetailsForm.get('csqNumber')?.hasError('maxlength')
          "
        >
          {{ "INTAKE.FORM_ERRORS.MAX_LENGTH" | translate : CSQNumberMaxChars }}
        </span>
        <span
          *ngIf="
            applicationDetailsForm.get('csqNumber')?.touched &&
            applicationDetailsForm.get('csqNumber')?.hasError('pipe')
          "
        >
          {{ "INTAKE.FORM_ERRORS.INVALID_CHARACTERS" | translate }}
        </span>
      </lib-form-input>
    </fieldset>
    <!-- If CSQ NO, CSQ Date-->
    <fieldset *ngIf="showCSQDateField">
      <legend>
        {{
          "INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.DATE_LEGEND" | translate
        }}
      </legend>
      <lib-form-datepicker
        identifier="dateAppliedForCSQ"
        [formControl]="applicationDetailsForm.controls.dateAppliedForCSQ"
        [label]="
          'INTAKE.IMM0008.FORMS.APPLICATION_DETAILS.CSQ.DATE_LABEL' | translate
        "
        [readOnly]="!user.can('documents:write')"
      >
        <span *ngIf="CSQDateInvalidError">
          {{ "INTAKE.FORM_ERRORS.DATE" | translate }}
        </span>
        <span *ngIf="CSQDatePastDateError">
          {{ "INTAKE.FORM_ERRORS.PAST_DATE" | translate }}
        </span>
      </lib-form-datepicker>
    </fieldset>
  </form>
</section>
