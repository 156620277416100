import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { WebFormTableComponent } from "../../web-form-table/web-form-table.component";
import { map, startWith } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { FormSelectOption, DOC_LIMITS } from "lib";
import {
  AppForm,
  Document,
  RawAppForm,
} from "@pr-applicant/app/core/models/document.model";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
@Component({
  selector: "pra-other-forms",
  templateUrl: "./other-forms.component.html",
  styleUrls: ["./other-forms.component.scss"],
})
export class OtherFormsComponent
  extends WebFormTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  public filteredOptions: Observable<AppForm[]>;
  public formControl = new FormControl("");
  public otherFormRows: any[] = [];
  public otherFormsList: AppForm[];
  public deleteModalId = "confirmDeleteOtherPdfForm";
  public deleteHeading: string = "";
  public deleteDescription: string = "";
  public loadingDocs: boolean = true;

  //delete modal functions
  public deleteFn: Function = () => undefined;
  public deleteFnParams: number[] = [];

  @ViewChild("otherFormsInput") tableInputElement!: ElementRef<any>;
  @ViewChild("otherFormContainer") scrollToRef: ElementRef<any>;

  @Input() isHiddenLob: boolean = false;
  @Input() otherForms: RawAppForm[];

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.user.can("documents:write")) {
      this.formControl.disable();
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.otherForms && changes.otherForms.currentValue) {
      this.otherFormsList = this.removeIfNoPDFs(
        this.otherForms.map((row) => this.getFormattedRowData(row))
      );
      this.getFormsList().then(() => {
        this.createTableRows();
        this.initAutocomplete();
      });
      this.loadingDocs = false;
    }
  }

  // controls Autocomplete select array
  public initAutocomplete(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(""),

      map((input) => {
        return this.otherFormsList.filter(
          (doc) =>
            this.match(doc.details, input!) ||
            this.match(doc.documentType, input!)
        );
      })
    );
  }

  private removeMemberDocAttachments(document: AppForm) {
    document.attachments = document.attachments.filter(
      (attachment) => !attachment.uploadedRawDocument.caseMemberId
    );
    return document;
  }

  // populate rows if matching document has been uploaded to case
  public createTableRows(): void {
    const tableRows: AppForm[] = [];
    this.otherFormsList.forEach((document: AppForm) => {
      document = this.removeMemberDocAttachments(document);
      if (this.checkForFormType(document.documentTypeId)) {
        tableRows.push({ ...document });
      }
    });
    this.otherFormRows = tableRows;
  }

  public addRow(newFormRow: any, event: any): void {
    if (
      event.isUserInput &&
      this.otherFormRows.filter(
        (formRow: any) => formRow.documentTypeId === newFormRow.documentTypeId
      ).length === 0
    ) {
      this.otherFormRows = [...this.otherFormRows, newFormRow];
    }
  }

  // deletes all files by type then removes table row from array
  public removeRow(rowIndex: number, documentType: number): void {
    this.deleteAllFilesByType(documentType).then(() => {
      this.alertService.success(this.alertDeleteSuccess);
      this.otherFormRows = this.otherFormRows.filter(
        (row: FormSelectOption, index) => index !== rowIndex
      );
      this.closeModal();
      this.shiftTempAttachments(rowIndex);
    });
  }

  //when removing a row, shift error messages up one table row
  public shiftTempAttachments(removeIndex: number) {
    let errorMap: { [key: number]: any[] } = {};
    Object.keys(this.tempAttachments).forEach((key) => {
      const _key = Number(key);
      if (Number(_key) <= removeIndex - 1) {
        errorMap[_key] = this.tempAttachments[_key];
      } else if (_key > removeIndex) {
        errorMap[Number(key) - 1] = this.tempAttachments[_key];
      }
    });
    this.tempAttachments = errorMap;
  }

  public async deleteAllFilesByType(documentType: number): Promise<any> {
    try {
      const documents = await this.getUploadedfilesById(documentType);
      if (documents.length > 0) {
        const promises: Promise<any>[] = [];
        documents.forEach((document: any) => {
          this.tempDeleteDocument = document;
          promises.push(this.deleteFile());
        });

        return Promise.all(promises);
      }
    } catch {
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  // sets tempDelete properties before calling deleteFile()
  public removeForm(document: Document) {
    this.tempDeleteDocument = document;
    this.deleteFile().then(() => {
      this.closeModal();
    });
  }
  public async deleteFile(): Promise<any> {
    if (this.tempDeleteDocument) {
      try {
        this.isLoading = true;
        await this.documentService.deleteFileByDocumentTypeAndId(
          this.caseId,
          Number(this.tempDeleteDocument.documentTypeId),
          this.tempDeleteDocument.id
        );
        const elementIndex = this.otherFormRows.findIndex(
          (doc) =>
            doc.documentTypeId ===
            Number(this.tempDeleteDocument?.documentTypeId)
        );
        this.otherFormRows[elementIndex].attachments = this.otherFormRows[
          elementIndex
        ].attachments.filter(
          (attahcment: any) =>
            attahcment.uploadedRawDocument.id !== this.tempDeleteDocument?.id
        );
        this.updateCaseInfo(this.caseId);
        this.isLoading = false;
      } catch (error) {
        this.alertService.danger(this.alertTechnicalError);
        this.isLoading = false;
      }
    }
  }

  public async updateIndividualRow(id: number) {
    let isWebForm = false;
    // Check DB for existing form using the form id
    this.caseDocuments = (
      await this.documentService.getDocumentByCaseId(this.caseId)
    ).filter((doc: any) => !doc.caseMemberId);
    // use form ID to match the uploaded form to the correct pdf form so the file appears in the correct row of the table
    const elementIndex = this.otherFormRows.findIndex(
      (doc) => doc.documentTypeId === id
    );
    if (elementIndex !== undefined) {
      const newDocuments = this.getUploadedfilesById(id);
      // If you update a form row by adding or deleteing, check that
      if (
        newDocuments &&
        newDocuments.length === 0 &&
        this.documentTypeIdsToDeleteIfNoPDFs.includes(id)
      ) {
        this.otherFormRows.splice(elementIndex, 1);
        return;
      }
      const attachments: Array<any> = [];

      //  there may be more than one document - add attachments array
      //  with status and attachment properties to update the attachements and their status
      // When the form changes
      newDocuments.forEach((document: Document) => {
        attachments.push({
          uploadedRawDocument: document,
          status: this.checkStatus(document),
        });
      });
      // update the button from upload to start (if it is a web form)
      // if there are no uploaded documents, its type is determined by the default documentLocation
      this.appFormsList = await this.caseService.getAppForms();
      const refToForm = this.appFormsList.findIndex((doc) => doc.id === id);
      if (attachments.length === 0) {
        isWebForm = this.appFormsList[refToForm].documentLocation === "mongo";
      }
      this.otherFormRows[elementIndex] = await {
        ...this.otherFormRows[elementIndex],
        attachments,
        isWebForm,
      };
    }
  }
  public checkForFormType(docTypeId: number | null): boolean | void {
    if (docTypeId) {
      return (
        this.caseDocuments.length > 0 &&
        this.caseDocuments
          .filter((doc: any) => !doc.caseMemberId)
          .some((file: any) => file.documentTypeId === docTypeId)
      );
    }
  }
  public removeIfNoPDFs(forms: AppForm[]): AppForm[] {
    const PDFForms = forms.filter((row) => {
      const isFormToRemove =
        this.documentTypeIdsToDeleteIfNoPDFs.includes(row.documentTypeId) &&
        row.attachments?.length === 0;
      return !isFormToRemove;
    });
    return PDFForms;
  }

  // delete confirmation modal can only render one instance
  // made it dynamic to accomodate delete/deleteAllFile functions
  public openDynamicDeleteModal(
    subHeading: string,
    description: string,
    deleteFn: Function,
    ...params: number[]
  ): void {
    this.modalService.open(this.deleteModalId);
    this.deleteFn = deleteFn;
    this.deleteFnParams = params;
    this.deleteHeading = subHeading;
    this.deleteDescription = description;
  }
  private match(string1: string, string2: string): boolean {
    return string1.toLowerCase().includes(string2.toLowerCase());
  }
  public resetInput(): void {
    this.formControl.setValue("");
    this.tableInputElement.nativeElement.blur();
  }

  // method to open autocomplete on first and subsequent input clicks
  // scrolls input to top if view height cannot fit autocomplete dropdown
  // useful for mobile when scroll closes select window
  public handleClick(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    setTimeout(() => {
      if (
        window.visualViewport?.height &&
        window.visualViewport?.height < 480
      ) {
        this.scrollToRef.nativeElement.scrollIntoView();
      }
    }, 350);
  }
}
