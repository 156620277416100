import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "lib-collapse",
  templateUrl: "./collapse.component.html",
  styleUrls: ["./collapse.component.scss"],
})
export class CollapseComponent implements OnInit {
  @Input() buttonTextMore: string;
  @Input() buttonTextHide: string;
  @Input() buttonCategory: string = "secondary";
  @Input() buttonClass: string = "btn btn-secondary";
  @Input() isDisabled: boolean = false;

  public active: boolean = true;

  public get disabled() {
    return this.isDisabled;
  }

  constructor() {}

  ngOnInit(): void {}

  toggleCollapse(e: Event): void {
    e.preventDefault();
    if (!this.disabled) {
      this.active = !this.active;
    }
  }
}
