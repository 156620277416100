<section class="collapse">
  <div class="collapse__actions">
    <button
      class="collapse__button"
      type="button"
      [attr.category]="buttonCategory"
      [ngClass]="buttonClass"
      [disabled]="disabled"
      (click)="toggleCollapse($event)"
    >
      <span *ngIf="active">{{ buttonTextMore }}</span>
      <span *ngIf="!active">{{ buttonTextHide }}</span>
    </button>
    <div class="collapse__actions-secondary">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
  <div class="collapse__area" *ngIf="!active">
    <ng-content select="[collapse]"></ng-content>
  </div>
</section>
