<div class="spinner" *ngIf="loading">
  <lib-spinner [showSpinner]="loading" [fullPage]="false"></lib-spinner>
</div>

<ng-container *ngIf="!loading">
  <div class="login">
    <div class="login__form">
      <form [formGroup]="loginForm" autocomplete="off" (ngSubmit)="onSubmit()">
        <fieldset>
          <legend>{{ "LOGIN.FORM_TITLE" | translate }}</legend>
          <lib-form-input
            identifier="username"
            type="email"
            [formControl]="loginForm.controls.username"
            [label]="'LOGIN.USERNAME_INPUT_LABEL' | translate"
            required="true"
            [hideRequired]="true"
          >
            <span
              *ngIf="
                loginForm.controls.username.touched &&
                loginForm.controls.username.errors?.email
              "
            >
              {{ "LOGIN.ERRORS.INVALID_EMAIL" | translate }}
            </span>
            <span
              *ngIf="
                loginForm.controls.username.touched &&
                loginForm.controls.username.errors?.required
              "
            >
              {{ "LOGIN.ERRORS.REQUIRED_EMAIL" | translate }}
            </span>
          </lib-form-input>

          <lib-form-input
            identifier="password"
            type="password"
            [formControl]="loginForm.controls.password"
            [label]="'LOGIN.PASSWORD_INPUT_LABEL' | translate"
            required="true"
            [hideRequired]="true"
          >
            <span
              *ngIf="
                loginForm.controls.password.touched &&
                loginForm.controls.password.errors?.required
              "
            >
              {{ "LOGIN.ERRORS.REQUIRED_PASSWORD" | translate }}
            </span>
          </lib-form-input>
          <div>
            <a
              [routerLink]="forgotPasswordRouteName"
              class="login__form__forgot-password"
              aria-describedby="forgot-password-help-text"
              >{{ "LOGIN.FORGOT_PASSWORD" | translate }}</a
            >
            <div
              class="forgot-password-help-text"
              id="forgot-password-help-text"
            >
              {{ "LOGIN.FORGOT_PASSWORD_HELP_TEXT" | translate }}
            </div>
          </div>
          <button
            [disabled]="autofill === true ? false : !loginForm.valid"
            category="primary"
            type="submit"
          >
            {{ "LOGIN.SUBMIT" | translate }}
          </button>
        </fieldset>
      </form>
    </div>
  </div>
  <div
    *ngIf="!isProduction"
    class="quickCreds"
    [ngClass]="{ quickCreds__closed: toggleLabel === 'SHOW' }"
  >
    <div class="quickCreds__buttons">
      <div class="quickCreds__prod">DISABLED IN PRODUCTION</div>
      <div class="quickCreds__hide" (click)="toggleCreds()">
        {{ toggleLabel }}
      </div>
    </div>
    <div class="quickCreds__env-container">
      <div class="quickCreds__env">{{ env }} users</div>
      <div class="quickCreds__cred-selector">Credential Selector</div>
    </div>
    <div class="quickCreds__container">
      <div
        *ngFor="let user of quickCreds"
        (click)="useQuickCreds(user)"
        class="quickCreds__cred"
      >
        {{ user.name }}
      </div>
    </div>
  </div>
</ng-container>
