import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, DOC_LIMITS, ValidationService } from "lib";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { CurrentIMMDocumentState } from "../../../../shared/currentIMMDocument/store/currentIMMDocument.reducer";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-imm5669-section-e",
  templateUrl: "./imm5669-section-e.component.html",
  styleUrls: ["./imm5669-section-e.component.scss"],
})
export class IMM5669SectionEComponent implements OnInit, OnDestroy {
  public formMaxLength = {
    from: 7,
    to: 7,
    nameOfOrganization: 26,
    typeOfOrganization: 17,
    activities: 20,
    cityAndCountry: 17,
  };

  public sectionEdata: any;
  public memberships: UntypedFormArray = new UntypedFormArray([]);
  public subscription$: Subscription;
  public isReadOnly = !this.user.can("documents:write");

  private buttonClicked: boolean = false;

  constructor(
    public fb: UntypedFormBuilder,
    public alertService: AlertService,
    public translate: TranslateService,
    private store: Store<fromApp.State>,
    private validationService: ValidationService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    this.subscription$ = this.store
      .select("currentIMMDocument")
      .pipe(distinctUntilChanged())
      .subscribe((formdata: CurrentIMMDocumentState) => {
        this.sectionEdata = formdata.currentForm?.form?.sectionE;
        if (this.sectionEdata?.memberships?.length > 0) {
          this.fillFormWithSavedValues();
        } else {
          this.memberships.push(this.createForms());
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  public get isFormValid(): boolean {
    return this.memberships?.valid;
  }

  public get alertMaxMembershipLimit(): string {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }

  // public functions
  public addMembership(): void {
    // check limit
    if (this.memberships.length < DOC_LIMITS.IMM5669.MEMBERSHIP_LIMIT) {
      this.buttonClicked = true;
      this.memberships.push(this.createForms());
    } else {
      // alert
      this.alertService.danger(this.alertMaxMembershipLimit);
    }
  }

  public removeMembership(i: number): void {
    this.buttonClicked = true;
    this.memberships.removeAt(i);
  }

  public getFormData(): any {
    if (
      (this.memberships.controls as FormControl[]).find(
        (fc) => fc.invalid && fc.touched
      )
    ) {
      return { sectionName: "sectionE", isInvalid: true };
    }
    const formValue = this.memberships.getRawValue();
    return {
      sectionName: "sectionE",
      sectionInfo: { ...this.sectionEdata, memberships: formValue },
    };
  }

  // this method is used by container to identify if user can navigate away
  public isFormDataUnSaved(): boolean {
    return this.memberships.touched || this.buttonClicked;
  }

  // private functions
  private createForms(): FormGroup {
    return this.fb.group({
      from: new FormControl(null, {
        validators: [Validators.maxLength(this.formMaxLength.from)],
      }),
      to: new FormControl(null, {
        validators: [Validators.maxLength(this.formMaxLength.to)],
      }),
      nameOfOrganization: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.nameOfOrganization),
        ],
      }),
      typeOfOrganization: new FormControl(null, {
        validators: [
          Validators.maxLength(this.formMaxLength.typeOfOrganization),
        ],
      }),
      activities: new FormControl(null, {
        validators: [Validators.maxLength(this.formMaxLength.activities)],
      }),
      cityAndCountry: new FormControl(null, {
        validators: [Validators.maxLength(this.formMaxLength.cityAndCountry)],
      }),
    });
  }

  private fillFormWithSavedValues(): void {
    this.memberships?.clear();
    const savedmembershipForms = this.sectionEdata?.memberships;
    savedmembershipForms?.forEach((savedmembershipForm: any) => {
      const membership: FormGroup = this.createForms();
      membership?.setValue({
        to: savedmembershipForm.to,
        from: savedmembershipForm.from,
        nameOfOrganization: savedmembershipForm.nameOfOrganization,
        typeOfOrganization: savedmembershipForm.typeOfOrganization,
        activities: savedmembershipForm.activities,
        cityAndCountry: savedmembershipForm.cityAndCountry,
      });
      this.memberships.push(membership);
    });
  }
}
