import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, DOC_LIMITS, ModalService, ValidationService } from "lib";
import { WebFormService } from "../../../../shared/services/web-form/web-form.service";
import { RouteLocalizerService } from "../../../../routing/route-localizer.service";
import { FamilyTableRow } from "./family-table.model";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";

@Component({
  selector: "pra-family-table",
  templateUrl: "./family-table.component.html",
  styleUrls: ["./family-table.component.scss"],
})
export class FamilyTableComponent implements OnInit {
  @Input() familyMembers: FamilyTableRow[] = [];

  public lang: string;
  public isLoading = true;
  public deleteModalId = "confirmDeleteFamilyMember";
  public addingNewMember = false;
  private formIdToDelete: string | null;

  constructor(
    public alertService: AlertService,
    public translate: TranslateService,
    private webFormSvc: WebFormService,
    private routeLocalizer: RouteLocalizerService,
    private modalService: ModalService,
    private webFormService: WebFormService,
    private validationService: ValidationService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.lang = this.routeLocalizer.getCurrentRouteLang();
  }

  public openDeleteModal(formId: string): void {
    this.modalService.open(this.deleteModalId);
    this.formIdToDelete = formId;
  }

  public editFamilyMember(formId: string): void {
    this.webFormSvc.immDocumentSignal$.next({
      message: "EDIT_FAMILY_MEMBER",
      formId,
    });
  }

  public get isReadOnly(): boolean {
    return !this.user.can("documents:write");
  }

  /* PRIVATE ------------------------------------------------------------------------------------ */

  private get familyMembersLimitErrorMessage(): string {
    return this.translate.instant("INTAKE.ALERTS.MAX_FILE_LIMIT");
  }
}
