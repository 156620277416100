import { ActionReducerMap } from "@ngrx/store";
import { PrcPrtdState } from "../prcPrtdState";
import { prcPrtdApplicationsReducer } from "./prcPrtdApplicationsReducers";
import { selectedPRCReducer } from "./selectedPRCReducer";
import { selectedPRTDReducer } from "./selectedPRTDReducer";

export const allPrcPrtdReducersReducers: ActionReducerMap<PrcPrtdState> = {
  prcPrtdApplications: prcPrtdApplicationsReducer,
  selectedPRC: selectedPRCReducer,
  selectedPRTD: selectedPRTDReducer,
};
