<lib-card>
  <div class="member-card__card">
    <div class="member-card__title">
      <div class="member-card__pill" *ngIf="showMoreDetails">
        <lib-status-pill
          [type]="statusMapToPillType[status]"
          [text]="statusMapToText[status] | translate"
        ></lib-status-pill>
      </div>
      <h5 class="emphasis">
        {{
          member.givenName
            ? member.givenName + " " + member.familyName
            : member.familyName
        }}
      </h5>
      <div class="member-card__card-row">
        <div>
          <ng-container
            *ngIf="
              member.roleId === RoleIds.PRIMARY_SPONSOR ||
              member.roleId === RoleIds.PRIMARY_CONTACT
            "
          >
            {{ "PSR.ROLES.PRIMARY_SPONSOR" | translate }}
          </ng-container>
          <ng-container *ngIf="member.roleId === RoleIds.SPONSOR_MEMBER">
            {{ "PSR.ROLES.SPONSOR_MEMBER" | translate }}
          </ng-container>
        </div>
        <div><i class="fa-solid fa-circle"></i></div>
        <div>{{ member.emailAddress }}</div>
      </div>
    </div>
    <div class="member-card__card-footer" *ngIf="showMoreDetails">
      <lib-collapse
        [buttonTextMore]="
          'PSR.MANAGE_GROUP_MEMBERS_PAGE.MORE_DETAILS' | translate
        "
        [buttonTextHide]="
          'PSR.MANAGE_GROUP_MEMBERS_PAGE.HIDE_DETAILS' | translate
        "
      >
        <ng-container
          *ngIf="member.roleId === RoleIds.SPONSOR_MEMBER && showActions"
          actions
        >
          <div class="member-card__card-actions">
            <pra-resend-password-button
              *ngIf="member.canResendPassword"
              [caseId]="caseId"
              [caseMemberId]="member.caseMemberId"
            ></pra-resend-password-button>
            <button
              *ngIf="canDelete"
              class="member-card__card-delete"
              category="plain"
              color="critical"
              (click)="openModal()"
              type="button"
            >
              <i class="fa-regular fa-trash-can"></i>
              {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.REMOVE_MEMBER" | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container collapse>
          <div class="member-card__table-container">
            <pra-documents-upload-table
              [requiredDocumentOptions]="memberDocs"
              [caseMemberId]="member.caseMemberId"
              [(status)]="status"
            ></pra-documents-upload-table>
          </div>
        </ng-container>
      </lib-collapse>
    </div>
  </div>

  <!-- MODALS -->
  <lib-modal
    [id]="deleteModalId"
    [title]="'PSR.MANAGE_GROUP_MEMBERS_PAGE.MODAL.TITLE' | translate"
    [deleteModal]="true"
  >
    <ng-container modalBody>
      <p>
        {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.MODAL.DESCRIPTION" | translate }}
      </p>
    </ng-container>
    <ng-container modalButtons>
      <button
        category="primary"
        color="critical"
        (click)="deleteMember()"
        [disabled]="isProcessing"
      >
        <lib-spinner
          [showSpinner]="isProcessing"
          [fullPage]="false"
          [isButton]="true"
        ></lib-spinner>
        <div *ngIf="!isProcessing">
          {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.MODAL.SEND" | translate }}
        </div>
      </button>
      <button category="secondary" (click)="closeModal()">
        {{ "PSR.MANAGE_GROUP_MEMBERS_PAGE.MODAL.CANCEL" | translate }}
      </button>
    </ng-container>
  </lib-modal>
</lib-card>
