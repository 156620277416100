import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RouteLocalizerService } from "../../routing/route-localizer.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { ElementRef, ViewChild } from "@angular/core";
import routePaths from "../../routing/route-paths";
import { UserService } from "@pr-applicant/app/shared/services/user/user.service";
import { Subscription } from "rxjs";
import { userSelector } from "../auth-module/store/authSelectors";
import { User } from "../auth-module/store/auth.reducer";
import { CasesCountService } from "@pr-applicant/app/shared/services/case-count.service";
import { AlertService, casePhases } from "lib";
import { Auth } from "aws-amplify";
import { CaseService } from "@pr-applicant/app/shared/case-module/case.service";
import { Case } from "@pr-applicant/app/shared/case-module/case.model";
import { first } from "rxjs/operators";
import { GarService } from "../../shared/services/gar/gar.service";
import {
  IPsrDetails,
  PsrService,
} from "@pr-applicant/app/shared/services/psr/psr.service";
@Component({
  selector: "pra-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavBarComponent implements OnInit, OnDestroy {
  public lang: string;
  public isClient: boolean;
  public showPhase3 = false;
  public showMyProfileLink = false;
  public showMyApplicationLink = false;
  public showManageGroupMembersLink = false;
  public showViewGroupMembersLink = false;
  public showBell: boolean = false;
  public showBellMember: boolean = false;
  public currentCaseId: string | any;
  public currentCaseLobId: number | null;
  public currentCasePhaseId: number | null;
  public currentCasePsr: IPsrDetails | undefined;
  public currentCaseStatus: string | undefined;
  public previousURL: string;
  public currentUrl: string;
  public isLoading: boolean = false;
  public allPreviousUrls: string[] = [];
  public myApplicationUrl: string;
  private subs: Subscription[] = [];

  private principalApplicationRegex = /\d+($|#\w*|\?\w*)/;
  private manageGroupMemeberRegex = /\d+\/\w/; //This regex is for PSR routes where we have /id/<some route path>

  @ViewChild("intakeMenuDropdown") intakeMenuDropdown: ElementRef;
  @ViewChild("intakeMenuDropdownButton") intakeMenuDropdownButton: ElementRef;

  @ViewChild("renewalMenuDropdown") renewalMenuDropdown: ElementRef;
  @ViewChild("renewalMenuDropdownButton") renewalMenuDropdownButton: ElementRef;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private caseService: CaseService,
    private casesCountService: CasesCountService,
    private alertService: AlertService,
    private psrService: PsrService,
    private garService: GarService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = this.routeLocalizer.getCurrentRouteLang(); //This property is not initialized while the view is rendered if we initialize after the await

    this.subs.push(
      this.userService.currentCaseObs$.subscribe((data) => {
        this.currentCaseId = data.id;
        this.currentCaseLobId = data.lobId;
        this.currentCasePhaseId = data.phaseId;
        this.currentCasePsr = data.psrDetails;
        this.currentCaseStatus = data.status?.id;
        this.showHideNotificationBell();
        this.showHideNotificationBellMember();
      })
    );

    this.showHideMyProfileLink();
    this.showHideMyApplicationLink();
    this.showHideManageGroupMembersLink();
    this.showHideViewGroupMembersLink();
    this.setMyApplicationURL();

    this.subs.push(
      this.router.events.subscribe((routerEvent: RouterEvent) => {
        this.showHideMyProfileLink();
        this.showHideMyApplicationLink();
        this.showHideManageGroupMembersLink();
        this.showHideViewGroupMembersLink();
        this.setMyApplicationURL();
      })
    );
    this.isClient = this.userService.isClientAccount();

    setTimeout(() => {
      this.isLoading = true;
    }, 500);
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public setMyApplicationURL() {
    switch (this.currentCasePhaseId) {
      case casePhases.intake.id:
        return (
          this.routeLocalizer.getNestedPath(
            this.lang,
            ["INTAKE", "INTAKE_CASE_DETAILS"],
            ""
          ) + this.currentCaseId
        );
      case casePhases.confirmation.id:
        return (
          this.routeLocalizer.getNestedPath(
            this.lang,
            ["CONFIRMATION_CASES", "CONFIRMATION_CASE_DETAILS"],
            ""
          ) + this.currentCaseId
        );
      case casePhases.renewals.id:
        if (this.currentCaseLobId === 29) {
          return (
            this.routeLocalizer.getNestedPath(
              this.lang,
              ["RENEWAL", "RENEWAL_PERMANENT_RESIDENT_CARD"],
              ""
            ) + this.currentCaseId
          );
        } else {
          return (
            this.routeLocalizer.getNestedPath(
              this.lang,
              ["RENEWAL", "RENEWAL_TRAVEL_DOCUMENTS"],
              ""
            ) + this.currentCaseId
          );
        }
    }
  }

  public get isPSR(): boolean {
    return this.currentCasePsr ? this.currentCasePsr.isPsr : false;
  }

  public get isGAR() {
    return this.garService.isGAR(this.currentCaseLobId);
  }

  public toggleDropdown(dropdownName: string) {
    if (dropdownName === "INTAKE") {
      this.intakeMenuDropdown.nativeElement.classList.toggle("show");
      const expanded = this.intakeMenuDropdown.nativeElement.classList.contains(
        "show"
      )
        ? true
        : false;
      this.intakeMenuDropdownButton.nativeElement.setAttribute(
        "aria-expanded",
        expanded.toString()
      );
    } else if (dropdownName === "RENEWAL") {
      this.renewalMenuDropdown.nativeElement.classList.toggle("show");
      const expanded =
        this.renewalMenuDropdown.nativeElement.classList.contains("show")
          ? true
          : false;
      this.renewalMenuDropdownButton.nativeElement.setAttribute(
        "aria-expanded",
        expanded.toString()
      );
    }
  }

  public hideDropDownOnOutsideClick(dropdownName: string) {
    if (
      dropdownName === "INTAKE" &&
      this.intakeMenuDropdown.nativeElement.classList.contains("show")
    ) {
      this.intakeMenuDropdown.nativeElement.classList.toggle("show");
      const expanded = this.intakeMenuDropdown.nativeElement.classList.contains(
        "show"
      )
        ? true
        : false;
      this.intakeMenuDropdownButton.nativeElement.setAttribute(
        "aria-expanded",
        expanded.toString()
      );
    } else if (
      dropdownName === "RENEWAL" &&
      this.renewalMenuDropdown.nativeElement.classList.contains("show")
    ) {
      this.renewalMenuDropdown.nativeElement.classList.toggle("show");
      const expanded =
        this.renewalMenuDropdown.nativeElement.classList.contains("show")
          ? true
          : false;
      this.renewalMenuDropdownButton.nativeElement.setAttribute(
        "aria-expanded",
        expanded.toString()
      );
    }
  }
  public showHideMyProfileLink(): void {
    let url = this.router.url;
    const praURL =
      this.lang === "en"
        ? routePaths.en.INTAKE_CASE_DETAILS
        : routePaths.fr.INTAKE_CASE_DETAILS;
    const profileURL =
      this.lang === "en" ? routePaths.en.PROFILE : routePaths.fr.PROFILE;
    const confirmationURL =
      this.lang === "en"
        ? routePaths.en.CONFIRMATION_CASES
        : routePaths.fr.CONFIRMATION_CASES;

    if (
      (url.includes(praURL) || url.includes(profileURL)) &&
      !url.includes(confirmationURL)
    ) {
      this.showMyProfileLink = true;
    } else {
      this.showMyProfileLink = false;
    }
  }

  public showHideMyApplicationLink(): void {
    const currentUrl = this.router.url;
    if (
      this.principalApplicationRegex.test(currentUrl) ||
      this.manageGroupMemeberRegex.test(currentUrl)
    ) {
      this.showMyApplicationLink = true;
    } else {
      this.showMyApplicationLink = false;
    }
  }

  public showHideManageGroupMembersLink(): void {
    const currentUrl = this.router.url;
    if (
      (this.isPSR || this.isGAR) &&
      this.userService.can("group-members:write") &&
      (this.principalApplicationRegex.test(currentUrl) ||
        this.manageGroupMemeberRegex.test(currentUrl))
    ) {
      this.showManageGroupMembersLink = true;
    } else {
      this.showManageGroupMembersLink = false;
    }
  }

  public showHideNotificationBell(): void {
    if (this.userService.can("case:submit") && this.currentCasePsr) {
      if (this.currentCasePsr.isG5) {
        const check =
          this.currentCasePsr.has5members &&
          this.currentCasePsr.hasMemberDocsComplete;
        this.showBell = !check;
      } else {
        this.showBell = this.currentCasePsr.hasMemberDocsComplete
          ? false
          : true;
      }
    } else {
      this.showBell = false;
    }
  }

  public showHideNotificationBellMember(): void {
    if (this.userService.can("member:write") && this.currentCasePsr) {
      this.showBellMember = !this.currentCasePsr.hasCurrentMemberDocsComplete;
    } else {
      this.showBellMember = false;
    }
  }

  public showHideViewGroupMembersLink(): void {
    const currentUrl = this.router.url;
    if (
      this.isPSR &&
      this.userService.can("member:write") &&
      (this.principalApplicationRegex.test(currentUrl) ||
        this.manageGroupMemeberRegex.test(currentUrl))
    ) {
      this.showViewGroupMembersLink = true;
    } else {
      this.showViewGroupMembersLink = false;
    }
  }

  public get isReturned(): boolean {
    return Number(this.currentCaseStatus) === 8 ? true : false;
  }

  public applicationLimitValidator() {
    this.toggleDropdown("INTAKE");
    if (this.casesCountService.reachLimit()) {
      this.alertService.danger(this.addNewApplicationError);
    }
  }

  private get addNewApplicationError() {
    return this.translate.instant("INTAKE.FORM_ERRORS.MAX_VALUE_EXCEEDED");
  }
}
