import { createAction, props } from "@ngrx/store";

export enum RenewalApplicationsActionTypes {
  StartLoadRenewalsApplicationsList = "[Start Load PRC_PRTD Applications] Start Load PRC PRTD Applications",
  LoadRenewalsApplicationsList = "[Load PRC_PRTD Applications] Load PRC PRTD Applications",
  ErrorLoadRenewalsApplicationsList = "[Error Load PRC_PRTD Applications] Error Load PRC PRTD Applications",
}

export const StartLoadRenewalsApplicationsList = createAction(
  RenewalApplicationsActionTypes.StartLoadRenewalsApplicationsList
);

export const LoadRenewalsApplicationsList = createAction(
  RenewalApplicationsActionTypes.LoadRenewalsApplicationsList,
  props<{ list: any[] }>()
);

export const ErrorLoadRenewalsApplicationsList = createAction(
  RenewalApplicationsActionTypes.ErrorLoadRenewalsApplicationsList,
  props<{ error: any }>()
);
