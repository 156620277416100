// References
// https://ddcmtl.atlassian.net/wiki/spaces/IDS/pages/2342223877/Webform+Service
// https://ddcmtl.atlassian.net/wiki/spaces/IDS/pages/2359951628/API+Docs
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AWSService } from "@pr-applicant/app/core/auth-module/services/AWS.service";
import { DocumentService } from "../document/document.service";
import { IFormData, IFormInfo } from "./models";
export interface WebFormSignal {
  message:
    | "ADD_NEW_FAMILY_MEMBER"
    | "EDIT_FAMILY_MEMBER"
    | "DELETE_FAMILY_MEMBER";
  formId?: string;
}

@Injectable({
  providedIn: "root",
})
export class WebFormService {
  public immDocumentSignal$: Subject<WebFormSignal> = new Subject();

  private baseUrl = `/${environment.API_VERSION}/cases/`;
  private apiName = environment.API_NAME;

  constructor(
    private documentService: DocumentService,
    private awsService: AWSService
  ) {}

  // Creates the document reference - This refers to the entire document with it's subforms if they exists
  // If there are no documents of documentTypeId XX, you need to create one when loading the page.
  // Use this the first time you load the forms table.
  public async createDocument(formInfo: IFormInfo): Promise<any> {
    const path = this.baseUrl + formInfo.caseId + "/documents";
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: { documentTypeId: formInfo.documentTypeId },
    };
    try {
      const response = await this.awsService.api.post(this.apiName, path, init);
      // We need to update selected Case Documents
      this.documentService.getDocumentByCaseId(formInfo.caseId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Updates the document reference - This refers to the entire document with it's subforms if they exists
  // If there are no documents of documentTypeId XX, you need to create one when loading the page.
  // Use this the first time you load the forms table.
  public async updateDocument(
    formInfo: IFormInfo,
    formData: any
  ): Promise<any> {
    const path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: formData,
    };
    try {
      const response = await this.awsService.api.put(this.apiName, path, init);
      // We need to update selected Case Documents
      this.documentService.getDocumentByCaseId(formInfo.caseId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Gets the document reference - This refers to the entire document with it's subforms if they exists
  // If there are documents of documentTypeId XX, you should just fetch it when loading the page.
  // Use this the nth time you load the forms table
  public async getDocumentData(formInfo: IFormInfo): Promise<any> {
    const path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      console.log("response:", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Delete the document reference - This refers to the entire document with it's subforms if they exists
  // Not sure if this is scoped in or not, but applicants should have
  // the ability to delete documents if they don’t need them.
  public async deleteDocument(formData: IFormInfo): Promise<any> {
    const path =
      this.baseUrl + formData.caseId + "/documents/" + formData.documentId;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
    };
    try {
      const response = await this.awsService.api.del(this.apiName, path, init);
      this.documentService.getDocumentByCaseId(formData.caseId);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Creates a new form. This form is under a documentId
  // Should be used by the “Add new family member” to create a new form entry in the forms array.
  public async createFormData(formInfo: IFormInfo): Promise<any> {
    const path =
      this.baseUrl +
      formInfo.caseId +
      "/documents/" +
      formInfo.documentId +
      "/forms";
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: {},
    };
    try {
      const response = await this.awsService.api.post(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Gets an existing form.
  // If it's a form under a formId.
  // Should be used by the "Edit family member” to get the form entry
  public async getFormData(formInfo: IFormInfo): Promise<any> {
    let path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    path += "/forms/" + formInfo.formId;

    const init = {
      headers: { Authorization: await this.awsService.getToken() },
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      console.log("response", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Deletes a given form. Tied to the delete button in the table of forms.
  // Upon successful file deletion, the endpoint will return the updated parent document so you don’t have to re-get
  // This is the new pattern, and it’s glorious (thanks Matt Watson ).
  // Should be used by the "Delete family member” to delete the form entry
  public async deleteFormData(formInfo: IFormInfo): Promise<any> {
    let path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    path += "/forms/" + formInfo.formId;

    const init = {
      headers: { Authorization: await this.awsService.getToken() },
    };
    try {
      const response = await this.awsService.api.del(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Fetches a specific form section.
  // Can be used to load the data per each form section (see “pages” in frontend).
  public async getFormSection(formInfo: IFormInfo): Promise<any> {
    let path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    // If we need to make an API call with formId
    if (formInfo.formId) {
      path += "/forms/" + formInfo.formId;
    }
    path += "?section=" + formInfo.sectionId;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
    };
    try {
      const response = await this.awsService.api.get(this.apiName, path, init);
      console.log("FormsectionData:", response);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Updates a specific form section.
  // an be used to load the data per each form section (see “pages” in frontend).
  // Updates _ids in place. If an object doesn’t have an _id, it will be assigned by mongo.
  public async updateFormSection(
    formInfo: IFormInfo,
    formData: IFormData
  ): Promise<any> {
    let path =
      this.baseUrl + formInfo.caseId + "/documents/" + formInfo.documentId;
    // If we need to make an API call with formId
    if (formInfo.formId) {
      path += "/forms/" + formInfo.formId;
    }
    path += "?section=" + formInfo.sectionId;
    const init = {
      headers: { Authorization: await this.awsService.getToken() },
      body: formData,
    };
    try {
      console.log("formData_sent", formData);
      const response = await this.awsService.api.put(this.apiName, path, init);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
